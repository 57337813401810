// const changePasswordUrl = process.env.VUE_APP_CHANGE_PASSWORD_URL;

export default function() {
  this.setInitConnDevice();
  if (this.isLogin) {
    this.fetchCommonCode();

    if (this.isCommonCodeReady) {
      this.goFirstPage();
    }
  }

  this.setCompanyClass();
  this.login();
}
