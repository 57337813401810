/**
 * 1. 개요 : Inputs 공통 컴포넌트
 * 2. 처리내용 : Inputs 공통 컴포넌트 - DateRangeAndInputDate
 * @File Name : index.vue
 * @date : 2020. 02. 20. 09:46:00
 * @author : 이상철
 * @history : 2022. 12. 12 Lam.nt1 has changed
 *-----------------------------------------------------------------------
 * 변경일			          		  작성자		변경내용
 *-----------------------------------------------------------------------
 * 2020. 2. 20. 09:46:00	    이상철	  최초 작성
 *-----------------------------------------------------------------------
 */
<template>
  <div :key="updateKey">
    <div class="combo-box-date" :class="{'my-page-date': hideInputTime}">
      <input-date
        v-model="search.tranStaDt"
        @dateValue="changeDateOrTime"
        @input="clickStaDtm"
        :hideInputTime="hideInputTime"
      />
        <!-- :disabled-date="limitDatePickStaDt" -->
      <input-time
        ref="dateRangeAndInputDateStaTm"
        v-if="!hideInputTime"
        v-model="search.tranStaTm"
        :hourOnly="true"
        @timeValue="changeDateOrTime"
      /> 
      <label> ~ </label>
      <input-date
        v-model="search.tranEndDt"
        @dateValue="changeDateOrTime"
        @input="clickEndDtm"
        :hideInputTime="hideInputTime"
      />
        <!-- :disabled-date="limitDatePickEndDt" -->
      <input-time
        v-if="!hideInputTime"
        v-model="search.tranEndTm"
        :hourOnly="true"
        @timeValue="changeDateOrTime"
      />
        <!-- :disabled-time="limitEndTimePick" -->
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { isEmpty } from "@/utils";

export default {
  name: "DateRangeInput",
  data() {
    return {
      diffYn: false,
      copySearch: {
        tranStaDt: "",
        tranEndDt: "",
        tranStaTm: "",
        tranEndTm: ""
      },

      serverDate: '',
      serverTime: '',
      
      updateKey: 0,

      search: {
        tranStaDt: "",
        tranEndDt: "",
        tranStaTm: "",
        tranEndTm: "",
      },
    };
  },
  props: {
    hideInputTime: {
      type: Boolean,
      default: false
    },
  },
  created(){
    this.serverTimeInit();
  },
  mounted(){
  },
  methods: {
    // 시작 날짜 disabled control
    limitDatePickStaDt(date){
      return date > new Date(moment(this.serverDate, "YYYYMMDD")) 
    },
    // 끝 날짜 disabled control
    limitDatePickEndDt(date){
      return date > new Date(moment(this.serverDate, "YYYYMMDD")) || new Date(moment(this.search.tranStaDt, "YYYYMMDD")) > date
    },
    // 끝 시간 disabled control
    limitEndTimePick(time){
      if(this.search.tranEndDt === this.serverDate && this.search.tranStaDt === this.search.tranEndDt) { // '오늘'
        return time.getHours() > this.serverTime || time.getHours() < this.search.tranStaTm
      } else if(this.search.tranEndDt === this.serverDate) { // 끝날짜가 '오늘'
        return time.getHours() > this.serverTime
      } else if(this.search.tranStaDt === this.search.tranEndDt){ // '오늘'은 아니지만 둘이 날짜가 같을 경우
        return time.getHours() < this.search.tranStaTm
      } else return null
    },
    // 서버시간 겟.
    getServerTime(){
      return this.$httpPost(
        "/api/com/getCurrentServerTime.do",
				{},
				{ showLoading: false }
      )
      .then(response => {
          this.serverDate = moment(response.data.result)
                            .format("YYYYMMDD");
          // this.serverTime = moment(response.data.result)
          //                   .subtract("hours")
          //                   .format("HH:mm:ss") // 세 나라 동일 포멧
          //                   .replace(":", "")
          //                   .slice(0, 2);
          // console.log('getServerTime', response.data.result);
          // 20230103 lam.nt1 Update : Set default value end time
          this.serverTime = "23";
          this.$emit('getServerDTm', {serverDate: this.serverDate, serverTime: this.serverTime})
        })
      .catch(this.commonErrorHandler);
    },
    // 서버시간 초기 셋.
    serverTimeInit() {
      this.getServerTime()
      .then(() => {
        // search 초기화 (최초 조회: 서버시간 기준으로 한달 데이터)
        this.search = {
          tranStaTm: "00",
          tranEndTm: this.serverTime,
          tranStaDt: moment(this.serverDate).subtract(1, "day").format("YYYYMMDD"),
          tranEndDt: moment(this.serverDate).format("YYYYMMDD"),
        }

        this.updateKey = this.updateKey + 1;
        this.$emit("mergeDateRangeIntoSearchObject", this.search)
        this.$emit("onSearchList");
      })
      .catch(this.commonErrorHandler);
    },
    /**
     * 데이터 피커나 데이트 타임을 선택하면 데이트 레인지 비활성화
     * @params {String} params
     */
    changeDateOrTime(params) {
      const containYn = Object.values(this.copySearch).some(a => a === params);
      
      if (containYn === true) this.diffYn = true;
      else this.diffYn = false;
      this.$emit("mergeDateRangeIntoSearchObject", this.search)
    },
    /**
     * 시작날짜 클릭시
     * @params {String} params
     */
    clickStaDtm(params) {
      this.search.tranStaDt = params;
    },
    /**
     * 끝날짜 클릭시
     * @params {String} params
     */
    clickEndDtm(params) {
      this.search.tranEndDt = params;
      
      // 끝날짜를 오늘 날짜 선택시 서버 시간으로 조정
      if(this.search.tranEndDt === this.serverDate) {
        this.search.tranEndTm = this.serverTime;
        this.copySearch.tranEndTm = this.serverTime;
      } else {
        this.search.tranEndTm = "23";
        this.copySearch.tranEndTm = "23";
      }

    },
   
    searchObjectClear(){
      this.search = {
        tranStaDt: "",
        tranEndDt: "",
        tranStaTm: "",
        tranEndTm: "",
      }
    },
    focus(){
      this.$nextTick(() => {
        this.$refs.dateRangeAndInputDateStaTm && 
        this.$refs.dateRangeAndInputDateStaTm.$children[0].focus()
      })
    }
  }
};
</script>
<style lang="scss"> 
.combo-box-date{ // 7.30 이지혜 추가. 데이트 인풋보다 시간 인풋이 더 짧음.
  display: inline !important;
  // margin-left: 5px;
  vertical-align: middle;
  font-size:0;
  .input-date{
    display:inline-block;
    vertical-align: middle;
    width:35% !important;
    .mx-datepicker{
      width: 100% !important;
    }
  }
  .short-time-input { // 시간 인풋 짧은 버전
    display:inline-block;
    vertical-align: middle;
    width:10% !important;
    .mx-datepicker{
      width: 100% !important;
    }
  }
}
// .combo-box-for-log { // 8월 28일 이지혜 추가. 마이페이지에서 date만 있는 경우
//   &.my-page-date{
//     margin-left: 10px;
//     .input-date{
//       margin-left:4px;
//     }
//     .only-date{
//       .mx-input-wrapper {
//         width: 120px;
//       }
//     }
//   }
// }
</style>