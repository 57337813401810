/**
 * 1. 개요 : 총판관리
 * 2. 처리내용 : 총판관리 Mixins - 파일 관련 helper
 * @File Name : FileUpload.js
 * @date : 2020. 04. 08.  05:38:13
 * @author : 고영호
 * @history :
 *-----------------------------------------------------------------------
 * 변경일			          		    작성자		변경내용
 *-----------------------------------------------------------------------
 * 2020. 04. 08.  05:38:13	    고영호	  최초 작성
 *-----------------------------------------------------------------------
 */
export default {
  data() {
    return {
      fileUpload: {
        tblNm: 'AXR_PSN',
        acceptExtention: ['.doc', '.docx', '.xls', '.xlsx', '.ppt', '.pptx', '.pdf', '.txt', '.csv', '.jpg', 'jpeg', '.gif', '.png'],
        fileList: [],
        files: []
      },
    }
  },
  methods: {
    /**
     * 파일 Validation
     */
    validCheckFile() {
      return Object.entries(this.fileUpload.files).every(([key]) => {
        if (this.fileUpload.files[key].size > 1024 * 1024 * 10) {
          this.alert({
            type: 'warning',
            title: this.i18n('label.error'),
            message: this.i18n('alt.attatchGuide2', 10),
          });

          return false;
        }
        return true;
      });
    },
    /**
     * 업로드 예정 파일들
     */
    uploadFile() {
      this.fileUpload.files = this.$refs.file.files;

      if (!this.validCheckFile()) {
        return;
      }

      const formData = new FormData();

      if (this.fileUpload.files) {
        for (let i = 0; i < this.fileUpload.files.length; i += 1) {
          // 확장자 를 모두 소문자로 바꿈
          const fileName =
            `${this.fileUpload.files[i].name.split('.')[0]}.${this.fileUpload.files[i].name.split('.')[1].toLowerCase()}`

          formData.append('uploadFile', this.fileUpload.files[i], fileName);
        }
      }

      formData.append('atchFileFkId', this.psnMngVO.atchFileFkId);
      formData.append('msgLangCd', this.msgLangCd);

      // 업로드 테이블 이름
      formData.append('tblNm', this.fileUpload.tblNm);

      // 업로드 테이블의 컬럼 조회조건
      // formData.append('tblParams', JSON.stringify([{
      //   col: "BIZ_NO",
      //   val: this.psnMngVO.bizNo
      // }]));      

      this.$httpPost('/api/com/uploadFile.do', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        showLoading: true,
      }).then((res) => {
        this.psnMngVO.atchFileFkId = res.data.resultList.atchFileFkId;
        this.getFileList(this.psnMngVO.atchFileFkId);
      })
      .catch(this.commonErrorHandler)
      .finally(() => {
        this.$refs.file.value = null;
      });
    },
    /**
     * 파일 추가
     */
    fileAdd() {
      this.$refs.file.click();
    },
    /**
     * 파일리스트 조회
     */
    getFileList(searchAtchFileFkId) {
      this.$httpPost('/api/com/getFileList.do', {
        searchAtchFileFkId
      }, {
        showLoading: true
      }).then((res) => {
        this.fileUpload.fileList = res.data.resultList;
      })
      .catch(this.commonErrorHandler)
    },
    
    /**
     * 파일 삭제 확인
     */
    deleteFile(file) {
      this.alert({
        title: this.i18n('label.del'),
        message: this.i18n('alt.questionDel'),
        buttons: [{
          label: this.i18n('button.confirm'),
          type: 'primary',
          onPress: () => { this.deleteFileProcess(file); },
        }, {
          label: this.i18n('button.cancel'),
        }],
      });
    },
    /**
     *파일 삭제
      */
    deleteFileProcess(file) {
      // let tblNm = '';
      if (this.fileUpload.fileList.length <= 1) {
        this.psnMngVO.atchFileFkId = "";
      }
      this.$httpPost('/api/com/deleteFile.do', {
        atchFileFkId: file.atchFileFkId,
        fileSeqNo: file.fileSeqNo,
        storedFileNm: file.storedFileNm,
        tblNm: this.fileUpload.tblNm || ''
      }, {
        showLoading: true
      }).then(() => {
        this.getFileList(this.psnMngVO.atchFileFkId);
      })
      .catch(this.commonErrorHandler)
    },
    /**
     * 파일 다운로드
     */
    downloadFile(file) {
      this.$httpPost('/api/com/downloadFile.do', {
        searchAtchFileFkId: file.atchFileFkId,
        searchFileSeqNo: file.fileSeqNo,
      }, {
        responseType: 'blob'
      }).then((res) => {
        if (navigator.msSaveBlob) {
          const blob = new Blob([res.data], { type: 'application/octet-stream;charset=utf-8;' });
          navigator.msSaveBlob(blob, `${file.orginlFileNm}`);
        } else if (navigator.appName === 'Microsoft Internet Explorer') {
          const frame = document.createElement('iframe');
          document.body.appendChild(frame);

          frame.contentWindow.document.open('application/octet-stream;charset=utf-8;', 'replace');
          frame.contentWindow.document.write(`sep=,\r\n${res.data}`);
          frame.contentWindow.document.close();
          frame.contentWindow.focus();
          frame.contentWindow.document.execCommand('SaveAs', false, `${file.orginlFileNm}`);

          document.body.removeChild(frame);
        } else {
          const blob = new Blob([res.data], { type: 'application/octet-stream;charset=utf-8;' });
          const link = document.createElement('a');
          // feature detection
          if (link.download !== undefined) {
            // Browsers that support HTML5 download attribute
            const url = URL.createObjectURL(blob);
            link.setAttribute('href', url);
            link.setAttribute('download', `${file.orginlFileNm}`);
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          }
        }
      })
      .catch(this.commonErrorHandler)
    },
  }
}