/**
 * 1. 개요 : 대리점정보검색
 * 2. 처리내용 : 대리점정보검색 Mixins - 화면 모델
 * @File Name : CmnAgncyInfPopupViewModel.js
 * @date : 2020. 04. 08. 13:00:00
 * @author : 이현우
 * @history :
 *-----------------------------------------------------------------------
 * 변경일			          		  작성자		변경내용
 *-----------------------------------------------------------------------
 * 2020. 04. 08. 13:00:00	    이현우	  최초 작성
 *-----------------------------------------------------------------------
 */
import { lookupValue } from "@/utils/dataGrid";

export default {
  methods: {
    cmnAgncyInfPopupViewModel(params = {}) {
      return [
        {
          field: "rnum",
          headerName: "",
          type: Number,
          width: 50,
          isPk: false,
          editable: false,
          pinned: false,
          cellStyle: {
            textAlign: "center"
          },
          cellClassRules: {
            "selected-cell": param =>
              params && param.rowIndex === params.rowIndex
          },
          valueFormatter: param => {
            // 오름차순
            // return (
            //   (this.currPage - 1) * this.pageUnit + param.node.rowIndex + 1
            // );

            // 내림차순
            return (
              this.gridProps.totalItems -
              ((this.currPage - 1) * this.pageUnit + param.node.rowIndex)
            );
          }
        },
        {
          field: "agncyCd",
          headerName: this.i18n("label.agncy") + this.i18n("label.cd"),
          type: String,
          width: 140,
          isPk: true,
          editable: false,
          pinned: false,
          suppressMenu: false,
          sortable: true,
          cellStyle: {
            textAlign: "center"
          },
          cellClassRules: {}
        },
        {
          field: "agncyNm",
          headerName: this.i18n("label.agncy") + this.i18n("label.nm"),
          type: String,
          width: 140,
          isPk: false,
          editable: false,
          pinned: false,
          suppressMenu: false,
          sortable: true,
          cellStyle: {
            textAlign: "center"
          },
          cellClassRules: {}
        },
        {
          field: "agncyEnm",
          headerName: this.i18n("label.agncy") + this.i18n("label.enm"),
          type: String,
          width: 140,
          isPk: false,
          editable: false,
          pinned: false,
          suppressMenu: false,
          sortable: true,
          cellStyle: {
            textAlign: "center"
          },
          cellClassRules: {}
        },
        {
          field: "agncyStatCd",
          headerName:
            this.i18n("label.agncy") +
            this.i18n("label.stat") +
            this.i18n("label.cd"),
          type: String,
          width: 140,
          isPk: false,
          editable: false,
          pinned: false,
          suppressMenu: false,
          sortable: true,
          cellStyle: {
            textAlign: "center"
          },
          cellClassRules: {}
        },
        {
          field: "bizNo",
          headerName: this.i18n("label.biz") + this.i18n("label.no"),
          type: String,
          width: 180,
          isPk: false,
          editable: false,
          pinned: false,
          suppressMenu: false,
          sortable: true,
          cellStyle: {
            textAlign: "center"
          },
          cellClassRules: {}
        },
        {
          field: "bizNm",
          headerName: this.i18n("label.bizNm"),
          type: String,
          width: 180,
          isPk: false,
          editable: false,
          pinned: false,
          suppressMenu: false,
          sortable: true,
          cellStyle: {
            textAlign: "left"
          },
          cellClassRules: {}
        },
        {
          field: "parDistrCd",
          headerName:
            this.i18n("label.par") +
            this.i18n("label.distr") +
            this.i18n("label.cd"),
          type: String,
          width: 180,
          isPk: false,
          editable: false,
          pinned: false,
          suppressMenu: false,
          sortable: true,
          cellStyle: {
            textAlign: "center"
          },
          cellClassRules: {}
        },
        {
          field: "parDistrNm",
          headerName:
            this.i18n("label.parDistrNm"),
          type: String,
          width: 180,
          isPk: false,
          editable: false,
          pinned: false,
          suppressMenu: false,
          sortable: true,
          cellStyle: {
            textAlign: "left"
          },
          cellClassRules: {}
        },        
        {
          field: "addedDate",
          headerName: this.i18n("label.addedDate"),
          type: String,
          width: 140,
          isPk: false,
          editable: false,
          pinned: false,
          suppressMenu: false,
          sortable: true,
          cellStyle: {
            textAlign: "center"
          },
          cellClassRules: {},
          valueFormatter: param => this.i18nDtTmFormat(param.value)
        },
        {
          field: "restCell",
          headerName: "",
          width: 0,
          type: String,
          isPk: false,
          editable: false,
          pinned: false,
          suppressMenu: false,
          sortable: false,
          cellStyle: {},
          cellClassRules: {}
        }
      ];
    }
  }
};
