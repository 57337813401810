/**
 * 1. 개요 : Inputs 공통 컴포넌트
 * 2. 처리내용 : Inputs 공통 컴포넌트 - InputText
 * @File Name : index.vue
 * @date : 2020. 02. 20. 09:46:00
 * @author : 이상철
 * @history :
 *-----------------------------------------------------------------------
 * 변경일			          		  작성자		변경내용
 *-----------------------------------------------------------------------
 * 2020. 2. 20. 09:46:00	    이상철	  최초 작성
 *-----------------------------------------------------------------------
 */
<template>
	<div class="input-text text-box">
		<input
			:ref="id"
			v-model="model"
			:type="type"
			v-bind="$attrs"
			v-on="$listeners"
			:id="id"
			:maxlength="calMaxlength"
			:placeholder="placeholder"
			:disabled="disabled"
			:onkeydown="onKeyDown"
			:class="{'number-type': alignRight === true}"
			@blur="onBlurHandler"
			@input="$emit('input')"
		/>
		<div v-if="showClear" class="input-clear" title="clear" @click="$emit('clear')" />
	</div>
</template>
<script>
import { isEmpty } from "@/utils";

export default {
	name: "InputText",

	model: {
		prop: "value",
		event: "apply"
	},

	props: {
		type: {
			type: String,
			default: "text"
		},
		value: {
			type: [String, Number],
			default: ""
		},
		showClear: {
			type: Boolean,
			default: false
		},
		id: {
			type: String,
			default: ""
		},
		formatter: {
			type: Function,
			default: null
		},
		maxlength: {
			type: Number,
			default: null
		},
		placeholder: {
			type: String,
			default: ""
		},
		disabled: {
			type: Boolean,
			default: false
		},
		alignRight: {
			type: Boolean,
			default: false
		},
		preventMinus: {
			type: Boolean,
			default: true
		},
		preventDot: {
			type: Boolean,
			default: true
		},
		isNumberType: {
			type: Boolean,
			default: false
		}
	},

	data() {
		return {
			model: this.value
		};
	},

	computed: {
		onKeyDown() {
			if (this.isNumberType && !this.preventMinus && !this.preventDot) {
				return `return  event.keyCode !== 187 && // '=,+'
												event.keyCode !== 107 && // '+'
												event.keyCode !== 191 && // '/'
												event.keyCode !== 111 && // '/'
												event.keyCode !== 106 && // '*'
												event.keyCode !== 192 && // '~'
												event.keyCode !== 222 && // '
												event.keyCode !== 186 && // ';'
												event.keyCode !== 188 && // ','
												event.keyCode !== 219 && // '['
												event.keyCode !== 221 && // ']'
												event.keyCode !== 220 && // '|\'
												event.keyCode !== 229 && // '한글'
		                    event.keyCode !== 81 && // 영문
		                    event.keyCode !== 87 &&
		                    event.keyCode !== 69 &&
		                    event.keyCode !== 82 &&
		                    event.keyCode !== 84 &&
		                    event.keyCode !== 89 &&
		                    event.keyCode !== 85 &&
		                    event.keyCode !== 73 &&
		                    event.keyCode !== 79 &&
		                    event.keyCode !== 80 &&
		                    event.keyCode !== 83 &&
		                    event.keyCode !== 68 &&
		                    event.keyCode !== 70 &&
		                    event.keyCode !== 71 &&
		                    event.keyCode !== 72 &&
		                    event.keyCode !== 74 &&
		                    event.keyCode !== 75 &&
		                    event.keyCode !== 76 &&
		                    event.keyCode !== 90 &&
												event.keyCode !== 66 &&
												event.keyCode !== 78 &&
												event.keyCode !== 32 &&
												event.keyCode !== 77`;
			} else if (this.isNumberType && !this.preventMinus && this.preventDot) {
				return `return  event.keyCode !== 187 && // '=,+'
												event.keyCode !== 110 && // '.'
												event.keyCode !== 190 && // '.'
												event.keyCode !== 107 && // '+'
												event.keyCode !== 191 && // '/'
												event.keyCode !== 111 && // '/'
												event.keyCode !== 106 && // '*'
												event.keyCode !== 192 && // '~'
												event.keyCode !== 222 && // '
												event.keyCode !== 186 && // ';'
												event.keyCode !== 188 && // ','
												event.keyCode !== 219 && // '['
												event.keyCode !== 221 && // ']'
												event.keyCode !== 220 && // '|\'
												event.keyCode !== 229 && // '한글'
		                    event.keyCode !== 81 && // 영문
		                    event.keyCode !== 87 &&
		                    event.keyCode !== 69 &&
		                    event.keyCode !== 82 &&
		                    event.keyCode !== 84 &&
		                    event.keyCode !== 89 &&
		                    event.keyCode !== 85 &&
		                    event.keyCode !== 73 &&
		                    event.keyCode !== 79 &&
		                    event.keyCode !== 80 &&
		                    event.keyCode !== 83 &&
		                    event.keyCode !== 68 &&
		                    event.keyCode !== 70 &&
		                    event.keyCode !== 71 &&
		                    event.keyCode !== 72 &&
		                    event.keyCode !== 74 &&
		                    event.keyCode !== 75 &&
		                    event.keyCode !== 76 &&
		                    event.keyCode !== 90 &&
												event.keyCode !== 66 &&
												event.keyCode !== 78 &&
												event.keyCode !== 32 &&
												event.keyCode !== 77`;
			} else if (this.isNumberType && this.preventMinus && !this.preventDot) {
				return `return  event.keyCode !== 187 && // '=,+'
												event.keyCode !== 189 && // 마이너스
												event.keyCode !== 109 && // 마이너스
												event.keyCode !== 107 && // '+'
												event.keyCode !== 191 && // '/'
												event.keyCode !== 111 && // '/'
												event.keyCode !== 106 && // '*'
												event.keyCode !== 192 && // '~'
												event.keyCode !== 222 && // '
												event.keyCode !== 186 && // ';'
												event.keyCode !== 188 && // ','
												event.keyCode !== 219 && // '['
												event.keyCode !== 221 && // ']'
												event.keyCode !== 220 && // '|\'
												event.keyCode !== 229 && // '한글'
		                    event.keyCode !== 81 && // 영문
		                    event.keyCode !== 87 &&
		                    event.keyCode !== 69 &&
		                    event.keyCode !== 82 &&
		                    event.keyCode !== 84 &&
		                    event.keyCode !== 89 &&
		                    event.keyCode !== 85 &&
		                    event.keyCode !== 73 &&
		                    event.keyCode !== 79 &&
		                    event.keyCode !== 80 &&
		                    event.keyCode !== 83 &&
		                    event.keyCode !== 68 &&
		                    event.keyCode !== 70 &&
		                    event.keyCode !== 71 &&
		                    event.keyCode !== 72 &&
		                    event.keyCode !== 74 &&
		                    event.keyCode !== 75 &&
		                    event.keyCode !== 76 &&
		                    event.keyCode !== 90 &&
												event.keyCode !== 66 &&
												event.keyCode !== 78 &&
												event.keyCode !== 32 &&
												event.keyCode !== 77`;
			} else if (this.isNumberType && this.preventMinus && this.preventDot) {
				return `return  event.keyCode !== 187 && // '=,+'
												event.keyCode !== 189 && // 마이너스
												event.keyCode !== 109 && // 마이너스
												event.keyCode !== 110 && // '.'
												event.keyCode !== 190 && // '.'
												event.keyCode !== 107 && // '+'
												event.keyCode !== 191 && // '/'
												event.keyCode !== 111 && // '/'
												event.keyCode !== 106 && // '*'
												event.keyCode !== 110 && // '.'
												event.keyCode !== 190 && // '.'
												event.keyCode !== 192 && // '~'
												event.keyCode !== 222 && // '
												event.keyCode !== 186 && // ';'
												event.keyCode !== 188 && // ','
												event.keyCode !== 219 && // '['
												event.keyCode !== 221 && // ']'
												event.keyCode !== 220 && // '|\'
												event.keyCode !== 229 && // '한글'
		                    event.keyCode !== 81 && // 영문
		                    event.keyCode !== 87 &&
		                    event.keyCode !== 69 &&
		                    event.keyCode !== 82 &&
		                    event.keyCode !== 84 &&
		                    event.keyCode !== 89 &&
		                    event.keyCode !== 85 &&
		                    event.keyCode !== 73 &&
		                    event.keyCode !== 79 &&
		                    event.keyCode !== 80 &&
		                    event.keyCode !== 83 &&
		                    event.keyCode !== 68 &&
		                    event.keyCode !== 70 &&
		                    event.keyCode !== 71 &&
		                    event.keyCode !== 72 &&
		                    event.keyCode !== 74 &&
		                    event.keyCode !== 75 &&
		                    event.keyCode !== 76 &&
		                    event.keyCode !== 90 &&
												event.keyCode !== 66 &&
												event.keyCode !== 78 &&
												event.keyCode !== 32 &&
												event.keyCode !== 77`;
			} else {
				return null;
			}
		},
		calMaxlength() {
			if (typeof this.model !== "string") this.model = String(this.model);

			if (
				this.isNumberType &&
				(!isEmpty(this.model.split(".")[1]) || this.model.includes("-"))
			)
				return this.maxlength + 1;
			else return this.maxlength;
		}
	},

	watch: {
		model(value) {
			if (typeof value !== "string") value = String(value);

			if (!isEmpty(value) && this.isNumberType && this.preventDot) {
				value = value.replace(/\./gi, "");
			}
			if (!isEmpty(value) && this.isNumberType && this.preventMinus) {
				value = value.replace(/-/gi, "");
			}
			// 타입이 number일 경우 maxlength 설정
			if (
				this.isNumberType &&
				this.maxlength &&
				value.length > this.maxlength
			) {
				this.model = value.slice(0, this.maxlength);
				value = value.slice(0, this.maxlength);
				this.$emit("apply", value);
			} else {
				this.$emit("apply", value);
			}
		},

		value(value) {
			if (this.model !== value) {
				value = !isEmpty(this.formatter) ? this.formatter(value) : value;
				this.model = value;
			}
		}
	},

	methods: {
		onBlurHandler(e) {
			this.$emit("blur", e);
		},
		focus() {
			const input = this.$refs[this.id];
			if (input) input.focus();
		}
	}
};
</script>

<style lang="scss" scoped>
@import "@/scss/_color.scss";
@import "@/scss/_input.scss";
.number-type {
	text-align: right;
}
</style>

