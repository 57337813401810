/**
 * 1. 개요 : Inputs 공통 컴포넌트
 * 2. 처리내용 : Inputs 공통 컴포넌트 - CheckBox
 * @File Name : index.vue
 * @date : 2020. 02. 20. 09:46:00
 * @author : 이상철
 * @history :
 *-----------------------------------------------------------------------
 * 변경일			          		  작성자		변경내용
 *-----------------------------------------------------------------------
 * 2020. 2. 20. 09:46:00	    이상철	  최초 작성
 *-----------------------------------------------------------------------
 */
<template>
  <label class="check-box"
         role="checkbox"
         :class="{
           disabled,
           checked: checkedState,
           inline
         }"
  >
    <input ref="checkbox"
           v-model="checkModel"
           type="checkbox"
           v-bind="$attrs"
           :disabled="disabled"
           :value="value"
           @change="onChange"
           v-on="$listeners"
          tabindex="0"
    >
    <span class="check-box-icon"
          :style="iconStyles"
    /><span v-if="$slots.default"
            class="label"
    >
      <slot />
    </span>
  </label>
</template>

<script>
export default {
  name: 'CheckBox',

  model: {
    prop: 'checked',
    event: 'apply',
  },

  props: {
    checked: {
      type: [Boolean, Array],
      default: false,
    },

    indeterminate: {
      type: Boolean,
      default: false,
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    value: {
      type: [String, Number],
      default: null,
    },

    inline: {
      type: Boolean,
      default: false,
    },

    size: {
      type: Number,
      default: 14,
    },
  },

  data() {
    return {
      checkModel: null,
    };
  },

  computed: {
    checkedState() {
      if (Array.isArray(this.checked)) {
        return this.checked.indexOf(this.checked) > -1;
      }
      return this.checked;
    },

    iconStyles() {
      return {
        width: `${this.size}px`,
        height: `${this.size}px`,
      };
    },
  },

  watch: {
    indeterminate(value) {
      this.$refs.checkbox.indeterminate = value;
    },

    checked(value) {
      this.checkModel = value;
    },
  },

  created() {
    this.checkModel = this.checked;
  },

  mounted() {
    this.$refs.checkbox.indeterminate = this.indeterminate;
  },

  methods: {
    onChange() {
      this.$emit('apply', this.checkModel);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/_input.scss";
</style>

