 /**
 * 1. 개요 : 가맹점 정보 검색
 * 2. 처리내용 : 가맹점 정보 검색 Mixins - 화면 모델
 * File Name : CmnMultMerInfPopupViewModel.js
 * date : 2022. 12. 29. 16:15:00
 * author : 김준석
 * history :
 *-----------------------------------------------------------------------
 * 변경일			          		  작성자		변경내용
 *-----------------------------------------------------------------------
 * 2022. 12. 29. 16:15:00	    김준석	  최초 작성
 *-----------------------------------------------------------------------
 */
 
// import { lookupValue } from "@/utils/dataGrid";

export default {
  methods: {
    cmnMultiMerInfPopupViewModel(params = {}) {
      return [
        {
          checkboxSelection: true,
          headerCheckboxSelection: true,
          headerCheckboxSelectionFilteredOnly: false,
          width: 50,
          sortable: false,
          pinned: true,
          cellStyle: {
            textAlign: 'center'
          },
        },
        {
          field: "merId",
          headerName: this.i18n("label.merId"),
          type: Number,
          width: 0,
          isPk: true,
          editable: false,
          pinned: true,
          suppressMenu: false,
          sortable: true,
          cellStyle: {},
          cellClassRules: {},
        },
        {
          field: "bizNo",
          headerName: this.i18n("label.bizNo"),
          type: Number,
          width: 0,
          isPk: true,
          editable: false,
          pinned: true,
          suppressMenu: false,
          sortable: true,
          cellStyle: {},
          cellClassRules: {},
          
        },
        {
          field: "merNm",
          headerName: this.i18n("label.merNm"),
          type: String,
          width: 0,
          isPk: false,
          editable: false,
          pinned: false,
          suppressMenu: false,
          sortable: true,
          cellStyle: {},
          cellClassRules: {},
          
        },
        {
          field: "merEnm",
          headerName: this.i18n("label.enm"),
          type: String,
          width: 0,
          isPk: false,
          editable: false,
          pinned: false,
          suppressMenu: false,
          sortable: true,
          cellStyle: {},
          cellClassRules: {},
          
        },
        {
          field: "agncyNm",
          headerName: this.i18n("label.agncyNm"),
          type: String,
          width: 0,
          isPk: false,
          editable: false,
          pinned: false,
          suppressMenu: false,
          sortable: true,
          cellStyle: {},
          cellClassRules: {},
          
        },
        {
          field: "merTelNo",
          headerName: this.i18n("label.telNo"),
          type: String,
          width: 0,
          isPk: false,
          editable: false,
          pinned: false,
          suppressMenu: false,
          sortable: true,
          cellStyle: {},
          cellClassRules: {},
          
        },
        {
          field: "merPno",
          headerName: this.i18n("label.pno"),
          type: String,
          width: 0,
          isPk: false,
          editable: false,
          pinned: false,
          suppressMenu: false,
          sortable: true,
          cellStyle: {},
          cellClassRules: {},
          
        },
        {
          field: "ctAddrNm",
          headerName: this.i18n("label.city"),
          type: String,
          width: 0,
          isPk: false,
          editable: false,
          pinned: false,
          suppressMenu: false,
          sortable: true,
          cellStyle: {},
          cellClassRules: {},
          
        },
        {
          field: "dsAddrNm",
          headerName: this.i18n("label.dist"),
          type: String,
          width: 0,
          isPk: false,
          editable: false,
          pinned: false,
          suppressMenu: false,
          sortable: true,
          cellStyle: {},
          cellClassRules: {},
          
        },
        {
          field: "wdAddrNm",
          headerName: this.i18n("label.ward"),
          type: String,
          width: 0,
          isPk: false,
          editable: false,
          pinned: false,
          suppressMenu: false,
          sortable: true,
          cellStyle: {},
          cellClassRules: {},
          
        },
        {
          field: "merAddr",
          headerName: this.i18n("label.addr"),
          type: String,
          width: 0,
          isPk: false,
          editable: false,
          pinned: false,
          suppressMenu: false,
          sortable: true,
          cellStyle: {},
          cellClassRules: {},
          
        },
        {
          field: "deptCd",
          headerName: this.i18n("label.deptCd"),
          type: String,
          width: 140,
          isPk: false,
          editable: false,
          pinned: false,
          suppressMenu: false,
          sortable: true,
          cellStyle: {
            textAlign: "center"
          },
          cellClassRules: {}
        },              
        {
          field: "addedDate",
          headerName: this.i18n("label.addedDate"),
          type: String,
          width: 0,
          isPk: false,
          editable: false,
          pinned: false,
          suppressMenu: false,
          sortable: true,
          cellStyle: {},
          cellClassRules: {},
          
        },
      ];
    }
  }
};
