var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "selector select-box" }, [
    _c(
      "select",
      _vm._g(
        _vm._b(
          {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.model,
                expression: "model"
              }
            ],
            attrs: { id: _vm.id },
            on: {
              change: function($event) {
                var $$selectedVal = Array.prototype.filter
                  .call($event.target.options, function(o) {
                    return o.selected
                  })
                  .map(function(o) {
                    var val = "_value" in o ? o._value : o.value
                    return val
                  })
                _vm.model = $event.target.multiple
                  ? $$selectedVal
                  : $$selectedVal[0]
              }
            }
          },
          "select",
          _vm.$attrs,
          false
        ),
        _vm.$listeners
      ),
      [_vm._t("default")],
      2
    ),
    _c("div", { staticClass: "arrow-box" })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }