var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "popup",
    {
      attrs: { title: _vm.title },
      on: {
        close: function($event) {
          return _vm.$emit("close", { refresh: false })
        }
      }
    },
    [
      _c(
        "data-table",
        { attrs: { tblId: "tblInfo001", id: "dynamicTable" } },
        [
          _c("template", { slot: "tableInfo" }, [
            _vm._v(
              " " +
                _vm._s(_vm._f("i18n")("label.sale")) +
                ", " +
                _vm._s(_vm._f("i18n")("label.void")) +
                ", " +
                _vm._s(_vm._f("i18n")("label.refund")) +
                ", " +
                _vm._s(_vm._f("i18n")("label.balance")) +
                ", " +
                _vm._s(_vm._f("i18n")("label.preAuth")) +
                ", " +
                _vm._s(_vm._f("i18n")("label.adjust")) +
                " , " +
                _vm._s(_vm._f("i18n")("label.motoSale")) +
                ", " +
                _vm._s(_vm._f("i18n")("label.offlineSale")) +
                ", " +
                _vm._s(_vm._f("i18n")("label.cashAdvance")) +
                ", " +
                _vm._s(_vm._f("i18n")("label.changePin")) +
                " " +
                _vm._s(_vm._f("i18n")("label.rgt")) +
                " or " +
                _vm._s(_vm._f("i18n")("label.change")) +
                " "
            )
          ]),
          _c("template", { slot: "caption" }, [
            _vm._v(" " + _vm._s(_vm.title) + " ")
          ]),
          _c("template", { slot: "colgroup" }, [
            _c("col", { staticStyle: { width: "150px" } }),
            _c("col", { staticStyle: { width: "auto" } }),
            _c("col", { staticStyle: { width: "150px" } }),
            _c("col", { staticStyle: { width: "auto" } })
          ]),
          _c(
            "template",
            { slot: "tbody" },
            _vm._l(_vm.itemPacketList, function(item) {
              return _c("tr", { key: item.codeName1 }, [
                _c("th", { attrs: { scope: "row" } }, [
                  _vm._v(" " + _vm._s(item.codeName1) + " ")
                ]),
                _c(
                  "td",
                  [
                    _c("check-box", {
                      attrs: { checked: item.status1 == true },
                      on: {
                        change: function($event) {
                          return _vm.putOrChange(item, 1, $event)
                        }
                      }
                    })
                  ],
                  1
                ),
                _c("th", { attrs: { scope: "row" } }, [
                  _vm._v(" " + _vm._s(item.codeName2) + " ")
                ]),
                _c(
                  "td",
                  [
                    item.codeName2 != ""
                      ? _c("check-box", {
                          attrs: { checked: item.status2 == true },
                          on: {
                            change: function($event) {
                              return _vm.putOrChange(item, 2, $event)
                            }
                          }
                        })
                      : _vm._e()
                  ],
                  1
                )
              ])
            }),
            0
          )
        ],
        2
      ),
      _c(
        "template",
        { slot: "buttons" },
        [
          _c("primary-button", { on: { click: _vm.validate } }, [
            _vm._v(_vm._s(_vm.buttonTitle))
          ]),
          _c(
            "default-button",
            {
              on: {
                click: function($event) {
                  return _vm.$emit("close", { refresh: false })
                }
              }
            },
            [_vm._v(_vm._s(_vm._f("i18n")("button.cancel")))]
          )
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }