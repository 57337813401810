/**
 * 1. 개요 : bep 스토어
 * 2. 처리내용 : 업무 공통 팝업(단말기시리얼번호정보 검색) - 스토어
 * @File Name : CmnTrmSnPopupChecked.js
 * @date : 2020. 02. 20. 09:46:00
 * @author : 이상철
 * @history :
 *-----------------------------------------------------------------------
 * 변경일			          		  작성자		변경내용
 *-----------------------------------------------------------------------
 * 2020. 2. 20. 09:46:00	    이상철	  최초 작성
 *-----------------------------------------------------------------------
 */
export default {
  state: {
    trmSnList: [],
    limitCheckCnt: 0
  },
  namespaced: true,
  mutations: {
    SET_TRMSN_LIST(state, trmSn) {
      state.trmSnList.push(trmSn);
    },
    REMOVE_TRMSN_LIST(state, trmSn) {
      if(trmSn) state.trmSnList = state.trmSnList.filter(a => a.trmSn !== trmSn.trmSn)
    },
    INITIAL_TRMSN_LIST(state, trmSn) {
      state.trmSnList = []
    },

    SET_LIMIT_CNT(state, checkCnt) {
      state.limitCheckCnt = checkCnt
    },
    INITIAL_LIMIT_CNT(state) {
      state.limitCheckCnt = 0;
    }
  },
  actions: {
    setTrmsnList({ commit }, trmSn) {
      commit('SET_TRMSN_LIST', trmSn);
    },
    removeTrmsnList({ commit }, trmSn) {
      commit('REMOVE_TRMSN_LIST', trmSn)
    },
    initialTrmsnList({ commit }, trmSn) {
      commit('INITIAL_TRMSN_LIST', trmSn)
    },

    setLimitCheckCnt({ commit }, checkCnt) {
      commit('SET_LIMIT_CNT', checkCnt)
    },
    initialLimitCheckCnt({ commit }, checkCnt) {
      commit('INITIAL_LIMIT_CNT', checkCnt)
    }
  },
  getters: {
    getTrmSnList: state => state.trmSnList,
    getlimitCheckCnt: state => state.limitCheckCnt
  }
};
