/**
 * 1. 개요 : 카드BIN관리
 * 2. 처리내용 : 카드BIN관리 Mixins - 화면 모델
 * CmnCardBinPopupViewModel.js
 * @date : 2020. 06. 17.  15:55:53
 * @author : 이현우
 * @history :
 *-----------------------------------------------------------------------
 * 변경일			          		    작성자		변경내용
 *-----------------------------------------------------------------------
 * 2020. 06. 17.  15:55:53	    이현우	  최초 작성
 *-----------------------------------------------------------------------
 */
import { lookupValue } from "@/utils/dataGrid";
import moment from "moment";

export default {
  methods: {
    cmnTrmInstPopupViewModel(params = {}) {
      return [
        {
          field: "rnum",
          headerName: "",
          type: Number,
          width: 50,
          isPk: false,
          editable: false,
          pinned: true,
          sortable: true,
          cellStyle: {
            textAlign: "center"
          },
          cellClassRules: {
            "selected-cell": param => params && param.rowIndex === params.rowIndex
          },
          valueFormatter: param => {
            return this.gridProps.totalItems - ((this.currPage - 1) * this.pageUnit + param.node.rowIndex);
          }
        },
        {
          field: "trmId",
          headerName: this.i18n("label.trmId"),
          type: Number,
          width: 150,
          isPk: true,
          editable: false,
          pinned: false,
          suppressMenu: false,
          sortable: true,
          cellStyle: {
            textAlign:"center"
          },
          cellClassRules: {},
        },
        {
          field: "trInstCd",
          headerName: this.i18n("label.trInstCd"),
          type: String,
          width: 150,
          isPk: true,
          editable: false,
          pinned: false,
          suppressMenu: false,
          sortable: true,
          cellStyle: {
            textAlign:"center"
          },
          cellClassRules: {},
        },
        {
          field: "instNm",
          headerName: this.i18n("label.instNm"),
          type: String,
          width: 0,
          isPk: true,
          editable: false,
          pinned: false,
          suppressMenu: false,
          sortable: true,
          cellStyle: {},
          cellClassRules: {},
        },
        {
          field: "merNm",
          headerName: this.i18n("label.merNm"),
          type: String,
          width: 0,
          isPk: true,
          editable: false,
          pinned: false,
          suppressMenu: false,
          sortable: true,
          cellStyle: {},
          cellClassRules: {},
        },
        {
          field: "instMerNo",
          headerName: this.i18n("label.instMerNo"),
          type: String,
          width: 0,
          isPk: true,
          editable: false,
          pinned: false,
          suppressMenu: false,
          sortable: true,
          cellStyle: {},
          cellClassRules: {},
        },
        {
          field: "instMerCd",
          headerName: this.i18n("label.instMerCd"),
          type: String,
          width: 150,
          isPk: true,
          editable: false,
          pinned: false,
          suppressMenu: false,
          sortable: true,
          cellStyle: {},
          cellClassRules: {},
        },
        {
          field: "instTrmId",
          headerName: this.i18n("label.instTrmId"),
          type: String,
          width: 150,
          isPk: true,
          editable: false,
          pinned: false,
          suppressMenu: false,
          sortable: true,
          cellStyle: {},
          cellClassRules: {},
        },
        {
          field: "",
          headerName: this.i18n(""),
          type: String,
          width: 50,
          isPk: true,
          editable: false,
          pinned: false,
          suppressMenu: false,
          sortable: true,
          cellStyle: {},
          cellClassRules: {},
        },
      ];
    }
  }
};
