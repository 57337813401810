var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "label",
    {
      staticClass: "radio-box",
      class: {
        disabled: _vm.disabled,
        checked: _vm.$attrs.value === _vm.model
      },
      attrs: { role: "radio", for: _vm.id + _vm.$attrs.value }
    },
    [
      _c(
        "input",
        _vm._g(
          _vm._b(
            {
              ref: "radio",
              attrs: {
                type: "radio",
                disabled: _vm.disabled,
                id: _vm.id + _vm.$attrs.value,
                name: _vm.id + "Name"
              },
              domProps: { checked: _vm.$attrs.value === _vm.model },
              on: {
                change: function($event) {
                  return _vm.$emit("apply", _vm.$attrs.value)
                }
              }
            },
            "input",
            _vm.$attrs,
            false
          ),
          _vm.$listeners
        )
      ),
      _c("span", { staticClass: "radio-box-icon" }),
      _c("span", { staticClass: "label" }, [
        _vm._v(" " + _vm._s(_vm.item) + " ")
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }