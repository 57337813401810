/**
 * 1. 개요 : Dialog 공통 컴포넌트
 * 2. 처리내용 : Dialog 공통 컴포넌트 - Alert
 * @File Name : Alert.vue
 * @date : 2020. 02. 20. 09:46:00
 * @author : 이상철
 * @history :
 *-----------------------------------------------------------------------
 * 변경일			          		  작성자		변경내용
 *-----------------------------------------------------------------------
 * 2020. 2. 20. 09:46:00	    이상철	  최초 작성
 *-----------------------------------------------------------------------
 */
<template>
	<window role="dialog" :id="id" :type="type">
		<template slot="header">{{ title }}</template>
		<message :message="message" tag="p" :use-i18n="false" />
		<template slot="buttons">
			<template v-for="(button, index) in buttons">
				<primary-button
					v-if="button.type === 'primary'"
					:key="index"
					type="button"
					@click="onPressListener(button.onPress)"
				>{{ button.label }}</primary-button>
				<default-button
					v-else
					:key="index"
					type="button"
					@click="onPressListener(button.onPress)"
				>{{ button.label }}</default-button>
			</template>
		</template>
	</window>
</template>

<script>
import { mapActions } from "vuex";

import Window from "./Window";
import Message from "./Message";

import { isEmpty } from "@/utils";

export default {
	name: "Alert",
	components: {
		Window,
		Message
	},
	props: {
		id: {
			type: String,
			required: true
		},
		title: {
			type: String,
			default: ""
		},
		message: {
			type: String,
			default: ""
		},
		buttons: {
			type: Array,
			default() {
				return [
					{
						label: (lang =>
							this.isLogin
								? this.i18n("button.confirm")
								: this.$parent.i18nOff("button.confirm"))(
							document.documentElement.lang
						),
						type: "default",
						onPress: () => this.onPress()
					}
				];
			}
		},
		onPress: {
			type: Function,
			default: () => {}
		},
		type: {
			type: String,
			default: "default"
		}
	},
	mounted() {
		const button = this.$el.getElementsByTagName("button")[0];
		if (button) button.focus();
	},
	methods: {
		/**
		 * store에 선언된 closeAlert 함수를 불러옴.
		 */
		...mapActions("Alert", ["closeAlert"]),
		/**
		 * 얼럿 팝업 내 버튼 listner. 이벤트가 없으면 창 닫음.
		 * @params {Function} onPress
		 */
		onPressListener(onPress) {
			if (!onPress || onPress() !== false) this.close();
		},
		/**
		 * 얼럿 팝업 close
		 */
		close() {
			this.closeAlert(this.id);
		}
	}
};
</script>