/**
 * 1. 개요 : 코드가져오기
 * 2. 처리내용 : Mixins - 코드 fetch helper
 * @File Name : CodeFetchHelper.js
 * @date : 2022. 12. 02.  10:08:00
 * @author : 이진우
 * @history :
 *-----------------------------------------------------------------------
 * 변경일			          		    작성자		변경내용
 *-----------------------------------------------------------------------
 * 2022. 12. 02.  10:08:00	    이진우	  최초 작성
 *-----------------------------------------------------------------------
 */
import { isEmpty } from '@/utils'

export default {
  data() {
    return {
      cdList: {
        
      }
    }
  },
  created() {
    
  },
  methods: {
    /**
     * 업무 코드 리스트 페치
     * @param {Object} searchCodeGrpIdList
     */
		async fnGetAxcCodeGrpList(searchCodeGrpIdList = []) {
      if(isEmpty(searchCodeGrpIdList)) return;

			await this.$httpPost(
					"/api/bep/getAxcCodeGroupListByCodeGrpIdList.do",
					{ searchCodeGrpIdList },
					{ showLoading: false }
				)
				.then(response => {
          
        })
        .catch(this.commonErrorHandler)
    },
  }
}