/**
 * 1. 개요 : 단말기시리얼번호정보검색
 * 2. 처리내용 : 단말기시리얼번호정보검색 Mixins - 화면 모델
 * @File Name : cmnTrmSnMvnoPopupViewModel.js
 * @date : 2020. 04. 20. 13:00:00
 * @author : 이현우
 * @history :
 *-----------------------------------------------------------------------
 * 변경일			          		  작성자		변경내용
 *-----------------------------------------------------------------------
 * 2020. 04. 20. 13:00:00	    이현우	  최초 작성
 *-----------------------------------------------------------------------
 */

import { lookupValue } from "@/utils/dataGrid";

export default {
  methods: {
    cmnTrmSnMvnoPopupViewModel(params = {}) {
      return [
        {
          field: "rnum",
          headerName: "",
          type: Number,
          width: 60,
          isPk: false,
          editable: false,
          pinned: false,
          cellStyle: {
            textAlign: "center"
          },
          cellClassRules: {
            "selected-cell": param => params && param.rowIndex === params.rowIndex
          },
          valueFormatter: param => {
            // 오름차순
            // return (
            //   (this.currPage - 1) * this.pageUnit + param.node.rowIndex + 1
            // );

            // 내림차순
            return this.gridProps.totalItems - ((this.currPage - 1) * this.pageUnit + param.node.rowIndex);
          }
        },
        {
          field: "mvnoSn",
          headerName: this.i18n("label.mvnoSn"),
          type: String,
          width: 160,
          isPk: true,
          editable: false,
          pinned: false,
          suppressMenu: false,
          sortable: true,
          cellStyle: {
            textAlign: "center"
          },
          cellClassRules: {}
        },
        {
          field: "mvnoMdlNm",
          headerName: this.i18n("label.mvnoMdlCd"),
          type: String,
          width: 140,
          isPk: false,
          editable: false,
          pinned: false,
          suppressMenu: false,
          sortable: true,
          cellStyle: {
            textAlign: "center"
          },
          cellClassRules: {}
        },
        {
          field: "mvnoMdlCdDscr",
          headerName: this.i18n("label.mvnoMdlNm"),
          type: String,
          width: 140,
          isPk: false,
          editable: false,
          pinned: false,
          suppressMenu: false,
          sortable: true,
          cellStyle: {
            textAlign: "center"
          },
          cellClassRules: {}
        },
        {
          field: "stckTgtLvlCd",
          headerName: this.i18n("label.stckTgtLvl"),
          type: String,
          width: 130,
          isPk: false,
          editable: false,
          pinned: false,
          suppressMenu: false,
          sortable: true,
          cellStyle: {
            textAlign: "center"
          },
          cellClassRules: {},
          valueFormatter: param => param.value && lookupValue(this.cdList.stckTgtLvlCdList, param.value)
        },
        {
          field: "stckTgtNm",
          headerName: this.i18n("label.stckTgt"),
          type: String,
          width: 140,
          isPk: false,
          editable: false,
          pinned: false,
          suppressMenu: false,
          sortable: true,
          cellStyle: {
            textAlign: "center"
          },
          cellClassRules: {}
        },
        {
          field: "mvnoPrdNo",
          headerName: this.i18n("label.mvnoPrdNo"),
          type: String,
          width: 140,
          isPk: false,
          editable: false,
          pinned: false,
          suppressMenu: false,
          sortable: true,
          cellStyle: {
            textAlign: "center"
          },
          cellClassRules: {}
        },
        {
          field: "trmSn",
          headerName: this.i18n("label.trmSn"),
          type: String,
          width: 160,
          isPk: false,
          editable: false,
          pinned: false,
          suppressMenu: false,
          sortable: true,
          cellStyle: {
            textAlign: "center"
          },
          cellClassRules: {}
        },
        {
          field: "statCd",
          headerName: this.i18n("label.statCd"),
          type: String,
          width: 100,
          isPk: false,
          editable: false,
          pinned: false,
          suppressMenu: false,
          sortable: true,
          cellStyle: {
            textAlign: "center"
          },
          cellClassRules: {},
          valueFormatter: param => param.value && lookupValue(this.cdList.stckStatCdList, param.value)
        },
        {
          field: "addedDate",
          headerName: this.i18n("label.addedDate"),
          type: String,
          width: 130,
          isPk: false,
          editable: false,
          pinned: false,
          suppressMenu: false,
          sortable: true,
          cellStyle: {
            textAlign: "center"
          },
          cellClassRules: {},
          valueFormatter: param => this.i18nDtTmFormat(param.value)
        },
        {
          field: "restCell",
          headerName: "",
          width: 0,
          type: String,
          isPk: false,
          editable: false,
          pinned: false,
          suppressMenu: false,
          sortable: false,
          cellStyle: {},
          cellClassRules: {}
        }
      ];
    }
  }
};
