/**
 * 1. 개요 : 쿠폰배분관리번호검색
 * 2. 처리내용 : 쿠폰배분관리번호검색 - 이벤트 버스
 * @File Name : EventBus.js
 * @date : 2022. 08. 04. 17:05:00
 * @author : 이진우
 * @history :
 *-----------------------------------------------------------------------
 * 변경일			          		  작성자		변경내용
 *-----------------------------------------------------------------------
 * 2022. 08. 04. 17:05:00	    이진우	  최초 작성
 *-----------------------------------------------------------------------
 */
import Vue from "vue";

export const EventBus = new Vue();

export default {
  EventBus
};
