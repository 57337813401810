/**
 * 1. 개요 : Navbar
 * 2. 처리내용 : Navbar - 진입점
 * @File Name : index.vue
 * @date : 2020. 02. 20. 09:46:00
 * @author : 이상철
 * @history :
 *-----------------------------------------------------------------------
 * 변경일			          		  작성자		변경내용
 *-----------------------------------------------------------------------
 * 2020. 2. 20. 09:46:00	    이상철	  최초 작성
 *-----------------------------------------------------------------------
 */
<template>
	<div class="navbar flex-btw-center">
		<logo v-if="showLogo" />
		<div class="right flex-center">
			<a href="javascript:;" class="logout" ref="logout" @click.stop="onLogout">
				{{
				"label.logout" | i18n
				}}
			</a>
			<div class="btn-pw-change">
				<button type="button" @click.stop="showChangePasswordPopup = true">{{ "label.pwdChg" | i18n }}</button>
			</div>
			<lang-change />
		</div>
		<change-password-popup
			v-if="showChangePasswordPopup"
			@close="showChangePasswordPopup = false"
			:chgPassOrgType="'LOG_IN'"
		/>
	</div>
</template>

<script>
import { mapState } from "vuex";
import Logo from "./Logo";
import LangChange from "./LangChange";
import ChangePasswordPopup from "@/views/login/popup/ChangePasswordPopup";

export default {
	name: "Navbar",
	components: {
		Logo,
		LangChange,
		ChangePasswordPopup
	},
	data() {
		return {
			shownTopNotifyPopup: false,
			shownUserInfoPopup: false,
			shownPassChangePopup: false,
			shownLangChangePopup: false,
			showChangePasswordPopup: false
		};
	},
	computed: {
		...mapState({
			sidebar: state => state.Sidebar.sidebar,
			needTagsView: state => state.Settings.tagsView,
			fixedHeader: state => state.Settings.fixedHeader
		}),
		userInfo() {
			return this.$store.getters["Options/userInfo"];
		},
		showLogo() {
			return this.$store.state.Settings.sidebarLogo;
		}
	},
	methods: {
		onLogout() {
			this.$nextTick(() => this.$refs.logout.blur());
			this.alert({
				type: "warning",
				title: this.i18n("label.logout"),
				message: `${this.i18n("label.logout")} ${this.i18n("alt.question")}`,
				buttons: [
					{
						label: this.i18n("button.logout"),
						type: "default",
						onPress: () => this.logoutWithServer()
					}
				]
			});
		},
		handleCommand(cmd) {
			if (cmd === "1") {
				this.shownUserInfoPopup = true;
			} else if (cmd === "2") {
				this.shownPassChangePopup = true;
			} else if (cmd === "3") {
				this.shownLangChangePopup = true;
			}
		}
	}
};
</script>

<style lang="scss" scoped>
@import "@/scss/_color.scss";

.navbar {
	position: relative;
	height: 40px;
	padding: 0 30px 0 20px;
	background: #fff;
	box-shadow: 0 1px 4px 0 $rgba25;
	z-index: 10;
	.right {
		// width:292px;
		justify-content: flex-end;
		.logout {
			margin: 3px 10px 0 0;
			font-size: 11px;
			color: $c4;
			font-weight: bold;
			letter-spacing: -0.6px;
		}
	}
	.btn-pw-change {
		position: relative;
		display: inline-block;
		margin: 3px 20px 0 10px;
		font-size: 11px;
		vertical-align: middle;
		&::before {
			content: "";
			position: absolute;
			left: -10px;
			display: inline-block;
			height: 11px;
			border-left: solid 1px #aaa;
			outline-style: none;
		}
	}
}
</style>
