import { isEmpty } from "@/utils/index";

/**
 * 1. 개요 : Q&amp;A관리
 * 2. 처리내용 : Q&amp;A관리 Mixins - 파일 관련 helper
 * @File Name : FileUpload.js
 * @date : 2020. 04. 16.  05:25:19
 * @author : 김경식
 * @history :
 *-----------------------------------------------------------------------
 * 변경일			          		    작성자		변경내용
 *-----------------------------------------------------------------------
 * 2020. 04. 16.  05:25:19	    김경식	  최초 작성
 *-----------------------------------------------------------------------
 */
export default {
  data() {
    return {
      fileUpload: {
        tblNm: 'axc_bbs',
        acceptExtention: ['.doc', '.docx', '.xls', '.xlsx', '.ppt', '.pptx', '.pdf', '.txt', '.csv', '.jpg', '.jpeg', '.gif', '.png'],
        fileList: [],
        orgFileList: [],
        rplyFileList:[],
        files: [],
        uploadMaxCnt: 5
      },
    }
  },
  methods: {
    /**
     * 파일 Validation
     */
    validCheckFile() {
      if (this.fileUpload.fileList.length + 1 > this.fileUpload.uploadMaxCnt) {
        this.alert({
          type: 'warning',
          title: this.i18n('label.error'),
          message: this.i18n('alt.attatchGuide1'),
        });

        return false;
      }

      return Object.entries(this.fileUpload.files).every(([key]) => {
        if (this.fileUpload.files[key].size > 1024 * 1024 * 10) {
          this.alert({
            type: 'warning',
            title: this.i18n('label.error'),
            message: this.i18n('alt.attatchGuide2', 10),
          });

          return false;
        }
        return true;
      });
    },
    /**
     * 업로드 예정 파일들
     */
     uploadFile() {
      this.fileUpload.files = this.$refs.file.files;

      if (!this.validCheckFile()) {
        return;
      }

      const formData = new FormData();

      if (this.fileUpload.files) {
        for (let i = 0; i < this.fileUpload.files.length; i += 1) {
          // 확장자 를 모두 소문자로 바꿈
          const fileName =
            `${this.fileUpload.files[i].name.split('.')[0]}.${this.fileUpload.files[i].name.split('.')[1].toLowerCase()}`
            
          formData.append('uploadFile', this.fileUpload.files[i], fileName);
        }
      }

      if( this.complaintMngVO.atchFileFkId != undefined ) {
        formData.append('atchFileFkId', this.complaintMngVO.atchFileFkId);
      }

      formData.append('msgLangCd', this.msgLangCd);
      formData.append('tblNm', this.fileUpload.tblNm);

      this.$httpPost('/api/com/uploadFile.do', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        showLoading: true,
      }).then((res) => {
        this.complaintMngVO.atchFileFkId = res.data.resultList.atchFileFkId;
        this.getFileList(this.complaintMngVO.atchFileFkId);
      })
      .catch(this.commonErrorHandler)
      .finally(() => {
        this.$refs.file.value = null;
      });
    },
    uploadFile1() {
      this.fileUpload.files = this.$refs.file1[0].files;

      if (!this.validCheckFile()) {
        return;
      }

      const formData = new FormData();

      if (this.fileUpload.files) {
        for (let i = 0; i < this.fileUpload.files.length; i += 1) {
          // 확장자 를 모두 소문자로 바꿈
          const fileName =
            `${this.fileUpload.files[i].name.split('.')[0]}.${this.fileUpload.files[i].name.split('.')[1].toLowerCase()}`
            
          formData.append('uploadFile', this.fileUpload.files[i], fileName);
        }
      }

      if( this.complaintMngVO.atchFileFkId != undefined ) {
        formData.append('atchFileFkId', this.complaintMngVO.atchFileFkId);
      }

      formData.append('msgLangCd', this.msgLangCd);
      formData.append('tblNm', this.fileUpload.tblNm);

      this.$httpPost('/api/com/uploadFile.do', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        showLoading: true,
      }).then((res) => {
        this.complaintMngVO.atchFileFkId = res.data.resultList.atchFileFkId;
        this.getFileList(this.complaintMngVO.atchFileFkId);
      })
      .catch(this.commonErrorHandler)
      .finally(() => {
        this.$refs.file1[0].value = null;
      });
    },
    uploadRplyFile(){
      this.fileUpload.files = this.$refs.rplyFile.files;

      if (!this.validCheckFile()) {
        return;
      }

      const formData = new FormData();

      if (this.fileUpload.files) {
        for (let i = 0; i < this.fileUpload.files.length; i += 1) {
          // 확장자 를 모두 소문자로 바꿈
          const fileName =
            `${this.fileUpload.files[i].name.split('.')[0]}.${this.fileUpload.files[i].name.split('.')[1].toLowerCase()}`
            
          formData.append('uploadFile', this.fileUpload.files[i], fileName);
        }
      }

      if( this.complaintMngVO.atchFileFkId != undefined ) {
        formData.append('atchFileFkId', this.complaintMngVO.atchFileFkId);
      }
      
      formData.append('msgLangCd', this.msgLangCd);
      formData.append('tblNm', this.fileUpload.tblNm);

      this.$httpPost('/api/com/uploadFile.do', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        showLoading: true,
      }).then((res) => {
        
        this.complaintMngVO.atchFileFkId = res.data.resultList.atchFileFkId;
        this.getRplyFileList(this.complaintMngVO.atchFileFkId);
      })
      .catch(this.commonErrorHandler)
      .finally(() => {
        this.$refs.file.value = null;
      });
    },
    uploadOrgFile(){
      this.fileUpload.files = this.$refs.orgFile[0].files;

      if (!this.validCheckFile()) {
        return;
      }

      const formData = new FormData();

      if (this.fileUpload.files) {
        for (let i = 0; i < this.fileUpload.files.length; i += 1) {
          // 확장자 를 모두 소문자로 바꿈
          const fileName =
            `${this.fileUpload.files[i].name.split('.')[0]}.${this.fileUpload.files[i].name.split('.')[1].toLowerCase()}`
            
          formData.append('uploadFile', this.fileUpload.files[i], fileName);
        }
      }

      if( this.orgComplaintMngVO.atchFileFkId != undefined ) {
        formData.append('atchFileFkId', this.orgComplaintMngVO.atchFileFkId);
      }
      
      formData.append('msgLangCd', this.msgLangCd);
      formData.append('tblNm', this.fileUpload.tblNm);

      this.$httpPost('/api/com/uploadFile.do', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        showLoading: true,
      }).then((res) => {
        
        this.orgComplaintMngVO.atchFileFkId = res.data.resultList.atchFileFkId;
        this.getOrgFileList(this.orgComplaintMngVO.atchFileFkId);
      })
      .catch(this.commonErrorHandler)
      .finally(() => {
        this.$refs.orgFile[0].value = null;
      });
    },
    /**
     * 파일 추가
     */
    fileAdd() {
      this.$refs.file.click();
    },
    rplyFileAdd(){
      this.$refs.rplyFile.click();
    },
    orgFileAdd(){
      this.$refs.orgFile[0].click();
    },
    fileAdd1(){
      this.$refs.file1[0].click();
    },
    /**
     * 파일리스트 조회
     */
    getFileList(searchAtchFileFkId) {
      this.$httpPost('/api/com/getFileList.do', {
        searchAtchFileFkId
      }, {
        showLoading: true
      }).then((res) => {
        this.fileUpload.fileList = res.data.resultList;
      })
      .catch(this.commonErrorHandler);
    },
    getRplyFileList(searchAtchFileFkId) {
      this.$httpPost('/api/com/getFileList.do', {
        searchAtchFileFkId
      }, {
        showLoading: true
      }).then((res) => {
        this.fileUpload.rplyFileList = res.data.resultList;
      })
      .catch(this.commonErrorHandler);
    },
    getOrgFileList(searchAtchFileFkId) {
      this.$httpPost('/api/com/getFileList.do', {
        searchAtchFileFkId
      }, {
        showLoading: true
      }).then((res) => {
        this.fileUpload.orgFileList = res.data.resultList;
      })
      .catch(this.commonErrorHandler);
    },
    /**
     * 파일 삭제 확인
     */
    deleteFile(file) {
      this.alert({
        title: this.i18n('label.del'),
        message: this.i18n('alt.questionDel'),
        buttons: [{
          label: this.i18n('button.confirm'),
          type: 'primary',
          onPress: () => { this.deleteFileProcess(file); },
        }, {
          label: this.i18n('button.cancel'),
        }],
      });
    },
    /**
     *파일 삭제
      */
    deleteFileProcess(file) {
      // let tblNm = '';
      if (this.fileUpload.fileList.length <= 1) {
        this.qnaMngVO.atchFileFkId = "";
      }
      this.$httpPost('/api/com/deleteFile.do', {
        atchFileFkId: file.atchFileFkId,
        fileSeqNo: file.fileSeqNo,
        storedFileNm: file.storedFileNm,
        tblNm: this.fileUpload.tblNm || ''
      }, {
        showLoading: true
      }).then(() => {
        this.getFileList(this.qnaMngVO.atchFileFkId);
      })
      .catch(this.commonErrorHandler);
    },
    /**
     * 파일 다운로드
     */
    downloadFile(file) {
      if(file.atch_file_fk_id != undefined && !isEmpty(file.atch_file_fk_id)){
        file.atchFileFkId = file.atch_file_fk_id;
      }
      if(file.file_seq_no != undefined && !isEmpty(file.file_seq_no)){
        file.fileSeqNo = file.file_seq_no;
      }
      if(file.orginl_file_nm != undefined && !isEmpty(file.orginl_file_nm)){
        file.orginlFileNm = file.orginl_file_nm;
      }
      this.$httpPost('/api/com/downloadFile.do', {
        searchAtchFileFkId: file.atchFileFkId,
        searchFileSeqNo: file.fileSeqNo,
      }, {
        responseType: 'blob'
      }).then((res) => {
        if (navigator.msSaveBlob) {
          const blob = new Blob([res.data], { type: 'application/octet-stream;charset=utf-8;' });
          navigator.msSaveBlob(blob, `${file.orginlFileNm}`);
        } else if (navigator.appName === 'Microsoft Internet Explorer') {
          const frame = document.createElement('iframe');
          document.body.appendChild(frame);

          frame.contentWindow.document.open('application/octet-stream;charset=utf-8;', 'replace');
          frame.contentWindow.document.write(`sep=,\r\n${res.data}`);
          frame.contentWindow.document.close();
          frame.contentWindow.focus();
          frame.contentWindow.document.execCommand('SaveAs', false, `${file.orginlFileNm}`);

          document.body.removeChild(frame);
        } else {
          const blob = new Blob([res.data], { type: 'application/octet-stream;charset=utf-8;' });
          const link = document.createElement('a');
          // feature detection
          if (link.download !== undefined) {
            // Browsers that support HTML5 download attribute
            const url = URL.createObjectURL(blob);
            link.setAttribute('href', url);
            link.setAttribute('download', `${file.orginlFileNm}`);
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          }
        }
      })
      .catch(this.commonErrorHandler);
    },
  }
}