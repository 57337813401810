/**
 * 1. 개요 : 이벤트정책검색
 * 2. 처리내용 : 이벤트정책검색 Mixins - 화면 모델
 * @File Name : CmnEvtPlcyPopupViewModel.js
 * @date : 2022. 11. 21.  14:55:00
 * @author : 김준석
 * @history :
 *-----------------------------------------------------------------------
 * 변경일			          		    작성자		변경내용
 *-----------------------------------------------------------------------
 * 2022. 11. 21.  14:55:00	    김준석	  최초 작성
 *-----------------------------------------------------------------------
 */

import { dateTimeFormatter } from "@/utils/formatter";

export default {
  methods: {
    CmnEvtPlcyPopupViewModel(params = {}) {
      return [
        {
          field: "rnum",
          headerName: "",
          type: Number,
          width: 50,
          isPk: false,
          editable: false,
          pinned: false,
          cellStyle: {
            textAlign: "center"
          },
          cellClassRules: {
            "selected-cell": param =>
              params && param.rowIndex === params.rowIndex
          },
          valueFormatter : (param) => {
            // 내림차순
            return this.gridProps.totalItems - (((this.currPage - 1) * this.pageUnit) + param.node.rowIndex);
          }, 
        },
        {
          field: "plcyClCdNm",
          headerName: this.i18n("label.evtPlcyCl"),
          type: String,
          width: 140,
          isPk: false,
          editable: false,
          pinned: false,
          suppressMenu: false,
          sortable: true,
          cellStyle: {
            textAlign: "center"
          },
          cellClassRules: {}
        },
        {
          field: "cpnPlcyMngNo",
          headerName: this.i18n("label.evtPlcyNo"),
          type: String,
          width: 140,
          isPk: false,
          editable: false,
          pinned: false,
          suppressMenu: false,
          sortable: true,
          cellStyle: {
            //  modify by jwlee 20221004 : 셀 내 정렬
            textAlign: "center"
          },
          cellClassRules: {}
        },
        {
          field: "cpnPlcyNm",
          headerName: this.i18n("label.evtPlcyNm"),
          type: String,
          width: 240,
          isPk: false,
          editable: false,
          pinned: false,
          suppressMenu: false,
          sortable: true,
          cellStyle: {},
          cellClassRules: {}
        },
        {
          field: "cpnIssPerdStaDtm",
          headerName: this.i18n("label.evt")+this.i18n("label.issue")+this.i18n("label.sta")+this.i18n("label.dtm"),
          type: String,
          width: 210,
          isPk: false,
          editable: false,
          pinned: false,
          suppressMenu: false,
          sortable: true,
          cellStyle: {
            //  modify by jwlee 20221004 : 셀 내 정렬
            textAlign: "center"
          },
          cellClassRules: {}
        },
        {
          field: "cpnIssPerdEndDtm",
          headerName: this.i18n("label.evt")+this.i18n("label.issue")+this.i18n("label.end")+this.i18n("label.dtm"),
          type: String,
          width: 210,
          isPk: false,
          editable: false,
          pinned: false,
          suppressMenu: false,
          sortable: true,
          cellStyle: {
            //  modify by jwlee 20221004 : 셀 내 정렬
            textAlign: "center"
          },
          cellClassRules: {}
        },
        // {
        //   field: "currCd",
        //   headerName: this.i18n("label.currCd"),
        //   type: String,
        //   width: 125,
        //   isPk: false,
        //   editable: false,
        //   pinned: false,
        //   suppressMenu: false,
        //   sortable: true,
        //   cellStyle: {
        //     //  modify by jwlee 20221004 : 셀 내 정렬
        //     textAlign: "center"
        //   },
        //   cellClassRules: {}
        // },
        {
          field: "issStpYn",
          headerName: this.i18n("label.iss")+this.i18n("label.stp")+this.i18n("label.yn"),
          type: String,
          width: 125,
          isPk: false,
          editable: false,
          pinned: false,
          suppressMenu: false,
          sortable: true,
          cellStyle: {
            //  modify by jwlee 20221004 : 셀 내 정렬
            textAlign: "center"
          },
          cellClassRules: {}
        },

        {
          field: "dcCl",
          headerName: this.i18n("label.dc")+this.i18n("label.cl"),
          type: String,
          width: 110,
          isPk: false,
          editable: false,
          pinned: false,
          suppressMenu: false,
          sortable: true,
          cellStyle: {
            //  modify by jwlee 20221004 : 셀 내 정렬
            textAlign: "center"
          },
          cellClassRules: {}
        },
        {
          field: "giftTaxYn",
          headerName: this.i18n("label.giftTxaYn"),
          type: String,
          width: 135,
          isPk: false,
          editable: false,
          pinned: false,
          suppressMenu: false,
          sortable: true,
          cellStyle: {
            //  modify by jwlee 20221004 : 셀 내 정렬
            textAlign: "center"
          },
          cellClassRules: {}
        },

        {
          field: "giftTaxRate",
          headerName: this.i18n("label.giftTaxRate"),
          type: String,
          width: 125,
          isPk: false,
          editable: false,
          pinned: false,
          suppressMenu: false,
          sortable: true,
          cellStyle: {
            textAlign: "right"
          },
          cellClassRules: {}
        },
      ];
    }
  }
};
