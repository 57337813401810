/**
 * 1. 개요 : 시뮬레이션속성정의검색
 * 2. 처리내용 : 시뮬레이션속성정의검색 Mixins - 화면 모델
 * @File Name : CmnSmltDefPopupViewModel.js
 * @date : 2020. 04. 07. 13:00:00
 * @author : 이현우
 * @history :
 *-----------------------------------------------------------------------
 * 변경일			          		  작성자		변경내용
 *-----------------------------------------------------------------------
 * 2020. 04. 07. 13:00:00	    이현우	  최초 작성
 *-----------------------------------------------------------------------
 */
import { lookupValue } from "@/utils/dataGrid";
import { phoneFormatter } from "@/utils/formatter";

export default {
  methods: {
    cmnSmltDefPopupViewModel(params = {}) {
      return [
        {
          field: "rnum",
          headerName: "",
          type: Number,
          width: 50,
          isPk: false,
          editable: false,
          pinned: true,
          sortable: true,
          cellStyle: {
            textAlign: 'center'
          },
          cellClassRules: {
            'selected-cell': param => params && param.rowIndex === params.rowIndex
          },
          valueFormatter : (param) => {
            // 오름차순
            // return (((this.currPage - 1) * this.pageUnit) + param.node.rowIndex) + 1;

            // 내림차순
            return this.gridProps.totalItems - (((this.currPage - 1) * this.pageUnit) + param.node.rowIndex);
          },
        },
        {
          field: "mainClNm",
          headerName: this.i18n("label.mainClNm"),
          type: String,
          width: 200,
          isPk: false,
          editable: false,
          pinned: false,
          suppressMenu: false,
          sortable: true,
          cellStyle: {
            textAlign: "center"
          },
          cellClassRules: {}
        },
        {
          field: "midClNm",
          headerName: this.i18n("label.midClNm"),
          type: String,
          width: 200,
          isPk: false,
          editable: false,
          pinned: false,
          suppressMenu: false,
          sortable: true,
          cellStyle: {
            textAlign: "center"
          },
          cellClassRules: {}
        },
        {
          field: "subClNm",
          headerName: this.i18n("label.subClNm"),
          type: String,
          width: 200,
          isPk: false,
          editable: false,
          pinned: false,
          suppressMenu: false,
          sortable: true,
          cellStyle: {
            textAlign: "center"
          },
          cellClassRules: {}
        },
        {
          field: "hndlClNm",
          headerName: this.i18n("label.hndlCl"),
          type: String,
          width: 200,
          isPk: false,
          editable: false,
          pinned: false,
          suppressMenu: false,
          sortable: true,
          cellStyle: {
            textAlign: "center"
          },
          cellClassRules: {}
        },
        {
          field: "dscr",
          headerName: this.i18n("label.dscr"),
          type: String,
          width: 400,
          isPk: false,
          editable: false,
          pinned: false,
          suppressMenu: false,
          sortable: true,
          cellStyle: {},
          cellClassRules: {}
        },
        {
          field: "addedDate",
          headerName: this.i18n("label.addedDate"),
          type: String,
          width: 140,
          isPk: false,
          editable: false,
          pinned: false,
          suppressMenu: false,
          sortable: true,
          cellStyle: {
            textAlign: "center"
          },
          cellClassRules: {},
          valueFormatter: param => this.i18nDtTmFormat(param.value)
        },
        {
          field: "restCell",
          headerName: "",
          width: 0,
          type: String,
          isPk: false,
          editable: false,
          pinned: false,
          suppressMenu: false,
          sortable: false,
          cellStyle: {},
          cellClassRules: {}
        }
      ];
    }
  }
};
