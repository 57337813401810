/**
 * 1. 개요 : 코드가져오기
 * 2. 처리내용 : Mixins - 코드 fetch helper
 * @File Name : CodeFetchHelper.js
 * @date : 2020. 05. 07. 09:46:00
 * @author : 김영노
 * @history :
 *-----------------------------------------------------------------------
 * 변경일			          		  작성자		변경내용
 *-----------------------------------------------------------------------
 * 2020. 5. 07. 09:46:00	    김영노	  최초 작성
 *-----------------------------------------------------------------------
 */
import { isEmpty } from '@/utils'

export default {
  data() {
    return {
      cdList: {
        instStatCdList : [],
        ynCdList : [],
        instTypCdList : [],
        currCdList : [],
        runDataCdList : [],
        endTypCdList:[]
      }
    }
  },
  created() {
    this.fnGetAxcCodeGrpList(["INST_STAT_CD","YN_CD","INST_TYP_CD","CURR_CD","RUN_DATA_CD","END_TYP_CD"])
  },
  methods: {
    /**
     * 업무 코드 리스트 페치
     * @params {Array} searchCodeGrpIdList
     */
		async fnGetAxcCodeGrpList(searchCodeGrpIdList = []) {
      if(isEmpty(searchCodeGrpIdList)) return;

			await this.$httpPost(
					"/api/bep/getAxcCodeGroupListByCodeGrpIdList.do",
					{ searchCodeGrpIdList },
					{ showLoading: false }
				)
				.then(response => {
          this.cdList.instStatCdList = response.data.resultList.find(a => a[0].codeGrpId === "INST_STAT_CD");
          this.cdList.ynCdList = response.data.resultList.find(a => a[0].codeGrpId === "YN_CD");
          this.cdList.instTypCdList = response.data.resultList.find(a => a[0].codeGrpId === "INST_TYP_CD");
          this.cdList.currCdList = response.data.resultList.find(a => a[0].codeGrpId === "CURR_CD");
          this.cdList.runDataCdList = response.data.resultList.find(a => a[0].codeGrpId === "RUN_DATA_CD");
          this.cdList.endTypCdList = response.data.resultList.find(a => a[0].codeGrpId === "END_TYP_CD");
        })
        .catch(this.commonErrorHandler)
    },
  }
}