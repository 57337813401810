/**
 * 1. 개요 : 공통
 * 2. 처리내용 : 공통 - methods 영역
 * @File Name : methods.js
 * @date : 2020. 02. 20. 09:46:00
 * @author : 이상철
 * @history :
 *-----------------------------------------------------------------------
 * 변경일			          		  작성자		변경내용
 *-----------------------------------------------------------------------
 * 2020. 2. 20. 09:46:00	    이상철	  최초 작성
 *-----------------------------------------------------------------------
 */
import { mapActions, mapMutations } from "vuex";
import _ from "lodash";
import moment from "moment";
import { decodeToHtml } from "@/utils";
import { isEmpty } from "@/utils";

export default {
  ...mapActions("Alert", ["alert"]),
  ...mapMutations("Options", ["setUserInfo", "clearUserInfo"]),
  /**
   * 로그아웃(서버 로그아웃)
   */
  logoutWithServer() {
    this.$httpPost("/api/com/logout.do", {}, { showLoading: false })
      .then(() => this.logout())
      .catch(this.commonErrorHandler);
  },
  /**
   * 로그아웃(서버 로그아웃)
   */
  logoutWithServerNotToken() {
    const userInfo = this.$store.getters["Options/userInfo"];
    this.logout();

    this.$httpAnonymPost(
      "/api/com/logoutWithoutToken.do",
      {
        userId: userInfo.userId,
        msgLangCd: userInfo.msgLangCd
      },
      { showLoading: false }
    ).catch(this.commonErrorHandler);
  },
  /**
   * 로그아웃
   */
  logout() {
    this.$store.commit("Options/CLEAR_USER_INFO");
    this.$cookie.delete("spaToken");
    console.log("### logout ###");

    if (this.$store.getters["Device/getDeviceCd"] === "D") {
      this.$router.replace("/login");
    } else if (this.$store.getters["Device/getDeviceCd"] === "M") {
      this.$router.replace("/m/login");
    }
  },
  /**
   * 언어팩
   * @params {String} messageId
   * @params {} ...values
   * @return {String}
   */
  i18n(messageId, ...values) {
    const message = this.$store.getters["I18n/getMessageById"](messageId);

    if (message) {
      const text = (() => {
        switch (this.msgLangCd) {
          case "en_US":
            return message.msgEnText;
          case "vi_VN":
            return message.msgViText;
          case "ko_KR":
            return message.msgKoText; 
          case "jp_JP":
            return message.msgJpText;
          default:
            return message.msgEnText;
        }
      })();

      return text.replace(
        /{{\$(\d*)}}/g,
        (match, index) => values[index - 1] || match
      );
    }
    return decodeToHtml(messageId);
  },
  /**
   * 로그아웃일 경우의 언어팩
   * @params {String} title
   * return {String}
   */
  i18nOff(title) {
    return this[title][this.browserLang];
  },
  /**
   * 로그아웃일 경우의 언어팩
   * @params {String} title
   * return {String}
   */
  i18nOffFrm(title) {
    const msgLangCd = this.isLogin ? this.msgLangCd : this.browserLang;

    return this[title][msgLangCd];
  },
  /**
   * 언어에 따른 "YYYY.MM" 날짜 포매터
   * @params {String} date
   * @return {Object}
   */
  i18nDtFormatForYearAndMonth(date) {
    return (() => {
      switch (this.msgLangCd) {
        case "en_US":
          return moment(date, "YYYYMM").format("MM/YYYY");
        case "vi_VN":
          return moment(date, "YYYYMM").format("MM-YYYY");
        case "ko_KR":
          return moment(date, "YYYYMM").format("YYYY.MM");
        default:
          return moment(date, "YYYYMM").format("MM/YYYY");
      }
    })();
  },
  /**
   * 언어에 따른 "YYYY.MM.DD" 날짜 포매터
   * @params {String} date
   * @return {Object}
   */
  i18nDtFormat(date) {
    return (() => {
      switch (this.msgLangCd) {
        case "en_US":
          return moment(date, "YYYYMMDD").format("MM/DD/YYYY");
        case "vi_VN":
          return moment(date, "YYYYMMDD").format("MM-DD-YYYY");
        case "ko_KR":
          return moment(date, "YYYYMMDD").format("YYYY.MM.DD");
        default:
          return moment(date, "YYYYMMDD").format("MM/DD/YYYY");
      }
    })();
  },
  /**
   * 언어에 따른 "YYYY.MM.DD HH:mm:ss" 날짜 + 시간 포매터
   * @params {String} date
   * @return {Object}
   */
  i18nDtTmFormat(date) {
    return (() => {
      switch (this.msgLangCd) {
        case "en_US":
          return moment(date, "YYYYMMDDhhmmss").format("MM/DD/YYYY HH:mm:ss");
        case "vi_VN":
          return moment(date, "YYYYMMDDhhmmss").format("MM-DD-YYYY HH:mm:ss");
        case "ko_KR":
          return moment(date, "YYYYMMDDhhmmss").format("YYYY.MM.DD HH:mm:ss");
        default:
          return moment(date, "YYYYMMDDhhmmss").format("MM/DD/YYYY HH:mm:ss");
      }
    })();
  },
  /**
   * 언어에 따른 "HH:mm:ss" 시간 포매터
   * @params {String} date
   * @retrun {Object}
   */
  i18nTmFormat(date) {
    return (() => {
      switch (this.msgLangCd) {
        case "en_US":
          return moment(date, "hhmmss").format("HH:mm:ss");
        case "vi_VN":
          return moment(date, "hhmmss").format("HH:mm:ss");
        case "ko_KR":
          return moment(date, "hhmmss").format("HH:mm:ss");
        default:
          return moment(date, "hhmmss").format("HH:mm:ss");
      }
    })();
  },
  // add by jwlee 20221228 시작 : 월별 연별 포매팅 추가
  /**
   * 언어에 따른 "YYYY.MM" 날짜 포매터
   * @params {String} date
   * @return {Object}
   */
  i18nDtMonFormat(date) {
    return (() => {
      switch (this.msgLangCd) {
        case "en_US":
          return moment(date, "YYYYMM").format("MM/YYYY");
        case "vi_VN":
          return moment(date, "YYYYMM").format("MM-YYYY");
        case "ko_KR":
          return moment(date, "YYYYMM").format("YYYY.MM");
        default:
          return moment(date, "YYYYMM").format("MM/YYYY");
      }
    })();
  },
  /**
   * 언어에 따른 "YYYY" 날짜 포매터
   * @params {String} date
   * @return {Object}
   */
  i18nDtYearFormat(date) {
    return moment(date, "YYYY").format("YYYY");
  },
  // add by jwlee 20221228 끝 : 월별 연별 포매팅 추가
  /**
   * 넘버 포매터
   * @params {Any} value
   * @return {Number}
   */
  numberFormat(value) {
    if (typeof value !== "number") return value;
    return value.toFixed(0).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,");
  },

  /**
   * CSV 출력
   * @param {Array} dataList 데이터
   * @param {string} fileName 저장 파일명
   * @param {string} delimeter 구분자
   * @param {object} title 타이틀셋 { [column1]:[title1], [column2]:[title2] }
   */
  csvExport(dataList, title = {}, { fileName = "export", delimeter = "," }) {
    /** 데이터를 정해진 타이틀로 재조합 */
    const csvArray = [];
    dataList.forEach(item1 => {
      const csvObj = {};
      Object.entries(item1).forEach(item2 => {
        const [key, value] = item2;
        if (Object.keys(title).indexOf(key) > -1) {
          csvObj[title[key]] = _.isObject(value) ? value.value : value;
          csvObj[title[key]] = _.isNumber(csvObj[title[key]])
            ? `"${csvObj[title[key]]}"`
            : `"${(csvObj[title[key]] || "").toString().replace(/\\"/g, "'")}"`;
        }
      });
      csvArray.push(csvObj);
    });

    const csvData = [
      Object.keys(csvArray[0]).join(delimeter),
      ...csvArray.map(item => Object.values(item).join(delimeter))
    ]
      .join("\n")
      .replace(/(^\[)|(\]$)/gm, "");

    // IE 10+
    if (navigator.msSaveBlob) {
      const blob = new Blob(["\uFEFF", csvData], {
        type: "text/csv;charset=utf-8;"
      });
      navigator.msSaveBlob(blob, `${fileName}.csv`);
    } else if (navigator.appName === "Microsoft Internet Explorer") {
      const frame = document.createElement("iframe");
      document.body.appendChild(frame);

      frame.contentWindow.document.open("text/csv;charset=utf-8;", "replace");
      frame.contentWindow.document.write(`sep=,\r\n${csvData}`);
      frame.contentWindow.document.close();
      frame.contentWindow.focus();
      frame.contentWindow.document.execCommand(
        "SaveAs",
        false,
        `${fileName}.csv`
      );

      document.body.removeChild(frame);
    } else {
      const blob = new Blob(["\uFEFF", csvData], {
        type: "text/csv;charset=utf-8;"
      });
      const link = document.createElement("a");
      // feature detection
      if (link.download !== undefined) {
        // Browsers that support HTML5 download attribute
        const url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", `${fileName}.csv`);
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  },
  /**
   * 디바이스 셋
   * @params {String} deviceCd
   */
  setDeviceCd(deviceCd) {
    window.localStorage.setItem("deviceCd", deviceCd);
    this.$store.dispatch("Device/setDeviceCd", deviceCd);
  },
  /**
   * 디바이스 겟
   * @return {String}
   */
  getDeviceCd() {
    return window.localStorage.getItem("deviceCd");
  },
  /**
   * 네트워크 오류
   * @params {Object} error
   */
  commonErrorHandler(error) {
    if (!!error.isAxiosError && !error.response) {
      this.alert({
        type: "warning",
        title: `${
          this.isLogin
            ? this.i18n("label.networkError")
            : this.i18nOff("label.networkError")
        }`,
        message: `${
          this.isLogin
            ? this.i18n("alt.networkError")
            : this.i18nOff("alt.networkError")
        }`,
        buttons: [
          {
            type: "default",
            label: this.isLogin
              ? this.i18n("button.confirm")
              : this.i18nOff("button.confirm")
          }
        ]
      });
      return;
    }

    if (error.response && error.response.data) {
      const status = error.response.status;
      const orgMessage = error.response.data.resultMessage;
      let message = error.response.data.resultMessage;
      const resultCode = error.response.data.resultCode;
      // Page Not Found: 서버 주소가 틀렸으면 404페이지로 라우팅 한다.
      if (status === 404) {
        console.log("### 404 ###");
        this.$router.replace({ path: "/404" });
        return;
      }

      // 사용자가 이미 로그아웃 되어 있을때 로그인 화면으로 이동
      if (!isEmpty(message) && message.includes("err.sys.user.025")) {
        message = message.replaceAll(/err.sys.user.025/gi, "");
      }

      // 다른 PC에서 로그인이 확인 되었을때 로그인 화면으로 이동
      if (!isEmpty(message) && message.includes("err.sys.user.016")) {
        if (this.$store.getters["OrtherPcLoginAlert/getIsOrtherPcLoginAlert"]) {
          return;
        }
        message = message.replaceAll(/err.sys.user.016/gi, "");
        this.$store.commit("OrtherPcLoginAlert/SET_ORTHER_PC_LOGIN_ALERT_TRUE");

        this.alert({
          type: "warning",
          title: `${
            this.isLogin
              ? this.i18n("label.confirm")
              : this.i18nOff("label.confirm")
          }`,
          message,
          buttons: [
            {
              label: this.isLogin
                ? this.i18n("button.confirm")
                : this.i18nOff("button.confirm"),
              onPress: () => {
                this.logout();
                this.$store.commit(
                  "OrtherPcLoginAlert/SET_ORTHER_PC_LOGIN_ALERT_FALSE"
                );
              }
            }
          ]
        });

        setTimeout(
          () =>
            this.$store.commit(
              "OrtherPcLoginAlert/SET_ORTHER_PC_LOGIN_ALERT_FALSE"
            ),
          2000
        );

        return;
      }

      if (status === 401 || status === 406 || status === 403) {
        // 일정시간이 지난뒤 자동로그아웃이 되었을 경우, 중복 alert 방지를 위한 코드: 더이상 alert 이 나오지 않음
        if (this.$store.getters["TimeoutAlert/getIsTimeoutAlert"]) {
          return;
        }

        if (!isEmpty(message) && message.includes("err.sys.auth.001")) {
          this.$store.commit("TimeoutAlert/SET_TIMEOUT_ALERT_TRUE");
          message = message.replaceAll(/err.sys.auth.001/gi, "");
        }

        // 세션이 종료되었을 경우, 중복 alert 방지를 위한 코드: 더이상 alert 이 나오지 않음
        if (!isEmpty(message) && resultCode === -406) {
          if (this.isFrmError(orgMessage)) {
            message = this.getFrmErrorMessage(orgMessage);
          }

          if (this.$store.getters["AccessDeniedAlert/getIsAccessDeniedAlert"]) {
            return;
          }
          this.$store.commit("AccessDeniedAlert/SET_ACCESS_DENIED_ALERT_TRUE");

          this.alert({
            type: "warning",
            title: `${
              this.isLogin
                ? this.i18n("label.confirm")
                : this.i18nOff("label.confirm")
            }`,
            message,
            buttons: [
              {
                label: this.isLogin
                  ? this.i18n("button.confirm")
                  : this.i18nOff("button.confirm"),
                onPress: () => {
                  this.logout();
                  this.$store.commit(
                    "AccessDeniedAlert/SET_ACCESS_DENIED_ALERT_FALSE"
                  );
                }
              }
            ]
          });

          setTimeout(
            () =>
              this.$store.commit(
                "AccessDeniedAlert/SET_ACCESS_DENIED_ALERT_FALSE"
              ),
            2000
          );

          return;
        }

        // 프레임워크 오류 메세지일 경우
        if (this.isFrmError(orgMessage)) {
          message = this.getFrmErrorMessage(orgMessage);
        }

        this.alert({
          type: "warning",
          title: `${
            this.isLogin
              ? this.i18n("label.userAuthError")
              : this.i18nOff("label.userAuthError")
          }`,
          message,
          buttons: [
            {
              label: this.isLogin
                ? this.i18n("button.confirm")
                : this.i18nOff("button.confirm")
            }
          ]
        });

        if (resultCode === -100) {
          this.logoutWithServerNotToken();
        }
      } else {
        var patten = /\{.+?\}/g;
        let label = message;
        let matchArr = label.match(patten);

        if (matchArr != null && matchArr.length > 0) {
          label = matchArr[0];

          message = message.replace(label, "");

          label = label.replace(/[ |\\{|\\}]/gi, "");

          message += "[" + `${this.i18n(label)}` + "]";
        }

        // 프레임워크 오류 메세지일 경우
        if (this.isFrmError(orgMessage)) {
          message = this.getFrmErrorMessage(orgMessage);
        }

        this.alert({
          type: "warning",
          title: `${
            this.isLogin
              ? this.i18n("label.serverError")
              : this.i18nOff("label.serverError")
          }`,
          message
        });
      }
    }
  },
  /**
   * 프레임워크 오류일경우
   * @params {String} message
   */
  isFrmError(message) {
    return (
      message.includes("err.sys.cmn.001") ||
      message.includes("err.sys.cmn.002") ||
      message.includes("err.sys.cmn.003") ||
      message.includes("err.sys.cmn.004") ||
      message.includes("err.sys.cmn.005") ||
      message.includes("err.sys.cmn.006") ||
      message.includes("err.sys.cmn.007") ||
      message.includes("err.sys.auth.001") ||
      message.includes("err.sys.auth.002") ||
      message.includes("err.sys.auth.003") ||
      message.includes("err.sys.auth.004") ||
      message.includes("err.sys.auth.005") ||
      message.includes("err.sys.auth.006") ||
      message.includes("err.sys.auth.007") ||
      message.includes("err.sys.user.017") ||
      message.includes("err.sys.user.019") ||
      message.includes("err.sys.user.021") ||
      message.includes("err.sys.user.023")
    );
  },
  /**
   * 프레임워크 오류일경우 처리
   * @params {String} message
   */
  getFrmErrorMessage(message) {
    let newMessage = message;
    let messageId = "";

    if (message.includes("err.sys.cmn.001")) messageId = "err.sys.cmn.001";
    else if (message.includes("err.sys.cmn.002")) messageId = "err.sys.cmn.002";
    else if (message.includes("err.sys.cmn.003")) messageId = "err.sys.cmn.003";
    else if (message.includes("err.sys.cmn.004")) messageId = "err.sys.cmn.004";
    else if (message.includes("err.sys.cmn.005")) messageId = "err.sys.cmn.005";
    else if (message.includes("err.sys.cmn.006")) messageId = "err.sys.cmn.006";
    else if (message.includes("err.sys.cmn.007")) messageId = "err.sys.cmn.007";
    else if (message.includes("err.sys.auth.001"))
      messageId = "err.sys.auth.001";
    else if (message.includes("err.sys.auth.002"))
      messageId = "err.sys.auth.002";
    else if (message.includes("err.sys.auth.003"))
      messageId = "err.sys.auth.003";
    else if (message.includes("err.sys.auth.004"))
      messageId = "err.sys.auth.004";
    else if (message.includes("err.sys.auth.005"))
      messageId = "err.sys.auth.005";
    else if (message.includes("err.sys.auth.006"))
      messageId = "err.sys.auth.006";
    else if (message.includes("err.sys.auth.007"))
      messageId = "err.sys.auth.007";
    else if (message.includes("err.sys.user.017"))
      messageId = "err.sys.user.017";
    else if (message.includes("err.sys.user.019"))
      messageId = "err.sys.user.019";
    else if (message.includes("err.sys.user.021"))
      messageId = "err.sys.user.021";
    else if (message.includes("err.sys.user.023"))
      messageId = "err.sys.user.023";

    if (isEmpty(messageId)) return newMessage;

    newMessage = this.i18nOffFrm(messageId);

    if (message.includes("err.sys.user.017")) {
      const splitStr = message.split("|");
      newMessage = newMessage.replace("{0}", splitStr[1]);
      newMessage = newMessage.replace("{1}", splitStr[2]);

      return newMessage;
    }

    return newMessage;
  },
  /**
   * 우클릭 방지
   * @params {Object} e
   */
  preventRightMouse(e) {
    this.alert({
      type: "warning",
      title: this.i18n("label.notice"),
      message: this.i18n("alt.preventRightMouse"),
      buttons: [
        {
          label: this.i18n("button.confirm")
        }
      ]
    });
  },
  /**
   * 검색영역 - 애니메이션
   * @params {Object} eventBus
   * @params {Object} _this
   */
  _animationWithSearchPane(eventBus, _this) {
    let diviceH = document.body.clientHeight, //화면 height
      btnAreaH = document.querySelector(".grid-top-box").clientHeight, // 서브 제목 + 버튼 영역
      srcAreaH = document.querySelector(".search-area").clientHeight;

    if (diviceH > 950) {
      _this.gridProps.gridHeight = diviceH - srcAreaH - btnAreaH - 288;
    } else {
      _this.gridProps.gridHeight = 950 - srcAreaH - btnAreaH - 288;
    }

    // searchPane 열리고 닫힐 때 그리드 사이즈 조절
    eventBus.$on("isSearchPaneShow" + _this.$route.name, payload => {
      payload.isSearchPaneShow
        ? (_this.gridProps.gridHeight = _this.gridProps.gridHeight - srcAreaH)
        : (_this.gridProps.gridHeight = _this.gridProps.gridHeight + srcAreaH);
    });
  },
  /**
   * 상세 검색 영역 - 애니메이션
   * @params {Object} eventBus
   * @params {Object} _this
   */
  _animationWithSearchPaneAndInnerSearchPane(eventBus, _this) {
    //grid height
    let diviceH = document.body.clientHeight, //화면 height
      btnAreaH = document.querySelector(".grid-top-box").clientHeight, // 서브 제목 + 버튼 영역
      srcAreaH = document.querySelector(".search-area").clientHeight,
      inSrcAreaHeight = document.querySelector(".inner-search-area")
        .clientHeight,
      inSrcAreaH;

    if (inSrcAreaHeight > 0) {
      inSrcAreaH = inSrcAreaHeight;
    }

    if (diviceH > 950) {
      _this.gridProps.gridHeight = diviceH - srcAreaH - btnAreaH - 288;
    } else {
      _this.gridProps.gridHeight = 950 - srcAreaH - btnAreaH - 288;
    }

    // searchPane 열리고 닫힐 때 그리드 사이즈 조절
    eventBus.$on("isSearchPaneShow" + _this.$route.name, payload => {
      payload.isSearchPaneShow
        ? (_this.gridProps.gridHeight = _this.gridProps.gridHeight - srcAreaH)
        : (_this.gridProps.gridHeight = _this.gridProps.gridHeight + srcAreaH);
    });

    // inner searchPane 열리고 닫힐 때 그리드 사이즈 조절
    eventBus.$on("isInnerSearchPaneShow" + _this.$route.name, payload => {
      payload.isInnerSearchPaneShow
        ? (_this.gridProps.gridHeight = _this.gridProps.gridHeight - inSrcAreaH)
        : (_this.gridProps.gridHeight =
            _this.gridProps.gridHeight + inSrcAreaH);
    });
  },
  /**
   * 검색 영역 열고 닫는 함수
   * @params {Object} eventBus
   * @params {Object} _this
   */
  _fnShowSearchPane(eventBus, _this) {
    _this.isSearchPaneShow = !_this.isSearchPaneShow;
    _this.fnGridAni(); //애니추가

    if (_this.isInnerSearchPaneShow)
      _this._fnShowInnerSearchPane(eventBus, _this);

    eventBus.$emit("isSearchPaneShow" + _this.$route.name, {
      isSearchPaneShow: _this.isSearchPaneShow
    });
  },
  /**
   * 확장 검색영역이 있는 경우만
   * @params {Object} eventBus
   * @params {Object} _this
   */
  _fnShowInnerSearchPane(eventBus, _this) {
    _this.isInnerSearchPaneShow = !_this.isInnerSearchPaneShow;
    _this.fnGridAni(); //애니추가

    eventBus.$emit("isInnerSearchPaneShow" + _this.$route.name, {
      isInnerSearchPaneShow: _this.isInnerSearchPaneShow
    });
  },
  /**
   * 애니메이션 추가
   * @params {Object} _this
   */
  _fnGridAni(_this) {
    let gridWrap = document.querySelector(".grid-wrap");
    gridWrap.classList.add("ani-h");
    _this.gridAniTimeout = setTimeout(function() {
      gridWrap.classList.remove("ani-h");
    }, 600);
  },
  /**
   * 서버 시간 프로미스 반환
   */
  async MGetServerDtm() {
    return await this.$httpPost(
      "/api/com/getCurrentServerTime.do",
      {},
      { showLoading: false }
    ).catch(this.commonErrorHandler);
  },
  /**
   * a 태그 등에서 사용해야함
   */
  fnDoNotLogout(e) {
    sessionStorage.setItem("reloaded", "Y");
  }
};
