/**
 * 1. 개요 : 재고일괄등록
 * 2. 처리내용 : 재고일괄등록 Mixins - 화면 모델
 * @File Name : CmnStckBatUpPopupSaveModel.js
 * @date : 2020. 05. 06. 13:00:00
 * @author : 이현우
 * @history :
 *-----------------------------------------------------------------------
 * 변경일			          		  작성자		변경내용
 *-----------------------------------------------------------------------
 * 2020. 05. 06. 13:00:00	    이현우	  최초 작성
 *-----------------------------------------------------------------------
 */

// import { lookupValue } from "@/utils/dataGrid";

export default {
  methods: {
    CmnStckBatUpPopupSaveModel(param = {}) {
      return [
        {
          field: "mdlTypCd",
          isPk: true,
          isNotNull: false
        },
        {
          field: "stckTgtLvlCd",
          isPk: false,
          isNotNull: false
        },
        {
          field: "stckTgtCd",
          isPk: false,
          isNotNull: false
        },
        {
          field: "upTypCd",
          isPk: false,
          isNotNull: false
        },
        {
          field: "atchFileFkId",
          isPk: false,
          isNotNull: false
        }
      ];
    }
  }
};
