/**
 * 1. 개요 : Minputs 모바일 공통 컴포넌트 - MCheckBox
 * 2. 처리내용 : MCheckBox - 진입점
 * File Name : index.vue
 * date : 2020. 05. 12. 10:40:00
 * author : 이지혜
 * history :
 *-----------------------------------------------------------------------
 * 변경일			          		  작성자		변경내용
 *-----------------------------------------------------------------------
 * 2020. 05. 12. 10:40:00	    이지혜	  최초 작성
 *-----------------------------------------------------------------------
 */
<template>
  <label class="check-box"
         role="checkbox"
         :class="{
           disabled,
           checked: checkedState,
           inline
         }"
        tabindex="0"
  >
    <input ref="checkbox"
           v-model="checkModel"
           type="checkbox"
           v-bind="$attrs"
           :disabled="disabled"
           :value="value"
           @change="onChange"
           v-on="$listeners"
    >
          <!-- :style="iconStyles" -->
    <span class="check-box-icon"/>
    <span v-if="$slots.default" class="label">
      <slot />
    </span>
  </label>
</template>

<script>
export default {
  name: 'MCheckBox',

  model: {
    prop: 'checked',
    event: 'apply',
  },

  props: {
    checked: {
      type: [Boolean, Array],
      default: false,
    },

    indeterminate: {
      type: Boolean,
      default: false,
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    value: {
      type: [String, Number],
      default: null,
    },

    inline: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      checkModel: null,
    };
  },

  computed: {
    checkedState() {
      if (Array.isArray(this.checked)) {
        return this.checked.indexOf(this.checked) > -1;
      }
      return this.checked;
    },
  },

  watch: {
    indeterminate(value) {
      this.$refs.checkbox.indeterminate = value;
    },

    checked(value) {
      this.checkModel = value;
    },
  },

  created() {
    this.checkModel = this.checked;
  },

  mounted() {
    this.$refs.checkbox.indeterminate = this.indeterminate;
  },

  methods: {
    onChange() {
      this.$emit('apply', this.checkModel);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/mobile/_m-input.scss";

</style>

