var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "input-text mail-box" }, [
    _c("label", [
      _vm.type === "checkbox"
        ? _c(
            "input",
            _vm._g(
              _vm._b(
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.prefix,
                      expression: "prefix"
                    }
                  ],
                  ref: "prefix",
                  attrs: {
                    id: _vm.id,
                    placeholder: "ID",
                    maxlength: _vm.prefixMaxlength,
                    type: "checkbox"
                  },
                  domProps: {
                    checked: Array.isArray(_vm.prefix)
                      ? _vm._i(_vm.prefix, null) > -1
                      : _vm.prefix
                  },
                  on: {
                    change: function($event) {
                      var $$a = _vm.prefix,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 && (_vm.prefix = $$a.concat([$$v]))
                        } else {
                          $$i > -1 &&
                            (_vm.prefix = $$a
                              .slice(0, $$i)
                              .concat($$a.slice($$i + 1)))
                        }
                      } else {
                        _vm.prefix = $$c
                      }
                    }
                  }
                },
                "input",
                _vm.$attrs,
                false
              ),
              _vm.$listeners
            )
          )
        : _vm.type === "radio"
        ? _c(
            "input",
            _vm._g(
              _vm._b(
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.prefix,
                      expression: "prefix"
                    }
                  ],
                  ref: "prefix",
                  attrs: {
                    id: _vm.id,
                    placeholder: "ID",
                    maxlength: _vm.prefixMaxlength,
                    type: "radio"
                  },
                  domProps: { checked: _vm._q(_vm.prefix, null) },
                  on: {
                    change: function($event) {
                      _vm.prefix = null
                    }
                  }
                },
                "input",
                _vm.$attrs,
                false
              ),
              _vm.$listeners
            )
          )
        : _c(
            "input",
            _vm._g(
              _vm._b(
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.prefix,
                      expression: "prefix"
                    }
                  ],
                  ref: "prefix",
                  attrs: {
                    id: _vm.id,
                    placeholder: "ID",
                    maxlength: _vm.prefixMaxlength,
                    type: _vm.type
                  },
                  domProps: { value: _vm.prefix },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.prefix = $event.target.value
                    }
                  }
                },
                "input",
                _vm.$attrs,
                false
              ),
              _vm.$listeners
            )
          ),
      _vm.showClear
        ? _c("span", {
            staticClass: "input-clear",
            attrs: { title: "clear" },
            on: {
              click: function($event) {
                return _vm.$emit("clearPrefix")
              }
            }
          })
        : _vm._e()
    ]),
    _vm._v(" @ "),
    !_vm.isDirectInput
      ? _c("label", [
          _vm.type === "checkbox"
            ? _c(
                "input",
                _vm._g(
                  _vm._b(
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.suffix,
                          expression: "suffix"
                        }
                      ],
                      ref: "subffix",
                      attrs: {
                        id: _vm.id + "Subffix",
                        title: "Email Address",
                        maxlength: _vm.suffixMaxlength,
                        type: "checkbox"
                      },
                      domProps: {
                        checked: Array.isArray(_vm.suffix)
                          ? _vm._i(_vm.suffix, null) > -1
                          : _vm.suffix
                      },
                      on: {
                        change: function($event) {
                          var $$a = _vm.suffix,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 && (_vm.suffix = $$a.concat([$$v]))
                            } else {
                              $$i > -1 &&
                                (_vm.suffix = $$a
                                  .slice(0, $$i)
                                  .concat($$a.slice($$i + 1)))
                            }
                          } else {
                            _vm.suffix = $$c
                          }
                        }
                      }
                    },
                    "input",
                    _vm.$attrs,
                    false
                  ),
                  _vm.$listeners
                )
              )
            : _vm.type === "radio"
            ? _c(
                "input",
                _vm._g(
                  _vm._b(
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.suffix,
                          expression: "suffix"
                        }
                      ],
                      ref: "subffix",
                      attrs: {
                        id: _vm.id + "Subffix",
                        title: "Email Address",
                        maxlength: _vm.suffixMaxlength,
                        type: "radio"
                      },
                      domProps: { checked: _vm._q(_vm.suffix, null) },
                      on: {
                        change: function($event) {
                          _vm.suffix = null
                        }
                      }
                    },
                    "input",
                    _vm.$attrs,
                    false
                  ),
                  _vm.$listeners
                )
              )
            : _c(
                "input",
                _vm._g(
                  _vm._b(
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.suffix,
                          expression: "suffix"
                        }
                      ],
                      ref: "subffix",
                      attrs: {
                        id: _vm.id + "Subffix",
                        title: "Email Address",
                        maxlength: _vm.suffixMaxlength,
                        type: _vm.type
                      },
                      domProps: { value: _vm.suffix },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.suffix = $event.target.value
                        }
                      }
                    },
                    "input",
                    _vm.$attrs,
                    false
                  ),
                  _vm.$listeners
                )
              ),
          _vm.showClear
            ? _c("span", {
                staticClass: "input-clear",
                attrs: { title: "clear" },
                on: {
                  click: function($event) {
                    return _vm.$emit("clearSuffix")
                  }
                }
              })
            : _vm._e()
        ])
      : _vm._e(),
    _c("label", { staticClass: "selector select-box" }, [
      _c(
        "select",
        _vm._g(
          _vm._b(
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.autoSuffix,
                  expression: "autoSuffix"
                }
              ],
              on: {
                change: function($event) {
                  var $$selectedVal = Array.prototype.filter
                    .call($event.target.options, function(o) {
                      return o.selected
                    })
                    .map(function(o) {
                      var val = "_value" in o ? o._value : o.value
                      return val
                    })
                  _vm.autoSuffix = $event.target.multiple
                    ? $$selectedVal
                    : $$selectedVal[0]
                }
              }
            },
            "select",
            _vm.$attrs,
            false
          ),
          _vm.$listeners
        ),
        _vm._l(_vm.pickSubffixList, function(item) {
          return _c(
            "option",
            {
              key: item.id,
              attrs: { label: item.name },
              domProps: { value: item.id }
            },
            [_vm._v(_vm._s(item.name))]
          )
        }),
        0
      ),
      _c("span", { staticClass: "arrow-box" })
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }