import Vue from "vue";
import App from "./App";
import router from "./router";
import store from "./store";

router.beforeEach((to, from, next) => {
  if (
    router.options.routes.findIndex(a => a.path === to.path) === -1 &&
    to.path !== "/"
  ) {
    next("/404");
  } else {
    next();
  }
});

router.onError(err => {
  const message1 = "Loading chunk";
  const message2 = "failed";
  const isCuhunkLoadFailed =
    err.message.includes(message1) && err.message.includes(message2);

  if (isCuhunkLoadFailed) {
    const isConfirm = alert(
      "The application has been upgraded. Browser refresh is required."
    );

    if (isConfirm) {
      window.location.href = "/";
    }
  }
});

// css
import "expose-loader?$!expose-loader?jQuery!jquery";
import "jquery-ui/ui/widgets/datepicker";
import "jquery-ui/themes/base/core.css";
import "jquery-ui/themes/base/datepicker.css";
import "jquery-ui/themes/base/theme.css";

// cookie
import Cookies from "js-cookie";
import VueCookie from "vue-cookie";
Vue.use(VueCookie);

// layout
import AdminLayout from "./layout/admin";
import EmptyLayout from "./layout/empty";
Vue.component("admin-layout", AdminLayout);
Vue.component("empty-layout", EmptyLayout);

// mobile layout
import MobileAdminLayout from "./layout/mobile/admin";
import MobileEmptyLayout from "./layout/mobile/empty";
Vue.component("mobile-admin-layout", MobileAdminLayout);
Vue.component("empty-layout", MobileEmptyLayout);

// a modern alternative to CSS resets
import "normalize.css/normalize.css";

// element-ui
import Element from "element-ui";
import "./styles/element-variables.scss";
Vue.use(Element, {
  size: Cookies.get("size") || "medium" // set element-ui default size
});

import { LicenseManager } from "@ag-grid-enterprise/core";

LicenseManager.setLicenseKey(
  "CompanyName=Blueport, Inc. (Seocho-gu)_on_behalf_of_FIT Co.Ltd,LicensedApplication=Sales,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=0,AssetReference=AG-013859,ExpiryDate=26_February_2022_[v2]_MTY0NTgzMzYwMDAwMA==085ef07d045ca64831a142cb71fa5284"
);

// global css
import "./styles/index.scss";

// Pagination
import pagination from "vuejs-uib-pagination";
Vue.use(pagination);

// VCollapseTransition
import VCollapseTransition from "v-collapse-transition";
Vue.use(VCollapseTransition);

// PortalVue
import PortalVue from "portal-vue";
Vue.use(PortalVue);

// JsonViewer
import JsonViewer from "vue-json-viewer";
Vue.use(JsonViewer);

// vue-qrcode
import VueQrCode from "@chenfengyuan/vue-qrcode";
Vue.component(VueQrCode.name, VueQrCode);

// axios
import { post, anonymPost } from "./config/http";
Vue.prototype.$httpPost = post;
Vue.prototype.$httpAnonymPost = anonymPost;

// golbal mixin
import globalMixin from "@/mixins/global";
Vue.mixin(globalMixin);

// global filters
import * as filters from "./filters";

// register global utility filters
Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key]);
});

// 대쉬보드 도넛 그래프
import Donut from "vue-css-donut-chart";
import "vue-css-donut-chart/dist/vcdonut.css";
Vue.use(Donut);

// 프로필 이미지
import Croppa from "vue-croppa";
Vue.use(Croppa);

// 바코드
import VueBarcode from "@chenfengyuan/vue-barcode";
Vue.component(VueBarcode.name, VueBarcode);

// vue-html2canvas - screenshot
import VueHtml2Canvas from "vue-html2canvas";
Vue.use(VueHtml2Canvas);

console.log("[i] Starting client app");
Vue.config.productionTip = process.env.NODE_ENV === "development";

new Vue({
  components: { App },
  render: h => h(App),
  store,
  router
}).$mount("#app");
