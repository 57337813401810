/**
 * 1. 개요 : 사은품관리번호검색
 * 2. 처리내용 : 사은품관리번호검색 Mixins - 화면 모델
 * @File Name : CmnBizPopupViewModel.js
 * @date : 2022. 11. 18.  14:55:00
 * @author : 김준석
 * @history :
 *-----------------------------------------------------------------------
 * 변경일			          		    작성자		변경내용
 *-----------------------------------------------------------------------
 * 2022. 11. 18.  14:55:00	    김준석	  최초 작성
 *-----------------------------------------------------------------------
 */

import { dateTimeFormatter } from "@/utils/formatter";
import { amtFormatter } from "@/utils/formatter";
import { lookupValue } from "@/utils/dataGrid";

export default {
  methods: {
    CmnCpnGiftMngNoPopupViewModel(params = {}) {
      return [
        {
          field: "rnum",
          headerName: "",
          type: Number,
          width: 50,
          isPk: false,
          editable: false,
          pinned: false,
          cellStyle: {
            textAlign: "center"
          },
          cellClassRules: {
            "selected-cell": param =>
              params && param.rowIndex === params.rowIndex
          },
          valueFormatter : (param) => {
            // 내림차순
            return this.gridProps.totalItems - (((this.currPage - 1) * this.pageUnit) + param.node.rowIndex);
          }, 
        },
        //사은품관리번호
        {
          field: "giftMngNo",
          headerName: this.i18n('label.giftMngNo'),
          type: String,
          width: 200,
          isPk: false,
          editable: false,
          pinned: false,
          suppressMenu: false,
          sortable: true,
          cellStyle: {
            textAlign: "center"
          },
          cellClassRules: {},

        },
        //쿠폰정책관리번호
        {
          field: "cpnPlcyMngNo",
          headerName: this.i18n('label.evtPlcyNo'),
          type: String,
          width: 200,
          isPk: false,
          editable: false,
          pinned: false,
          suppressMenu: false,
          sortable: true,
          cellStyle: {
            textAlign: "center"
          },
          cellClassRules: {},
        },
        //등수명
        {
          field: "rankNm",
          headerName: this.i18n('label.rankNm'),
          type: String,
          width: 120,
          isPk: false,
          editable: false,
          pinned: false,
          suppressMenu: false,
          sortable: true,
          cellStyle: {
            textAlign: "center"
          },
          cellClassRules: {},

        },
        //사은품종류
        {
          field: "giftKnd",
          headerName: this.i18n('label.giftKind'),
          type: String,
          width: 200,
          isPk: false,
          editable: false,
          pinned: false,
          suppressMenu: false,
          sortable: true,
          cellStyle: {},
          cellClassRules: {},

        },
        //당첨자수
        {
          field: "winnerCount",
          headerName: this.i18n('label.winnerNo'),
          type: String,
          width: 140,
          isPk: false,
          editable: false,
          pinned: false,
          suppressMenu: false,
          sortable: true,
          cellStyle: {
            textAlign: "center"
          },
          cellClassRules: {},

        }
      ];
    }
  }
};
