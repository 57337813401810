/**
 * 1. 개요 : OrtherPcLoginAlert
 * 2. 처리내용 : OrtherPcLoginAlert - 스토어
 * @File Name : OrtherPcLoginAlert.js
 * @date : 2020. 02. 20. 09:46:00
 * @author : 이상철
 * @history :
 *-----------------------------------------------------------------------
 * 변경일			          		  작성자		변경내용
 *-----------------------------------------------------------------------
 * 2020. 2. 20. 09:46:00	    이상철	  최초 작성
 *-----------------------------------------------------------------------
 */
export default {
  state: {
    isOrtherPcLoginAlert: false
  },
  namespaced: true,
  mutations: {
    SET_ORTHER_PC_LOGIN_ALERT_TRUE(state, param) {
      state.isOrtherPcLoginAlert = true;
    },
    SET_ORTHER_PC_LOGIN_ALERT_FALSE(state, param) {
      state.isOrtherPcLoginAlert = false;
    }
  },
  actions: {
    setOrtherPcLoginAlertTrue({ commit }, param) {
      commit("SET_ORTHER_PC_LOGIN_ALERT_TRUE", param);
    },
    setOrtherPcLoginAlertFalse({ commit }, param) {
      commit("SET_ORTHER_PC_LOGIN_ALERT_FALSE", param);
    }
  },
  getters: {
    getIsOrtherPcLoginAlert: state => state.isOrtherPcLoginAlert
  }
};
