/**
 * 1. 개요 : Minputs 모바일 공통 컴포넌트 - MSelector
 * 2. 처리내용 : MSelector - 진입점
 * File Name : index.vue
 * date : 2020. 05. 12. 10:40:00
 * author : 이지혜
 * history :
 *-----------------------------------------------------------------------
 * 변경일			          		  작성자		변경내용
 *-----------------------------------------------------------------------
 * 2020. 05. 12. 10:40:00	    이지혜	  최초 작성
 *-----------------------------------------------------------------------
 */
<template>
  <div class="selector select-box">
    <select v-model="model" v-bind="$attrs" v-on="$listeners" :id="id">
      <slot />
    </select>
    <div class="arrow-box" />
  </div>
</template>
<script>
export default {
  name: 'MSelector',
  model: {
    prop: 'value',
    event: 'apply'
  },

  props: {
    value: {
      type: [String, Number],
      default: ''
    },
    id: {
			type: String,
			default: ""
		}
  },

  data() {
    return {
      model: this.value
    };
  },

  watch: {
    value(value) {
      if (this.model !== value) this.model = value;
    },

    model(value) {
      this.$emit('apply', typeof value === 'number' ? Number(value) : value);
    }
  },

  methods: {
    focus() {
      const input = this.$el.getElementsByTagName('select')[0];
      if (input) input.focus();
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/scss/mobile/_m-select.scss";
</style>

