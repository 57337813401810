/**
 * 1. 개요 : 주문시리얼번호 검색
 * 2. 처리내용 : 주문시리얼번호 검색 - 팝업창 컴포넌트
 * @File Name : CmnOrdSnPopup.vue
 * @date : 2020. 04. 20. 15:00:00
 * @author : 이현우
 * @history :
 *-----------------------------------------------------------------------
 * 변경일			          		  작성자		변경내용
 *-----------------------------------------------------------------------
 * 2020. 04. 20. 15:00:00	    이현우	  최초 작성
 *-----------------------------------------------------------------------
 */
<template>
	<popup :title="title" @close="$emit('close')" popSize="size-grid">
		<div class="search-wrap">
			<collapse-search-pane
				:isSearchPaneShow="isPaSearchPaneShow"
				@fnShowSearchPane="fnShowPaSearchPane"
			>
				<div class="search-area">
					<div id="tblInfo" class="hide">
						{{ 'label.ordId' | i18n }}
						&nbsp;{{ 'label.search' | i18n }}
					</div>
					<div class="tbl-wrap">
						<table aria-describedby="tblInfo">
							<caption>{{ 'label.searchArea' | i18n }}</caption>
							<colgroup>
								<col style="width:150px">
								<col style="width:auto">
								<col style="width:150px">
								<col style="width:auto">
							</colgroup>
							<tbody>
								<tr>
									<th scope="row">
										<label for="searchOrdId">{{ 'label.ordId' | i18n }}</label>
									</th>
									<td>
										<input-text
											id="searchTrmSn"
											v-model="search.ordId"
											type="text"
											name="searchTrmSn"
											@keydown.enter="validate"
											disabled
										/>
									</td>
									<th scope="row">
										<label for="searchCompStatCd">{{ 'label.compStat' | i18n }}</label>
									</th>
									<td>
										<code-selector
											id="searchCompStatCd"
											v-model="search.compStatCd"
											:cdList="cdList.compStatCdList"
											:isAll="true"
										/>
									</td>
								</tr>
								<tr>
									<th scope="row">
										<label for="pageUnit">{{ "label.pageUnit" | i18n }}</label>
									</th>
									<td colspan="3">
										<input-text
											id="pageUnit"
											ref="pageUnit"
											name="pageUnit"
											v-model="pageUnit"
											:isNumberType="true"
											@keydown.enter="validate"
										/>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</collapse-search-pane>
			<div class="search-btn">
				<button type="button" name="searchList" @click.stop="validate">{{'button.look' | i18n }}</button>
				<button type="button" name="excelDown" @click.stop="fnExcelDown" >{{ 'button.excel' | i18n }}</button>
				<!-- <button type="button" name="clear" @click.stop="clear">{{'button.init' | i18n }}</button> -->
			</div>
		</div>
		<div v-if="showSelectedRowNm" style="position:absolute;transform:translateY(15px)">Selected Row: {{ getSnListLength }}</div>
		<div>
			<data-grid
				ref="dataGrid1"
				:key="key"
				:totalItems="gridProps.totalItems"
				:columnDefs="gridProps.columnDefs"
				:rowData="gridProps.rowData"
				:initPage="gridProps.initPage"
				:perPage="pageSize"
				:selectedRows="gridProps.selectedRows"
				:gridHeight="gridProps.gridHeight"
				:rowClassRules="gridProps.rowClassRules"
				:frameworkComponents="gridProps.frameworkComponents"
				:rowSelection="gridProps.rowSelection"
				:topBtnList="gridProps.topBtnList"
				:suppressRowClickSelection="gridProps.suppressRowClickSelection"
				:icons="gridProps.icons"
				@rowSelected="setRowSelected"
				@selectionChanged="setSelectionChangedRow"
				@callOnRowDoubleClicked="fnRowDoubleClick"
				@fnList="fnList"
			/>
		</div>
	</popup>
</template>

<script>
import Popup from "@/components/Popup";
import CollapseSearchPane from "@/components/CollapseSearchPane";

import { EventBus } from "./EventBus";
import { CmnOrdSnPopupViewModel, Helper, AnimationHelper, ExcelDownload } from "./mixins";
import { isEmpty } from "@/utils";
import { validNumberInputData, validAmtInputPrecision, inputPhoneValidate } from "@/utils/validate";
import DataGrid from "@/components/DataGrid";

export default {
	name: "CmnOrdSnPopup",
	components: {
		Popup,
		CollapseSearchPane,
		DataGrid
	},
	mixins: [CmnOrdSnPopupViewModel, Helper, AnimationHelper, ExcelDownload],
	props: {
		paramData: {
			type: Object,
			default: () => {}
		},
		showSelectedRowNm: {
			type: Boolean,
			dafault: false
		}
	},
	data() {
		return {
			key: 0,
			resultList: [],
			currPage: 1,
			pageUnit: 100, 
			pageSize: 100,
			search: {
				ordId: "",
				compStatCd: "",
				mdlTypCd: ""
			},
			totalCnt:0,
			gridProps: {
				icons: null,
				totalItems: 0,
				columnDefs: [],
				rowData: [],
				// 일반적인 row 선택 or 더블클릭시
				selectedRows: [],
				initPage: true,
				editable: true,
				gridHeight: 360,
				rowClassRules: null,
				frameworkComponents: null,
				rowSelection: null,
				// 로우 선택시 선택이 되도록 하려면 false, 체크박스 or 라디오 선택시 선택이 되오도록 하려면 true
				suppressRowClickSelection: false,
				topBtnList: []
			},
			// 일반적인 row 선택 or 더블클릭시
			selectedRow: null,
			// 체크박스 또는 라디오 row 선택시
			selectedCheckOrRadioRows: [],

			isPaSearchPaneShow: true,
			cdList: {
				stckTgtLvlCdList: [],
				stckStatCdList: [],
				compStatCdList: [],
			}
		};
	},
	created() {
		this.fnGetAxcCodeGrpList(["STCK_TGT_LVL_CD", "STCK_STAT_CD", "COMP_STAT_CD"]);
	},
	computed: {
		/**
     * 화면 title 설정
     * @return {String}
     */
		title() {
			return this.i18n("label.ordSn") + this.i18n("label.search");
		},
		/**
     * 선택 목록 가져오기
     * @return {Array}
     */
		getSnList() {
			return this.$store.getters["CmnOrdSnPopupChecked/getSnList"];
		},
		/**
     * 선택목록 length
     * @return {int}
     */
		getSnListLength(){
			return this.getSnList.length
		}
	},
	watch: {
		// row 더블 클릭 했을 경우 다시 호출
		selectedRow: {
			handler(params) {
				this.setColumnDefs(params);
				this.setRowClassRules();
			}
		}
	},
	beforeMount() {
		this.search.ordId = this.paramData.ordId;

		// 그리드 초기화
		this.setColumnDefs();

		// row 클래스 동적 적용
		this.setRowClassRules();

		// 그리드의 frameworkComponents 셋(필요한 경우에만)
		this.setFrameworkComponents();

		// 그리드 상단의 버튼 리스트 정의
		this.setTopBtnList();

		// 그리드의 rowSelection 셋
		this.setRowSelection();
		// 그리드의 icon 셋
    this.gridProps.icons = {
      checkboxUnchecked: `<label class="ag-check-box" tabindex="0">
                          	<input type="checkbox">
                          	<span class="ag-check-box-icon"></span>
                          </label>`,
      checkboxChecked: `<label class="ag-check-box checked" tabindex="0">
                        	<span class="ag-check-box-icon"></span>
												</label>`,
			checkboxIndeterminate: `<label class="ag-check-box" tabindex="0">
                        				<span class="ag-check-box-icon"></span>
															</label>`
    }
	},
	beforeDestroy() {
		this.$store.dispatch("CmnOrdSnPopupChecked/initialSnList", []);
	},
	mounted() {
		this.search.compStatCd = "N";
		this.search.mdlTypCd = this.paramData.mdlTypCd;

		this.searchList();
	},
	methods: {
		/**
     * 업무 코드 리스트 페치
     * @params {Array} searchCodeGrpIdList
     */
		async fnGetAxcCodeGrpList(searchCodeGrpIdList = []) {
			if (isEmpty(searchCodeGrpIdList)) return;

			await this.$httpPost(
					"/api/bep/getAxcCodeGroupListByCodeGrpIdList.do",
					{ searchCodeGrpIdList },
					{ showLoading: true }
				)
				.then(response => {
					this.cdList.stckTgtLvlCdList = response.data.resultList.find(a => a[0].codeGrpId === "STCK_TGT_LVL_CD");
					this.cdList.stckStatCdList = response.data.resultList.find(a => a[0].codeGrpId === "STCK_STAT_CD");
					this.cdList.compStatCdList = response.data.resultList.find(a => a[0].codeGrpId === "COMP_STAT_CD");
				})
				.catch(this.commonErrorHandler);

			this.key = this.key + 1;
		},
		
		validate() {
			if (
				this.validateHelper([
					{
						ref: this.$refs.pageUnit,
						isValidate: validNumberInputData(this.pageUnit),
						message: this.i18n("alt.validateInputNum")
					},
				])
			) {
				return;
			} else {
				this.searchList();
			}
		},

		/**
     * 주문재고SN 목록 조회
     * @params {Object}} search
     */
		async searchList(search) {
			if (search) {
				this.currPage = 1;
				this.gridProps.initPage = true;
				// 선택대상 초기화
				this.$store.dispatch("CmnOrdSnPopupChecked/initialSnList", []);
			}
			if(isEmpty(this.pageUnit)){
				this.alert({
					type: "warning",
					title: this.i18n("label.confirm"),
					message: this.i18n("label.pageUnit") +this.i18n("alt.required")
				});
				return;
			}
			this.resultList = [];
			this.gridProps.rowData = [];

			console.log(this.search);

			await this.$httpPost(
					"/api/bep/getCmnOrdSnList.do",
					{
						searchOrdId: this.search.ordId,
						searchCompStatCd: this.search.compStatCd,
						searchMdlTypCd: this.search.mdlTypCd,

						currPage: this.currPage,
						pageUnit: this.pageUnit,
						pageSize: this.pageUnit
					},
					{
						showLoading: true
					}
				)
				.then(response => {
					// 페이지 수 변경
					this.pageSize = this.pageUnit;
					// result 셋
					this.resultList = response.data.resultList;
					// 데이터 총 갯수 셋
					this.gridProps.totalItems = response.data.totalCount;
					this.totalCnt = this.gridProps.totalItems;
					this.selectedCheckOrRadioRows = [];
				})
				.then(() => {
					// 그리드에 조회해온 데이터 셋
					if (this.resultList)
						this.gridProps.rowData = this.resultList.map(a => ({
							...a,
							status: "R"
						}));

						// state가 있으면 fnDefaultRowSelectedList를 실행한다.
						if (!isEmpty(this.getSnList)) {
							this.$nextTick(() => this.fnDefaultRowSelectedList());
						}

				})
				.catch(this.commonErrorHandler);
		},

		/**
     * 페이지 변환시 데이터 조회 함수
     * @params {int} currPage
     */
		fnList(currPage) {
			this.currPage = currPage;
			this.searchList();
			this.gridProps.initPage = false;
		},

		/**
     * 선택 목록 리턴
     */
		fnRtnAplyClick() {
			if (this.getSnList.length < 1) {
				this.alert({
					type: "warning",
					title: this.i18n("label.confirm"),
					message: this.i18n("alt.noSelect")
				});
				return;
			}

			for(var i = 0; i < this.getSnList.length; i++) {
				if(this.getSnList[i].compYn == "Y") {
					this.alert({
						type: "warning",
						title: this.i18n("label.confirm"),
						message: this.i18n("alt.compNo")
					});
					return;
				}
			}

			this.$emit(
				"fnRtnAplyClick",
				this.getSnList.map(a => ({
					sn: a.sn
				}))
			);
			this.$emit("close");
		},
		
		/**
     * 더블 클릭시
     * @params {Object} selectedRow
     */
		fnRowDoubleClick(selectedRow) {},

		/**
     * 선택 목록 체크
     * @params {Object} params
     */
		fnDefaultRowSelectedList() {
			this.$nextTick(() => {
				this.$refs.dataGrid1.gridApi.forEachNode(node => {
					const selectedSnList = this.getSnList.map(a => a.sn);
					node.setSelected(selectedSnList.includes(node.data.sn));
				});
			});
		},


		/**
     * 그리드에 하나의 row 선택 함수
     * @params {Object} params
     */
		setSelectionChangedRow(params) {
			this.gridProps.selectedRows = [];
			this.gridProps.selectedRows = [
				{ ...params.api.getSelectedRows()[0], status: "U" }
			];
		},

		/**
     * 그리드의 columnDefs 셋
     * @params {Object} params
     */
		setColumnDefs(params = {}) {
			this.$nextTick(() => {
				let gridWidth = this.$refs.dataGrid1.$el.clientWidth - 20;
				let totalWidth = 0;

				this.gridProps.columnDefs = this.cmnOrdSnPopupViewModel(params).map(
					a => {
						totalWidth += a.width === 0 ? 200 : a.width;

						return {
							headerName: a.headerName,
							field: a.field,
							width:
								a.field === "restCell" && totalWidth > 0
									? gridWidth - totalWidth + 200
									: a.width,
							editable: a.editable,
							pinned: a.pinned,
							cellStyle: a.cellStyle,
							cellClassRules: a.cellClassRules,
							valueFormatter: a.valueFormatter,
							cellRenderer: a.cellRenderer,
							headerCheckboxSelection: a.headerCheckboxSelection,
							headerCheckboxSelectionFilteredOnly:
								a.headerCheckboxSelectionFilteredOnly,
							checkboxSelection: a.checkboxSelection,
							headerCheckboxSelection: a.headerCheckboxSelection,
          		headerCheckboxSelectionFilteredOnly: a.headerCheckboxSelectionFilteredOnly,
							suppressMenu: a.suppressMenu,
							sortable: a.sortable
						};
					}
				);
			});
		},

		/**
     * 그리드 상단의 버튼 리스트 정의
     */
		setTopBtnList() {
      let initBtn = {
        type: "primary",
        disabled: false,
        title: this.i18n("button.opnInit"),
        event: this.fnInitClick
      };

      this.gridProps.topBtnList = [
        {
          type: "primary",
          disabled: false,
          title: this.i18n("button.aply"),
          event: this.fnRtnAplyClick
        }
      ];

      if (this.search.rowSelection == "multiple") {
        // 그리드 체크박스 전체 선택 기능 추가시 오픈
        // this.gridProps.topBtnList.push(initBtn);
      }
		},

		/**
     * 그리드의 rowClass 셋
     */
		setRowClassRules() {
			this.gridProps.rowClassRules = {
				// 'selected-row': param => this.selectedRow && (this.selectedRow.rowIndex === param.rowIndex)
			};
		},

		/**
     * 그리드의 frameworkComponents 셋
     */
		setFrameworkComponents() {
			this.gridProps.frameworkComponents = {};
		},

		/**
     * 그리드의 rowSelection 셋
     */
		setRowSelection() {
			// 단수 선택 경우 'single', 복수 선택일 경우 'multiple'
			this.gridProps.rowSelection = "multiple";
		},

		/**
     * 셀렉트박스 또는 라디오 선택시
     * @params {Object} params
     */
		setRowSelected(params) {
			if (params.node.isSelected()) {
				if (
					isEmpty(this.getSnList.find(a => a.sn === params.data.sn))
				) {
					this.$store.dispatch(
						"CmnOrdSnPopupChecked/setSnList",
						params.data
					);
				}
			} else {
				this.$store.dispatch(
					"CmnOrdSnPopupChecked/removeSnList",
					params.data
				);
			}
		},

	}
};
</script>

<style lang="scss" scoped>
@import "@/scss/_color.scss";
@import "@/scss/_button.scss";
@import "@/scss/_input.scss";
</style>


