<template>
	<div class="input-text text-box search-combo">
		<input
			:ref="id"
			:id="id"
			type="text"
			style="text-align:right;"
			:value="formatedValue"
			:disabled="disabled"
			@blur="onBlurHandler"
			@keydown.enter.prevent="fnSearch"
			@input="handleInput"
			:onkeydown="onKeyDown"
			:maxlength="calMaxlength"
			:placeholder="placeholder"
		/>
		<span style="color:#999;margin-top:5px;" v-if="unit">{{ unit }}</span>
		<button
			v-if="showBtn"
			type="button"
			icon-class="search"
			@click.stop="fnSearch"
			:disabled="btnDisabled"
		>search</button>
	</div>
</template>

<script>
import { isEmpty } from "@/utils";

export default {
	name: "SearchInputAmt",
	props: {
		value: {
			// type: String,
			default: "",
			required: true
		},
		maxlength: {
			type: Number,
			default: null
		},
		disabled: {
			type: Boolean,
			default: false
		},
		placeholder: {
			type: String,
			default: ""
		},
		unit: {
			type: String,
			default: ""
		},
		// btn
		showBtn: {
			type: Boolean,
			default: true
		},
		btnDisabled: {
			type: Boolean,
			default: false
		},
		id: {
			type: String,
			default: ""
		},
		preventMinus: {
			type: Boolean,
			default: true
		}
	},
	data() {
		return {
			formatedValue: this.processFormatting(this.value),
			updateCount: 0
		};
	},
	watch: {
		value() {
			this.formatedValue = this.processFormatting(this.value);
		}
	},
	computed: {
		onKeyDown() {
			if (!this.preventMinus) {
				return `return  event.keyCode !== 187 && // '=,+'
												event.keyCode !== 107 && // '+'
												event.keyCode !== 191 && // '/'
												event.keyCode !== 111 && // '/'
												event.keyCode !== 106 && // '*'
												event.keyCode !== 192 && // '~'
												event.keyCode !== 222 && // '
												event.keyCode !== 186 && // ';'
												event.keyCode !== 188 && // ','
												event.keyCode !== 219 && // '['
												event.keyCode !== 221 && // ']'
												event.keyCode !== 220 && // '|\'
												event.keyCode !== 229 && // '한글'
		                    event.keyCode !== 81 && // 영문
		                    event.keyCode !== 87 &&
		                    event.keyCode !== 69 &&
		                    event.keyCode !== 82 &&
		                    event.keyCode !== 84 &&
		                    event.keyCode !== 89 &&
		                    event.keyCode !== 85 &&
		                    event.keyCode !== 73 &&
		                    event.keyCode !== 79 &&
		                    event.keyCode !== 80 &&
		                    event.keyCode !== 83 &&
		                    event.keyCode !== 68 &&
		                    event.keyCode !== 70 &&
		                    event.keyCode !== 71 &&
		                    event.keyCode !== 72 &&
		                    event.keyCode !== 74 &&
		                    event.keyCode !== 75 &&
		                    event.keyCode !== 76 &&
		                    event.keyCode !== 90 &&
												event.keyCode !== 66 &&
												event.keyCode !== 78 &&
												event.keyCode !== 32 &&
												event.keyCode !== 77`;
			} else {
				return `return  event.keyCode !== 189 && // 마이너스
												event.keyCode !== 109 && // 마이너스
												event.keyCode !== 187 && // '=,+'
												event.keyCode !== 107 && // '+'
												event.keyCode !== 191 && // '/'
												event.keyCode !== 111 && // '/'
												event.keyCode !== 106 && // '*'
												event.keyCode !== 192 && // '~'
												event.keyCode !== 222 && // '
												event.keyCode !== 186 && // ';'
												event.keyCode !== 188 && // ','
												event.keyCode !== 219 && // '['
												event.keyCode !== 221 && // ']'
												event.keyCode !== 220 && // '|\'
												event.keyCode !== 229 && // '한글'
		                    event.keyCode !== 81 && // 영문
		                    event.keyCode !== 87 &&
		                    event.keyCode !== 69 &&
		                    event.keyCode !== 82 &&
		                    event.keyCode !== 84 &&
		                    event.keyCode !== 89 &&
		                    event.keyCode !== 85 &&
		                    event.keyCode !== 73 &&
		                    event.keyCode !== 79 &&
		                    event.keyCode !== 80 &&
		                    event.keyCode !== 83 &&
		                    event.keyCode !== 68 &&
		                    event.keyCode !== 70 &&
		                    event.keyCode !== 71 &&
		                    event.keyCode !== 72 &&
		                    event.keyCode !== 74 &&
		                    event.keyCode !== 75 &&
		                    event.keyCode !== 76 &&
		                    event.keyCode !== 90 &&
												event.keyCode !== 66 &&
												event.keyCode !== 78 &&
												event.keyCode !== 32 &&
												event.keyCode !== 77`;
			}
		},
		calMaxlength() {
			if (
				!isEmpty(this.formatedValue.split(".")[1]) ||
				this.formatedValue.includes("-")
			)
				return this.maxlength + 1;
			else return this.maxlength;
		}
	},
	methods: {
		onBlurHandler(e) {
			const returnValue = this.getFormatValue(this.formatedValue);

			if (
				returnValue.toString().length > 1 &&
				returnValue.toString()[0] === "0"
			) {
				e.target.value = returnValue.substring(1);
			} else {
				e.target.value = returnValue;
			}

			this.$emit("blur", e);
		},
		handleInput(e) {
			let tempValue = e.target.value;

			if (this.preventMinus) {
				tempValue = e.target.value.replace(/-/gi, "");
			}

			this.formatedValue = this.processFormatting(tempValue);

			e.target.value = tempValue;

			this.$emit("input", { value: this.formatedValue });
		},
		processFormatting(value) {
			if (typeof value !== "string") value = String(value);

			if (!value) return "0"; // 수민씨 요청에 따라 첫 로딩시 '0'이 보이게 수정

			// const reg2 = /\b0+/g;
			const reg2 = /(^0+)/;

			if (this.updateCount === 0 || this.updateCount === undefined) {
				this.$nextTick(() => {
					const returnValue = this.getFormatValue(this.formatedValue);

					this.$refs[this.id].value = this.getFormatValue(this.formatedValue);

					this.updateCount = this.updateCount + 1;
				});
			} else {
				if (!isEmpty(value.split(".")[1])) {
					value = `${value.split(".")[0].replace(reg2, "")}.${
						value.split(".")[1]
					}`;
				} else if (value.toString() !== "0") {
					value = value.replace(reg2, "");
				}
			}

			return value;
		},
		focus() {
			const input = this.$el.getElementsByTagName("input")[0];
			const btn = this.$el.getElementsByTagName("button")[0];

			if (!this.inputDisabled && input) {
				// 인풋이 disabled가 아닐 경우 input으로 포커스
				input.focus();
			} else if (this.inputDisabled) {
				// 인풋이 disabled인 경우 버튼으로 포커스
				btn.focus();
			} else {
				// 나머지 경우
				input.focus();
			}
		},
		// btn
		fnSearch() {
			this.$emit("fnSearch", this.formatedValue);
		},
		getFormatValue(value) {
			// const reg2 = /\b0+/g;
			const reg2 = /(^0+)/;
			const reg3 = /(\d)(?=(\d{3})+(?!\d))/g;
			let returnValue = value;
			returnValue = returnValue.replace(reg3, "$1,");

			if (this.updateCount === 0 || this.updateCount === undefined) {
				if (!isEmpty(value.split(".")[1])) {
					const front = value.split(".")[0].replace(reg3, "$1,");
					let back = value.split(".")[1];

					returnValue = `${front}.${back}`;
				}
			} else {
				if (!isEmpty(value.split(".")[1])) {
					const front = value
						.split(".")[0]
						.replace(reg2, "")
						.replace(reg3, "$1,");
					let back = value.split(".")[1];

					returnValue = `${front}.${back}`;
				} else if (value.toString() !== "0") {
					returnValue = returnValue.replace(reg2, "");
				}
			}

			return returnValue;
		}
	}
};
</script>

<style lang="scss" scoped>
@import "@/scss/_color.scss";
@import "@/scss/_input.scss";
.search-combo {
	button:disabled {
		color: #999;
	}
}
</style>