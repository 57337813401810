var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { on: { click: _vm.toggleClick } }, [
    _c("a", { staticClass: "sp-b", attrs: { href: "javascript:;" } }, [
      _vm._v("side-menu show or hide button")
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }