var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "tab-content" }, [
    _c(
      "div",
      { staticClass: "search-wrap" },
      [
        _c(
          "collapse-search-pane",
          {
            attrs: { isSearchPaneShow: _vm.isSearchPaneShow },
            on: { fnShowSearchPane: _vm.fnShowSearchPane }
          },
          [
            _c("div", { staticClass: "search-area" }, [
              _c("div", { staticClass: "hide", attrs: { id: "tblInfo01" } }, [
                _vm._v(
                  " " +
                    _vm._s(_vm._f("i18n")("label.trDt")) +
                    " " +
                    _vm._s(_vm._f("i18n")("label.search")) +
                    " "
                )
              ]),
              _c("div", { staticClass: "tbl-wrap" }, [
                _c("table", { attrs: { "aria-describedby": "tblInfo01" } }, [
                  _c("caption", [
                    _vm._v(_vm._s(_vm._f("i18n")("label.searchArea")))
                  ]),
                  _c("colgroup", [
                    _c("col", { staticStyle: { width: "150px" } }),
                    _c("col", { staticStyle: { width: "262px" } }),
                    _c("col", { staticStyle: { width: "150px" } }),
                    _c("col", { staticStyle: { width: "262px" } }),
                    _c("col", { staticStyle: { width: "150px" } }),
                    _c("col", { staticStyle: { width: "262px" } }),
                    _c("col", { staticStyle: { width: "150px" } }),
                    _c("col", { staticStyle: { width: "auto" } })
                  ]),
                  _c("tbody", [
                    _c("tr", [
                      _c("th", { attrs: { scope: "row" } }, [
                        _c("label", [
                          _vm._v(_vm._s(_vm._f("i18n")("label.qnaDt")))
                        ])
                      ]),
                      _c(
                        "td",
                        { attrs: { colspan: "3" } },
                        [
                          _c("date-range-and-input-date", {
                            key: _vm.updateKey,
                            ref: "dateRange",
                            attrs: {
                              propsServerDate: _vm.serverDate,
                              propsServerTime: _vm.serverTime,
                              propsSearch: _vm.search,
                              hideInputTime: true
                            },
                            on: {
                              mergeDateRangeIntoSearchObject:
                                _vm.mergeDateRangeIntoSearchObject
                            }
                          })
                        ],
                        1
                      )
                    ])
                  ])
                ])
              ])
            ])
          ]
        ),
        _c(
          "div",
          { staticClass: "search-btn" },
          [
            _c(
              "button",
              {
                attrs: { type: "button", name: "searchList" },
                on: {
                  click: function($event) {
                    $event.stopPropagation()
                    return _vm.searchValidate($event)
                  }
                }
              },
              [_vm._v(_vm._s(_vm._f("i18n")("button.look")))]
            ),
            _c("default-button", { on: { click: _vm.clearSearch } }, [
              _vm._v(_vm._s(_vm._f("i18n")("button.init")))
            ]),
            _c(
              "button",
              {
                attrs: {
                  type: "button",
                  name: "showMyPagePopupComplaintSavePopup"
                },
                on: {
                  click: function($event) {
                    return _vm.showSavePopup()
                  }
                }
              },
              [_vm._v(_vm._s(_vm._f("i18n")("button.reg")))]
            )
          ],
          1
        )
      ],
      1
    ),
    _c(
      "div",
      [
        _c(
          "data-table",
          { staticClass: "tbl003", attrs: { tblId: "tbl003" } },
          [
            _c(
              "template",
              { staticStyle: { "text-align": "center" }, slot: "tbody" },
              [
                _c("tr", { staticClass: "MyPgComplaintTblHeader" }, [
                  _c("th", { attrs: { scope: "row" } }),
                  _c("th", { attrs: { scope: "row", colspan: "3" } }, [
                    _vm._v(_vm._s(_vm._f("i18n")("label.myComplaints")))
                  ]),
                  _c("th", { attrs: { scope: "row" } }, [
                    _vm._v(_vm._s(_vm._f("i18n")("label.qnaDt")))
                  ]),
                  _c("th", { attrs: { scope: "row" } }, [
                    _vm._v(_vm._s(_vm._f("i18n")("label.bbsSt")))
                  ])
                ]),
                _vm._l(this.resultList, function(item, index) {
                  return _c("tr", { key: index }, [
                    _c(
                      "td",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: item.contents == null,
                            expression: "item.contents == null"
                          }
                        ],
                        staticClass: "MyPgComplaintTd"
                      },
                      [
                        _c("input", {
                          ref: "inputIcon",
                          refInFor: true,
                          attrs: {
                            type: "image",
                            name: "toggle",
                            value: "open",
                            src: _vm.addBtn,
                            alt: ""
                          },
                          on: {
                            click: function($event) {
                              return _vm.clickTitleShowContents(index, item)
                            }
                          }
                        })
                      ]
                    ),
                    item.contents == null
                      ? _c(
                          "td",
                          {
                            staticClass: "MyPgComplaintTd",
                            staticStyle: { cursor: "pointer" },
                            attrs: { colspan: "3" },
                            on: {
                              click: function($event) {
                                return _vm.clickTitleShowContents(
                                  index,
                                  item,
                                  $event
                                )
                              }
                            }
                          },
                          [_vm._v(_vm._s(item.title))]
                        )
                      : _vm._e(),
                    item.contents == null
                      ? _c(
                          "td",
                          {
                            staticClass: "MyPgComplaintTd MyPgComplaintTdCenter"
                          },
                          [_vm._v(_vm._s(_vm.i18nDtFormat(item.acptDtm)))]
                        )
                      : _vm._e(),
                    item.contents == null
                      ? _c(
                          "td",
                          {
                            staticClass: "MyPgComplaintTd MyPgComplaintTdCenter"
                          },
                          [_vm._v(_vm._s(_vm.bbsStCd(item.bbsStCd)))]
                        )
                      : _vm._e(),
                    item.contents != null
                      ? _c(
                          "td",
                          { attrs: { colspan: "6" } },
                          [
                            _vm.show
                              ? _c(
                                  "data-table",
                                  {
                                    staticClass: "MyPageComplaintTbl004",
                                    attrs: { tblId: "tbl004" }
                                  },
                                  [
                                    _c("template", { slot: "tableInfo" }, [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm._f("i18n")("label.title")
                                          ) +
                                          ", " +
                                          _vm._s(
                                            _vm._f("i18n")("label.contents")
                                          ) +
                                          ", " +
                                          _vm._s(
                                            _vm._f("i18n")("label.readCnt")
                                          ) +
                                          ", " +
                                          _vm._s(
                                            _vm._f("i18n")("label.attchFile")
                                          ) +
                                          ", " +
                                          _vm._s(
                                            _vm._f("i18n")("label.reply")
                                          ) +
                                          " " +
                                          _vm._s(_vm._f("i18n")("label.rgt")) +
                                          " or " +
                                          _vm._s(
                                            _vm._f("i18n")("label.change")
                                          ) +
                                          " "
                                      )
                                    ]),
                                    _c("template", { slot: "colgroup" }, [
                                      _c("col", {
                                        staticStyle: { width: "150px" }
                                      }),
                                      _c("col", {
                                        staticStyle: { width: "auto" }
                                      }),
                                      _c("col", {
                                        staticStyle: { width: "150px" }
                                      }),
                                      _c("col", {
                                        staticStyle: { width: "auto" }
                                      })
                                    ]),
                                    _c(
                                      "template",
                                      {
                                        staticClass:
                                          "MyPageComplaintTbl004Body",
                                        slot: "tbody"
                                      },
                                      [
                                        item.bbsStCd === "00" &&
                                        item.status === "U"
                                          ? _c("tr", [
                                              _c(
                                                "td",
                                                { attrs: { colspan: "5" } },
                                                [
                                                  _c("input-text", {
                                                    staticStyle: {
                                                      width: "100%"
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.complaintMngVO
                                                          .title,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          _vm.complaintMngVO,
                                                          "title",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "complaintMngVO.title"
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            ])
                                          : _vm._e(),
                                        item.bbsStCd === "00" &&
                                        item.status === "U"
                                          ? _c("tr", [
                                              _c(
                                                "td",
                                                { attrs: { colspan: "5" } },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      style: {
                                                        height: _vm.height
                                                      }
                                                    },
                                                    [
                                                      _c("input-text-editor", {
                                                        directives: [
                                                          {
                                                            name: "show",
                                                            rawName: "v-show",
                                                            value:
                                                              item.bbsStCd ===
                                                              "00",
                                                            expression:
                                                              "item.bbsStCd==='00'"
                                                          }
                                                        ],
                                                        ref:
                                                          "complaintMngVOContents1",
                                                        refInFor: true,
                                                        staticStyle: {
                                                          width: "100%"
                                                        },
                                                        attrs: {
                                                          id:
                                                            "complaintMngVOContents",
                                                          height: _vm.height
                                                        },
                                                        on: {
                                                          getHtmlFromEditor:
                                                            _vm.getHtmlFromEditor
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  )
                                                ]
                                              )
                                            ])
                                          : _vm._e(),
                                        _vm.complaintMngVO.bbsStCd === "10"
                                          ? _c("tr", [
                                              _c(
                                                "th",
                                                {
                                                  staticClass:
                                                    "MyPgComplaintPaddingTh",
                                                  attrs: { rowspan: "3" }
                                                },
                                                [
                                                  _c("img", {
                                                    attrs: {
                                                      src: require("@/assets/img/button/icon_q.gif")
                                                    }
                                                  })
                                                ]
                                              ),
                                              _c(
                                                "td",
                                                {
                                                  staticClass:
                                                    "MyPgComplaintPaddingTdTitle",
                                                  staticStyle: {
                                                    overflow: "auto"
                                                  },
                                                  attrs: { colspan: "1" }
                                                },
                                                [
                                                  _c("div", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.complaintMngVO.title
                                                      )
                                                    )
                                                  ])
                                                ]
                                              ),
                                              _c(
                                                "td",
                                                {
                                                  staticClass:
                                                    "MyPgComplaintDate",
                                                  attrs: { colspan: "3" }
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.i18nDtFormat(
                                                          _vm.complaintMngVO
                                                            .acptDtm
                                                        )
                                                      ) +
                                                      " "
                                                  )
                                                ]
                                              )
                                            ])
                                          : _vm._e(),
                                        _vm.complaintMngVO.bbsStCd === "10"
                                          ? _c("tr", [
                                              _c(
                                                "td",
                                                {
                                                  staticClass:
                                                    "MyPgComplaintPaddingTdContents",
                                                  attrs: { colspan: "4" }
                                                },
                                                [
                                                  _c("div", [
                                                    _c("div", {
                                                      staticStyle: {
                                                        "min-height": "100px",
                                                        "max-height": "150px",
                                                        "overflow-y": "auto"
                                                      },
                                                      domProps: {
                                                        innerHTML: _vm._s(
                                                          _vm.decodeContents
                                                        )
                                                      }
                                                    })
                                                  ])
                                                ]
                                              )
                                            ])
                                          : _vm._e(),
                                        _vm.complaintMngVO.bbsStCd === "10"
                                          ? _c("tr", [
                                              _c(
                                                "td",
                                                { attrs: { colspan: "4" } },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass: "file-list"
                                                    },
                                                    _vm._l(
                                                      _vm.fileUpload.fileList,
                                                      function(file) {
                                                        return _c(
                                                          "span",
                                                          {
                                                            key: file.fileSeqNo,
                                                            staticClass:
                                                              "file-selected",
                                                            on: {
                                                              click: function(
                                                                $event
                                                              ) {
                                                                return _vm.downloadFile(
                                                                  file
                                                                )
                                                              }
                                                            }
                                                          },
                                                          [
                                                            _c(
                                                              "a",
                                                              {
                                                                attrs: {
                                                                  href:
                                                                    "javascript:;",
                                                                  title:
                                                                    file.orginlFileNm
                                                                }
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      file.orginlFileNm
                                                                    ) +
                                                                    " "
                                                                )
                                                              ]
                                                            )
                                                          ]
                                                        )
                                                      }
                                                    ),
                                                    0
                                                  )
                                                ]
                                              )
                                            ])
                                          : _vm._e(),
                                        _vm._l(_vm.replyDataList, function(
                                          reply,
                                          index
                                        ) {
                                          return [
                                            _c(
                                              "tr",
                                              {
                                                key:
                                                  _vm.replyDataList[index].bbsId
                                              },
                                              [
                                                _c(
                                                  "th",
                                                  {
                                                    staticClass:
                                                      "MyPgComplaintPaddingTh",
                                                    attrs: { rowspan: "3" }
                                                  },
                                                  [
                                                    _c("img", {
                                                      attrs: {
                                                        src: require("@/assets/img/button/icon_a.gif")
                                                      }
                                                    })
                                                  ]
                                                ),
                                                _c(
                                                  "td",
                                                  {
                                                    staticClass:
                                                      "MyPgComplaintPaddingTdTitle",
                                                    staticStyle: {
                                                      overflow: "auto"
                                                    },
                                                    attrs: { colspan: "1" }
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(reply.title) +
                                                        " "
                                                    )
                                                  ]
                                                ),
                                                _c(
                                                  "td",
                                                  {
                                                    staticClass:
                                                      "MyPgComplaintDate",
                                                    attrs: { colspan: "3" }
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.i18nDtFormat(
                                                            reply.hndlDtm
                                                          )
                                                        ) +
                                                        " "
                                                    )
                                                  ]
                                                )
                                              ]
                                            ),
                                            _c(
                                              "tr",
                                              {
                                                key:
                                                  _vm.replyDataList[index]
                                                    .bbsTypId
                                              },
                                              [
                                                _c(
                                                  "td",
                                                  {
                                                    staticClass:
                                                      "MyPgComplaintPaddingTdContents",
                                                    attrs: { colspan: "4" }
                                                  },
                                                  [
                                                    _c("div", [
                                                      _c("div", {
                                                        staticStyle: {
                                                          "min-height": "100px",
                                                          "max-height": "150px",
                                                          "overflow-y": "auto"
                                                        },
                                                        domProps: {
                                                          innerHTML: _vm._s(
                                                            _vm.getReplyContents(
                                                              reply.contents
                                                            )
                                                          )
                                                        }
                                                      })
                                                    ])
                                                  ]
                                                )
                                              ]
                                            ),
                                            _c(
                                              "tr",
                                              {
                                                key:
                                                  _vm.replyDataList[index]
                                                    .atchFileFkId
                                              },
                                              [
                                                _c(
                                                  "td",
                                                  { attrs: { colspan: "4" } },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass: "file-list"
                                                      },
                                                      _vm._l(
                                                        reply.atchFileList,
                                                        function(file) {
                                                          return _c(
                                                            "span",
                                                            {
                                                              key:
                                                                file.fileSeqNo,
                                                              staticClass:
                                                                "file-selected",
                                                              on: {
                                                                click: function(
                                                                  $event
                                                                ) {
                                                                  return _vm.downloadFile(
                                                                    file
                                                                  )
                                                                }
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "a",
                                                                {
                                                                  attrs: {
                                                                    href:
                                                                      "javascript:;",
                                                                    title:
                                                                      file.orginlFileNm
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        file.orginl_file_nm
                                                                      ) +
                                                                      " "
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          )
                                                        }
                                                      ),
                                                      0
                                                    )
                                                  ]
                                                )
                                              ]
                                            )
                                          ]
                                        }),
                                        _c("tr", [
                                          _c(
                                            "td",
                                            { attrs: { colspan: "4" } },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "file-wrap" },
                                                [
                                                  _c(
                                                    "button",
                                                    {
                                                      directives: [
                                                        {
                                                          name: "show",
                                                          rawName: "v-show",
                                                          value:
                                                            _vm.complaintMngVO
                                                              .status === "U" &&
                                                            _vm.complaintMngVO
                                                              .bbsStCd == "00",
                                                          expression:
                                                            "complaintMngVO.status==='U' && complaintMngVO.bbsStCd=='00' "
                                                        }
                                                      ],
                                                      staticClass: "file-box",
                                                      attrs: { type: "button" },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          $event.stopPropagation()
                                                          return _vm.fileAdd1(
                                                            $event
                                                          )
                                                        }
                                                      }
                                                    },
                                                    [
                                                      _c("input", {
                                                        ref: "file1",
                                                        refInFor: true,
                                                        attrs: {
                                                          id: "file1",
                                                          type: "file",
                                                          multiple: "",
                                                          accept:
                                                            _vm.fileUpload
                                                              .acceptExtention,
                                                          title:
                                                            "i18n('label.attchfile')"
                                                        },
                                                        on: {
                                                          change: function(
                                                            $event
                                                          ) {
                                                            $event.stopPropagation()
                                                            return _vm.uploadFile1(
                                                              $event
                                                            )
                                                          }
                                                        }
                                                      }),
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm._f("i18n")(
                                                              "label.file"
                                                            )
                                                          ) +
                                                          _vm._s(
                                                            _vm._f("i18n")(
                                                              "label.search"
                                                            )
                                                          ) +
                                                          " "
                                                      )
                                                    ]
                                                  ),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass: "file-list"
                                                    },
                                                    _vm._l(
                                                      _vm.fileUpload.fileList,
                                                      function(file) {
                                                        return _c(
                                                          "span",
                                                          {
                                                            directives: [
                                                              {
                                                                name: "show",
                                                                rawName:
                                                                  "v-show",
                                                                value:
                                                                  _vm
                                                                    .complaintMngVO
                                                                    .status ===
                                                                    "U" &&
                                                                  _vm
                                                                    .complaintMngVO
                                                                    .bbsStCd ==
                                                                    "00",
                                                                expression:
                                                                  "complaintMngVO.status==='U' && complaintMngVO.bbsStCd=='00' "
                                                              }
                                                            ],
                                                            key: file.fileSeqNo,
                                                            staticClass:
                                                              "file-selected",
                                                            on: {
                                                              click: function(
                                                                $event
                                                              ) {
                                                                return _vm.downloadFile(
                                                                  file
                                                                )
                                                              }
                                                            }
                                                          },
                                                          [
                                                            _c(
                                                              "a",
                                                              {
                                                                attrs: {
                                                                  href:
                                                                    "javascript:;",
                                                                  title:
                                                                    file.orginlFileNm
                                                                }
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      file.orginlFileNm
                                                                    ) +
                                                                    " "
                                                                )
                                                              ]
                                                            )
                                                          ]
                                                        )
                                                      }
                                                    ),
                                                    0
                                                  )
                                                ]
                                              )
                                            ]
                                          ),
                                          _c(
                                            "td",
                                            {
                                              staticStyle: {
                                                "text-align": "right"
                                              }
                                            },
                                            [
                                              item.bbsStCd === "00"
                                                ? _c(
                                                    "primary-button",
                                                    {
                                                      on: {
                                                        click: _vm.validate
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm._f("i18n")(
                                                            "button.update"
                                                          )
                                                        )
                                                      )
                                                    ]
                                                  )
                                                : _vm._e(),
                                              item.bbsStCd === "10"
                                                ? _c(
                                                    "primary-button",
                                                    {
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.addAsk(
                                                            $event,
                                                            index
                                                          )
                                                        }
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm._f("i18n")(
                                                            "label.addQuestion"
                                                          )
                                                        )
                                                      )
                                                    ]
                                                  )
                                                : _vm._e(),
                                              item.bbsStCd === "00"
                                                ? _c(
                                                    "default-button",
                                                    {
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.validateForDel(
                                                            item.bbsId
                                                          )
                                                        }
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm._f("i18n")(
                                                            "button.delete"
                                                          )
                                                        )
                                                      )
                                                    ]
                                                  )
                                                : _vm._e()
                                            ],
                                            1
                                          )
                                        ]),
                                        _c(
                                          "tr",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value: _vm.add1,
                                                expression: "add1"
                                              }
                                            ]
                                          },
                                          [
                                            _c(
                                              "td",
                                              { attrs: { colspan: "5" } },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    style: {
                                                      height: _vm.height
                                                    }
                                                  },
                                                  [
                                                    _c("input-text-editor", {
                                                      ref:
                                                        "complaintMngVOContents2",
                                                      refInFor: true,
                                                      staticStyle: {
                                                        width: "100%"
                                                      },
                                                      attrs: {
                                                        id:
                                                          "complaintMngVOContents",
                                                        height: _vm.height
                                                      },
                                                      on: {
                                                        getHtmlFromEditor:
                                                          _vm.getHtmlFromEditor1
                                                      }
                                                    })
                                                  ],
                                                  1
                                                )
                                              ]
                                            )
                                          ]
                                        ),
                                        _c(
                                          "tr",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value: _vm.add1,
                                                expression: "add1"
                                              }
                                            ]
                                          },
                                          [
                                            _c(
                                              "td",
                                              { attrs: { colspan: "4" } },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "file-wrap" },
                                                  [
                                                    _c(
                                                      "button",
                                                      {
                                                        staticClass: "file-box",
                                                        attrs: {
                                                          type: "button"
                                                        },
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            $event.stopPropagation()
                                                            return _vm.orgFileAdd(
                                                              $event
                                                            )
                                                          }
                                                        }
                                                      },
                                                      [
                                                        _c("input", {
                                                          ref: "orgFile",
                                                          refInFor: true,
                                                          attrs: {
                                                            id: "orgFile",
                                                            type: "file",
                                                            multiple: "",
                                                            accept:
                                                              _vm.fileUpload
                                                                .acceptExtention,
                                                            title:
                                                              "i18n('label.attchfile')"
                                                          },
                                                          on: {
                                                            change: function(
                                                              $event
                                                            ) {
                                                              $event.stopPropagation()
                                                              return _vm.uploadOrgFile(
                                                                $event
                                                              )
                                                            }
                                                          }
                                                        }),
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm._f("i18n")(
                                                                "label.file"
                                                              )
                                                            ) +
                                                            _vm._s(
                                                              _vm._f("i18n")(
                                                                "label.search"
                                                              )
                                                            ) +
                                                            " "
                                                        )
                                                      ]
                                                    ),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass: "file-list"
                                                      },
                                                      _vm._l(
                                                        _vm.fileUpload
                                                          .orgFileList,
                                                        function(file) {
                                                          return _c(
                                                            "span",
                                                            {
                                                              key:
                                                                file.fileSeqNo,
                                                              staticClass:
                                                                "file-selected",
                                                              on: {
                                                                click: function(
                                                                  $event
                                                                ) {
                                                                  return _vm.downloadFile(
                                                                    file
                                                                  )
                                                                }
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "a",
                                                                {
                                                                  attrs: {
                                                                    href:
                                                                      "javascript:;",
                                                                    title:
                                                                      file.orginlFileNm
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        file.orginlFileNm
                                                                      ) +
                                                                      " "
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          )
                                                        }
                                                      ),
                                                      0
                                                    )
                                                  ]
                                                )
                                              ]
                                            ),
                                            _c(
                                              "td",
                                              {
                                                staticStyle: {
                                                  "text-align": "right"
                                                }
                                              },
                                              [
                                                _c(
                                                  "primary-button",
                                                  {
                                                    on: { click: _vm.validate }
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm._f("i18n")(
                                                          "button.save"
                                                        )
                                                      )
                                                    )
                                                  ]
                                                )
                                              ],
                                              1
                                            )
                                          ]
                                        )
                                      ],
                                      2
                                    )
                                  ],
                                  2
                                )
                              : _vm._e()
                          ],
                          1
                        )
                      : _vm._e()
                  ])
                }),
                this.resultList.length == 0
                  ? _c("tr", { staticClass: "noArticleTr" }, [
                      _c(
                        "td",
                        {
                          staticClass: "MyPgComplaintNoArticleTd",
                          attrs: { colspan: "6" }
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm._f("i18n")("label.noComplaint")) +
                              " "
                          )
                        ]
                      )
                    ])
                  : _vm._e()
              ],
              2
            )
          ],
          2
        ),
        this.totalCount > this.pageUnit
          ? _c("pagination-for-table", {
              attrs: {
                totalItems: _vm.gridProps.totalItems,
                initPage: _vm.gridProps.initPage,
                perPage: _vm.pageSize
              },
              on: {
                fnListFromPaginationForTable: _vm.fnListFromPaginationForTable
              }
            })
          : _vm._e()
      ],
      1
    ),
    _vm.complaintMngVO.status === "I"
      ? _c(
          "div",
          [
            _c(
              "data-table",
              { attrs: { tblId: "tblInfo001" } },
              [
                _c("template", { slot: "tableInfo" }, [
                  _vm._v(
                    " " +
                      _vm._s(_vm._f("i18n")("label.title")) +
                      ", " +
                      _vm._s(_vm._f("i18n")("label.contents")) +
                      ", " +
                      _vm._s(_vm._f("i18n")("label.readCnt")) +
                      ", " +
                      _vm._s(_vm._f("i18n")("label.attchFile")) +
                      ", " +
                      _vm._s(_vm._f("i18n")("label.reply")) +
                      " " +
                      _vm._s(_vm._f("i18n")("label.rgt")) +
                      " or " +
                      _vm._s(_vm._f("i18n")("label.change")) +
                      " "
                  )
                ]),
                _c("template", { slot: "colgroup" }, [
                  _c("col", { staticStyle: { width: "150px" } }),
                  _c("col", { staticStyle: { width: "auto" } }),
                  _c("col", { staticStyle: { width: "150px" } }),
                  _c("col", { staticStyle: { width: "auto" } })
                ]),
                _c("template", { slot: "tbody" }, [
                  _c("tr", [
                    _c("th", { attrs: { scope: "row" } }, [
                      _c(
                        "label",
                        {
                          staticClass: "required",
                          attrs: { for: "complaintMngVOTitle" }
                        },
                        [
                          _c("span", { staticClass: "star" }, [
                            _vm._v(
                              _vm._s(_vm._f("i18n")("label.require")) +
                                _vm._s(_vm._f("i18n")("label.input"))
                            )
                          ]),
                          _vm._v(
                            " " + _vm._s(_vm._f("i18n")("label.title")) + " "
                          )
                        ]
                      )
                    ]),
                    _c(
                      "td",
                      { attrs: { colspan: "3" } },
                      [
                        _vm.complaintMngVO.status === "U" &&
                        _vm.complaintMngVO.bbsStCd == "10"
                          ? _c(
                              "label",
                              { attrs: { for: "complaintMngVOTitle" } },
                              [_vm._v(_vm._s(_vm.complaintMngVO.title))]
                            )
                          : _c("input-text", {
                              ref: "complaintMngVOTitle",
                              staticStyle: { width: "100%" },
                              attrs: {
                                id: "complaintMngVOTitle",
                                type: "text",
                                placeholder: _vm.i18n("label.title"),
                                maxlength: 500
                              },
                              model: {
                                value: _vm.complaintMngVO.title,
                                callback: function($$v) {
                                  _vm.$set(_vm.complaintMngVO, "title", $$v)
                                },
                                expression: "complaintMngVO.title"
                              }
                            })
                      ],
                      1
                    )
                  ]),
                  _c("tr", [
                    _c("th", { attrs: { scope: "row" } }, [
                      _c(
                        "label",
                        {
                          staticClass: "required",
                          attrs: { for: "complaintMngVOContents" }
                        },
                        [
                          _c("span", { staticClass: "star" }, [
                            _vm._v(
                              _vm._s(_vm._f("i18n")("label.require")) +
                                _vm._s(_vm._f("i18n")("label.input"))
                            )
                          ]),
                          _vm._v(
                            " " + _vm._s(_vm._f("i18n")("label.contents")) + " "
                          )
                        ]
                      )
                    ]),
                    _c("td", { attrs: { colspan: "3" } }, [
                      _vm.complaintMngVO.status === "U" &&
                      _vm.complaintMngVO.bbsStCd == "10"
                        ? _c("div", {
                            staticStyle: {
                              "min-height": "100px",
                              "max-height": "150px",
                              "overflow-y": "auto"
                            },
                            domProps: { innerHTML: _vm._s(_vm.decodeContents) }
                          })
                        : _c(
                            "div",
                            { style: { height: _vm.height } },
                            [
                              _c("input-text-editor", {
                                ref: "complaintMngVOContents",
                                staticStyle: { width: "100%" },
                                attrs: {
                                  id: "complaintMngVOContents",
                                  height: _vm.height
                                },
                                on: { getHtmlFromEditor: _vm.getHtmlFromEditor }
                              })
                            ],
                            1
                          )
                    ])
                  ]),
                  _c("tr", [
                    _c("th", { attrs: { scope: "row" } }, [
                      _c(
                        "label",
                        { attrs: { for: "complaintMngVOAtchFileFkId" } },
                        [_vm._v(_vm._s(_vm._f("i18n")("label.attchFile")))]
                      )
                    ]),
                    _c("td", { attrs: { colspan: "2" } }, [
                      _c("div", { staticClass: "file-wrap" }, [
                        _vm.complaintMngVO.status === "I" ||
                        (_vm.complaintMngVO.status === "U" &&
                          _vm.complaintMngVO.bbsStCd == "00")
                          ? _c(
                              "button",
                              {
                                staticClass: "file-box",
                                attrs: { type: "button" },
                                on: {
                                  click: function($event) {
                                    $event.stopPropagation()
                                    return _vm.fileAdd($event)
                                  }
                                }
                              },
                              [
                                _c("input", {
                                  ref: "file",
                                  attrs: {
                                    id: "file",
                                    type: "file",
                                    multiple: "",
                                    accept: _vm.fileUpload.acceptExtention,
                                    title: "i18n('label.attchfile')"
                                  },
                                  on: {
                                    change: function($event) {
                                      $event.stopPropagation()
                                      return _vm.uploadFile($event)
                                    }
                                  }
                                }),
                                _vm._v(
                                  " " +
                                    _vm._s(_vm._f("i18n")("label.file")) +
                                    _vm._s(_vm._f("i18n")("label.search")) +
                                    " "
                                )
                              ]
                            )
                          : _vm._e(),
                        _c(
                          "div",
                          { staticClass: "file-list" },
                          _vm._l(_vm.fileUpload.fileList, function(file) {
                            return _c(
                              "span",
                              {
                                key: file.fileSeqNo,
                                staticClass: "file-selected",
                                on: {
                                  click: function($event) {
                                    return _vm.downloadFile(file)
                                  }
                                }
                              },
                              [
                                _c(
                                  "a",
                                  {
                                    attrs: {
                                      href: "javascript:;",
                                      title: file.orginlFileNm
                                    }
                                  },
                                  [
                                    _vm._v(
                                      " " + _vm._s(file.orginlFileNm) + " "
                                    )
                                  ]
                                ),
                                _vm.userInfo.userId ===
                                _vm.complaintMngVO.acptUserId
                                  ? _c(
                                      "button",
                                      {
                                        attrs: { type: "button" },
                                        on: {
                                          click: function($event) {
                                            $event.stopPropagation()
                                            return _vm.deleteFile(file)
                                          }
                                        }
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(_vm._f("i18n")("label.file")) +
                                            _vm._s(_vm._f("i18n")("label.del"))
                                        )
                                      ]
                                    )
                                  : _vm._e()
                              ]
                            )
                          }),
                          0
                        )
                      ])
                    ]),
                    _c(
                      "td",
                      { staticStyle: { "text-align": "right" } },
                      [
                        _c("primary-button", { on: { click: _vm.validate } }, [
                          _vm._v(_vm._s(_vm._f("i18n")(_vm.button)))
                        ]),
                        _c(
                          "default-button",
                          { on: { click: _vm.clearSearch } },
                          [_vm._v(_vm._s(_vm._f("i18n")("button.cancel")))]
                        )
                      ],
                      1
                    )
                  ]),
                  _vm.complaintMngVO.status === "U"
                    ? _c("tr", [
                        _c("th", { attrs: { scope: "row" } }, [
                          _c(
                            "label",
                            { attrs: { for: "complaintMngVORplyContents" } },
                            [_vm._v(_vm._s(_vm._f("i18n")("label.reply")))]
                          )
                        ]),
                        _c(
                          "td",
                          {
                            staticStyle: { overflow: "auto" },
                            attrs: { colspan: "3" }
                          },
                          [
                            _c("div", {
                              staticStyle: {
                                "min-height": "100px",
                                "max-height": "150px",
                                "overflow-y": "auto"
                              },
                              domProps: {
                                innerHTML: _vm._s(_vm.decodeReplyContents)
                              }
                            })
                          ]
                        )
                      ])
                    : _vm._e(),
                  _vm.complaintMngVO.status === "U"
                    ? _c("tr", [
                        _c("th", { attrs: { scope: "row" } }, [
                          _c(
                            "label",
                            {
                              attrs: { for: "complaintMngVORplyAtchFileFkId" }
                            },
                            [_vm._v(_vm._s(_vm._f("i18n")("label.attchFile")))]
                          )
                        ]),
                        _c("td", [
                          _c("div", { staticClass: "file-wrap" }, [
                            _c(
                              "div",
                              { staticClass: "file-list" },
                              _vm._l(_vm.fileUpload.rplyFileList, function(
                                file
                              ) {
                                return _c(
                                  "span",
                                  {
                                    key: file.fileSeqNo,
                                    staticClass: "file-selected",
                                    on: {
                                      click: function($event) {
                                        return _vm.downloadFile(file)
                                      }
                                    }
                                  },
                                  [
                                    _c(
                                      "a",
                                      {
                                        attrs: {
                                          href: "javascript:;",
                                          title: file.orginlFileNm
                                        }
                                      },
                                      [
                                        _vm._v(
                                          " " + _vm._s(file.orginlFileNm) + " "
                                        )
                                      ]
                                    ),
                                    _c(
                                      "button",
                                      {
                                        attrs: { type: "button" },
                                        on: {
                                          click: function($event) {
                                            $event.stopPropagation()
                                            return _vm.deleteFile(file)
                                          }
                                        }
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(_vm._f("i18n")("label.file")) +
                                            _vm._s(_vm._f("i18n")("label.del"))
                                        )
                                      ]
                                    )
                                  ]
                                )
                              }),
                              0
                            )
                          ])
                        ])
                      ])
                    : _vm._e()
                ])
              ],
              2
            )
          ],
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }