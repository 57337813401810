// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@/assets/img/sp-pc.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".pc button[data-v-1175ec7c] {\n  min-width: 52px;\n  min-height: 27px;\n  padding: 7px 15px 6px;\n  color: #fff;\n  background-color: #2178c8;\n  border-radius: 0;\n  vertical-align: middle;\n  cursor: pointer;\n}\n.pc button.default[data-v-1175ec7c] {\n    background-color: #666;\n}\n.pc button.btn-gray[data-v-1175ec7c] {\n    background-color: #e3e3e3;\n    color: #666;\n}\n.pc button[data-v-1175ec7c]:disabled {\n    padding: 7px 15px 5px;\n    color: #666;\n    background-color: #ededed;\n    border: solid 1px #cbcbcb;\n}\n.pc _[data-v-1175ec7c]:-ms-lang(x), .pc button[data-v-1175ec7c] {\n  padding: 8px 15px 5px;\n}\n.pc button + [type=button][data-v-1175ec7c],\n.pc button.primary + .default[data-v-1175ec7c] {\n  margin-left: 2px;\n}\n.pc .excel-down[data-v-1175ec7c] {\n  min-width: 52px;\n  height: 27px;\n  margin-right: 2px;\n  padding: 6px 15px 4px;\n  color: #fff;\n  vertical-align: middle;\n  letter-spacing: 0;\n  background-color: #666;\n  border-radius: 0;\n}\n.pc .excel-down img[data-v-1175ec7c] {\n    display: inline-block;\n    vertical-align: -2px;\n}\n.pc .pop-wrap .excel-down[data-v-1175ec7c] {\n  border-radius: 3px;\n}\n.sp[data-v-1175ec7c] {\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat;\n}\n.sp-b[data-v-1175ec7c]::before {\n  content: \"\";\n  display: inline-block;\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat;\n}\n.sp-a[data-v-1175ec7c]::after {\n  content: \"\";\n  display: inline-block;\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat;\n}\ni[class^=sp][data-v-1175ec7c], a[class^=sp][data-v-1175ec7c], label[class^=sp][data-v-1175ec7c] {\n  display: inline-block;\n  vertical-align: middle;\n  font-size: 0 !important;\n}\n", ""]);
// Exports
module.exports = exports;
