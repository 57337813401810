var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "side-top-wrap" },
        [
          _c(
            "div",
            { staticClass: "side-top-area" },
            [
              _c(
                "span",
                {
                  staticStyle: { cursor: "pointer" },
                  on: { click: _vm.checkPwdPopup }
                },
                [
                  _c("img", {
                    staticClass: "profile",
                    attrs: {
                      alt: "profile photo",
                      width: "55",
                      height: "55",
                      src: _vm.psnImage
                    }
                  })
                ]
              ),
              _vm.isShow.checkPwdPopup
                ? _c("cmn-check-password-popup", {
                    attrs: { userId: _vm.getUserId },
                    on: {
                      close: _vm.closeCheckPwdPop,
                      showMyPagePop: _vm.showMyPagePop
                    }
                  })
                : _vm._e(),
              _vm.isShow.myPagePopup
                ? _c("cmn-my-page-popup", {
                    on: {
                      close: _vm.closeMypagePop,
                      refreshProfile: _vm.getPsnDetail
                    }
                  })
                : _vm._e(),
              _c("div", { staticClass: "profile-box" }, [
                _c(
                  "button",
                  { staticClass: "badge", on: { click: _vm.showTodoList } },
                  [_vm._v(_vm._s(_vm.totalCnt))]
                ),
                _c("p", [
                  _c(
                    "span",
                    {
                      staticStyle: { cursor: "pointer" },
                      on: { click: _vm.checkPwdPopup }
                    },
                    [_vm._v(_vm._s(_vm.psnNm))]
                  )
                ])
              ]),
              this.userAuth.userId != "pcidss_office" &&
              this.userAuth.userId != "pcidss_merchant" &&
              this.userAuth.userId != "pcidss_agency" &&
              this.userAuth.userId != "pcidss_bank" &&
              this.userAuth.userId != "pcidss_office1" &&
              this.userAuth.userId != "pcidss_merchant1" &&
              this.userAuth.userId != "pcidss_agency1" &&
              this.userAuth.userId != "pcidss_bank1"
                ? _c("div", [
                    _c("div", { staticClass: "btn-box" }, [
                      _vm.isAuthorMerPtMng
                        ? _c(
                            "button",
                            {
                              staticClass: "sp-b icon-info",
                              attrs: { type: "button" },
                              on: { click: _vm.routeMerPtMng }
                            },
                            [_vm._v(_vm._s(_vm._f("i18n")("menu.210")))]
                          )
                        : _vm._e(),
                      _vm.isAuthorCardTrLstMng
                        ? _c(
                            "button",
                            {
                              staticClass: "sp-b icon-card",
                              attrs: { type: "button" },
                              on: { click: _vm.routeCardTrLstMng }
                            },
                            [_vm._v(_vm._s(_vm._f("i18n")("menu.10007")))]
                          )
                        : _vm._e()
                    ])
                  ])
                : _vm._e()
            ],
            1
          ),
          this.userAuth.userId != "pcidss_office" &&
          this.userAuth.userId != "pcidss_merchant" &&
          this.userAuth.userId != "pcidss_agency" &&
          this.userAuth.userId != "pcidss_bank" &&
          this.userAuth.userId != "pcidss_office1" &&
          this.userAuth.userId != "pcidss_merchant1" &&
          this.userAuth.userId != "pcidss_agency1" &&
          this.userAuth.userId != "pcidss_bank1"
            ? _c(
                "div",
                [
                  _vm.isShow.todoList
                    ? _c("todo-list", {
                        attrs: {
                          resultList: _vm.resultList,
                          newList: _vm.newList,
                          lastConnIp: _vm.lastConnIp
                        },
                        on: {
                          closeBtn: _vm.closeTodoList,
                          closeCookie: _vm.setCookieTodo,
                          getClickedMenuInfo: _vm.getClickedMenuInfo
                        }
                      })
                    : _vm._e()
                ],
                1
              )
            : _vm._e(),
          _c("hamburger2", {
            staticClass: "sidebar-btn-box",
            on: {
              quickMenuClick: _vm.showQuickMenu,
              listMenuClick: _vm.showlistMenu
            }
          }),
          _c("hamburger", {
            staticClass: "hamburger-container",
            attrs: {
              id: "hamburger-container",
              "is-active": _vm.sidebar.opened
            },
            on: { toggleClick: _vm.toggleSideBar }
          })
        ],
        1
      ),
      _vm.isShow.quickMenu &&
      this.userAuth.userId != "pcidss_office" &&
      this.userAuth.userId != "pcidss_merchant" &&
      this.userAuth.userId != "pcidss_agency" &&
      this.userAuth.userId != "pcidss_bank" &&
      this.userAuth.userId != "pcidss_office1" &&
      this.userAuth.userId != "pcidss_merchant1" &&
      this.userAuth.userId != "pcidss_agency1" &&
      this.userAuth.userId != "pcidss_bank1"
        ? _c(
            "el-scrollbar",
            { attrs: { "wrap-class": "scrollbar-wrapper quick-menu" } },
            [
              _c(
                "router-link",
                {
                  staticClass: "setting sp-b",
                  attrs: { to: "/admin/QuickMenuMng" }
                },
                [_vm._v("Setting")]
              ),
              _vm.isShow.quickMenu
                ? _c(
                    "el-menu",
                    { attrs: { mode: "vertical", router: "" } },
                    _vm._l(_vm.quickMenuList, function(quick) {
                      return _c(
                        "el-menu-item",
                        {
                          key: quick.id,
                          attrs: { index: quick.name, route: quick }
                        },
                        [
                          quick
                            ? _c(
                                "a",
                                {
                                  attrs: {
                                    slot: "title",
                                    href: "javascript:;"
                                  },
                                  slot: "title"
                                },
                                [_vm._v(_vm._s(quick.title))]
                              )
                            : _vm._e()
                        ]
                      )
                    }),
                    1
                  )
                : _vm._e()
            ],
            1
          )
        : _vm._e(),
      _vm.isShow.listMenu &&
      this.userAuth.userId != "pcidss_office" &&
      this.userAuth.userId != "pcidss_merchant" &&
      this.userAuth.userId != "pcidss_agency" &&
      this.userAuth.userId != "pcidss_bank"
        ? _c(
            "el-scrollbar",
            { attrs: { "wrap-class": "scrollbar-wrapper" } },
            [
              _c(
                "el-menu",
                {
                  attrs: {
                    "default-active": _vm.activeMenu,
                    collapse: _vm.isCollapse,
                    "background-color": _vm.variables.menuBg,
                    "text-color": _vm.variables.menuText,
                    "unique-opened": true,
                    "active-text-color": _vm.variables.menuActiveText,
                    "collapse-transition": true,
                    mode: "vertical",
                    router: "",
                    id: "elMenu"
                  }
                },
                [
                  _vm._l(_vm.routes, function(route) {
                    return [
                      route.children !== null
                        ? _c(
                            "el-submenu",
                            {
                              key: route.name,
                              ref: "subMenu",
                              refInFor: true,
                              attrs: {
                                index: route.name,
                                "popper-append-to-body": ""
                              }
                            },
                            [
                              _c("template", { slot: "title" }, [
                                route.title
                                  ? _c(
                                      "a",
                                      {
                                        attrs: {
                                          slot: "title",
                                          href: "javascript:;"
                                        },
                                        slot: "title"
                                      },
                                      [_vm._v(_vm._s(route.title))]
                                    )
                                  : _vm._e()
                              ]),
                              _vm._l(route.children, function(child) {
                                return _c(
                                  "el-menu-item",
                                  {
                                    key: child.id,
                                    attrs: { index: child.name, route: child }
                                  },
                                  [
                                    child.title
                                      ? _c(
                                          "a",
                                          {
                                            attrs: {
                                              slot: "title",
                                              href: "javascript:;"
                                            },
                                            slot: "title"
                                          },
                                          [_vm._v(_vm._s(child.title))]
                                        )
                                      : _vm._e()
                                  ]
                                )
                              })
                            ],
                            2
                          )
                        : _vm._e()
                    ]
                  })
                ],
                2
              )
            ],
            1
          )
        : _vm._e(),
      _vm.isShowChangePasswordPopup
        ? _c("change-password-popup", {
            attrs: { chgPassOrgType: "LOG_IN" },
            on: {
              close: function($event) {
                _vm.isShowChangePasswordPopup = false
              }
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }