export default [
    
    {
        name: "AddrMng",
        path: "/biz/bep/svc/AddrMng",
        component: () => import("@/views/biz/bep/svc/AddrMng"),
        meta: { layout: "admin" }
  
      },
    {
        name: "SttlMng",
        path: "/biz/bep/sttl/SttlMng",
        component: () => import("@/views/biz/bep/sttl/SttlMng"),
        meta: { layout: "admin" }
  
      },
      {
        name: "BilngMng",
        path: "/biz/bep/bilng/BilngMng",
        component: () => import("@/views/biz/bep/bilng/BilngMng"),
        meta: { layout: "admin" }
  
      },
      {
        name: "SttlDfnMng",
        path: "/biz/bep/sttl/SttlDfnMng",
        component: () => import("@/views/biz/bep/sttl/SttlDfnMng"),
        meta: { layout: "admin" }
  
      },
      {
        name: "SttlDfnDtlMng",
        path: "/biz/bep/sttl/SttlDfnDtlMng",
        component: () => import("@/views/biz/bep/sttl/SttlDfnDtlMng"),
        meta: { layout: "admin" }
  
      },
      {
        name: "DccMng",
        path: "/biz/bep/svc/DccMng",
        component: () => import("@/views/biz/bep/svc/DccMng"),
        meta: { layout: "admin" }
  
      },
      {
        name: "ActlSumDay",
        path: "/biz/bep/sal/ActlSumDay",
        component: () => import("@/views/biz/bep/sal/ActlSumDay"),
        meta: { layout: "admin" }
  
      },
      {
        name: "ActlSumMon",
        path: "/biz/bep/sal/ActlSumMon",
        component: () => import("@/views/biz/bep/sal/ActlSumMon"),
        meta: { layout: "admin" }
  
      },
      {
        name: "CardTrLstMng",
        path: "/biz/bep/trLst/CardTrLstMng",
        component: () => import("@/views/biz/bep/trLst/CardTrLstMng"),
        meta: { layout: "admin" }
  
      },
      {
        name: "HistLstMng",
        path: "/biz/bep/mng/HistLstMng",
        component: () => import("@/views/biz/bep/mng/HistLstMng"),
        meta: { layout: "admin" }
  
      },
      {
        name: "PontTrLstMng",
        path: "/biz/bep/trLst/PontTrLstMng",
        component: () => import("@/views/biz/bep/trLst/PontTrLstMng"),
        meta: { layout: "admin" }
  
      },
      {
        name: "CashRcptTrLstMng",
        path: "/biz/bep/trLst/CashRcptTrLstMng",
        component: () => import("@/views/biz/bep/trLst/CashRcptTrLstMng"),
        meta: { layout: "admin" }
  
      },
      {
        name: "StipLmtMng",
        path: "/biz/bep/merinst/StipLmtMng",
        component: () => import("@/views/biz/bep/merinst/StipLmtMng"),
        meta: { layout: "admin" }
  
      },
      {
        name: "InstAggMng",
        path: "/biz/bep/agg/InstAggMng",
        component: () => import("@/views/biz/bep/agg/InstAggMng"),
        meta: { layout: "admin" }
  
      },
      {
        name: "InstBizAggMng",
        path: "/biz/bep/agg/InstBizAggMng",
        component: () => import("@/views/biz/bep/agg/InstBizAggMng"),
        meta: { layout: "admin" }
  
      },
      {
        name: "InstMerAggMng",
        path: "/biz/bep/agg/InstMerAggMng",
        component: () => import("@/views/biz/bep/agg/InstMerAggMng"),
        meta: { layout: "admin" }
  
      },
      {
        name: "SettleHistLstMng",
        path: "/biz/bep/trLst/SettleHistLstMng",
        component: () => import("@/views/biz/bep/trLst/SettleHistLstMng"),
        meta: { layout: "admin" }
  
      },
    //포인트거래 모바일
    {
      name: "MPontTrHistMng",
      path: "/m/biz/PontTrHistMng",
      component: () => import("@/views/mobile/biz/bep/MPontTrHistMng"),
      meta: { layout: "mobile-admin" },
    },
    
    {
      name: "SyncCrdtListMng",
      path: "/biz/bep/trLst/SyncCrdtListMng",
      component: () => import("@/views/biz/bep/trLst/SyncCrdtListMng"),
      meta: { layout: "admin" }

    },
    { 
      name: "PosInfoMng",
      path: "/biz/bep/mng/PosInfoMng",
      component: () => import("@/views/biz/bep/mng/PosInfoMng"),
      meta: { layout: "admin" }
    },
    { 
      name: "PosListMng",
      path: "/biz/bep/pos/PosListMng",
      component: () => import("@/views/biz/bep/pos/PosListMng"),
      meta: { layout: "admin" }
    },
    { 
      name: "PosSaveMng",
      path: "/biz/bep/pos/PosSaveMng",
      component: () => import("@/views/biz/bep/pos/PosSaveMng"),
      meta: { layout: "admin" }
    },
    { 
      name: "PosMultiSaveMng",
      path: "/biz/bep/pos/PosMultiSaveMng",
      component: () => import("@/views/biz/bep/pos/PosMultiSaveMng"),
      meta: { layout: "admin" }
    },
    {
      name: "LoginHisMng",
      path: "/admin/LoginHisMng",
      component: () => import("@/views/admin/LoginHisMng"),
      meta: { layout: "admin" }
    },
    {
      name: "MerCustGrpMng",
      path: "/biz/bep/merinst/MerCustGrpMng",
      component: () => import("@/views/biz/bep/merinst/MerCustGrpMng"),
      meta: { layout: "admin" }
    },
    {
      name: "MerCustInfoMng",
      path: "/biz/bep/merinst/MerCustInfoMng",
      component: () => import("@/views/biz/bep/merinst/MerCustInfoMng"),
      meta: { layout: "admin" }
    },
    {
      name: "MerSmsHistMng",
      path: "/biz/bep/mersms/MerSmsHistMng",
      component: () => import("@/views/biz/bep/mersms/MerSmsHistMng"),
      meta: { layout: "admin" }
    },
    {
      name: "MerSmsSingleRegMng",
      path: "/biz/bep/mersms/MerSmsSingleRegMng",
      component: () => import("@/views/biz/bep/mersms/MerSmsSingleRegMng"),
      meta: { layout: "admin" }
    },
    {
      name: "MerSmsMultiRegMng",
      path: "/biz/bep/mersms/MerSmsMultiRegMng",
      component: () => import("@/views/biz/bep/mersms/MerSmsMultiRegMng"),
      meta: { layout: "admin" }
    },
    {
      name: "BilngMngNew",
      path: "/biz/bep/bilngNew/BilngMngNew",
      component: () => import("@/views/biz/bep/bilngNew/BilngMngNew"),
      meta: { layout: "admin" }
    },
    {
      name: "BilngQryMngNew",
      path: "/biz/bep/bilngNew/BilngQryMngNew",
      component: () => import("@/views/biz/bep/bilngNew/BilngQryMngNew"),
      meta: { layout: "admin" }
    },
]
