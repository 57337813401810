<template>
  <popup
    :title="title"
    @close="$emit('close')"
    popSize="size-page"
    :myPagePopup="true"
  >
    <ul id="tags-view-container" class="tags-view-container">
      <!-- 사이즈에 관계없이 데이터를 붙이시면 넓이에 맞춰서 맞게 들어갑니다 -->
      <li
        v-for="tag in tagViews"
        ref="tag"
        :key="tag.name"
        class="tags-view-item"
        :class="isActive(tag) ? 'active' : ''"
        :title="tag.title"
        @click.stop="activeTag(tag)"
      >
        <a href="javascript:;">{{ tag.title }}</a>
      </li>
    </ul>
    <transition name="fade-transform" mode="out-in">
      <keep-alive class="tab-content">
        <my-page-popup-psn
          v-if="activeTagName === 'MyPagePopupPsn'"
          @close="$emit('close')"
          @refreshProfile="$emit('refreshProfile')"
        />
        <my-page-popup-complaint
          v-if="activeTagName === 'MyPagePopupComplaint'"
        />
        <my-page-popup-faq v-if="activeTagName === 'MyPagePopupFaq'" />
      </keep-alive>
    </transition>
  </popup>
</template>

<script>
import Popup from "@/components/Popup";
import ScrollPane from "@/layout/admin/components/TagsView/ScrollPane";

import { EventBus } from "./EventBus";
import { CmnMyPagePopupViewModel, Helper, AnimationHelper } from "./mixins";
import { isEmpty } from "@/utils";

import MyPagePopupPsn from "./MyPagePopupPsn";
import MyPagePopupComplaint from "./MyPagePopupComplaint";
import MyPagePopupFaq from "./MyPagePopupFaq";

export default {
  name: "CmnMyPagePopup",
  components: {
    Popup,
    ScrollPane,
    MyPagePopupPsn,
    MyPagePopupComplaint,
    MyPagePopupFaq
  },
  mixins: [CmnMyPagePopupViewModel, Helper, AnimationHelper],
  data() {
    return {
      activeTagName: "MyPagePopupPsn",
      tagViews: [],
      data: []
    };
  },
  computed: {
    title() {
      return "My Page";
    }
  },
  mounted() {
    this.currentTagName = this.activeTagName;

    this.tagViews = [
      { name: "MyPagePopupPsn", title: this.i18n("label.myInfo") },
      { name: "MyPagePopupComplaint", title: this.i18n("label.complaintInfo") },
      { name: "MyPagePopupFaq", title: this.i18n("label.faq") }
    ];
  },
  methods: {
    isActive(tag) {
      return this.activeTagName === tag.name;
    },
    activeTag(tag) {
      this.activeTagName = tag.name;
    }
  }
};
</script>
<style lang="scss" scoped>
@import "@/scss/_color.scss";
@import "@/scss/_icon.scss";

.tags-view-container {
  display: table;
  width: 100%;
  height: 28px;
  table-layout: fixed;
  background: $cf;
  border-bottom: 1px solid $pale;
  .tags-view-item {
    overflow: hidden;
    display: table-cell;
    height: 28px;
    text-align: center;
    vertical-align: middle;
    > a {
      position: relative;
      display: block;
      height: 31px;
      margin-bottom: -1px;
      margin-left: 2px;
      padding: 0 15px;
      font-size: 12px;
      color: $c4;
      line-height: 28px;
      letter-spacing: -0.5px;
      border: 1px solid $cc6;
      border-radius: 3px 3px 0 0;
      background: $cec;
      cursor: pointer;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    &:first-of-type {
      > a {
        margin-left: 0;
      }
    }
    &.active {
      overflow: visible;
      > a {
        font-weight: bold;
        background: linear-gradient(#e8f0f9 5%, #fff 95%);
        border-color: $pale;
        border-bottom-color: $cf;
        &::after {
          content: "";
          position: absolute;
          bottom: -1px;
          left: -1px;
          display: block;
          width: 100%;
          height: 1px;
          background-color: $cf;
        }
      }
    }
    .home {
      @include home;
      position: absolute;
      top: calc(50% - 1px);
      left: calc(50% - 6px);
      width: 14px;
      height: 10px;
      &::before {
        top: -10px;
        border-width: 5px 7px;
      }
      &::after {
        background-color: $ce3;
        left: 5px;
        width: 4px;
        height: 6px;
        border-radius: 1px;
      }
    }
  }
}
.tab-txt-close {
  position: absolute;
  top: 17px;
  right: 30px;
  font: {
    size: 11px;
    weight: bold;
  }
  color: $c6;
  letter-spacing: -0.5px;
  background-color: $cf;
}
</style>
