/**
 * 1. 개요 : 공통 비밀번호 확인 팝업
 * 2. 처리내용 : 공통 비밀번호 확인 팝업 - 진입점
 * index.vue
 * @date : 2020. 08. 12.  10:06:00
 * @author : 이지혜
 * @history :
 *-----------------------------------------------------------------------
 * 변경일			          		    작성자		변경내용
 *-----------------------------------------------------------------------
 * 2020. 08. 12.  10:06:00	    이지혜	  최초 작성
 *-----------------------------------------------------------------------
 */
<template>
	<popup :title="title" @close="$emit('close')" popSize="size-500">
		<data-table class="table">
			<template slot="caption">{{ title }}</template>
			<template slot="colgroup">
				<col style="width:150px" />
				<col style="width:auto" />
			</template>
			<template slot="tbody">
				<tr>
					<th scope="row">
						<label>
							{{ this.isLogin
							? this.i18n("label.userId")
							: i18nOff("label.userId") }}
						</label>
					</th>
					<td>
						<label>{{ userId }}</label>
						<!-- <input-text
							id="confirmPwdVOUserId"
							v-model="userId"
							type="text"
							:placeholder="`${i18nOff('label.userId')}`"
							ref="confirmPwdVOUserId"
							:disabled="true"
						/>-->
					</td>
				</tr>
				<tr>
					<th scope="row">
						<label for="confirmPwdVOPassword">
							{{ this.isLogin
							? this.i18n("label.pwd")
							: i18nOff("label.pwd")}}
						</label>
					</th>
					<td>
						<input-text
							id="confirmPwdVOPassword"
							v-model="password"
							:type="passwordType"
							:placeholder="`${this.isLogin ? this.i18n('label.pwd') : i18nOff('label.pwd')}`"
							ref="confirmPwdVOPassword"
							@keydown.enter.prevent="validate"
						/>
						<button type="button" class="el-eye new" @click.stop="showpassword" data-icon="off">
							{{
							passwordType === "password" ? (this.isLogin ? this.i18n('label.viewPassword') : i18nOff('label.viewPassword'))
							: (this.isLogin ? this.i18n('label.notViewPassword') : i18nOff('label.notViewPassword'))
							}}
							<img
								src="~@/assets/img/icon/eye-open.svg"
								width="24"
							/>
						</button>
					</td>
				</tr>
			</template>
		</data-table>
		<template slot="buttons">
			<primary-button @click="validate">
				{{ this.isLogin
				? this.i18n("label.confirm")
				: i18nOff("label.confirm") }}
			</primary-button>
			<default-button @click="clear">
				{{ this.isLogin
				? this.i18n("button.init")
				: i18nOff("button.init") }}
			</default-button>
			<default-button @click="$emit('close')">
				{{ this.isLogin
				? this.i18n("button.cancel")
				: i18nOff("button.cancel") }}
			</default-button>
		</template>
	</popup>
</template>

<script>
import { validUserId, validPassword } from "@/utils/validate";
import DataTable from "@/components/DataTable";
import Popup from "@/components/Popup";
import { Helper } from "./mixins";
import { isEmpty } from "@/utils";

export default {
	name: "CmnCheckPasswordPopup",
	components: {
		DataTable,
		Popup
	},
	mixins: [Helper],
	props: {
		userId: {
			type: String,
			default: ""
		}
	},
	data() {
		return {
			password: "",
			isValidPassword: "",

			passwordType: "password",
			confirmPasswordType: "password"
		};
	},
	computed: {
		// 팝업창 타이틀
		title() {
			return this.isLogin
				? this.i18n("label.pwdConfirm")
				: this.i18nOff("label.pwdConfirm");
		}
	},
	mounted() {
		this.focus();
	},
	methods: {
		// 포커스
		focus() {
			this.$nextTick(() => {
				this.$refs.confirmPwdVOPassword.focus();
			});
		},

		// 입력화면 초기화
		clear() {
			this.password = "";

			(this.passwordType = "password"),
				(this.confirmPasswordType = "password"),
				this.$nextTick(() => {
					this.$refs.confirmPwdVOPassword.focus();
				});
		},

		// 비밀번호 암호화 <-> 비암호화
		showpassword() {
			let eleye = document.querySelector(".el-eye.new");
			if (this.passwordType === "password") {
				this.passwordType = "text";
				eleye.dataset.icon = "on";
			} else {
				this.passwordType = "password";
				eleye.dataset.icon = "off";
			}
			this.$nextTick(() => this.$refs.confirmPwdVOPassword.focus());
		},

		// 비밀번호(재입력) 암호회 <-> 비암호화
		showConfirmPassword() {
			let eleye = document.querySelector(".el-eye.confirm");
			if (this.confirmPasswordType === "password") {
				this.confirmPasswordType = "text";
				eleye.dataset.icon = "on";
			} else {
				this.confirmPasswordType = "password";
				eleye.dataset.icon = "off";
			}
			this.$nextTick(() => this.$refs.confirmPwdVOConfirmPassword.focus());
		},

		// 벨리데이션
		validate() {
			if (
				this.validateHelper([
					{
						ref: this.$refs.confirmPwdVOPassword,
						isValidate: this.password,
						message: this.isLogin
							? this.i18n("alt.inputPwd")
							: this.i18nOff("alt.inputPwd")
					}
					// 비밀번호 양식 벨리데이션. 개발 완료 후 주석 풀기.
					// {
					// 	ref: this.$refs.confirmPwdVOPassword,
					// 	isValidate: !isEmpty(this.password) && validPassword(this.password),
					// 	message: this.i18nOff("alt.validUserPwd")
					// },
				])
			) {
				return;
			} else {
				this.checkPwd();
			}
		},

		// 비밀번호 체크
		checkPwd() {
			this.$httpPost(
				"/api/com/checkPassword.do",
				{
					regUserId: this.userId,
					chkUserPass: this.password
				},
				{
					showLoading: true
				}
			)
				.then(response => {
					// this.isValidPassword = response.data.resultData;
					this.isValidPassword = true;
					this.checkResultData();
				})
				.catch(this.commonErrorHandler);
		},

		// 비밀번호가 일치하지 않는 경우
		checkResultData() {
			if (this.isValidPassword) {
				this.$emit("showMyPagePop");
			} else {
				this.alert({
					type: "warning",
					title: this.isLogin
						? this.i18n("label.error")
						: this.i18nOff("label.error"),
					message: this.isLogin
						? this.i18n("alt.wrongPwd")
						: this.i18nOff("alt.wrongPwd"),
					buttons: [
						{
							label: this.isLogin
								? this.i18n("label.confirm")
								: this.i18nOff("label.confirm"),
							onPress: () => {
								this.clear();
							}
						}
					]
				});
			}
		}
	}
};
</script>

<style lang="scss" scoped>
.el-eye {
	position: relative;
	top: 0;
	right: -10px;
	font-size: 0;
	background-color: transparent;
	opacity: 0.35;
	[type="password"] {
		padding-right: 40px;
	}
	&[data-icon="off"]::after {
		content: "";
		position: absolute;
		top: -3px;
		left: 11px;
		display: block;
		width: 2px;
		height: 30px;
		background-color: #000;
		transform: rotate(45deg);
		cursor: pointer;
	}
}
</style>
