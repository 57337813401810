/**
 * 1. 개요 : Logo
 * 2. 처리내용 : Navbar - 로고 컴포넌트
 * @File Name : index.vue
 * @date : 2020. 02. 20. 09:46:00
 * @author : 이상철
 * @history :
 *-----------------------------------------------------------------------
 * 변경일			          		  작성자		변경내용
 *-----------------------------------------------------------------------
 * 2020. 2. 20. 09:46:00	    이상철	  최초 작성
 *-----------------------------------------------------------------------
 */
<template>
  <div class="sidebar-logo-container">
    <transition name="sidebarLogoFade">
        <h1 class="sidebar-title">
          <!-- 20221206 Lam.nt1 Update:  Change main dashboard -->
          <!-- CONFIG FOR PCIDSS -->
		      <div v-if="this.userAuth.userId != 'pcidss_office' 
                  && this.userAuth.userId != 'pcidss_merchant' 
                  && this.userAuth.userId != 'pcidss_agency' 
                  && this.userAuth.userId != 'pcidss_bank'
                  && this.userAuth.userId != 'pcidss_office1' 
                  && this.userAuth.userId != 'pcidss_merchant1' 
                  && this.userAuth.userId != 'pcidss_agency1' 
                  && this.userAuth.userId != 'pcidss_bank1'">
            <router-link key="expand" class="sidebar-logo-link" to="/dashboard">
              {{ title }}
              <img v-if="logo" :src="logo" class="sidebar-logo" alt="logo image" width="104" height="25">
            </router-link>
		      </div>
          <div v-else>
            <img v-if="logo" :src="logo" class="sidebar-logo" alt="logo image" width="104" height="25">
          </div>
        </h1>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'Logo',
  data() {
    return {
      title: '',
      logo: require('@/assets/img/alliex-logo-small.png')
    }
  },
  created() {
		this.userAuth = this.$store.getters["Options/userInfo"];
	},
}
</script>

<style lang="scss" scoped>
.sidebar-logo-container {
  position: relative;
  height: 100%;
  line-height: 40px;

  h1{
    height:40px;
    margin:0;
    font-size:0;
    .sidebar-logo-link {
      height:40px;
      padding-top:7px;
    }
  }

}
</style>
