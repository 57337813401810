/**
 * 1. 개요 : 공통
 * 2. 처리내용 : 공통 - beforeRouteEnter 영역
 * @File Name : beforeRouteEnter.js
 * @date : 2020. 02. 20. 09:46:00
 * @author : 이상철
 * @history :
 *-----------------------------------------------------------------------
 * 변경일			          		  작성자		변경내용
 *-----------------------------------------------------------------------
 * 2020. 2. 20. 09:46:00	    이상철	  최초 작성
 *-----------------------------------------------------------------------
 */
import store from "@/store";
import router from "@/router/index";

export default function(to, from, next) {
  window.scrollTo(0, 0);

  // 모바일로 세팅 되어 있으면 모바일용 대쉬보드로 보내기
  if (
    store.getters["Options/isLogin"] &&
    store.getters["Device/getDeviceCd"] === "M" &&
    to.path === "/dashboard"
  ) {
    next("/m/dashboard");
  }

  if (
    !store.getters["Options/isLogin"] &&
    to.path !== "/login" &&
    to.path !== "/m/login"
  ) {
    if (
      (from.path === "/login" && to.path === "/m/login") ||
      (from.path === "/m/login" && to.path === "/login") ||
      (from.path === "/" && to.path === "/m/login")
    ) {
    } else {
      return;
    }
  }

  // 관리자 권한이 없으면 권한없음 페이지로 보낸다.
  const pageInfo = router.options.routes.filter(
    item => item.meta && item.meta.layout === "admin"
  );
  const menuList = store.getters["CommonCode/getMenuList"]();

  pageInfo.find(item => {
    if (item.path === to.path) {
      if (!item.hidden) {
        if (menuList && !menuList.find(item => !to.path.indexOf(item.path))) {
          next("/notAuthority");
          return;
        }
      }
      return;
    }
  });

  const menu = menuList.find(item => to.path === item.path);

  if (!!menu && menu.menuLvl === 1) {
    const childMenu = menuList.find(
      item => item.lvl === 2 && !item.path.indexOf(to.path)
    );
    next(childMenu.path);
    return;
  }

  next();
}
