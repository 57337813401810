/**
 * 1. 개요 : bep 스토어
 * 2. 처리내용 : 업무 공통 팝업(주문시리얼번호 검색) - 스토어
 * @File Name : CmnOrdSnPopupChecked.js
 * @date : 2020. 02. 20. 09:46:00
 * @author : 이상철
 * @history :
 *-----------------------------------------------------------------------
 * 변경일			          		  작성자		변경내용
 *-----------------------------------------------------------------------
 * 2020. 2. 20. 09:46:00	    이상철	  최초 작성
 *-----------------------------------------------------------------------
 */
export default {
  state: {
    snList: []
  },
  namespaced: true,
  mutations: {
    SET_SN_LIST(state, sn) {
      state.snList.push(sn);
    },
    REMOVE_SN_LIST(state, sn) {
      if(sn) state.snList = state.snList.filter(a => a.sn !== sn.sn)
    },
    INITIAL_SN_LIST(state, sn) {
      state.snList = []
    }
  },
  actions: {
    setSnList({ commit }, sn) {
      commit('SET_SN_LIST', sn);
    },
    removeSnList({ commit }, sn) {
      commit('REMOVE_SN_LIST', sn)
    },
    initialSnList({ commit }, sn) {
      commit('INITIAL_SN_LIST', sn)
    }
  },
  getters: {
    getSnList: state => state.snList
  }
};
