/**
 * 1. 개요 : 공통팝업
 * 2. 처리내용 : 공통팝업 - 이벤트 버스
 * @File Name : EventBus.js
 * @date : 2020. 02. 20. 09:46:00
 * @author : 이상철
 * @history :
 *-----------------------------------------------------------------------
 * 변경일			          		  작성자		변경내용
 *-----------------------------------------------------------------------
 * 2020. 2. 20. 09:46:00	    이상철	  최초 작성
 *-----------------------------------------------------------------------
 */
import Vue from 'vue';

export const EventBus = new Vue();

export default {
  EventBus,
};
