/**
 * 1. 개요 : Sidebar
 * 2. 처리내용 : Sidebar - 진입점
 * @File Name : index.vue
 * @date : 2020. 02. 20. 09:46:00
 * @author : 이상철
 * @history :
 *-----------------------------------------------------------------------
 * 변경일			          		  작성자		변경내용
 *-----------------------------------------------------------------------
 * 2020. 2. 20. 09:46:00	    이상철	  최초 작성
 *-----------------------------------------------------------------------
 */
<template>
	<div>
		<div class="side-top-wrap">
			<div class="side-top-area">
				<!-- case : 고객이 이미지를 등록한 경우 : background-image:url("경로"); 이렇게 주시면 됩니다. -->

				<span @click="checkPwdPopup" style="cursor:pointer;">
					<img class="profile" alt="profile photo" width="55" height="55" :src="psnImage" />
				</span>

				<!-- 비밀번호 확인 팝업 -->
				<cmn-check-password-popup
					v-if="isShow.checkPwdPopup"
					@close="closeCheckPwdPop"
					@showMyPagePop="showMyPagePop"
					:userId="getUserId"
				/>

				<!-- 마이 페이지 팝업 -->
				<cmn-my-page-popup
					v-if="isShow.myPagePopup"
					@close="closeMypagePop"
					@refreshProfile="getPsnDetail"
				/>
				<!-- CONFIG FOR PCIDSS -->
				<div class="profile-box">
					<button class="badge" @click="showTodoList">{{ totalCnt }}</button>
					<p>
						<span @click="checkPwdPopup" style="cursor:pointer;">{{ psnNm }}</span>
					</p>
				</div>
				<div v-if="this.userAuth.userId != 'pcidss_office' 
								&& this.userAuth.userId != 'pcidss_merchant' 
								&& this.userAuth.userId != 'pcidss_agency' 
								&& this.userAuth.userId != 'pcidss_bank'
								&& this.userAuth.userId != 'pcidss_office1' 
								&& this.userAuth.userId != 'pcidss_merchant1' 
								&& this.userAuth.userId != 'pcidss_agency1' 
								&& this.userAuth.userId != 'pcidss_bank1'">
					<div class="btn-box">
						<button 
							v-if="isAuthorMerPtMng"
							type="button" 
							class="sp-b icon-info" 
							@click="routeMerPtMng">{{ 'menu.210' | i18n }}</button>
						<button
							v-if="isAuthorCardTrLstMng"
							type="button"
							class="sp-b icon-card"
							@click="routeCardTrLstMng"
						>{{ 'menu.10007' | i18n }}</button>
					</div>
				</div>
			</div>
			<!-- 투두리스트 영역 -->
			<!-- CONFIG FOR PCIDSS -->
			<div v-if="this.userAuth.userId != 'pcidss_office' 
							&& this.userAuth.userId != 'pcidss_merchant' 
							&& this.userAuth.userId != 'pcidss_agency' 
							&& this.userAuth.userId != 'pcidss_bank'
							&& this.userAuth.userId != 'pcidss_office1' 
							&& this.userAuth.userId != 'pcidss_merchant1' 
							&& this.userAuth.userId != 'pcidss_agency1' 
							&& this.userAuth.userId != 'pcidss_bank1'">
			<todo-list
				v-if="isShow.todoList"
				:resultList="resultList"
				:newList="newList"
				:lastConnIp ="lastConnIp"
				@closeBtn="closeTodoList"
				@closeCookie="setCookieTodo"
				@getClickedMenuInfo="getClickedMenuInfo"
			/>
			</div>
			<!-- 퀵메뉴 / 리스트 메뉴 토글 영역 -->
			<hamburger2
				class="sidebar-btn-box"
				@quickMenuClick="showQuickMenu"
				@listMenuClick="showlistMenu"
			/>
			<!-- 메뉴 열기/닫기 -->
			<hamburger
				id="hamburger-container"
				:is-active="sidebar.opened"
				class="hamburger-container"
				@toggleClick="toggleSideBar"
			/>
		</div>
		<!-- 퀵메뉴영역 -->
		<!-- CONFIG FOR PCIDSS -->
		<!-- <div v-if="this.userAuth.userId != 'pcidss_office' 
						&& this.userAuth.userId != 'pcidss_merchant' 
						&& this.userAuth.userId != 'pcidss_agency' 
						&& this.userAuth.userId != 'pcidss_bank'
						&& this.userAuth.userId != 'pcidss_office1' 
						&& this.userAuth.userId != 'pcidss_merchant1' 
						&& this.userAuth.userId != 'pcidss_agency1' 
						&& this.userAuth.userId != 'pcidss_bank1'"> -->
		<el-scrollbar v-if="isShow.quickMenu 
						&& this.userAuth.userId != 'pcidss_office' 
						&& this.userAuth.userId != 'pcidss_merchant' 
						&& this.userAuth.userId != 'pcidss_agency' 
						&& this.userAuth.userId != 'pcidss_bank'
						&& this.userAuth.userId != 'pcidss_office1' 
						&& this.userAuth.userId != 'pcidss_merchant1' 
						&& this.userAuth.userId != 'pcidss_agency1' 
						&& this.userAuth.userId != 'pcidss_bank1'" wrap-class="scrollbar-wrapper quick-menu" >
			<router-link to="/admin/QuickMenuMng" class="setting sp-b">Setting</router-link>
			<el-menu v-if="isShow.quickMenu" mode="vertical" router>
				<el-menu-item v-for="quick in quickMenuList" :key="quick.id" :index="quick.name" :route="quick">
					<a href="javascript:;" v-if="quick" slot="title">{{ quick.title }}</a>
				</el-menu-item>
			</el-menu>
		</el-scrollbar>
		<!-- </div> -->

		<!-- 리스트 메뉴 영역 -->
		<!-- CONFIG FOR PCIDSS -->
		<!-- <div v-if="this.userAuth.userId != 'pcidss_office' 
						&& this.userAuth.userId != 'pcidss_merchant' 
						&& this.userAuth.userId != 'pcidss_agency' 
						&& this.userAuth.userId != 'pcidss_bank'"> -->
		<el-scrollbar v-if="isShow.listMenu 
						&& this.userAuth.userId != 'pcidss_office' 
						&& this.userAuth.userId != 'pcidss_merchant' 
						&& this.userAuth.userId != 'pcidss_agency' 
						&& this.userAuth.userId != 'pcidss_bank'" wrap-class="scrollbar-wrapper">
			<el-menu
				:default-active="activeMenu"
				:collapse="isCollapse"
				:background-color="variables.menuBg"
				:text-color="variables.menuText"
				:unique-opened="true"
				:active-text-color="variables.menuActiveText"
				:collapse-transition="true"
				mode="vertical"
				router
				id="elMenu"
			>
				<template v-for="route in routes">
					<el-submenu
						v-if="route.children !== null"
						ref="subMenu"
						:key="route.name"
						:index="route.name"
						popper-append-to-body
					>
						<template slot="title">
							<!-- <svg-icon v-if="route.icon" :icon-class="route.icon" /> -->
							<a href="javascript:;" v-if="route.title" slot="title">{{ route.title }}</a>
						</template>
						<el-menu-item
							v-for="child in route.children"
							:key="child.id"
							:index="child.name"
							:route="child"
						>
							<!-- <svg-icon v-if="child.icon" :icon-class="child.icon" /> -->
							<a href="javascript:;" v-if="child.title" slot="title">{{ child.title }}</a>
						</el-menu-item>
					</el-submenu>
				</template>
			</el-menu>
		</el-scrollbar>
		<!-- </div> -->
		<change-password-popup
			v-if="isShowChangePasswordPopup"
			@close="isShowChangePasswordPopup = false"
			:chgPassOrgType="'LOG_IN'"
		/>
	</div>
</template>

<script>
import { mapState } from "vuex";
import variables from "@/styles/variables.scss";
import { isEmpty } from "@/utils";
import moment from "moment";
import Hamburger from "@/components/Hamburger";
import TodoList from "./TodoList";
import Hamburger2 from "@/components/Hamburger2";
import CmnMyPagePopup from "@/views/biz/bep/common/CmnMyPagePopup";
import CmnCheckPasswordPopup from "@/views/biz/bep/common/CmnCheckPasswordPopup";
import ChangePasswordPopup from "@/views/login/popup/ChangePasswordPopup";
export default {
	components: {
		Hamburger,
		TodoList,
		Hamburger2,
		CmnMyPagePopup,
		CmnCheckPasswordPopup,
		ChangePasswordPopup
	},
	data() {
		return {
			psnNm: "",
			psnImage: require("@/assets/img/profile.gif"),
			isShow: {
				todoList: true,
				listMenu: true,
				quickMenu: false,
				checkPwdPopup: false,
				myPagePopup: false
			},
			totalCnt: null,
			resultList: [],
			newList:[],
			menuAuthDetailList: [],
			isShowChangePasswordPopup: false,
			lastConnIp:""
		};
	},
	created() {
		this.userAuth = this.$store.getters["Options/userInfo"];
	},
	computed: {
		...mapState({
			sidebar: state => state.Sidebar.sidebar
		}),
		quickMenuList() {
			return this.$store.getters["QuickMenu/getQuickMenuList"];
		},
		routes() {
			let menuTree = [];
			if(this.userAuth.userId != 'pcidss_office1' && this.userAuth.userId != 'pcidss_merchant1' && this.userAuth.userId != 'pcidss_agency1' && this.userAuth.userId != 'pcidss_bank1'){
				menuTree = this.getMenuTree()
			}else{
				menuTree = this.getMenuTree().filter(item => item.name == 'MerSmsMng' || item.name == 'BilngMng1');

				menuTree.forEach(element => {
					if(element.name == 'BilngMng1'){
							// element.children.shift();
					}
				});
			}
			return menuTree;
		},
		activeMenu() {
			const route = this.$route;
			const { name } = route;
			return name;
		},
		variables() {
			return variables;
		},
		isCollapse() {
			return !this.sidebar.opened;
		},
		getUserId() {
			return this.$store.getters["Options/userInfo"].userId;
		},
		/**
		 * 사용자의 메뉴 리스트
		 * @return {String}
		 */
		getMenuList() {
			return this.$store.getters["CommonCode/getMenuList"]();
		},
		/**
     * 카드거래내역조회에 대한 권한 정보 겟
     * @return {Object}
     */
		isAuthorCardTrLstMng() {
			return this.$store.getters['Options/userInfo'].menuAuthDetailList && !isEmpty(this.$store.getters['Options/userInfo'].menuAuthDetailList.find(
				a => a.menuNm === 'CardTrLstMng'
			))
		},
		/**
     * 가맹점종합관리에 대한 권한 정보 겟
     * @return {Object}
     */
		isAuthorMerPtMng() {
      return this.$store.getters['Options/userInfo'].menuAuthDetailList && !isEmpty(this.$store.getters['Options/userInfo'].menuAuthDetailList.find(
				a => a.menuNm === 'MerPtMng'
			))
    },
	},
	mounted() {
		this.getCookieTodo();
		this.getPsnDetail();
		this.getMenuList.length > 0 && this.fetchQuickMenuList();
		this.searchTodoList();
		let sideTopHeight = document.querySelector(".side-top-wrap").clientHeight,
			scrollBox = document.querySelector(".sidebar-container .el-scrollbar");

		if (sideTopHeight > 170) {
			scrollBox.style.height = "calc(100% - " + sideTopHeight + "px)";
		}
	},
	beforeDestroy() {
		// 30분 마다 todoList 리프레시. 개발시 방해가 될 수 있으니 일단 주석 처리.
		// clearTimeout(this.refresh);
	},
	methods: {
		/**
		 * 퀵 메뉴 목록 조회
		 */
		fetchQuickMenuList() {
			this.$store.dispatch("QuickMenu/setInitialQuickMenuList");

			this.$httpPost(
				"/api/admin/sys/quickmenu/getQuickMenuTreeList.do",
				{
					// searchLangCd: this.msgLangCd,	//#2454 Remove undeclared fields to address API Mass Assignment Vulnerability - SeungHyeon Han 2023.02.28
					searchUserId: this.$store.getters["Options/userInfo"].userId
				},
				{
					showLoading: true
				}
			)
				.then(response => {
					this.$store.dispatch(
						"QuickMenu/setQuickMenuList",
						response.data.resultData.children.map(a => a.children).flat(1)
					);
				})
				.catch(this.commonErrorHandler);
		},
		/**
		 * 개인 설정 get
		 */
		getPsnDetail() {
			this.$httpPost(
				"/api/bep/getPsnMngDetail.do",
				{ searchPsnId: this.$store.getters["Options/userInfo"].psnId },
				{ showLoading: true }
			)
				.then(response => {
					this.psnNm =
						this.msgLangCd === "ko_KR"
							? response.data.resultList[0].psnNm
							: response.data.resultList[0].psnEnm;
					//TODO: 사용자 이미지 바인딩 해야됨
					this.psnImage = isEmpty(response.data.resultList[0].photoDat)
						? require("@/assets/img/profile.gif")
						: response.data.resultList[0].photoDat;
				})
				.catch(this.commonErrorHandler);
		},
		/**
		 * 투두리스트 get
		 */
		searchTodoList() {
			this.menuAuthDetailList = [];
			this.resultList = [];
			this.totalCnt = 0;
			var lastSearchDtm ="";
      // 2021.02.09 To-do주석처리
			this.$httpPost(
				"/api/bep/getTodoResultList.do",
				{ 
					searchUserId: this.getUserId,
					searchTodoDtm : lastSearchDtm
				},
				{ showLoading: true }
			)
				.then(res => {
					this.menuAuthDetailList = this.$store.getters[
						"Options/userInfo"
					].menuAuthDetailList;
					this.totalCnt = res.data.mustList.length+res.data.newList.length;
					this.resultList = res.data.mustList;
					this.newList = res.data.newList;
					if(!isEmpty(res.data.lastConnIp)){
						this.lastConnIp = res.data.lastConnIp;
					}

					if(res.data.newCnt > 0){
						this.$cookie.delete("chkDoNotTodoListDate");
						this.isShow.todoList = true;
					}
				})
				.catch(this.commonErrorHandler);

			// 30분 마다 todoList 리프레시. 개발시 방해가 될 수 있으니 일단 주석 처리.
			// this.refresh = setInterval(() => {
			// 	this.$httpPost(
			// 		"/api/bep/getTodoResultList.do",
			// 		{ "searchUserId": this.getUserId },
			// 		{ showLoading: true	}
			// 	)
			// 	.then(res => {
			// 테스트용으로 랜덤 숫자를 넣고 있음. 테스트 이후 아래 주석 풀면 됨.
			// 		const randomNm = (min, max) => Math.floor(Math.random()*(max-min)+min)
			// 		this.totalCnt = randomNm(1,10)
			// 		// this.totalCnt = res.data.totalCount;
			// 		this.resultList = res.data.resultList;
			// 	})
			// 	.catch(this.commonErrorHandler);
			// },1800000) // 30분(1800000) 마다 리프레시
		},
		/**
		 * 할 일 개수를 클릭하면 해당 이슈가 있는 페이지로 route
		 * @param {String} param
		 */
		getClickedMenuInfo(param) {
		//	this.$cookie.set(this.getUserId, moment().format("YYYYMMDDHHmmss")+param,"3d");
		let todoMngVOList;
		if(param =='ALL'){
			//3일동안 보여주지 않기일 경우 모든 화면을 현재시점으로 AXC_TODO에 저장
			todoMngVOList = [
				{
					status: "U",
					menuId: param
				},
			];
		} else {
			//아닐 경우 해당 화면만 저장
			todoMngVOList = [
				{
					status: "I",
					menuId: param
				},
			];
		}
			
			this.$httpPost(
				"/api/bep/saveTodoMngList.do",
				{ todoMngVOList },
				{
					showLoading:true
				}
			)
			.then(response=>{
				if(param == "101"){
					// 101 (개인관리)일 경우 비밀번호 변경 팝업 호출
					this.isShowChangePasswordPopup = true;
					console.log(this.isShow.todoList);
					this.closeTodoList();
				} else  {
					if(param!='ALL'){
						this.closeTodoList();
						const getClickedMenuNm = this.menuAuthDetailList.filter(
							a => a.menuId === parseInt(param)
						)[0].menuNm;
						if (this.$route.name === getClickedMenuNm) return;
						this.$router.push({ name: `${getClickedMenuNm}` });
					}
				}
			})
		},
		/**
		 * 퀵 메뉴 open
		 */
		showQuickMenu() {
			this.isShow.quickMenu = true;
			this.isShow.listMenu = false;
		},
		/**
		 * 리스트 메뉴 open
		 */
		showlistMenu() {
			this.isShow.listMenu = true;
			this.isShow.quickMenu = false;
		},
		/**
		 * 슬라이드바를 조작하면 투두리스트 close
		 */
		toggleSideBar() {
			this.$store.dispatch("Sidebar/toggleSideBar");
			this.closeTodoList();
		},
		/**
		 * 투두리스트 close
		 */
		closeTodoList() {
			this.isShow.todoList = false;
		},
		/**
		 * 투두리스트 open <-> close
		 */
		showTodoList() {
			if(this.userAuth.userId != 'pcidss_office' && this.userAuth.userId != 'pcidss_merchant' && this.userAuth.userId != 'pcidss_agency' && this.userAuth.userId != 'pcidss_bank'){
			if(!this.isShow.todoList){
				this.searchTodoList();
			}
			this.isShow.todoList = !this.isShow.todoList;
			}
		},
		/**
		 * 쿠키 set
		 */
		setCookieTodo() {
			this.$cookie.set(
				"chkDoNotTodoListDate",
				moment().format("YYYYMMDDhmmss"),
				"3d"
			);
		},
		/**
		 * 쿠키 get
		 */
		getCookieTodo() {
			!isEmpty(this.$cookie.get("chkDoNotTodoListDate"))
				? (this.isShow.todoList = false)
				: (this.isShow.todoList = true);
		},
		/**
		 * '카드거래내역'으로 이동
		 */
		routeCardTrLstMng() {
			this.$router.push({ name: "CardTrLstMng" });
		},
		/**
		 * '가맹점종합관리'로 이동
		 */
		routeMerPtMng() {
			this.$router.push({ name: "MerPtMng" });
		},
		/**
		 * 비밀번호 확인 팝업 show
		 */
		checkPwdPopup() {
			this.isShow.checkPwdPopup = true;
		},
		/**
		 * 마이페이지 팝업 show
		 */
		showMyPagePop() {
			this.isShow.myPagePopup = true;
			this.isShow.checkPwdPopup = false;
		},
		/**
		 * 비밀번호 확인 팝업 close
		 */
		closeCheckPwdPop() {
			this.isShow.checkPwdPopup = false;
		},
		/**
		 * 마이페이지 팝업 close
		 */
		closeMypagePop() {
			this.isShow.myPagePopup = false;
		}
	}
};
</script>
<style lang="scss" scoped>
@import "@/scss/_color.scss";
@import "@/scss/_button.scss";
@import "@/scss/_icon.scss";
</style>
