/**
 * 1. 개요 : bep 스토어
 * 2. 처리내용 : 업무 공통 팝업(유심정보 검색 검색) - 스토어
 * @File Name : CmnMvnoPopupChecked.js
 * @date : 2020. 02. 20. 09:46:00
 * @author : 이상철
 * @history :
 *-----------------------------------------------------------------------
 * 변경일			          		  작성자		변경내용
 *-----------------------------------------------------------------------
 * 2020. 2. 20. 09:46:00	    이상철	  최초 작성
 *-----------------------------------------------------------------------
 */
export default {
  state: {
    mvnoList: [],
    limitCheckCnt: 0
  },
  namespaced: true,
  mutations: {
    SET_MVNO_LIST(state, mvno) {
      state.mvnoList.push(mvno);
    },
    REMOVE_MVNO_LIST(state, mvno) {
      if(mvno) state.mvnoList = state.mvnoList.filter(a => a.mvnoSn !== mvno.mvnoSn)
    },
    INITIAL_MVNO_LIST(state, mvno) {
      state.mvnoList = []
    },
    SET_LIMIT_CNT(state, checkCnt) {
      state.limitCheckCnt = checkCnt
    },
    INITIAL_LIMIT_CNT(state) {
      state.limitCheckCnt = 0;
    }
  },
  actions: {
    setMvnosnList({ commit }, mvno) {
      commit('SET_MVNO_LIST', mvno);
    },
    removeMvnoList({ commit }, mvno) {
      commit('REMOVE_MVNO_LIST', mvno)
    },
    initialMvnoList({ commit }, mvno) {
      commit('INITIAL_MVNO_LIST', mvno)
    },
    setLimitCheckCnt({ commit }, checkCnt) {
      commit('SET_LIMIT_CNT', checkCnt)
    },
    initialLimitCheckCnt({ commit }, checkCnt) {
      commit('INITIAL_LIMIT_CNT', checkCnt)
    }
  },
  getters: {
    getMvnoList: state => state.mvnoList,
    getlimitCheckCnt: state => state.limitCheckCnt
  }
};
