/**
 * 1. 개요 : Inputs 공통 컴포넌트
 * 2. 처리내용 : Inputs 공통 컴포넌트 - RadioBox
 * @File Name : index.vue
 * @date : 2020. 02. 20. 09:46:00
 * @author : 이상철
 * @history :
 *-----------------------------------------------------------------------
 * 변경일			          		  작성자		변경내용
 *-----------------------------------------------------------------------
 * 2020. 2. 20. 09:46:00	    이상철	  최초 작성
 *-----------------------------------------------------------------------
 */
<template>
  <label class="radio-box"
         role="radio"
         :class="{
           disabled,
           checked: $attrs.value === model,
         }"
        :for="id + $attrs.value"
  >
    <input ref="radio"
           type="radio"
           v-bind="$attrs"
           :checked="$attrs.value === model"
           :disabled="disabled"
           @change="$event => $emit('apply', $attrs.value)"
           v-on="$listeners"
          :name="id+'Name'"
    >
    <span class="radio-box-icon" />
    <span v-if="$slots.default" class="label">
      <slot />
    </span>
  </label>
</template>

<script>
export default {
  name: 'RadioBox',

  model: {
    prop: 'model',
    event: 'apply',
  },

  props: {
    disabled: {
      type: Boolean,
      default: false,
    },

    model: {
      type: [String, Number],
      default: '',
    },

    id: {
      type: String,
      default: ""
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/_color.scss";
@import "@/scss/_input.scss";

</style>

