/**
 * 1. 개요 : 공통 컴포넌트
 * 2. 처리내용 : 공통 컴포넌트 - Loading
 * @File Name : index.vue
 * @date : 2020. 02. 20. 09:46:00
 * @author : 이상철
 * @history :
 *-----------------------------------------------------------------------
 * 변경일			          		  작성자		변경내용
 *-----------------------------------------------------------------------
 * 2020. 2. 20. 09:46:00	    이상철	  최초 작성
 *-----------------------------------------------------------------------
 */
<template>
  <transition name="fade">
    <div class="modal">
      <spinner
        class="spinner"
        :line-size="10"
        :size="100"
        message="Loading..."
        text-fg-color="#fff"
        :line-fg-color="color"
      />
    </div>
  </transition>
</template>

<script>
import Spinner from 'vue-simple-spinner';

export default {
  components: {
    Spinner
  },
  data() {
    return {
      beforeFocusedElement: null
    };
  },
  computed: {
    /**
     * spinner color 셋
     * @return {String}
     */
    color() {
      return this.userInfo.compType === 'K' ? '#d3243c' : '#4492d2';
    }
  },
  created() {
    this.beforeFocusedElement = document.activeElement;
  },
  mounted() {
    document.activeElement.blur();
    document.querySelector('body').style.overflow = 'hidden'; // 로딩바 생길 때 overflow: hidden;
  },
  beforeDestroy() {
    document.querySelector('body').style = ''; // 로딩바 사라질 때 스타일을 비움.
    this.beforeFocusedElement.focus();
  }
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
	transition: opacity 0.2s;
}
.fade-enter {
	opacity: 0;
}
.fade-leave-to {
	opacity: 0;
}
.modal {
	display: block;
	position: fixed;
	z-index: 9999999;
	top: 0;
  left: 0;
  right:0;
  bottom:0;
	width: 100%;
	height: 100%;
	overflow: auto;
	background-color: rgb(0, 0, 0);
	background-color: rgba(0, 0, 0, 0.4);
}
.spinner {
	position: absolute;
	left: 50%;
	top: 50%;
	margin-top: -50px;
	margin-left: -50px;
}
</style>
