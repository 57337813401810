/**
 * 1. 개요 : Options
 * 2. 처리내용 : Options - 스토어
 * @File Name : Options.js
 * @date : 2020. 02. 20. 09:46:00
 * @author : 이상철
 * @history :
 *-----------------------------------------------------------------------
 * 변경일			          		  작성자		변경내용
 *-----------------------------------------------------------------------
 * 2020. 2. 20. 09:46:00	    이상철	  최초 작성
 *-----------------------------------------------------------------------
 */
const storage = window.sessionStorage;

const defaultOptions = {
  msgLangCd: storage.getItem("msgLangCd") || "ko_KR"
};

const defaultUserInfo = {
  accesstoken: storage.getItem("accesstoken"),
  userId: "",
  userName: "",
  msgLangCd: "",
  deptCd: "",
  classCd: "",
  authorities: [],
  grpMenuAuthorCd: "",
  userMenuAuthorYn: "",
  menuAuthDetailList: [],
  grpTypCd: "",
  grpCd: "",
  parGrpTypCd: "",
  parGrpCd: "",
  psnId: ""
};

const setItem = (state, key, value) => {
  state[key] = value;
  if (key === "accesstoken") storage.setItem(key, value);
};

const removeItem = (state, key) => {
  state[key] = "";
  storage.removeItem(key);
};

export default {
  state: Object.assign({}, defaultOptions, defaultUserInfo),

  namespaced: true,

  mutations: {
    SET_USER_INFO(state, param) {
      Object.keys(param).forEach(key => {
        const value = param[key];
        if (value) {
          setItem(state, key, param[key]);
        } else {
          removeItem(state, key);
        }
      });
    },

    CLEAR_USER_INFO(state, param) {
      Object.keys(defaultUserInfo).forEach(key => {
        removeItem(state, key);
      });
    }
  },
  actions: {
    setUserInfo({ commit }, param) {
      commit("SET_USER_INFO", param);
    },

    clearUserInfo({ commit }) {
      commit("CLEAR_USER_INFO", "");
    }
  },
  getters: {
    isLogin: state => {
      const { accesstoken, userName } = state;
      return Boolean(
        /^[a-z0-9-_]{1,}\.[a-z0-9-_]{1,}\.[a-z0-9-_]{1,}$/i.test(accesstoken) &&
          userName
      );
    },

    loginToken: state => state.accesstoken,

    userInfo: state => {
      const userInfo = {};

      Object.keys(defaultUserInfo).forEach(key => {
        userInfo[key] = state[key];
      });

      return userInfo;
    },

    all: state => state
  }
};
