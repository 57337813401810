/**
 * 1. 개요 : 공통 팝업
 * 2. 처리내용 : 공통 팝업 Mixins - helper
 * @File Name : helper.js
 * @date : 2020. 02. 20. 09:46:00
 * @author : 이상철
 * @history :
 *-----------------------------------------------------------------------
 * 변경일			          		  작성자		변경내용
 *-----------------------------------------------------------------------
 * 2020. 2. 20. 09:46:00	    이상철	  최초 작성
 *-----------------------------------------------------------------------
 */
import CmnPopupa from "./CmnPopupa";
import CmnTrmIdPopup from "./CmnTrmIdPopup";
import CmnAgncyInfPopup from "./CmnAgncyInfPopup";
import CmnAddrPopup from "./CmnAddrPopup"; // 김영노-주소팝업 관련추가
import CmnDistrInfPopup from "./CmnDistrInfPopup";
import CmnBizPopup from "./CmnBizPopup"; // 김영노-사업자팝업 관련추가
import CmnInstPopup from "./CmnInstPopup";
import CmnTrmSnPopup from "./CmnTrmSnPopup";
import CmnStckBatUpPopup from "./CmnStckBatUpPopup";
import CmnMerInfPopup from "./CmnMerInfPopup"; // 이지혜-가맹점팝업 추가
import CmnOrdSnPopup from "./CmnOrdSnPopup";
import CmnPsnPopup from "./CmnPsnPopup";
import CmnCardBinPopup from "./CmnCardBinPopup";
import CmnTrmSnPtPopup from "./CmnTrmSnPtPopup";
import CmnTrmSnMvnoPtPopup from "./CmnTrmSnMvnoPtPopup";
import CmnCodePopup from "./CmnCodePopup";
import CmnTrmInstPopup from "./CmnTrmInstPopup";
import CmnMyPagePopup from "./CmnMyPagePopup";
import CmnCheckPasswordPopup from "./CmnCheckPasswordPopup"; // 이지혜 - 공통 비밀번호 확인 팝업
import CmnMvnoPopup from "./CmnMvnoPopup";
import CmnCorpPopup from "./CmnCorpPopup";
import CmnSmltDefPopup from "./CmnSmltDefPopup";
import CmnCpnPlcyPopup from "./CmnCpnPlcyPopup"; // 이진우-쿠폰정책팝업 추가
import CmnCpnCstMngNoPopup from "./CmnCpnCstMngNoPopup"; // 이진우-쿠폰지불번호팝업 추가
import CmnCpnDstrbtMngNoPopup from "./CmnCpnDstrbtMngNoPopup"; // 이진우-쿠폰배분번호팝업 추가
import CmnCpnGiftMngNoPopup from "./CmnCpnGiftMngNoPopup"; // 김준석-사은품관리번호팝업 추가
import CmnEvtPlcyPopup from "./CmnEvtPlcyPopup"; // 김준석-이벤트정책팝업 추가
import CmnEvtPlcyForLotteryPopup from "./CmnEvtPlcyForLotteryPopup"; // 김준석-이벤트정책팝업 추가
import CmnMerGrpIdPopup from "./CmnMerGrpIdPopup"; // 이진우 - 가맹점 그룹ID팝업 추가
import CmnMultiMerInfPopup from "./CmnMultiMerInfPopup"; //김준석 - 가맹점 멀티선택팝업 추가

export default {
  components: {
    CmnPopupa,
    CmnTrmIdPopup,
    CmnAgncyInfPopup,
    CmnAddrPopup,
    CmnDistrInfPopup,
    CmnBizPopup,
    CmnInstPopup,
    CmnTrmSnPopup,
    CmnStckBatUpPopup,
    CmnMerInfPopup,
    CmnOrdSnPopup,
    CmnPsnPopup,
    CmnCardBinPopup,
    CmnTrmSnPtPopup,
    CmnTrmSnMvnoPtPopup,
    CmnCodePopup,
    CmnTrmInstPopup,
    CmnMyPagePopup,
    CmnCheckPasswordPopup,
    CmnMvnoPopup,
    CmnCorpPopup,
    CmnSmltDefPopup,
    CmnCpnPlcyPopup,
    CmnCpnCstMngNoPopup,
    CmnCpnDstrbtMngNoPopup,
    CmnCpnGiftMngNoPopup,
    CmnEvtPlcyPopup,
    CmnEvtPlcyForLotteryPopup,
    CmnMerGrpIdPopup,
    CmnMultiMerInfPopup
  },
  data() {
    return {
      isShowCmnPopupa: false,
      isShowCmnTrmIdPopup: false,
      isShowCmnAgncyInfPopup: false,
      isShowCmnAddrPopup: false,
      isShowCmnDistrInfPopup: false,
      isShowCmnBizPopup: false,
      isShowCmnInstPopup:false,
      isShowCmnTrmSnPopup: false,
      isShowCmnStckBatUpPopup: false,
      isShowCmnMerInfPopup: false,
      isShowCmnOrdSnPopup: false,
      isShowCmnPsnPopup: false,
      isShowCmnCardBinPopup: false,
      isShowCmnTrmSnPtPopup: false,
      isShowCmnTrmSnMvnoPtPopup: false,
      isShowCmnCodePopup: false,
      isShowCmnTrmInstPopup : false,
      isShowCmnMyPagePopup : false,
      isShowCmnCheckPasswordPopup: false,
      isShowCmnMvnoPopup: false,
      isShowCmnCorpPopup: false,
      isShowCmnSmltDefPopup: false,
      isShowCmnCpnPlcyPopup: false,
      isShowCmnCpnCstMngNoPopup: false,
      isShowCmnCpnDstrbtMngNoPopup: false,
      isShowCmnCpnGiftMngNoPopup: false,
      isShowCmnEvtPlcyPopup: false,
      isShowCmnEvtPlcyForLotteryPopup: false,
      isShowCmnMerGrpIdPopup: false,
      isShowCmnMultiMerInfPopup: false,
      isShowCmnGrpIdPopup: false
    };
  },
  methods: {
    showCmnPopupa() {
      this.isShowCmnPopupa = !this.isShowCmnPopupa;
    },
    closeCmnPopupa() {
      this.isShowCmnPopupa = false;
    },
    showCmnTrmIdPopup() {
      this.isShowCmnTrmIdPopup = !this.isShowCmnTrmIdPopup;
    },
    closeCmnTrmIdPopup() {
      this.isShowCmnTrmIdPopup = false;
    },
    showCmnAgncyInfPopup() {
      this.isShowCmnAgncyInfPopup = !this.isShowCmnAgncyInfPopup;
    },
    closeCmnAgncyInfPopup() {
      this.isShowCmnAgncyInfPopup = false;
    },
    showCmnAddrPopup() {
      this.isShowCmnAddrPopup = !this.isShowCmnAddrPopup;
    },
    closeCmnAddrPopup() {
      this.isShowCmnAddrPopup = false;
    },
    showCmnDistrInfPopup() {
      this.isShowCmnDistrInfPopup = !this.isShowCmnDistrInfPopup;
    },
    closeCmnDistrInfPopup() {
      this.isShowCmnDistrInfPopup = false;
    },
    showCmnBizPopup() {
      this.isShowCmnBizPopup = !this.isShowCmnBizPopup;
    },
    closeCmnBizPopup() {
      this.isShowCmnBizPopup = false;
    },
    showCmnInstPopup() {
      this.isShowCmnInstPopup = !this.isShowCmnInstPopup;
    },
    closeCmnInstPopup() {
      this.isShowCmnInstPopup = false;
    },
    showCmnTrmSnPopup() {
      this.isShowCmnTrmSnPopup = !this.isShowCmnTrmSnPopup;
    },
    closeCmnTrmSnPopup() {
      this.isShowCmnTrmSnPopup = false;
    },
    showCmnStckBatUpPopup() {
      this.isShowCmnStckBatUpPopup = !this.isShowCmnStckBatUpPopup;
    },
    closeCmnStckBatUpPopup() {
      this.isShowCmnStckBatUpPopup = false;
    },
    showCmnMerInfPopup() {
      this.isShowCmnMerInfPopup = !this.isShowCmnMerInfPopup;
    },
    closeCmnMerInfPopup() {
      this.isShowCmnMerInfPopup = false;
    },
    showCmnOrdSnPopup() {
      this.isShowCmnOrdSnPopup = !this.isShowCmnOrdSnPopup;
    },
    closeCmnOrdSnPopup() {
      this.isShowCmnOrdSnPopup = false;
    },
    showCmnPsnPopup() {
      this.isShowCmnPsnPopup = !this.isShowCmnPsnPopup;
    },
    closeCmnPsnPopup() {
      this.isShowCmnPsnPopup = false;
    },
    showCmnCardBinPopup() {
      this.isShowCmnCardBinPopup = !this.isShowCmnCardBinPopup;
    },
    closeCmnCardBinPopup() {
      this.isShowCmnCardBinPopup = false;
    },
    showCmnTrmSnPtPopup() {
      this.isShowCmnTrmSnPtPopup = !this.isShowCmnTrmSnPtPopup;
    },
    closeCmnTrmSnPtPopup() {
      this.isShowCmnTrmSnPtPopup = false;
    },
    showCmnTrmSnMvnoPtPopup() {
      this.isShowCmnTrmSnMvnoPtPopup = !this.isShowCmnTrmSnMvnoPtPopup;
    },
    closeCmnTrmSnMvnoPtPopup() {
      this.isShowCmnTrmSnMvnoPtPopup = false;
    },
    showCmnCodePopup() {
      this.isShowCmnCodePopup = !this.isShowCmnCodePopup;
    },
    closeCmnCodePopup() {
      this.isShowCmnCodePopup = false;
    },
    showCmnTrmInstPopup() {
      this.isShowCmnTrmInstPopup = !this.isShowCmnTrmInstPopup;
    },
    closeCmnTrmInstPopup() {
      this.isShowCmnTrmInstPopup = false;
    },
    showCmnMyPagePopup() {
      this.isShowCmnMyPagePopup = !this.isShowCmnMyPagePopup;
    },
    closeCmnMyPagePopup() {
      this.isShowCmnMyPagePopup = false;
    },
    showCmnCheckPasswordPopup() {
      this.isShowCmnCheckPasswordPopup = !this.isShowCmnCheckPasswordPopup;
    },
    closeCmnCheckPasswordPopup() {
      this.isShowCmnCheckPasswordPopup = false;
    },
    showCmnMvnoPopup() {
      this.isShowCmnMvnoPopup = !this.isShowCmnMvnoPopup;
    },
    closeCmnMvnoPopup() {
      this.isShowCmnMvnoPopup = false;
    },
    showCmnCorpPopup() {
      this.isShowCmnCorpPopup = !this.isShowCmnCorpPopup;
    },
    closeCmnCorpPopup() {
      this.isShowCmnCorpPopup = false;
    },
    showCmnSmltDefPopup() {
      this.isShowCmnSmltDefPopup = !this.isShowCmnSmltDefPopup;
    },
    closeCmnSmltDefPopup() {
      this.isShowCmnSmltDefPopup = false;
    },
    showCmnCpnPlcyPopup() {
      this.isShowCmnCpnPlcyPopup = !this.isShowCmnCpnPlcyPopup;
    },
    closeCmnCpnPlcyPopup() {
      this.isShowCmnCpnPlcyPopup = false;
    },
    showCmnCpnCstMngNoPopup() {
      this.isShowCmnCpnCstMngNoPopup = !this.isShowCmnCpnCstMngNoPopup;
    },
    closeCmnCpnCstMngNoPopup() {
      this.isShowCmnCpnCstMngNoPopup = false;
    },
    showCmnCpnDstrbtMngNoPopup() {
      this.isShowCmnCpnDstrbtMngNoPopup = !this.isShowCmnCpnDstrbtMngNoPopup;
    },
    closeCmnCpnDstrbtMngNoPopup() {
      this.isShowCmnCpnDstrbtMngNoPopup = false;
    },
    showCmnCpnGiftMngNoPopup() {
      this.isShowCmnCpnGiftMngNoPopup = !this.isShowCmnCpnGiftMngNoPopup;
    },
    closeCmnCpnGiftMngNoPopup() {
      this.isShowCmnCpnGiftMngNoPopup = false;
    },
    showCmnEvtPlcyPopup() {
      this.isShowCmnEvtPlcyPopup = !this.isShowCmnEvtPlcyPopup;
    },
    closeCmnEvtPlcyPopup() {
      this.isShowCmnEvtPlcyPopup = false;
    },
    showCmnEvtPlcyForLotteryPopup() {
      this.isShowCmnEvtPlcyForLotteryPopup = !this.isShowCmnEvtPlcyForLotteryPopup;
    },
    closeCmnEvtPlcyForLotteryPopup() {
      this.isShowCmnEvtPlcyForLotteryPopup = false;
    },
    showCmnMerGrpIdPopup() {
      this.isShowCmnMerGrpIdPopup = !this.isShowCmnMerGrpIdPopup;
    },
    closeCmnMerGrpIdPopup() {
      this.isShowCmnMerGrpIdPopup = false;
    },
    showCmnMultiMerInfPopup() {
      this.isShowCmnMultiMerInfPopup = !this.isShowCmnMultiMerInfPopup;
    },
    closeCmnMultiMerInfPopup() {
      this.isShowCmnMultiMerInfPopup = false;
    },
  }
};
