/**
 * 1. 개요 : Inputs 공통 컴포넌트
 * 2. 처리내용 : Inputs 공통 컴포넌트 - InputTime
 * @File Name : index.vue
 * @date : 2020. 02. 20. 09:46:00
 * @author : 이상철
 * @history :
 *-----------------------------------------------------------------------
 * 변경일			          		  작성자		변경내용
 *-----------------------------------------------------------------------
 * 2020. 2. 20. 09:46:00	    이상철	  최초 작성
 *-----------------------------------------------------------------------
 */
<template>
  <div class="input-date time-box short-time-input">
    <date-picker
      :key="updateKey"
      v-model="date"
      v-bind="$attrs"
      :value-type="hourOnly ? hourFormat : format"
      :type="type"
      :lang="lang"
      :editable="false"
      :format="hourOnly ? hourFormat : format"
      :clearable="false"
      @change="value => $emit('apply', value)"
      @input="updateDate()"
      v-on="$listeners"
      append-to-body
      :time-picker-options="hourOnly ? timePickerOptions : null"
      :placeholder="placeholder"
    />
  </div>
</template>

<script>
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import moment from "moment";

export default {
  name: "InputTime",

  components: {
    DatePicker
  },

  model: {
    prop: "value",
    event: "change"
  },

  props: {
    value: {
      type: String,
      default: ""
    },

    type: {
      type: String,
      default: "time"
    },
    hourOnly: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: ""
    },
    search: {
      type: Object,
      default: () => {}
    }
  },

  data() {
    return {
      updateKey: 0,
      // date: this.value,
      date: moment(this.value, "HHmmss").format("HHmmss"),
      kr: {
        days: ["일", "월", "화", "수", "목", "금", "토"],
        months: [
          "1월",
          "2월",
          "3월",
          "4월",
          "5월",
          "6월",
          "7월",
          "8월",
          "9월",
          "10월",
          "11월",
          "12월"
        ],
        pickers: ["다음 7일", "다음 30일", "이전 7일", "이전 30일"],
        placeholder: {
          date: "시간 선택",
          dateRange: "기간 선택"
        }
      },
      timePickerOptions: {
        start: "00:00",
        step: "01:00",
        end: "23:00"
      }
    };
  },

  computed: {
    lang() {
      if (this.msgLangCd === "en_US" || this.msgLangCd === "vi_VN") return "en";
      return this.kr;
    },
    format() {
      return (() => {
        switch (this.msgLangCd) {
          case "en_US":
            return "HH:mm:ss";
          case "vi_VN":
            return "HH:mm:ss";
          case "ko_KR":
            return "HH:mm:ss";
          default:
            return "HH:mm:ss";
        }
      })();
    },
    hourFormat() {
      return (() => {
        switch (this.msgLangCd) {
          case "en_US":
            return "HH";
          case "vi_VN":
            return "HH";
          case "ko_KR":
            return "HH";
          default:
            return "HH";
        }
      })();
    }
  },

  watch: {
    value(value) {
      this.$emit(
        "timeValue",
        moment(
          this.value,
          this.hourOnly ? this.hourFormat : this.format
        ).format(this.hourOnly ? this.hourFormat : this.format)
      );
      // IE에서 최초생성 후 model변경을 통한 UI갱싱 문제, 키 갱신을 통한 강제 처리 중
      this.refresh(value);
    }
  },
  mounted() {
    if (this.date != "") this.refresh(this.date);
  },

  methods: {
    updateDate() {
      this.$emit("input", this.date);
      this.updateKey = this.updateKey + 1;
    },
    refresh(value) {
      this.date = moment(
        this.value,
        this.hourOnly ? this.hourFormat : this.format
      ).format(this.hourOnly ? this.hourFormat : this.format);
      this.updateKey = this.updateKey + 1;
    }
  }
};
</script>

<style lang="scss">
.pc {
  .time-box {
    position: relative;
    display: inline-block;
    width: 170px;
    height: 27px;
    vertical-align: middle;
    .mx-icon-calendar {
      width: 18px !important;
      height: 18px !important;
      background: url(~@/assets/img/sp-pc.png) no-repeat -450px 0 !important;
      transform: translateY(0);
      svg {
        display: none;
      }
    }
    & + .time-box,
    & + button,
    & + .date-box {
      margin-left: 4px;
    }
  }
  .combo-box {
    font-size: 0;
    .mx-datepicker,
    .time-box {
      width: 98px !important;
      & + .mx-datepicker,
      & + .time-box {
        width: 97px !important;
        margin-left: 4px;
      }
    }
  }
  .combo-box-2{ // 9월 1일 이지혜 추가. 날짜+시간 combo-box인데 input-time이 더 작음.
    font-size: 0;
		text-align: center;
    .mx-datepicker,
    .time-box {
      width: 60px;
      .mx-datepicker{
        width: 60px;
        margin-left:2px;
      }
    }
  }
}
</style>
