/**
 * 1. 개요 : 공통 컴포넌트
 * 2. 처리내용 : 공통 컴포넌트 - SmoothScrollbar
 * @File Name : SmoothScrollbar.vue
 * @date : 2020. 02. 20. 09:46:00
 * @author : 이상철
 * @history :
 *-----------------------------------------------------------------------
 * 변경일			          		  작성자		변경내용
 *-----------------------------------------------------------------------
 * 2020. 2. 20. 09:46:00	    이상철	  최초 작성
 *-----------------------------------------------------------------------
 */
<template>
  <div style="overflow: hidden; position: relative">
    <slot />
  </div>
</template>

<script>
import Scrollbar from 'smooth-scrollbar/index';

export default {
  name: 'SmoothScrollbar',
  props: {
    suppressScrollX: {
      type: Boolean,
      default: true,
    },
    suppressScrollY: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    this.scroller = Scrollbar.init(this.$el, {
      damping: 1,
      thumbMinSize: 10,
      continuousScrolling: 'auto',
      alwaysShowTracks: true,
    });
    this.scroller.addListener(({ offset }) => {
      if (this.lastY !== offset.y) {
        this.$emit('scrollY', {}, offset.y);
      }
      this.lastY = offset.y;

      if (this.lastX !== offset.x) {
        this.$emit('scrollX', {}, offset.x);
      }
      this.lastX = offset.x;
    });
  },
  beforeDestroy() {
    if (this.scroller) {
      this.scroller.destroy(this.$el);
    }
    this.scroller = undefined;
  },
};
</script>

<style lang="scss">
@import "@/scss/_color.scss";

.scrollbar-track {
  border-left:solid 1px $tbl-border;
  background-color:$cf !important;
  &.scrollbar-track-y{
    width:17px;
    padding:0 3px;
  }
  &.scrollbar-track-x{
    height:17px;
    padding:3px 4px;
  }
  .scrollbar-thumb{
    position:initial;
    left:3px;
    width:10px;
    background-color: $tbl-border !important;
    border-radius: 5px !important;
  }
}

 _:-ms-lang(x), .scrollbar-track{//ie
    border-left:none;
    background-color:#f0f0f0 !important;
}
.scrollbar-track{
    &_:-ms-lang(x),.scrollbar-track-y{
      width:12px;
      height:12px;
      // padding:2px 0;
    }
    &_:-ms-lang(x), .scrollbar-track-x{
      width:12px;
      height:12px;
      // padding:0 1px;
    }
    _:-ms-lang(x), .scrollbar-thumb{
      position:initial;
      width:11px;
      background-color:#cdcdcd !important;
      border-radius:0 !important;
    }
    _:-ms-lang(x), .scrollbar-thumb:hover{
      background-color: #666 !important;
    }
}
</style>
