/**
 * 1. 개요 : 총판정보검색
 * 2. 처리내용 : 총판정보검색 - 팝업창 컴포넌트
 * @File Name : index.vue
 * @date : 2020. 04. 13. 15:00:00
 * @author : 이현우
 * @history :
 *-----------------------------------------------------------------------
 * 변경일			          		  작성자		변경내용
 *-----------------------------------------------------------------------
 * 2020. 04. 13. 15:00:00	    이현우	  최초 작성
 *-----------------------------------------------------------------------
 */
<template>
	<popup :title="title" @close="$emit('close')" popSize="size-grid">
		<div class="search-wrap">
			<collapse-search-pane
				:isSearchPaneShow="isPaSearchPaneShow"
				@fnShowSearchPane="fnShowPaSearchPane"
			>
				<div class="search-area">
					<div id="tblInfo" class="hide">
						{{ 'label.distrCd' | i18n }}, {{ 'label.distrNm' | i18n }}, {{ 'label.parDistrCd' | i18n }}, {{ 'label.DistrTypCd' | i18n }}
						&nbsp;{{ 'label.search' | i18n }}
					</div>
					<div class="tbl-wrap">
						<table aria-describedby="tblInfo">
							<caption>{{ 'label.searchArea' | i18n }}</caption>
							<colgroup>
								<col style="width:150px" />
								<col style="width:auto" />
								<col style="width:150px" />
								<col style="width:auto" />
							</colgroup>
							<tbody>
								<tr>
									<th scope="row">
										<label for="searchDistrTypCd">{{ 'label.distrTyp' | i18n }}</label>
									</th>
									<td>
										<code-selector
											id="searchDistrTypCd"
											v-model="search.distrTypCd"
											:cdList="cdList.distrTypCdList"
											:isAll="false"
											:isSel="true"
											@keydown.enter="onSearchList"
											:disabled="paramDisabled"
											@change="chgLvlCd($event)"
										/>
									</td>
									<th scope="row">
										<label for="searchDistrCd">{{ 'label.distrCd' | i18n }}</label>
									</th>
									<td>
										<input-text
											id="searchDistrCd"
											v-model="search.distrCd"
											type="text"
											:maxlength="6"
											name="searchDistrCd"
											@keydown.enter="searchList"
											:disabled="distrCdDisabled"
										/>
									</td>
								</tr>
								<tr>
									<th scope="row">
										<label for="searchDistrNm">{{ 'label.distrNm' | i18n }}</label>
									</th>
									<td>
										<input-text
											id="searchDistrNm"
											v-model="search.distrNm"
											type="text"
											:maxlength="80"
											name="searchDistrNm"
											@keydown.enter="searchList"
											
										/>
									</td>
									<th scope="row">
										<label for="searchParDistrCd">{{ 'label.parDistrCd' | i18n }}</label>
									</th>
									<td>
										<input-text
											id="searchParDistrCd"
											v-model="search.parDistrCd"
											type="text"
											:maxlength="6"
											name="searchParDistrCd"
											@keydown.enter="searchList"
											
										/>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</collapse-search-pane>
			<div class="search-btn">
				<button type="button" name="searchList" @click.stop="searchList">{{'button.look' | i18n }}</button>
				<button type="button" name="clear" :disabled="paramDisabled==true" @click.stop="clear">{{'button.init' | i18n }}</button>
			</div>
		</div>
		<div>
			<data-grid
				:key="key"
				ref="dataGrid1"
				:totalItems="gridProps.totalItems"
				:columnDefs="gridProps.columnDefs"
				:rowData="gridProps.rowData"
				:initPage="gridProps.initPage"
				:perPage="pageSize"
				:selectedRows="gridProps.selectedRows"
				:gridHeight="gridProps.gridHeight"
				:rowClassRules="gridProps.rowClassRules"
				:frameworkComponents="gridProps.frameworkComponents"
				:rowSelection="gridProps.rowSelection"
				:topBtnList="gridProps.topBtnList"
				:suppressRowClickSelection="gridProps.suppressRowClickSelection"
				@rowSelected="setRowSelected"
				@selectionChanged="setSelectionChangedRow"
				@callOnRowDoubleClicked="fnRowDoubleClick"
				@fnList="fnList"
			/>
		</div>
	</popup>
</template>

<script>
import Popup from "@/components/Popup";
import CollapseSearchPane from "@/components/CollapseSearchPane";

import { EventBus } from "./EventBus";
import { CmnDistrInfPopupViewModel, Helper, AnimationHelper } from "./mixins";
import { isEmpty } from "@/utils";
import DataGrid from "@/components/DataGrid";

export default {
	name: "CmnDistrInfPopup",
	components: {
		Popup,
		CollapseSearchPane,
		DataGrid
	},
	mixins: [CmnDistrInfPopupViewModel, Helper, AnimationHelper],
	props: {
		paramData: {
			type: Object,
			default: () => {}
		}
	},
	data() {
		return {
			key: 0,
			resultList: [],
			currPage: 1,
			pageUnit: 100,
			pageSize: 100,
			search: {
				distrCd: "",
				distrNm: "",
				parDistrCd: "",
				distrTypCd: ""
			},

			gridProps: {
				totalItems: 0,
				columnDefs: [],
				rowData: [],
				// 일반적인 row 선택 or 더블클릭시
				selectedRows: [],
				initPage: true,
				editable: true,
				gridHeight: 360,
				rowClassRules: null,
				frameworkComponents: null,
				rowSelection: null,
				// 로우 선택시 선택이 되도록 하려면 false, 체크박스 or 라디오 선택시 선택이 되오도록 하려면 true
				suppressRowClickSelection: false,
				topBtnList: []
			},
			// 일반적인 row 선택 or 더블클릭시
			selectedRow: null,
			// 체크박스 또는 라디오 row 선택시
			selectedCheckOrRadioRows: [],
			isPaSearchPaneShow: true,
			paramDisabled: false,
			distrCdDisabled: false,
			cdList: {
				distrTypCdList: [],
				distrStatCdList: []
			}
		};
	},
	computed: {
		/**
     * 화면 title 설정
     * @return {String}
     */
		title() {
			return (
				this.i18n("label.distr") +
				this.i18n("label.inf") +
				this.i18n("label.search")
			);
		}
	},
	watch: {
		// row 더블 클릭 했을 경우 다시 호출
		selectedRow: {
			handler(params) {
				this.setColumnDefs(params);
				this.setRowClassRules();
			}
		}
	},
	created() {
		this.fnGetAxcCodeGrpList(["DISTR_TYP_CD", "DISTR_STAT_CD"]);
	},
	beforeMount() {
		// 그리드 초기화
		this.setColumnDefs();

		// row 클래스 동적 적용
		this.setRowClassRules();

		// 그리드의 frameworkComponents 셋(필요한 경우에만)
		this.setFrameworkComponents();

		// 그리드의 rowSelection 셋
		this.setRowSelection();
	},
	mounted() {
		if (!isEmpty(this.paramData)) {
			// console.log("##########################this.paramData#######################");
			// console.log(this.paramData);
		}

		console.log(this.paramData);
		this.paramDisabled = this.paramData.disabledYn == "Y" ? true : false;
		this.search.distrCd = this.paramData.cd;
		this.search.distrNm = this.paramData.nm;
		this.search.parDistrCd = this.paramData.parCd;
		this.search.distrTypCd = this.paramData.distrTypCd;
		console.log(this.search);
		// 총판 선택이 불가능하게 온 경우
		if (this.paramDisabled == true) {
		  if (!isEmpty(this.paramData.grpTypCd)) {
				this.search.distrTypCd = this.paramData.grpTypCd;
			}	
		}

		// 로그인 유저가 대리점일 경우
		if (this.$store.getters["Options/userInfo"].grpTypCd == "04") {
			this.paramDisabled = true;
			this.distrCdDisabled = true;
		}

		this.searchList();
	},
	methods: {
		/**
     * 업무 코드 리스트 페치
     * @params {Array} searchCodeGrpIdList
     */
		async fnGetAxcCodeGrpList(searchCodeGrpIdList = []) {
			if (isEmpty(searchCodeGrpIdList)) return;

			await this.$httpPost(
					"/api/bep/getAxcCodeGroupListByCodeGrpIdList.do",
					{ searchCodeGrpIdList },
					{ showLoading: true }
				)
				.then(response => {
					// this.cdList.distrTypCdList = response.data.resultList.find(
					// 	a => a[0].codeGrpId === "DISTR_TYP_CD"
					// );
					this.cdList.distrStatCdList = response.data.resultList.find(a => a[0].codeGrpId === "DISTR_STAT_CD");

					const grpTypCd = this.$store.getters["Options/userInfo"].grpTypCd;
					let distrTypCdListTmp = response.data.resultList.find(a => a[0].codeGrpId === "DISTR_TYP_CD");

					if (grpTypCd == "02") {
						// 본사총판
						this.cdList.distrTypCdList = distrTypCdListTmp.filter(a => a.codeId != "01");
					} else if (grpTypCd == "03") {
						// 총판
						this.cdList.distrTypCdList = distrTypCdListTmp.filter(a => a.codeId == "03" || a.codeId == "04");
					} else {
						this.cdList.distrTypCdList = distrTypCdListTmp;
					}
				})
				.catch(this.commonErrorHandler);

			this.key = this.key + 1;
		},
		/**
     * 총판 목록 조회
     */
		async searchList() {
			this.resultList = [];
			this.gridProps.rowData = [];

			this.currPage = 1;
			this.gridProps.initPage = true;

			await this.$httpPost(
					"/api/bep/getCmnDistrInfList.do",
					{
						searchDistrCd: this.search.distrCd,
						searchDistrNm: this.search.distrNm,
						searchParDistrCd: this.search.parDistrCd,
						searchDistrTypCd: this.search.distrTypCd,

						currPage: this.currPage,
						pageUnit: this.pageUnit,
						pageSize: this.pageSize
					},
					{
						showLoading: true
					}
				)
				.then(response => {
					// result 셋
					this.resultList = response.data.resultList;
					// 데이터 총 갯수 셋
					this.gridProps.totalItems = response.data.totalCount;
				})
				.then(() => {
					// 그리드에 조회해온 데이터 셋
					if (this.resultList)
						this.gridProps.rowData = this.resultList.map(a => ({
							...a,
							status: "R"
						}));
				})
				.catch(this.commonErrorHandler);
		},

		/**
     * 페이지 변환시 데이터 조회 함수
     * @params {int} currPage
     */
		fnList(currPage) {
			this.currPage = currPage;
			this.searchList();
			this.gridProps.initPage = false;
		},

		/**
     * 더블 클릭시
     * @params {Object} selectedRow
     */
		fnRowDoubleClick(selectedRow) {
			this.$emit("fnRowDoubleClick", selectedRow);
			this.$emit("close");
		},

		/**
     * 그리드에 하나의 row 선택 함수
     * @params {Object} params
     */
		setSelectionChangedRow(params) {
			this.gridProps.selectedRows = [];
			this.gridProps.selectedRows = [
				{ ...params.api.getSelectedRows()[0], status: "U" }
			];
		},

		/**
     * 그리드의 columnDefs 셋
     * @params {Object} params
     */
		setColumnDefs(params = {}) {
			this.$nextTick(() => {
				let gridWidth = this.$refs.dataGrid1.$el.clientWidth - 20;
				let totalWidth = 0;

				this.gridProps.columnDefs = this.cmnDistrInfPopupViewModel(params).map(
					a => {
						totalWidth += a.width === 0 ? 200 : a.width;

						return {
							headerName: a.headerName,
							field: a.field,
							width:
								a.field === "restCell" && totalWidth > 0
									? gridWidth - totalWidth + 200
									: a.width,
							editable: a.editable,
							pinned: a.pinned,
							cellStyle: a.cellStyle,
							cellClassRules: a.cellClassRules,
							valueFormatter: a.valueFormatter,
							cellRenderer: a.cellRenderer,
							checkboxSelection: a.checkboxSelection,
							suppressMenu: a.suppressMenu,
							sortable: a.sortable
						};
					}
				);
			});
		},

		/**
     * 그리드의 rowClass 셋
     */
    setRowClassRules() {
      this.gridProps.rowClassRules = {
        // 'selected-row': param => this.selectedRow && (this.selectedRow.rowIndex === param.rowIndex)
      };
    },

    /**
     * 그리드의 frameworkComponents 셋
     */
    setFrameworkComponents() {
      this.gridProps.frameworkComponents = {};
    },

    /**
     * 그리드의 rowSelection 셋
     */
    setRowSelection() {
      // 단수 선택 경우 'single', 복수 선택일 경우 'multiple'
      this.gridProps.rowSelection = "single";
    },

		/**
     * 셀렉트박스 또는 라디오 선택시
     * @params {Object} params
     */
		setRowSelected(params) {
			if (params.node.isSelected()) {
				this.selectedCheckOrRadioRows.push(params.data);
			} else {
				this.selectedCheckOrRadioRows.pop(params.data);
			}
		},

		/**
     * 총판코드 입력필드 활성화 처리
     * @params {event} event
     */
		chgLvlCd(event) {
			let value = event.target.value;
			let grpTypCd = this.$store.getters["Options/userInfo"].grpTypCd;

			// 로그인 유저와 같은 레벨일 경우
			if (grpTypCd == value) {
				this.paramDisabled = false;
				this.distrCdDisabled = true;
				this.search.distrCd = this.$store.getters["Options/userInfo"].grpCd;
			} else {
				this.paramDisabled = false;
				this.distrCdDisabled = false;
				this.search.distrCd = "";
			}
		},

		/**
     * 입력 폼 초기화
     */
		clear() {
			this.search = {
				distrCd: "",
				distrNm: "",
				parDistrCd: ""
			};
			this.searchList();
		}
	}
};
</script>

<style lang="scss" scoped>
@import "@/scss/_color.scss";
@import "@/scss/_button.scss";
</style>


