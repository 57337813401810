/**
 * 1. 개요 : 업체검색
 * 2. 처리내용 : 업체검색 Mixins - 화면 모델
 * @File Name : CmnCorpPopupViewModel.js
 * @date : 2020. 04. 07. 13:00:00
 * @author : 이현우
 * @history :
 *-----------------------------------------------------------------------
 * 변경일			          		  작성자		변경내용
 *-----------------------------------------------------------------------
 * 2020. 04. 07. 13:00:00	    이현우	  최초 작성
 *-----------------------------------------------------------------------
 */
import { lookupValue } from "@/utils/dataGrid";
import { phoneFormatter } from "@/utils/formatter";

export default {
  methods: {
    cmnCorpPopupViewModel(params = {}) {
      return [
        {
          field: "rnum",
          headerName: "",
          type: Number,
          width: 50,
          isPk: false,
          editable: false,
          pinned: true,
          sortable: true,
          cellStyle: {
            textAlign: 'center'
          },
          cellClassRules: {
            'selected-cell': param => params && param.rowIndex === params.rowIndex
          },
          valueFormatter : (param) => {
            // 오름차순
            // return (((this.currPage - 1) * this.pageUnit) + param.node.rowIndex) + 1;

            // 내림차순
            return this.gridProps.totalItems - (((this.currPage - 1) * this.pageUnit) + param.node.rowIndex);
          },
        },
        {
          field: "corpTypCd",
          headerName: this.i18n('label.corpTyp'),
          type: String,
          width: 150,
          isPk: false,
          editable: false,
          pinned: false,
          suppressMenu: false,
          sortable: true,
          cellStyle: {
            textAlign: 'center'
          },
          cellClassRules: {},
          valueFormatter: param => param.value && lookupValue(this.cdList.corpTypCdList, param.value)
        },
        {
          field: "corpNm",
          headerName: this.i18n('label.corpNm'),
          type: String,
          width: 150,
          isPk: false,
          editable: false,
          pinned: false,
          suppressMenu: false,
          sortable: true,
          cellStyle: {},
          cellClassRules: {}
        },
        {
          field: "corpEnm",
          headerName: this.i18n('label.corpEnm'),
          type: String,
          width: 150,
          isPk: false,
          editable: false,
          pinned: false,
          suppressMenu: false,
          sortable: true,
          cellStyle: {},
          cellClassRules: {}
        },
        {
          field: "corpStatCd",
          headerName: this.i18n('label.corpStat'),
          type: String,
          width: 120,
          isPk: false,
          editable: false,
          pinned: false,
          suppressMenu: false,
          sortable: true,
          cellStyle: {
            textAlign: 'center'
          },
          cellClassRules: {},
          valueFormatter: param => param.value && lookupValue(this.cdList.corpStatCdList, param.value)
        },
        {
          field: "bizNo",
          headerName: this.i18n('label.bizNo'),
          type: String,
          width: 130,
          isPk: false,
          editable: false,
          pinned: false,
          suppressMenu: false,
          sortable: true,
          cellStyle: {
            textAlign: 'center'
          },
          cellClassRules: {}
        },
        {
          field: "telNo",
          headerName: this.i18n('label.telNo'),
          type: String,
          width: 130,
          isPk: false,
          editable: false,
          pinned: false,
          suppressMenu: false,
          sortable: true,
          cellStyle: {
            textAlign: 'center'
          },
          cellClassRules: {},
          valueFormatter: param => param.value && phoneFormatter(param.value)
        },
        {
          field: "mobileNo",
          headerName: this.i18n('label.mobileNo'),
          type: String,
          width: 130,
          isPk: false,
          editable: false,
          pinned: false,
          suppressMenu: false,
          sortable: true,
          cellStyle: {
            textAlign: 'center'
          },
          cellClassRules: {},
          valueFormatter: param => param.value && phoneFormatter(param.value)
        },
        {
          field: "addedDate",
          headerName: this.i18n("label.addedDate"),
          type: String,
          width: 130,
          isPk: false,
          editable: false,
          pinned: false,
          suppressMenu: false,
          sortable: true,
          cellStyle: {
            textAlign: "center"
          },
          cellClassRules: {},
          valueFormatter: param => this.i18nDtTmFormat(param.value)
        },
        {
          field: "restCell",
          headerName: "",
          width: 0,
          type: String,
          isPk: false,
          editable: false,
          pinned: false,
          suppressMenu: false,
          sortable: false,
          cellStyle: {},
          cellClassRules: {}
        }
      ];
    }
  }
};
