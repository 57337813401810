<template>
	<main class="app-main">
		<transition name="fade-transform" mode="out-in">
			<keep-alive :include="cachedViews">
				<router-view :key="key" />
			</keep-alive>
		</transition>
	</main>
</template>

<script>
export default {
	name: "MAppMain",
	computed: {
		cachedViews() {
			return this.$store.state.TagsView.cachedViews;
		},
		key() {
			return this.$route.path;
		}
	}
};
</script>

<style lang="scss" scoped>
.app-main {
	min-height:calc(100% - 90px);
	}
</style>

<style lang="scss">
// fix css style bug in open el-dialog
.el-popup-parent--hidden {
	.fixed-header {
		padding-right: 15px;
	}
}
</style>
