var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "input-date time-box short-time-input" },
    [
      _c(
        "date-picker",
        _vm._g(
          _vm._b(
            {
              key: _vm.updateKey,
              attrs: {
                "value-type": _vm.hourOnly ? _vm.hourFormat : _vm.format,
                type: _vm.type,
                lang: _vm.lang,
                editable: false,
                format: _vm.hourOnly ? _vm.hourFormat : _vm.format,
                clearable: false,
                "append-to-body": "",
                "time-picker-options": _vm.hourOnly
                  ? _vm.timePickerOptions
                  : null,
                placeholder: _vm.placeholder
              },
              on: {
                change: function(value) {
                  return _vm.$emit("apply", value)
                },
                input: function($event) {
                  return _vm.updateDate()
                }
              },
              model: {
                value: _vm.date,
                callback: function($$v) {
                  _vm.date = $$v
                },
                expression: "date"
              }
            },
            "date-picker",
            _vm.$attrs,
            false
          ),
          _vm.$listeners
        )
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }