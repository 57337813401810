<template>
  <div @click="toggleClick">
    <a href="javascript:;" class="sp-b">side-menu show or hide button</a>
  </div>
</template>

<script>
export default {
  name: 'MHamburger',
  props: {
    isActive: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    toggleClick() {
      // 햄버거 메뉴 열릴 때 body에 overflow='hidden'주기
      document.querySelector('body').style.overflow = 'hidden';
      this.$emit('toggleClick')
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/scss/mobile/_m-icon.scss";
.mobile{
  .hamburger-container{
    position:absolute;
    top:0;
    left:0;
    > a{
      width:65px;
      height:50px;
      font-size:0; 
      &::before{
        width:25px;
        height:16.5px;
        margin-top:16px;
      }
    }
  }
}
</style>