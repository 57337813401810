var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "navbar flex-btw-center" },
    [
      _vm.showLogo ? _c("logo") : _vm._e(),
      _c(
        "div",
        { staticClass: "right flex-center" },
        [
          _c(
            "a",
            {
              ref: "logout",
              staticClass: "logout",
              attrs: { href: "javascript:;" },
              on: {
                click: function($event) {
                  $event.stopPropagation()
                  return _vm.onLogout($event)
                }
              }
            },
            [_vm._v(" " + _vm._s(_vm._f("i18n")("label.logout")) + " ")]
          ),
          _c("div", { staticClass: "btn-pw-change" }, [
            _c(
              "button",
              {
                attrs: { type: "button" },
                on: {
                  click: function($event) {
                    $event.stopPropagation()
                    _vm.showChangePasswordPopup = true
                  }
                }
              },
              [_vm._v(_vm._s(_vm._f("i18n")("label.pwdChg")))]
            )
          ]),
          _c("lang-change")
        ],
        1
      ),
      _vm.showChangePasswordPopup
        ? _c("change-password-popup", {
            attrs: { chgPassOrgType: "LOG_IN" },
            on: {
              close: function($event) {
                _vm.showChangePasswordPopup = false
              }
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }