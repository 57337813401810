/**
 * 1. 개요 : 공통 컴포넌트
 * 2. 처리내용 : 공통 컴포넌트 - DefaultButton
 * @File Name : DefaultButton.vue
 * @date : 2020. 02. 20. 09:46:00
 * @author : 이상철
 * @history :
 *-----------------------------------------------------------------------
 * 변경일			          		  작성자		변경내용
 *-----------------------------------------------------------------------
 * 2020. 2. 20. 09:46:00	    이상철	  최초 작성
 *-----------------------------------------------------------------------
 */
<template>
	<button
		:type="type || 'button'"
		:class="classes"
		@click.stop="$emit('click')"
		@mouseover="mouseover"
		@mouseout="mouseout"
	>
		<img v-if="leftIconPath" :src="leftIconPath" class="button-left-icon" alt>
		<slot />
		<img v-if="rightIconPath" :src="rightIconPath" class="button-right-icon" alt>
	</button>
</template>

<script>
import { isEmpty } from '@/utils'

export default {
	name: "DefaultButton",
	props: {
		type: {
			type: String,
			default: "button"
		},
		leftIconPath: {
			type: String,
			default: ""
		},
		rightIconPath: {
			type: String,
			default: ""
		},
		mouseover: {
			type: Function,
			default: () => null
		},
		mouseout: {
			type: Function,
			default: () => null
		}
	},
	data() {
		return {
			classes: `default ${(!isEmpty(this.leftIconPath) || !isEmpty(this.rightIconPath)) && "icon-down"}`
		};		
	}
};
</script>

<style lang="scss" scoped>
@import "@/scss/_button.scss";
#app{
	button.icon-down{
		padding:5px 10px;
		border-radius:3px;
		img{
			margin-right:5px;
			vertical-align: -3px;
		}
	}
}
</style>


