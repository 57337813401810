<template>
	<header class="navbar">
		<m-hamburger
			id="hamburger-container"
			:is-active="sidebar.opened"
			class="hamburger-container"
			@toggleClick="toggleSideBar"
		/>
		<m-logo v-if="showLogo" />
	</header>
</template>

<script>
import { mapState } from "vuex";
import MLogo from "./MLogo";
import MHamburger from "./MHamburger";

export default {
	name: "MNavbar",
	components: {
		MHamburger,
		MLogo
	},
	data() {
		return {
			shownTopNotifyPopup: false,
			shownUserInfoPopup: false,
			shownPassChangePopup: false,
			shownLangChangePopup: false
		};
	},
	computed: {
		...mapState({
			sidebar: state => state.Sidebar.sidebar,
			needTagsView: state => state.Settings.tagsView,
			fixedHeader: state => state.Settings.fixedHeader
		}),
		userInfo() {
			return this.$store.getters["Options/userInfo"];
		},
		showLogo() {
			return this.$store.state.Settings.sidebarLogo;
		}
	},
	methods: {
		toggleSideBar() {
			this.$store.dispatch("Sidebar/toggleSideBar");
		},
		handleCommand(cmd) {
			if (cmd === "1") {
				this.shownUserInfoPopup = true;
			} else if (cmd === "2") {
				this.shownPassChangePopup = true;
			} else if (cmd === "3") {
				this.shownLangChangePopup = true;
			}
		}
	}
};
</script>

<style lang="scss" scoped>
@import "@/scss/_color.scss";
.mobile {
	.navbar {
		position: relative;
		width: 100%;
		height: 50px;
		padding: 13px 65px 0;
		text-align: center;
		background-color: $pale;
		box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.4);
		z-index: 10;
	}
}
</style>
