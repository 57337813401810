/**
 * 1. 개요 : Inputs 공통 컴포넌트
 * 2. 처리내용 : Inputs 공통 컴포넌트 - DateRangeAndInputDate
 * @File Name : index.vue
 * @date : 2020. 02. 20. 09:46:00
 * @author : 이상철
 * @history :
 *-----------------------------------------------------------------------
 * 변경일			          		  작성자		변경내용
 *-----------------------------------------------------------------------
 * 2020. 2. 20. 09:46:00	    이상철	  최초 작성
 *-----------------------------------------------------------------------
 */
<template>
  <div :key="updateKey">
    <date-range 
      ref="dateRange"
      @change="dateRangeChanged" 
      :diffYn="diffYn"
      :propsServerDate="serverDate"
    />
    <div class="combo-box-for-log" :class="{'my-page-date': hideInputTime}">
      <input-date
        v-model="search.tranStaDt"
        @dateValue="changeDateOrTime"
        @input="clickStaDtm"
        :hideInputTime="hideInputTime"
      />
        <!-- :disabled-date="limitDatePickStaDt" -->
      <input-time
        ref="dateRangeAndInputDateStaTm"
        v-if="!hideInputTime"
        v-model="search.tranStaTm"
        :hourOnly="true"
        @timeValue="changeDateOrTime"
      />
      <input-date
        v-model="search.tranEndDt"
        @dateValue="changeDateOrTime"
        @input="clickEndDtm"
        :hideInputTime="hideInputTime"
      />
        <!-- :disabled-date="limitDatePickEndDt" -->
      <input-time
        v-if="!hideInputTime"
        v-model="search.tranEndTm"
        :hourOnly="true"
        @timeValue="changeDateOrTime"
      />
        <!-- :disabled-time="limitEndTimePick" -->
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { isEmpty } from "@/utils";

export default {
  name: "DateRangeAndInputDate",
  data() {
    return {
      diffYn: false,
      copySearch: {
        tranStaDt: "",
        tranEndDt: "",
        tranStaTm: "",
        tranEndTm: ""
      },

      serverDate: '',
      serverTime: '',
      
      updateKey: 0,

      search: {
        tranStaDt: "",
        tranEndDt: "",
        tranStaTm: "",
        tranEndTm: "",
      },
    };
  },
  props: {
    hideInputTime: {
      type: Boolean,
      default: false
    },
  },
  created(){
    this.serverTimeInit();
  },
  mounted(){
  },
  methods: {
    // 시작 날짜 disabled control
    limitDatePickStaDt(date){
      return date > new Date(moment(this.serverDate, "YYYYMMDD")) 
    },
    // 끝 날짜 disabled control
    limitDatePickEndDt(date){
      return date > new Date(moment(this.serverDate, "YYYYMMDD")) || new Date(moment(this.search.tranStaDt, "YYYYMMDD")) > date
    },
    // 끝 시간 disabled control
    limitEndTimePick(time){
      if(this.search.tranEndDt === this.serverDate && this.search.tranStaDt === this.search.tranEndDt) { // '오늘'
        return time.getHours() > this.serverTime || time.getHours() < this.search.tranStaTm
      } else if(this.search.tranEndDt === this.serverDate) { // 끝날짜가 '오늘'
        return time.getHours() > this.serverTime
      } else if(this.search.tranStaDt === this.search.tranEndDt){ // '오늘'은 아니지만 둘이 날짜가 같을 경우
        return time.getHours() < this.search.tranStaTm
      } else return null
    },
    // 서버시간 겟.
    getServerTime(){
      return this.$httpPost(
        "/api/com/getCurrentServerTime.do",
				{},
				{ showLoading: false }
      )
      .then(response => {
          this.serverDate = moment(response.data.result)
                            .format("YYYYMMDD");
          this.serverTime = moment(response.data.result)
                            .subtract("hours")
                            .format("HH:mm:ss") // 세 나라 동일 포멧
                            .replace(":", "")
                            .slice(0, 2);
          // console.log('getServerTime', response.data.result);
          this.$emit('getServerDTm', {serverDate: this.serverDate, serverTime: this.serverTime})
        })
      .catch(this.commonErrorHandler);
    },
    // 서버시간 초기 셋.
    serverTimeInit() {
      this.getServerTime()
      .then(() => {
        // search 초기화 (최초 조회: 서버시간 기준으로 한달 데이터)
        this.search = {
          tranStaTm: "00",
          tranEndTm: this.serverTime,
          tranStaDt: moment(this.serverDate).subtract(1, "day").format("YYYYMMDD"),
          tranEndDt: moment(this.serverDate).format("YYYYMMDD"),
        }

        this.updateKey = this.updateKey + 1;
        this.$emit("mergeDateRangeIntoSearchObject", this.search)
        this.$emit("onSearchList");
      })
      .catch(this.commonErrorHandler);
    },
    /**
     * 데이터 피커나 데이트 타임을 선택하면 데이트 레인지 비활성화
     * @params {String} params
     */
    changeDateOrTime(params) {
      const containYn = Object.values(this.copySearch).some(a => a === params);
      
      if (containYn === true) this.diffYn = true;
      else this.diffYn = false;
      this.$emit("mergeDateRangeIntoSearchObject", this.search)
    },
    /**
     * 시작날짜 클릭시
     * @params {String} params
     */
    clickStaDtm(params) {
      this.search.tranStaDt = params;

      // 12.28 김차장님: 모든 옵션 삭제해달라고 하셔서 주석처리
      // isBetween: 시작 날짜가 한 달 사이에 있으면 true를 반환, 아니면 false 반환.
      // moment("2020-09-10").isBetween("2020-09-11","2020-09-30") // false
      // const includeBeforeMonthYn = moment(this.search.tranStaDt).isBetween(
      //                               moment(this.search.tranEndDt, "YYYYMMDD")
      //                               .subtract(1, "months").format("YYYYMMDD"), // 끝날짜 기준 한달 전 날짜
      //                               this.search.tranEndDt
      //                             );
      
      // if(!includeBeforeMonthYn && this.search.tranStaDt === this.search.tranEndDt) { // 2. 날짜가 같은 경우
      //   this.search.tranEndDt = this.search.tranStaDt;
      //   return;
      // } else if (!includeBeforeMonthYn && this.search.tranStaDt !== this.search.tranEndDt) { // 1. 한달 내에 없을 경우
      //   this.search.tranEndDt = moment(this.search.tranStaDt, "YYYYMMDD")
      //                           .subtract(-1, "months").format("YYYYMMDD") // 시작날짜 기준 한달 이후 날짜

      //   if(this.search.tranEndDt > this.serverDate) { // 1-2. 끝 날짜가 서버날짜보다 클 경우, 서버날짜으로 변경
      //     this.search.tranEndDt = this.serverDate;
      //     this.search.tranEndTm = this.serverTime; // 시간은 오늘 시간
      //     this.copySearch.tranEndTm = this.serverTime;
      //   } 
      //   return;
      // } 
    },
    /**
     * 끝날짜 클릭시
     * @params {String} params
     */
    clickEndDtm(params) {
      this.search.tranEndDt = params;
      
      // 끝날짜를 오늘 날짜 선택시 서버 시간으로 조정
      if(this.search.tranEndDt === this.serverDate) {
        this.search.tranEndTm = this.serverTime;
        this.copySearch.tranEndTm = this.serverTime;
      } else {
        this.search.tranEndTm = "23";
        this.copySearch.tranEndTm = "23";
      }

      // 12.28 김차장님: 모든 옵션 삭제해달라고 하셔서 주석처리
      // isBetween: 시작 날짜가 한 달 사이에 있으면 true를 반환, 아니면 false 반환.
      // moment("2020-09-10").isBetween("2020-09-11","2020-09-30") // false
      // const includeBeforeMonthYn = moment(this.search.tranEndDt).isBetween(
      //                               this.search.tranStaDt,
      //                               moment(this.search.tranStaDt, "YYYYMMDD")
      //                               .subtract(-1, "months").format("YYYYMMDD"), // 시작 날짜 기준 한달 후 날짜
      //                             );

      // if(!includeBeforeMonthYn && this.search.tranStaDt === this.search.tranEndDt) { // 2. 날짜가 같은 경우
      //   this.search.tranStaDt = this.search.tranEndDt;
      //   return;
      // } else if (!includeBeforeMonthYn && this.search.tranStaDt !== this.search.tranEndDt) { // 1. 한달 내에 없을 경우
      //   this.search.tranStaDt = moment(this.search.tranEndDt, "YYYYMMDD")
      //                           .subtract(1, "months").format("YYYYMMDD") // 끝 날짜 기준 한달 이전 날짜
      //   return;
      // }
    },
    /**
     * 데이터 레인지 클릭시 호출
     * @params {String} rangeUnit
     * @params {String} startDt
     * @params {String} endDt
     * @return {Object}
     */
    dateRangeChanged(rangeUnit, startDt, endDt){
      // 데이터 레인지 클릭할 때마다 서버시간을 새로 가져온다. 12월 21일 추가.
      this.getServerTime();
      
      // input-date 컴포넌트에 "YYYYMMDD"포맷으로 보내면 보여줄 때는 나라별 포맷으로 보여줌.
      const getWeekBeforeDt = moment(this.serverDate, "YYYYMMDD").subtract(1, "weeks").format("YYYYMMDD");
      const getNowDt = moment(this.serverDate, "YYYYMMDD").format("YYYYMMDD");
      startDt = moment(startDt, "YYYYMMDD").format("YYYYMMDD");
      endDt = moment(endDt, "YYYYMMDD").format("YYYYMMDD");

      if (rangeUnit === "1D") { // 당일
        this.search.tranStaDt = getNowDt;
        this.search.tranEndDt = getNowDt;
        this.search.tranStaTm = "00";
        this.search.tranEndTm = this.serverTime;

        this.copySearch.tranStaDt = getNowDt;
        this.copySearch.tranEndDt = getNowDt;
        this.copySearch.tranStaTm = "00";
        this.copySearch.tranEndTm = this.serverTime;
      }

      if (rangeUnit === "7D") { // 일주일 전
        this.search.tranStaDt = getWeekBeforeDt;
        this.search.tranEndDt = getNowDt;
        this.search.tranStaTm = "00";
        this.search.tranEndTm = this.serverTime;

        this.copySearch.tranStaDt = getWeekBeforeDt;
        this.copySearch.tranEndDt = getNowDt;
        this.copySearch.tranStaTm = "00";
        this.copySearch.tranEndTm = this.serverTime;
      }

      if (rangeUnit === "1M") { // 한 달 전
        this.search.tranStaDt = moment(getNowDt, "YYYYMMDD")
                                .subtract(1, "months").format("YYYYMMDD");
        this.search.tranEndDt = getNowDt;
        this.search.tranStaTm = "00";
        this.search.tranEndTm = this.serverTime;
        
        this.copySearch.tranStaDt = moment(getNowDt, "YYYYMMDD")
                                    .subtract(1, "months").format("YYYYMMDD");
        this.copySearch.tranEndDt = getNowDt;
        this.copySearch.tranStaTm = "00";
        this.copySearch.tranEndTm = this.serverTime;
      }

      if (rangeUnit === "2M-") { // 두 달 전 달
        endDt = moment(endDt, "YYYYMMDD")
                .subtract(2, "months").format("YYYYMMDD");
        this.search.tranStaDt = moment(endDt, "YYYYMMDD")
                                .startOf("month").format("YYYYMMDD");
        this.search.tranEndDt = moment(endDt, "YYYYMMDD")
                                .endOf("month").format("YYYYMMDD");
        this.search.tranStaTm = "00";
        this.search.tranEndTm = "23";

        this.copySearch.tranStaDt = moment(endDt, "YYYYMMDD")
                                    .startOf("month").format("YYYYMMDD");
        this.copySearch.tranEndDt = moment(endDt, "YYYYMMDD")
                                    .endOf("month").format("YYYYMMDD");
        this.copySearch.tranStaTm = "00";
        this.copySearch.tranEndTm = "23";
      }
      if (rangeUnit === "1M-") { // 한 달 전 달
        endDt = moment(endDt, "YYYYMMDD")
                .subtract(1, "months").format("YYYYMMDD");

        this.search.tranStaDt = moment(endDt, "YYYYMMDD")
                                .startOf("month").format("YYYYMMDD");
        this.search.tranEndDt = moment(endDt, "YYYYMMDD")
                                .endOf("month").format("YYYYMMDD");
        this.search.tranStaTm = "00";
        this.search.tranEndTm = "23";

        this.copySearch.tranStaDt = moment(endDt, "YYYYMMDD")
                                    .startOf("month").format("YYYYMMDD");
        this.copySearch.tranEndDt = moment(endDt, "YYYYMMDD")
                                    .endOf("month").format("YYYYMMDD");
        this.copySearch.tranStaTm = "00";
        this.copySearch.tranEndTm = "23";
      }
      if (rangeUnit === "0M-") { // 현재 달
        this.search.tranStaDt = moment(getNowDt, "YYYYMMDD")
                                .startOf("month").format("YYYYMMDD");
        this.search.tranEndDt = getNowDt;
        this.search.tranStaTm = "00";
        this.search.tranEndTm = this.serverTime;

        this.copySearch.tranStaDt = moment(getNowDt, "YYYYMMDD")
                                    .startOf("month").format("YYYYMMDD");
        this.copySearch.tranEndDt = getNowDt;
        this.copySearch.tranStaTm = "00";
        this.copySearch.tranEndTm = this.serverTime;
      }
      this.$emit("mergeDateRangeIntoSearchObject", this.search)
      return this.search;
    },
    searchObjectClear(){
      this.search = {
        tranStaDt: "",
        tranEndDt: "",
        tranStaTm: "",
        tranEndTm: "",
      }
    },
    focus(){
      this.$nextTick(() => {
        this.$refs.dateRangeAndInputDateStaTm && 
        this.$refs.dateRangeAndInputDateStaTm.$children[0].focus()
      })
    }
  }
};
</script>
<style lang="scss"> 
.combo-box-for-log{ // 7.30 이지혜 추가. 데이트 인풋보다 시간 인풋이 더 짧음.
  display: inline !important;
  margin-left: 5px;
  vertical-align: middle;
  font-size:0;
  .input-date{
    display:inline-block;
    vertical-align: middle;
    width:117px !important;
    .mx-datepicker{
      width: 116px !important;
    }
  }
  .short-time-input { // 시간 인풋 짧은 버전
    display:inline-block;
    vertical-align: middle;
    width:52px !important;
    .mx-datepicker{
      width: 52px !important;
    }
  }
}
.combo-box-for-log { // 8월 28일 이지혜 추가. 마이페이지에서 date만 있는 경우
  &.my-page-date{
    margin-left: 10px;
    .input-date{
      margin-left:4px;
    }
    .only-date{
      .mx-input-wrapper {
        width: 120px;
      }
    }
  }
}
</style>