import store from "@/store";

const domain =
  process.env.NODE_ENV === "development"
    ? "OFFICE"
    : process.env.NODE_ENV === "staging"
    ? window.location.host
        .split(".")[0]
        .replace("dev", "")
        .replace("test", "")
        .toUpperCase()
    : window.location.host.split(".")[0].toUpperCase();

export default [
  {
    path: "/login",
    component: () => {
      if (domain === "OFFICE") return import("@/views/login/domains/office");
      else if (domain === "AGENCY")
        return import("@/views/login/domains/agency");
      else if (domain === "MERCHANT")
        return import("@/views/login/domains/merchant");
      else if (domain === "BANK") return import("@/views/login/domains/bank");
      else return import("@/views/login/domains/office");
    },
    meta: { layout: "empty" },
    hidden: true
  },
  {
    path: "/m/login",
    component: () => {
      if (domain === "OFFICE")
        return import("@/views/mobile/login/domains/office");
      else if (domain === "AGENCY")
        return import("@/views/mobile/login/domains/agency");
      else if (domain === "MERCHANT")
        return import("@/views/mobile/login/domains/merchant");
      else if (domain === "BANK")
        return import("@/views/mobile/login/domains/bank");
      else return import("@/views/mobile/login/domains/office");
    },
    meta: { layout: "empty" },
    hidden: true
  },
  {
    path: "/layout",
    component: () => import("@/layout/admin"),
    meta: { layout: "empty" },
    hidden: true
  },
  {
    path: "/m/layout",
    component: () => import("@/layout/mobile/admin"),
    meta: { layout: "empty" },
    hidden: true
  },
  {
    path: "/notAuthority",
    component: () => import("@/views/authority"),
    meta: { layout: "empty" },
    hidden: true
  },
  {
    path: "/401",
    component: () => import("@/views/error-page/401"),
    meta: { layout: "empty" },
    hidden: true
  },
  {
    path: "/404",
    component: () => import("@/views/error-page/404"),
    meta: { layout: "empty" },
    hidden: true
  },
  {
    path: "/redirect",
    component: () => import("@/layout/admin"),
    hidden: true,
    children: [
      {
        path: "/redirect/:path*",
        component: () => import("@/views/redirect/index"),
        meta: { layout: "empty" }
      }
    ],
    meta: { layout: "empty" }
  },
  {
    name: "dashboard",
    path: "/dashboard",
    component: () => import("@/views/dashboard"),
    meta: { layout: "admin" },
    hidden: true
  },
  {
    name: "GrpMenuAuthorMng",
    path: "/admin/GrpMenuAuthorMng",
    component: () => import("@/views/admin/GrpMenuAuthorMng"),
    meta: { layout: "admin" }
  },
  {
    name: "UserMenuAuthorMng",
    path: "/admin/UserMenuAuthorMng",
    component: () => import("@/views/admin/UserMenuAuthorMng"),
    meta: { layout: "admin" }
  },
  {
    name: "LogJournalMng",
    path: "/admin/LogJournalMng",
    component: () => import("@/views/admin/LogJournalMng"),
    meta: { layout: "admin" }
  },
  {
    name: "LogAccessMng",
    path: "/admin/LogAccessMng",
    component: () => import("@/views/admin/LogAccessMng"),
    meta: { layout: "admin" }
  },
  {
    name: "MsgMng",
    path: "/admin/MsgMng",
    component: () => import("@/views/admin/MsgMng"),
    meta: { layout: "admin" }
  },
  {
    name: "ResMng",
    path: "/admin/ResMng",
    component: () => import("@/views/admin/ResMng"),
    meta: { layout: "admin" }
  },
  {
    name: "CodeMng",
    path: "/admin/CodeMng",
    component: () => import("@/views/admin/CodeMng"),
    meta: { layout: "admin" }
  },
  {
    name: "MenuMng",
    path: "/admin/MenuMng",
    component: () => import("@/views/admin/MenuMng"),
    meta: { layout: "admin" }
  },
  {
    name: "DeptMng",
    path: "/admin/DeptMng",
    component: () => import("@/views/admin/DeptMng"),
    meta: { layout: "admin" }
  },
  {
    name: "HistListMng",
    path: "/admin/HistListMng",
    component: () => import("@/views/admin/HistListMng"),
    meta: { layout: "admin" }
  },
  {
    name: "TrmJobMng",
    path: "/biz/bep/trm/TrmJobMng",
    component: () => import("@/views/biz/bep/trm/TrmJobMng"),
    meta: { layout: "admin" }
  },
  {
    name: "PermMng",
    path: "/admin/PermMng",
    component: () => import("@/views/admin/PermMng"),
    meta: { layout: "admin" }
  },

  // 모바일 '설정' 메뉴
  {
    name: "MSettingMng",
    path: "/m/admin/SettingMng",
    component: () => import("@/views/mobile/admin/MSettingMng/MSettingMng"),
    meta: { layout: "mobile-admin" }
  },
  {
    name: "MChangeLangMng",
    path: "/m/admin/SettingMng/ChangeLangMng",
    component: () => import("@/views/mobile/admin/MSettingMng/MChangeLangMng"),
    meta: { layout: "mobile-admin" }
  },
  {
    name: "MChangePwdMng",
    path: "/m/admin/SettingMng/ChangePwdMng",
    component: () => import("@/views/mobile/admin/MSettingMng/MChangePwdMng"),
    meta: { layout: "mobile-admin" }
  },

  {
    name: "AxcCodeMng",
    path: "/biz/bep/svc/AxcCodeMng",
    component: () => import("@/views/biz/bep/svc/AxcCodeMng"),
    meta: { layout: "admin" }
  },
  {
    name: "TTypeAMng",
    path: "/biz/bep/tType/TTypeAMng",
    component: () => import("@/views/biz/bep/tType/TTypeAMng"),
    meta: { layout: "admin" }
  },
  {
    name: "TTypeBMng",
    path: "/biz/bep/tType/TTypeBMng",
    component: () => import("@/views/biz/bep/tType/TTypeBMng"),
    meta: { layout: "admin" }
  },
  
  // [SETTLE File 관리] 메뉴
  {
    name: "SettleFileLstMng",
    path: "/biz/bep/trLst/SettleFileLstMng",
    component: () => import("@/views/biz/bep/trLst/SettleFileLstMng"),
    meta: { layout: "admin" }
  },
];
