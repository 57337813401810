/**
 * 1. 개요 : 공통 컴포넌트
 * 2. 처리내용 : 공통 컴포넌트 - DataGrid
 * @File Name : index.vue
 * @date : 2020. 02. 20. 09:46:00
 * @author : 이상철
 * @history :
 *-----------------------------------------------------------------------
 * 변경일			          		  작성자		변경내용
 *-----------------------------------------------------------------------
 * 2020. 2. 20. 09:46:00	    이상철	  최초 작성
 *-----------------------------------------------------------------------
 */
<template>
	<div class="grid-wrap">
		<div class="grid-top-box">
			<div class="CSubTit">
				<h3 v-if="subTitle" class="title">{{ subTitle }}</h3>
			</div>
			<div class="right" :key="updateKey">
				<span v-for="(btn, key) in topBtnList" :key="key">
					<primary-button
						v-if="btn.type === 'primary'"
						@click="btn.event"
						:disabled="btn.disabled"
					>{{ btn.title }}</primary-button>
					<default-button
						v-if="btn.type === 'default'"
						@click="btn.event"
						:disabled="btn.disabled"
					>{{ btn.title }}</default-button>
					<!-- case disabled : 버튼에 직접 disabled 처리하면 디자인 적용됩니다. -->
				</span>
			</div>
		</div>
		<div :style="{ height: gridHeight.toString() + 'px', width: gridWidth }" class="grid-box">
			<ag-grid-vue
				id="myGrid"
				class="ag-theme-material"
				:gridOptions="gridOptions"
				style="width: 100%; height: 100%;"
				:style="{display: showWholeRow}"
				:defaultColDef="defaultColDef"
				:enableRangeSelection="enableRangeSelection"
				:rowSelection="rowSelection"
				:suppressRowTransform="suppressRowTransform"
				:suppressColumnVirtualisation="suppressColumnVirtualisation"
				:suppressRowClickSelection="suppressRowClickSelection"
				:suppressContextMenu="true"
				:enableCellTextSelection="true"
				:columnDefs="columnDefs"
				:animateRows="animateRows"
				:rowData="rowData"
				rowHeight="31"
				:rowClassRules="rowClassRules"
				:icons="icons"
				:localeText="localeText"
				:modules="modules"
				@modelUpdated="onModelUpdated"
				@rowSelected="onRowSelected"
				@selectionChanged="onSelectionChanged"
				:isRowSelectable="isRowSelectable"
				:suppressCellSelection="true"
			/>
		</div>
		<div class="total" v-if="totalYn">{{ "label.totalCnt" | i18n }}: {{ totalItems | numberFormat }}</div>
		<div v-if="pagiYn">
			<pagination
				v-model="pagination"
				:total-items="totalItems"
				:max-size="perPage"
				:items-per-page="perPage"
				@change="$emit('fnList', pagination.currentPage)"
			/>
		</div>
	</div>
</template>

<script>
import { isEmpty } from "@/utils";
// import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";
// import { CsvExportModule } from "@ag-grid-community/csv-export";
// import { InfiniteRowModelModule } from "@ag-grid-community/infinite-row-model";
import { AllModules } from "@ag-grid-enterprise/all-modules";

import { AgGridVue } from "@ag-grid-community/vue";

export default {
	name: "DataGrid",
	components: {
		AgGridVue
	},
	props: {
		gridHeight: {
			type: Number,
			default: 450
		},
		gridWidth: {
			type: String,
			default: "100%"
		},
		totalItems: {
			type: Number,
			default: 0
		},
		columnDefs: {
			type: Array,
			default: () => []
		},
		rowData: {
			type: Array,
			default: () => []
		},
		selectedRows: {
			type: Array,
			default: () => []
		},
		perPage: {
			type: Number,
			default: 20
		},
		initPage: {
			type: Boolean,
			default: false
		},
		selectedCheckOrRadioRows: {
			type: Array,
			default: () => []
		},
		rowClassRules: {
			type: Object,
			default: () => {}
		},
		enableRangeSelection: {
			type: Boolean,
			default: false
		},
		rowSelection: {
			type: String,
			default: "single"
		},
		suppressRowTransform: {
			type: Boolean,
			default: true
		},
		suppressColumnVirtualisation: {
			type: Boolean,
			default: true
		},
		suppressRowClickSelection: {
			type: Boolean,
			default: true
		},
		animateRows: {
			type: Boolean,
			default: true
		},
		frameworkComponents: {
			type: Object,
			default: () => {}
		},
		topBtnList: {
			type: Array,
			default: () => []
		},
		icons: {
			type: Object,
			default: () => {}
		},
		subTitle: {
			type: String,
			default: ""
		},
		totalYn: {
			// 7.14 이지혜 추가 - 가맹점종합관리 첫번째 그리드에 필
			type: Boolean,
			default: true
		},
		pagiYn: {
			// 7.14 이지혜 추가
			type: Boolean,
			default: true
		},
		isRowSelectable: {
			type: Function,
			default: null
		},
		showWholeRow:{ // 12월 10일 추가. 그리드의 모든 row가 화면에 출력되어야 하면 'table'을 받으면 됨.
			type: String,
		},
		bottomRowData: { // 12월 10일 추가. grid bottom data 셋
			type: Array,
			default: () => []
		}
	},
	data() {
		return {
			updateKey: 0,
			pagination: {},

			gridApi: null,
			gridColumnApi: null,

			defaultColDef: null,
			gridOptions: null,
			// modules: [ClientSideRowModelModule, CsvExportModule, InfiniteRowModelModule]
			modules: AllModules
		};
	},
	mounted() {
		this.gridApi = this.gridOptions.api;
		this.gridColumnApi = this.gridOptions.columnApi;
	},
	created() {
		this.gridOptions = {
			onRowDoubleClicked: this.callOnRowDoubleClicked,
			onCellDoubleClicked: this.onCellDoubleClicked,
			headerHeight: 31,
			frameworkComponents: this.frameworkComponents,
			pinnedBottomRowData: this.bottomRowData && this.bottomRowData, // 12월 10일 추가. grid bottom data 셋
			// 12월 11일. pinned row 스타일링. 하단 <style>에서 아래처럼 적용해도 스타링 입혀짐.
			// .ag-floating-bottom{ font-weight: 900; background-color: $grid-selected;}
			// 일단 라이브러리가 제공하는 방식으로 코딩.
			getRowStyle: params => {  
				if(params.node.rowPinned) {
					return {
						'font-weight': 'bold', 
						'background-color': '#ffeedc', 
					}
				}
			},
		};
		this.defaultColDef = { resizable: true, sortable: true, filter: true };

		this.localeText = {
			noRowsToShow: this.i18n("label.noRowsToShow")
		};
	},
	methods: {
		/**
		 * 페이지네이션 초기화
		 */
		onModelUpdated() {
			if (this.initPage) {
				this.pagination.currentPage = 1;
			}
		},
		/**
		 * row 더블 클릭시
		 * @params {Object} params
		 */
		callOnRowDoubleClicked(params) {
			this.$emit("callOnRowDoubleClicked", params);
		},
		/**
		 * cell 더블 클릭시
		 * @params {Object} params
		 */
		onCellDoubleClicked(params) {
			this.$emit("callOnCellDoubleClicked", params);
		},
		/**
		 * 전체 개수 get
		 */
		getTotalItems() {
			this._totalItems = this.totalItems;
		},
		/**
		 * 여러 row 선택시
		 * @params {Object} params
		 */
		onRowSelected(params) {
			this.$emit("rowSelected", params);
		},
		/**
		 * 하나의 row 선택시
		 * @params {Object} params
		 */
		onSelectionChanged(params) {
			this.$emit("selectionChanged", params);
		},
		/**
		 * 원하는 row를 자동으로 선택
		 * @params {number} index
		 */
		fnSetSelectedRow(index) {
			this.gridApi.getRowNode(index).setSelected(true);
		},
		/**
		 * 원하는 row를 자동으로 선택 해제
		 * @params {number} index
		 */
		fnSetDeselectedRow(index) {
			this.gridApi.getRowNode(index).setSelected(false);
		}
	}
};
</script>

<style lang="scss">
@import "../../../node_modules/@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
@import "../../../node_modules/@ag-grid-enterprise/all-modules/dist/styles/ag-theme-material.css";
// @import "../../../node_modules/@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
// @import "../../../node_modules/@ag-grid-enterprise/all-modules/dist/styles/ag-theme-material.css";
@import "@/scss/_color.scss";
@import "@/scss/_input.scss";
.eCellWrapper,
.ag-cell-wrapper {
	justify-content: center;
}
.customHeaderLabel {
	margin-left: 5px;
	margin-top: 3px;
	float: inherit;
}

.ag-header-cell-label {
	justify-content: center;
}

.none-ag-status-bar {
	.ag-status-bar {
		display: none;
	}
}

.row-spaning-cell {
	background: white;
	border-left: 1px solid lightgrey !important;
	border-right: 1px solid lightgrey !important;
	border-bottom: 1px solid lightgrey !important;
}

.ag-pivot-off .ag-header-group-cell,
.ag-pivot-on .ag-header-group-cell,
.ag-cell-label-container {
	height: 31px;
}

//material
.ag-theme-material {
	.ag-status-bar {
		padding: 0;
	}
	.ag-root,
	.ag-header {
		color: $c6;
		font-weight: 400;
		border-color: $grid-border;
		background-color: $cf;
	}

	.ag-header {
		* {
			// 9월 17일 추가. 헤더 병합을 했더니 중간에 선이 회색이 나와서 수정함.
			border-color: $grid-border;
		}
		&-cell,
		&-group-cell {
			background-color: $grid-bg;
			border-right: solid 1px $grid-border;
		}

		&-cell::after,
		&-group-cell::after {
			content: none;
		}

		&-cell-text {
			line-height: 32px;
		}
		//hover
		&-group-cell:not(.ag-column-resizing) + .ag-header-group-cell:hover,
		&-group-cell:not(.ag-column-resizing)
			+ .ag-header-group-cell.ag-column-resizing,
		&-cell:not(.ag-column-resizing) + .ag-header-cell:hover,
		&-cell:not(.ag-column-resizing) + .ag-header-cell.ag-column-resizing,
		&-group-cell:first-of-type:hover,
		&-group-cell:first-of-type.ag-column-resizing,
		&-cell:first-of-type:hover,
		&-cell:first-of-type.ag-column-resizing {
			background-color: $grid-bg;
		}
		&-group-text {
			// 9월 17일 추가. 그리드 헤더 병합했을 시 text-align: center
			width: 100%;
			text-align: center;
		}
	} //ag-header

	// 8월 6일 이지혜 추가
	// cell 선택 -> 체크박스 체크 혹은 체크박스 해제 시
	// 해당 cell에만 컬러링 되는 부분 수정.
	.ag-cell-range-selected-1:not(.ag-cell-focus) {
		background-color: transparent !important;
	}
	.ag-cell-range-selected:not(.ag-cell-focus) {
		background-color: transparent !important;
	}

	// 8월 7일. 클릭시 '>' 제거 요청이 있어서 아래의 코드들을 주석 처리 함.
	// .selected-row,// 더블클릭
	// .ag-row-selected {
	//   background-color: $selected;
	//   .selected-cell:not(.ag-cell-range-selected),//더블클릭
	// 	.ag-cell-range-left:not(.ag-cell-range-selected) {
	//     position: relative;
	//     font-size: 0;
	//     &::before,
	//     &::after {
	//       content: "";
	//       position: absolute;
	//       display: inline-block;
	//       top: calc(50% - 5px);
	//       left: calc(50% - -1px);
	//       width: 1px;
	//       height: 7px;
	//       background-color: $c6;
	//       transform: rotate(-45deg);
	//     }
	//     &::after {
	//       top: calc(50% - 1px);
	//       transform: rotate(45deg);
	//     }
	//   }
	// }

	// .ag-row {
	//   border-color: $grid-border;

	//   &-selected {
	//     background-color: $ced;
	//     .ag-numeric-cell {
	//       position: relative;
	//       font-size: 0;
	//       &::before,
	//       &::after {
	//         content: "";
	//         position: absolute;
	//         display: inline-block;
	//         top: calc(50% - 5px);
	//         left: calc(50% - -1px);
	//         width: 1px;
	//         height: 7px;
	//         background-color: $c6;
	//         transform: rotate(-45deg);
	//       }
	//       &::after {
	//         top: calc(50% - 1px);
	//         transform: rotate(45deg);
	//       }
	//     }
	//   } //selected

	//   &-hover,
	//   &focus {
	//     background-color: $hover;
	//   }
	// }

	// 8월 7일 추가 코드: row 호버시, selected시 색상 변경.
	.ag-row {
		border-color: $grid-border;
		// .selected-row,
		&-selected {
			background-color: $grid-selected;
		}
		&-hover,
		&focus {
			background-color: $grid-hover;
		}
	}

	.ag-ltr {
		.ag-cell {
			padding: 8px 10px;
			line-height: 16px;
			border-right: solid 1px $grid-border;
		}
		.ag-cell:not(.ag-cell-focus).ag-cell-last-left-pinned:not(.ag-cell-range-right),
		.ag-cell.ag-cell-range-selected:not(.ag-cell-range-single-cell).ag-cell-last-left-pinned:not(.ag-cell-range-right),
		.ag-root:not(.ag-has-focus)
			.ag-cell.ag-cell-last-left-pinned:not(.ag-cell-range-right) {
			border-right: solid 1px $grid-border;
		}
	}
	.ag-status-bar {
		border-color: transparent;
	}

	.ag-selection-checkbox {
		line-height: normal;
	}
	.ag-header-icon {
		position: relative;
	}

	.ag-numeric-header .ag-header-cell-label .ag-header-icon {
		width: 4px; // 순번 옆에 생기는 arrow
	}

	.ag-icon {
		font-size: 15px;
		color: $c6;

		&-asc,
		&-desc {
			font-size: 0 !important;
			content: "";
			display: block;
			position: absolute;
			top: calc(50% - 5px);
			box-sizing: border-box;
			border-style: solid;
			border-color: $c4 transparent transparent;
			border-width: 8px 5px;
		}
		&-asc {
			top: calc(50% - 12px);
			border-color: transparent transparent $c4;
		}
	} //ag-icon

	//checkboxAll
	.ag-checkbox {
		.ag-icon-checkbox {
			&-indeterminate,
			&-unchecked,
			&-checked {
				@include chk-box;
				@include chk-border;
				&::before {
					font-size: 0;
				}
			}
			&-checked {
				&::before {
					@include chk-checked;
				}
			}
		}
	}
	//checkbox
	.grid-select-box {
		.ag-selection-checkbox {
			.ag-icon-checkbox {
				&-unchecked,
				&-checked {
					@include chk-box;
					@include chk-border;
					&::before {
						font-size: 0;
					}
				}
				&-checked {
					&::before {
						@include chk-checked;
					}
				}
			}
		}
	}

	//radio
	.grid-radio {
		.ag-selection-checkbox {
			.ag-icon-checkbox {
				&-unchecked,
				&-checked {
					@include rdo-box;
					&::before {
						font-size: 0;
					}
				}
				&-checked {
					&::before {
						@include rdo-checked;
					}
				}
			}
		}
	}
} //ag-theme-material

.status-bar {
	border-top-width: 0px;
}

.header-cell,
.ag-header-group-cell {
	background-color: $grid-bg;
	// transition: background-color 0.5s;
}
.ag-theme-material .ag-header-cell,
.ag-theme-material .ag-header-group-cell {
	padding: 0 11px;
}
.ag-theme-material .ag-header-cell-text {
	padding-left: 14px;
}

.ag-root-wrapper-body {
	border: 1px solid $grid-border;
	&.ag-layout-normal {
		height: 100%;
	}
}

.grid-wrap {
	position: relative;
	.total {
		position: absolute;
		bottom: 10px;
	}
	.grid-box {
		transition: auto 0.4s;
	}
}
</style>
