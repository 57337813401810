/**
 * 1. 개요 : 공통 컴포넌트
 * 2. 처리내용 : 공통 컴포넌트 - PerfectScrollbar
 * @File Name : PerfectScrollbar.vue
 * @date : 2020. 02. 20. 09:46:00
 * @author : 이상철
 * @history :
 *-----------------------------------------------------------------------
 * 변경일			          		  작성자		변경내용
 *-----------------------------------------------------------------------
 * 2020. 2. 20. 09:46:00	    이상철	  최초 작성
 *-----------------------------------------------------------------------
 */
<template>
  <vue-perfect-scrollbar 
    :settings="_settings"
    v-bind="$attrs"
    v-on="$listeners"
    @ps-scroll-y="onScrollY"
  >
    <slot />
  </vue-perfect-scrollbar>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar';

const defaultSettings = {
  minScrollbarLength: 30,
  suppressScrollX: false,
  suppressScrollY: false,
};

export default {
  name: 'Scrollbar',
  components: {
    VuePerfectScrollbar,
  },
  props: {
    suppressScrollX: {
      type: Boolean,
      default: true,
    },
    suppressScrollY: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    /**
     * scrollbar 셋
     * @return {Object}
     */
    _settings() {
      const {
        suppressScrollX,
        suppressScrollY,
      } = this;
      
      return {
        ...defaultSettings,
        suppressScrollX,
        suppressScrollY,
      };
    },
  },
  methods: {
    onScrollY($event) {
      this.$emit('scrollY', $event, $event.target.scrollTop);
    },
  },
};
</script>

<style lang="scss">
.ps__scrollbar-x-rail,
.ps__scrollbar-y-rail {
  background-color: transparent !important;
  opacity: 1 !important;
}
</style>
