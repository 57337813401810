/**
 * Created by PanJiaChen on 16/11/18.
 */
import moment from "moment";

/**
 * Parse the time to string
 * @param {(Object|string|number)} time
 * @param {string} cFormat
 * @returns {string}
 */
export function parseTime(time, cFormat) {
  if (arguments.length === 0) {
    return null;
  }
  const format = cFormat || "{y}-{m}-{d} {h}:{i}:{s}";
  let date;
  if (typeof time === "object") {
    date = time;
  } else {
    if (typeof time === "string" && /^[0-9]+$/.test(time)) {
      time = parseInt(time);
    }
    if (typeof time === "number" && time.toString().length === 10) {
      time = time * 1000;
    }
    date = new Date(time);
  }
  const formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay()
  };
  const time_str = format.replace(/{(y|m|d|h|i|s|a)+}/g, (result, key) => {
    let value = formatObj[key];
    // Note: getDay() returns 0 on Sunday
    if (key === "a") {
      return ["日", "一", "二", "三", "四", "五", "六"][value];
    }
    if (result.length > 0 && value < 10) {
      value = "0" + value;
    }
    return value || 0;
  });
  return time_str;
}

/**
 * @param {number} time
 * @param {string} option
 * @returns {string}
 */
export function formatTime(time, option) {
  if (("" + time).length === 10) {
    time = parseInt(time) * 1000;
  } else {
    time = +time;
  }
  const d = new Date(time);
  const now = Date.now();

  const diff = (now - d) / 1000;

  if (diff < 30) {
    return "刚刚";
  } else if (diff < 3600) {
    // less 1 hour
    return Math.ceil(diff / 60) + "分钟前";
  } else if (diff < 3600 * 24) {
    return Math.ceil(diff / 3600) + "小时前";
  } else if (diff < 3600 * 24 * 2) {
    return "1天前";
  }
  if (option) {
    return parseTime(time, option);
  } else {
    return (
      d.getMonth() +
      1 +
      "月" +
      d.getDate() +
      "日" +
      d.getHours() +
      "时" +
      d.getMinutes() +
      "分"
    );
  }
}

/**
 * @param {string} url
 * @returns {Object}
 */
export function getQueryObject(url) {
  url = url == null ? window.location.href : url;
  const search = url.substring(url.lastIndexOf("?") + 1);
  const obj = {};
  const reg = /([^?&=]+)=([^?&=]*)/g;
  search.replace(reg, (rs, $1, $2) => {
    const name = decodeURIComponent($1);
    let val = decodeURIComponent($2);
    val = String(val);
    obj[name] = val;
    return rs;
  });
  return obj;
}

/**
 * @param {string} input value
 * @returns {number} output value
 */
export function byteLength(str) {
  // returns the byte length of an utf8 string
  let s = str.length;
  for (var i = str.length - 1; i >= 0; i--) {
    const code = str.charCodeAt(i);
    if (code > 0x7f && code <= 0x7ff) s++;
    else if (code > 0x7ff && code <= 0xffff) s += 2;
    if (code >= 0xdc00 && code <= 0xdfff) i--;
  }
  return s;
}

/**
 * @param {Array} actual
 * @returns {Array}
 */
export function cleanArray(actual) {
  const newArray = [];
  for (let i = 0; i < actual.length; i++) {
    if (actual[i]) {
      newArray.push(actual[i]);
    }
  }
  return newArray;
}

/**
 * @param {Object} json
 * @returns {Array}
 */
export function param(json) {
  if (!json) return "";
  return cleanArray(
    Object.keys(json).map(key => {
      if (json[key] === undefined) return "";
      return encodeURIComponent(key) + "=" + encodeURIComponent(json[key]);
    })
  ).join("&");
}

/**
 * @param {string} url
 * @returns {Object}
 */
export function param2Obj(url) {
  const search = url.split("?")[1];
  if (!search) {
    return {};
  }
  return JSON.parse(
    '{"' +
      decodeURIComponent(search)
        .replace(/"/g, '\\"')
        .replace(/&/g, '","')
        .replace(/=/g, '":"')
        .replace(/\+/g, " ") +
      '"}'
  );
}

/**
 * @param {string} val
 * @returns {string}
 */
export function html2Text(val) {
  const div = document.createElement("div");
  div.innerHTML = val;
  return div.textContent || div.innerText;
}

/**
 * Merges two objects, giving the last one precedence
 * @param {Object} target
 * @param {(Object|Array)} source
 * @returns {Object}
 */
export function objectMerge(target, source) {
  if (typeof target !== "object") {
    target = {};
  }
  if (Array.isArray(source)) {
    return source.slice();
  }
  Object.keys(source).forEach(property => {
    const sourceProperty = source[property];
    if (typeof sourceProperty === "object") {
      target[property] = objectMerge(target[property], sourceProperty);
    } else {
      target[property] = sourceProperty;
    }
  });
  return target;
}

/**
 * @param {HTMLElement} element
 * @param {string} className
 */
export function toggleClass(element, className) {
  if (!element || !className) {
    return;
  }
  let classString = element.className;
  const nameIndex = classString.indexOf(className);
  if (nameIndex === -1) {
    classString += "" + className;
  } else {
    classString =
      classString.substr(0, nameIndex) +
      classString.substr(nameIndex + className.length);
  }
  element.className = classString;
}

/**
 * @param {string} type
 * @returns {Date}
 */
export function getTime(type) {
  if (type === "start") {
    return new Date().getTime() - 3600 * 1000 * 24 * 90;
  } else {
    return new Date(new Date().toDateString());
  }
}

/**
 * @param {Function} func
 * @param {number} wait
 * @param {boolean} immediate
 * @return {*}
 */
export function debounce(func, wait, immediate) {
  let timeout, args, context, timestamp, result;

  const later = function() {
    // 据上一次触发时间间隔
    const last = +new Date() - timestamp;

    // 上次被包装函数被调用时间间隔 last 小于设定时间间隔 wait
    if (last < wait && last > 0) {
      timeout = setTimeout(later, wait - last);
    } else {
      timeout = null;
      // 如果设定为immediate===true，因为开始边界已经调用过了此处无需调用
      if (!immediate) {
        result = func.apply(context, args);
        if (!timeout) context = args = null;
      }
    }
  };

  return function(...args) {
    context = this;
    timestamp = +new Date();
    const callNow = immediate && !timeout;
    // 如果延时不存在，重新设定延时
    if (!timeout) timeout = setTimeout(later, wait);
    if (callNow) {
      result = func.apply(context, args);
      context = args = null;
    }

    return result;
  };
}

/**
 * This is just a simple version of deep copy
 * Has a lot of edge cases bug
 * If you want to use a perfect deep copy, use lodash's _.cloneDeep
 * @param {Object} source
 * @returns {Object}
 */
export function deepClone(source) {
  if (!source && typeof source !== "object") {
    throw new Error("error arguments", "deepClone");
  }
  const targetObj = source.constructor === Array ? [] : {};
  Object.keys(source).forEach(keys => {
    if (source[keys] && typeof source[keys] === "object") {
      targetObj[keys] = deepClone(source[keys]);
    } else {
      targetObj[keys] = source[keys];
    }
  });
  return targetObj;
}

/**
 * @param {Array} arr
 * @returns {Array}
 */
export function uniqueArr(arr) {
  return Array.from(new Set(arr));
}

/**
 * @returns {string}
 */
export function createUniqueString() {
  const timestamp = +new Date() + "";
  const randomNum = parseInt((1 + Math.random()) * 65536) + "";
  return (+(randomNum + timestamp)).toString(32);
}

/**
 * Check if an element has a class
 * @param {HTMLElement} elm
 * @param {string} cls
 * @returns {boolean}
 */
export function hasClass(ele, cls) {
  return !!ele.className.match(new RegExp("(\\s|^)" + cls + "(\\s|$)"));
}

/**
 * Add class to element
 * @param {HTMLElement} elm
 * @param {string} cls
 */
export function addClass(ele, cls) {
  if (!hasClass(ele, cls)) ele.className += " " + cls;
}

/**
 * Remove class from element
 * @param {HTMLElement} elm
 * @param {string} cls
 */
export function removeClass(ele, cls) {
  if (hasClass(ele, cls)) {
    const reg = new RegExp("(\\s|^)" + cls + "(\\s|$)");
    ele.className = ele.className.replace(reg, " ");
  }
}

export function getCurrentTimeYyyyMmDdHh24Mi() {
  return moment().format("YYYYMMDDHHMM");
}

export function minByArr(arr) {
  if (isEmpty(arr)) return false;
  return Math.min(...arr);
}

export function maxByArr(arr) {
  if (isEmpty(arr)) return false;
  return Math.max(...arr);
}

export function sumByArr(arr) {
  if (isEmpty(arr)) return false;
  return arr.reduce((a, b) => a + b);
}

export function isEmpty(value) {
  if (value === null) return true;
  if (typeof value === 'string' && value.trim() === "") return true;
  if (typeof value === "undefined") return true;
  if (typeof value === "string" && value === "") return true;
  if (Array.isArray(value) && value.length < 1) return true;
  if (
    typeof value === "object" &&
    value.constructor.name === "Object" &&
    Object.keys(value).length < 1 &&
    Object.getOwnPropertyNames(value) < 1
  )
    return true;
  if (
    typeof value === "object" &&
    value.constructor.name === "String" &&
    Object.keys(value).length < 1
  )
    return true;
  return false;
}

export function omit(orgObj, newObj) {
  return Object.keys(newObj).reduce((diff, key) => {
    if (orgObj[key] === newObj[key]) return diff;
    return {
      ...diff,
      [key]: newObj[key]
    };
  }, {});
}

/**
 * 객체에서 키를 확인하여 필터링하고 리턴한다
 */
export function filterObjectByKey(obj, predicate) {
  return Object.keys(obj)
    .filter(predicate)
    .reduce((_obj, key) => {
      _obj[key] = obj[key];
      return _obj;
    }, {});
}

/**
 * 문자열에 대한 byte 리턴
 * @param {*} str
 */
export function getByte(str) {
  if (isEmpty(str)) {
    return 0;
  }

  return str
    .split("")
    .map(s => s.charCodeAt(0))
    .reduce((prev, c) => prev + (c === 10 ? 2 : c >> 7 ? 2 : 1), 0);
}

// 입력된 값의 byte 구하기
export function getByteLengthOfUtf8String(s) {
  let i = 0;
  let b = 0;
  let c = s;
  if(s != undefined && s != "") {
    for(b=i=0;c=s.charCodeAt(i++);b+=c>>11?3:c>>7?2:1);
    return b;
  } else {
    return 0;
  }
}

/**
 * 문자열중 숫자만 리턴
 * @param {*} str
 */
export function filteredNum(str) {
  if (isEmpty(str)) {
    return "";
  }

  return str.replace(/\D/g, "");
}

/**
 * 특수문자로 바뀐 html string을 html 로 변환
 * @param {*} str
 */
export function decodeToHtml(str) {
  for(let i = 1; i < 7; i++){
    // h1~h6 여는태그
    const trgStr = `&lt;h${i}&gt;`
    const trgStrRegex = new RegExp(trgStr, 'gi')
    // h1~h6 닫는태그
    const trgStr1 = `&lt;/h${i}&gt;`
    const trgStrRegex1 = new RegExp(trgStr1, 'gi') 
    // h1~h6 right정렬
    const trgStr2 = `&lt;h${i} class="ql-align-right"&gt;`
    const trgStrRegex2 = new RegExp(trgStr2, 'gi')
    // h1~h6 center정렬
    const trgStr3 = `&lt;h${i} class="ql-align-center"&gt;`
    const trgStrRegex3 = new RegExp(trgStr3, 'gi')

    str = str.replace(trgStrRegex, `<h${i}>`) 
          .replace(trgStrRegex1, `</h${i}>`)  
          .replace(trgStrRegex2, `<h${i} class='ql-align-right'>`)
          .replace(trgStrRegex3, `<h${i} class='ql-align-center'>`)
  }
  for(let i = 1; i < 9; i++) {
    // p 태그 들여쓰기
    const trgStr4 = `&lt;p class="ql-indent-${i}"&gt`
    const trgStrRegex4 = new RegExp(trgStr4, 'gi')
    // h1~h6 들여쓰기
    const trgStr5 = `&lt;h1 class="ql-indent-${i}"&gt;`
    const trgStrRegex5 = new RegExp(trgStr5, 'gi')
    const trgStr6 = `&lt;h2 class="ql-indent-${i}"&gt;`
    const trgStrRegex6 = new RegExp(trgStr6, 'gi')
    const trgStr7 = `&lt;h3 class="ql-indent-${i}"&gt;`
    const trgStrRegex7 = new RegExp(trgStr7, 'gi')
    const trgStr8 = `&lt;h4 class="ql-indent-${i}"&gt;`
    const trgStrRegex8 = new RegExp(trgStr8, 'gi')
    const trgStr9 = `&lt;h5 class="ql-indent-${i}"&gt;`
    const trgStrRegex9 = new RegExp(trgStr9, 'gi')
    const trgStr10 = `&lt;h6 class="ql-indent-${i}"&gt;`
    const trgStrRegex10 = new RegExp(trgStr10, 'gi')
    // h1~h6 들여쓰기1~8번 right
    const trgStr11 = `&lt;h1 class="ql-indent-${i} ql-align-right"&gt;`
    const trgStr101 = `&lt;h1 class="ql-align-right ql-indent-${i}"&gt;`
    const trgStrRegex11 = new RegExp(trgStr11, 'gi')
    const trgStrRegex101 = new RegExp(trgStr101, 'gi')

    const trgStr12 = `&lt;h2 class="ql-indent-${i} ql-align-right"&gt;`
    const trgStr102 = `&lt;h2 class="ql-align-right"&gt; ql-indent-${i}`
    const trgStrRegex12 = new RegExp(trgStr12, 'gi')
    const trgStrRegex102 = new RegExp(trgStr102, 'gi')

    const trgStr13 = `&lt;h3 class="ql-indent-${i} ql-align-right"&gt;`
    const trgStr103 = `&lt;h3 class="ql-align-right ql-indent-${i}"&gt;`
    const trgStrRegex13 = new RegExp(trgStr13, 'gi')
    const trgStrRegex103 = new RegExp(trgStr103, 'gi')

    const trgStr14 = `&lt;h4 class="ql-indent-${i} ql-align-right"&gt;`
    const trgStrRegex14 = new RegExp(trgStr14, 'gi')
    const trgStr104 = `&lt;h4 class="ql-align-right ql-indent-${i}"&gt;`
    const trgStrRegex104 = new RegExp(trgStr104, 'gi')
    
    const trgStr15 = `&lt;h5 class="ql-indent-${i} ql-align-right"&gt;`
    const trgStrRegex15 = new RegExp(trgStr15, 'gi')
    const trgStr105 = `&lt;h5 class="ql-align-right ql-indent-${i}"&gt;`
    const trgStrRegex105 = new RegExp(trgStr105, 'gi')

    const trgStr16 = `&lt;h6 class="ql-indent-${i} ql-align-right"&gt;`
    const trgStrRegex16 = new RegExp(trgStr16, 'gi')
    const trgStr106 = `&lt;h6 class="ql-align-right ql-indent-${i}"&gt;`
    const trgStrRegex106 = new RegExp(trgStr106, 'gi')
    // h1~h6 들여쓰기1~8번 center
    const trgStr21 = `&lt;h1 class="ql-indent-${i} ql-align-center"&gt;`
    const trgStrRegex21 = new RegExp(trgStr21, 'gi')
    const trgStr201 = `&lt;h1 class="ql-align-center ql-indent-${i}"&gt;`
    const trgStrRegex201 = new RegExp(trgStr201, 'gi')

    const trgStr22 = `&lt;h2 class="ql-indent-${i} ql-align-center"&gt;`
    const trgStrRegex22 = new RegExp(trgStr22, 'gi')
    const trgStr202 = `&lt;h2 class="ql-align-center ql-indent-${i}"&gt;`
    const trgStrRegex202 = new RegExp(trgStr202, 'gi')

    const trgStr23 = `&lt;h3 class="ql-indent-${i} ql-align-center"&gt;`
    const trgStrRegex23 = new RegExp(trgStr23, 'gi')
    const trgStr203 = `&lt;h3 class="ql-align-center ql-indent-${i}"&gt;`
    const trgStrRegex203 = new RegExp(trgStr203, 'gi')

    const trgStr24 = `&lt;h4 class="ql-indent-${i} ql-align-center"&gt;`
    const trgStrRegex24 = new RegExp(trgStr24, 'gi')
    const trgStr204 = `&lt;h4 class="ql-align-center ql-indent-${i}"&gt;`
    const trgStrRegex204 = new RegExp(trgStr204, 'gi')

    const trgStr25 = `&lt;h5 class="ql-indent-${i} ql-align-center"&gt;`
    const trgStrRegex25 = new RegExp(trgStr25, 'gi')
    const trgStr205 = `&lt;h5 class="ql-align-center ql-indent-${i}"&gt;`
    const trgStrRegex205 = new RegExp(trgStr205, 'gi')

    const trgStr26 = `&lt;h6 class="ql-indent-${i} ql-align-center"&gt;`
    const trgStrRegex26 = new RegExp(trgStr26, 'gi')
    const trgStr206 = `&lt;h6 class="ql-align-center ql-indent-${i}"&gt;`
    const trgStrRegex206 = new RegExp(trgStr206, 'gi')

    str = str
          .replace(trgStrRegex4, `<p class='ql-indent-${i}'>`)
          .replace(trgStrRegex5, `<h1 class='ql-indent-${i}'>`)
          .replace(trgStrRegex6, `<h2 class='ql-indent-${i}'>`)
          .replace(trgStrRegex7, `<h3 class='ql-indent-${i}'>`)
          .replace(trgStrRegex8, `<h4 class='ql-indent-${i}'>`)
          .replace(trgStrRegex9, `<h5 class='ql-indent-${i}'>`)
          .replace(trgStrRegex10, `<h6 class='ql-indent-${i}'>`)

          .replace(trgStrRegex11, `<h1 class='ql-indent-${i} ql-align-right'>`)
          .replace(trgStrRegex101, `<h1 class='ql-indent-${i} ql-align-right'>`)
          .replace(trgStrRegex12, `<h2 class='ql-indent-${i} ql-align-right'>`)
          .replace(trgStrRegex102, `<h2 class='ql-indent-${i} ql-align-right'>`)
          .replace(trgStrRegex13, `<h3 class='ql-indent-${i} ql-align-right'>`)
          .replace(trgStrRegex103, `<h3 class='ql-indent-${i} ql-align-right'>`)
          .replace(trgStrRegex14, `<h4 class='ql-indent-${i} ql-align-right'>`)
          .replace(trgStrRegex104, `<h4 class='ql-indent-${i} ql-align-right'>`)
          .replace(trgStrRegex15, `<h5 class='ql-indent-${i} ql-align-right'>`)
          .replace(trgStrRegex105, `<h5 class='ql-indent-${i} ql-align-right'>`)
          .replace(trgStrRegex16, `<h6 class='ql-indent-${i} ql-align-right'>`)
          .replace(trgStrRegex106, `<h6 class='ql-indent-${i} ql-align-right'>`)
          
          .replace(trgStrRegex21, `<h1 class='ql-indent-${i} ql-align-center'>`)
          .replace(trgStrRegex201, `<h1 class='ql-indent-${i} ql-align-center'>`)
          .replace(trgStrRegex22, `<h2 class='ql-indent-${i} ql-align-center'>`)
          .replace(trgStrRegex202, `<h2 class='ql-indent-${i} ql-align-center'>`)
          .replace(trgStrRegex23, `<h3 class='ql-indent-${i} ql-align-center'>`)
          .replace(trgStrRegex203, `<h3 class='ql-indent-${i} ql-align-center'>`)
          .replace(trgStrRegex24, `<h4 class='ql-indent-${i} ql-align-center'>`)
          .replace(trgStrRegex204, `<h4 class='ql-indent-${i} ql-align-center'>`)
          .replace(trgStrRegex25, `<h5 class='ql-indent-${i} ql-align-center'>`)
          .replace(trgStrRegex205, `<h5 class='ql-indent-${i} ql-align-center'>`)
          .replace(trgStrRegex26, `<h6 class='ql-indent-${i} ql-align-center'>`)
          .replace(trgStrRegex206, `<h6 class='ql-indent-${i} ql-align-center'>`)
  }
  return str
  .replace(/&lt;p&gt;/gi, "<p>")
  .replace(/&lt;\/p&gt;/gi, "</p>")

  // strong 태그
  .replace(/&lt;strong&gt;/gi, "<strong>")
  .replace(/&lt;\/strong&gt;/gi, "</strong>")

  // 언더라인
  .replace(/&lt;u&gt;/gi, "<u>")
  .replace(/&lt;\/u&gt;/gi, "</u>")

  // 스트로크
  .replace(/&lt;s&gt;/gi, "<s>")
  .replace(/&lt;\/s&gt;/gi, "</s>")

  // br
  .replace(/&lt;br&gt;/gi, "<br>")
  
  // img
  .replace(/&lt;img/gi, "<img")
  
  // p 정렬
  .replace(/&lt;p class="ql-align-right"&gt;/gi, "<p class='ql-align-right'>")
  .replace(/&lt;p class='ql-align-right'&gt;/gi, "<p class='ql-align-right'>")
  .replace(/&lt;p class="ql-align-center"&gt;/gi, "<p class='ql-align-center'>")
  .replace(/&lt;p class='ql-align-center'&gt;/gi, "<p class='ql-align-center'>")
  
  // 띄어쓰기
  .replace(/&amp;/gi, "&")
  .replace(/&amp;nbsp;/gi, "　") // 이 빈공간은 ㄱ + 한자키 누르면 나오는 첫번째 공란으로 처리했습니다.
  .replace(/&amp;nbsp; /gi, "　")
  .replace(/ &amp;nbsp;/gi, "　")

  // 그 밖에
  .replace(/&#35;/gi, '#')
  .replace(/&#39;/gi, "'")
}
/**
 * 배열과 키를 넣으면 값에 따라 그루핑한 객체를 리턴해줌.
 * @param {Array} data
 * @param {String} key
 * @return {Object}
 */
export function groupBy(data, key){
  return data.reduce((acc, cur) => {
    const group = cur[key];

    if(acc[group] === undefined) {
      acc[group] = []
    }
    acc[group].push(cur)
    return acc
  },{})
}
