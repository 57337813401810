var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "m-al-wrap",
      class: { "overflow-hidden": _vm.sidebar.opened }
    },
    [
      _c("m-navbar"),
      _c("m-sidebar", { class: _vm.classObj }),
      _vm.sidebar.opened
        ? _c("div", {
            staticClass: "drawer-bg",
            on: { click: _vm.handleClickOutside }
          })
        : _vm._e(),
      _c("m-app-main"),
      _c("m-copyright")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }