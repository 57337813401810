/**
 * 1. 개요 : 마이페이지 민원관리
 * 2. 처리내용 : 마이페이지 민원관리 Mixins - 화면 모델
 * CmnPopupComplaintViewModel.js
 * @date : 2020. 08. 13.  05:25:19
 * @author : 왕수민
 * @history :
 *-----------------------------------------------------------------------
 * 변경일			          		    작성자		변경내용
 *-----------------------------------------------------------------------
 * 2020. 08. 13.  05:25:19	    왕수민	  최초 작성
 *-----------------------------------------------------------------------
 */
import moment from 'moment';
import { lookupValue } from "@/utils/dataGrid";
import { amtFormatter } from "@/utils/formatter";
import { isEmpty } from '@/utils';

export default {
  methods: {
    cmnMyPageFaqViewModel(params = {}) {
      return [
        {
          field: "rnum",
          headerName: "",
          type: Number,
          width: 50,
          isPk: false,
          editable: false,
          pinned: true,
          sortable: true,
          cellStyle: {
            textAlign: 'center'
          },
          cellClassRules: {
            'selected-cell': param => params && param.rowIndex === params.rowIndex
          },
          valueFormatter : (param) => {
            // 오름차순
            // return (((this.currPage - 1) * this.pageUnit) + param.node.rowIndex) + 1;

            // 내림차순
            return this.gridProps.totalItems - (((this.currPage - 1) * this.pageUnit) + param.node.rowIndex);
          },   
        },        
        {
          field: "bbsId",
          headerName: this.i18n('label.bbsId'),
          type: Number,
          width: 0,
          isPk: false,
          editable: false,
          pinned: false,
          suppressMenu: false,
          sortable: true,
          cellStyle: {},
          cellClassRules: {},
          hide: true,          
        },
        {
          field: "bbsTypCd",
          headerName: this.i18n('label.bbsTypCd'),
          type: String,
          width: 0,
          isPk: false,
          editable: false,
          pinned: false,
          suppressMenu: false,
          sortable: true,
          cellStyle: {},
          cellClassRules: {},
          hide: true,          
        },
        {
          field: "bbsTypId",
          headerName: this.i18n('label.bbsTypId'),
          type: Number,
          width: 0,
          isPk: false,
          editable: false,
          pinned: false,
          suppressMenu: false,
          sortable: true,
          cellStyle: {},
          cellClassRules: {},
          hide: true,
        },
        {
          field: "parBbsId",
          headerName: this.i18n('label.parBbsId'),
          type: Number,
          width: 0,
          isPk: false,
          editable: false,
          pinned: false,
          suppressMenu: false,
          sortable: true,
          cellStyle: {},
          cellClassRules: {},
          hide: true,
        },
        {
          field: "title",
          headerName: this.i18n('label.faqTitle'),
          type: String,
          width: 400,
          isPk: false,
          editable: false,
          pinned: false,
          suppressMenu: false,
          sortable: true,
          cellStyle: {
            textAlign: 'left'
          },
          cellClassRules: {},         
        },
        {
          field: "file_info",
          headerName: this.i18n('label.atchFile'),
          type: String,
          width: 150,
          isPk: false,
          editable: false,
          pinned: false,
          suppressMenu: false,
          sortable: true,
          cellStyle: {
            textAlign: 'center'
          },
          cellClassRules: {},         
          cellRenderer: "FileDownRenderer",
        },
        {
          field: "contents",
          headerName: this.i18n('label.contents'),
          type: String,
          width: 0,
          isPk: false,
          editable: false,
          pinned: false,
          suppressMenu: false,
          sortable: true,
          cellStyle: {},
          cellClassRules: {},
          hide: true,
        },        
        {
          field: "dispStaDt",
          headerName: this.i18n('label.dispStaDt'),
          type: String,
          width: 0,
          isPk: false,
          editable: false,
          pinned: false,
          suppressMenu: false,
          sortable: true,
          cellStyle: {
            textAlign: 'center'
          },
          cellClassRules: {},
          valueFormatter : (params) => {
            if( params && !isEmpty(params.value) ) {
              return this.i18nDtFormat(params.value);
            }else{
              return "";
            }
          },   
          hide: true,
        },
        {
          field: "dispEndDt",
          headerName: this.i18n('label.dispEndDt'),
          type: String,
          width: 0,
          isPk: false,
          editable: false,
          pinned: false,
          suppressMenu: false,
          sortable: true,
          cellStyle: {
            textAlign: 'center'
          },
          cellClassRules: {},
          valueFormatter : (params) => {
            if( params && !isEmpty(params.value) ) {
              return this.i18nDtFormat(params.value);
            }else{
              return "";
            }
          },
          hide: true,  
        },
        {
          field: "acptDtm",
          headerName: this.i18n('label.addDate'),
          type: String,
          width: 140,
          isPk: false,
          editable: false,
          pinned: false,
          suppressMenu: false,
          sortable: true,
          cellStyle: {
            textAlign: 'center'
          },
          cellClassRules: {},
          valueFormatter : (params) => {
            if( params && !isEmpty(params.value) ) {
              return this.i18nDtFormat(params.value);
            }else{
              return "";
            }
          },   
        },
        {
          field: "regUserEmail",
          headerName: this.i18n('label.regUserEmail'),
          type: String,
          width: 0,
          isPk: false,
          editable: false,
          pinned: false,
          suppressMenu: false,
          sortable: true,
          cellStyle: {},
          cellClassRules: {},
          hide: true,
        },
        {
          field: "regUserPwd",
          headerName: this.i18n('label.regUserPwd'),
          type: String,
          width: 0,
          isPk: false,
          editable: false,
          pinned: false,
          suppressMenu: false,
          sortable: true,
          cellStyle: {},
          cellClassRules: {},
          hide: true,
        },
        {
          field: "acptUserId",
          headerName: this.i18n('label.acptUserId'),
          type: String,
          width: 0,
          isPk: false,
          editable: false,
          pinned: false,
          suppressMenu: false,
          sortable: true,
          cellStyle: {},
          cellClassRules: {},
          hide: true,
        },
        {
          field: "hndlUserId",
          headerName: this.i18n('label.hndlUserId'),
          type: String,
          width: 0,
          isPk: false,
          editable: false,
          pinned: false,
          suppressMenu: false,
          sortable: true,
          cellStyle: {},
          cellClassRules: {},
          hide: true,
        },
        {
          field: "restCell",
          headerName: "",
          width: 0,
          type: String,
          isPk: false,
          editable: false,
          pinned: false,
          suppressMenu: false,
          sortable: false,
          cellStyle: {},
          cellClassRules: {},          
        }
      ];
    }
  }
};
