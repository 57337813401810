/**
 * 1. 개요 : 코드가져오기
 * 2. 처리내용 : Mixins - 코드 fetch helper
 * @File Name : CodeFetchHelper.js
 * @date : 2020. 05. 07. 09:46:00
 * @author : 김경식
 * @history :
 *-----------------------------------------------------------------------
 * 변경일			          		  작성자		변경내용
 *-----------------------------------------------------------------------
 * 2020. 5. 07. 09:46:00	    김경식	  최초 작성
 *-----------------------------------------------------------------------
 */
import { isEmpty } from '@/utils'

export default {
  data() {
    return {
      cdList: {
        bbsStCdList : [],
        jobPosCdList : [],
        psnStatCdList : [],
        enthCdList : [],
        natCdList : [],
        grpTypCdList : [],
        grpMenuAuthorCdList : [],
        gndList : [],
        pidKndCdList : [],
        bizClCdList: []
      }
    }
  },
  created() {
    this.fnGetAxcCodeGrpList([
      "BBS_ST_CD",
      "JOB_POS_CD",
      "PSN_STAT_CD",
      "ENTH_CD",
      "NAT_CD",
      "GRP_TYP_CD",
      "GRP_MENU_AUTHOR_CD",
      "GND",
      "PID_KND_CD",
      "BIZ_CL_CD"
    ]);
  },
  methods: {
    /**
     * 업무 코드 리스트 페치
     * @params {Array} searchCodeGrpIdList
     */
		fnGetAxcCodeGrpList(searchCodeGrpIdList = []) {
      if(isEmpty(searchCodeGrpIdList)) return;

			this.$httpPost(
					"/api/bep/getAxcCodeGroupListByCodeGrpIdList.do",
					{ searchCodeGrpIdList },
					{ showLoading: false }
				)
				.then(response => {
          this.cdList.bbsStCdList = response.data.resultList.find(a => a[0].codeGrpId === "BBS_ST_CD");

          this.cdList.jobPosCdList = response.data.resultList.find(a => a[0].codeGrpId === "JOB_POS_CD");
          this.cdList.psnStatCdList = response.data.resultList.find(a => a[0].codeGrpId === "PSN_STAT_CD");
          this.cdList.enthCdList = response.data.resultList.find(a => a[0].codeGrpId === "ENTH_CD");
          this.cdList.natCdList = response.data.resultList.find(a => a[0].codeGrpId === "NAT_CD");
          this.cdList.grpTypCdList = response.data.resultList.find(a => a[0].codeGrpId === "GRP_TYP_CD").filter(a => a.codeId != "06");
          this.cdList.grpMenuAuthorCdList = this.getCommonCodeList("GRP_MENU_AUTHOR_CD");
          this.cdList.gndList = response.data.resultList.find(a => a[0].codeGrpId === "GND");
          this.cdList.pidKndCdList = response.data.resultList.find(a => a[0].codeGrpId === "PID_KND_CD");
          this.cdList.bizClCdList = response.data.resultList.find(a => a[0].codeGrpId === "BIZ_CL_CD");
        })
        .catch(this.commonErrorHandler)
    },
  }
}