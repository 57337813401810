export default [
  {
    name: "CpnCstPmtInq",
    path: "/biz/bep/cpn/CpnCstPmtInq",
    component: () => import("@/views/biz/bep/cpn/CpnCstPmtInq"),
    meta: { layout: "admin" }
  },

  {
    name: "SaleTotAggMng",
    path: "/biz/bep/agg/SaleTotAggMng",
    component: () => import("@/views/biz/bep/agg/SaleTotAggMng"),
    meta: { layout: "admin" }
  },

  {
    name: "MerSalesMng",
    path: "/biz/bep/agg/MerSalesMng",
    component: () => import("@/views/biz/bep/agg/MerSalesMng"),
    meta: { layout: "admin" }
  },

  {
    name: "MerSalesInqMng",
    path: "/biz/bep/agg/MerSalesInqMng",
    component: () => import("@/views/biz/bep/agg/MerSalesInqMng"),
    meta: { layout: "admin" }
  },

]