/**
 * 1. 개요 : 서비스여부값 세팅 팝업
 * 2. 처리내용 : 서비스여부값 세팅 팝업 - 팝업창 컴포넌트
 * @File Name : TrmIfcMngSvcYnValPopup.vue
 * @date : 2020. 03. 31.  06:24:15
 * @author : 왕수민
 * @history :
 *-----------------------------------------------------------------------
 * 변경일			          		    작성자		변경내용
 *-----------------------------------------------------------------------
 * 2020. 03. 31.  06:24:15	    왕수민	  최초 작성
 *-----------------------------------------------------------------------
 */
<template>
	<popup :title="title" @close="$emit('close', {refresh: false})">
		<data-table tblId="tblInfo001" id="dynamicTable">
			<template slot="tableInfo">
				{{ 'label.sale' | i18n }}, {{ 'label.void' | i18n }}, {{ 'label.refund' | i18n }}, {{ 'label.balance' | i18n }}, {{ 'label.preAuth' | i18n }}, {{ 'label.adjust' | i18n }}
				, {{ 'label.motoSale' | i18n }}, {{ 'label.offlineSale' | i18n }}, {{ 'label.cashAdvance' | i18n }}, {{ 'label.changePin' | i18n }}
				&nbsp;
				{{ 'label.rgt' | i18n }} or
				{{ 'label.change' | i18n }}
			</template>
			<template slot="caption">
				{{ title }}
			</template>
			<template slot="colgroup">
				<col style="width:150px" />
				<col style="width:auto" />
				<col style="width:150px" />
				<col style="width:auto" />
			</template>
			<template slot="tbody">
				<tr v-for="item in itemPacketList" :key="item.codeName1">
					<th scope="row">
						{{ item.codeName1 }}
					</th>
					<td>
						<check-box
								:checked="item.status1 == true"
								@change="putOrChange(item, 1, $event)"
							/>
					</td>
					<th scope="row">
						{{ item.codeName2 }}
					</th>
					<td>
						<check-box
								v-if="item.codeName2 != ''"
								:checked="item.status2 == true"
								@change="putOrChange(item, 2, $event)"
							/>
					</td>
				</tr>
			</template>
		</data-table>
		<template slot="buttons">
			<primary-button @click="validate">{{buttonTitle}}</primary-button>
			<default-button @click="$emit('close', {refresh: false})">{{ 'button.cancel' | i18n }}</default-button>
		</template>
	</popup>
</template>

<script>
import DataTable from "@/components/DataTable";
import Popup from "@/components/Popup";
import {CheckBox } from "@/components/Inputs";
import { isEmpty } from "@/utils";

import { GlobalValidationSet } from "./mixins/Helper"

import {  Helper } from "./mixins";

export default {
	name: "TrmIfcMngSmplPymtLstPopup",
	components: {
		DataTable,
		Popup,
		CheckBox
	},
	mixins: [Helper],
	props: {
		cdList: {
			type: Object,
			default: () => {}
		},
		inheritedStckStatList: {
			type: Array,
			default: () => []
		}
	},

	data() {
		return {
			resultList: [],
			itemPacketList: [],
			stckStatList: []
		};
	},
	computed: {
		/**
		 * 제목 리턴 함수
		 * @return {String}
		*/
		title() {
			return this.i18n("label.stcok");
		},
		/**
		 * 버튼 제목 리턴 함수
		 * @return {String}
		*/
		buttonTitle(){
			return this.i18n("label.reg");
		}
	},
	created() {
		/**
		 * 1. "Props" 속성을 통해, Payment List를 넘겨받도록 세팅한다
		 * 2. 최초 [Search] 버튼 클릭하여, 진입 시에는 "[]" 상태로 진입된다.
		 * 3. 하지만, 1회라도 해당 버튼을 눌러 간편결제를 위한 Payment사를 선택한 경우에는 해당 List도 넘기도록 한다.
		 * 4. 해당 List가 넘어가는 즉시, 상위 컴포넌트의 List에는 해당 List가 Set된다.
		 * 5. 이후 [Search] 버튼을 한 번 더 클릭하는 이벤트 발생 시, 팝업이 세팅되기 전에 해당 List의 값을 통해 기존값을 유지하도록 세팅한다. => 어려울 듯
		 * 
		 */
		this.initList();

		this.make2PacketList();
	},
	mounted() {
		
	},
	methods: {
		changeOfflineSale(){
			this.ex.offlineSale = ! this.ex.offlineSale
			if(this.ex.offlineSale === true ){
				this.alert({
					type: "warning",
					title: this.i18n("label.confirm"),
					message: "Vietin 전용 가맹점인 경우에만 허용된 서비스 입니다.",
				});
				return;
			}
		},
		/**
		 * 간편결제사 목록 초기화
		 */
		initList() {
			this.stckStatList = this.cdList.stckStatCdList
													.filter(a => a.codeId != '02')
													.map(a => ({
															...a,
															status: false
													}));
			this.setInheritance();
		},
		/**
		 * 부모 팝업창으로부터 받은 List 처리
		 */
		setInheritance() {
			const inheritance = this.inheritedStckStatList;
			const length = inheritance.length;

			if (length < 1) {
				return;
			}
			
			for (var i=0; i<this.stckStatList.length; i++) {
				this.stckStatList.forEach(a => {				
					if (a.codeId == inheritance[i]) {
						a.status = !a.status;
					}
				})

				// let tmp = this.stckStatList.find(a => a.codeId == inheritance[i].codeId);
				// tmp.status = inheritance[i].status;
			}
		},
		/**
		 * (1row - 2column) 식의 View를 위한 (1obj - 2contents) 처리
		 */
		make2PacketList() {
			const list = this.stckStatList;

			var length = list.length;
			var row = parseInt(length/2);
			var mod = length%2;

			/* 2 Packet List 세팅 */
			let itemPacket;
			for (var i=0; i<row; i++) {
				itemPacket = {
					codeGrpId1: "",
					codeId1: "",
					codeName1: "",
					sortOrder1: "",
					useYn1: "",
					status1: false,

					codeGrpId2: "",
					codeId2: "",
					codeName2: "",
					sortOrder2: "",
					useYn2: "",
					status2: false,
				};

				var tmp;
				
				tmp = list[(2*i)];
				itemPacket.codeGrpId1 = tmp.codeGrpId;
				itemPacket.codeId1 = tmp.codeId;
				itemPacket.codeName1 = tmp.codeName;
				itemPacket.sortOrder1 = tmp.sortOrder;
				itemPacket.useYn1 = tmp.useYn;
				itemPacket.status1 = tmp.status;

				tmp = list[(2*i)+1];
				itemPacket.codeGrpId2 = tmp.codeGrpId;
				itemPacket.codeId2 = tmp.codeId;
				itemPacket.codeName2 = tmp.codeName;
				itemPacket.sortOrder2 = tmp.sortOrder;
				itemPacket.useYn2 = tmp.useYn;
				itemPacket.status2 = tmp.status;

				this.itemPacketList[i] = itemPacket;
			}

			/* 홀수개 일 시 */
			if (mod == 1) {
				itemPacket = {
					codeGrpId1: "",
					codeId1: "",
					codeName1: "",
					sortOrder1: "",
					useYn1: "",
					status1: false,

					codeGrpId2: "",
					codeId2: "",
					codeName2: "",
					sortOrder2: "",
					useYn2: "",
					status2: false,
				};

				const tmpLen = this.itemPacketList.length;

				var tmp = list[length-1];
				itemPacket.codeGrpId1 = tmp.codeGrpId;
				itemPacket.codeId1 = tmp.codeId;
				itemPacket.codeName1 = tmp.codeName;
				itemPacket.sortOrder1 = tmp.sortOrder;
				itemPacket.useYn1 = tmp.useYn;
				itemPacket.status1 = tmp.status;
				
				this.itemPacketList[tmpLen] = itemPacket;
			}
		},
		putOrChange(item, idx, event) {
			let obj = this.stckStatList.find(a => a.codeId == (idx == 1 ? item.codeId1 : item.codeId2));
			
			// if (obj == null) {
			// 	var tmp = {
			// 		codeGrpId: "",
			// 		codeId: "",
			// 		codeName: "",
			// 		sortOrder: "",
			// 		useYn: "",
			// 		status: false
			// 	}

			// 	tmp.codeGrpId = (idx == 1 ? item.codeGrpId1 : item.codeGrpId2);
			// 	tmp.codeId = (idx == 1 ? item.codeId1 : item.codeId2);
			// 	tmp.codeName = (idx == 1 ? item.codeName1 : item.codeName2);
			// 	tmp.sortOrder = (idx == 1 ? item.sortOrder1 : item.sortOrder2);
			// 	tmp.useYn = (idx == 1 ? item.useYn1 : item.useYn2);
			// 	tmp.status = event.target.checked;

			// 	this.stckStatList[this.stckStatList.length] = tmp;
			// } else {
			// 	obj.status = !obj.status;
			// }

			obj.status = !obj.status;

		},
		/**
     * 현재 메뉴에 대한 권한 정보 겟
     * @return {Object}
     */
		// getAuthor() {
    //   return this.$store.getters['Options/userInfo'].menuAuthDetailList.find(a => a.menuNm === this.$route.name)
    // },
		validate() {
			const returnStckStatList = this.stckStatList
																.filter(a => a.status == true)
																;
			
			const length = returnStckStatList.length;

			let preview = "";
			
			if (length > 0) {
				preview = returnStckStatList[0].codeName;
	
				if (length > 1) {
					preview += " 외 #{count}개".replace("#{count}", length-1);
				}
			} 

			this.$emit("fnStckStatPrv", preview)
			this.$emit("fnStckStatLst", returnStckStatList.map(a => a.codeId));

			this.$emit("close")
		}
	}
};
</script>

<style lang="scss" scoped>
@import "@/scss/_button.scss";
	.del-btn{
	background-color: $delBtn !important;
	}
</style>


