/**
 * 1. 개요 : AccessDeniedAlert
 * 2. 처리내용 : AccessDeniedAlert - 스토어
 * @File Name : AccessDeniedAlert.js
 * @date : 2020. 02. 20. 09:46:00
 * @author : 이상철
 * @history :
 *-----------------------------------------------------------------------
 * 변경일			          		  작성자		변경내용
 *-----------------------------------------------------------------------
 * 2020. 2. 20. 09:46:00	    이상철	  최초 작성
 *-----------------------------------------------------------------------
 */
export default {
  state: {
    isAccessDeniedAlert: false
  },
  namespaced: true,
  mutations: {
    SET_ACCESS_DENIED_ALERT_TRUE(state, param) {
      state.isAccessDeniedAlert = true;
    },
    SET_ACCESS_DENIED_ALERT_FALSE(state, param) {
      state.isAccessDeniedAlert = false;
    }
  },
  actions: {
    setAccessDeniedAlertTrue({ commit }, param) {
      commit("SET_ACCESS_DENIED_ALERT_TRUE", param);
    },
    setAccessDeniedAlertFalse({ commit }, param) {
      commit("SET_ACCESS_DENIED_ALERT_FALSE", param);
    }
  },
  getters: {
    getIsAccessDeniedAlert: state => state.isAccessDeniedAlert
  }
};
