/**
 * 1. 개요 : Inputs 공통 컴포넌트
 * 2. 처리내용 : Inputs 공통 컴포넌트 - InputMonth
 * @File Name : index.vue
 * @date : 2020. 02. 20. 09:46:00
 * @author : 이상철
 * @history :
 *-----------------------------------------------------------------------
 * 변경일			          		  작성자		변경내용
 *-----------------------------------------------------------------------
 * 2020. 2. 20. 09:46:00	    이상철	  최초 작성
 *-----------------------------------------------------------------------
 */
<template>
  <div class="input-date">
    <date-picker
      :key="updateKey"
      v-model="date"
      v-bind="$attrs"
      value-type="YYYYMM"
      :type="type"
      :lang="lang"
      :editable="false"
      :format="format"
      :clearable="false"
      @change="value => $emit('apply', value)"
      v-on="$listeners"
      append-to-body
    />
  </div>
</template>

<script>
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import moment from "moment";

export default {
  name: "InputTime",

  components: {
    DatePicker
  },

  model: {
    prop: "value",
    event: "change"
  },

  props: {
    value: {
      type: String,
      default: ""
    },

    type: {
      type: String,
      default: "month"
    }
  },

  data() {
    return {
      updateKey: 0,
      date: moment(new Date(this.value)).format("YYYYMM"),
      kr: {
        days: ["일", "월", "화", "수", "목", "금", "토"],
        months: [
          "1월",
          "2월",
          "3월",
          "4월",
          "5월",
          "6월",
          "7월",
          "8월",
          "9월",
          "10월",
          "11월",
          "12월"
        ],
        pickers: ["다음 7일", "다음 30일", "이전 7일", "이전 30일"],
        placeholder: {
          date: "시간 선택",
          dateRange: "기간 선택"
        }
      }
    };
  },

  computed: {
    lang() {
      if (this.msgLangCd === "en_US" || this.msgLangCd === "vi_VN") return "en";
      return this.kr;
    },
    format() {
      return (() => {
        switch (this.msgLangCd) {
          case "en_US":
            return "MM/YYYY";
          case "vi_VN":
            return "MM-YYYY";
          case "ko_KR":
            return "YYYY.MM";
          default:
            return "MM/YYYY";
        }
      })();
    }
  },

  watch: {
    value(value) {
      console.log("update date value");
      // IE에서 최초생성 후 model변경을 통한 UI갱싱 문제, 키 갱신을 통한 강제 처리 중
      this.refresh(value);
    }
  },

  mounted() {
    this.refresh();
  },

  methods: {
    refresh(value) {
      // this.date = moment(value, this.format).format(this.format);
      this.date = moment(new Date(this.value)).format("YYYYMM");
      this.updateKey = this.updateKey + 1;
    }
  }
};
</script>

<style lang="scss" scoped></style>
