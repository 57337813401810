/**
 * 1. 개요 : i18n
 * 2. 처리내용 : i18n - 스토어
 * @File Name : i18n.js
 * @date : 2020. 02. 20. 09:46:00
 * @author : 이상철
 * @history :
 *-----------------------------------------------------------------------
 * 변경일			          		  작성자		변경내용
 *-----------------------------------------------------------------------
 * 2020. 2. 20. 09:46:00	    이상철	  최초 작성
 *-----------------------------------------------------------------------
 */
export default {
  state: {
    messageList: []
  },
  namespaced: true,
  mutations: {
    SET_MESSAGE_LIST(state, messageList) {
      state.messageList = [...messageList];
    }
  },
  actions: {
    setMessageList({ commit }, param) {
      commit("SET_MESSAGE_LIST", param);
    }
  },
  getters: {
    getMessageById: state => messageId =>
      state.messageList.find(item => item.msgId === messageId),
    findMessage: state => message =>
      state.messageList.filter(item => {
        if ((item.resKoText || "").indexOf(message) > -1) return true;
        if ((item.resEnText || "").indexOf(message) > -1) return true;
        return false;
      })
  }
};
