/** * 1. 개요 : 프로젝트 * 2. 처리내용 : 프로젝트 - 최초 진입점 * @File Name :
App.vue * @date : 2020. 02. 20. 09:46:00 * @author : 이상철 * @history :
*----------------------------------------------------------------------- *
변경일 작성자 변경내용
*----------------------------------------------------------------------- * 2020.
2. 20. 09:46:00 이상철 최초 작성
*----------------------------------------------------------------------- */
<template>
  <!-- 개발 끝난 후, 우클릭 차단 코드 활성화 -->
  <!-- @contextmenu.prevent="preventRightMouse"  -->

  <!-- @keyup.44를 쓰면 chrome에서는 정상작동되며 IE에서는 다른키를 누른뒤 print키를 눌러야 인식됨 -->
  <div id="app" ref="app" :class="classes">
    <!-- 워터마크 -->
    <div :class="{ 'water-mark': printKey === true }" />
    <!-- 프린트스크린이 된 이미지 -->
    <img id="watermark" src style="display:none;" />

    <loading v-if="isCommonCodeFetching" />
    <template v-else>
      <component :is="`${layout}-layout`">
        <router-view :key="$route.fullPath" />
      </component>
    </template>
    <portal-target name="popup" multiple />
    <Dialog />
    <loading v-if="isGlobalLoading" />
  </div>
</template>

<script>
import initialMixin from "@/mixins/initial";
import watermarkMixin from "@/mixins/watermark";

export default {
  name: "App",
  mixins: [initialMixin, watermarkMixin],
  watch: {
    getConnDevice(val, oldVal) {
      if (val !== oldVal) {
        this.$nextTick(() => this.getClasses(val));
        val === "D" && this.$store.dispatch("TagsView/delAllViews");
      }
    },
    isCommonCodeFail(val, oldVal) {
      if (val) {
        this.logout();
      }
    }
  },
  data() {
    return {
      classes: []
    };
  },
  created() {
    // window.addEventListener("beforeunload", this.beforeunload);
    document.onkeydown = this.onkeydown;
  },
  mounted() {
    this.getClasses(this.getConnDevice); // this.getConnDevice는 global computed.js에 정의 되어있음.
    document.onkeyup = this.findKeyCode;

    this.browserCheck();
  },
  computed: {
    layout() {
      return this.$route.meta.layout ? this.$route.meta.layout : "empty";
    }
  },
  methods: {
    /**
     * 디바이스 체크
     * @params {String} device
     */
    getClasses(device) {
      let agentCheck = "pc";

      if (device === "D") {
        agentCheck = "pc";
      } else if (device === "M") {
        agentCheck = "mobile";
      }

      this.classes = [agentCheck];
    },
    onkeydown(e) {
      if (e.keyCode == 116 || (e.ctrlKey == true && e.keyCode == 82)) {
        e.cancelBubble = true;
        e.returnValue = false;
        sessionStorage.setItem("reloaded", "Y");
        setTimeout(function() {
          window.location.reload();
        }, 500);
        return false;
      }
    },
    beforeunload(e) {
      console.log("**************");
      console.log("**************");
      console.log("**************");

      if (
        sessionStorage.getItem("reloaded") != "Y" ||
        sessionStorage.getItem("reloaded") == null
      ) {
        console.log("**************");
        console.log("**************");
        console.log("**************");
        console.log("**************");
        this.$httpPost("/api/com/logout.do", {}, { showLoading: false }).catch(
          this.commonErrorHandler
        );
        sessionStorage.setItem("reloaded-alert", "Y");
      } else {
        sessionStorage.removeItem("reloaded");
      }
      e.preventDefault();
    }
  }
};
</script>

<style lang="scss">
@import "@/scss/reset";
#app {
  overflow: auto;
}
</style>
