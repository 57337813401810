/**
 * 1. 개요 : 공통 컴포넌트
 * 2. 처리내용 : 공통 컴포넌트 - Hamburger2
 * @File Name : index.vue
 * @date : 2020. 02. 20. 09:46:00
 * @author : 이상철
 * @history :
 *-----------------------------------------------------------------------
 * 변경일			          		  작성자		변경내용
 *-----------------------------------------------------------------------
 * 2020. 2. 20. 09:46:00	    이상철	  최초 작성
 *-----------------------------------------------------------------------
 */
<template>
  <div>
    <span @click="quickMenuClick">{{ 'button.showQuickMenu' | i18n }} </span>
    <span @click="listMenuClick" class="menu-list">{{ 'button.showMenuList' | i18n }}</span>
    <!-- <a href="javascript:;" @click="quickMenuClick">{{ 'button.showQuickMenu' | i18n }} </a>
    <a href="javascript:;" @click="listMenuClick" class="menu-list">{{ 'button.showMenuList' | i18n }}</a> -->
  </div>
</template>

<script>
export default {
  methods: {
    /**
     * 사이드 바에 있는 퀵메뉴 보기 버튼 클릭시
     */
    quickMenuClick(){
      this.$emit("quickMenuClick", true)
    },
    /**
     * 사이드 바에 있는 리스트 메뉴 보기 버튼 클릭시
     */
    listMenuClick() {
      this.$emit("listMenuClick", true)
    }
  }
}
</script>

<style lang="scss" scoped>
.sidebar-btn-box{
  > span{ // 원래 a태그였는데 span 태그로 바꿈. 8월 31일 이지혜.
    cursor: pointer; // span 태그가 되면서 추가.
    display: block;
    width:17px;
    height:56px;
    font-size: 0;
    background:url('~@/assets/img/sp-pc.png') no-repeat 0 -50px;
    &.menu-list{
      height:57px;
      border-top:solid 1px #fff;
      background-position-x:-50px; 
    }
  }
}
</style>