/**
 * 1. 개요 : 코드가져오기
 * 2. 처리내용 : Mixins - 코드 fetch helper
 * @File Name : CodeFetchHelper.js
* @date : 2022. 11. 14.  04:40:11
 * @author : 김준석
 * @history :
 *-----------------------------------------------------------------------
 * 변경일			          		    작성자		변경내용
 *-----------------------------------------------------------------------
 * 2022. 11. 14.  04:40:11	    김준석	  최초 작성
 *-----------------------------------------------------------------------
 */
import { isEmpty } from "@/utils";

export default {
  data() {
    return {
      cdList: {
        currencyCdList: []
      }
    };
  },
  created() {
    this.fnGetAxcCodeGrpList([
      "CURRENCY_CD"
    ]);
  },
  methods: {
    /**
     * 업무 코드 리스트 페치
     * @params {Array} searchCodeGrpIdList
     */
    fnGetAxcCodeGrpList(searchCodeGrpIdList = []) {
      if (isEmpty(searchCodeGrpIdList)) return;

      this.$httpPost(
        "/api/bep/getAxcCodeGroupListByCodeGrpIdList.do",
        { searchCodeGrpIdList },
        { showLoading: false }
      )
        .then(response => {
          this.cdList.currencyCdList = response.data.resultList.find(
            a => a[0].codeGrpId === "CURRENCY_CD"
          );
        })
        .catch(this.commonErrorHandler);
    }
  }
};