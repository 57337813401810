/**
 * 1. 개요 : 사업자검색
 * 2. 처리내용 : 사업자검색 Mixins - 화면 모델
 * @File Name : CmnBizPopupViewModel.js
 * @date : 2020. 04. 13. 13:00:00
 * @author : 김영노
 * @history :
 *-----------------------------------------------------------------------
 * 변경일			          		  작성자		변경내용
 *-----------------------------------------------------------------------
 * 2020. 04. 13. 13:00:00	    김영노	  최초 작성
 *-----------------------------------------------------------------------
 */

import { dateTimeFormatter } from "@/utils/formatter";

export default {
  methods: {
    cmnBizPopupViewModel(params = {}) {
      return [
        {
          field: "rnum",
          headerName: "",
          type: Number,
          width: 50,
          isPk: false,
          editable: false,
          pinned: false,
          cellStyle: {
            textAlign: "center"
          },
          cellClassRules: {
            "selected-cell": param =>
              params && param.rowIndex === params.rowIndex
          },
          valueFormatter : (param) => {
            // 내림차순
            return this.gridProps.totalItems - (((this.currPage - 1) * this.pageUnit) + param.node.rowIndex);
          }, 
        },
        {
          field: "bizNo",
          headerName: this.i18n("label.bizNo"),
          type: String,
          width: 180,
          isPk: false,
          editable: false,
          pinned: false,
          suppressMenu: false,
          sortable: true,
          cellStyle: {},
          cellClassRules: {}
        },
        {
          field: "bizNm",
          headerName: this.i18n("label.bizNm"),
          type: String,
          width: 180,
          isPk: false,
          editable: false,
          pinned: false,
          suppressMenu: false,
          sortable: true,
          cellStyle: {},
          cellClassRules: {}
        },
        {
          field: "bizEnm",
          headerName: this.i18n("label.bizEnm"),
          type: String,
          width: 180,
          isPk: false,
          editable: false,
          pinned: false,
          suppressMenu: false,
          sortable: true,
          cellStyle: {},
          cellClassRules: {}
        },
        {
          field: "representativeNm",
          headerName: this.i18n("label.representativeNm"),
          type: String,
          width: 180,
          isPk: false,
          editable: false,
          pinned: false,
          suppressMenu: false,
          sortable: true,
          cellStyle: {},
          cellClassRules: {}
        },
        {
          field: "ctAddrNm",
          headerName: this.i18n("label.city"),
          type: String,
          width: 180,
          isPk: false,
          editable: false,
          pinned: false,
          suppressMenu: false,
          sortable: true,
          cellStyle: {},
          cellClassRules: {}
        },
        {
          field: "dsAddrNm",
          headerName: this.i18n("label.dist"),
          type: String,
          width: 180,
          isPk: false,
          editable: false,
          pinned: false,
          suppressMenu: false,
          sortable: true,
          cellStyle: {},
          cellClassRules: {}
        },

        {
          field: "wdAddrNm",
          headerName: this.i18n("label.ward"),
          type: String,
          width: 180,
          isPk: false,
          editable: false,
          pinned: false,
          suppressMenu: false,
          sortable: true,
          cellStyle: {},
          cellClassRules: {}
        },
        {
          field: "instDeptNm",
          headerName: this.i18n("label.instNm"),
          type: String,
          width: 180,
          isPk: false,
          editable: false,
          pinned: false,
          suppressMenu: false,
          sortable: true,
          cellStyle: {},
          cellClassRules: {}
        },
        {
          field: "addedDate",
          headerName: this.i18n("label.addedDate"),
          type: String,
          width: 140,
          isPk: false,
          editable: false,
          pinned: false,
          suppressMenu: false,
          sortable: true,
          cellStyle: {
            textAlign: "center"
          },
          cellClassRules: {}
        }
      ];
    }
  }
};
