 /**
 * 1. 개요 : 가맹점 정보 검색
 * 2. 처리내용 : 가맹점 정보 검색 Mixins - 화면 모델
 * File Name : CmnMyPagePopupViewModel.js
 * date : 2020. 05. 20. 16:15:00
 * author : 이지혜
 * history :
 *-----------------------------------------------------------------------
 * 변경일			          		  작성자		변경내용
 *-----------------------------------------------------------------------
 * 2020. 05. 20. 16:15:00	    이지혜	  최초 작성
 *-----------------------------------------------------------------------
 */
 
// import { lookupValue } from "@/utils/dataGrid";

export default {
  methods: {
    cmnMyPagePopupViewModel(params = {}) {
      return [
        {
          field: "rnum",
          headerName: "",
          type: Number,
          width: 50,
          isPk: false,
          editable: false,
          pinned: true,
          sortable: true,
          cellStyle: {
            textAlign: 'center'
          },
          cellClassRules: {
            'selected-cell': param => params && param.rowIndex === params.rowIndex
          },
          valueFormatter: param => {
            // 오름차순
            // return (
            //   (this.currPage - 1) * this.pageUnit + param.node.rowIndex + 1
            // );

            // 내림차순
            return (
              this.gridProps.totalItems -
              ((this.currPage - 1) * this.pageUnit + param.node.rowIndex)
            );
          }
        },
        // {
        //   checkboxSelection: true,
        //   headerCheckboxSelection: true,
        //   headerCheckboxSelectionFilteredOnly: true,
        //   width: 35,
        //   suppressMenu: true,
        //   sortable: false,
        //   pinned: true,
        //   cellClassRules: {
        //     'grid-select-box': param => true, // 셀렉트박스일 경우
        //     'grid-radio': param => false // 라디오일 경우
        //   }
        // },
        // {
        //   checkboxSelection: true,
        //   width: 35,
        //   suppressMenu: true,
        //   sortable: false,
        //   pinned: true,
        //   cellClassRules: {
        //     'grid-select-box': param => false, // 셀렉트박스일 경우
        //     'grid-radio': param => true // 라디오일 경우
        //   }
        // },
        {
          field: "merId",
          headerName: this.i18n("label.merId"),
          type: Number,
          width: 0,
          isPk: true,
          editable: false,
          pinned: true,
          suppressMenu: false,
          sortable: true,
          cellStyle: {},
          cellClassRules: {},
        },
        {
          field: "bizNo",
          headerName: this.i18n("label.bizNo"),
          type: Number,
          width: 0,
          isPk: true,
          editable: false,
          pinned: true,
          suppressMenu: false,
          sortable: true,
          cellStyle: {},
          cellClassRules: {},
          
        },
        {
          field: "merNm",
          headerName: this.i18n("label.merNm"),
          type: String,
          width: 0,
          isPk: false,
          editable: false,
          pinned: false,
          suppressMenu: false,
          sortable: true,
          cellStyle: {},
          cellClassRules: {},
          
        },
        {
          field: "merEnm",
          headerName: this.i18n("label.enm"),
          type: String,
          width: 0,
          isPk: false,
          editable: false,
          pinned: false,
          suppressMenu: false,
          sortable: true,
          cellStyle: {},
          cellClassRules: {},
          
        },
        {
          field: "merTelNo",
          headerName: this.i18n("label.telNo"),
          type: String,
          width: 0,
          isPk: false,
          editable: false,
          pinned: false,
          suppressMenu: false,
          sortable: true,
          cellStyle: {},
          cellClassRules: {},
          
        },
        {
          field: "merPno",
          headerName: this.i18n("label.pno"),
          type: String,
          width: 0,
          isPk: false,
          editable: false,
          pinned: false,
          suppressMenu: false,
          sortable: true,
          cellStyle: {},
          cellClassRules: {},
          
        },
        {
          field: "ctAddrNm",
          headerName: this.i18n("label.city"),
          type: String,
          width: 0,
          isPk: false,
          editable: false,
          pinned: false,
          suppressMenu: false,
          sortable: true,
          cellStyle: {},
          cellClassRules: {},
          
        },
        {
          field: "dsAddrNm",
          headerName: this.i18n("label.dist"),
          type: String,
          width: 0,
          isPk: false,
          editable: false,
          pinned: false,
          suppressMenu: false,
          sortable: true,
          cellStyle: {},
          cellClassRules: {},
          
        },
        {
          field: "wdAddrNm",
          headerName: this.i18n("label.ward"),
          type: String,
          width: 0,
          isPk: false,
          editable: false,
          pinned: false,
          suppressMenu: false,
          sortable: true,
          cellStyle: {},
          cellClassRules: {},
          
        },
        {
          field: "merAddr",
          headerName: this.i18n("label.addr"),
          type: String,
          width: 0,
          isPk: false,
          editable: false,
          pinned: false,
          suppressMenu: false,
          sortable: true,
          cellStyle: {},
          cellClassRules: {},
          
        },
        {
          field: "deptCd",
          headerName: this.i18n("label.deptCd"),
          type: String,
          width: 140,
          isPk: false,
          editable: false,
          pinned: false,
          suppressMenu: false,
          sortable: true,
          cellStyle: {
            textAlign: "center"
          },
          cellClassRules: {}
        },              
        {
          field: "addedDate",
          headerName: this.i18n("label.addedDate"),
          type: String,
          width: 0,
          isPk: false,
          editable: false,
          pinned: false,
          suppressMenu: false,
          sortable: true,
          cellStyle: {},
          cellClassRules: {},
          
        },
      ];
    }
  }
};
