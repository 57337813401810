/**
 * 1. 개요 : Inputs 공통 컴포넌트
 * 2. 처리내용 : Inputs 공통 컴포넌트 - InputTextEditor
 * @File Name : index.vue
 * @date : 2020. 02. 20. 09:46:00
 * @author : 이상철
 * @history :
 *-----------------------------------------------------------------------
 * 변경일			          		  작성자		변경내용
 *-----------------------------------------------------------------------
 * 2020. 2. 20. 09:46:00	    이상철	  최초 작성
 *-----------------------------------------------------------------------
 */
<template>
  <quill-editor 
    class="quillEditor"
    ref="myQuillEditor"
    :style="{minHeight:height}"
    v-model="contents"
    :options="editorOption"
    @change="onEditorChange"
    @ready="onEditorReady($event)"
  />
</template>

<script>
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';
import 'quill/dist/quill.bubble.css';
import { quillEditor } from 'vue-quill-editor';

export default {
  name: "InputTextEditor",
  components: {
    quillEditor
  },
  data(){
    return {
      contents: '',
      editorOption: {
        theme: 'snow',
        boundary: document.body,
        modules: {
          toolbar: [
            ['bold', 'underline', 'strike'], // 12월 23일. 이탤릭체는 적용이 안돼서 삭제.
            [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
            [{ 'indent': '-1' },
             { 'indent': '+1' },
             { 'align': ['', 'right', 'center'] }], 
            ['image'],

            // 라이브러리에서 제공하는 모든 툴바
            // ['bold', 'italic', 'underline', 'strike'],
            // ['blockquote', 'code-block'],
            // [{ 'header': 1 }, { 'header': 2 }],
            // [{ 'list': 'ordered' }, { 'list': 'bullet' }],
            // [{ 'script': 'sub' }, { 'script': 'super' }],
            // [{ 'indent': '-1' }, { 'indent': '+1' }],
            // [{ 'direction': 'rtl' }],
            // [{ 'size': ['small', false, 'large', 'huge'] }],
            // [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
            // [{ 'color': [] }, { 'background': [] }],
            // [{ 'font': [] }],
            // [{ 'align': [] }],
            // ['clean'],
            // ['link', 'image', 'video']
          ]
        },
        placeholder: '',
        readOnly: false
      }
    }
  },
  props: {
    height: {
      type: String,
      default: "300px"
    },
    editable: {
      type: Boolean,
      default: true
    }
  },
  methods:{
    // 텍스트 에디터 change
    onEditorChange({quill, html, text}){
      this.contents = html;

      // 12월 21일 추가. 고과장님이 작업하시는 화면 중에 text editor를 n개 for문으로 만드는 곳이 있어서
      // 어디서 보낸 contents인지 클래스로 구분하기 위해 추가함.
      const editorClass = quill.container.parentElement.classList[2];
      
      this.$emit("getHtmlFromEditor", this.contents, editorClass)
    },
    // 텍스트 에디터 클리어
    delText(){
      this.contents = '';
    },
    // 텍스트 에디터 셋
    setText(params) {
      this.contents = params;
    },
    // 텍스트 에디터 editable 여부
    onEditorReady(quill){
      this.editable === false ? quill.enable(false) : quill.enable(true)
    },
  }
}
</script>
<style lang="scss">
.ql-container {
  height: calc(100% - 43px);
}
.quill-editor{
  height: 100%
}
</style>