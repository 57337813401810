/**
 * 1. 개요 : 개인관리
 * 2. 처리내용 : 개인관리 Mixins - 화면 모델
 * psnMngSaveViewModel.js
 * @date : 2020. 04. 06.  02:46:42
 * @author : 고영호
 * @history :
 *-----------------------------------------------------------------------
 * 변경일			          		    작성자		변경내용
 *-----------------------------------------------------------------------
 * 2020. 04. 06.  02:46:42	    고영호	  최초 작성
 *-----------------------------------------------------------------------
 */
import { lookupValue } from "@/utils/dataGrid";
export default {
  methods: {
    userMngViewModel(params = {}) {
      return [
        {
          field: "rnum",
          headerName: "",
          type: Number,
          width: 50,
          isPk: false,
          editable: false,
          pinned: true,
          sortable: true,
          cellStyle: {
            textAlign: 'center'
          },
          cellClassRules: {
            "selected-cell": param => params && param.rowIndex === params.rowIndex
          },
          valueFormatter: param => {
            // 내림차순
            return this.gridProps.totalItems - ((this.currPage - 1) * this.pageUnit + param.node.rowIndex);
          }
        },
        {
          field: "regGrpTypCd",
          headerName: this.i18n("label.grpTyp"),
          type: String,
          width: 180,
          isPk: false,
          editable: false,
          pinned: false,
          suppressMenu: false,
          sortable: true,
          cellStyle: {
            textAlign: 'center'
          },
          cellClassRules: {},
          valueFormatter: param => param.value && lookupValue(this.cdList.grpTypCdList, param.value)
        },        
        {
          field: "grpCdNm",
          headerName: this.i18n("label.grpNm"),
          type: String,
          width: 180,
          isPk: false,
          editable: false,
          pinned: false,
          suppressMenu: false,
          sortable: true,
          cellStyle: {
            textAlign: 'center'
          },
          cellClassRules: {},
          
        },
        {
          field: "regUserId",
          headerName: this.i18n("label.userId"), 
          type: String,
          width: 180,
          isPk: false,
          editable: false,
          pinned: false,
          suppressMenu: false,
          sortable: true,
          cellStyle: {
            textAlign: 'center'
          },
          cellClassRules: {},
          
        },
        {
          field: "grpMenuAuthorCd",
          headerName: this.i18n("label.grpMenuAuthor"),
          type: String,
          width: 180,
          isPk: false,
          editable: false,
          pinned: false,
          suppressMenu: false,
          sortable: true,
          cellStyle: {
            textAlign: 'center'
          },
          cellClassRules: {},
          valueFormatter: param => param.value && lookupValue(this.cdList.grpMenuAuthorCdList, param.value)
        },
        {
          field: "addedBy",
          headerName: this.i18n("label.addedBy"),
          type: String,
          width: 180,
          isPk: false,
          editable: false,
          pinned: false,
          suppressMenu: false,
          sortable: true,
          cellStyle: {
            textAlign: "center"
          },
          cellClassRules: {},
        },
        {
          field: "addedDate",
          headerName: this.i18n("label.addedDate"),
          type: String,
          width: 180,
          isPk: false,
          editable: false,
          pinned: false,
          suppressMenu: false,
          sortable: true,
          cellStyle: {
            textAlign: "center"
          },
          cellClassRules: {},
          valueFormatter: param => this.i18nDtTmFormat(param.value)
        },
        {
          field: "restCell",
          headerName: "",
          width: 0,
          type: String,
          isPk: false,
          editable: false,
          pinned: false,
          suppressMenu: false,
          sortable: false,
          cellStyle: {},
          cellClassRules: {}
        }
      ];
    }
  }
};
