/**
 * 1. 개요 : 공통 컴포넌트
 * 2. 처리내용 : 공통 컴포넌트 - PaginationForTable
 * @File Name : index.vue
 * @date : 2020. 02. 20. 09:46:00
 * @author : 이상철
 * @history :
 *-----------------------------------------------------------------------
 * 변경일			          		  작성자		변경내용
 *-----------------------------------------------------------------------
 * 2020. 2. 20. 09:46:00	    이상철	  최초 작성
 *-----------------------------------------------------------------------
 */
<template>
  <div class="pagi-wrap">
    <div class="total" v-if="totalYn">
      {{ "label.totalCnt" | i18n }}: {{ totalItems | numberFormat }}
    </div>
    <div class="pagi">
      <pagination
        v-model="pagination"
        :total-items="totalItems"
        :max-size="perPage"
        :items-per-page="perPage"
        @change="$emit('fnListFromPaginationForTable', pagination.currentPage)"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "PaginationForTable",
  data() {
    return {
      pagination: {},
    }
  },
  props: {
    totalItems: {
      type: Number,
      default: 0
    },
    perPage: {
      type: Number,
      default: 20
    },
    initPage: {
      type: Boolean,
      default: false
    },
    totalYn: {
      type: Boolean,
      default: true
    },
  },
  watch: {
    initPage(value){
      if(value) {
        this.pagination.currentPage = 1;
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.pagi-wrap {
  position: relative;
  .total{
    position:absolute;
    bottom: 10px;
  }
}
</style>