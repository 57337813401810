/**
 * 1. 개요 : Inputs 공통 컴포넌트
 * 2. 처리내용 : Inputs 공통 컴포넌트 - InputPhone
 * @File Name : index.vue
 * @date : 2020. 02. 20. 09:46:00
 * @author : 이상철
 * @history :
 *-----------------------------------------------------------------------
 * 변경일			          		  작성자		변경내용
 *-----------------------------------------------------------------------
 * 2020. 2. 20. 09:46:00	    이상철	  최초 작성
 *-----------------------------------------------------------------------
 */
<template>
  <div class="input-text phone-box">
    <span>
      <input v-model="one"
            :type="type"
            v-bind="$attrs"
            v-on="$listeners"
            :id="id"
            :title="i18n('label.telNmOne')"
            :maxlength="maxlengthOne"
            ref="one"
            @blur="blur"
      >
      <span v-if="showClear"
          class="input-clear"
          title="clear"
          @click="$emit('clearPrefix')"
      />
    </span>
    &nbsp;-&nbsp;
    <span>
      <input v-model="two"
            :type="type"
            v-bind="$attrs"
            v-on="$listeners"
            :id="id + 'Two'"
            :title="i18n('label.telNmTwo')"
            :maxlength="maxlengthTwo"
            ref="two"
            @blur="blur"
      >
      <span v-if="showClear"
          class="input-clear"
          title="clear"
          @click="$emit('clearSuffix')"
      />
    </span>
    &nbsp;-&nbsp;
    <span>
      <input v-model="three"
            :type="type"
            v-bind="$attrs"
            v-on="$listeners"
            :id="id + 'Three'"
            :title="i18n('label.telNmThree')"
            :maxlength="maxlengthThree"
            ref="three"
            @blur="blur"
      >
      <span v-if="showClear"
          class="input-clear"
          title="clear"
          @click="$emit('clearSuffix')"
      />
    </span>
  </div>
</template>
<script>
import { isEmpty } from '@/utils';

export default {
  name: 'InputPhone',
  
  model: {
    prop: "value",
  },

  props: {
    type: {
      type: String,
      default: 'text',
    },
    value: {
      type: String,
      default: ''
    },
    showClear: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
      default: ''
    },
    maxlengthOne: {
      type: Number,
      default: 3
    },
    maxlengthTwo: {
      type: Number,
      default: 4
    },
    maxlengthThree: {
      type: Number,
      default: 4
    },
  },

  data() {
    return {
      one: "",
      two: "",
      three: "",
      model: this.value,
      // maxlengthOne: 3,
      // maxlengthTwo: 4,
      // maxlengthThree: 4,
    };
  },

  mounted() {
    this.slicer();
  },

  watch: {
    value(value) {
      this.model = value;
      this.slicer();
    },
    one(value) {
      this.one = value.replace(/[^0-9]/g,'');
    },
    two(value) {
      this.two = value.replace(/[^0-9]/g,'');
    },
    three(value) {
      this.three = value.replace(/[^0-9]/g,'');
    },
  },

  methods: {
    blur(){ // 12월 3일 추가. 받는 화면에서 "" 빈값 체크해야 함. 정상적으로 들어갔으면 빈값이 들어가면 안됨.
      const returnValue = this.one.padRight(this.maxlengthOne, " ") 
                          + this.two.padRight(this.maxlengthTwo, " ") 
                          + this.three.padRight(this.maxlengthThree, " ")

      const totalLength = !isEmpty(returnValue)&& returnValue.replaceAll(" ", "").length
      if(totalLength === 0) this.$emit('fnChange', "");
      else this.$emit('fnChange', returnValue);
    },
    slicer() {
      this.$nextTick(() => {
        this.$nextTick(() => {// 전화번호 길이는 무조건 10자리 혹은 11자리
          this.one = this.model.slice(0, 3)
          this.two = this.model.slice(3, this.model.length - 4)
          this.three = this.model.slice(-4)
        })
      })
    },
    clear() {
      this.one = ""
      this.two = ""
      this.three = ""
    },
    focus() {
      this.$refs.one && this.$refs.one.focus();
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/scss/_color.scss";
@import "@/scss/_input.scss";
@import "@/scss/_select.scss";
</style>

