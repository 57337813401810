/**
 * 1. 개요 : 사업자검색
 * 2. 처리내용 : 사업자검색 Mixins - 화면 모델
 * @File Name : CmnInstPopupViewModel.js
 * @date : 2020. 04. 13. 13:00:00
 * @author : 김영노
 * @history :
 *-----------------------------------------------------------------------
 * 변경일			          		  작성자		변경내용
 *-----------------------------------------------------------------------
 * 2020. 04. 13. 13:00:00	    김영노	  최초 작성
 *-----------------------------------------------------------------------
 */

import { dateTimeFormatter } from "@/utils/formatter";
import { lookupValue } from "@/utils/dataGrid";
export default {
  methods: {
    cmnInstPopupViewModel(params = {}) {
      return [
        {
          field: "rnum",
          headerName: "",
          type: Number,
          width: 50,
          isPk: false,
          editable: false,
          pinned: false,
          cellStyle: {
            textAlign: "center"
          },
          cellClassRules: {
            "selected-cell": param =>
              params && param.rowIndex === params.rowIndex
          },
          valueFormatter : (param) => {
            // 내림차순
            return this.gridProps.totalItems - (((this.currPage - 1) * this.pageUnit) + param.node.rowIndex);
          }, 
        },
        {
          field: "instCd",
          headerName: this.i18n("label.instCd"),
          type: String,
          width: 150,
          isPk: false,
          editable: false,
          pinned: false,
          suppressMenu: false,
          sortable: true,
          cellStyle: {
            textAlign: "center"
          },
          cellClassRules: {}
        },
        {
          field: "instNm",
          headerName: this.i18n("label.instNm"),
          type: String,
          width: 180,
          isPk: false,
          editable: false,
          pinned: false,
          suppressMenu: false,
          sortable: true,
          cellStyle: {},
          cellClassRules: {}
        },
        {
          field: "instEnm",
          headerName: this.i18n("label.instEnm"),
          type: String,
          width: 180,
          isPk: false,
          editable: false,
          pinned: false,
          suppressMenu: false,
          sortable: true,
          cellStyle: {},
          cellClassRules: {}
        },
         // 운영상태
         { 
          field: "runData",
          headerName: this.i18n("label.runData"),
          type: String,
          width: 100,
          isPk: true,
          editable: false,
          pinned: false,
          suppressMenu: false,
          sortable: true,
          cellStyle: { textAlign: "center" },
          cellClassRules: {},
          valueFormatter: param => param.value && lookupValue(this.cdList.runDataCdList, param.value)     
        }, 
        {
          field: "instStatCd",
          headerName: this.i18n("label.instStatCd"),
          type: String,
          width: 180,
          isPk: false,
          editable: false,
          pinned: false,
          suppressMenu: false,
          sortable: true,
          cellStyle: { textAlign: "center" },
          cellClassRules: {},
          valueFormatter: param => param.value && lookupValue(this.cdList.instStatCdList, param.value)
        },
        {
          field: "instTypCd",
          headerName: this.i18n("label.instTypCd"),
          type: String,
          width: 180,
          isPk: false,
          editable: false,
          pinned: false,
          suppressMenu: false,
          sortable: true,
          cellStyle: { textAlign: "center" },
          cellClassRules: {},
          valueFormatter: param => param.value && lookupValue(this.cdList.instTypCdList, param.value)
        },
        {
          field: "instDeptCd",
          headerName: this.i18n("label.deptCd"),
          type: String,
          width: 180,
          isPk: false,
          editable: false,
          pinned: false,
          suppressMenu: false,
          sortable: true,
          cellStyle: { textAlign: "center" },
          cellClassRules: {}
        },

        {
          field: "bizNo",
          headerName: this.i18n("label.bizNo"),
          type: String,
          width: 180,
          isPk: false,
          editable: false,
          pinned: false,
          suppressMenu: false,
          sortable: true,
          cellStyle: { textAlign: "center" },
          cellClassRules: {}
        },
        {
          field: "bizNm",
          headerName: this.i18n("label.bizNm"),
          type: String,
          width: 180,
          isPk: false,
          editable: false,
          pinned: false,
          suppressMenu: false,
          sortable: true,
          cellStyle: {},
          cellClassRules: {}
        },
        
      ];
    }
  }
};
