var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    _vm.tag,
    { tag: "component" },
    _vm._l(_vm.msg.split("\\n"), function(str, index) {
      return _c("p", { key: index }, [_vm._v(" " + _vm._s(str) + " ")])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }