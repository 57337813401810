/**
 * 1. 개요 : 공통 컴포넌트
 * 2. 처리내용 : 공통 컴포넌트 - Popup
 * @File Name : index.vue
 * @date : 2020. 02. 20. 09:46:00
 * @author : 이상철
 * @history :
 *-----------------------------------------------------------------------
 * 변경일			          		  작성자		변경내용
 *-----------------------------------------------------------------------
 * 2020. 2. 20. 09:46:00	    이상철	  최초 작성
 *-----------------------------------------------------------------------
 */
<template>
  <portal to="popup">
    <transition name="fade">
      <div class="popup-dim">
        <div
          ref="wrap"
          role="dialog"
          class="pop-wrap"
          :class="popSize"
          :style="styles"
        >
          <div class="header">
            <template v-if="title">
              <h1 class="title" :title="title">
                {{ title }}
              </h1>
            </template>
            <template v-else>
              <slot name="header" />
            </template>
            <slot name="header-append" />
          </div>
          <!-- 'my-page-popup':myPagePopup -->
          <div class="body" :class="customPopSizeClass">
            <slot />
          </div>
          <div class="footer">
            <slot name="footer" />
            <slot name="buttons" />
          </div>
          <button type="button" class="close-button" @click="$emit('close')">
            Close
          </button>
        </div>
      </div>
    </transition>
  </portal>
</template>

<script>
export default {
  name: "Popup",
  props: {
    title: {
      type: String,
      default: null
    },
    width: {
      type: String,
      default: ""
    },
    height: {
      type: String,
      default: ""
    },
    popSize: {
      type: String,
      default: ""
    },
    myPagePopup: {
      type: Boolean,
      default: false
    },
    prftLssBasDatMngPopup: {
      type: Boolean,
      default: false
    },
    prftLssMngPopup: {
      type: Boolean,
      default: false
    },
    prftLssPrintArea: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      windowHeight: 0,
      windowWidth: 0
    };
  },
  computed: {
    /**
     * 스타일 속성 셋
     * @return {Object}
     */
    styles() {
      return {
        width: this.width,
        height: this.height
      };
    },
    customPopSizeClass() {
      if(this.myPagePopup) return 'my-page-popup'
      if(this.prftLssBasDatMngPopup) return 'prft-Lss-Bas-Dat-Mng-page-popup'
      if(this.prftLssMngPopup) return 'prft-Lss-Mng-page-popup'
      if(this.prftLssPrintArea) return 'prft-lss-print-area'
    }
    // bodyStyles() {
    //   return {
    //     maxHeight: `${this.windowHeight - 150}px`,
    //     maxWidth: `${this.windowWidth - 100}px`,
    //     overflow: 'auto',
    //   };
    // },
  },
  mounted() {
    this.$nextTick(() => {
      const { wrap } = this.$refs;
      if (wrap) wrap.focus();
    });
  },
  created() {
    this.windowWidth = window.innerWidth;
    this.windowHeight = window.innerHeight;
    this.addWindowEventListeners();
    document.querySelector("body").style.overflow = "hidden"; // 팝업창 열릴 때 overflow:'hidden'
  },
  beforeDestroy() {
    this.removeWindowEventListeners();
    document.querySelector("body").style = ""; // 팝업창을 내릴 때 스타일을 비움.
  },
  methods: {
    /**
     * window resize
     */
    onWindowResize() {
      this.windowWidth = window.innerWidth;
      this.windowHeight = window.innerHeight;
    },
    /**
     * add event
     */
    addWindowEventListeners() {
      window.addEventListener("resize", this.onWindowResize);
    },
    /**
     * event remove
     */
    removeWindowEventListeners() {
      window.removeEventListener("resize", this.onWindowResize);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/scss/_color.scss";
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
  .wrap {
    transition: opacity 0.3s, transform 0.3s;
  }
}
.fade-enter {
  opacity: 0;
  .wrap {
    opacity: 0;
    transform: translateY(20px) scale(0.9);
  }
}
.fade-leave-to {
  opacity: 0;
  .wrap {
    opacity: 0;
    transform: translateY(-20px) scale(0.9);
  }
}

button {
  cursor: pointer !important;
  background: transparent !important;
  appearance: none !important;
  border: none !important;
}

.popup-dim {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1003;
  transition: opacity 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;

  .pop-wrap {
    position: relative;
    width: 850px;
    min-width: 850px;
    min-height: 173px;
    max-height: 900px;
    padding-bottom: 20px;
    background-color: $cf;
    border: 2px solid $primary;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);

    //팝업창 사이즈
    &.size {
      &-grid {
        width: 1274px;
        min-width: 1274px;
      }
      &-page {
        width: 1280px;
        min-width: 1280x;
        min-height: 810px;
      }
      $s0: 0;
      @for $n from 5 to 9 {
        &-#{$n}00 {
          width: #{$n}#{$s0}0px;
          min-width: #{$n}#{$s0}0px;
        }
        &-#{$n}50 {
          width: #{$n}50px;
          min-width: #{$n}50px;
        }
      }
    }

    .header {
      height: 40px;
      margin-bottom: 20px;
      padding: 10px;
      background-color: $primary;

      span {
        color: inherit;
      }
      .title {
        margin: 0;
        font-size: 14px;
        font-weight: 600;
        color: $cf;
        letter-spacing: -0.43px;
        line-height: 20px;

        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }

    .body {
      overflow: hidden;
      overflow-y: auto;
      min-width: 100px;
      max-width: 1274px;
      max-height: 581px;
      padding: 0 30px;
      p {
        margin: 0;
      }
    }

    .footer {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .close-button {
      position: absolute;
      top: 10px;
      right: 10px;
      background: transparent;

      width: 20px;
      height: 20px;
      color: transparent;
      font-size: 0;
      overflow: hidden;

      &::before,
      &::after {
        content: "";
        position: absolute;
        background-color: $cf;
        width: 1px;
        height: 100%;
        top: 0;
        left: 50%;
        transform: rotate(45deg);
      }

      &::after {
        transform: rotate(-45deg);
      }
    }
    &.privacy {
      .header {
        margin-bottom: 70px;
      }
      .body {
        width: 780px;
        max-height: 400px;
        margin: 0 auto;
        padding: 10px;
        background-color: #f2f7fb;
        &::-webkit-scrollbar {
          background-color: #fff !important;
          border: solid 1px #bcd5ed;
          &-thumb {
            border-width: 2px 3px;
          }
        }
      }
      .footer {
        display: block;
        padding: 20px 30px;
      }
    }
    &.opt {
      .header {
        margin-bottom: 10px;
      }
      .body {
        width: 780px;
        max-height: 400px;
        margin: 0 auto;
        padding: 10px;
        background-color: #f2f7fb;
        &::-webkit-scrollbar {
          background-color: #fff !important;
          border: solid 1px #bcd5ed;
          &-thumb {
            border-width: 2px 3px;
          }
        }
      }
      .footer {
        display: block;
        padding: 20px 30px;
      }
    }
  }

  .my-page-popup { // custom-popup-size 1. 수민씨 요청으로 mypage 팝업에만 적용. 이지혜 추가. 8월 20일
    max-height: 800px !important;
  }
  .prft-Lss-Bas-Dat-Mng-page-popup { // custom-popup-size 2. 이현우 차장님 요청으로 손익기초정보관리 등록 팝업 사이즈 적용. 11월 13일
    max-height: 580px !important;
    max-width: 1250px !important;
  }
  .prft-Lss-Mng-page-popup { // custom-popup-size 2. 이현우 차장님 요청으로 손익관리 등록 팝업 사이즈 적용. 11월 13일
    max-height: 700px !important;
    max-width: 1600px !important;
  }
  .prft-lss-print-area{ // 손익관리에서 인쇄되는 팝업 영역을 위해 할당.
    max-height: 700px !important;
    max-width: 1600px !important;
  }
}
</style>
