/**
 * 1. 개요 : TagsView
 * 2. 처리내용 : TagsView - 진입점
 * @File Name : index.vue
 * @date : 2020. 02. 20. 09:46:00
 * @author : 이상철
 * @history :
 *-----------------------------------------------------------------------
 * 변경일			          		  작성자		변경내용
 *-----------------------------------------------------------------------
 * 2020. 2. 20. 09:46:00	    이상철	  최초 작성
 *-----------------------------------------------------------------------
 */
<template>
	<div id="tags-view-container" class="tags-view-container">
		<scroll-pane ref="scrollPane" class="tags-view-wrapper">
			<router-link
				v-for="tag in visitedViews"
				ref="tag"
				:key="tag.name"
				:class="isActive(tag) ? 'active' : ''"
				:to="{ path: tag.path }"
				tag="span"
				class="tags-view-item"
				@click.middle.native="closeSelectedTag(tag)"
				@contextmenu.prevent.native="openMenu(tag, $event)"
				:title="tag.title"
			>
				{{ tag.title }}
				<!-- 20221206 Lam.nt1 Update:  Change main dashboard -->
				<span v-if="tag.title == 'dashboard'" class="home" />
				<span v-if="!tag.meta.affix" class="sp-b i-close" @click.prevent.stop="closeSelectedTag(tag)" />
			</router-link>
		</scroll-pane>
		<!-- CONFIG FOR PCIDSS -->
		<div v-if="this.userAuth.userId != 'pcidss_office' 
						&& this.userAuth.userId != 'pcidss_merchant' 
						&& this.userAuth.userId != 'pcidss_agency' 
						&& this.userAuth.userId != 'pcidss_bank'
						&& this.userAuth.userId != 'pcidss_office1' 
						&& this.userAuth.userId != 'pcidss_merchant1' 
						&& this.userAuth.userId != 'pcidss_agency1' 
						&& this.userAuth.userId != 'pcidss_bank1'">
			<!-- <button type="button" @click="openDashboard(selectedTag)" class="tab-dashboard">Dash Board</button> -->
			<button type="button" @click="closeAllTags(selectedTag)" class="tab-txt-close">Close All</button>
		</div>
	</div>
</template>

<script>
import ScrollPane from "./ScrollPane";
import path from "path";

export default {
	components: { ScrollPane },
	data() {
		return {
			visible: false,
			top: 0,
			left: 0,
			selectedTag: {},
			affixTags: []
		};
	},
	created() {
		this.userAuth = this.$store.getters["Options/userInfo"];
	},
	computed: {
		visitedViews() {
			const visited = this.$store.state.TagsView.visitedViews;
			return visited;
		},
		routes() {
			return this.getMenuList();
		}
	},
	watch: {
		$route() {
			this.addTags();
			this.moveToCurrentTag();
		},
		visible(value) {
			if (value) {
				document.body.addEventListener("click", this.closeMenu);
			} else {
				document.body.removeEventListener("click", this.closeMenu);
			}
		}
	},
	mounted() {
		// CONFIG FOR PCIDSS
		if(this.userAuth.userId != 'pcidss_office' 
			&& this.userAuth.userId != 'pcidss_merchant' 
			&& this.userAuth.userId != 'pcidss_agency' 
			&& this.userAuth.userId != 'pcidss_bank'
			&& this.userAuth.userId != 'pcidss_office1' 
			&& this.userAuth.userId != 'pcidss_merchant1' 
			&& this.userAuth.userId != 'pcidss_agency1' 
			&& this.userAuth.userId != 'pcidss_bank1'){
			this.initTags();
			this.addTags();
		}
	},
	methods: {
		/**
		 * 현재 보고 있는 페이지인지 체크
		 * @params {Object} route
		 * @return {Boolean}
		 */
		isActive(route) {
			return route.path === this.$route.path;
		},
		filterAffixTags(routes, basePath = "/") {
			let tags = [];
			routes.forEach(route => {
				if (route.meta && route.meta.affix) {
					const tagPath = path.resolve(basePath, route.path);
					tags.push({
						fullPath: tagPath,
						path: tagPath,
						name: route.name,
						meta: { ...route.meta }
					});
				}
				if (route.children) {
					const tempTags = this.filterAffixTags(route.children, route.path);
					if (tempTags.length >= 1) {
						tags = [...tags, ...tempTags];
					}
				}
			});
			return tags;
		},
		initTags() {
			// 20221206 Lam.nt1 Update:  Change main dashboard
			const tag = {
				fullPath: "/dashboard",
				path: "/dashboard",
				name: "dashboard",
				meta: { title: "dashboard", layout: "admin", affix: true }
			};

			this.affixTags.push(tag);
			if (tag.name) {
				this.$store.dispatch("TagsView/addVisitedView", tag);
				this.$router.push(tag.path).catch(err => {});
			}

			//home class
			if (tag.meta.title === "dashboard") {
				const home = document.querySelector(".tags-view-item");
				if (home) {
					home.style.minWidth = "50px";
					home.style.fontSize = "0";
				}
			}
		},
		addTags() {
			const { name, id } = this.$route;

			const route =
				name === "dashboard"
					? this.$route
					: this.routes.find(item => item.name === name);

			if (route || name === "dashboard") {
				const tag = {
					fullPath: route.path,
					path: route.path,
					name: route.name,
					meta: { title: route.title, layout: "admin", affix: route.fix }
				};

				if (name) {
					this.$store.dispatch("TagsView/addView", tag);
				}

				if (this.$store.state.TagsView.cachedViews.length > 11) {
					this.closeSelectedTag(tag);
					this.alert({
						type: "warning",
						title: this.i18n("label.error"),
						message: this.i18n("alt.overTags"),
						buttons: [{ label: this.i18n("label.confirm") }]
					});
				}
			}

			return false;
		},
		moveToCurrentTag() {
			const tags = this.$refs.tag;
			this.$nextTick(() => {
				for (const tag of tags) {
					if (tag.to.path === this.$route.path) {
						this.$refs.scrollPane.moveToTarget(tag);
						break;
					}
				}
			});
		},
		refreshSelectedTag(view) {
			this.$store.dispatch("TagsView/delCachedView", view).then(() => {
				const { fullPath } = view;
				this.$nextTick(() => {
					this.$router.replace({
						path: "/redirect" + fullPath
					});
				});
			});
		},
		closeSelectedTag(view) {
			this.$store
				.dispatch("TagsView/delView", view)
				.then(({ visitedViews }) => {
					if (this.isActive(view)) {
						this.toLastView(visitedViews, view);
					}
				});
		},
		closeOthersTags() {
			this.$router.push(this.selectedTag);
			this.$store
				.dispatch("TagsView/delOthersViews", this.selectedTag)
				.then(() => {
					this.moveToCurrentTag();
				});
		},
		closeAllTags(view) {
			this.$store.dispatch("TagsView/delAllViews").then(({ visitedViews }) => {
				if (this.affixTags.some(tag => tag.path === view.path)) {
					return;
				}
				this.toLastView(visitedViews, view);
			});
		},
		toLastView(visitedViews, view) {
			const latestView = visitedViews.slice(-1)[0];
				if (latestView) {
					this.$router.push(latestView);
				} else {
					// now the default is to redirect to the home page if there is no tags-view-view,
					// you can adjust it according to your needs.
					if (view.name === "dashboard") {
						this.$router.replace({ path: view.fullPath });
					} else {
						this.$router.push("/");
					}
				}
		},

		// 20221206 Lam.nt1 Add:  Create button go to the dashboard
		openDashboard(view) {
			this.$router.push("/dashboard");
			return;
		},
		openMenu(tag, e) {
			const menuMinWidth = 105;
			const offsetLeft = this.$el.getBoundingClientRect().left; // container margin left
			const offsetWidth = this.$el.offsetWidth; // container width
			const maxLeft = offsetWidth - menuMinWidth; // left boundary
			const left = e.clientX - offsetLeft + 15; // 15: margin right

			if (left > maxLeft) {
				this.left = maxLeft;
			} else {
				this.left = left;
			}

			this.top = e.clientY;
			this.visible = true;
			this.selectedTag = tag;
		},
		closeMenu() {
			this.visible = false;
		}
	}
};
</script>

<style lang="scss" scoped>
@import "@/scss/_color.scss";
@import "@/scss/_icon.scss";
%tab-txt {
	position: absolute;
	top: 17px;
	font: {
		size: 11px;
		weight: bold;
	}
	color: $c6;
	letter-spacing: -0.5px;
	background-color: $cf;
}
.tags-view-container {
	width: 100%;
	height: 37px;
	background: $cf;
	border-bottom: 1px solid $ce3;
	.tags-view-wrapper {
		padding: 5px 15px 0 17px;
		.tags-view-item {
			display: inline-block;
			position: relative;
			min-width: 100px;
			max-width: 150px;
			height: 32px;
			margin-left: 2px;
			padding: 0 26px 0 16px;
			font-size: 12px;
			color: $c5;
			line-height: 34px;
			letter-spacing: -0.5px;
			border: 1px solid $ce3;
			border-radius: 3px 3px 0 0;
			background: $ce3;
			cursor: pointer;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
			&:first-of-type {
				margin-left: 0;
			}
			&.active {
				background-color: $cf;
				border-bottom-color: $cf;
				.home {
					&::after {
						background-color: $cf;
					}
				}
			}
			.home {
				@include home;
				position: absolute;
				top: calc(50% - 1px);
				left: calc(50% - 6px);
				width: 14px;
				height: 10px;
				&::before {
					top: -10px;
					border-width: 5px 7px;
				}
				&::after {
					background-color: $ce3;
					left: 5px;
					width: 4px;
					height: 6px;
					border-radius: 1px;
				}
			}
		}
	}
	.tab-txt-close {
		@extend %tab-txt;
		right: 2%;
	}
	.tab-dashboard {
		@extend %tab-txt;
		right: 6%;
	}
}
</style>

<style lang="scss">
//reset element css of el-icon-close
.tags-view-wrapper {
	.tags-view-item {
		.i-close {
			position: absolute;
			top: 0;
			right: 0;
			width: 17px;
			height: 17px;
			&:before {
				display: block;
				width: 7px;
				height: 7px;
				margin: 5px auto;
				background-position: -200px 0;
			}
		}
	}
}
</style>
