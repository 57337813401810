/**
 * 1. 개요 : Inputs 공통 컴포넌트
 * 2. 처리내용 : Inputs 공통 컴포넌트 - InputEmail
 * @File Name : index.vue
 * @date : 2020. 02. 20. 09:46:00
 * @author : 이상철
 * @history :
 *-----------------------------------------------------------------------
 * 변경일			          		  작성자		변경내용
 *-----------------------------------------------------------------------
 * 2020. 2. 20. 09:46:00	    이상철	  최초 작성
 *-----------------------------------------------------------------------
 */
<template>
	<div class="input-text mail-box">
		<label>
			<input
				v-model="prefix"
				:type="type"
				v-bind="$attrs"
				v-on="$listeners"
				:id="id"
				ref="prefix"
				placeholder="ID"
				:maxlength="prefixMaxlength"
			/>
			<span v-if="showClear" class="input-clear" title="clear" @click="$emit('clearPrefix')" />
		</label>
		@
		<label v-if="!isDirectInput">
			<input
				v-model="suffix"
				:type="type"
				v-bind="$attrs"
				v-on="$listeners"
				:id="id + 'Subffix'"
				ref="subffix"
				title="Email Address"
				:maxlength="suffixMaxlength"
			/>
			<span v-if="showClear" class="input-clear" title="clear" @click="$emit('clearSuffix')" />
		</label>
		<label class="selector select-box">
			<select v-model="autoSuffix" v-bind="$attrs" v-on="$listeners">
				<!-- <option :value="suffix" :label="isDirectInput ? `::: ${directInput} :::` : suffix" >::: {{ directInput }} :::</option> -->
				<option
					v-for="item in pickSubffixList"
					:key="item.id"
					:value="item.id"
					:label="item.name"
				>{{ item.name }}</option>
			</select>
			<span class="arrow-box" />
		</label>
	</div>
</template>
<script>
import { isEmpty } from "@/utils";
import { validEmail } from "@/utils/validate";

export default {
	name: "InputText",

	props: {
		type: {
			type: String,
			default: "text"
		},
		value: {
			type: String,
			default: ""
		},
		showClear: {
			type: Boolean,
			default: false
		},
		id: {
			type: String,
			default: ""
		},
		prefixMaxlength: {
			type: Number,
			default: null
		},
		suffixMaxlength: {
			type: Number,
			default: null
		}
	},

	mounted() {
		this.pickSubffixList = [
			{
				id: "direct",
				// name: `::: ${this.i18nOff("label.directInput")} :::`
				name: this.isLogin
					? this.i18n("label.directInput")
					: this.i18nOff("label.directInput")
			},
			...this.pickSubffixList
		];
		this.prefix = this.value.split("@")[0];
		this.suffix = this.isDirectInput
			? this.autoSuffix
			: this.value.split("@")[1];
	},

	data() {
		return {
			prefix: "",
			suffix: "",
			autoSuffix: "direct",
			pickSubffixList: [
				{
					id: "gmail.com",
					name: "gmail.com"
				},
				{
					id: "naver.com",
					name: "naver.com"
				},
				{
					id: "daum.net",
					name: "daum.net"
				}
			]
		};
	},

	computed: {
		isDirectInput() {
			return this.autoSuffix !== "direct";
		}
	},

	watch: {
		prefix(value) {
			this.prefix = value;
			// 2. suffix일 경우
			if (!isEmpty(this.suffix))
				this.$emit("fnChange", value + "@" + this.suffix);
			// 3. autoSuffix일 경우('직접 입력' select / 그 밖에)
			else if (this.autoSuffix === "direct")
				this.$emit("fnChange", value + "@");
			// 4. 아이디 수정 중에 suffix 붙이기(수정할 때는 autoSuffix로 입력했더라도 직접 입력으로 불려온다.)
			else if (!isEmpty(this.prefix) && this.autoSuffix === "direct")
				this.$emit("fnChange", value + "@" + this.suffix);
			else this.$emit("fnChange", value + "@" + this.autoSuffix);
		},

		suffix(value) {
			this.suffix = value;
			if (this.suffix === undefined) this.suffix = ""; // 1. 빈값을 넣었을 경우, undefined이 들어가는 문제 해결.
			this.$emit("fnChange", this.prefix + "@" + this.suffix);
		},

		autoSuffix(value) {
			this.autoSuffix = value;
			if (this.autoSuffix === "direct") this.suffix = "";
			this.$emit("fnChange", this.prefix + "@" + this.autoSuffix);
		}
	},

	methods: {
		/**
		 * 초기화 함수
		 */
		clear() {
			this.prefix = "";
			this.suffix = "";
		},
		/**
		 * 포커스 함수
		 */
		focus() {
			this.$refs.prefix && this.$refs.prefix.focus();
		},
		/**
		 * 이메일 포맷 체크 함수
		 * @params {String} params
		 * @return {Boolean}
		 */
		emailFormatCheck(params) {
			// 이 함수 안쓰기로 했음. 9월 30일 쯤 지우자.
			console.log(
				"해당 함수는 9월 30일에 삭제될 예정입니다. Hepler.js에 함수를 추가해서 사용해주세요."
			);
			if (params.length <= 1 && params.includes("@")) {
				this.$emit("fnChange", ""); // 아무 것도 안 썼을 경우, 빈값으로 보낸다.
				return true;
			} else {
				return validEmail(params); // 무언가를 썼을 경우, 이메일 포맷 체크를 한다.
			}
		}
	}
};
</script>

<style lang="scss" scoped>
@import "@/scss/_color.scss";
@import "@/scss/_input.scss";
@import "@/scss/_select.scss";
.mail-box {
	> label {
		display: inline-block;
		width: 170px;
		vertical-align: middle;
	}
	.select-box {
		margin-left: 4px;
	}
}
</style>

