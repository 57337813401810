/**
 * 1. 개요 : 총판정보검색
 * 2. 처리내용 : 총판정보검색 Mixins - 화면 모델
 * @File Name : CmnDistrInfPopupViewModel.js
 * @date : 2020. 04. 13. 13:00:00
 * @author : 이현우
 * @history :
 *-----------------------------------------------------------------------
 * 변경일			          		  작성자		변경내용
 *-----------------------------------------------------------------------
 * 2020. 04. 13. 13:00:00	    이현우	  최초 작성
 *-----------------------------------------------------------------------
 */
import { lookupValue } from "@/utils/dataGrid";

export default {
  methods: {
    cmnDistrInfPopupViewModel(params = {}) {
      return [
        {
          field: "rnum",
          headerName: "",
          type: Number,
          width: 50,
          isPk: false,
          editable: false,
          pinned: false,
          cellStyle: {
            textAlign: "center"
          },
          cellClassRules: {
            "selected-cell": param => params && param.rowIndex === params.rowIndex
          },
          valueFormatter: param => {
            // 오름차순
            // return (
            //   (this.currPage - 1) * this.pageUnit + param.node.rowIndex + 1
            // );

            // 내림차순
            return this.gridProps.totalItems - ((this.currPage - 1) * this.pageUnit + param.node.rowIndex);
          }
        },
        {
          field: "distrCd",
          headerName: this.i18n("label.distrCd"),
          type: String,
          width: 140,
          isPk: true,
          editable: false,
          pinned: false,
          suppressMenu: false,
          sortable: true,
          cellStyle: {
            textAlign: "center"
          },
          cellClassRules: {}
        },
        {
          field: "distrNm",
          headerName: this.i18n("label.distrNm"),
          type: String,
          width: 160,
          isPk: false,
          editable: false,
          pinned: false,
          suppressMenu: false,
          sortable: true,
          cellStyle: {
            textAlign: "center"
          },
          cellClassRules: {}
        },
        {
          field: "distrEnm",
          headerName: this.i18n("label.distrEnm"),
          type: String,
          width: 140,
          isPk: false,
          editable: false,
          pinned: false,
          suppressMenu: false,
          sortable: true,
          cellStyle: {
            textAlign: "center"
          },
          cellClassRules: {}
        },
        {
          field: "distrTypCd",
          headerName: this.i18n("label.distrTyp"),
          type: String,
          width: 140,
          isPk: false,
          editable: false,
          pinned: false,
          suppressMenu: false,
          sortable: true,
          cellStyle: {
            textAlign: "center"
          },
          cellClassRules: {},
          valueFormatter: param => param.value && lookupValue(this.cdList.distrTypCdList, param.value)
        },
        {
          field: "distrStatCd",
          headerName: this.i18n("label.distrStatCd"),
          type: String,
          width: 140,
          isPk: false,
          editable: false,
          pinned: false,
          suppressMenu: false,
          sortable: true,
          cellStyle: {
            textAlign: "center"
          },
          cellClassRules: {},
          valueFormatter: param => param.value && lookupValue(this.cdList.distrStatCdList, param.value)
        },
        {
          field: "bizNo",
          headerName: this.i18n("label.bizNo"),
          type: String,
          width: 140,
          isPk: false,
          editable: false,
          pinned: false,
          suppressMenu: false,
          sortable: true,
          cellStyle: {
            textAlign: "center"
          },
          cellClassRules: {}
        },
        {
          field: "parDistrCd",
          headerName: this.i18n("label.parDistrCd"),
          type: String,
          width: 140,
          isPk: false,
          editable: false,
          pinned: false,
          suppressMenu: false,
          sortable: true,
          cellStyle: {
            textAlign: "center"
          },
          cellClassRules: {}
        },
        // {
        //   field: "deptCd",
        //   headerName: this.i18n("label.deptCd"),
        //   type: String,
        //   width: 140,
        //   isPk: false,
        //   editable: false,
        //   pinned: false,
        //   suppressMenu: false,
        //   sortable: true,
        //   cellStyle: {
        //     textAlign: "center"
        //   },
        //   cellClassRules: {}
        // },
        {
          field: "addedDate",
          headerName: this.i18n("label.addedDate"),
          type: String,
          width: 140,
          isPk: false,
          editable: false,
          pinned: false,
          suppressMenu: false,
          sortable: true,
          cellStyle: {
            textAlign: "center"
          },
          cellClassRules: {},
          valueFormatter: param => this.i18nDtTmFormat(param.value)
        },
        {
          field: "restCell",
          headerName: "",
          width: 0,
          type: String,
          isPk: false,
          editable: false,
          pinned: false,
          suppressMenu: false,
          sortable: false,
          cellStyle: {},
          cellClassRules: {}
        }
      ];
    }
  }
};
