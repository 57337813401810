/**
 * 1. 개요 : 공통
 * 2. 처리내용 : 공통 - filter
 * @File Name : filter.js
 * @date : 2020. 02. 20. 09:46:00
 * @author : 이상철
 * @history :
 *-----------------------------------------------------------------------
 * 변경일			          		  작성자		변경내용
 *-----------------------------------------------------------------------
 * 2020. 2. 20. 09:46:00	    이상철	  최초 작성
 *-----------------------------------------------------------------------
 */
import moment from "moment";
import store from "@/store";
import { decodeToHtml } from "@/utils";

export default {
  numberFormat: value => {
    if (typeof value !== "number") return value;
    return value.toFixed(0).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,");
  },
  dateFormat: (value, format = "YYYY-MM-DD") => moment(value).format(format),
  i18n: (messageId, ...values) => {
    const message = store.getters["I18n/getMessageById"](messageId);
    const { msgLangCd } = store.getters["Options/all"];
    if (message) {
      const text = (() => {
        switch (msgLangCd) {
          case "en_US":
            return message.msgEnText;
          case "vi_VN":
            return message.msgViText;
          case "ko_KR":
            return message.msgKoText;
          case "jp_JP":
              return message.msgJpText;
          default:
            return message.msgEnText; 
        }
      })();

      return text.replace(
        /{{\$(\d*)}}/g,
        (match, index) => values[index - 1] || match
      );
    }

    const suggestList = store.getters["I18n/findMessage"](messageId);
    if (suggestList.length) {
      suggestList.forEach(item =>
        console.log(`i18n: suggestion ${item.msgId} : ${item.msgKoText}`)
      );
    }

    return decodeToHtml(messageId);
  },

  i18nDtFormat: date => {
    const { msgLangCd } = store.getters["Options/all"];

    return (() => {
      switch (msgLangCd) {
        case "en_US":
          return moment(date, "YYYYMMDD").format("MM/DD/YYYY");
        case "vi_VN":
          return moment(date, "YYYYMMDD").format("MM-DD-YYYY");
        case "ko_KR":
          return moment(date, "YYYYMMDD").format("YYYY.MM.DD");
        default:
          return moment(date, "YYYYMMDD").format("MM/DD/YYYY");
      }
    })();
  },

  i18nDtTmFormat: date => {
    const { msgLangCd } = store.getters["Options/all"];

    return (() => {
      switch (msgLangCd) {
        case "en_US":
          return moment(date, "YYYYMMDDhhmmss").format("MM/DD/YYYY HH:mm:ss");
        case "vi_VN":
          return moment(date, "YYYYMMDDhhmmss").format("MM-DD-YYYY HH:mm:ss");
        case "ko_KR":
          return moment(date, "YYYYMMDDhhmmss").format("YYYY.MM.DD HH:mm:ss");
        default:
          return moment(date, "YYYYMMDDhhmmss").format("MM/DD/YYYY HH:mm:ss");
      }
    })();
  }
};
