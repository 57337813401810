/**
 * 1. 개요 : 재고일괄등록
 * 2. 처리내용 : 재고일괄등록 - 팝업창 컴포넌트
 * @File Name : CmnStckBatUpPopup.vue
 * @date : 2020. 05. 06. 15:00:00
 * @author : 이현우
 * @history :
 *-----------------------------------------------------------------------
 * 변경일			          		  작성자		변경내용
 *-----------------------------------------------------------------------
 * 2020. 05. 06. 15:00:00	    이현우	  최초 작성
 *-----------------------------------------------------------------------
 */
<template>
	<popup :title="title" @close="$emit('close')" popSize="size-850">
		<data-table tblId="tblInfo01">
			<template slot="tableInfo">
				{{'label.mdlTypCd' | i18n }}, {{'label.stckTgtLvlCd' | i18n }}, {{'label.upTypCd' | i18n }}, {{'label.stckTgtCd' | i18n }},
				{{'label.trmMdlCd' | i18n }}, {{'label.mvnoMdlCd' | i18n }}, {{'label.attchFile' | i18n }}
				&nbsp;
				{{ 'label.rgt' | i18n }} or {{ 'label.change' | i18n }}
			</template>
			<template slot="caption">{{ title }}</template>
			<template slot="colgroup">
				<col style="width:150px" />
				<col style="width:auto" />
				<col style="width:150px" />
				<col style="width:auto" />
			</template>
			<template slot="tbody">
				<tr>
					<th scope="row">
						<label class="required" for="stckBatUpVOMdlTypCd">
							<span class="star">{{ 'label.require'| i18n }}{{ 'label.input'| i18n }}</span>
							{{'label.mdlTypCd' | i18n }}
						</label>
					</th>
					<td>
						<code-selector
							:key="key"
							id="stckBatUpVOMdlTypCd"
							ref="stckBatUpVOMdlTypCd"
							v-model="stckBatUpVO.mdlTypCd"
							:cdList="cdList.mdlTypCdList"
							:isAll="false"
							:isSel="true"
							@change="fnMdlTypCdChange($event.target.value)"
							:disabled="stckBatUpVO.status === 'U'"
						/>
					</td>
					<th scope="row">
						<label class="required" for="stckBatUpVOStckTgtLvlCd">
							<span class="star">{{ 'label.require'| i18n }}{{ 'label.input'| i18n }}</span>
							{{'label.stckTgtLvlCd' | i18n }}
						</label>
					</th>
					<td>
						<code-selector
							id="stckBatUpVOStckTgtLvlCd"
							ref="stckBatUpVOStckTgtLvlCd"
							v-model="stckBatUpVO.stckTgtLvlCd"
							:cdList="cdList.stckTgtLvlCdList"
							:isAll="false"
							:isSel="true"
						/>
					</td>
				</tr>
				<tr>
					<th scope="row">
						<label class="required" for="stckBatUpVOUpTypCd">
							<span class="star">{{ 'label.require'| i18n }}{{ 'label.input'| i18n }}</span>
							{{'label.upTypCd' | i18n }}
						</label>
					</th>
					<td>
						<code-selector
							:key="key"
							id="stckBatUpVOUpTypCd"
							ref="stckBatUpVOUpTypCd"
							v-model="stckBatUpVO.upTypCd"
							:cdList="cdList.upTypCdList"
							:isAll="false"
							:isSel="true"
							@change="fnUpTypCdChange($event.target.value)"
						/>
					</td>
					<th scope="row">
						<label class="required" for="stckBatUpVOStckTgtCd">
							<span class="star">{{ 'label.require'| i18n }}{{ 'label.input'| i18n }}</span>
							{{'label.stckTgtCd' | i18n }}
						</label>
					</th>
					<td>
						<search-input-text
							id="stckBatUpVOStckTgtCd"
							ref="stckBatUpVOStckTgtCd"
							v-model="stckBatUpVO.stckTgtCd"
							type="text"
							:disabled="false"
							:placeholder="i18n('label.stckTgtCd')"
							@fnSearch="validateShowCmnInfPopup"
						/>
					</td>
				</tr>
				<tr v-show="stckBatUpVO.upTypCd=='11'">
					<th scope="row">
						<label class="required" for="searchTrmMdlCd">
							<span class="star">{{ 'label.require'| i18n }}{{ 'label.input'| i18n }}</span>
							{{'label.trmMdlCd' | i18n }}
						</label>
					</th>
					<td colspan="3">
						<biz-selector
							:key="key"
							id="searchTrmMdlCd"
							v-model="stckBatUpVO.trmMdlCd"
							:list="trmMdlCdList"
							:isAll="false"
							:isSel="true"
							@change="searchTrmMdlOne($event.target.value)"
						/>
					</td>
				</tr>
				<tr v-show="stckBatUpVO.upTypCd=='21'">
					<th scope="row">
						<label class="required" for="searchMvnoMdlCd">
							<span class="star">{{ 'label.require'| i18n }}{{ 'label.input'| i18n }}</span>
							{{'label.mvnoMdlCd' | i18n }}
						</label>
					</th>
					<td colspan="3">
						<biz-selector
							:key="key"
							id="searchMvnoMdlCd"
							v-model="stckBatUpVO.mvnoMdlCd"
							:list="mvnoMdlCdList"
							:isAll="false"
							:isSel="true"
						/>
					</td>
				</tr>
				<tr>
					<th scope="row">
						<label for="stckBatUpVOAtchFileFkId">{{'label.attchFile' | i18n }}</label>
					</th>
					<td colspan="3">
						<div class="file-wrap">
							<a :href="this.excelTempUrl" @click="fnDoNotLogout($event)" class="excel-down">
								<img src="@/assets/img/btn-icon-download02.png" alt />
								{{ 'label.excelTempDown' | i18n }}
							</a>
							<button type="button" @click.stop="excelFileAdd" class="file-box">
								<input
									id="excelFile"
									ref="excelFile"
									type="file"
									:accept="excelUpload.acceptExtention"
									@change.stop="uploadExcelFile"
									title="i18n('label.attchFile')"
								/>
								{{ 'label.file' | i18n }}{{ 'label.search' | i18n }}
							</button>
						</div>
					</td>
				</tr>
			</template>
		</data-table>

		<cmn-agncy-inf-popup
			v-if="isShowCmnAgncyInfPopup"
			@close="closeCmnAgncyInfPopup"
			@fnRowDoubleClick="fnCmnAgncyInfPopupDoubleClick"
			:paramData="agncyDistrSetParam()"
		/>
		<cmn-distr-inf-popup
			v-if="isShowCmnDistrInfPopup"
			@close="closeCmnDistrInfPopup"
			@fnRowDoubleClick="fnCmnDistrInfPopupDoubleClick"
			:paramData="agncyDistrSetParam()"
		/>

		<template slot="buttons">
			<primary-button @click="validate">{{ 'button.reg' | i18n }}</primary-button>
			<default-button @click="$emit('close')">{{ 'button.cancel' | i18n }}</default-button>
		</template>
	</popup>
</template>

<script>
import DataTable from "@/components/DataTable";
import Popup from "@/components/Popup";
import CmnAgncyInfPopup from "../CmnAgncyInfPopup";
import CmnDistrInfPopup from "../CmnDistrInfPopup";
import { isEmpty } from "@/utils";

import { CmnStckBatUpPopupSaveModel, Helper, ExcelUpload } from "./mixins";

export default {
	name: "CmnStckBatUpPopup",
	components: {
		Popup,
		DataTable,
		CmnAgncyInfPopup,
		CmnDistrInfPopup
	},
	mixins: [CmnStckBatUpPopupSaveModel, Helper, ExcelUpload],
	props: {
		paramData: {
			type: Object,
			default: () => {}
		}
	},
	data() {
		return {
			key: 0,
			stckBatUpVO: {
				status: "U",
				mdlTypCd: "",
				stckTgtLvlCd: "",
				stckTgtCd: "",
				upTypCd: "",
				ordId: "",
				atchFileFkId: "",
				trmMdlCd: "",
				manufCd: "",
				mdlClCd: "",
				mvnoMdlCd: ""
			},
			trmMdlCdList: [],
			trmMdlMngVO: {
				status: "I",
				trmMdlCd: "",
				manufCd: "",
				mdlClCd: ""
			},
			mvnoMdlCdList: [],
			agncyDistrParam: {
				cd: "",
				nm: "",
				parCd: ""
			},
			isShowCmnDistrInfPopup: false,
			isShowCmnAgncyInfPopup: false,
			cdList: {
				stckTgtLvlCdList: [],
				mdlTypCdList: [],
				upTypCdList: [],
				upTypCdListAll: []
			},
			excelTempUrl: ""
		};
	},
	created() {
		this.fnGetAxcCodeGrpList(["STCK_TGT_LVL_CD", "MDL_TYP_CD", "UP_TYP_CD"]);
	},
	computed: {
		/**
		 * 화면 title 설정
		 * @return {String}
		 */
		title() {
			return this.i18n("label.stck") + this.i18n("label.batUp");
		}
	},
	mounted() {},
	methods: {
		/**
		 * 모델유형코드 변경시(화면 최초 로딩시)
		 */
		fnMdlTypCdChangeFirst(value) {
			if (value == "TRM") {
				// if (this.stckBatUpVO.mdlTypCd == "TRM") {
				// 모델유형코드가 단말기일 경우
				this.cdList.upTypCdList = this.cdList.upTypCdListAll.filter(
					a => a.codeId.trim().substring(0, 1) === "1"
				);
			} else if (value == "MVN") {
				// } else if (this.stckBatUpVO.mdlTypCd == "MVN") {
				// 모델유형코드가 유심일 경우
				this.cdList.upTypCdList = this.cdList.upTypCdListAll.filter(
					a => a.codeId.trim().substring(0, 1) === "2"
				);
			} else if (value == "SUP") {
				this.cdList.upTypCdList = [];
			}
			this.key = this.key + 1;
		},
		/**
		 * 모델유형코드 변경시
		 */
		fnMdlTypCdChange(value) {
			this.trmMdlCdList = [];
			this.mvnoMdlCdList = [];
			this.stckBatUpVO.upTypCd = "";
			this.excelUpload.file = null;

			if (value == "TRM") {
				// if (this.stckBatUpVO.mdlTypCd == "TRM") {
				// 모델유형코드가 단말기일 경우
				this.cdList.upTypCdList = this.cdList.upTypCdListAll.filter(
					a => a.codeId.trim().substring(0, 1) === "1"
				);
			} else if (value == "MVN") {
				// } else if (this.stckBatUpVO.mdlTypCd == "MVN") {
				// 모델유형코드가 유심일 경우
				this.cdList.upTypCdList = this.cdList.upTypCdListAll.filter(
					a => a.codeId.trim().substring(0, 1) === "2"
				);
			} else if (value == "SUP") {
				this.cdList.upTypCdList = [];
			}
			this.key = this.key + 1;
		},

		/**
		 * 업로드유형코드 변경시(샘플파일 추가후 변경)
		 */
		fnUpTypCdChange(value) {
			this.clear();

			if (value == "11") {
				// 단말기등록 양식
				this.excelTempUrl = "/excel/TrmRegTemp.xlsx";
				// 단말기 목록 콤보박스 조회
				this.searchCdTrmMdl();
			} else if (value == "12") {
				// 단말기ID매핑 양식
				this.excelTempUrl = "/excel/TrmIdMappingTemp.xlsx";
			} else if (value == "21") {
				// 유심등록 양식
				this.excelTempUrl = "/excel/MvnoRegTemp.xlsx";
				// 유심 목록 콤보박스 조회
				this.searchCdMvnoMdl();
			} else if (value == "22") {
				// 단말기SN매핑 양식
				this.excelTempUrl = "/excel/TrmSnMappingTemp.xlsx";
			} else {
				this.excelTempUrl = "";
			}

			this.key = this.key + 1;
		},
		/**
		 * 업무 코드 리스트 페치
		 * @params {Array} searchCodeGrpIdList
		 */
		fnGetAxcCodeGrpList(searchCodeGrpIdList = []) {
			if (isEmpty(searchCodeGrpIdList)) return;

			this.$httpPost(
				"/api/bep/getAxcCodeGroupListByCodeGrpIdList.do",
				{ searchCodeGrpIdList },
				{ showLoading: false }
			)
				.then(response => {
					this.cdList.mdlTypCdList = response.data.resultList.find(
						a => a[0].codeGrpId === "MDL_TYP_CD"
					);
					this.cdList.stckTgtLvlCdList = response.data.resultList.find(
						a => a[0].codeGrpId === "STCK_TGT_LVL_CD"
					);
					this.cdList.upTypCdListAll = response.data.resultList.find(
						a => a[0].codeGrpId === "UP_TYP_CD"
					);

					if (!isEmpty(this.paramData.status)) {
						this.stckBatUpVO.status = this.paramData.status;
					}
					this.stckBatUpVO.mdlTypCd = this.paramData.mdlTypCd;
					this.stckBatUpVO.stckTgtLvlCd = this.paramData.stckTgtLvlCd;
					this.stckBatUpVO.stckTgtCd = this.paramData.stckTgtCd;
					// this.stckBatUpVO.upTypCd = this.paramData.upTypCd;
					// this.stckBatUpVO.atchFileFkId = this.paramData.atchFileFkId;
					this.key = this.key + 1;

					// 모델유형코드 변경시
					this.fnMdlTypCdChangeFirst(this.stckBatUpVO.mdlTypCd);

					// 업로드유형코드 변경시
					this.fnUpTypCdChange(this.stckBatUpVO.upTypCd);
				})
				.catch(this.commonErrorHandler);
		},
		/**
		 * 총판 검색 팝업 오픈
		 */
		showCmnDistrInfPopup() {
			this.isShowCmnDistrInfPopup = !this.isShowCmnDistrInfPopup;
		},
		/**
		 * 총판 검색 팝업 닫기
		 */
		closeCmnDistrInfPopup() {
			this.isShowCmnDistrInfPopup = false;
		},
		/**
		 * 대리점 검색 팝업 호출
		 */
		showCmnAgncyInfPopup() {
			this.isShowCmnAgncyInfPopup = !this.isShowCmnAgncyInfPopup;
		},
		/**
		 * 대리점 검색 팝업 호출
		 */
		closeCmnAgncyInfPopup() {
			this.isShowCmnAgncyInfPopup = false;
		},
		/**
		 * 대리점/총판 검색 팝업 파라미터
		 * @return {Object}
		 */
		agncyDistrSetParam() {
			this.agncyDistrParam.cd = this.stckBatUpVO.stckTgtCd;
			this.agncyDistrParam.nm = "";
			this.agncyDistrParam.parCd = this.$store.getters[
				"Options/userInfo"
			].parCd;
			return this.agncyDistrParam;
		},
		/**
		 * 대리점정보 조회 팝업에서 넘어온 데이터 처리
		 * @params {Object} params
		 */
		fnCmnAgncyInfPopupDoubleClick(params) {
			this.stckBatUpVO.stckTgtCd = params.data.agncyCd;
		},
		/**
		 * 총판정보 조회 팝업에서 넘어온 데이터 처리
		 * @params {Object} params
		 */
		fnCmnDistrInfPopupDoubleClick(params) {
			this.stckBatUpVO.stckTgtCd = params.data.distrCd;
		},
		/**
		 * 대리점, 총판 팝업 분기
		 */
		validateShowCmnInfPopup() {
			if (isEmpty(this.stckBatUpVO.stckTgtLvlCd)) {
				this.alert({
					type: "warning",
					title: this.i18n("label.confirm"),
					message: this.i18n("label.stckTgtLvlCd") + this.i18n("alt.required")
				});
			} else {
				if (this.stckBatUpVO.stckTgtLvlCd == "04") {
					// 대리점 검색 팝업 호출
					this.showCmnAgncyInfPopup();
				} else {
					// 총판 검색 팝업 호출
					this.showCmnDistrInfPopup();
				}
			}
		},
		/**
		 * 단말기모델코드 조회(콤보박스)
		 */
		searchCdTrmMdl() {
			this.$httpPost(
				"/api/bep/getTrmMdlMngListForCombo.do",
				{},
				{
					showLoading: true
				}
			)
				.then(response => {
					this.trmMdlCdList = response.data.resultList;
				})
				.catch(this.commonErrorHandler);
		},
		/**
		 * 단말기모델 정보 단건 조회
		 * @params {String} value
		 */
		searchTrmMdlOne(value) {
			this.clear();
			this.$httpPost(
				"/api/bep/getTrmMdlMngDetail.do",
				{
					searchTrmMdlCd: value
				},
				{
					showLoading: true
				}
			)
				.then(response => {
					this.trmMdlMngVO = { ...response.data.resultList[0], status: "U" };

					this.stckBatUpVO.trmMdlCd = this.trmMdlMngVO.trmMdlCd;
					this.stckBatUpVO.manufCd = this.trmMdlMngVO.manufCd;
					this.stckBatUpVO.mdlClCd = this.trmMdlMngVO.mdlClCd;
					this.stckBatUpVO.trmMdlCd = this.trmMdlMngVO.trmMdlCd;
					// 주문단계에서 사용시에 주문ID 추가 필요
					// this.stckBatUpVO.ordId = this.trmMdlMngVO.ordId;
				})
				.catch(this.commonErrorHandler);
		},
		/**
		 * 유심모델코드 조회(콤보박스)
		 */
		searchCdMvnoMdl() {
			this.$httpPost(
				"/api/bep/getMvnoMdlMngListForCombo.do",
				{},
				{
					showLoading: true
				}
			)
				.then(response => {
					this.mvnoMdlCdList = response.data.resultList;
				})
				.catch(this.commonErrorHandler);
		},
		/**
		 * 입력 폼 초기화
		 */
		clear() {
			this.trmMdlMngVO.status = "I";
			this.trmMdlMngVO.trmMdlCd = "";
			this.trmMdlMngVO.manufCd = "";
			this.trmMdlMngVO.mdlClCd = "";
		},
		/**
		 * 유효성 검증
		 */
		validate() {
			const validObj = [];
			validObj.push({
				ref: this.$refs.stckBatUpVOMdlTypCd,
				isValidate: !isEmpty(this.stckBatUpVO.mdlTypCd),
				message: this.i18n("label.mdlTypCd") + this.i18n("alt.required")
			});

			validObj.push({
				ref: this.$refs.stckBatUpVOStckTgtLvlCd,
				isValidate: !isEmpty(this.stckBatUpVO.stckTgtLvlCd),
				message: this.i18n("label.stckTgtLvlCd") + this.i18n("alt.required")
			});
			validObj.push({
				ref: this.$refs.stckBatUpVOStckTgtCd,
				isValidate: !isEmpty(this.stckBatUpVO.stckTgtCd),
				message: this.i18n("label.stckTgtCd") + this.i18n("alt.required")
			});
			validObj.push({
				ref: this.$refs.stckBatUpVOUpTypCd,
				isValidate: !isEmpty(this.stckBatUpVO.upTypCd),
				message: this.i18n("label.upTypCd") + this.i18n("alt.required")
			});
			if (this.stckBatUpVO.upTypCd == "11") {
				validObj.push({
					ref: this.$refs.stckBatUpVOTrmMdlCd,
					isValidate: !isEmpty(this.stckBatUpVO.trmMdlCd),
					message: this.i18n("label.trmMdlCd") + this.i18n("alt.required")
				});
			} else if (this.stckBatUpVO.upTypCd == "21") {
				validObj.push({
					ref: this.$refs.stckBatUpVOMvnoMdlCd,
					isValidate: !isEmpty(this.stckBatUpVO.mvnoMdlCd),
					message: this.i18n("label.mvnoMdlCd") + this.i18n("alt.required")
				});
			}

			if (this.validateHelper(validObj)) {
				return;
			} else {
				this.alert({
					title: this.i18n("label.save"),
					message: this.i18n("alt.questionSave"),
					buttons: [
						{
							type: "primary",
							label: this.i18n("button.confirm"),
							onPress: () => this.saveUploadExcelFile()
						},
						{ label: this.i18n("button.cancel") }
					]
				});
			}
		}
	}
};
</script>

<style lang="scss" scoped>
@import "@/scss/_color.scss";
@import "@/scss/_button.scss";
</style>


