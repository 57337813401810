/**
 * 1. 개요 : 사업자관리
 * 2. 처리내용 : 사업자관리 Mixins - 파일 관련 helper
 * @File Name : FileUpload.js
 * @date : 2020. 02. 20. 09:46:00
 * @author : 이상철
 * @history :
 *-----------------------------------------------------------------------
 * 변경일			          		  작성자		변경내용
 *-----------------------------------------------------------------------
 * 2020. 2. 20. 09:46:00	    이상철	  최초 작성
 *-----------------------------------------------------------------------
 */
import exportFromJSON from 'export-from-json'
import moment from 'moment';
import { CmnOrdSnPopupViewModel } from "./";
import { isEmpty } from '@/utils'
import { lookupValue } from "@/utils/dataGrid";
const excelDownCnt = process.env.VUE_APP_EXCEL_DOWN_CNT;

export default {
  data() {
    return {
      excelDownload: {
        currPage: 1,
        resultList: [],
        pageUnit: excelDownCnt,
        pageSize: excelDownCnt
      },
    }
  },
  computed: {
		gridTitle() {
			return "그리드 이름"
		}
	},
  mixins: [CmnOrdSnPopupViewModel],
  methods: {
    /**
     * 엑셀 다운로드 함수
     */
		fnExcelDown() {
      // 최대 엑셀 다운로드 가능 데이터 갯수 체크.
      if(this.totalCnt ==0){
        this.alert({
          type: "warning",
          title: `${this.i18n("label.info")}`,
          message: this.i18n("alt.noData")
        })

        return;
      }
      if(this.totalCnt > excelDownCnt) {
        this.alert({
          type: "warning",
          title: this.i18n("label.error"),
          message: excelDownCnt + this.i18n("alt.maxExcelCnt") 
        })
        return;
      }
			this.$httpPost(
          "/api/bep/getCmnOrdSnListForExcelDown.do",
          {
            searchOrdId: this.search.ordId,
						searchCompStatCd: this.search.compStatCd,
						searchMdlTypCd: this.search.mdlTypCd,

            // currPage: this.currPage,
            // pageUnit: this.pageUnit,
            // pageSize: this.pageSize
          },
          {
            showLoading: true
          }
				)
				.then(response => {
          if(isEmpty(response.data.resultList)) {
            this.alert({
              type: "warning",
					    title: `${this.i18n("label.info")}`,
              message: this.i18n("alt.noData")
            })

            return;
          }

          this.excelDownload.resultList = response.data.resultList

          const data = this.excelDownload.resultList.map(a => {
            const newObj = {};
            const viewModel = this.cmnOrdSnPopupViewModel();

            Object.entries(a).forEach(aa => {
              const found = viewModel.find(aaa => aa[0] === aaa.field && (aaa.export == undefined || aaa.export == true));
              if(found){
                if(aa[0]=="addedDate"){
                  aa[1] = this.i18nDtTmFormat(aa[1]);
                }
                if(aa[0] == "compStatCd"){
                  aa[1]= lookupValue(this.cdList.compStatCdList, a.compStatCd);
                }
                if(aa[0]=="stckTgtLvlCd"){
                  aa[1]= lookupValue(this.cdList.stckTgtLvlCdList, a.stckTgtLvlCd);
                }
                if(aa[0]=="statCd"){
                  aa[1]= lookupValue(this.cdList.stckStatCdList, a.statCd);
                }
                newObj[found.headerName] = aa[1]
              } 
            })

            return newObj
          })

          exportFromJSON({
            data,
            fileName: `${moment().format('YYYYMMDD HHmmss')}_${this.gridTitle}`,
            exportType: 'xls'
          })
        })
        .catch(this.commonErrorHandler)
    },
  }
}