/**
 * 1. 개요 : Dialog 공통 컴포넌트
 * 2. 처리내용 : Dialog 공통 컴포넌트 - Window
 * @File Name : Window.vue
 * @date : 2020. 02. 20. 09:46:00
 * @author : 이상철
 * @history :
 *-----------------------------------------------------------------------
 * 변경일			          		  작성자		변경내용
 *-----------------------------------------------------------------------
 * 2020. 2. 20. 09:46:00	    이상철	  최초 작성
 *-----------------------------------------------------------------------
 */
<template>
	<transition name="fade">
		<div class="dimmed">
			<div ref="wrap" class="wrap" role="dialog">
				<h1 
					class="header" 
					:class="{'warning-orange': type === 'warning'}"
				>
					<template v-if="title">{{ title }}</template>
					<template v-else>
						<slot name="header" />
					</template>
				</h1>
				<div class="body">
					<slot />
				</div>
				<div class="footer">
					<slot name="footer" />
					<slot name="buttons" />
				</div>
				<button type="button" class="close-button" @click="close()">Close</button>
			</div>
		</div>
	</transition>
</template>

<script>
import { mapActions } from "vuex";

export default {
	name: "Window",
	props: {
		id: {
			type: String,
			required: true
		},
		title: {
			type: String,
			default: null
		},
		type: {
			type: String,
			default: null
		},
	},
	created() {
		this.beforeFocusedElement = document.activeElement;
	},
	mounted() {
		this.updatePosition();
		this.addEvent();
		this.$nextTick(() => {
			const { wrap } = this.$refs;
			wrap.focus();
		});
	},
	beforeDestroy() {
		this.isUnmounting = true;
		this.removeEvent();
		this.beforeFocusedElement.focus();
	},
	methods: {
		/**
     * store에 선언된 closeAlert 함수를 불러옴.
     */
		...mapActions("Alert", ["closeAlert"]),
		/**
		 * 얼럿 팝업 내 버튼 listner. 이벤트가 없으면 창 닫음.
     * @params {Function} onPress
     */
		onPressListener(onPress) {
			if (!onPress || onPress() !== false) this.close();
		},
		/**
		 * 얼럿 팝업 close
     */
		close() {
			this.closeAlert(this.id);
		},
		/**
     * position event
     */
		updatePosition() {
			const { wrap } = this.$refs;
			const { innerWidth, innerHeight } = window;
			const rect = wrap.getBoundingClientRect();

			this.left = (innerWidth - rect.width) / 2;
			this.top = (innerHeight - rect.height) / 2;
		},
		/**
     * event add
     */
		addEvent() {
			window.addEventListener("resize", this.updatePosition);
		},
		/**
     * event remove
     */
		removeEvent() {
			window.removeEventListener("resize", this.updatePosition);
		},
	}
};
</script>

<style lang="scss">
@import "@/scss/_color.scss";

.fade-enter-active,
.fade-leave-active {
	transition: opacity 0.5s;
	.wrap {
		transition: opacity 0.3s, transform 0.3s;
	}
}
.fade-enter {
	opacity: 0;
	.wrap {
		opacity: 0;
		transform: translateY(20px) scale(0.9);
	}
}

.fade-leave-to {
	opacity: 0;
	.wrap {
		opacity: 0;
		transform: translateY(-20px) scale(0.9);
	}
}

.dimmed {
	position: fixed;
	display: flex;
	justify-content: center;
	align-items: center;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background: rgba(0, 0, 0, 0.5);
	z-index: 1003;
	transition: opacity 0.3s;
}

.wrap {
	overflow: hidden;
	position: relative;
	display: inline-block;
	width: 300px;
	min-height: 160px;
	background: $cf;
	border: solid 2px $c6;

	.header {
		width:100%;
		height: 40px;
		margin: 0;
		padding: 12px 45px 12px 18px;
		font-size: 14px;
		font-weight: 600;
		color: $cf;
		letter-spacing: -0.3px;
		background: $c6;
		span {
			color: inherit;
		}
		&:after {
			display: inline-block;
			content: "";
		}
	}

	.body {
		overflow-y: auto;
		max-height: 350px;
		margin-top: 30px;
		padding: 0 18px;
		color: $c6;
		p {
			margin: 0;
		}
	}

	.footer {
		margin: 15px 18px 30px;
		text-align: right;
		button{
			min-width:52px;
			min-height:27px;
			padding:7px 15px 6px;
			border-radius:0;
			vertical-align:middle;
			color: $cf;
			background-color: $c6;
			&+button{
				margin-left:4px;
			}
		}
	}
	.close-button {
		position: absolute;
		top: 8px;
		right: 14px;
		background: transparent !important;

		width: 20px;
		height: 20px;
		font-size:0;
		color: transparent;
		overflow: hidden;

		&::before,
		&::after {
			content: "";
			position: absolute;
			background: $cf;
			width: 1px;
			height: 100%;
			top: 0;
			left: 50%;
			transform: rotate(45deg);
		}
		&::after {
			transform: rotate(-45deg);
		}
	}

	
	.warning-orange{ // 8.4 이지혜 추가
		background-color:#cf6a13;
	}
}
</style>

