export default {
  state: {
    bizNo: "",
    merId: "",
    trmId: "",
    aplyStaDtm: "",
    aplyEndDtm: "",
    agncyCd: "",
    trmInstMngId: "",
    trInstCd: "",
    trInstAplyStaDtm: "",
    trmMngId: "",
    trmBtn: true,
    trmSnBtn: true,
    trmInstBtn: true,
    trmIssTrInstBtn: true,
    merSubId :"",
    instMerMngId :"",
    tranUuid :"",
    pcposId : ""
  },
  namespaced: true,
  mutations: {
    SET_BIZ_NO(state, BizNo) {
      state.bizNo = BizNo;
    },
    SET_MER_ID(state, MerId) {
      state.merId = MerId;
    },
    SET_TRM_ID(state, TrmId) {
      state.trmId = TrmId;
    },
    SET_APLY_STA_DTM(state, AplyStaDtm) {
      state.aplyStaDtm = AplyStaDtm;
    },
    SET_APLY_END_DTM(state, AplyEndDtm) {
      state.aplyEndDtm = AplyEndDtm;
    },
    SET_AGNCY_CD(state, AgncyCd) {
      state.agncyCd = AgncyCd;
    },
    SET_TRM_INST_MNG_ID(state, TrmInstMngId) {
      state.trmInstMngId = TrmInstMngId;
    },
    SET_TR_INST_CD(state, TrInstCd) {
      state.trInstCd = TrInstCd;
    },
    SET_TR_INST_APLY_STA_DTM(state, TrInstAplyStaDtm) {
      state.trInstAplyStaDtm = TrInstAplyStaDtm;
    },
    SET_TRM_MNG_ID(state, TrmMngId) {
      state.trmMngId = TrmMngId;
    },
    SET_TRM_BTN(state, TrmBtn) {
      state.trmBtn = TrmBtn;
    },
    SET_TRM_SN_BTN(state, TrmSnBtn) {
      state.trmSnBtn = TrmSnBtn;
    },
    SET_TRM_INST_BTN(state, TrmInstBtn) {
      state.trmInstBtn = TrmInstBtn;
    },
    SET_TRM_ISS_TR_INST_BTN(state, TrmIssTrInstBtn) {
      state.trmIssTrInstBtn = TrmIssTrInstBtn;
    },
    SET_MER_SUB_ID(state, MerSubId){
      state.merSubId = MerSubId;
    },
    SET_INST_MER_MNG_ID(state, InstMerMngId){
      state.instMerMngId = InstMerMngId;
    },
    SET_TRAN_UUID(state, tranUuid){
      state.tranUuid = tranUuid;
    },
    SET_PCPOS_ID(state, pcposId){
      state.pcposId = pcposId;
    },
    INITIALIZE(state) {
      state.bizNo = "";
      state.merId = "";
      state.trmId = "";
      state.aplyStaDtm = "";
      state.aplyEndDtm = "";
      state.agncyCd = "";
      state.trmInstMngId = "";
      state.trInstCd = "";
      state.trInstAplyStaDtm = "";
      state.trmMngId = "";
      state.trmBtn =  true;
      state.trmSnBtn =  true;
      state.trmInstBtn =  true;
      state.trmIssTrInstBtn = true;
      state.merSubId = "";
      state.trSvcCd ="";
      state.instMerMngId = "";
      state.tranUuid ="";
      state.pcposId ="";
    }
  },
  actions: {
    setBizNo({ commit }, BizNo) {
      commit('SET_BIZ_NO', BizNo);
    },
    setMerId({ commit }, MerId) {
      commit('SET_MER_ID', MerId);
    },
    setTrmId({ commit }, TrmId) {
      commit('SET_TRM_ID', TrmId);
    },
    setAplyStaDtm({ commit }, AplyStaDtm) {
      commit('SET_APLY_STA_DTM', AplyStaDtm);
    },
    setAplyEndDtm({ commit }, AplyEndDtm) {
      commit('SET_APLY_END_DTM', AplyEndDtm);
    },
    setAgncyCd({ commit }, AgncyCd) {
      commit('SET_AGNCY_CD', AgncyCd);
    },
    setTrmInstMngId({ commit }, TrmInstMngId) {
      commit('SET_TRM_INST_MNG_ID', TrmInstMngId);
    },
    setTrInstCd({ commit }, TrInstCd) {
      commit('SET_TR_INST_CD', TrInstCd);
    },
    setTrInstAplyStaDtm({ commit }, TrInstaplyStaDtm) {
      commit('SET_TR_INST_APLY_STA_DTM', TrInstaplyStaDtm);
    },
    setTrmMngId({commit}, TrmMngId) {
      commit('SET_TRM_MNG_ID', TrmMngId);
    },
    setTrmBtn({commit}, TrmBtn) {
      commit('SET_TRM_BTN', TrmBtn);
    },
    setTrmSnBtn({commit}, TrmSnBtn) {
      commit('SET_TRM_SN_BTN', TrmSnBtn);
    },
    setTrmInstBtn({commit}, TrmInstBtn) {
      commit('SET_TRM_INST_BTN', TrmInstBtn);
    },
    setTrmIssTrInstBtn({commit}, TrmIssTrInstBtn) {
      commit('SET_TRM_ISS_TR_INST_BTN', TrmIssTrInstBtn);
    },
    setInitilize({commit}) {
      commit('INITIALIZE');
    },
    setMerSubId({commit}, MerSubId) {
      commit('SET_MER_SUB_ID', MerSubId);
    },
    setInstMerMngId({commit}, InstMerMngId) {
      commit('SET_INST_MER_MNG_ID', InstMerMngId);
    },
    setTranUuid({commit}, tranUuid) {
      commit('SET_INST_MER_MNG_ID', tranUuid);
    },
    setPcposId({commit}, pcposId) {
      commit('SET_PCPOS_ID', pcposId);
    },
  },
  getters: {
    getBizNo: state => state.bizNo,
    getMerId: state => state.merId,
    getTrmId: state => state.trmId,
    getAplyStaDtm: state => state.aplyStaDtm,
    getAplyEndDtm: state => state.aplyEndDtm,
    getAgncyCd: state => state.agncyCd,
    getTrmInstMngId: state => state.trmInstMngId,
    getTrInstCd: state => state.trInstCd,
    getTrInstAplyStaDtm: state => state.trInstAplyStaDtm,
    getTrmMngId: state => state.trmMngId,
    getTrmBtn: state => state.trmBtn,
    getTrmSnBtn: state => state.trmSnBtn,
    getTrmInstBtn: state => state.trmInstBtn,
    getTrmIssTrInstBtn: state => state.trmIssTrInstBtn,
    getMerSubId: state => state.merSubId,
    getInstMerMngId : state => state.instMerMngId,
    getTranUuid : state => state.tranUuid,
    getPcposId : state => state.pcposId
  }
};
