var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "input-text text-box" }, [
    _vm.type === "checkbox"
      ? _c(
          "input",
          _vm._g(
            _vm._b(
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.model,
                    expression: "model"
                  }
                ],
                ref: _vm.id,
                class: { "number-type": _vm.alignRight === true },
                attrs: {
                  id: _vm.id,
                  maxlength: _vm.calMaxlength,
                  placeholder: _vm.placeholder,
                  disabled: _vm.disabled,
                  onkeydown: _vm.onKeyDown,
                  type: "checkbox"
                },
                domProps: {
                  checked: Array.isArray(_vm.model)
                    ? _vm._i(_vm.model, null) > -1
                    : _vm.model
                },
                on: {
                  blur: _vm.onBlurHandler,
                  input: function($event) {
                    return _vm.$emit("input")
                  },
                  change: function($event) {
                    var $$a = _vm.model,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false
                    if (Array.isArray($$a)) {
                      var $$v = null,
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 && (_vm.model = $$a.concat([$$v]))
                      } else {
                        $$i > -1 &&
                          (_vm.model = $$a
                            .slice(0, $$i)
                            .concat($$a.slice($$i + 1)))
                      }
                    } else {
                      _vm.model = $$c
                    }
                  }
                }
              },
              "input",
              _vm.$attrs,
              false
            ),
            _vm.$listeners
          )
        )
      : _vm.type === "radio"
      ? _c(
          "input",
          _vm._g(
            _vm._b(
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.model,
                    expression: "model"
                  }
                ],
                ref: _vm.id,
                class: { "number-type": _vm.alignRight === true },
                attrs: {
                  id: _vm.id,
                  maxlength: _vm.calMaxlength,
                  placeholder: _vm.placeholder,
                  disabled: _vm.disabled,
                  onkeydown: _vm.onKeyDown,
                  type: "radio"
                },
                domProps: { checked: _vm._q(_vm.model, null) },
                on: {
                  blur: _vm.onBlurHandler,
                  input: function($event) {
                    return _vm.$emit("input")
                  },
                  change: function($event) {
                    _vm.model = null
                  }
                }
              },
              "input",
              _vm.$attrs,
              false
            ),
            _vm.$listeners
          )
        )
      : _c(
          "input",
          _vm._g(
            _vm._b(
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.model,
                    expression: "model"
                  }
                ],
                ref: _vm.id,
                class: { "number-type": _vm.alignRight === true },
                attrs: {
                  id: _vm.id,
                  maxlength: _vm.calMaxlength,
                  placeholder: _vm.placeholder,
                  disabled: _vm.disabled,
                  onkeydown: _vm.onKeyDown,
                  type: _vm.type
                },
                domProps: { value: _vm.model },
                on: {
                  blur: _vm.onBlurHandler,
                  input: [
                    function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.model = $event.target.value
                    },
                    function($event) {
                      return _vm.$emit("input")
                    }
                  ]
                }
              },
              "input",
              _vm.$attrs,
              false
            ),
            _vm.$listeners
          )
        ),
    _vm.showClear
      ? _c("div", {
          staticClass: "input-clear",
          attrs: { title: "clear" },
          on: {
            click: function($event) {
              return _vm.$emit("clear")
            }
          }
        })
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }