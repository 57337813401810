/**
 * 1. 개요 : 가맹점 정보 검색
 * 2. 처리내용 : 가맹점 정보 검색 - 이벤트 버스
 * File Name : EventBus.js
 * date : 2020. 05. 20. 16:15:00
 * author : 이지혜
 * history :
 *-----------------------------------------------------------------------
 * 변경일			          		  작성자		변경내용
 *-----------------------------------------------------------------------
 * 2020. 05. 20. 16:15:00	    이지혜	  최초 작성
 *-----------------------------------------------------------------------
 */
import Vue from "vue";

export const EventBus = new Vue();

export default {
  EventBus
};
