/**
 * 1. 개요 : 공통
 * 2. 처리내용 : 공통 - components 영역
 * @File Name : components.js
 * @date : 2020. 02. 20. 09:46:00
 * @author : 이상철
 * @history :
 *-----------------------------------------------------------------------
 * 변경일			          		  작성자		변경내용
 *-----------------------------------------------------------------------
 * 2020. 2. 20. 09:46:00	    이상철	  최초 작성
 *-----------------------------------------------------------------------
 */
import * as Buttons from "@/components/Buttons";
import * as Grid from "@/components/Grid";
import Pagination from "@/components/Pagination";
import Loading from "@/components/Loading";
import Scrollbar, { Scroller } from "@/components/Scrollbar";
import Inputs from "@/components/Inputs";
import MInputs from "@/components/Mobile/MInputs";

export default {
  ...Buttons,
  ...Grid,
  Loading,
  Pagination,
  Scrollbar,
  Scroller,
  ...Inputs,
  ...MInputs
};
