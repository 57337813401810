/**
 * Created by 이상철 on 2020/03/24 09:46:00
 */
import { isEmpty } from "@/utils";
import moment from "moment";
import store from "@/store";

// 전화번호 포매터
export const phoneFormatter = param => {
  if (isEmpty(param)) {
    return;
  }

  const value = param.toString().trim();

  let result;

  if (value.length === 9) {
    result = value.replace(/(\d{2})(\d{3})(\d{4})/, "$1-$2-$3");
  } else if (value.trim().length === 10 && value.slice(2) === "02") {
    result = value.replace(/(\d{2})(\d{4})(\d{4})/, "$1-$2-$3");
  } else if (value.trim().length === 10 && value.slice(2) !== "02") {
    result = value.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3");
  } else if (value.length === 11) {
    result = value.replace(/(\d{3})(\d{4})(\d{4})/, "$1-$2-$3");
  }

  return result;
};

// 금액 콤마 포매터
export const amtFormatter = param => {
  if (isEmpty(param)) {
    return;
  }

  const value = param.toString().trim();

  return value.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

// 경과 시간 포매터
export const rptTmFormatter = param => {
  if (isEmpty(param)) {
    return;
  }
  return moment(param.value.substring(0, 6), "hmmss").format("HH:mm:ss");
  // return moment.utc(parseInt(param.value)).format('HH:mm:ss');
};

// 시간 포매터
export const timeFormatter = param => {
  if (isEmpty(param) || param.length != 6) {
    return;
  }
  return moment(param, "hmmss").format("HH:mm:ss");
  // return moment.utc(parseInt(param.value)).format('HH:mm:ss');
};

// 일시 포매터
export const dateTimeFormatter = param => {
  if (isEmpty(param)) {
    return;
  }
  return moment(param.value).format("YYYY-MM-DD, HH:mm:ss");
};

// add by jwlee 20221017 시작 : 일시 포매터 - 언어코드에 따른 차등 포매팅
export const dateFormatter = param => {
  if (isEmpty(param)) {
    return;
  }

  const msgLangCd = store.getters["Options/all"].msgLangCd;

  switch (msgLangCd) {
    case "en_US":
      return moment(param.value).format("MM/DD/YYYY, HH:mm:ss");
    case "vi_VN":
      return moment(param.value).format("MM-DD-YYYY, HH:mm:ss");
    case "ko_KR":
      return moment(param.value).format("YYYY.MM.DD, HH:mm:ss");
    default:
      return moment(param.value).format("MM/DD/YYYY, HH:mm:ss");
  }
};
// add by jwlee 20221017 끝 : 일시 포매터 - 언어코드에 따른 차등 포매팅

// 확률 포매터
export const ratFormatter = param => {
  if (isEmpty(param)) {
    return;
  }
  return param.value * 0.01;
};

// 카드 번호 포매터
export const cardNoFormatter = param => {
  if (isEmpty(param)) {
    return;
  }
  return param.value.trim().replace(/(\d)(?=(?:\d{4})+(?!\d))/g, "$1-");
};
