// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@/assets/img/sp-pc.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".pc .hamburger-container[data-v-4e6f274c] {\n  font-size: 0;\n}\n.pc .hamburger-container svg[data-v-4e6f274c] {\n    width: 20px;\n    height: 20px;\n}\n.pc .hamburger-container > span[data-v-4e6f274c] {\n    cursor: pointer;\n    display: block;\n    bottom: -15px;\n    left: -28px;\n    width: 13px;\n    height: 58px;\n    font-size: 0;\n    background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat -100px -50px;\n}\n.pc .hamburger-container > span[data-v-4e6f274c]::before {\n      content: \"\";\n      display: block;\n      position: absolute;\n      top: calc(50% - 7px);\n      left: -1px;\n      -webkit-box-sizing: border-box;\n      box-sizing: border-box;\n      border-style: solid;\n      border-color: transparent #fff transparent transparent;\n      border-width: 5px;\n}\n.pc .hideSidebar .hamburger-container > a[data-v-4e6f274c]::before {\n  left: 4px;\n  -webkit-transform: rotate(180deg);\n          transform: rotate(180deg);\n}\n", ""]);
// Exports
module.exports = exports;
