/**
 * 1. 개요 : 사업자관리
 * 2. 처리내용 : 사업자관리 Mixins - 생성/수정 모델
 * @File Name : BizMngSaveModel.js
 * @date : 2020. 02. 20. 09:46:00
 * @author : 이상철
 * @history :
 *-----------------------------------------------------------------------
 * 변경일			          		  작성자		변경내용
 *-----------------------------------------------------------------------
 * 2020. 2. 20. 09:46:00	    이상철	  최초 작성
 *-----------------------------------------------------------------------
 */
export default {
  methods: {
    bizMngSaveModel(param = {}) {
      return [
        {
          field: "bizNo",
          isPk: true,
          isNotNull: true
        },
        {
          field: "bizNm",
          isPk: false,
          isNotNull: true
        },
        {
          field: "bizEnm",
          isPk: false,
          isNotNull: true
        },
        {
          field: "hompgAddr",
          isPk: false,
          isNotNull: false
        },
        {
          field: "bizPno",
          isPk: false,
          isNotNull: false
        },
        {
          field: "city",
          isPk: false,
          isNotNull: false
        },
        {
          field: "dist",
          isPk: false,
          isNotNull: false
        },
        {
          field: "ward",
          isPk: false,
          isNotNull: false
        },
        {
          field: "bizAddr",
          isPk: false,
          isNotNull: false
        },
        {
          field: "psnCorpClCd",
          isPk: false,
          isNotNull: false
        },
        {
          field: "telNo",
          isPk: false,
          isNotNull: false
        },
        {
          field: "mccCd",
          isPk: false,
          isNotNull: false
        },
        {
          field: "sicCd",
          isPk: false,
          isNotNull: false
        },
        {
          field: "atchFileFkId",
          isPk: false,
          isNotNull: false
        },
        {
          field: "delYn",
          isPk: false,
          isNotNull: true
        }
      ];
    }
  }
};
