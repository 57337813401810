/**
 * 1. 개요 : 재고일괄등록
 * 2. 처리내용 : 재고일괄등록 Mixins - Excel 업로드
 * @File Name : CmnStckBatUpPopupSaveModel.js
 * @date : 2020. 05. 06. 13:00:00
 * @author : 이현우
 * @history :
 *-----------------------------------------------------------------------
 * 변경일			          		  작성자		변경내용
 *-----------------------------------------------------------------------
 * 2020. 05. 06. 13:00:00	    이현우	  최초 작성
 *-----------------------------------------------------------------------
 */
import exportFromJSON from "export-from-json";
import { isEmpty } from "@/utils";
import moment from "moment";

export default {
  data() {
    return {
      excelUpload: {
        acceptExtention: [".xls", ".xlsx"],
        file: null,
        resultList: []
      }
    };
  },
  methods: {
    /**
     * 파일 Validation
     */
    validCheckExcelFile() {
      if (isEmpty(this.excelUpload.file)) {
        this.alert({
          type: "warning",
          title: this.i18n("label.error"),
          message: this.i18n("alt.attatchGuide1")
        });

        return false;
      }

      if (this.excelUpload.file.size > 1024 * 1024 * 10) {
        this.alert({
          type: "warning",
          title: this.i18n("label.error"),
          message: this.i18n("alt.attatchGuide2", 10)
        });

        return false;
      }

      return true;
    },
    /**
     * 업로드 예정 파일들
     */
    uploadExcelFile() {
      console.log(this.excelUpload.file);
      this.excelUpload.file = this.$refs.excelFile.files[0];
      console.log(this.excelUpload.file);

      return false;
    },

    // 첨부한 엑셀 파일 DB 저장
    saveUploadExcelFile() {
      if (!this.validCheckExcelFile()) {
        return;
      }

      const formData = new FormData();

      if (this.excelUpload.file) {
        // 확장자 를 모두 소문자로 바꿈
        const fileName = `${
          this.excelUpload.file.name.split(".")[0]
        }.${this.excelUpload.file.name.split(".")[1].toLowerCase()}`;

        formData.append("uploadFile", this.excelUpload.file, fileName);
        formData.append("strStckBatUpVO", JSON.stringify(this.stckBatUpVO));
      }

      this.$httpPost("/api/bep/saveStckBatUpWithExcel.do", formData, {
          headers: {
            "Content-Type": "multipart/form-data"
          },
          showLoading: true
        })
        .then(response => {
          if (isEmpty(response.data.resultList)) {
            this.alert({
              type: 'warning',
              title: `${this.i18n("label.info")}`,
              message: this.i18n("alt.allDataSaved")
            });

            return;
          }

          this.alert({
            type: "warning",
            title: `${this.i18n("label.info")}`,
            message: this.i18n("alt.existNotSavedData")
          });

          this.excelUpload.resultList = response.data.resultList;

          exportFromJSON({
            data: this.excelUpload.resultList,
            fileName: `${moment().format(
              "YYYYMMDD HHmmss"
            )}_fail_user_info_data`,
            exportType: "xls"
          });
        })
        .finally(() => {
          this.$refs.excelFile.value = null;
        });
    },
    /**
     * 파일 추가
     */
    excelFileAdd() {
      this.$refs.excelFile.click();
    }
  }
};
