var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "common-pagination" },
    [
      _c("uib-pagination", {
        staticClass: "pagination-sm",
        attrs: {
          "first-text": "",
          "previous-text": "",
          "next-text": "",
          "last-text": "",
          "total-items": _vm.totalItems,
          "max-size": 10,
          "items-per-page": _vm.itemsPerPage,
          "boundary-links": true
        },
        on: { change: _vm.onChange },
        model: {
          value: _vm.model,
          callback: function($$v) {
            _vm.model = $$v
          },
          expression: "model"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }