/**
 * 1. 개요 : 쿠폰배분관리번호검색
 * 2. 처리내용 : 쿠폰배분관리번호검색 Mixins - 화면 모델
 * @File Name : CmnCpnDstrbtMngNoPopupViewModel.js
 * @date : 2022. 08. 04. 17:05:00
 * @author : 이진우
 * @history :
 *-----------------------------------------------------------------------
 * 변경일			          		  작성자		변경내용
 *-----------------------------------------------------------------------
 * 2022. 08. 04. 17:05:00	    이진우	  최초 작성
 *-----------------------------------------------------------------------
 */

//  modify by jwlee 20221004 : 숫자 포매팅
import { amtFormatter } from "@/utils/formatter";
import moment from "moment";

export default {
  methods: {
    CmnCpnDstrbtMngNoPopupViewModel(params = {}) {
      return [
        {
          field: "rnum",
          headerName: "",
          type: Number,
          width: 50,
          isPk: false,
          editable: false,
          pinned: false,
          cellStyle: {
            textAlign: "center"
          },
          cellClassRules: {
            "selected-cell": param =>
              params && param.rowIndex === params.rowIndex
          },
          valueFormatter : (param) => {
            // 내림차순
            return this.gridProps.totalItems - (((this.currPage - 1) * this.pageUnit) + param.node.rowIndex);
          }, 
        },
        {
          field: "cpnCstDstrbtMngNo",
          headerName: this.i18n("label.cpn")+this.i18n("label.pmt")+this.i18n("label.dstrbt")+this.i18n("label.no"),
          type: String,
          width: 140,
          isPk: false,
          editable: false,
          pinned: false,
          suppressMenu: false,
          sortable: true,
          cellStyle: {
            //  modify by jwlee 20221004 : 셀 내 정렬
            textAlign: "center"
          },
          cellClassRules: {}
        },
        {
          field: "cpnDstrbtMngLst",
          headerName: this.i18n("label.dstrbt")+this.i18n("label.mng")+this.i18n("label.history"),
          type: String,
          width: 240,
          isPk: false,
          editable: false,
          pinned: false,
          suppressMenu: false,
          sortable: true,
          cellStyle: {},
          cellClassRules: {}
        },
        {
          field: "dstrbtDtm",
          headerName: this.i18n("label.dstrbt")+this.i18n("label.dtm"),
          type: String,
          width: 210,
          isPk: false,
          editable: false,
          pinned: false,
          suppressMenu: false,
          sortable: true,
          cellStyle: {
            //  modify by jwlee 20221004 : 셀 내 정렬
            textAlign: "center"
          },
          cellClassRules: {}
        },
        {
          field: "totDstrbtAmt",
          headerName: this.i18n("label.tot") + this.i18n("label.dstrbt") + this.i18n("label.amt"),
          type: String,
          // modify by jwlee 20221004 : 셀 사이즈 수정
          width: 110,
          isPk: false,
          editable: false,
          pinned: false,
          suppressMenu: false,
          sortable: true,
          cellStyle: {
            //  modify by jwlee 20221004 : 셀 내 정렬
            textAlign: "right"
          },
          cellClassRules: {},
          //  modify by jwlee 20221004 : 숫자 포매팅
          valueFormatter : (params) => {
            return amtFormatter(params.data.totDstrbtAmt);
          }
        },
        {
          field: "totDstrbtCnt",
          headerName: this.i18n("label.tot") + this.i18n("label.dstrbt") + this.i18n("label.cnt"),
          type: String,
          // modify by jwlee 20221004 : 셀 사이즈 수정
          width: 110,
          isPk: false,
          editable: false,
          pinned: false,
          suppressMenu: false,
          sortable: true,
          cellStyle: {
            //  modify by jwlee 20221004 : 셀 내 정렬
            textAlign: "right"
          },
          cellClassRules: {},
          //  modify by jwlee 20221004 : 숫자 포매팅
          valueFormatter : (params) => {
            return amtFormatter(params.data.totDstrbtCnt);
          }
        },
        {
          field: "extrtStaDt",
          headerName: this.i18n("label.extrtStaDt"),
          type: String,
          width: 125,
          isPk: false,
          editable: false,
          pinned: false,
          suppressMenu: false,
          sortable: true,
          cellStyle: {
            //  modify by jwlee 20221004 : 셀 내 정렬
            textAlign: "center"
          },
          cellClassRules: {},
          // modify by jwlee 20221004 : 날짜 포매팅
          valueFormatter : (params) => {
            return moment(params.data.extrtStaDt).format("YYYY-MM-DD");
          }
        },
        {
          field: "extrtEndDt",
          headerName: this.i18n("label.extrtEndDt"),
          type: String,
          width: 125,
          isPk: false,
          editable: false,
          pinned: false,
          suppressMenu: false,
          sortable: true,
          cellStyle: {
            //  modify by jwlee 20221004 : 셀 내 정렬
            textAlign: "center"
          },
          cellClassRules: {},
          // modify by jwlee 20221004 : 날짜 포매팅
          valueFormatter : (params) => {
            return moment(params.data.extrtEndDt).format("YYYY-MM-DD");
          }
        },
        {
          field: "normalCanYn",
          headerName: this.i18n("label.can")+this.i18n("label.yn"),
          type: String,
          width: 110,
          isPk: false,
          editable: false,
          pinned: false,
          suppressMenu: false,
          sortable: true,
          cellStyle: {
            //  modify by jwlee 20221004 : 셀 내 정렬
            textAlign: "center"
          },
          cellClassRules: {}
        }
      ];
    }
  }
};
