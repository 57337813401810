/**
 * 1. 개요 : Dialog 공통 컴포넌트
 * 2. 처리내용 : Dialog 공통 컴포넌트 - Message
 * @File Name : Message.vue
 * @date : 2020. 02. 20. 09:46:00
 * @author : 이상철
 * @history :
 *-----------------------------------------------------------------------
 * 변경일			          		  작성자		변경내용
 *-----------------------------------------------------------------------
 * 2020. 2. 20. 09:46:00	    이상철	  최초 작성
 *-----------------------------------------------------------------------
 */
<template>
  <component :is="tag">
    <p v-for="(str, index) of msg.split('\\n')" :key="index">
      {{ str }}
    </p>
  </component>
</template>

<script>
export default {
  name: 'Message',
  props: {
    tag: {
      type: String,
      default: 'p',
    },
    message: {
      type: String,
      required: true,
      default: '',
    },
    useI18n: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    /**
     * use-i18n 여부에 따라 message return
     * @return {String}
     */
    msg() {
      if (this.useI18n) return this.i18n(this.message);
      return this.message;
    },
  },
};
</script>

<style lang="scss" scoped>
span:not(:last-child):after{
  content: '\A';
  white-space: pre;
}
</style>

