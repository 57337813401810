export default [
  {
    name: "CpnIssInq",
    path: "/biz/bep/cpn/CpnIssInq",
    component: () => import("@/views/biz/bep/cpn/CpnIssInq"),
    meta: { layout: "admin" }
  },
  {
    name: "CpnUseLstInq",
    path: "/biz/bep/cpn/CpnUseLstInq",
    component: () => import("@/views/biz/bep/cpn/CpnUseLstInq"),
    meta: { layout: "admin" }
  },
  {
    name: "CpnAmtPmtDtl",
    path: "/biz/bep/cpn/CpnAmtPmtDtl",
    component: () => import("@/views/biz/bep/cpn/CpnAmtPmtDtl"),
    meta: { layout: "admin" }
  },
  {
    name: "CpnAmtDstrbtMng",
    path: "/biz/bep/cpn/CpnAmtDstrbtMng",
    component: () => import("@/views/biz/bep/cpn/CpnAmtDstrbtMng"),
    meta: { layout: "admin" }
  },
  {
    name: "MerGrpIdMng",
    path: "/biz/bep/merinst/MerGrpIdMng",
    component: () => import("@/views/biz/bep/merinst/MerGrpIdMng"),
    meta: { layout: "admin" }
  },
  {
    name: "TrmTotAggMng",
    path: "/biz/bep/agg/TrmTotAggMng",
    component: () => import("@/views/biz/bep/agg/TrmTotAggMng"),
    meta: { layout: "admin" }
  }
]