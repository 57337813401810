/**
 * 1. 개요 : 사업자관리
 * 2. 처리내용 : 사업자관리 - 팝업창 태그 컴포넌트(PHB)
 * @File Name : Phb.vue
 * @date : 2020. 02. 20. 09:46:00
 * @author : 이상철
 * @history :
 *-----------------------------------------------------------------------
 * 변경일			          		  작성자		변경내용
 *-----------------------------------------------------------------------
 * 2020. 2. 20. 09:46:00	    이상철	  최초 작성
 *-----------------------------------------------------------------------
 */
<template>
  <div class="tab-content">
		<!-- SearchPane 추가 시작 -->
		<div class="search-wrap">
		<collapse-search-pane :isSearchPaneShow="isSearchPaneShow" @fnShowSearchPane="fnShowSearchPane">
		<div class="search-area">
			<div id="tblInfo01" class="hide">
        {{ 'label.title' | i18n }}, {{ 'label.qnaDt' | i18n }}
        &nbsp;{{ 'label.search' | i18n }}
	    </div>
			<div class="tbl-wrap">
    		<table aria-describedby="tblInfo01">
					<caption>{{ 'label.searchArea' | i18n }}</caption>
					<colgroup>
						<col style="width:150px">
						<col style="width:262px">
						<col style="width:150px">
						<col style="width:262px">
						<col style="width:150px">
						<col style="width:262px">
						<col style="width:150px">
						<col style="width:auto">
					</colgroup>
					<tbody>
						<tr>
							<th scope="row">
								<label for="searchBizClCd">{{ 'label.bizClCd' | i18n }}</label>
							</th>
							<td colspan="2">
								<code-selector
									id="searchBizClCd"
									v-model="search.bizClCd"
									:cdList="cdList.bizClCdList"
									type="text"
									name="searchBizClCd"
								/>
							</td>
							<th scope="row">
								<label for="searchTitle">{{ 'label.title' | i18n }}</label>
							</th>
							<td colspan="2">
								<input-text
									id="searchTitle"
									v-model="search.title"
									type="text"
									name="searchTitle"
									@keydown.enter="onSearchList"
								/>
							</td>

						</tr>
					</tbody>
				</table>
			</div>
		</div>
		</collapse-search-pane>
			<div class="search-btn">
				<button type="button" name="searchList" @click="onSearchList">{{ 'button.look' | i18n }}</button>
				<button type="button" name="clear" @click="clearSearch">{{ 'button.init' | i18n }}</button>
			</div>
		</div>
		<!-- SearchPane 추가 끝 -->
		<div>
		</div>
		<data-table class="tbl003" tblId="tbl003">
				<template slot="tbody" style="text-align:center;">
						<tr class="MyPgTblHeader">
							<th scope="row"></th>
							<th scope="row" colspan="4" style="text-align:center">{{'label.faqTitle'|i18n}}</th>
							<th scope="row"  style="text-align:center">{{'label.bizClCd'|i18n}}</th>
							<th scope="row"  style="text-align:center">{{'label.addedDate'|i18n}}</th>
						</tr>
						<tr v-for="(item,index) in resultList" :key="index">
							<td class="MyPgTd" v-show="item.contents == null"><input type="image" ref="inputIcon" name="toggle" @click="clickTitleShowContents(index, item)" :src='addBtn' alt=""></td>
							<td class="MyPgTd" v-if="item.contents == null" colspan="4"  @click="clickTitleShowContents(index, item)" style="cursor:pointer;">{{item.title}}</td>
							<td class="MyPgTd" v-if="item.contents == null" style="text-align:center">{{bizClCd(item.bizClCd)}}</td>
							<td class="MyPgTd" v-if="item.contents == null" style="text-align:center">{{i18nDtFormat(item.addedDate)}}</td>
							<td v-if="item.contents != null" colspan="7">
								<data-table class="MyPageComplaintTbl004" tblId="tbl004" v-if="show">
									<template slot="tableInfo">
										{{ 'label.title' | i18n }}, {{ 'label.contents' | i18n }}, {{ 'label.readCnt' | i18n }}, {{ 'label.attchFile' | i18n }}, {{ 'label.reply' | i18n }}
										&nbsp;
										{{ 'label.rgt' | i18n }} or
										{{ 'label.change' | i18n }}
									</template>
									<template slot="colgroup">
										<col style="width:150px" />
										<col style="width:auto" />
										<col style="width:150px" />
										<col style="width:auto" />
									</template>
									<template class="MyPageComplaintTbl004Body" slot="tbody">
										<tr>
											<th rowspan="3" class="paddingTh">
												<img src="~@/assets/img/button/icon_q.gif"/>
											</th>
											<td colspan="1" style="overflow:auto;" class="paddingTdTitle">
												<div>{{faqMngVO.title}}</div>
											</td>
											<td colspan="3" class="dtm">
												{{i18nDtFormat(faqMngVO.addedDate)}}
											</td>
										</tr>
									<tr>
										<td colspan="4" class="paddingTdContents">
											<div>
												<div style="min-height:100px; max-height:150px; overflow-y:auto;" v-html="decodeContents"/>
											</div>
										</td>
									</tr>
									<tr>
										<td colspan="4">
											<div class="file-list">
												<span class="file-selected" v-for="file in fileUpload.fileList" :key="file.fileSeqNo" @click="downloadFile(file)">
													<a href="javascript:;" :title="file.orginlFileNm">
													{{ file.orginlFileNm }}
													</a>
												</span>
											</div>
										</td>
									</tr>
									<tr>
										<th rowspan="3" class="paddingTh">
											<img src="~@/assets/img/button/icon_a.gif"/>
										</th>
										<td colspan="1" v-if="faqMngVO.bbsStCd==='10' && faqRplyMngVO.contents!=undefined" style="overflow:auto;" class="paddingTdTitle">
											{{faqRplyMngVO.title}}
										</td>
										<td colspan="3" class="dtm">
											{{i18nDtFormat(faqRplyMngVO.addedDate)}}
										</td>
									</tr>
									<tr>
										<td v-if="faqRplyMngVO.contents!=undefined" colspan="4" class="paddingTdContents">
											<div>
												<div style="min-height:100px; max-height:150px; overflow-y:auto;" v-html="decodeReplyContents"/>
											</div>
										</td>
									</tr>
									<tr>
										<td colspan="4" v-if="faqMngVO.bbsStCd==='10'">
											<div class="file-list">
													<span class="file-selected" v-for="file in fileUpload.rplyFileList" :key="file.fileSeqNo" @click="downloadFile(file)">
														<a href="javascript:;" :title="file.orginlFileNm">
														{{ file.orginlFileNm }}
														</a>
													</span>
												</div>
										</td>
									</tr>
							</template>
						</data-table>
						</td>
					</tr>
					<tr v-if="this.resultList.length==0" class="noArticleTr">
						<td colspan="7" class="MyPgFaqNoArticleTd">
						 {{'alt.noData' | i18n}}
						</td>
					</tr>
				</template>
			</data-table>
			<pagination-for-table
			v-if="this.totalCount>this.pageUnit"
				:totalItems="gridProps.totalItems"
				:initPage="gridProps.initPage"
				:perPage="pageSize"
				@fnListFromPaginationForTable="fnListFromPaginationForTable"
			/>
  </div>
</template>

<script>
import { isEmpty, decodeToHtml } from "@/utils";
import ScrollPane from '@/layout/admin/components/TagsView/ScrollPane';
import Popup from "@/components/Popup";
import { EventBus } from "./EventBus";
import { CmnMyPageFaqViewModel,CodeFetchHelper,Helper, AnimationHelper, FileUpload } from "./mixins";
import DataGrid from "@/components/DataGrid";
import CollapseSearchPane from "@/components/CollapseSearchPane";
import moment from 'moment';
import FileDownRenderer from "./mixins/FileDownRenderer";
import DataTable from "@/components/DataTable";
import PaginationForTable from "@/components/PaginationForTable";
export default {
	name: "MyPagePopupComplaint",
	components: {
		Popup,
		ScrollPane,
		DataGrid,
		CollapseSearchPane,
		DataTable,
		PaginationForTable
	},
	mixins: [CmnMyPageFaqViewModel,CodeFetchHelper,Helper, AnimationHelper, FileUpload],
	props: {
    paramData: {
      type: Object,
      default: () => {}
		},
  },
	data() {
		return {
			height:"150px",
			resultList: [],
			orgResultList: [],
			btnArr : [],
			currPage: 1,
      pageUnit: 10,
			pageSize: 10,
			show:true,
			totalCount:0,
			search: {
				title: "",
				bbsStCd: "",
				orderBy: "",
				bbsTypCd: "",
				acptDtm: "",
				bizClCd: "",
			},
			listYn: false,
			listIndex: "",
			removeBtn: require('@/assets/img/button/ic_remove_black_18dp.png'),
			addBtn: require('@/assets/img/button/ic_add_black_18dp.png'),
			indexNo:"",
			faqMngVO: {
				status: "I",
				bbsTypCd: "ASK",
				bbsTypId: "",
				parBbsId: "",
				title: "",
				contents: "",
				bbsStCd: "",
				dispStaDt: "",
				dispEndDt: "",
				readCnt: "",
				acptUserId: "",
				acptDtm: "",
				hndlUserId: "",
				hndlDtm: "",
				atchFileFkId: "",
				exposeTgt: "YYYYYY",
				addedBy: "",
				addedDate: "",
				addedNm: "",
				modifyBy: "",
				modifyDate: "",
				modifyNm: "",
				blockYn: "",
			},
			faqRplyMngVO: {
				status: "I",
				bbsTypCd: "ASK",
				bbsTypId: "",
				parBbsId: "",
				title: "",
				contents: "",
				bbsStCd: "",
				dispStaDt: "",
				dispEndDt: "",
				readCnt: "",
				acptUserId: "",
				acptDtm: "",
				hndlUserId: "",
				hndlDtm: "",
				atchFileFkId: "",
				exposeTgt: "YYYYYY",
				addedBy: "",
				addedDate: "",
				addedNm: "",
				modifyBy: "",
				modifyDate: "",
				modifyNm: "",
				blockYn: ""
			},
			isSearchPaneShow: true,
			 gridProps: {
        totalItems: 0,
        columnDefs: [],
        rowData: [],
        // 일반적인 row 선택 or 더블클릭시
        selectedRows: [],
        initPage: true,
        editable: true,
        gridHeight: 360,
        rowClassRules: null,
        frameworkComponents: null,
        rowSelection: null,
        // 로우 선택시 선택이 되도록 하려면 false, 체크박스 or 라디오 선택시 선택이 되오도록 하려면 true
        suppressRowClickSelection: false,
        topBtnList: []
      },
      // 일반적인 row 선택 or 더블클릭시
      selectedRow: null,
      // 체크박스 또는 라디오 row 선택시
      selectedCheckOrRadioRows: [],


    };

	},
	 watch: {
    // row 더블 클릭 했을 경우 다시 호출
    selectedRow: {
      handler(params) {
        this.setColumnDefs(params);
        this.setRowClassRules();
      }
    }
  },
  beforeMount() {
    // 그리드 초기화
    this.setColumnDefs();

    // row 클래스 동적 적용
    this.setRowClassRules();

    // 그리드의 frameworkComponents 셋(필요한 경우에만)
    this.setFrameworkComponents();

    // 그리드 상단의 버튼 리스트 정의
    this.setTopBtnList();

    // 그리드의 rowSelection 셋
    this.setRowSelection();
	},
	computed:{
		/**
     * 특수문자가 포함된 문자열을 html 코드로 변환. (공통 함수)
     * @return {String}
     */
		decodeContents() {
		if(this.faqMngVO.contents!= undefined){
				return decodeToHtml(this.faqMngVO.contents)
			} else {
				return '';
			}
		},
		/**
     * 특수문자가 포함된 문자열을 html 코드로 변환. (공통 함수)
     * @return {String}
     */
		decodeReplyContents(){
			if(this.faqRplyMngVO.contents== undefined){
				return decodeToHtml('');
			} else {
				return decodeToHtml(this.faqRplyMngVO.contents)

			}
		},
	},
	mounted() {
		this.searchList();
		this.onSearchList();
  },
  // Pop-up 소명 시에 처리
  beforeDestroy() {

	},
	methods: {
		/**
		 * 업무 구분
		 * @params {Number} key
     */
		bizClCd(key){
			for (let i = 0; i < this.cdList.bizClCdList.length; i++) {
				if (this.cdList.bizClCdList[i].codeId === key) {
					return this.cdList.bizClCdList[i].codeName;
				}
			}
		},
		/**
     * 현재 메뉴에 대한 권한 정보 겟
     * @return {Object}
     */
		// getAuthor() {
    //   return this.$store.getters['Options/userInfo'].menuAuthDetailList.find(a => a.menuNm === this.$route.name)
		// },
		/**
     * 화면 초기화시 데이터 조회
     */
		onSearchList() {
			this.searchList(this.search);
		},
		/**
     * 입력 폼 초기화
     */
		clear() {
			this.search = {
				title: "",
				bbsStCd: "",
				bbsTypCd: "",
				orderBy: "",
				acptDtm: "",
			};
		},
		/**
		 * 입력 폼 초기화 및 재 검색
		 */
		clearSearch() {
			this.clear();
			this.onSearchList();
		},
		 /**
		 * 사용자 목록 조회
		 * @params {String} search
     */
		searchList(search) {
			if (search) {
				this.search = search;
				this.currPage = 1;
				this.gridProps.initPage = true;
			}
			if( !isEmpty(this.search.acptDtm)) {
				this.search.acptDtm = this.search.acptDtm.replace(/[-.]/g, '');
			}
			this.resultList = [];
			this.gridProps.rowData = [];

			this.$httpPost(
					"/api/bep/getQnaMngList.do",
					{
						searchTitle: this.search.title,
						searchBbsStCd: this.search.bbsStCd,
						searchBbsTypCd: "QNA",
						searchAcptDtm: this.search.acptDtm,
						searchParBbsId: 0	,	// 부모 ID 값이 0 인게 최상위 이기 때문..
						searchBizClCd : this.search.bizClCd,
						currPage: this.currPage,
						pageUnit: this.pageUnit,
						pageSize: this.pageSize
					},
					{
						showLoading: true
					}
				)
				.then(response => {
					// result 셋
					this.resultList = response.data.resultList;
					this.gridProps.totalItems = response.data.totalCount;
					this.totalCount =  response.data.totalCount;
				})
				.catch(this.commonErrorHandler);
		},
		/**
     * 특정 글 선택하면 + 버튼 - 버튼으로 변경 및 다른 글 close
     * @params {Number} index, {Object} item
     */
		async clickTitleShowContents(index, item){
			this.btnArr = [];
			this.btnArr = this.$refs.inputIcon;
			//전부 +로 만들고 index에 해당하는 img만 -로 바꿈
			for(var i=0;i<this.btnArr.length; i++){
				this.btnArr[i].src=this.addBtn;
			}
			if(this.listIndex==index){
				//같은 글을 눌렀을 때
				if(this.listYn){
				//컨텐츠 닫아야 할 때
				this.listYn = false;
				this.listIndex=index;
				if(this.resultList[index+1].contents !=null){
						this.resultList.splice(index+1,1);
				}
			} else {
				// 컨텐츠 열어야 할 때
				this.listYn = true;
				this.btnArr[index].src = this.removeBtn;
				this.listIndex=index;
				for(var i=0;i<this.resultList.length;i++){
						if(this.resultList[i].contents !=null){
							this.resultList.splice(i,1);
							if(i<index){
							index=index-1;
						}
					}
				}
				await this.getContents(index, item);
				}
			} else {
				//다른 글을 눌렀을 때
				for(var i=0;i<this.resultList.length;i++){
						if(this.resultList[i].contents !=null){
							this.resultList.splice(i,1);
							if(i<index){
							index=index-1;
						}
					}
				}
				this.listYn = true;
				this.btnArr[index].src = this.removeBtn;
				this.listIndex=index;
				await this.getContents(index, item);
			}
		},
		/**
		 * 선택한 글 단건조회 후 resultList 바인딩
		 * @params {Number} index, {Object} item
		*/
		async getContents(index, item){
			await this.$httpPost(
				"/api/bep/getQnaMng.do",
				{
					searchBbsid: item.bbsId,
				},
				{
					showLoading: true
				}
			)
			.then(response => {
				this.faqMngVO = { ...response.data.resultList[0], status: "U" };

			})
			.then(() => {
				this.resultList.splice(index+1,0,this.faqMngVO)
			})
			.catch(this.commonErrorHandler);

		this.$httpPost(
			"/api/bep/getQnaMngList.do",
				{
					searchBbsTypCd: "QNA",
					searchParBbsId: item.bbsId,
				},
				{
					showLoading: true
				}
			)
			.then(response => {
				if(isEmpty(response.data.resultList[0])){
					this.faqRplyMngVO = { ...response.data.resultList[0], status: "I" };
				} else {
					this.faqRplyMngVO = { ...response.data.resultList[0], status: "U" };
				}

				!isEmpty(this.faqRplyMngVO.atchFileFkId != undefined) && this.getRplyFileList(this.faqRplyMngVO.atchFileFkId);
				})
				.catch(this.commonErrorHandler);
		},
		/**
     * 페이지 변환시 데이터 조회 함수
     * @params {Number} currPage
    */
    fnList(currPage) {
      this.currPage = currPage;
      this.searchList();
      this.gridProps.initPage = false;
    },
    /**
		 * 더블 클릭시 팝업 열기 함수
     * @params {Object} selectedRow
		 */
		fnShowPopup(selectedRow) {
			this.selectedRow = null
			this.gridProps.selectedRows = []
			this.selectedRow = selectedRow;
			this.gridProps.selectedRows = [{ ...selectedRow.data, status: "U" }];
		},
    /**
		 * 그리드에 하나의 row 선택 함수
		 * @params {Object} params
		 */
    setSelectionChangedRow(params) {
      this.gridProps.selectedRows = [];
      this.gridProps.selectedRows = [
        { ...params.api.getSelectedRows()[0], status: "U" }
      ];
    },
    /**
		 * 그리드의 columnDefs 셋
		 * @params {Object} params
		 */
    setColumnDefs(params = {}) {
      this.gridProps.columnDefs = this.cmnMyPageFaqViewModel(params).map(
        a => ({
          headerName: a.headerName,
          field: a.field,
          width: a.width,
          editable: a.editable,
          pinned: a.pinned,
          cellStyle: a.cellStyle,
          cellClassRules: a.cellClassRules,
          valueFormatter: a.valueFormatter,
          cellRenderer: a.cellRenderer,
          checkboxSelection: a.checkboxSelection,
          headerCheckboxSelection: a.headerCheckboxSelection,
          suppressMenu: a.suppressMenu,
          sortable: a.sortable,
          hide: a.hide
        })
      );
    },
		/**
		 * 그리드 상단의 버튼 리스트 정의
		*/
    setTopBtnList() {},
		/**
		 * 그리드의 rowClass 셋
		*/
    setRowClassRules() {
      this.gridProps.rowClassRules = {
        // 'selected-row': param => this.selectedRow && (this.selectedRow.rowIndex === param.rowIndex)
      };
    },

    // 그리드의 frameworkComponents 셋
    setFrameworkComponents() {
      this.gridProps.frameworkComponents = {
				FileDownRenderer
			};
    },
    /**
		 * 그리드의 하나의 rowSelection 셋
		 */
    setRowSelection() {
      // 단수 선택 경우 'single', 복수 선택일 경우 'multiple'
      this.gridProps.rowSelection = "single";
    },
		/**
		 * 팝업 닫기 함수
		 * @params {Object} params
		 */
		close(params) {
			this.gridProps.selectedRows = [];
			this.selectedRow = null;

			if( !isEmpty(params) && !isEmpty(params.refresh) && params.refresh ) {
			if( !isEmpty(params.currPage) ) {
				this.currPage = params.currPage;
				this.gridProps.initPage = true;
			}
				this.searchList();
			}
		},
		/**
		 * pagination-for-table에서 페이지 변환시 데이터 조회 함수
		 * @params {Number} currPage
		 */
		fnListFromPaginationForTable(currPage){
			this.currPage = currPage;
			this.searchList();
			this.gridProps.initPage = false;
		}
	}
};
</script>
<style lang="scss">
</style>
<style lang="scss" scoped>

@import "@/scss/_button.scss";
$scroll-color:#bcd5ed;
.pc{
  .tab-content{
		margin-top:25px;
		.cont{
			overflow: hidden;
			overflow-y: auto;
			min-height:100px;
			max-height:450px;
			&::-webkit-scrollbar{
				&:horizontal{
					border-top-color:transparent;
				}
				&:vertical{
					border-left-color:transparent;
				}
			}
		}
	}
	.search-wrap{
		margin-top:50px;
	}
}
.MyPgTblHeader th{
	border: 1px solid #bcd6ed;
	border-top: 0;
	border-bottom: 0;
	border-right: 0;
	height: 30px !important;
	font-size: 12px !important;
}
.MyPgTblHeader th:last-of-type{
	border: 1px solid #bcd6ed;
	border-top: 0;
	border-bottom: 0;
	height: 30px !important;
	font-size: 12px !important;
}
.MyPgTblHeader th:first-of-type{
	width: 41px !important;
}
.MyPgTd{
	border: 1px solid #bcd6ed;
	border-top: 0;
	border-right: 0;
	height: 30px !important;
	font-size: 12px !important;
}
.MyPgTd:last-of-type{
	border: 1px solid #bcd6ed;
	border-top: 0;
	height: 30px !important;
	font-size: 12px !important;
}
.MyPageComplaintTbl004{
	border-top: none;
	background-color: #F6F6F6;
}
.MyPageComplaintTbl004 tr{
	border: none;
}
.MyPageComplaintTbl004 tr td{
	border: none;
}
.MyPageComplaintTbl004 table tbody tr{
	padding-left: 30px;
}
.quill-editor{
	background-color: white;
}
.MyPgComplaintToggleBtn{
	background-color: white !important;
}
.paddingTd{
	padding:0 50px !important;
	border: none;
}
.paddingTdTitle{
	padding:20px 10px;
	border: none;
	font-weight: bold;
}
.paddingTh{
	border: none;
	background-color: #F6F6F6;
	vertical-align: top;
	padding: 20px 10px;
}
.paddingTh img{
	background-color: #F6F6F6;
	float: right;
}
.dtm{
	font-size: 11px;
}
.paddingTdContents{
	padding-right: 50px;
}
.MyPgFaqNoArticleTd{
	border-top: 1px solid #bcd6ed;
	border-left: 1px solid #bcd6ed;
	border-right: 1px solid #bcd6ed;
	text-align: center;
	height: 150px;
}
</style>