import axios from "axios";
import store from "@/store";
import flatten from "tree-flatten";
import { isEmpty, decodeToHtml } from "@/utils";

export default {
  async fetchCommonCode() {
    this.isCommonCodeFetching = true;
    if (sessionStorage.getItem("reloaded-alert") != null) {
      await this.$httpPost(
        "/api/com/updateLoginYn.do",
        {
          loginYn: "Y"
        },
        { showLoading: false }
      ).catch(this.commonErrorHandler);
    }
    axios
      .all([
        this.$httpPost(
          "/api/com/getCodeList.do",
          {
            msgLangCd: this.msgLangCd 
          },
          { showLoading: true }
        ).then(res => {
          console.log("res ++ ",res);
          
          this.$store.commit(
            "CommonCode/SET_COMMON_CODE_LIST",
            res.data.resultList
          );
        }),
        this.$httpPost(
          "/api/com/getCodeGroupList.do",
          {},
          { showLoading: true }
        ).then(res => {
          this.$store.commit(
            "CommonCode/SET_COMMON_CODE_GROUP_LIST",
            res.data.resultList
          );
        }),
        this.$httpPost(
          "/api/com/getUserMenuTree.do",
          {},
          { showLoading: true }
        ).then(res => {
          const userInfo = this.$store.getters["Options/userInfo"];

          if (!isEmpty(res.data.resultData.children)) {
            // // 사용자 메뉴 권한이 Y인 경우 사용자 메뉴관리 제외
            // const menuTree =
            //   userInfo.userMenuAuthorYn === "N"
            //     ? res.data.resultData.children
            //     : res.data.resultData.children.map(a => ({
            //         ...a,
            //         children: a.children.filter(b => b.id !== 15 && b.id !== 14)
            //       }));

            // const menuList =
            //   userInfo.userMenuAuthorYn === "N"
            //     ? flatten(res.data.resultData, "children")
            //     : flatten(res.data.resultData, "children").filter(
            //         a => a.id !== 15 && a.id !== 14
            //       );
            const menuTree = res.data.resultData.children;

            const menuList = flatten(res.data.resultData, "children");

            if (!isEmpty(menuTree)) {
              this.$store.commit(
                "CommonCode/SET_MENU_TREE",
                menuTree.filter(a => a.id !== 10000)
              );
              this.$store.commit(
                "CommonCode/SET_MENU_LIST",
                menuList.filter(a => a.id !== 10000 || a.upId !== 10000)
              );
              this.$store.commit(
                "CommonCode/SET_MOBILE_MENU_TREE",
                menuTree.filter(a => a.id === 10000)
              );
              this.$store.commit(
                "CommonCode/SET_MOBILE_MENU_LIST",
                menuList.filter(a => a.id === 10000 || a.upId === 10000)
              );
            }

            this.$store.commit("Options/SET_USER_INFO", {
              menuAuthDetailList: [
                ...res.data.resultData.children.map(a => ({
                  menuId: a.id,
                  menuNm: a.name,
                  readOnlyYn: a.readOnlyYn,
                  excelExportYn: a.excelExportYn
                })),
                ...res.data.resultData.children
                  .map(a => (isEmpty(a.children) ? [] : a.children))
                  .flat()
                  .map(a => ({
                    menuId: a.id,
                    menuNm: a.name,
                    readOnlyYn: a.readOnlyYn,
                    excelExportYn: a.excelExportYn
                  }))
              ]
            });
          }
        }),
        this.$httpPost(
          "/api/com/getResourceList.do",
          { currPage: -1 },
          { showLoading: true }
        ).then(res => {
          this.$store.commit("I18n/SET_MESSAGE_LIST", res.data.resultList);
        })
      ])
      .then(
        axios.spread(() => {
          this.updateLang();
          this.isCommonCodeReady = true;

          if (store.getters["Options/userInfo"].initPassYn === "Y") {
            this.alert({
              type: "warning",
              title: this.i18nOff("label.confirm"),
              message: this.i18nOff("alt.pleaseChangePassword"),
              buttons: [{ label: this.i18n("label.confirm") }]
            });
            this.logoutWithServer();
          }

          this.getConnDevice === "D"
            ? this.$router.replace("/layout")
            : this.$router.replace("/m/dashboard");
        })
      )
      .catch(error => {
        this.isCommonCodeFail = true;
        this.commonErrorHandler(error);
      })
      .finally(() => {
        this.isCommonCodeFetching = false;
      });
  },
  setCompanyClass() {
    document.body.classList.remove("cms");
    document.body.classList.add(this.company);
  },

  /**
   * 쿠키를 확인해서 토큰 정보를 갱신하고 유저 정보를 스토어에 저장
   */
  login() {
    if (this.isLogin) return;

    const spaToken = this.loginToken || this.$cookie.get("spaToken");
    this.$cookie.delete("spaToken");

    if (spaToken) {
      // 토큰갱신(api 콜) & 세션스토리지 저장
      this.$httpPost(
        "/api/com/getTokenInfo.do",
        {},
        {
          headers: {
            accesstoken: spaToken
          }
          // param: {}
        }
      )
        .then(res => {
          const { accesstoken } = res.headers;

          const userInfo = res.data.resultData;
          userInfo.accesstoken = accesstoken;

          store.commit("Options/SET_USER_INFO", userInfo);
        })
        .catch(this.commonErrorHandler);

      return;
    }

    if (
      this.$router.currentRoute.fullPath === "/login" ||
      this.$router.currentRoute.fullPath === "/m/login"
    )
      return;

    this.logout();
  },

  goFirstPage() {
    const userInfo = this.$store.getters["Options/userInfo"];
    const authorities = userInfo.authorities[0];
    const { pathname } = window.location;

    let menuList = this.$store.getters["CommonCode/getMenuList"]();
    menuList = menuList.filter(item => item.lvl === 2);

    this.$router.replace({
      path: menuList[0].path,
      name: menuList[0].name,
      params: {
        parentId: menuList[0].upId,
        menuId: menuList[0].Id
      }
    });
  },

  updateLang() {
    switch (this.msgLangCd) {
      case "ko_KR":
        document.documentElement.lang = "ko";
        break;
      case "en_US":
        document.documentElement.lang = "en";
        break;
      case "vi_VN":
        document.documentElement.lang = "vi";
        break;
      case "jp_JP":
        document.documentElement.lang = "jp";
        break;
      default:
        document.documentElement.lang = "en";
    }
  },

  setInitConnDevice() {
    const savedDeviceCd = this.getDeviceCd();
    if (!isEmpty(savedDeviceCd)) {
      this.$store.dispatch("Device/setDeviceCd", savedDeviceCd);
      return;
    }

    const {
      isChrome,
      isChromeIOS,
      isEdge,
      isFirefox,
      isIE,
      isIOS,
      isOpera,
      isSafari,
      meta
    } = this.browserDetect();

    if (
      (isChrome && !meta.ua.includes("Android")) ||
      isEdge ||
      isFirefox ||
      isIE ||
      isOpera ||
      isSafari
    )
      this.setDeviceCd("D");
    else if (isChromeIOS || isIOS || (isChrome && meta.ua.includes("Android")))
      this.setDeviceCd("M");
    else this.setDeviceCd("D");
  },

  browserCheck() {
    const {
      isChrome,
      isChromeIOS,
      isEdge,
      isFirefox,
      isIE,
      isIOS,
      isOpera,
      isSafari,
      isEdgeChromium,
      meta
    } = this.browserDetect();

    // if (!(isChrome || isChromeIOS || isEdgeChromium)) {
    //   this.$router.replace("/404");
    //   alert(this.i18nOff("alt.notSupportedBrowser"));
    //   if (navigator.appVersion.indexOf("MSIE 6.0") >= 0) {
    //     parent.window.close();
    //   } else {
    //     parent.window.open("about:blank", "_self").close();
    //   }
    // }
  },

  browserDetect() {
    let ua = window.navigator.userAgent;
    let browserObj = {};

    // Opera 8.0+ (UA detection to detect Blink/v8-powered Opera)
    browserObj.isOpera =
      !!window.opera || navigator.userAgent.indexOf(" OPR/") >= 0;
    // Firefox 1.0+
    browserObj.isEdge = /Edge/.test(navigator.userAgent);
    browserObj.isFirefox = /Firefox/.test(navigator.userAgent);
    // Safari 3.0+
    /*eslint-disable */
    browserObj.isSafari =
      /constructor/i.test(window.HTMLElement) ||
      (function(p) {
        return p.toString() === "[object SafariRemoteNotification]";
      })(!window["safari"] || safari.pushNotification);
    /*eslint-ensable */
    // Internet Explorer 6-11
    browserObj.isIE = /*@cc_on!@*/ false || !!document.documentMode;
    // Edge 20+

    browserObj.isChrome = /Google Inc/.test(navigator.vendor);
    browserObj.isChromeIOS = /CriOS/.test(navigator.userAgent);
    browserObj.isIOS =
      /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

    browserObj.isEdgeChromium =
      browserObj.isChrome && navigator.userAgent.indexOf("Edg") != -1;

    browserObj.meta = browserSpecs();
    browserObj.meta.ua = ua;

    function browserSpecs() {
      /**
       * https://stackoverflow.com/questions/5916900/how-can-you-detect-the-version-of-a-browser
       */
      var tem,
        M =
          ua.match(
            /(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i
          ) || [];
      if (/trident/i.test(M[1])) {
        tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
        return { name: "IE", version: tem[1] || "" };
      }
      if (M[1] === "Chrome") {
        tem = ua.match(/\b(OPR|Edge)\/(\d+)/);
        if (tem != null)
          return { name: tem[1].replace("OPR", "Opera"), version: tem[2] };
      }
      M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, "-?"];
      if ((tem = ua.match(/version\/(\d+)/i)) != null) M.splice(1, 1, tem[1]);
      return { name: M[0], version: M[1] };
    }

    return browserObj;
  }
};
