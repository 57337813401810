var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "tags-view-container",
      attrs: { id: "tags-view-container" }
    },
    [
      _c(
        "scroll-pane",
        { ref: "scrollPane", staticClass: "tags-view-wrapper" },
        _vm._l(_vm.visitedViews, function(tag) {
          return _c(
            "router-link",
            {
              key: tag.name,
              ref: "tag",
              refInFor: true,
              staticClass: "tags-view-item",
              class: _vm.isActive(tag) ? "active" : "",
              attrs: { to: { path: tag.path }, tag: "span", title: tag.title },
              nativeOn: {
                mouseup: function($event) {
                  if ("button" in $event && $event.button !== 1) {
                    return null
                  }
                  return _vm.closeSelectedTag(tag)
                },
                contextmenu: function($event) {
                  $event.preventDefault()
                  return _vm.openMenu(tag, $event)
                }
              }
            },
            [
              _vm._v(" " + _vm._s(tag.title) + " "),
              tag.title == "dashboard"
                ? _c("span", { staticClass: "home" })
                : _vm._e(),
              !tag.meta.affix
                ? _c("span", {
                    staticClass: "sp-b i-close",
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        $event.stopPropagation()
                        return _vm.closeSelectedTag(tag)
                      }
                    }
                  })
                : _vm._e()
            ]
          )
        }),
        1
      ),
      this.userAuth.userId != "pcidss_office" &&
      this.userAuth.userId != "pcidss_merchant" &&
      this.userAuth.userId != "pcidss_agency" &&
      this.userAuth.userId != "pcidss_bank" &&
      this.userAuth.userId != "pcidss_office1" &&
      this.userAuth.userId != "pcidss_merchant1" &&
      this.userAuth.userId != "pcidss_agency1" &&
      this.userAuth.userId != "pcidss_bank1"
        ? _c("div", [
            _c(
              "button",
              {
                staticClass: "tab-txt-close",
                attrs: { type: "button" },
                on: {
                  click: function($event) {
                    return _vm.closeAllTags(_vm.selectedTag)
                  }
                }
              },
              [_vm._v("Close All")]
            )
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }