<template>
	<aside class="side-menu-wrap">
		<div class="side-menu">
			<div class="side-top">
				<!-- case : 고객이 이미지를 등록한 경우 : background-image:url("경로"); 이렇게 주시면 됩니다.
				-->
				<img class="profile" alt="profile photo" width="45" height="45" :src="psnImage" />
				<p class="profile-txt">{{ psnNm }}</p>
			</div>
			<div class="side-cont">
				<ul class="el-menu">
					<li
						v-for="route in routes"
						:key="route.id"
						:index="route.name"
						:route="route"
						:class="{'setting sp-b' : route.icon === 'setting'}"
					>
					<a href="javascript:;" v-if="route" slot="title" @click="clickMenu(route)">{{ route.title }}</a>
					</li>
				</ul>
			</div>
			<a href="javascript:;" class="side-close" @click="toggleSideBar">side-menu close</a>
		</div>
	</aside>
</template>

<script>
import { mapState } from "vuex";
import variables from "@/styles/variables.scss";
import { isEmpty } from "@/utils";
import moment from "moment";

export default {
	name: "MSidebar",
	data() {
		return {
			psnNm: "",
			psnImage: require('@/assets/img/profile.gif'),
			isShowListMenu: true
		}
	},
	computed: {
		...mapState({
			sidebar: state => state.Sidebar.sidebar
		}),
		routes() {
			const menuTitle = [
				// '사업자조회',
				// '포인트거래조회',
				// '정산달력',
				// '영업일지조회/등록/변경',
				'*조회/수정(예시)',
				'*Inputs(예시)'
			]
			return [...this.getMobileMenuList().filter(a => a.id !== 10000), 
				// ...menuTitle.map(a => {
				// 	return { title: a }
				// })
			]
		},
		variables() {
			return variables;
		},
	},
	mounted() {
		this.getPsnDetail()
	},
	methods: {
		getPsnDetail() {    
			this.$httpPost("/api/bep/getPsnMngDetail.do", { searchPsnId: this.$store.getters['Options/userInfo'].psnId }, { showLoading: true })
				.then(response => {
						this.psnNm = this.msgLangCd === "ko_KR" ? response.data.resultList[0].psnNm : response.data.resultList[0].psnEnm
						//TODO: 사용자 이미지 바인딩 해야됨
				})
				.catch(this.commonErrorHandler);
		},
		// 사이드바에서 X를 클릭하면 실행되는 메소드
		toggleSideBar() {
			// 햄버거 메뉴 닫힐 때 body에 style 비우기
			document.querySelector('body').style = '';

			this.$store.dispatch("Sidebar/toggleSideBar");
		},
		// 메뉴명을 눌렀을 때 실행되는 메소드
		clickMenu(route) {			
			if(route.title === '등록화면') {
				this.$router.push({ path: "/m/biz/Reg"})
			}
			if(route.title === '*조회/수정(예시)') {
				this.$router.push({ path: "/m/biz/Look2"})
			}
			if(route.title === '*Inputs(예시)') {
				this.$router.push({ path: "/m/biz/Inputs"})
			}

			const findRoute = this.getMobileMenuList().find(a => a.path === route.path);
			if(!isEmpty(findRoute)) this.$router.push({ path: findRoute.path })
			// else return;

			this.$nextTick(() => this.$store.dispatch("Sidebar/closeSideBar", { withoutAnimation: false }));

		},
		goToSetting(){
			this.$router.push({ path: "/m/biz/Setting"})
		}
	}
};
</script>
<style lang="scss" scoped>
@import "~@/scss/mobile/_m-icon.scss";
.mobile{
	.side-menu-wrap{
		overflow:hidden;
		position:fixed;
		display:none;
		top:0;
		left:0;
		right:0;
		bottom:0;
		width:100%;
		height:100%;
		opacity: 0;
		background-color:rgba(0,0,0,0.5);
		z-index:1003;
		.side-menu{
			position:relative;
			height:100%;
			background-color:#fff;
			.side-top{
				padding:10px 50px 8px 20px;
				border-bottom:solid 1px #666;
				.profile{
					display: inline-block;
					border:solid 1px #666;
					border-radius:50%;
					vertical-align: middle;
				}
				.profile-txt{
					overflow:hidden;
					display:inline-block;
					width:calc(100% - 55px);
					max-height:45px;
					margin-left:10px;
					font-size:16px;
					color:#333;
					line-height:22px;
					font-weight:bold;
					vertical-align: middle;
				}
			}
			.side-cont{
				overflow-y:auto;
				height:calc(100vh - 140px); // 원래 - 63px
			}
			.side-close{
				position:absolute;
				top:0;
				right:0;
				width:55px;
				height:63px;
				font-size:0;
				&::before,
				&::after{
					content:"";
					position:absolute;
					top:33px;
					right:17px;
					width:26px;
					height:1px;
					background-color:#002a50;
					transform:rotate(45deg);
				}
				&::after{
					transform:rotate(-45deg);
				}
			}
		}
		
		.el-menu{
			width:0;
			animation: sideshow 0.4s;
		}

		&.openSidebar{
			display:block;
			opacity:1;
			.side-menu{
				width:300px;
				animation: sideshow 0.4s;
			}
			.el-menu{
				width:100%;
				animation: sideshow 0.4s;
			}
		}

		.setting{
			&::before{
				width:17px;
				height:19px;
				margin-right:5px;
				background-position:-50px 0;
			}
			a{
				width:calc(100% - 30px);
			}
		}

	}
}
</style>
<style lang="scss">

	@keyframes sideshow {
		0%{
			width:0;
			opacity:0;
		}
		100%{
			transition:width, opacity;
		}
	}
</style>
