/**
 * 1. 개요 : GridRenderer 공통 파일
 * 2. 처리내용 : GridRenderer 공통 파일 - FileDownRenderer
 * @File Name : FileDownRenderer.js
 * @date : 2020. 03. 18. 09:46:00
 * @author : 이지혜
 * @history :
 *-----------------------------------------------------------------------
 * 변경일			          		  작성자		변경내용
 *-----------------------------------------------------------------------
 * 2020. 03. 18. 09:46:00	    이지혜	  최초 작성
 *-----------------------------------------------------------------------
 */
import Vue from "vue";

export default Vue.extend({
  data(){
    return {
      fileList: []
    }
  },
  mounted() {
    this.fileList = this.params.value
  },
  methods: {
    /**
     * 클릭시 부모에 emit을 보냄
     * params {Object} file
     */
    downloadFile(file) {
      this.$httpPost('/api/com/downloadFile.do', {
        searchAtchFileFkId: file.atchFileFkId,
        searchFileSeqNo: file.fileSeqNo,
      }, {
        responseType: 'blob'
      }).then((res) => {
        if (navigator.msSaveBlob) {
          const blob = new Blob([res.data], { type: 'application/octet-stream;charset=utf-8;' });
          navigator.msSaveBlob(blob, `${file.orginlFileNm}`);
        } else if (navigator.appName === 'Microsoft Internet Explorer') {
          const frame = document.createElement('iframe');
          document.body.appendChild(frame);

          frame.contentWindow.document.open('application/octet-stream;charset=utf-8;', 'replace');
          frame.contentWindow.document.write(`sep=,\r\n${res.data}`);
          frame.contentWindow.document.close();
          frame.contentWindow.focus();
          frame.contentWindow.document.execCommand('SaveAs', false, `${file.orginlFileNm}`);

          document.body.removeChild(frame);
        } else {
          const blob = new Blob([res.data], { type: 'application/octet-stream;charset=utf-8;' });
          const link = document.createElement('a');
          // feature detection
          if (link.download !== undefined) {
            // Browsers that support HTML5 download attribute
            const url = URL.createObjectURL(blob);
            link.setAttribute('href', url);
            link.setAttribute('download', `${file.orginlFileNm}`);
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          }
        }
      }).catch(this.commonErrorHandler)
    },
  },
  template: `
  <div class="grid-file-list">
    <span v-if="fileList.length > 0" v-for="file in fileList" :key="file.fileSeqNo" @click="downloadFile(file)">
      <a class="grid-more" href="javascript:;" :title="file.orginlFileNm">
        {{ file.orginlFileNm }}
      </a>
    </span>  
  </div>
  `
})