var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "pagi-wrap" }, [
    _vm.totalYn
      ? _c("div", { staticClass: "total" }, [
          _vm._v(
            " " +
              _vm._s(_vm._f("i18n")("label.totalCnt")) +
              ": " +
              _vm._s(_vm._f("numberFormat")(_vm.totalItems)) +
              " "
          )
        ])
      : _vm._e(),
    _c(
      "div",
      { staticClass: "pagi" },
      [
        _c("pagination", {
          attrs: {
            "total-items": _vm.totalItems,
            "max-size": _vm.perPage,
            "items-per-page": _vm.perPage
          },
          on: {
            change: function($event) {
              return _vm.$emit(
                "fnListFromPaginationForTable",
                _vm.pagination.currentPage
              )
            }
          },
          model: {
            value: _vm.pagination,
            callback: function($$v) {
              _vm.pagination = $$v
            },
            expression: "pagination"
          }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }