/**
 * 1. 개요 : Inputs 공통 컴포넌트
 * 2. 처리내용 : Inputs 공통 컴포넌트 - InputAmt
 * @File Name : index.vue
 * @date : 2020. 08. 25. 16:32:00
 * @author : 이지혜
 * @history :
 *-----------------------------------------------------------------------
 * 변경일			          		  작성자		변경내용
 *-----------------------------------------------------------------------
 * 2020. 2. 20. 09:46:00	    이지혜	  최초 작성
 *-----------------------------------------------------------------------
 */
<template>
	<div class="input-text text-box">
		<input
			ref="inputAmt"
			type="text"
			v-model="displayValue"
			class="number-type"
			@blur="isInputActive = false"
			@focus="isInputActive = true"
			:placeholder="placeholder"
			:maxlength="maxlength"
			:onkeydown="isNumberType"
			:disabled="disabled"
		/>
	</div>
</template>
<script>
import { isEmpty } from '@/utils';
export default {
	name: "InputAmt",
	props: {
		value: {
			type: [String, Number],
			default: null
		},
		placeholder: {
			type: String,
			default: ""
		},
		maxlength: {
			type: Number,
			default: null
		},
		disabled:{
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			isInputActive: false
		};
	},
	computed: {
		// 데이터를 읽어들일 때는 get(), 사용자가 내용을 입력할 때는 set()
		displayValue: {
			get() {
				if (this.isInputActive) {
					return this.value.toString();
				} else {
					return this.value
						.toString()
						.replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,"); // 3자리 마다 콤마.
				}
			},
			set(modifiedValue) {
				if(!isEmpty(this.maxlength)) {
					modifiedValue.slice(0, this.maxlength) // maxlength 설정이 되어있으면 자릿수를 자름.
				}
				let newValue = parseFloat(modifiedValue.replace(/[^\d\.]/g, "")); // 콤마 제거.
				if (isNaN(newValue)) {
					newValue = 0;
				}

				this.$emit("input", newValue);
			}
		}
	},
	methods: {
		focus() {
			this.$refs.inputAmt && this.$refs.inputAmt.focus()
		}
	}
};
</script>

<style lang="scss" scoped>
@import "@/scss/_color.scss";
@import "@/scss/_input.scss";
.number-type {
	text-align: right;
}
</style>