var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "footer",
    { staticClass: "copyright" },
    [
      _c("p", [
        _vm._v(
          "COPYRIGHT " + _vm._s(_vm.toYear) + " Alliex, ALL RIGHTS RESERVED"
        )
      ]),
      _c("router-link", { attrs: { to: "/dashboard" } }, [
        _c(
          "button",
          { attrs: { type: "button" }, on: { click: _vm.chgDevice } },
          [_vm._v("PC version")]
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }