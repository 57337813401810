var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "button",
    {
      class: _vm.classes,
      attrs: { type: _vm.type || "button" },
      on: {
        click: function($event) {
          $event.stopPropagation()
          return _vm.$emit("click")
        },
        mouseover: _vm.mouseover,
        mouseout: _vm.mouseout
      }
    },
    [
      _vm.leftIconPath
        ? _c("img", {
            staticClass: "button-left-icon",
            attrs: { src: _vm.leftIconPath, alt: "" }
          })
        : _vm._e(),
      _vm._t("default"),
      _vm.rightIconPath
        ? _c("img", {
            staticClass: "button-right-icon",
            attrs: { src: _vm.rightIconPath, alt: "" }
          })
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }