/**
 * 1. 개요 : 사업자관리
 * 2. 처리내용 : 사업자관리 Mixins - 파일 관련 helper
 * @File Name : FileUpload.js
 * @date : 2020. 02. 20. 09:46:00
 * @author : 이상철
 * @history :
 *-----------------------------------------------------------------------
 * 변경일			          		  작성자		변경내용
 *-----------------------------------------------------------------------
 * 2020. 2. 20. 09:46:00	    이상철	  최초 작성
 *-----------------------------------------------------------------------
 */
import exportFromJSON from 'export-from-json'
import moment from 'moment';
import { CmnPsnPopupViewModel } from "./";

const excelDownCnt = process.env.VUE_APP_EXCEL_DOWN_CNT;

export default {
  data() {
    return {
      excelDownload: {
        currPage: 1,
        resultList: [],
        pageUnit: excelDownCnt,
        pageSize: excelDownCnt
      },
    }
  },
  computed: {
		gridTitle() {
			return "그리드 이름"
		}
	},
  mixins: [CmnPsnPopupViewModel],
  methods: {
    /**
     * 엑셀 다운로드 함수
     */
		fnExcelDown() {
			this.$httpPost(
					"/api/bep/getBizMngListForExcelDown.do",
					{
						searchBizNo: this.search.bizNo,
						searchBizNm: this.search.bizNm,
						currPage: this.excelDownload.currPage,
						pageUnit: this.excelDownload.pageUnit,
						pageSize: this.excelDownload.pageSize
					},
					{
						showLoading: true
					}
				)
				.then(response => {
          this.excelDownload.resultList = response.data.resultList

          const data = this.excelDownload.resultList.map(a => {
            const newObj = {};
            const viewModel = this.CmnPsnPopupViewModel();

            Object.entries(a).forEach(aa => {
              const found = viewModel.find(aaa => aa[0] === aaa.field)
              if(found) newObj[found.headerName] = aa[1]
            })

            return newObj
          })

          exportFromJSON({
            data,
            fileName: `${moment().format('YYYYMMDD HHmmss')}_${this.gridTitle}`,
            exportType: 'xls'
          })
        })
        .catch(this.commonErrorHandler)
    },
  }
}