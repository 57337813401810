var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "h1",
    { staticClass: "logo" },
    [
      _c(
        "router-link",
        {
          key: "expand",
          staticClass: "logo-link",
          attrs: { to: "/m/dashboard" },
          on: { click: _vm.clickLogo }
        },
        [
          _vm._v(" " + _vm._s(_vm.title) + " "),
          _vm.logo
            ? _c("img", {
                attrs: {
                  src: _vm.logo,
                  alt: "logo image",
                  width: "84.5",
                  height: "21"
                }
              })
            : _vm._e()
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }