/**
 * 1. 개요 : CommonCode
 * 2. 처리내용 : CommonCode - 스토어
 * @File Name : CommonCode.js
 * @date : 2020. 02. 20. 09:46:00
 * @author : 이상철
 * @history :
 *-----------------------------------------------------------------------
 * 변경일			          		  작성자		변경내용
 *-----------------------------------------------------------------------
 * 2020. 2. 20. 09:46:00	    이상철	  최초 작성
 *-----------------------------------------------------------------------
 */
export default {
  state: {
    codeList: [],
    bizCodeList: [],
    codeGroupList: [],
    menuList: [],
    menuTree: {},
    mobileMenuList: [],
    mobileMenuTree: {}
  },
  namespaced: true,
  mutations: {
    SET_COMMON_CODE_LIST(state, codeList) {
      state.codeList = [...codeList];
    },
    SET_COMMON_BIZ_CODE_LIST(state, bizCodeList) {
      state.bizCodeList = [...bizCodeList];
    },
    SET_COMMON_CODE_GROUP_LIST(state, codeGroupList) {
      state.codeGroupList = [...codeGroupList];
    },
    SET_MENU_LIST(state, menuList) {
      state.menuList = [...menuList];
    },
    SET_MENU_TREE(state, menuTree) {
      state.menuTree = menuTree;
    },
    SET_MOBILE_MENU_LIST(state, mobileMenuList) {
      state.mobileMenuList = [...mobileMenuList];
    },
    SET_MOBILE_MENU_TREE(state, mobileMenuTree) {
      state.mobileMenuTree = mobileMenuTree;
    }
  },
  actions: {
    setCommonCodeList(state, param) {
      commit("SET_COMMON_CODE_LIST", param);
    },
    setCommonBizCodeList(state, param) {
      commit("SET_COMMON_BIZ_CODE_LIST", param);
    },
    setCommonCodeGroupList(state, param) {
      commit("SET_COMMON_CODE_GROUP_LIST", param);
    },
    setMenuList(state, param) {
      commit("SET_MENU_LIST", param);
    },
    setMenuTree(state, param) {
      commit("SET_MENU_TREE", param);
    },
    setMobileMenuList(state, param) {
      commit("SET_MOBILE_MENU_LIST", param);
    },
    setMobileMenuTree(state, param) {
      commit("SET_MOBILE_MENU_TREE", param);
    }
  },
  getters: {
    getCommonCodeList: state => codeGrpId => {
      if (codeGrpId) {
        return state.codeList.filter(item => item.codeGrpId === codeGrpId);
      }
      return state.codeList;
    },
    getCommonBizCodeList: state => bizCodeGrpId => {
      if (bizCodeGrpId) {
        return state.bizCodeList.filter(
          item => item.codeGrpId === bizCodeGrpId
        );
      }
      return state.bizCodeList;
    },
    getCommonCodeByCodeId: state => codeId =>
      state.codeList.find(item => item.codeId === codeId),
    getCommonCodeGroupById: state => groupId =>
      state.codeGroupList.find(item => item.codeGrpId === groupId),
    getMenuList: state => () => state.menuList,
    getMenuTree: state => () => state.menuTree,
    getMobileMenuList: state => () => state.mobileMenuList,
    getMobileMenuTree: state => () => state.mobileMenuTree
  }
};
