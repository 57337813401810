/**
 * 1. 개요 : 쿠폰지불관리번호검색
 * 2. 처리내용 : 쿠폰지불관리번호검색 Mixins - 화면 모델
 * @File Name : CmnCpnCstMngNoPopupViewModel.js
 * @date : 2022. 07. 27. 19:07:00
 * @author : 이진우
 * @history :
 *-----------------------------------------------------------------------
 * 변경일			          		  작성자		변경내용
 *-----------------------------------------------------------------------
 * 2022. 07. 27. 19:07:00	    이진우	  최초 작성
 *-----------------------------------------------------------------------
 */

//  modify by jwlee 20221004 : 숫자 포매팅
import { amtFormatter } from "@/utils/formatter";

export default {
  methods: {
    CmnCpnCstMngNoPopupViewModel(params = {}) {
      return [
        {
          field: "rnum",
          headerName: "",
          type: Number,
          width: 50,
          isPk: false,
          editable: false,
          pinned: false,
          cellStyle: {
            //  modify by jwlee 20221004 : 셀 내 정렬
            textAlign: "center"
          },
          cellClassRules: {
            "selected-cell": param =>
              params && param.rowIndex === params.rowIndex
          },
          valueFormatter : (param) => {
            // 내림차순
            return this.gridProps.totalItems - (((this.currPage - 1) * this.pageUnit) + param.node.rowIndex);
          }, 
        },
        {
          field: "cpnCstPmtNo",
          headerName: this.i18n("label.cpn")+this.i18n("label.pmt")+this.i18n("label.cst")+this.i18n("label.no"),
          type: String,
          width: 150,
          isPk: false,
          editable: false,
          pinned: false,
          suppressMenu: false,
          sortable: true,
          cellStyle: {
            //  modify by jwlee 20221004 : 셀 내 정렬
            textAlign: 'center'
          },
          cellClassRules: {}
        },
        {
          field: "pmtMngLst",
          headerName: this.i18n("label.cst")+this.i18n("label.mng")+this.i18n("label.history"),
          type: String,
          width: 240,
          isPk: false,
          editable: false,
          pinned: false,
          suppressMenu: false,
          sortable: true,
          cellStyle: {},
          cellClassRules: {}
        },
        {
          field: "pmtDtm",
          headerName: this.i18n("label.cst")+this.i18n("label.dtm"),
          type: String,
          width: 210,
          isPk: false,
          editable: false,
          pinned: false,
          suppressMenu: false,
          sortable: true,
          cellStyle: {
            //  modify by jwlee 20221004 : 셀 내 정렬
            textAlign: 'center'
          },
          cellClassRules: {}
        },
        // {
        //   field: "merNm",
        //   headerName: this.i18n("label.merNm"),
        //   type: String,
        //   width: 210,
        //   isPk: false,
        //   editable: false,
        //   pinned: false,
        //   suppressMenu: false,
        //   sortable: true,
        //   cellStyle: {
        //     //  modify by jwlee 20221004 : 셀 내 정렬
        //     textAlign: 'center'
        //   },
        //   cellClassRules: {}
        // },
        {
          field: "totDcAmt",
          headerName: this.i18n("label.tot")+this.i18n("label.dcAmt"),
          type: String,
          width: 125,
          isPk: false,
          editable: false,
          pinned: false,
          suppressMenu: false,
          sortable: true,
          cellStyle: {
            //  modify by jwlee 20221004 : 셀 내 정렬
            textAlign: 'right'
          },
          cellClassRules: {},
          //  modify by jwlee 20221004 : 숫자 포매팅
          valueFormatter : (params) => {
            return amtFormatter(params.data.totDcAmt);
          }
        },
        {
          field: "totTrCnt",
          headerName: this.i18n("label.totTrCnt"),
          type: String,
          width: 125,
          isPk: false,
          editable: false,
          pinned: false,
          suppressMenu: false,
          sortable: true,
          cellStyle: {
            //  modify by jwlee 20221004 : 셀 내 정렬
            textAlign: 'right'
          },
          cellClassRules: {},
          //  modify by jwlee 20221004 : 숫자 포매팅
          valueFormatter : (params) => {
            return amtFormatter(params.data.totTrCnt);
          }
        },
        {
          field: "normalCanYn",
          headerName: this.i18n("label.can")+this.i18n("label.yn"),
          type: String,
          width: 110,
          isPk: false,
          editable: false,
          pinned: false,
          suppressMenu: false,
          sortable: true,
          cellStyle: {
            //  modify by jwlee 20221004 : 셀 내 정렬
            textAlign: 'center'
          },
          cellClassRules: {}
        }
        
      ];
    }
  }
};
