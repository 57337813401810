/**
 * 1. 개요 : mobile admin
 * 2. 처리내용 : mobile admin - 진입점
 * @File Name : index.vue
 * @date : 2020. 02. 20. 09:46:00
 * @author : 이상철
 * @history :
 *-----------------------------------------------------------------------
 * 변경일			          		  작성자		변경내용
 *-----------------------------------------------------------------------
 * 2020. 2. 20. 09:46:00	    이상철	  최초 작성
 *-----------------------------------------------------------------------
 */
<template>
	<div class="m-al-wrap" :class="{'overflow-hidden': sidebar.opened}">
		<m-navbar />
		<m-sidebar :class="classObj" />
		<div v-if="sidebar.opened" class="drawer-bg" @click="handleClickOutside" />		
		<m-app-main />
		<m-copyright />
	</div>
</template>

<script>
import { mapState } from "vuex";
import { MAppMain, MNavbar, MSidebar, MCopyright } from "./components";

export default {
	name: "MLayout",
	components: {
		MAppMain,
		MNavbar,
		MSidebar,
		MCopyright
	},
	computed: {
		...mapState({
			sidebar: state => state.Sidebar.sidebar,
		}),
		classObj() {
			return {
				hideSidebar: !this.sidebar.opened,
				openSidebar: this.sidebar.opened,
				withoutAnimation: this.sidebar.withoutAnimation,
			};
		},
		key() {
			return this.$route.path;
		},
		shownFooter() {
			return this.$route.meta.useFooter !== false;
		}
	},
	methods: {
		handleClickOutside() {
			this.$store.dispatch("Sidebar/closeSideBar", { withoutAnimation: false });
		},
	}
};
</script>

<style lang="scss" scoped>
.m-al-wrap{
	width:100%;
	height:100%;
}
.overflow-hidden{
	overflow: hidden;
}
</style>