// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@/assets/img/sp-pc.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".sidebar-btn-box > span[data-v-84d4d288] {\n  cursor: pointer;\n  display: block;\n  width: 17px;\n  height: 56px;\n  font-size: 0;\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat 0 -50px;\n}\n.sidebar-btn-box > span.menu-list[data-v-84d4d288] {\n    height: 57px;\n    border-top: solid 1px #fff;\n    background-position-x: -50px;\n}\n", ""]);
// Exports
module.exports = exports;
