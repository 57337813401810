/**
 * 1. 개요 : 공통 컴포넌트
 * 2. 처리내용 : 공통 컴포넌트 - Pagination
 * @File Name : index.vue
 * @date : 2020. 02. 20. 09:46:00
 * @author : 이상철
 * @history :
 *-----------------------------------------------------------------------
 * 변경일			          		  작성자		변경내용
 *-----------------------------------------------------------------------
 * 2020. 2. 20. 09:46:00	    이상철	  최초 작성
 *-----------------------------------------------------------------------
 */
<template>
  <div class="common-pagination">
    <uib-pagination
      v-model="model"
      class="pagination-sm"
      first-text
      previous-text
      next-text
      last-text
      :total-items="totalItems"
      :max-size="10"
      :items-per-page="itemsPerPage"
      :boundary-links="true"
      @change="onChange"
    />
  </div>
</template>

<script>
export default {
  name: 'Pagination',
  model: {
    prop: 'state',
    event: 'change'
  },
  props: {
    state: {
      type: Object,
      default: () => {}
    },
    totalItems: {
      type: Number,
      required: true
    },
    maxSize: {
      type: Number,
      default: 10
    },
    itemsPerPage: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      model: {}
    };
  },
  methods: {
    /**
     * pagination 이 변동되면 실행
     */
    onChange() {
      this.$emit('change', this.model);
    }
  }
};
</script>

<style lang="scss">
@import "@/scss/_color.scss";

.pagination {
  display: inline-block;
  padding-left: 0;
  margin: 20px 0 10px;
}

.pagination>li {
  display: inline-block;
  height: 13px;
  vertical-align: middle;
}

.pagination>li>a,
.pagination>li>span {
  position: relative;
  display:block;
  height:100%;
  color: $c6;
  text-decoration: none;
  background-color: #fff;
}

.pagination>li>a:hover,
.pagination>li>span:hover,
.pagination>li>a:focus,
.pagination>li>span:focus {
  color: $c4;
  z-index: 2;
}

.pagination>.active>a,
.pagination>.active>span,
.pagination>.active>a:hover,
.pagination>.active>span:hover,
.pagination>.active>a:focus,
.pagination>.active>span:focus {
  color: $c4;
  font-weight:bold;
  border-bottom:solid 1px $c4;
  cursor: default;
  z-index: 3;
}

.pagination>.disabled>span,
.pagination>.disabled>span:hover,
.pagination>.disabled>span:focus,
.pagination>.disabled>a,
.pagination>.disabled>a:hover,
.pagination>.disabled>a:focus {
  color: #777;
  cursor: not-allowed;
  background-color: #fff;
  border-color: #ddd;
}

.common-pagination {
  text-align:center;
	.pagination-page{
    >a,
    >span{margin:0 6px}
  }
	.pagination-first,
	.pagination-prev,
	.pagination-next,
	.pagination-last {
    max-width:15px;
    a{
      width: 100%;
      height:100%;
      &::before {
        display: block;
        width: 100%;
        height:100%;
        margin-top:-3px;
        font: {
          family:"Material Icons";
          size:15px;
        }
      }
		}
	}
	.pagination-first a::before {
		content: "\e5dc";
	}
	.pagination-prev a::before {
		content: "\e5cb";
	}
	.pagination-next a::before {
		content: "\e5cc";
	}
	.pagination-last a::before {
		content: "\e5dd";
	}
}
</style>
