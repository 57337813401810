var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "sidebar-logo-container" },
    [
      _c("transition", { attrs: { name: "sidebarLogoFade" } }, [
        _c("h1", { staticClass: "sidebar-title" }, [
          this.userAuth.userId != "pcidss_office" &&
          this.userAuth.userId != "pcidss_merchant" &&
          this.userAuth.userId != "pcidss_agency" &&
          this.userAuth.userId != "pcidss_bank" &&
          this.userAuth.userId != "pcidss_office1" &&
          this.userAuth.userId != "pcidss_merchant1" &&
          this.userAuth.userId != "pcidss_agency1" &&
          this.userAuth.userId != "pcidss_bank1"
            ? _c(
                "div",
                [
                  _c(
                    "router-link",
                    {
                      key: "expand",
                      staticClass: "sidebar-logo-link",
                      attrs: { to: "/dashboard" }
                    },
                    [
                      _vm._v(" " + _vm._s(_vm.title) + " "),
                      _vm.logo
                        ? _c("img", {
                            staticClass: "sidebar-logo",
                            attrs: {
                              src: _vm.logo,
                              alt: "logo image",
                              width: "104",
                              height: "25"
                            }
                          })
                        : _vm._e()
                    ]
                  )
                ],
                1
              )
            : _c("div", [
                _vm.logo
                  ? _c("img", {
                      staticClass: "sidebar-logo",
                      attrs: {
                        src: _vm.logo,
                        alt: "logo image",
                        width: "104",
                        height: "25"
                      }
                    })
                  : _vm._e()
              ])
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }