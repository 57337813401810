export default [
  {
    name: "CpnEvtWinner",
    path: "/biz/bep/cpn/CpnEvtWinner",
    component: () => import("@/views/biz/bep/cpn/CpnEvtWinner"),
    meta: { layout: "admin" }
  },
  {
    name: "CpnGiftInf",
    path: "/biz/bep/cpn/CpnGiftInf",
    component: () => import("@/views/biz/bep/cpn/CpnGiftInf"),
    meta: { layout: "admin" }
  },
  {
    name: "CpnJoinCustInf",
    path: "/biz/bep/cpn/CpnJoinCustInf",
    component: () => import("@/views/biz/bep/cpn/CpnJoinCustInf"),
    meta: { layout: "admin" }
  },
  {
    name: "CpnSyncHist",
    path: "/biz/bep/cpn/CpnSyncHist",
    component: () => import("@/views/biz/bep/cpn/CpnSyncHist"),
    meta: { layout: "admin" }
  },
  {
    name: "CpnPlcyInf",
    path: "/biz/bep/cpn/CpnPlcyInf",
    component: () => import("@/views/biz/bep/cpn/CpnPlcyInf"),
    meta: { layout: "admin" }
  }
]