/**
 * 1. 개요 : Alert
 * 2. 처리내용 : Alert - 스토어
 * @File Name : Alert.js
 * @date : 2020. 02. 20. 09:46:00
 * @author : 이상철
 * @history :
 *-----------------------------------------------------------------------
 * 변경일			          		  작성자		변경내용
 *-----------------------------------------------------------------------
 * 2020. 2. 20. 09:46:00	    이상철	  최초 작성
 *-----------------------------------------------------------------------
 */
import { v4 as uuidv4 } from "uuid";

export default {
  state: {
    alertList: []
  },
  namespaced: true,
  mutations: {
    PUSH_ALERT(state, payload) {
      state.alertList.push(payload);
    },
    REMOVE_ALERT(state, id) {
      state.alertList = state.alertList.filter(item => item.id !== id);
    }
  },
  actions: {
    alert(context, options) {
      context.commit("PUSH_ALERT", {
        id: uuidv4(),
        options
      });
    },
    closeAlert(context, id) {
      context.commit("REMOVE_ALERT", id);
    }
  }
};
