var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "portal",
    { attrs: { to: "popup" } },
    [
      _c("transition", { attrs: { name: "fade" } }, [
        _c("div", { staticClass: "popup-dim" }, [
          _c(
            "div",
            {
              ref: "wrap",
              staticClass: "pop-wrap",
              class: _vm.popSize,
              style: _vm.styles,
              attrs: { role: "dialog" }
            },
            [
              _c(
                "div",
                { staticClass: "header" },
                [
                  _vm.title
                    ? [
                        _c(
                          "h1",
                          { staticClass: "title", attrs: { title: _vm.title } },
                          [_vm._v(" " + _vm._s(_vm.title) + " ")]
                        )
                      ]
                    : [_vm._t("header")],
                  _vm._t("header-append")
                ],
                2
              ),
              _c(
                "div",
                { staticClass: "body", class: _vm.customPopSizeClass },
                [_vm._t("default")],
                2
              ),
              _c(
                "div",
                { staticClass: "footer" },
                [_vm._t("footer"), _vm._t("buttons")],
                2
              ),
              _c(
                "button",
                {
                  staticClass: "close-button",
                  attrs: { type: "button" },
                  on: {
                    click: function($event) {
                      return _vm.$emit("close")
                    }
                  }
                },
                [_vm._v(" Close ")]
              )
            ]
          )
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }