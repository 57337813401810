import { v4 as uuidv4 } from "uuid";
import axios from "axios";
import store from "@/store";
import { isEmpty } from '@/utils'

const baseURL = window.location.origin.includes("localhost")
  ? process.env.VUE_APP_BASE_API
  : window.location.origin;

const timeout = process.env.VUE_APP_TIME_OUT;

const _axios = axios.create({ timeout });

const showLoading = (loadingKey = uuidv4()) => {
  store.commit("Loading/PUSH_LOAD", loadingKey);
  return loadingKey;
};

const hideLoading = config =>
  store.commit("Loading/REMOVE_LOAD", config.loadingKey);

const setURL = url => {
  if (window.location.origin.includes("localhost")) {
    if (url.includes("/api/bep") || url.includes("/api/tms"))
      return `${baseURL}9093${url}`;
    else return `${baseURL}9090${url}`;
  } else {
    return `${baseURL}${url}`;
  }
};

export const post = (url, data, config) => {
  const { msgLangCd, accesstoken } = store.getters["Options/all"];
  
  // add by jwlee 20221007 : 메뉴ID 값 추가
  let str = store.getters["TagsView/visitedViews"].filter(store => store.path == window.location.pathname);
  if(!isEmpty(str)) {
    data.menuName = str[0].title;
  }

  if (config.showLoading) {
    config.loadingKey = showLoading();
  }

  const headers = {
    accesstoken: config.accesstoken ? config.accesstoken : accesstoken,
    data,
    msgLangCd: data.msgLangCd ? data.msgLangCd : msgLangCd,
    showLoading: config.showLoading || false
  };
  
  const responseType = config.responseType ? config.responseType : null;

  return _axios({
    method: "post",
    url: setURL(url),
    data,
    headers,
    timeout,
    responseType,
    withCredentials: window.location.origin.includes("localhost") ? false : true
  })
    .then(res => {
      if (res.config.responseType === "blob") {
        return res;
      }
      hideLoading(config);

      const { accesstoken } = res.headers;
      if (accesstoken) {
        store.commit("Options/SET_USER_INFO", { accesstoken });
      }

      return res;
    })
    .catch(err => {
      hideLoading(config);
      return Promise.reject(err);
    });
};

export const anonymPost = (url, data, config) => {
  const msgLangCd = getMsgLangCd();

  if (config.showLoading) {
    config.loadingKey = showLoading();
  }

  const headers = {
    data,
    msgLangCd,
    showLoading: config.showLoading || false
  };

  const responseType = config.responseType ? config.responseType : null;

  return _axios({
    method: "post",
    url: setURL(url),
    data,
    headers,
    timeout,
    responseType
  })
    .then(res => {
      hideLoading(config);
      return res;
    })
    .catch(err => {
      hideLoading(config);
      return Promise.reject(err);
    });
};

/**
 * 브라우저 로케일 가져오기
 */
const getMsgLangCd = () => {
  switch (navigator.language.slice(0, 2)) {
    case "en":
      return "en_US";
    case "vi":
      return "vi_VN";
    case "ko":
      return "ko_KR";
    default:
      return "en_US";
  }
};
