/**
 * 1. 개요 : 주문시리얼번호 검색
 * 2. 처리내용 : 주문시리얼번호 검색 Mixins - 화면 모델
 * @File Name : CmnOrdSnPopupViewModel.js
 * @date : 2020. 04. 20. 13:00:00
 * @author : 이현우
 * @history :
 *-----------------------------------------------------------------------
 * 변경일			          		  작성자		변경내용
 *-----------------------------------------------------------------------
 * 2020. 04. 20. 13:00:00	    이현우	  최초 작성
 *-----------------------------------------------------------------------
 */

import { lookupValue } from "@/utils/dataGrid";

export default {
  methods: {
    cmnOrdSnPopupViewModel(params = {}) {
      return [
        {
          field: "rnum",
          headerName: "",
          type: Number,
          width: 50,
          isPk: false,
          editable: false,
          pinned: false,
          cellStyle: {
            textAlign: "center"
          },
          cellClassRules: {
            "selected-cell": param =>
              params && param.rowIndex === params.rowIndex
          },
          valueFormatter: param => {
            // 오름차순
            // return (
            //   (this.currPage - 1) * this.pageUnit + param.node.rowIndex + 1
            // );

            // 내림차순
            return (
              this.gridProps.totalItems -
              ((this.currPage - 1) * this.pageUnit + param.node.rowIndex)
            );
          },
          export:false

        },
        {
          // headerName: "",
          // field: "chk",
          checkboxSelection: true,
          headerCheckboxSelection: true,
          headerCheckboxSelectionFilteredOnly: true,
          width: 50,
          cellStyle: { textAlign: "center" },
          pinned: false
        },
        {
          field: "ordId",
          headerName: this.i18n("label.ordId"),
          type: String,
          width: 150,
          isPk: true,
          editable: false,
          pinned: false,
          suppressMenu: false,
          sortable: true,
          cellStyle: {
            textAlign: "center"
          },
          cellClassRules: {}
        },
        // {
        //   field: "prcCd",
        //   headerName: this.i18n("label.prcCd"),
        //   type: String,
        //   width: 160,
        //   isPk: true,
        //   editable: false,
        //   pinned: false,
        //   suppressMenu: false,
        //   sortable: true,
        //   cellStyle: {
        //     textAlign: "center"
        //   },
        //   cellClassRules: {}
        // },
        {
          field: "sn",
          headerName: this.i18n("label.sn"),
          type: Number,
          width: 140,
          isPk: false,
          editable: false,
          pinned: false,
          suppressMenu: false,
          sortable: true,
          cellStyle: {
            textAlign: "center"
          },
          cellClassRules: {}
        },
        {
          field: "prdNo",
          headerName: this.i18n("label.prdNo"),
          type: String,
          width: 140,
          isPk: false,
          editable: false,
          pinned: false,
          suppressMenu: false,
          sortable: true,
          cellStyle: {
            textAlign: "center"
          },
          cellClassRules: {}
        },
        {
          field: "stckTgtLvlCd",
          headerName: this.i18n("label.stckTgtLvl"),
          type: String,
          width: 140,
          isPk: false,
          editable: false,
          pinned: false,
          suppressMenu: false,
          sortable: true,
          cellStyle: {
            textAlign: "center"
          },
          cellClassRules: {},
          valueFormatter: param =>
            param.value &&
            lookupValue(this.cdList.stckTgtLvlCdList, param.value)
        },
        {
          field: "stckTgtNm",
          headerName: this.i18n("label.stckTgt"),
          type: String,
          width: 140,
          isPk: false,
          editable: false,
          pinned: false,
          suppressMenu: false,
          sortable: true,
          cellStyle: {
            textAlign: "center"
          },
          cellClassRules: {}
        },
        {
          field: "compStatCd",
          headerName: this.i18n("label.compStat"),
          type: String,
          width: 120,
          isPk: false,
          editable: false,
          pinned: false,
          suppressMenu: false,
          sortable: true,
          cellStyle: {
            textAlign: "center"
          },
          cellClassRules: {},
          valueFormatter: param =>
            param.value && lookupValue(this.cdList.compStatCdList, param.value)
        },
        {
          field: "statCd",
          headerName: this.i18n("label.statCd"),
          type: String,
          width: 120,
          isPk: false,
          editable: false,
          pinned: false,
          suppressMenu: false,
          sortable: true,
          cellStyle: {
            textAlign: "center"
          },
          cellClassRules: {},
          valueFormatter: param =>
            param.value && lookupValue(this.cdList.stckStatCdList, param.value)
        },
        {
          field: "addedDate",
          headerName: this.i18n("label.addedDate"),
          type: String,
          width: 140,
          isPk: false,
          editable: false,
          pinned: false,
          suppressMenu: false,
          sortable: true,
          cellStyle: {
            textAlign: "center"
          },
          cellClassRules: {},
          valueFormatter: param => this.i18nDtTmFormat(param.value)
        },
        {
          field: "restCell",
          headerName: "",
          width: 0,
          type: String,
          isPk: false,
          editable: false,
          pinned: false,
          suppressMenu: false,
          sortable: false,
          cellStyle: {},
          cellClassRules: {}
        }
      ];
    }
  }
};
