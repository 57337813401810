var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "input-text text-box" }, [
    _c("input", {
      ref: _vm.id,
      staticStyle: { "text-align": "right" },
      attrs: {
        id: _vm.id,
        type: "text",
        disabled: _vm.disabled,
        onkeydown: _vm.onKeyDown,
        maxlength: _vm.calMaxlength,
        placeholder: _vm.placeholder
      },
      domProps: { value: _vm.formatedValue },
      on: {
        blur: _vm.onBlurHandler,
        keydown: function($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
          ) {
            return null
          }
          $event.preventDefault()
          return _vm.onEnter($event)
        },
        input: _vm.handleInput
      }
    })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }