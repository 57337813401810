var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    _vm._l(_vm.alertList, function(item) {
      return _c("alert", {
        key: item.id,
        attrs: {
          id: item.id,
          message: item.options.message,
          title: item.options.title,
          buttons: item.options.buttons,
          "on-press": item.options.onPress,
          type: item.options.type
        }
      })
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }