/**
 * 1. 개요 : Inputs 공통 컴포넌트
 * 2. 처리내용 : Inputs 공통 컴포넌트 - DateRange
 * @File Name : index.vue
 * @date : 2020. 02. 20. 09:46:00
 * @author : 이상철
 * @history :
 *-----------------------------------------------------------------------
 * 변경일			          		  작성자		변경내용
 *-----------------------------------------------------------------------
 * 2020. 2. 20. 09:46:00	    이상철	  최초 작성
 *-----------------------------------------------------------------------
 */
<template>
  <div class="date-range">
    <ul class="date-range-list">
      <li
        v-for="(item, index) in viewList"
        :key="list[index]"
        class="date-range-list-item"
        :class="{ selected: selectedYn(index)}"
        @click="select(index)"
      >
        <button>{{ item }}</button>
      </li>
    </ul>
  </div>
</template>

<script>
import moment from "moment/moment";  

const localeSetList = {
  KOR: {
    D: "일",
    W: "주",
    M: "월",
    Y: "년"
  },
  CHI: {
    D: "日",
    W: "周",
    M: "月",
    Y: "年"
  }
};

export default {
  name: "DateRange",

  filters: {
    test: (value, msgLangCd) => {
      const itemTrans = (value || "").split(/(y|m|w|d)/i);
      if (msgLangCd === "en_US") return value;
      return itemTrans[0] + localeSetList[msgLangCd][itemTrans[1]];
    }
  },

  model: {
    prop: "value",
    event: "change"
  },

  props: {
    value: {
      type: String,
      default: ""
    },
    format: {
      type: String,
      default: "YYYY-MM-DD"
    },
    list: {
      type: Array,
      default: () => ["1D", "7D", "1M", "2M-", "1M-", "0M-"]
      // 원래 있던 코드 default: () => ["7D", "1M", "3M", "6M", "1Y"] 
    },
    autoSetting: {
      type: Boolean,
      default: true
    },
    diffYn: {
      type: Boolean,
      default: false
    },
    propsServerDate: {
      type: String,
      default: ''
    }
  },

  data() {
    return {
      localValue: null,
      viewList: ["1D", "7D", "1M", "2M-", "1M-", "0M-"],

      serverDate: '',
    };
  },

  computed: {
    selectedIndex() {
      return this.list.indexOf(this.localValue);
    },
  },

  watch: {
    value(value) {
      this.localValue = value;
    },
  },

  created() {
    this.localValue = this.value;
  },

  mounted() {
    this.serverDate = this.propsServerDate;
    
    this.viewList = this.setViewList();
    
    if (this.autoSetting && this.selectedIndex >= 0) {
      this.select(this.selectedIndex);
    }    
  },

  methods: {
    selectedYn(index){
      return index === this.selectedIndex && this.diffYn
    },
    select(index) {
      this.localValue = this.list[index];
      
      const matches = /(\d{1,})(y|m|w|d)/i.exec(this.localValue);
      
      this.$emit("change",
        this.list[index],
        moment(this.serverDate, "YYYYMMDD")
          .subtract(
            matches[1],
            /y|d/i.test(matches[2])
              ? matches[2].toLowerCase()
              : matches[2].toUpperCase()
          )
          .add(1, "d")
          .format("YYYYMMDD"),
        moment().format("YYYYMMDD"),
      );
    },
    setViewList() {
      const koCurMon = moment(this.serverDate).format("MM");
      const enCurMon = moment(this.serverDate).format("MMM");
      
      const koLastMon = moment(this.serverDate).subtract(1, "months").format("MM");
      const koLastLastMon = moment(this.serverDate).subtract(2, "months").format("MM");
      
      const enLastMon = moment(this.serverDate).subtract(1, "months").format("MMM");
      const enLastLastMon = moment(this.serverDate).subtract(2, "months").format("MMM");
      
      return (() => {
        switch (this.msgLangCd) {
          case "en_US":
            return ["Today", "1 week", "1 month", enLastLastMon, enLastMon, enCurMon];
          case "vi_VN":
            return ["Ngày", "1 tuần", "1 tháng", enLastLastMon, enLastMon, enCurMon];
          case "ko_KR":
            return ["당일", '1주일', '1개월', `${koLastLastMon}월`, `${koLastMon}월`, `${koCurMon}월`];
          default:
            return ["Today", "1 week", "1 month", enLastLastMon, enLastMon, enCurMon];
        }
      })();
    }
  }
};
</script>

<style lang="scss" scoped>
$height: 32px;
$width: 34px;
$button-height: 27px;  // 원래 24px
$border-color: #e0e0e0;
$font-size: 11px;
$color: #666;
$space: 6px;
$cms-color: #4492d2;

.date-range {
  display: inline-block;
  vertical-align: middle;
  height: $height;
  padding: (($height - $button-height) / 2) 0;

  margin-left: 0px;

  .date-range-list{
    margin-right: 15px;
  }

  &-list {
    overflow: hidden;
    &-item {
      float: left;

      height: $button-height;
      text-align: center;
      color: $color;
      background-color: #fff;
      border: solid 1px #c6c6c6; // 원래 #e0e0e0
      button {
        line-height: $button-height - 2px;
        font-size: $font-size;

        min-width: $width;
        padding: 0 5px;

        background: none;
        border: 0;
        box-shadow: none;
        appearance: none;
        box-sizing: border-box;
        color: inherit;
        line-height: 1;

        width: 100%;
        height: 100%;
        display: block;

        cursor: pointer;
      }
      &:hover {
        background: #eee;
      }
      &.selected {
        color: #fff;
        background: $cms-color;
        border-color: darken($cms-color, 10%);
      }
      &:nth-child(n + 2) {
        margin-left: $space;
      }
    }
  }
}
</style>

