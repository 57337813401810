/**
 * 1. 개요 : 민원관리
 * 2. 처리내용 : 민원관리 Mixins - 생성/수정 모델
 * ComplaintMngSaveModel.js
 * @date : 2020. 04. 20.  05:25:19
 * @author : 김경식
 * @history :
 *-----------------------------------------------------------------------
 * 변경일			          		    작성자		변경내용
 *-----------------------------------------------------------------------
 * 2020. 04. 20.  05:25:19	    김경식	  최초 작성
 *-----------------------------------------------------------------------
 */
export default {
  methods: {
    complaintMngSaveModel(param = {}) {
      return [
        {
          field: "bbsTypCd",
          orderById: "BBS_TYP_CD",
          orderByName: this.i18n('label.bbsTypCd'),
          orderBy: false,
          isPk: false,
          isNotNull: true
        },
        {
          field: "bbsTypId",
          orderById: "BBS_TYP_ID",
          orderByName: this.i18n('label.bbsTypId'),
          orderBy: false,
          isPk: false,
          isNotNull: false
        },
        {
          field: "parBbsId",
          orderById: "PAR_BBS_ID",
          orderByName: this.i18n('label.parBbsId'),
          orderBy: false,
          isPk: false,
          isNotNull: false
        },
        {
          field: "title",
          orderById: "TITLE",
          orderByName: this.i18n('label.title'),
          orderBy: true,
          isPk: false,
          isNotNull: false
        },
        {
          field: "contents",
          orderById: "CONTENTS",
          orderByName: this.i18n('label.contents'),
          orderBy: false,
          isPk: false,
          isNotNull: false
        },
        {
          field: "bbsStCd",
          orderById: "BBS_ST_CD",
          orderByName: this.i18n('label.bbsStCd'),
          orderBy: false,
          isPk: false,
          isNotNull: false
        },
        {
          field: "dispStaDt",
          orderById: "DISP_STA_DT",
          orderByName: this.i18n('label.dispStaDt'),
          orderBy: false,
          isPk: false,
          isNotNull: false
        },
        {
          field: "dispEndDt",
          orderById: "DISP_END_DT",
          orderByName: this.i18n('label.dispEndDt'),
          orderBy: false,
          isPk: false,
          isNotNull: false
        },
        {
          field: "readCnt",
          orderById: "READ_CNT",
          orderByName: this.i18n('label.readCnt'),
          orderBy: false,
          isPk: false,
          isNotNull: false
        },
        {
          field: "regUserEmail",
          orderById: "REG_USER_EMAIL",
          orderByName: this.i18n('label.regUserEmail'),
          orderBy: false,
          isPk: false,
          isNotNull: false
        },
        {
          field: "regUserPwd",
          orderById: "REG_USER_PWD",
          orderByName: this.i18n('label.regUserPwd'),
          orderBy: false,
          isPk: false,
          isNotNull: false
        },
        {
          field: "acptUserId",
          orderById: "ACPT_USER_ID",
          orderByName: this.i18n('label.acptUserId'),
          orderBy: false,
          isPk: false,
          isNotNull: false
        },
        {
          field: "acptDtm",
          orderById: "ACPT_DTM",
          orderByName: this.i18n('label.complaintDt'),
          orderBy: true,
          isPk: false,
          isNotNull: false
        },
        {
          field: "hndlUserId",
          orderById: "HND_USER_ID",
          orderByName: this.i18n('label.hndlUserId'),
          orderBy: false,
          isPk: false,
          isNotNull: false
        },
        {
          field: "hndlDtm",
          orderById: "HND_DTM",
          orderByName: this.i18n('label.hndlDtm'),
          orderBy: false,
          isPk: false,
          isNotNull: false
        },
      ];
    }
  }
};
