/**
 * 1. 개요 : 공통 utils
 * 2. 처리내용 : 공통 utils - validate 함수 모음
 * @File Name : validate.js
 * @date : 2020. 03. 20. 09:46:00
 * @author : 이상철
 * @history :
 *-----------------------------------------------------------------------
 * 변경일			          		  작성자		변경내용
 *-----------------------------------------------------------------------
 * 2020. 03. 20. 09:46:00	    이상철	  최초 작성
 *-----------------------------------------------------------------------
 */

import { isEmpty } from "@/utils";

/**
 * url 밸리데이트
 * @param {string} url
 * @returns {Boolean}
 */
export function validURL(url) {
  const reg = /^(https?|ftp):\/\/([a-zA-Z0-9.-]+(:[a-zA-Z0-9.&%$-]+)*@)*((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9][0-9]?)(\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])){3}|([a-zA-Z0-9-]+\.)*[a-zA-Z0-9-]+\.(com|edu|gov|int|mil|net|org|biz|arpa|info|name|pro|aero|coop|museum|[a-zA-Z]{2}))(:[0-9]+)*(\/($|[a-zA-Z0-9.,?'\\+&%$#=~_-]+))*$/;
  return reg.test(url);
}

/**
 * 소문자 밸리데이트
 * @param {string} str
 * @returns {Boolean}
 */
export function validLowerCase(str) {
  const reg = /^[a-z]+$/;
  return reg.test(str);
}

/**
 * 대문자 밸리데이트
 * @param {string} str
 * @returns {Boolean}
 */
export function validUpperCase(str) {
  const reg = /^[A-Z]+$/;
  return reg.test(str);
}

/**
 * 숫자 밸리데이트
 * @param {string} str
 * @returns {Boolean}
 */
export function validNumber(str) {
  const reg = /^[0-9]+$/;
  return reg.test(str);
}

/**
 * 알파벳 밸리데이트
 * @param {string} str
 * @returns {Boolean}
 */
export function validAlphabets(str) {
  const reg = /^[A-Za-z]+$/;
  return reg.test(str);
}

/**
 * ID 밸리데이터: 영문자 + 숫자, 총 7자리 이상
 * @param {string} userId
 * @returns {Boolean}
 */
export function validUserId(userId) {
  const reg = /^([A-Za-z0-9]+){7,50}$/i;

  return reg.test(userId);
}

/**
 * 알파벳+숫자 밸리데이트  #2217 2022.09.15 한승현 작성(사업자 번호 검증)
 * @param {string} str
 * @returns {Boolean}
 */
 export function validAlphabetsAndNumber(str) {
  const reg = /^[a-zA-Z0-9 ]*$/;
  return reg.test(str);
}

/**
 * 이메일 밸리데이트
 * @param {string} email
 * @returns {Boolean}
 */
export function validEmail(email) {
  const reg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return reg.test(email);
}

/**
 * PW 밸리데이터: 영문자 + 특수문자 + 숫자 최소 1개 이상, 총 7자리 이상
 * @param {string} password
 * @returns {Boolean}
 */
export function validPassword(password) {
  const numReg = /[0-9]/;
  const strReg = /[a-zA-Z]/;
  // const speReg = /[`~!@#$%^&*|()\'\"\\_+<>?:{}]/;
  const speReg = /[`~!@#$%^&*|()\'\"\\_+<>?:;.-{}]/; // 12월 11일 특수문자 추가. ; . -
  if (
    !numReg.test(password) ||
    !strReg.test(password) ||
    !speReg.test(password) ||
    password.length < 7
  ) {
    return false;
  }

  return true;
}

/**
 * 문자열 밸리데이트
 * @param {string} str
 * @returns {Boolean}
 */
export function isString(str) {
  if (typeof str === "string" || str instanceof String) {
    return true;
  }
  return false;
}

/**
 * 배열 밸리데이트
 * @param {Array} arg
 * @returns {Boolean}
 */
export function isArray() {
  if (typeof Array.isArray === "undefined") {
    return Object.prototype.toString.call(arg) === "[object Array]";
  }
  return Array.isArray(arg);
}

/**
 * @param {string} path
 * @returns {Boolean}
 */
export function isExternal(path) {
  return /^(https?:|mailto:|tel:)/.test(path);
}

/**
 * 숫자형 input 밸리데이트
 * @param {string} path
 * @returns {Boolean}
 */
export function validNumberInputData(param) {
  if(!isEmpty(param)&&param.length!=0){
    const str = typeof param === "string" ? param : param.toString();

    //if (str.length === 0) return true;

    const reg1 = /[.]/g;
    const reg2 = /[-]/g;
    const reg3 = /^-?\d+\.?\d*$/g;

    if (reg2.test(str) && str.indexOf("-") !== 0) return false;
    if (reg2.test(str) && reg1.test(str) && str.indexOf(".") < 2) return false;
    if (!reg2.test(str) && reg1.test(str) && str.indexOf(".") === 0) return false;
    if (reg1.test(str) && str.indexOf(".") === str.length - 1) return false;

    return reg3.test(str);
  } else{
    return true;
  }
}

/**
 * 숫자형 input 밸리데이트(소수점)
 * @param {string, number} path
 * @returns {Boolean}
 */
export function validAmtInputPrecision(param, precision) {
  const str = typeof param === "string" ? param : param.toString();

  if (isEmpty(str) || str === "0") return true;
  if (isEmpty(str.split(".")[1]) && precision > 0) return false;
  else if (str.split(".")[1].length !== precision) return false;
  else return true;
}

/**
 * input-phone 전화번호 validate
 * @param {string}
 */
export function inputPhoneValidate(param) {
  
  if(isEmpty(param)||param.indexOf(" ") === -1) return true;
  if(!isEmpty(param) && !/\d/.test(param) && param.length === 11) return true;
  else if(param.includes(" ") && param.indexOf(" ") === 6 && param.lastIndexOf(" ") === 6) {
    return true;
  } else return false;
}

/**
 * 영문명 밸리데이트(영어 대소문자 + 숫자 + 공백 + 특수문자)  #2446 2022.12.02 한승현 작성
 * @param {string} param
 * @returns {Boolean}
 */
 export function validAlphabetsAndNumberAndSymbol(param) {
  console.log(param);
  const reg = /[^\w\s!@#$%^&*()-+{};':",./<>?\[\]]+/g;
  return reg.test(param);
}
