var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { ref: "app", class: _vm.classes, attrs: { id: "app" } },
    [
      _c("div", { class: { "water-mark": _vm.printKey === true } }),
      _c("img", {
        staticStyle: { display: "none" },
        attrs: { id: "watermark", src: "" }
      }),
      _vm.isCommonCodeFetching
        ? _c("loading")
        : [
            _c(
              _vm.layout + "-layout",
              { tag: "component" },
              [_c("router-view", { key: _vm.$route.fullPath })],
              1
            )
          ],
      _c("portal-target", { attrs: { name: "popup", multiple: "" } }),
      _c("Dialog"),
      _vm.isGlobalLoading ? _c("loading") : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }