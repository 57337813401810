 /**
 * 1. 개요 : 가맹점 정보 검색
 * 2. 처리내용 : 가맹점 정보 검색 Mixins - helper
 * File Name : helper.js
 * date : 2020. 05. 20. 16:15:00
 * author : 이지혜
 * history :
 *-----------------------------------------------------------------------
 * 변경일			          		  작성자		변경내용
 *-----------------------------------------------------------------------
 * 2020. 05. 20. 16:15:00	    이지혜	  최초 작성
 *-----------------------------------------------------------------------
 */
import { omit, isEmpty, getByte, getByteLengthOfUtf8String } from "@/utils";
import { validEmail } from "@/utils/validate";
import { phoneFormatter } from "@/utils/formatter"
export const GlobalValidationSet = {
  NOT_NULL: 0,
  MAX_BYTE: 1
};
export default {
  methods: {
    /**
     * 원본데이터에서 수정한 데이터만 필터링 해주는 함수
     * @params {Object} originData
     * @params {Object} newData
     * @params {Array} model
     * @return {Object}
     */
    filteredSaveDataHelper(originData, newData, model) {
      let diffData = omit(originData, newData);

      // 파일 업로드가 있는 경우 파일은 무조건 수정 되는 것으로 간주
      diffData.atchFileFkId = newData.atchFileFkId;

      // 두 객체가 같은 경우 빈 객체 리턴
      if (isEmpty(diffData)) return {};

      model
        .filter(a => a.isPk)
        .forEach(
          a =>
            (diffData = Object.assign(diffData, {
              [a.field]: newData[a.field]
            }))
        );

      diffData = Object.assign(diffData, { status: newData.status });

      // 두 객체가 다를 경우 다른 키: 밸류 객체 만들어서 리턴
      return diffData;
    },
     /**
     * 밸리데이터 함수
     * @params {Array} params
     * @return {Boolean}
     */
    async validateHelper(params) {
      console.log(params);

      let resultValidate = params.some((a, index) => {
        if( a.rule === GlobalValidationSet.NOT_NULL ) {
          if (isEmpty(a.value)) {
            this.alert({
              type: 'warning',
              title: this.i18n("label.error"),
              message: a.message,
              buttons: [{ 
                label: this.i18n("label.confirm"),
                onPress: () => { // date-picker를 쓰는 inputDate, inputTime, inputMonth일 경우
                  if(!isEmpty(a.datePicker) && a.datePicker) 
                  a.ref && a.ref.focus()
                }
              }]
            });
            
            // if(isEmpty(a.datePicker)) // inputDate, inputTime, inputMonth을 제외한 경우
            //a.ref && a.ref.focus();          
    
            return true;
          }
        }else if( a.rule === GlobalValidationSet.MAX_BYTE ) {
          if (!isEmpty(a.value) && a.max ) {
            if( getByteLengthOfUtf8String(a.value) > a.max ) {
              this.alert({
                type: 'warning',
                title: this.i18n("label.error"),
                message: a.message,
                buttons: [{ 
                  label: this.i18n("label.confirm"),
                  onPress: () => { // date-picker를 쓰는 inputDate, inputTime, inputMonth일 경우
                    if(!isEmpty(a.datePicker) && a.datePicker) 
                    a.ref && a.ref.focus()
                  }
                }]
              });

              if(isEmpty(a.datePicker)) // inputDate, inputTime, inputMonth을 제외한 경우
              a.ref && a.ref.focus();
      
              return true;
            }
          }
        }else if( a.rule === GlobalValidationSet.NUMBER ) {
          if (!isEmpty(a.value) ) {
            if( !validNumber(a.value) ) {
              this.alert({
                type: 'warning',
                title: this.i18n("label.error"),
                message: a.message,
                buttons: [{ 
                  label: this.i18n("label.confirm"),
                  onPress: () => { // date-picker를 쓰는 inputDate, inputTime, inputMonth일 경우
                    if(!isEmpty(a.datePicker) && a.datePicker) 
                    a.ref && a.ref.focus()
                  }
                }]
              });

              if(isEmpty(a.datePicker)) // inputDate, inputTime, inputMonth을 제외한 경우
              a.ref && a.ref.focus();
      
              return true;
            }
          }
        }
      })

      return resultValidate;
    },

    /**
     * 밸리데이터 함수
     * @params {Array} params
     * @return {Boolean}
     */
    legacyValidateHelper(params) {
      const notValidList = params.filter(a => !a.isValidate);

      if (!isEmpty(notValidList)) {
        this.alert({
          type: "warning",
          title: this.i18n("label.confirm"),
          message: notValidList[0].message,
          buttons: [{ 
            label: this.i18n("button.confirm"),
            onPress: () => { // date-picker를 쓰는 inputDate, inputTime, inputMonth일 경우
              if(!isEmpty(notValidList[0].datePicker) && notValidList[0].datePicker) 
              notValidList[0].ref && notValidList[0].ref.focus()
            }
          }]
        });
        
        if(isEmpty(notValidList[0].datePicker)) // inputDate, inputTime, inputMonth을 제외한 경우
        notValidList[0].ref && notValidList[0].ref.focus()

        return true;
      } else {
        return false;
      }
    },
    /**
     * 이메일 포맷 체크 함수(필수가 아닌 경우)
     * @params {String} params
     * @return {Boolean}
     */
		emailFormatCheckHelper(params){
      if(isEmpty(params)) {
        return true;
      } else if(params.length <= 1 && params.includes('@')) {
        this.psnMngVO.emailAddr = ''; // * 아무 것도 안 썼을 경우, 빈값으로 보낸다.
				return true
			} else if(params.length <= 7 && params.includes('@direct')) { // * 아무것도 안썼는데 @direct가 선택된 경우
        this.psnMngVO.emailAddr = '';
        return true
      } else if(params.length > 7 && params.includes('@direct')) { // 앞에는 썼는데 뒤에 @direct를 선택해 놓고 직접 입력 안한 경우
        return false
      } else {
				return validEmail(params) // 무언가를 썼을 경우, 이메일 포맷 체크를 한다.
      }
    },
    /**
     * 전화번호 포맷 체크 함수(필수 아닌 경우)
     * @params {Number} params
     * @return {Boolean}
     */
    phoneFormatCheckHelper(param){
      // 뭔가를 입력하면 빈값을 체크한다. (하나라도 입력하면 자리수가 11자리)
      if(!isEmpty(param) && !/\d/.test(param)){
        return true;
      } else if(!isEmpty(param) && param.indexOf(" ") !== -1) {
        return false;
			} else return true;
    },
    /**
     * 이메일 포맷 체크 함수(필수인 경우 - validate에 isEmpy 체크 필)
     * @params {String} params
     * @return {Boolean}
     */
    emailFormatCheckHelperRequired(params){
      if(isEmpty(params)) {
        return false;
      } else {
				return validEmail(params) // 무언가를 썼을 경우, 이메일 포맷 체크를 한다.
      }
    },
  }
};
