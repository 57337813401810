/**
 * 1. 개요 : 재고일괄등록
 * 2. 처리내용 : 재고일괄등록 Mixins - helper
 * @File Name : helper.js
 * @date : 2020. 05. 06. 13:00:00
 * @author : 이현우
 * @history :
 *-----------------------------------------------------------------------
 * 변경일			          		  작성자		변경내용
 *-----------------------------------------------------------------------
 *  2020. 05. 06. 13:00:00	    이현우	  최초 작성
 *-----------------------------------------------------------------------
 */
import { omit, isEmpty } from "@/utils";

export default {
  methods: {
    /**
     * 원본데이터에서 수정한 데이터만 필터링 해주는 함수
     * @params {Object} originData
     * @params {Object} newData
     * @params {Array} model
     * @return {Object}
     */
    filteredSaveDataHelper(originData, newData, model) {
      let diffData = omit(originData, newData);

      // 파일 업로드가 있는 경우 파일은 무조건 수정 되는 것으로 간주
      diffData.atchFileFkId = newData.atchFileFkId;

      // 두 객체가 같은 경우 빈 객체 리턴
      if (isEmpty(diffData)) return {};

      model
        .filter(a => a.isPk)
        .forEach(
          a =>
            (diffData = Object.assign(diffData, {
              [a.field]: newData[a.field]
            }))
        );

      diffData = Object.assign(diffData, { status: newData.status });

      // 두 객체가 다를 경우 다른 키: 밸류 객체 만들어서 리턴
      return diffData;
    },
    /**
     * 밸리데이터 함수
     * @params {Array} params
     * @return {Boolean}
     */
    validateHelper(params) {
      const notValidList = params.filter(a => !a.isValidate);

      if (!isEmpty(notValidList)) {
        this.alert({
          type: "warning",
          title: this.i18n("label.confirm"),
          message: notValidList[0].message,
          buttons: [{ 
            label: this.i18n("button.confirm"),
            onPress: () => { // date-picker를 쓰는 inputDate, inputTime, inputMonth일 경우
              if(!isEmpty(notValidList[0].datePicker) && notValidList[0].datePicker) 
              notValidList[0].ref && notValidList[0].ref.focus()
            }
          }]
        });
        
        if(isEmpty(notValidList[0].datePicker)) // inputDate, inputTime, inputMonth을 제외한 경우
        notValidList[0].ref && notValidList[0].ref.focus()

        return true;
      } else {
        return false;
      }
    },
  }
};
