/**
 * 1. 개요 : 사은품관리번호검색
 * 2. 처리내용 : 사은품관리번호검색 Mixins - 애니메이션 helper
 * @File Name : AnimationHelper.js
 * @date : 2022. 11. 18.  14:55:00
 * @author : 김준석
 * @history :
 *-----------------------------------------------------------------------
 * 변경일			          		    작성자		변경내용
 *-----------------------------------------------------------------------
 * 2022. 11. 18.  14:55:00	    김준석	  최초 작성
 *-----------------------------------------------------------------------
 */
import { EventBus } from "../EventBus";

export default {
	data() {
		return {
			gridAniTimeout: null
		}
	},
	beforeDestroy() {
		clearTimeout(this.gridAniTimeout)
	},
	methods: {
    /**
     * 검색영역 토글 이벤트
     * @return {Function}
     */
		fnShowSearchPane() {
			return this._fnShowSearchPane(EventBus, this)
    },
    /**
     * 검색 세부 영역 토글 이벤트
     * @return {Function}
     */
		fnShowInnerSearchPane(){
			return this._fnShowInnerSearchPane(EventBus, this)
    },
    /**
     * 그리드 애니메이션
     * @return {Function}
     */
		fnGridAni(){
			return this._fnGridAni(this)
		},

    //팝업 A 타입
    fnShowPaSearchPane() {
      let trLen = document.querySelectorAll(".search-area tr").length;

      this.isPaSearchPaneShow = !this.isPaSearchPaneShow;
      this.fnGridAni(); //애니추가

      if (this.isInnerSearchPaneShow) this.fnShowInnerSearchPane();

      let btnAreaH = document.querySelector(".grid-top-box").scrollHeight; // 서브 제목 + 버튼 height
      this.gridProps.gridHeight = 600 - btnAreaH; // 처음 height
      // searchPane 열리고 닫힐 때 그리드 사이즈 조절
      this.isPaSearchPaneShow
        ? (this.gridProps.gridHeight = this.gridProps.gridHeight - trLen * 41)
        : (this.gridProps.gridHeight = 600 - btnAreaH);
    },

    //팝업 C 타입
    fnShowPcSearchPane() {
      let trLen = document.querySelectorAll(".search-area tr").length;

      this.isPcSearchPaneShow = !this.isPcSearchPaneShow;
      this.fnGridAni(); //애니추가

      if (this.isInnerSearchPaneShow) this.fnShowInnerSearchPane();

      let btnAreaH = document.querySelector(".grid-top-box").scrollHeight; // 서브 제목 + 버튼 height
      this.dataTableHeight = 600 - btnAreaH; // 처음 height
      // searchPane 열리고 닫힐 때 그리드 사이즈 조절
      this.isPcSearchPaneShow
        ? (this.dataTableHeight = this.dataTableHeight - trLen * 41)
        : (this.dataTableHeight = 600 - btnAreaH);
    },

    //팝업 E 타입
    fnShowPeSearchPane() {
      let trLen = document.querySelectorAll(".search-area tr").length;

      this.isPeSearchPaneShow = !this.isPeSearchPaneShow;
      this.fnGridAni(); //애니추가

      if (this.isInnerSearchPaneShow) this.fnShowInnerSearchPane();

      let btnAreaH = document.querySelector(".grid-top-box").scrollHeight; // 서브 제목 + 버튼 height
      this.gridProps.gridHeight = 300 - btnAreaH; // 처음 height
      // searchPane 열리고 닫힐 때 그리드 사이즈 조절
      this.isPcSearchPaneShow
        ? (this.gridProps.gridHeight = this.gridProps.gridHeight - trLen * 41)
        : (this.gridProps.gridHeight = 300 - btnAreaH);
    }
  }
};
