/**
 * 1. 개요 : Minputs 모바일 공통 컴포넌트 - MInputDate
 * 2. 처리내용 : MInputDate - 진입점
 * File Name : index.vue
 * date : 2020. 05. 12. 10:40:00
 * author : 이지혜
 * history :
 *-----------------------------------------------------------------------
 * 변경일			          		  작성자		변경내용
 *-----------------------------------------------------------------------
 * 2020. 05. 12. 10:40:00	    이지혜	  최초 작성
 *-----------------------------------------------------------------------
 */
<template>
	<div class="input-date date-box">
		<date-picker
			:key="updateKey"
			v-model="date"
			v-bind="$attrs"
			value-type="YYYYMMDD"
			:type="type"
			:lang="lang"
			:editable="editable"
			:format="nationalDateFormat"
			:clearable="editable"
			@change="value => $emit('apply', value)"
			@input="updateDate()"
			v-on="$listeners"
			append-to-body
			:placeholder="nationalDateFormat"
		/>
	</div>
</template>

<script>
import DatePicker from "vue2-datepicker";
import 'vue2-datepicker/index.css';
import moment from "moment";
import { isEmpty } from "@/utils";

export default {
	name: "MInputDate",

	components: {
		DatePicker
	},

	model: {
		prop: "value",
		event: "change"
	},

	props: {
		value: {
			type: String,
			default: ""
		},
		type: {
			type: String,
			default: "date"
		},
		placeholder: {
			type: String,
			default: ""
		},
		editable: {
			type: Boolean,
			default: true
		},
	},

	data() {
		return {
			updateKey: 0,
			date: moment(this.value, "YYYYMMDD").format("YYYYMMDD"),
			kr: {
				days: ["일", "월", "화", "수", "목", "금", "토"],
				months: [
					"1월",
					"2월",
					"3월",
					"4월",
					"5월",
					"6월",
					"7월",
					"8월",
					"9월",
					"10월",
					"11월",
					"12월"
				],
				pickers: ["다음 7일", "다음 30일", "이전 7일", "이전 30일"],
				placeholder: {
					date: "날짜 선택",
					dateRange: "기간 선택"
				}
			}
		};
	},

	computed: {
		/**
		 * 언어에 따라 달력 리소스 정의
		 * @return {Object}
		 */
		lang() {
			if (this.msgLangCd === "en_US" || this.msgLangCd === "vi_VN") return "en";
			return this.kr;
		},
		/**
		 * (나라에 따라)데이터 피커에 보여지는 포맷 정의
		 * @return {String}
		 */
		nationalDateFormat() {
			return (() => {
				switch (this.msgLangCd) {
					case "en_US":
						return "MM/DD/YYYY";
					case "vi_VN":
						return "MM-DD-YYYY";
					case "ko_KR":
						return "YYYY.MM.DD";
					default:
						return "MM/DD/YYYY";
				}
			})();
		}
	},

	watch: {
		// value(value) {
		// 	console.log("update date value");
		// 	// IE에서 최초생성 후 model변경을 통한 UI갱싱 문제, 키 갱신을 통한 강제 처리 중
		// 	this.refresh(value)
		// },
		value(value) {
			if (value === null || value === undefined) {
				// null일 경우 빈값으로 보냄. 11/25
				value = "";
				this.$emit("dateValue", value);
				return;
			}

			this.$emit("dateValue", value);
			// console.log(value, "update date value");

			// IE에서 최초생성 후 model변경을 통한 UI갱싱 문제, 키 갱신을 통한 강제 처리 중
			this.refresh(value);
		}
	},
	mounted() {
		if( this.date != "" ) this.refresh(this.date);
	},

	methods: {
		/**
		 * 날짜 선택시 실행.
		 */
		updateDate() {
			this.$emit('input', this.date)
			this.updateKey = this.updateKey + 1
		},
		/**
		 * 선택한 날짜를 입력시키고 리프레시
		 * @params {String} value
		 */
		refresh(value) {
			this.date = moment(value, "YYYYMMDD").format("YYYYMMDD");
			this.updateKey = this.updateKey + 1
		}
	}
};
</script>

<style lang="scss" scoped>

</style>

<style lang="scss">
// @import "@/scss/_color.scss";

//date-picker
.mobile{
	.date-box{
		height:30px;
	}
	.input-date{
		display: block !important;
		&+button,
		&+.search-combo,
		&+.input-text,
		&+.input-date{
			margin-top:5px;
		}
	}
	.mx-datepicker {
		position: relative;
		&-popup{z-index:1004}
		.mx-icon-calendar{
			top:6px;
			right:5px;
			width: 15px;
			height:17px;
			background: url("~@/assets/img/sp-mob.png") no-repeat -450px -50px;
			background-size:500px;
			transform:translateY(0);
			svg{
				display:none;
			}
		}
	}

	.mx-input-wrapper {
		height: 100%;
	}

	.mx-input{
		height:30px;
		padding:0 10px;
		font-size:12px;
		border-radius:0;
		box-shadow:none;
	}
	.mx-input-append {
		width: 30px;
		height: 30px;
		margin-right:1px;
		padding:5px;
	}

	.mx-clear-wrapper {
		display: none !important;
		line-height: 1 !important;
	}

}
</style>
