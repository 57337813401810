/**
 * 1. 개요 : Inputs 공통 컴포넌트
 * 2. 처리내용 : Inputs 공통 컴포넌트 - InputDate
 * @File Name : index.vue
 * @date : 2020. 02. 20. 09:46:00
 * @author : 이상철
 * @history :
 *-----------------------------------------------------------------------
 * 변경일			          		  작성자		변경내용
 *-----------------------------------------------------------------------
 * 2020. 2. 20. 09:46:00	    이상철	  최초 작성
 *-----------------------------------------------------------------------
 */
<template>
	<div class="input-date date-box" :class="{'only-date': hideInputTime }">
		<date-picker
			:key="updateKey"
			v-model="date"
			v-bind="$attrs"
			value-type="YYYYMMDD"
			:type="type"
			:lang="lang"
			:editable="editable"
			:format="nationalDateFormat"
			:clearable="editable"
			@change="value => $emit('apply', value)"
			@input="updateDate()"
			v-on="$listeners"
			append-to-body
			:placeholder="nationalDateFormat"
		/>
	</div>
</template>

<script>
import "vue2-datepicker/index.css";
import moment from "moment";
import DatePicker from "vue2-datepicker";
import { isEmpty } from "@/utils";

export default {
	name: "InputDate",

	components: {
		DatePicker
	},

	model: {
		prop: "value",
		event: "change"
	},

	props: {
		value: {
			type: String,
			default: ""
		},
		type: {
			type: String,
			default: "date"
		},
		placeholder: {
			type: String,
			default: ""
		},
		editable: {
			type: Boolean,
			default: true
		},
		hideInputTime: {
			type: Boolean,
			default: false
		}
	},

	data() {
		return {
			updateKey: 0,
			date: moment(this.value, "YYYYMMDD").format("YYYYMMDD"),
			kr: {
				days: ["일", "월", "화", "수", "목", "금", "토"],
				months: [
					"1월",
					"2월",
					"3월",
					"4월",
					"5월",
					"6월",
					"7월",
					"8월",
					"9월",
					"10월",
					"11월",
					"12월"
				],
				pickers: ["다음 7일", "다음 30일", "이전 7일", "이전 30일"],
				placeholder: {
					date: "날짜 선택",
					dateRange: "기간 선택"
				}
			}
		};
	},

	computed: {
		/**
		 * 언어에 따라 달력 리소스 정의
		 * @return {Object}
		 */
		lang() {
			if (this.msgLangCd === "en_US" || this.msgLangCd === "vi_VN") return "en";
			return this.kr;
		},
		/**
		 * (나라에 따라)데이터 피커에 보여지는 포맷 정의
		 * @return {String}
		 */
		nationalDateFormat() {
			return (() => {
				switch (this.msgLangCd) {
					case "en_US":
						return "MM/DD/YYYY";
					case "vi_VN":
						return "MM-DD-YYYY";
					case "ko_KR":
						return "YYYY.MM.DD";
					default:
						return "MM/DD/YYYY";
				}
			})();
		}
	},

	watch: {
		value(value) {
			if (value === null || value === undefined) {
				// null일 경우 빈값으로 보냄. 11/25
				value = "";
				this.$emit("dateValue", value);
				return;
			}

			this.$emit("dateValue", value);
			// console.log(value, "update date value");

			// IE에서 최초생성 후 model변경을 통한 UI갱싱 문제, 키 갱신을 통한 강제 처리 중
			this.refresh(value);
		}
	},
	mounted() {
		if (this.date != "") this.refresh(this.date);
	},

	methods: {
		/**
		 * 날짜 선택시 실행.
		 */
		updateDate() {
			this.$emit("input", this.date);
			this.updateKey = this.updateKey + 1;
		},
		/**
		 * 선택한 날짜를 입력시키고 리프레시
		 * @params {String} value
		 */
		refresh(value) {
			this.date = moment(value, "YYYYMMDD").format("YYYYMMDD");
			this.updateKey = this.updateKey + 1;
		}
	}
};
</script>

<style lang="scss" scoped></style>

<style lang="scss">
// @import "@/scss/_color.scss";

//date-picker
.pc {
	.date-box {
		display: inline-block;
		vertical-align: middle;
		width: 170px;
		height: 27px;
		& + button,
		& + .text-box,
		& + .time-box,
		& + .date-box {
			margin-left: 3px; // 8월 28일 이지혜 수정. 간격이 안맞아서 4px에서 3px으로 변경.
		}
	}
	.mx-datepicker {
		position: relative;
		display: inline-block;
		width: 170px;
		&-popup {
			z-index: 1004;
		}
		.mx-icon-calendar {
			top: 4px;
			right: 5px;
			width: 15px;
			height: 17px;
			background: url("~@/assets/img/sp-pc.png") no-repeat -450px -50px;
			transform: translateY(0);
			svg {
				display: none;
			}
		}
	}

	.mx-input-wrapper {
		height: 100%;
	}

	.mx-input {
		display: inline-block;
		height: 27px;
		padding: 0 10px;
		font-size: 12px;
		vertical-align: middle;
		border-radius: 0;
		box-shadow: none;
	}
	.mx-input-append {
		display: inline-block;
		width: 27px;
		height: 27px;
		margin-right: 1px;
		padding: 5px;
	}

	.mx-clear-wrapper {
		display: none !important;
		line-height: 1 !important;
	}

	// .input-date + .input-date{margin-left:5px;}

	.combo-box {
		font-size: 0;
		.date-box {
			width: 115px !important;
			& + .mx-datepicker,
			& + .date-box {
				width: 115px !important;
				margin-left: 4px;
			}
		}
	}
	.combo-box-2 {
		// 9월 1일 이지혜 추가. 날짜+시간 combo-box인데 input-time이 더 작음.
		text-align: center;
		font-size: 0;
		.date-box {
			width: 115px;
			.mx-datepicker,
			.date-box {
				width: 115px;
			}
		}
	}

	.combo-box-3 {
		// 11.25 추가1. 팝업 > table > 왼쪽 정렬"input-date + input-time" 결합된 형태
		display: inline-block;
		text-align: center;
		font-size: 0;
		.date-box {
			// input-date
			width: 115px;
			.mx-datepicker,
			.date-box {
				width: 115px;
			}
		}
		.time-box {
			// input-time. 콤보박스3으로 쓸 경우 placeholder는 무조건 "i18n('label.hour')"
			width: 85px;
			.mx-datepicker,
			.time-box {
				width: 85px;
			}
		}
		&-btn {
			// 왼쪽정렬일 경우 버튼 붙일 때
			transform: translate(0, 3px);
			margin-left: 5px;
		}
	}

	.combo-box-3-add {
		// 11.25 추가3. 팝업 > table > 가운데 정렬로 "input-date + input-time + button"이 결합된 형태일 때
		margin-left: 59px;
		transform: translate(0, -2px);
		display: inline-block;
		&-btn {
			transform: translate(0, 1px);
			margin-left: 5px;
		}
	}

	.prft-lss-bas-dat-mng-date-renderer {
		// TODO: 시간이 너무 걸려서 하다 말았음. 나중에 하자.
		.mx-datepicker {
			width: 100px;
			transform: translate(0, -7px);
		}
		.mx-input-wrapper {
			width: 100px;
			transform: translate(0, -7px);
		}
		input {
			width: 100px;
			transform: translate(0, -7px);
		}
	}
}
</style>
