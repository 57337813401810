/**
 * 1. 개요 : LangChange
 * 2. 처리내용 : Navbar - 언어 변경 컴포넌트
 * @File Name : index.vue
 * @date : 2020. 02. 20. 09:46:00
 * @author : 이상철
 * @history :
 *-----------------------------------------------------------------------
 * 변경일			          		  작성자		변경내용
 *-----------------------------------------------------------------------
 * 2020. 2. 20. 09:46:00	    이상철	  최초 작성
 *-----------------------------------------------------------------------
 */
<template>
	<div class="lang-box">
		<span class="title">{{ 'label.lang' | i18n }}</span>
		<ul>
			<li v-for="code in msgLangCdList" :key="code.codeId" :class="code.codeId">
				<a
					href="javascript:;"
					@click="go(code.codeId)"
					:class="{ selected: code.codeId === userMsgLangCd }"
				>{{ code.codeName }}</a>
			</li>
		</ul>
	</div>
</template>

<script>
export default {
	name: "LangChange",
	data() {
		return {
			userMsgLangCd: ""
		};
	},
	computed: {
		userInfo() {
			return this.$store.getters["Options/userInfo"];
		},
		msgLangCdList() {
			return this.getCommonCodeList("MSG_LANG_CD"); 
		}
	},
	mounted() {
		this.userMsgLangCd = this.userInfo.msgLangCd;
	},
	methods: {
		go(selectedLangCd) {
			this.userMsgLangCd = selectedLangCd;

			this.$httpPost(
				"/api/com/updateUserLanguage.do",
				{
					userId: this.userInfo.userId,
					regUserId: this.userInfo.userId,
					msgLangCd: selectedLangCd
				},
				{
					showLoading: true
				}
			)
				.then(res => {
					const { accesstoken } = res.headers;

					this.$store.commit("Options/SET_USER_INFO", {
						accesstoken,
						msgLangCd: res.data.msgLangCd
					});
					// this.setUserInfo({
					// 	accesstoken,
					// 	msgLangCd: res.data.msgLangCd
					// });
					this.$emit("close");

					sessionStorage.setItem("reloaded", "Y");
					setTimeout(function() {
						window.location.href = "/";
					}, 500);
				})
				.catch(this.commonErrorHandler);
		}
	}
};
</script>

<style lang="scss" scoped>
@import "@/scss/_color.scss";
@import "@/scss/_input.scss";

.lang-box {
	// min-width:120px;
	height: 33px;
	padding: 3px 12px 0;
	text-align: left;
	border-radius: 16px;
	background-color: $cec;
	box-shadow: inset 0px 2px 3px 0px rgba(0, 0, 0, 0.2);
	.title {
		margin-top: 2px;
		font-size: 11px;
		color: $c6;
		letter-spacing: -0.5px;
		font-weight: bold;
		vertical-align: middle;
	}
	ul {
		display: inline-block;
		vertical-align: middle;
		li {
			position: relative;
			display: inline-block;
			width: 26px;
			height: 28px;
			margin-left: 6px;
			vertical-align: middle;
			&::before {
				content: "";
				display: block;
				width: 26px;
				height: 28px;
				background: url(~@/assets/img/sp-pc.png) no-repeat;
			}
			&.ko_KR::before {
				background-position: -50px 0;
			}
			&.en_US::before {
				background-position: -100px 0;
			}
			&.jp_JP::before {
				background-position: -474px -71px;
			}
			a {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				font-size: 0;
				&.selected::after {
					content: "";
					position: absolute;
					left: 2px;
					bottom: -2px;
					width: 22px;
					height: 2px;
					background-color: $pale;
				}
			}
		}
	}
}
</style>
