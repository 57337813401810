var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "lang-box" }, [
    _c("span", { staticClass: "title" }, [
      _vm._v(_vm._s(_vm._f("i18n")("label.lang")))
    ]),
    _c(
      "ul",
      _vm._l(_vm.msgLangCdList, function(code) {
        return _c("li", { key: code.codeId, class: code.codeId }, [
          _c(
            "a",
            {
              class: { selected: code.codeId === _vm.userMsgLangCd },
              attrs: { href: "javascript:;" },
              on: {
                click: function($event) {
                  return _vm.go(code.codeId)
                }
              }
            },
            [_vm._v(_vm._s(code.codeName))]
          )
        ])
      }),
      0
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }