/**
 * 1. 개요 : 공통 컴포넌트
 * 2. 처리내용 : 공통 컴포넌트 - Hamburger
 * @File Name : index.vue
 * @date : 2020. 02. 20. 09:46:00
 * @author : 이상철
 * @history :
 *-----------------------------------------------------------------------
 * 변경일			          		  작성자		변경내용
 *-----------------------------------------------------------------------
 * 2020. 2. 20. 09:46:00	    이상철	  최초 작성
 *-----------------------------------------------------------------------
 */
<template>
  <div @click="toggleClick">
    <span>{{ 'button.menuOpenClose' | i18n }}</span>
  </div>
</template>

<script>
export default {
  name: 'Hamburger',
  // props: {
  //   isActive: {
  //     type: Boolean,
  //     default: false
  //   }
  // },
  methods: {
    /**
     * 사이드바 토글 버튼을 누를 때마다 실행
     */
    toggleClick() {
      this.$emit('toggleClick')
    }
  }
}
</script>

<style lang="scss" scoped>
.pc{
  .hamburger-container{
    font-size:0;
    svg{
      width: 20px;
      height: 20px;
    }
    > span{ // 원래 a태그였는데 span 태그로 바꿈. 8월 31일 이지혜.
      cursor: pointer; // span 태그가 되면서 추가.
      display:block;
      bottom: -15px;
      left:-28px;
      width: 13px;
      height: 58px;
      font-size: 0;
      background:url(~@/assets/img/sp-pc.png) no-repeat -100px -50px;
      &::before{
        content: "";
        display: block;
        position: absolute;
        top: calc(50% - 7px);
        left: -1px;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        border-style: solid;
        border-color: transparent #fff transparent transparent;
        border-width: 5px;
      }
    }
  }
  .hideSidebar {
    .hamburger-container{
      > a{
        &::before{
          left:4px;
          transform:rotate(180deg);
        }
      }
    }
  }
}
</style>