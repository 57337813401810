var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "label",
    {
      staticClass: "check-box",
      class: {
        disabled: _vm.disabled,
        checked: _vm.checkedState,
        inline: _vm.inline
      },
      attrs: { role: "checkbox", tabindex: "0" }
    },
    [
      _c(
        "input",
        _vm._g(
          _vm._b(
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.checkModel,
                  expression: "checkModel"
                }
              ],
              ref: "checkbox",
              attrs: { type: "checkbox", disabled: _vm.disabled },
              domProps: {
                value: _vm.value,
                checked: Array.isArray(_vm.checkModel)
                  ? _vm._i(_vm.checkModel, _vm.value) > -1
                  : _vm.checkModel
              },
              on: {
                change: [
                  function($event) {
                    var $$a = _vm.checkModel,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false
                    if (Array.isArray($$a)) {
                      var $$v = _vm.value,
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 && (_vm.checkModel = $$a.concat([$$v]))
                      } else {
                        $$i > -1 &&
                          (_vm.checkModel = $$a
                            .slice(0, $$i)
                            .concat($$a.slice($$i + 1)))
                      }
                    } else {
                      _vm.checkModel = $$c
                    }
                  },
                  _vm.onChange
                ]
              }
            },
            "input",
            _vm.$attrs,
            false
          ),
          _vm.$listeners
        )
      ),
      _c("span", { staticClass: "check-box-icon" }),
      _vm.$slots.default
        ? _c("span", { staticClass: "label" }, [_vm._t("default")], 2)
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }