// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@/assets/img/sp-mob.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".mobile .time-box {\n  position: relative;\n  height: 30px;\n}\n.mobile .time-box .mx-icon-calendar {\n    top: 6px;\n    width: 18px;\n    height: 18px;\n    background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat -450px 0;\n    background-size: 500px;\n    -webkit-transform: translateY(0);\n            transform: translateY(0);\n}\n.mobile .time-box .mx-icon-calendar svg {\n      display: none;\n}\n.mobile .time-box + .time-box,\n  .mobile .time-box + button,\n  .mobile .time-box + .date-box {\n    margin-top: 5px;\n}\n", ""]);
// Exports
module.exports = exports;
