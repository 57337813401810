/**
 * 1. 개요 : store
 * 2. 처리내용 : store - 최초 진입점
 * @File Name : index.js
 * @date : 2020. 02. 20. 09:46:00
 * @author : 이상철
 * @history :
 *-----------------------------------------------------------------------
 * 변경일			          		  작성자		변경내용
 *-----------------------------------------------------------------------
 * 2020. 2. 20. 09:46:00	    이상철	  최초 작성
 *-----------------------------------------------------------------------
 */
import Vue from "vue";
import Vuex from "vuex";

import Alert from "./modules/Alert";
import CommonCode from "./modules/CommonCode";
import I18n from "./modules/I18n";
import Loading from "./modules/Loading";
import Options from "./modules/Options";
import Sidebar from "./modules/Sidebar";
import Device from "./modules/Device";
import QuickMenu from "./modules/QuickMenu";
import Settings from "./modules/Settings";
import TagsView from "./modules/TagsView";

import bep from "./modules/bep";
import alerts from "./modules/alerts";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    Alert,
    CommonCode,
    I18n,
    Loading,
    Options,
    Sidebar,
    Device,
    QuickMenu,
    Settings,
    TagsView,
    ...alerts,
    ...bep
  }
});
