var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("span", { on: { click: _vm.quickMenuClick } }, [
      _vm._v(_vm._s(_vm._f("i18n")("button.showQuickMenu")) + " ")
    ]),
    _c("span", { staticClass: "menu-list", on: { click: _vm.listMenuClick } }, [
      _vm._v(_vm._s(_vm._f("i18n")("button.showMenuList")))
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }