export default [
  {
    name: "PsnMng",
    path: "/biz/bep/user/PsnMng",
    component: () => import("@/views/biz/bep/user/PsnMng"),
    meta: { layout: "admin" }
  },{
    name: "BizMng",
    path: "/biz/bep/user/BizMng",
    component: () => import("@/views/biz/bep/user/BizMng"),
    meta: { layout: "admin" }
  },
  {
    name: "DistrMng",
    path: "/biz/bep/user/DistrMng",
    component: () => import("@/views/biz/bep/user/DistrMng"),
    meta: { layout: "admin" }
  },
  {
    name: "AgncyMng",
    path: "/biz/bep/user/AgncyMng",
    component: () => import("@/views/biz/bep/user/AgncyMng"),
    meta: { layout: "admin" }
  },
  {
    name: "CardBinMng",
    path: "/biz/bep/merinst/CardBinMng",
    component: () => import("@/views/biz/bep/merinst/CardBinMng"),
    meta: { layout: "admin" }
  },
  {
    name: "InstRespCdMng",
    path: "/biz/bep/svc/InstRespCdMng",
    component: () => import("@/views/biz/bep/svc/InstRespCdMng"),
    meta: { layout: "admin" }
  },
  {
    name: "AlxRespCdMng",
    path: "/biz/bep/svc/AlxRespCdMng",
    component: () => import("@/views/biz/bep/svc/AlxRespCdMng"),
    meta: { layout: "admin" }
  },
]
