/**
 * 1. 개요 : 로그인
 * 2. 처리내용 : 로그인 - 비밀번호 변경 팝업 컴포넌트
 * @File Name : ChangePasswordPopup.vue
 * @date : 2020. 02. 20. 09:46:00
 * @author: 이상철
 * @history :
 * -----------------------------------------------------------------------
 * 변경일                     작성자          변경내용
 * -----------------------------------------------------------------------
 * 2020.2. 20. 09:46:00       이상철          최초 작성
 * -----------------------------------------------------------------------
 */
<template>
	<popup :title="title" @close="$emit('close')" popSize="size-500">
		<data-table class="table">
			<template slot="caption">{{ title }}</template>
			<template slot="colgroup">
				<col style="width:150px" />
				<col style="width:auto" />
			</template>
			<template slot="tbody">
				<tr v-if="this.chgPassOrgType === 'LOG_OUT'">
					<th scope="row">
						<label for="changePwVORegUserId">
							{{
							this.isLogin
							? this.i18n('label.userId') : i18nOff("label.userId")
							}}
						</label>
					</th>
					<td style="width: 100%">
						<label id="changePwVORegUserId">{{ userId }}</label>
					</td>
				</tr>
				<tr>
					<th scope="row">
						<label class="required" for="changePwVOOrgPassword">
							<span class="star">
								{{ this.isLogin
								? this.i18n('label.require') : i18nOff("label.require") }}{{ this.isLogin
								? this.i18n('label.input') : i18nOff("label.input") }}
							</span>
							{{
							this.isLogin
							? this.i18n('label.orgPassword') : i18nOff("label.orgPassword")
							}}
						</label>
					</th>
					<td>
						<input-text
							id="changePwVOOrgPassword"
							v-model="changePwVO.orgPassword"
							:type="orgPasswordType"
							:maxlength="20"
							:placeholder="`${this.isLogin
							? this.i18n('label.orgPassword') : i18nOff('label.orgPassword')}`"
							ref="changePwVOOrgPassword"
							@keydown.enter.prevent="validate"
						/>
						<button type="button" class="el-eye default" @click.stop="showOrgPassword" data-icon="off">
							{{
							orgPasswordType === "password"
							? (this.isLogin
							? this.i18n('label.viewPassword') : i18nOff('label.viewPassword'))
							: (this.isLogin
							? this.i18n('label.notViewPassword') : i18nOff('label.notViewPassword'))
							}}
							<img
								src="~@/assets/img/icon/eye-open.svg"
								width="24"
							/>
						</button>
					</td>
				</tr>
				<tr>
					<th scope="row">
						<label class="required" for="changePwVONewPassword">
							<span class="star">
								{{ this.isLogin
								? this.i18n('label.require') : i18nOff("label.require") }}{{ this.isLogin
								? this.i18n('label.input') : i18nOff("label.input") }}
							</span>
							{{this.isLogin
							? this.i18n('label.newPassword') : i18nOff("label.newPassword")}}
						</label>
					</th>
					<td>
						<input-text
							id="changePwVONewPassword"
							ref="changePwVONewPassword"
							v-model="changePwVO.newPassword"
							:type="newPasswordType"
							:maxlength="20"
							:placeholder="`${this.isLogin
							? this.i18n('label.newPassword') : i18nOff('label.newPassword')}`"
							@keydown.enter.prevent="validate"
						/>
						<button type="button" class="el-eye new" @click.stop="showNewPassword" data-icon="off">
							{{
							newPasswordType === "password"
							? (this.isLogin
							? this.i18n('label.viewPassword') : i18nOff('label.viewPassword'))
							: (this.isLogin
							? this.i18n('label.notViewPassword') : i18nOff('label.notViewPassword'))
							}}
							<img
								src="~@/assets/img/icon/eye-open.svg"
								width="24"
							/>
						</button>
					</td>
				</tr>
				<tr>
					<th scope="row">
						<label class="required" for="changePwVOConfirmPassword">
							<span class="star">
								{{ this.isLogin
								? this.i18n('label.require') : i18nOff("label.require") }}{{ this.isLogin
								? this.i18n('label.input') : i18nOff("label.input") }}
							</span>
							{{ this.isLogin
							? this.i18n('label.confirmPassword') : i18nOff("label.confirmPassword") }}
						</label>
					</th>
					<td>
						<input-text
							id="changePwVOConfirmPassword"
							v-model="changePwVO.confirmPassword"
							:type="confirmPasswordType"
							:placeholder="`${this.isLogin
							? this.i18n('label.confirmPassword') : i18nOff('label.confirmPassword')}`"
							:maxlength="20"
							ref="changePwVOConfirmPassword"
							@keydown.enter.prevent="validate"
						/>
						<button
							type="button"
							class="el-eye confirm"
							@click.stop="showConfirmPassword"
							data-icon="off"
						>
							{{
							confirmPasswordType === "password"
							? (this.isLogin
							? this.i18n('label.viewPassword') : i18nOff('label.viewPassword'))
							: (this.isLogin
							? this.i18n('label.notViewPassword') : i18nOff('label.notViewPassword'))
							}}
							<img
								src="~@/assets/img/icon/eye-open.svg"
								width="24"
							/>
						</button>
					</td>
				</tr>
			</template>
		</data-table>
		<template slot="buttons">
			<primary-button @click="validate">
				{{
				this.isLogin
				? this.i18n('label.change') : i18nOff("label.change")
				}}
			</primary-button>
			<default-button @click="clear">
				{{
				this.isLogin
				? this.i18n('button.init') : i18nOff("button.init")
				}}
			</default-button>
			<default-button @click="$emit('close')">
				{{
				this.isLogin
				? this.i18n('button.cancel') : i18nOff("button.cancel")
				}}
			</default-button>
		</template>
	</popup>
</template>

<script>
import { validPassword } from "@/utils/validate";
import { Helper } from "../mixins";
import { isEmpty } from "@/utils";
import DataTable from "@/components/DataTable";
import Popup from "@/components/Popup";

export default {
	name: "ChangePasswordPopup",
	components: {
		DataTable,
		Popup
	},
	mixins: [Helper],
	props: {
		userId: {
			type: String,
			default: ""
		},
		chgPassOrgType: {
			type: String,
			default: ""
		}
	},
	data() {
		return {
			changePasswordToken: "",
			changePwVO: {
				orgPassword: "",
				newPassword: "",
				confirmPassword: ""
			},
			orgPasswordType: "password",
			newPasswordType: "password",
			confirmPasswordType: "password",
			qrCd: "",
			orgInit: false,
			newInit: false,
			confirmInit: false
		};
	},
	computed: {
		// 팝업창 타이틀
		title() {
			return this.isLogin
				? this.i18n("label.pwdChg")
				: this.i18nOff("label.pwdChg");
		},
		isChangePasswordToken() {
			return !isEmpty(this.changePasswordToken);
		},
		userInfo() {
			return this.$store.getters["Options/userInfo"];
		}
	},
	mounted() {
		this.$nextTick(() => this.$refs.changePwVOOrgPassword.focus());
	console.log("this.userId",this.userId);
	
		if(this.userId != ''){
				this.getQrCode(this.userId);
		}
	},
	methods: {
		/**
		 * 변경된 비밀번호 서버로 전송
		 */
		onChangePassword() {
			let data;

			if (this.chgPassOrgType === "LOG_OUT") {
				data = {
					chkUserPass: this.changePwVO.orgPassword,
					regUserPass: this.changePwVO.newPassword,
					chgPassOrgType: this.chgPassOrgType,
					regUserId: this.userId
				};
			} else {
				data = {
					chkUserPass: this.changePwVO.orgPassword,
					regUserPass: this.changePwVO.newPassword,
					chgPassOrgType: this.chgPassOrgType,
					msgLangCd: this.userInfo.msgLangCd
				};
			}

			this.$httpPost("/api/com/changePassword.do", data, {
				showLoading: true
			})
				.then(res => {
					this.alert({
						type: "warning",
						title: this.isLogin
							? this.i18n("label.confirm")
							: this.i18nOff("label.confirm"),
						message: this.isLogin
							? this.i18n("alt.complateChangedPw")
							: this.i18nOff("alt.complateChangedPw"),
						buttons: [
							{
								label: this.isLogin
									? this.i18n("label.confirm")
									: this.i18nOff("label.confirm")
							}
						]
					});
				})
				.then(() => this.$emit("close"))
				.catch(this.commonErrorHandler);
		},
		/**
		 * 입력화면 초기화
		 */
		clear() {
			this.changePwVO = {
				orgPassword: "",
				newPassword: "",
				confirmPassword: ""
			};
			this.orgPasswordType = "password";
			this.orgInit = true;
			this.showOrgPassword();

			this.newPasswordType = "password";
			this.newInit = true;
			this.showNewPassword();

			this.confirmPasswordType = "password";
			this.confirmInit = true;
			this.showConfirmPassword();

			this.$nextTick(() => this.$refs.changePwVOOrgPassword.focus());
		},
		/**
		 * 기존 비밀번호 암호화 해제
		 */
		showOrgPassword() {
			let eleye = document.querySelector(".el-eye.default");
			if (this.orgPasswordType === "password" && this.orgInit === true) {
				// 초기화시
				this.orgPasswordType = "password";
				eleye.dataset.icon = "off";
				this.orgInit = false;
			} else if (this.orgPasswordType === "password") {
				this.orgPasswordType = "text";
				eleye.dataset.icon === "on"
					? (eleye.dataset.icon = "off")
					: (eleye.dataset.icon = "on");
			} else {
				this.orgPasswordType = "password";
				// eleye.dataset.icon = "off";
				eleye.dataset.icon === "on"
					? (eleye.dataset.icon = "off")
					: (eleye.dataset.icon = "on");
			}

			this.$nextTick(() => this.$refs.changePwVOOrgPassword.focus());
		},
		/**
		 * 새로운 비밀번호 암호화 해제
		 */
		showNewPassword() {
			let eleye = document.querySelector(".el-eye.new");
			if (this.newPasswordType === "password" && this.newInit) {
				// 초기화시
				this.newPasswordType = "password";
				eleye.dataset.icon = "off";
				this.newInit = false;
			} else if (this.newPasswordType === "password") {
				this.newPasswordType = "text";
				eleye.dataset.icon === "on"
					? (eleye.dataset.icon = "off")
					: (eleye.dataset.icon = "on");
			} else {
				this.newPasswordType = "password";
				eleye.dataset.icon === "on"
					? (eleye.dataset.icon = "off")
					: (eleye.dataset.icon = "on");
			}

			this.$nextTick(() => this.$refs.changePwVONewPassword.focus());
		},
		/**
		 * 재입력한 비밀번호 암호화 해제
		 */
		showConfirmPassword() {
			let eleye = document.querySelector(".el-eye.confirm");
			if (this.confirmPasswordType === "password" && this.confirmInit) {
				// 초기화시
				this.confirmPasswordType = "password";
				eleye.dataset.icon = "off";
				this.confirmInit = false;
			} else if (this.confirmPasswordType === "password") {
				this.confirmPasswordType = "text";
				eleye.dataset.icon === "on"
					? (eleye.dataset.icon = "off")
					: (eleye.dataset.icon = "on");
			} else {
				this.confirmPasswordType = "password";
				// eleye.dataset.icon = "off";
				eleye.dataset.icon === "on"
					? (eleye.dataset.icon = "off")
					: (eleye.dataset.icon = "on");
			}
			this.$nextTick(() => this.$refs.changePwVOConfirmPassword.focus());
		},
		/**
		 * 밸리데이션 함수
		 */
		validate() {
			if (
				this.validateHelper([
					{
						ref: this.$refs.changePwVOOrgPassword,
						isValidate:
							this.isChangePasswordToken ||
							!isEmpty(this.changePwVO.orgPassword),
						message: `${
							this.isLogin
								? this.i18n("label.orgPassword")
								: this.i18nOff("label.orgPassword")
						} ${
							this.isLogin
								? this.i18n("alt.required")
								: this.i18nOff("alt.required")
						}` // 12월 11일 메시지 추가
					},
					{
						ref: this.$refs.changePwVONewPassword,
						isValidate: !isEmpty(this.changePwVO.newPassword),
						message: `${
							this.isLogin
								? this.i18n("label.newPassword")
								: this.i18nOff("label.newPassword")
						} ${
							this.isLogin
								? this.i18n("alt.required")
								: this.i18nOff("alt.required")
						}` // 12월 11일 메시지 추가
					},
					{
						ref: this.$refs.changePwVONewPassword,
						isValidate: validPassword(this.changePwVO.newPassword),
						message: `${
							this.isLogin
								? this.i18n("alt.validUserPwd")
								: this.i18nOff("alt.validUserPwd")
						}` // 12월 11일 메시지 추가
					},
					{
						ref: this.$refs.changePwVOConfirmPassword,
						isValidate: !isEmpty(this.changePwVO.confirmPassword),
						message: `${
							this.isLogin
								? this.i18n("label.confirmPassword")
								: this.i18nOff("label.confirmPassword")
						} ${
							this.isLogin
								? this.i18n("alt.required")
								: this.i18nOff("alt.required")
						}` // 12월 11일 메시지 추가
					},
					{
						ref: this.$refs.changePwVOConfirmPassword,
						isValidate: validPassword(this.changePwVO.confirmPassword),
						message: `${
							this.isLogin
								? this.i18n("alt.validUserPwd")
								: this.i18nOff("alt.validUserPwd")
						}` // 12월 11일 메시지 추가
					},
					{
						ref: this.$refs.changePwVONewPassword,
						isValidate:
							this.changePwVO.newPassword === this.changePwVO.confirmPassword,
						message: this.isLogin
							? this.i18n("alt.validCoincidePassword")
							: this.i18nOff("alt.validCoincidePassword")
					}
				])
			) {
				return;
			} else {
				this.alert({
					title: this.isLogin
						? this.i18n("label.pwdChg")
						: this.i18nOff("label.pwdChg"),
					message: this.isLogin
						? this.i18n("alt.questionChangedPw")
						: this.i18nOff("alt.questionChangedPw"),
					buttons: [
						{
							type: "primary",
							label: this.isLogin
								? this.i18n("button.confirm")
								: this.i18nOff("button.confirm"),
							onPress: () => this.onChangePassword()
						},
						{
							label: this.isLogin
								? this.i18n("button.cancel")
								: this.i18nOff("button.cancel")
						}
					]
				});
			}
		}
	}
};
</script>

<style lang="scss" scoped>
.el-eye {
	position: relative;
	top: 0;
	right: -10px;
	font-size: 0;
	background-color: transparent;
	opacity: 0.35;
	[type="password"] {
		padding-right: 40px;
	}
	&[data-icon="off"]::after {
		content: "";
		position: absolute;
		top: -3px;
		left: 11px;
		display: block;
		width: 2px;
		height: 30px;
		background-color: #000;
		transform: rotate(45deg);
		cursor: pointer;
	}
}
</style>
