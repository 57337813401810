/**
 * 1. 개요 : Loading
 * 2. 처리내용 : Loading - 스토어
 * @File Name : Loading.js
 * @date : 2020. 02. 20. 09:46:00
 * @author : 이상철
 * @history :
 *-----------------------------------------------------------------------
 * 변경일			          		  작성자		변경내용
 *-----------------------------------------------------------------------
 * 2020. 2. 20. 09:46:00	    이상철	  최초 작성
 *-----------------------------------------------------------------------
 */
export default {
  state: {
    list: []
  },
  namespaced: true,
  getters: {
    isGlobalLoading: state => !!state.list.length
  },
  mutations: {
    PUSH_LOAD(state, payload) {
      state.list.push(payload);
    },
    REMOVE_LOAD(state, id) {
      state.list = state.list.filter(item => item !== id);
    }
  },
  actions: {
    pushLoad(state, payload) {
      commit("PUSH_LOAD", param);
    },
    removeLoad(state, payload) {
      commit("REMOVE_LOAD", param);
    }
  }
};
