/**
 * 1. 개요 : 사업자관리
 * 2. 처리내용 : 사업자관리 Mixins - 화면 모델
 * @File Name : BizMngViewModel.js
 * @date : 2020. 02. 20. 09:46:00
 * @author : 이상철
 * @history :
 *-----------------------------------------------------------------------
 * 변경일			          		  작성자		변경내용
 *-----------------------------------------------------------------------
 * 2020. 2. 20. 09:46:00	    이상철	  최초 작성
 *-----------------------------------------------------------------------
 */
import { phoneFormatter } from "@/utils/formatter";
import { lookupValue } from "@/utils/dataGrid";

export default {
  methods: {
    bizMngViewModel(params = {}) {
      return [
        {
          field: "rnum",
          headerName: "",
          type: Number,
          width: 50,
          isPk: false,
          editable: false,
          pinned: true,
          sortable: true,
          cellStyle: {
            textAlign: 'center'
          },
          cellClassRules: {
            'selected-cell': param => param.rowIndex === params.rowIndex
          },
          hide: true
        },
        // {
        //   checkboxSelection: true,
        //   headerCheckboxSelection: true,
        //   headerCheckboxSelectionFilteredOnly: true,
        //   width: 35,
        //   suppressMenu: true,
        //   sortable: false,
        //   pinned: true,
        //   cellClassRules: {
        //     'grid-select-box': param => true, // 셀렉트박스일 경우
        //     'grid-radio': param => false // 라디오일 경우
        //   }
        // },
        // {
        //   checkboxSelection: true,
        //   width: 35,
        //   suppressMenu: true,
        //   sortable: false,
        //   pinned: true,
        //   cellClassRules: {
        //     'grid-select-box': param => false, // 셀렉트박스일 경우
        //     'grid-radio': param => true // 라디오일 경우
        //   }
        // },
        {
          field: "bizNo",
          headerName: "사업자번호",
          type: String,
          width: 0,
          isPk: true,
          editable: false,
          pinned: true,
          suppressMenu: false,
          sortable: true,
          cellStyle: {},
          cellClassRules: {}
        },
        {
          field: "bizNm",
          headerName: "사업자명",
          type: String,
          width: 0,
          isPk: false,
          editable: false,
          pinned: false,
          suppressMenu: false,
          sortable: true,
          cellStyle: {},
          cellClassRules: {}
        },
        {
          field: "bizEnm",
          headerName: "사업자영문명",
          type: String,
          width: 0,
          isPk: false,
          editable: false,
          pinned: false,
          suppressMenu: false,
          sortable: true,
          cellStyle: {},
          cellClassRules: {}
        },
        {
          field: "hompgAddr",
          headerName: "홈페이지주소",
          type: String,
          width: 0,
          isPk: false,
          editable: false,
          pinned: false,
          suppressMenu: false,
          sortable: true,
          cellStyle: {},
          cellClassRules: {}
        },
        {
          field: "bizPno",
          headerName: "사업자 우편번호",
          type: String,
          width: 0,
          isPk: false,
          editable: false,
          pinned: false,
          suppressMenu: false,
          sortable: true,
          cellStyle: {},
          cellClassRules: {}
        },
        {
          field: "city",
          headerName: "도시",
          type: String,
          width: 0,
          isPk: false,
          editable: false,
          pinned: false,
          suppressMenu: false,
          sortable: true,
          cellStyle: {},
          cellClassRules: {}
        },
        {
          field: "dist",
          headerName: "지구",
          type: String,
          width: 0,
          isPk: false,
          editable: false,
          pinned: false,
          suppressMenu: false,
          sortable: true,
          cellStyle: {},
          cellClassRules: {}
        },
        {
          field: "ward",
          headerName: "구역",
          type: String,
          width: 0,
          isPk: false,
          editable: false,
          pinned: false,
          suppressMenu: false,
          sortable: true,
          cellStyle: {},
          cellClassRules: {}
        },
        {
          field: "bizAddr",
          headerName: "사업자 주소",
          type: String,
          width: 0,
          isPk: false,
          editable: false,
          pinned: false,
          suppressMenu: false,
          sortable: true,
          cellStyle: {},
          cellClassRules: {}
        },
        {
          field: "psnCorpClCd",
          headerName: "개인법인구분코드",
          type: String,
          width: 0,
          isPk: false,
          editable: false,
          pinned: false,
          suppressMenu: false,
          sortable: true,
          cellStyle: {},
          cellClassRules: {},
          valueFormatter: param => param.value && lookupValue(this.getCommonCodeList('PSN_CORP_CL_CD'), param.value)
        },
        {
          field: "telNo",
          headerName: "전화번호",
          type: String,
          width: 0,
          isPk: false,
          editable: false,
          pinned: false,
          suppressMenu: false,
          sortable: true,
          cellStyle: {},
          cellClassRules: {},
          valueFormatter: param => param.value && phoneFormatter(param.value)
        },
        {
          field: "mccCd",
          headerName: "MCC업종코드",
          type: String,
          width: 0,
          isPk: false,
          editable: false,
          pinned: false,
          suppressMenu: false,
          sortable: true,
          cellStyle: {},
          cellClassRules: {},
          valueFormatter: param => param.value && lookupValue(this.getCommonCodeList('MCC_CD'), param.value)
        },
        {
          field: "sicCd",
          headerName: "SIC업종코드",
          type: String,
          width: 0,
          isPk: false,
          editable: false,
          pinned: false,
          suppressMenu: false,
          sortable: true,
          cellStyle: {},
          cellClassRules: {}
        },
        {
          field: "atchFileFkId",
          headerName: "첨부파일ID",
          type: String,
          width: 0,
          isPk: false,
          editable: false,
          pinned: false,
          suppressMenu: false,
          sortable: true,
          cellStyle: {},
          cellClassRules: {},
          cellRenderer: "FileDownRenderer"
        },
        {
          field: "delYn",
          headerName: "삭제여부",
          type: String,
          width: 0,
          isPk: false,
          editable: false,
          pinned: false,
          suppressMenu: false,
          sortable: true,
          cellStyle: {},
          cellClassRules: {}
        }
      ];
    }
  }
};
