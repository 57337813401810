var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "al-wrap" },
    [
      _c("div", { staticClass: "skip" }, [
        _c("a", { attrs: { href: "#elMenu" } }, [
          _vm._v(_vm._s(_vm._f("i18n")("alt.gotoMenu")))
        ]),
        _c("a", { attrs: { href: "#appMain" } }, [
          _vm._v(_vm._s(_vm._f("i18n")("alt.gotoContents")))
        ])
      ]),
      _c("navbar"),
      _c(
        "div",
        { staticClass: "app-wrapper flex", class: _vm.classObj },
        [
          _c("sidebar", { staticClass: "sidebar-container" }),
          _c(
            "div",
            {
              staticClass: "main-container",
              class: { hasTagsView: _vm.needTagsView }
            },
            [
              _c(
                "div",
                { class: { "fixed-header": _vm.fixedHeader } },
                [_vm.needTagsView ? _c("tags-view") : _vm._e()],
                1
              ),
              _c("app-main", { attrs: { id: "appMain" } }),
              _c("copyright")
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }