/**
 * 1. 개요 : alert 스토어
 * 2. 처리내용 : alert - 스토어 진입점
 * @File Name : index.js
 * @date : 2020. 02. 20. 09:46:00
 * @author : 이상철
 * @history :
 *-----------------------------------------------------------------------
 * 변경일			          		  작성자		변경내용
 *-----------------------------------------------------------------------
 * 2020. 2. 20. 09:46:00	    이상철	  최초 작성
 *-----------------------------------------------------------------------
 */

import TimeoutAlert from "./TimeoutAlert";
import OrtherPcLoginAlert from "./OrtherPcLoginAlert";
import AccessDeniedAlert from "./AccessDeniedAlert";

export default {
  TimeoutAlert,
  OrtherPcLoginAlert,
  AccessDeniedAlert
};
