var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "tooltip" },
    [
      _c("strong", [_vm._v("To-do list")]),
      _c("p", [_vm._v(_vm._s(_vm._f("i18n")("alt.newWorkCheck")))]),
      _c("p", [
        _vm._v(
          _vm._s(_vm._f("i18n")("label.lastConnIp")) +
            " : " +
            _vm._s(_vm.lastConnIp)
        )
      ]),
      _vm.newList && _vm.newList.length > 0
        ? _c(
            "ul",
            { staticClass: "todo-list" },
            [
              _c("p", { staticClass: "todoTitle" }, [
                _vm._v(_vm._s(_vm._f("i18n")("label.newTodo")))
              ]),
              _vm._l(_vm.newList, function(todo) {
                return _c("li", { key: todo.id, staticClass: "newList" }, [
                  _vm.msgLangCd === "ko_KR"
                    ? _c(
                        "p",
                        { staticClass: "els", attrs: { title: todo.todoNm } },
                        [_vm._v(_vm._s(todo.todoNm))]
                      )
                    : _c(
                        "p",
                        { staticClass: "els", attrs: { title: todo.todoEnm } },
                        [_vm._v(_vm._s(todo.todoEnm))]
                      ),
                  _c(
                    "span",
                    {
                      on: {
                        click: function($event) {
                          return _vm.onClickCount(todo.menuId)
                        }
                      }
                    },
                    [_vm._v(_vm._s(todo.resultCnt))]
                  )
                ])
              })
            ],
            2
          )
        : _vm._e(),
      _vm.resultList && _vm.resultList.length > 0
        ? _c("ul", { staticClass: "todo-list" }, [
            _c(
              "div",
              { staticClass: "titleDiv", on: { click: _vm.showList } },
              [
                _c("p", { staticClass: "todoTitle mustTitle" }, [
                  _vm._v(_vm._s(_vm._f("i18n")("label.mustTodo")))
                ]),
                _c("p", [_vm._v(_vm._s(_vm.val))])
              ]
            ),
            _vm.showMustList
              ? _c(
                  "div",
                  _vm._l(_vm.resultList, function(todo) {
                    return _c("li", { key: todo.id, staticClass: "mustList" }, [
                      _vm.msgLangCd === "ko_KR"
                        ? _c(
                            "p",
                            {
                              staticClass: "els",
                              attrs: { title: todo.todoNm }
                            },
                            [_vm._v(_vm._s(todo.todoNm))]
                          )
                        : _c(
                            "p",
                            {
                              staticClass: "els",
                              attrs: { title: todo.todoEnm }
                            },
                            [_vm._v(_vm._s(todo.todoEnm))]
                          ),
                      _c(
                        "span",
                        {
                          on: {
                            click: function($event) {
                              return _vm.onClickCount(todo.menuId)
                            }
                          }
                        },
                        [_vm._v(_vm._s(todo.resultCnt))]
                      )
                    ])
                  }),
                  0
                )
              : _vm._e()
          ])
        : _vm._e(),
      _vm.resultList.length === 0 && _vm.newList.length === 0
        ? _c("div", { staticClass: "no-work-todo" }, [
            _vm._v(_vm._s(_vm._f("i18n")("alt.noNewWorkTodo")))
          ])
        : _vm._e(),
      _c(
        "check-box",
        {
          staticClass: "three-days-wrap",
          class: {
            "no-work-checkbox":
              _vm.resultList.length === 0 && _vm.newList.length === 0
          },
          on: { click: _vm.closeCookie }
        },
        [_vm._v(_vm._s(_vm._f("i18n")("alt.3day")))]
      ),
      _c(
        "a",
        {
          staticClass: "close",
          attrs: { href: "javascript:;" },
          on: {
            click: function($event) {
              return _vm.$emit("closeBtn")
            }
          }
        },
        [_vm._v(_vm._s(_vm._f("i18n")("label.close")))]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }