<template>
  <footer class="copyright">
		<p>COPYRIGHT {{ toYear }} Alliex, ALL RIGHTS RESERVED</p>
		<!-- case : 모바일로 PC 화면 본 경우에만 show / PC에서 볼 경우 button은 hide -->
		<!-- 20221206 Lam.nt1 Update:  Change main dashboard -->
		<router-link to="/dashboard">
			<button type="button" @click="chgDevice">PC version</button>
		</router-link>
	</footer>
</template>
<script>
import moment from 'moment';
export default {
	name: 'MCopyright',
	data() {
		return {
			toYear: ''
		}
	},
	mounted() {
		this.toYear = moment().format("YYYY")
	},
	methods: {
		chgDevice() {
			this.$store.dispatch("Sidebar/closeSideBar", { withoutAnimation: false });
			this.setDeviceCd('D')
		}
	}
};
</script>

<style lang="scss" scoped>
@import "@/scss/_color.scss";
@import "@/scss/mobile/_m-button.scss";

.copyright {
	width: 100%;
	height: 40px;
	padding:10px 20px;
	color:$c9;
	background-color:#e9e9e9;
	> p{
		display: inline-block;
		margin-top:1px;
		font-size:10px;
		vertical-align: middle;
		letter-spacing:0;
	}
	button{
		height:20px !important;
		margin-left:7px;
		padding:4px 10px !important;
		font-size:11px;
		color:#666 !important;
		background-color:#f3f3f3 !important;
		border:solid 1px #d8d8d8;
	}
}
</style>
