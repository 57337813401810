/**
 * 1. 개요 : 단말기ID검색
 * 2. 처리내용 : 단말기ID검색 Mixins - 화면 모델
 * @File Name : CmnTrmIdPopupViewModel.js
 * @date : 2020. 04. 07. 13:00:00
 * @author : 이현우
 * @history :
 *-----------------------------------------------------------------------
 * 변경일			          		  작성자		변경내용
 *-----------------------------------------------------------------------
 * 2020. 04. 07. 13:00:00	    이현우	  최초 작성
 *-----------------------------------------------------------------------
 */

import { dateTimeFormatter } from "@/utils/formatter";

export default {
  methods: {
    cmnTrmIdPopupViewModel(params = {}) {
      return [
        {
          field: "rnum",
          headerName: "",
          type: Number,
          width: 50,
          isPk: false,
          editable: false,
          pinned: false,
          cellStyle: {
            textAlign: "center"
          },
          cellClassRules: {
            "selected-cell": param =>
              params && param.rowIndex === params.rowIndex
          },
          valueFormatter: param => {
            // 오름차순
            // return (
            //   (this.currPage - 1) * this.pageUnit + param.node.rowIndex + 1
            // );

            // 내림차순
            return (
              this.gridProps.totalItems -
              ((this.currPage - 1) * this.pageUnit + param.node.rowIndex)
            );
          }
        },
        {
          field: "trmId",
          headerName: this.i18n("label.trmId"),
          type: String,
          width: 130,
          isPk: true,
          editable: false,
          pinned: false,
          suppressMenu: false,
          sortable: true,
          cellStyle: {
            textAlign: "center"
          },
          cellClassRules: {}
        },
        // {
        //   field: "aplyStaDtm",
        //   headerName: this.i18n("label.aplyStaDtm"),
        //   type: String,
        //   width: 140,
        //   isPk: false,
        //   editable: false,
        //   pinned: false,
        //   suppressMenu: false,
        //   sortable: true,
        //   cellStyle: {
        //     textAlign: "center"
        //   },
        //   cellClassRules: {},
        //   valueFormatter: param => param.value && this.i18nDtTmFormat(param.value)
        // },
        // {
        //   field: "aplyEndDtm",
        //   headerName: this.i18n("label.aplyEndDtm"),
        //   type: String,
        //   width: 140,
        //   isPk: false,
        //   editable: false,
        //   pinned: false,
        //   suppressMenu: false,
        //   sortable: true,
        //   cellStyle: {
        //     textAlign: "center"
        //   },
        //   cellClassRules: {},
        //   valueFormatter: param => param.value && this.i18nDtTmFormat(param.value)
        // },
        {
          field: "merId",
          headerName: this.i18n("label.merId"),
          type: String,
          width: 130,
          isPk: false,
          editable: false,
          pinned: false,
          suppressMenu: false,
          sortable: true,
          cellStyle: {
            textAlign: "center"
          },
          cellClassRules: {}
        },
        {
          field: "merNm",
          headerName: this.i18n("label.merNm"),
          type: String,
          width: 180,
          isPk: false,
          editable: false,
          pinned: false,
          suppressMenu: false,
          sortable: true,
          cellStyle: {},
          cellClassRules: {}
        },
        {
          field: "agncyCd",
          headerName: this.i18n("label.agncyCd"),
          type: String,
          width: 130,
          isPk: false,
          editable: false,
          pinned: false,
          suppressMenu: false,
          sortable: true,
          cellStyle: {
            textAlign: "center"
          },
          cellClassRules: {}
        },
        {
          field: "agncyNm",
          headerName: this.i18n("label.agncyNm"),
          type: String,
          width: 160,
          isPk: false,
          editable: false,
          pinned: false,
          suppressMenu: false,
          sortable: true,
          cellStyle: {},
          cellClassRules: {}
        },
        {
          field: "addedDate",
          headerName: this.i18n("label.addedDate"),
          type: String,
          width: 140,
          isPk: false,
          editable: false,
          pinned: false,
          suppressMenu: false,
          sortable: true,
          cellStyle: {
            textAlign: "center"
          },
          cellClassRules: {},
          valueFormatter: param => this.i18nDtTmFormat(param.value)
        },
        {
          field: "restCell",
          headerName: "",
          width: 0,
          type: String,
          isPk: false,
          editable: false,
          pinned: false,
          suppressMenu: false,
          sortable: false,
          cellStyle: {},
          cellClassRules: {}
        }
      ];
    }
  }
};
