var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "copyright" },
    [
      _c("p", [
        _vm._v(
          "COPYRIGHT " + _vm._s(_vm.toYear) + " Alliex, ALL RIGHTS RESERVED"
        )
      ]),
      this.userAuth.userId != "pcidss_office" &&
      this.userAuth.userId != "pcidss_merchant" &&
      this.userAuth.userId != "pcidss_agency" &&
      this.userAuth.userId != "pcidss_bank" &&
      this.userAuth.userId != "pcidss_office1" &&
      this.userAuth.userId != "pcidss_merchant1" &&
      this.userAuth.userId != "pcidss_agency1" &&
      this.userAuth.userId != "pcidss_bank1"
        ? _c("router-link", { attrs: { to: "/m/dashboard" } }, [
            _c(
              "button",
              { attrs: { type: "button" }, on: { click: _vm.chgDevice } },
              [_vm._v("Mobile version")]
            )
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }