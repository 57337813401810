var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "open-drawer-wrap" },
    [
      _c("v-collapse-transition", [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.isSearchPaneShow,
                expression: "isSearchPaneShow"
              }
            ],
            class: { "open-drawer": _vm.isSearchPaneShow }
          },
          [_vm._t("default")],
          2
        )
      ]),
      _c(
        "button",
        {
          staticClass: "icon-search sp-b",
          on: {
            click: function($event) {
              return _vm.$emit("fnShowSearchPane")
            }
          }
        },
        [_vm._v(_vm._s(_vm._f("i18n")("label.hiddenSearch")))]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }