var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "tab-content" }, [
    _c(
      "div",
      { staticClass: "cont" },
      [
        _c("div", { staticClass: "grid-top-box" }, [
          _c("div", { staticClass: "CSubTit" }, [
            _c("h3", { staticClass: "title" }, [
              _vm._v(_vm._s(_vm._f("i18n")("label.PsnSubTbl2")))
            ])
          ])
        ]),
        _c(
          "data-table",
          { attrs: { tblId: "tblInfo01" } },
          [
            _c("template", { slot: "colgroup" }, [
              _c("col", { staticStyle: { width: "150px" } }),
              _c("col", { staticStyle: { width: "auto" } }),
              _c("col", { staticStyle: { width: "150px" } }),
              _c("col", { staticStyle: { width: "auto" } })
            ]),
            _c(
              "template",
              { slot: "tbody" },
              [
                _c("tr", [
                  _c("th", { attrs: { scope: "row" } }, [
                    _c(
                      "label",
                      { attrs: { for: "psnMngVOPsnId", tabindex: "1" } },
                      [
                        _vm._v(
                          " " + _vm._s(_vm._f("i18n")("label.psnId")) + " "
                        )
                      ]
                    )
                  ]),
                  _c(
                    "td",
                    { attrs: { colspan: "3" } },
                    [
                      _c("input-text", {
                        ref: "psnMngVOPsnId",
                        attrs: {
                          id: "psnMngVOPsnId",
                          isNumberType: true,
                          placeholder: _vm.i18n("label.psnId"),
                          disabled: true
                        },
                        model: {
                          value: _vm.psnMngVO.regPsnId,
                          callback: function($$v) {
                            _vm.$set(_vm.psnMngVO, "regPsnId", $$v)
                          },
                          expression: "psnMngVO.regPsnId"
                        }
                      })
                    ],
                    1
                  )
                ]),
                _c("tr", [
                  _c("th", { attrs: { scope: "row" } }, [
                    _c(
                      "label",
                      {
                        staticClass: "required",
                        attrs: { for: "psnMngVOPsnNm" }
                      },
                      [
                        _c("span", { staticClass: "star" }, [
                          _vm._v(
                            _vm._s(_vm._f("i18n")("label.require")) +
                              _vm._s(_vm._f("i18n")("label.input"))
                          )
                        ]),
                        _vm._v(" " + _vm._s(_vm._f("i18n")("label.Nm")) + " ")
                      ]
                    )
                  ]),
                  _c(
                    "td",
                    [
                      _c("input-text", {
                        ref: "psnMngVOPsnNm",
                        attrs: {
                          id: "psnMngVOPsnNm",
                          type: "text",
                          maxlength: 40,
                          placeholder: _vm.i18n("label.Nm")
                        },
                        model: {
                          value: _vm.psnMngVO.psnNm,
                          callback: function($$v) {
                            _vm.$set(_vm.psnMngVO, "psnNm", $$v)
                          },
                          expression: "psnMngVO.psnNm"
                        }
                      })
                    ],
                    1
                  ),
                  _c("th", { attrs: { scope: "row" } }, [
                    _c(
                      "label",
                      {
                        staticClass: "required",
                        attrs: { for: "psnMngVOPsnEnm" }
                      },
                      [
                        _c("span", { staticClass: "star" }, [
                          _vm._v(
                            _vm._s(_vm._f("i18n")("label.require")) +
                              _vm._s(_vm._f("i18n")("label.input"))
                          )
                        ]),
                        _vm._v(" " + _vm._s(_vm._f("i18n")("label.enm")) + " ")
                      ]
                    )
                  ]),
                  _c(
                    "td",
                    [
                      _c("input-text", {
                        attrs: {
                          id: "psnMngVOPsnEnm",
                          type: "text",
                          maxlength: 80,
                          placeholder: _vm.i18n("label.enm")
                        },
                        model: {
                          value: _vm.psnMngVO.psnEnm,
                          callback: function($$v) {
                            _vm.$set(_vm.psnMngVO, "psnEnm", $$v)
                          },
                          expression: "psnMngVO.psnEnm"
                        }
                      })
                    ],
                    1
                  )
                ]),
                _c("tr", [
                  _c("th", { attrs: { scope: "row" } }, [
                    _c("label", { attrs: { for: "psnMngVOJobPosCd" } }, [
                      _vm._v(
                        " " + _vm._s(_vm._f("i18n")("label.jobPosition")) + " "
                      )
                    ])
                  ]),
                  _c(
                    "td",
                    [
                      _c("code-selector", {
                        attrs: {
                          id: "psnMngVOJobPosCd",
                          cdList: _vm.cdList.jobPosCdList,
                          isAll: false,
                          isSel: true
                        },
                        model: {
                          value: _vm.psnMngVO.jobPosCd,
                          callback: function($$v) {
                            _vm.$set(_vm.psnMngVO, "jobPosCd", $$v)
                          },
                          expression: "psnMngVO.jobPosCd"
                        }
                      })
                    ],
                    1
                  ),
                  _c("th", { attrs: { scope: "row" } }, [
                    _c(
                      "label",
                      {
                        staticClass: "required",
                        attrs: { for: "psnMngVOPsnStatCd" }
                      },
                      [
                        _c("span", { staticClass: "star" }, [
                          _vm._v(
                            _vm._s(_vm._f("i18n")("label.require")) +
                              _vm._s(_vm._f("i18n")("label.input"))
                          )
                        ]),
                        _vm._v(" " + _vm._s(_vm._f("i18n")("label.stat")) + " ")
                      ]
                    )
                  ]),
                  _c(
                    "td",
                    [
                      _c("code-selector", {
                        key: "refreshInx1",
                        attrs: {
                          id: "psnMngVOPsnStatCd",
                          cdList: _vm.cdList.psnStatCdList,
                          isAll: false,
                          isSel: true,
                          disabled: true
                        },
                        model: {
                          value: _vm.psnMngVO.psnStatCd,
                          callback: function($$v) {
                            _vm.$set(_vm.psnMngVO, "psnStatCd", $$v)
                          },
                          expression: "psnMngVO.psnStatCd"
                        }
                      })
                    ],
                    1
                  )
                ]),
                _c("tr", [
                  _c("th", { attrs: { scope: "row" } }, [
                    _c("label", { attrs: { for: "psnMngVOPidKndCd" } }, [
                      _vm._v(" " + _vm._s(_vm._f("i18n")("label.pidKnd")) + " ")
                    ])
                  ]),
                  _c(
                    "td",
                    _vm._l(_vm.cdList.pidKndCdList, function(item) {
                      return _c("code-radio-box", {
                        key: item.codeId,
                        ref: "psnMngVOPidKndCd",
                        refInFor: true,
                        attrs: {
                          id: "psnMngVOPidKndCd",
                          value: item.codeId,
                          item: item.codeName
                        },
                        model: {
                          value: _vm.psnMngVO.pidKndCd,
                          callback: function($$v) {
                            _vm.$set(_vm.psnMngVO, "pidKndCd", $$v)
                          },
                          expression: "psnMngVO.pidKndCd"
                        }
                      })
                    }),
                    1
                  ),
                  _c("th", { attrs: { scope: "row" } }, [
                    _vm._v(_vm._s(_vm._f("i18n")("label.pidNo")))
                  ]),
                  _c(
                    "td",
                    [
                      _c("input-text", {
                        ref: "psnMngVOPidNo",
                        attrs: {
                          id: "psnMngVOPidNo",
                          type: "text",
                          maxlength: 300,
                          placeholder: _vm.i18n("label.pidNo")
                        },
                        model: {
                          value: _vm.psnMngVO.pidNo,
                          callback: function($$v) {
                            _vm.$set(_vm.psnMngVO, "pidNo", $$v)
                          },
                          expression: "psnMngVO.pidNo"
                        }
                      })
                    ],
                    1
                  )
                ]),
                _c("tr", [
                  _c("th", { attrs: { scope: "row" } }, [
                    _vm._v(" " + _vm._s(_vm._f("i18n")("label.telNo")) + " ")
                  ]),
                  _c(
                    "td",
                    [
                      _c("input-phone", {
                        ref: "psnMngVOTelNo",
                        attrs: {
                          id: "psnMngVOTelNo",
                          type: "text",
                          value: _vm.psnMngVO.telNo
                        },
                        on: {
                          fnChange: function(value) {
                            return (_vm.psnMngVO.telNo = value)
                          }
                        }
                      })
                    ],
                    1
                  ),
                  _c("th", { attrs: { scope: "row" } }, [
                    _c(
                      "label",
                      {
                        staticClass: "required",
                        attrs: { for: "psnMngVOTelNo" }
                      },
                      [
                        _c("span", { staticClass: "star" }, [
                          _vm._v(
                            _vm._s(_vm._f("i18n")("label.require")) +
                              _vm._s(_vm._f("i18n")("label.input"))
                          )
                        ]),
                        _vm._v(
                          " " + _vm._s(_vm._f("i18n")("label.mobileNo")) + " "
                        )
                      ]
                    )
                  ]),
                  _c(
                    "td",
                    [
                      _c("input-phone", {
                        ref: "psnMngVOMobileNo",
                        attrs: {
                          id: "psnMngVOMobileNo",
                          value: _vm.psnMngVO.mobileNo,
                          type: "text"
                        },
                        on: {
                          fnChange: function(value) {
                            return (_vm.psnMngVO.mobileNo = value)
                          }
                        }
                      })
                    ],
                    1
                  )
                ]),
                _c("tr", [
                  _c("th", { attrs: { scope: "row" } }, [
                    _c("label", { attrs: { for: "psnMngVOFaxNo" } }, [
                      _vm._v(_vm._s(_vm._f("i18n")("label.faxNo")))
                    ])
                  ]),
                  _c(
                    "td",
                    { attrs: { colspan: "3" } },
                    [
                      _c("input-phone", {
                        ref: "psnMngVOFaxNo",
                        attrs: {
                          id: "psnMngVOFaxNo",
                          value: _vm.psnMngVO.faxNo,
                          type: "text"
                        },
                        on: {
                          fnChange: function(value) {
                            return (_vm.psnMngVO.faxNo = value)
                          }
                        }
                      })
                    ],
                    1
                  )
                ]),
                _c("tr", [
                  _c("th", { attrs: { scope: "row" } }, [
                    _c("label", { attrs: { for: "psnMngVOPidNoIssDt" } }, [
                      _vm._v(
                        " " + _vm._s(_vm._f("i18n")("label.pidNoIssDt")) + " "
                      )
                    ])
                  ]),
                  _c(
                    "td",
                    [
                      _c("input-date", {
                        ref: "psnMngVOPidNoIssDt",
                        attrs: {
                          id: "psnMngVOPidNoIssDt",
                          placeholder: _vm.i18n("label.pidNoIssDt")
                        },
                        model: {
                          value: _vm.psnMngVO.pidNoIssDt,
                          callback: function($$v) {
                            _vm.$set(_vm.psnMngVO, "pidNoIssDt", $$v)
                          },
                          expression: "psnMngVO.pidNoIssDt"
                        }
                      })
                    ],
                    1
                  ),
                  _c("th", { attrs: { scope: "row" } }, [
                    _c("label", { attrs: { for: "psnMngVOPidNoIssPlc" } }, [
                      _vm._v(
                        " " + _vm._s(_vm._f("i18n")("label.pidNoIssPlc")) + " "
                      )
                    ])
                  ]),
                  _c(
                    "td",
                    [
                      _c("input-text", {
                        ref: "psnMngVOPidNoIssPlc",
                        attrs: {
                          id: "psnMngVOPidNoIssPlc",
                          type: "text",
                          maxlength: 25,
                          placeholder: _vm.i18n("label.pidNoIssPlc")
                        },
                        model: {
                          value: _vm.psnMngVO.pidNoIssPlc,
                          callback: function($$v) {
                            _vm.$set(_vm.psnMngVO, "pidNoIssPlc", $$v)
                          },
                          expression: "psnMngVO.pidNoIssPlc"
                        }
                      })
                    ],
                    1
                  )
                ]),
                _c("tr", [
                  _c("th", { attrs: { scope: "row" } }, [
                    _c(
                      "label",
                      {
                        staticClass: "required",
                        attrs: { for: "psnMngVOGnd" }
                      },
                      [
                        _c("span", { staticClass: "star" }, [
                          _vm._v(
                            _vm._s(_vm._f("i18n")("label.require")) +
                              _vm._s(_vm._f("i18n")("label.input"))
                          )
                        ]),
                        _vm._v(" " + _vm._s(_vm._f("i18n")("label.gnd")))
                      ]
                    )
                  ]),
                  _c(
                    "td",
                    _vm._l(_vm.cdList.gndList, function(item) {
                      return _c("code-radio-box", {
                        key: item.codeId,
                        ref: "psnMngVOGnd",
                        refInFor: true,
                        attrs: {
                          name: "psnMngVOGnd",
                          value: item.codeId,
                          item: item.codeName
                        },
                        model: {
                          value: _vm.psnMngVO.gnd,
                          callback: function($$v) {
                            _vm.$set(_vm.psnMngVO, "gnd", $$v)
                          },
                          expression: "psnMngVO.gnd"
                        }
                      })
                    }),
                    1
                  ),
                  _c("th", { attrs: { scope: "row" } }, [
                    _c("label", { attrs: { for: "psnMngVODob" } }, [
                      _vm._v(_vm._s(_vm._f("i18n")("label.dob")))
                    ])
                  ]),
                  _c(
                    "td",
                    [
                      _c("input-date", {
                        ref: "psnMngVODob",
                        attrs: {
                          id: "psnMngVODob",
                          placeholder: _vm.i18n("label.dob")
                        },
                        model: {
                          value: _vm.psnMngVO.dob,
                          callback: function($$v) {
                            _vm.$set(_vm.psnMngVO, "dob", $$v)
                          },
                          expression: "psnMngVO.dob"
                        }
                      })
                    ],
                    1
                  )
                ]),
                _c("tr", [
                  _c("th", { attrs: { scope: "row" } }, [
                    _c("label", { attrs: { for: "psnMngVOEnthCd" } }, [
                      _vm._v(_vm._s(_vm._f("i18n")("label.enth")))
                    ])
                  ]),
                  _c(
                    "td",
                    [
                      _c("code-selector", {
                        attrs: {
                          id: "psnMngVOEnthCd",
                          cdList: _vm.cdList.enthCdList,
                          isAll: false,
                          isSel: true
                        },
                        model: {
                          value: _vm.psnMngVO.enthCd,
                          callback: function($$v) {
                            _vm.$set(_vm.psnMngVO, "enthCd", $$v)
                          },
                          expression: "psnMngVO.enthCd"
                        }
                      })
                    ],
                    1
                  ),
                  _c("th", { attrs: { scope: "row" } }, [
                    _c("label", { attrs: { for: "psnMngVONatCd" } }, [
                      _vm._v(_vm._s(_vm._f("i18n")("label.nat")))
                    ])
                  ]),
                  _c(
                    "td",
                    [
                      _c("code-selector", {
                        attrs: {
                          id: "psnMngVONatCd",
                          cdList: _vm.cdList.natCdList,
                          isAll: false,
                          isSel: true
                        },
                        model: {
                          value: _vm.psnMngVO.natCd,
                          callback: function($$v) {
                            _vm.$set(_vm.psnMngVO, "natCd", $$v)
                          },
                          expression: "psnMngVO.natCd"
                        }
                      })
                    ],
                    1
                  )
                ]),
                _c("tr", [
                  _c("th", { attrs: { scope: "row" } }, [
                    _c(
                      "label",
                      {
                        staticClass: "required",
                        attrs: { for: "psnMngVOEmailAddr" }
                      },
                      [
                        _c("span", { staticClass: "star" }, [
                          _vm._v(
                            _vm._s(_vm._f("i18n")("label.require")) +
                              _vm._s(_vm._f("i18n")("label.input"))
                          )
                        ]),
                        _vm._v(
                          " " + _vm._s(_vm._f("i18n")("label.emailAddr")) + " "
                        )
                      ]
                    )
                  ]),
                  _c(
                    "td",
                    { attrs: { colspan: "3" } },
                    [
                      _c("input-email", {
                        key: _vm.refreshInx1,
                        ref: "psnMngVOEmailAddr",
                        attrs: {
                          id: "psnMngVOEmailAddr",
                          type: "text",
                          prefixMaxlength: 30,
                          suffixMaxlength: 30,
                          value: _vm.psnMngVO.emailAddr
                        },
                        on: {
                          fnChange: function(value) {
                            return (_vm.psnMngVO.emailAddr = value)
                          }
                        }
                      })
                    ],
                    1
                  )
                ]),
                _c("tr", [
                  _c("th", { attrs: { scope: "row" } }, [
                    _c("label", { attrs: { for: "psnMngVOCityNm" } }, [
                      _vm._v(_vm._s(_vm._f("i18n")("label.city")))
                    ])
                  ]),
                  _c(
                    "td",
                    { attrs: { colspan: "3" } },
                    [
                      _c("input-text", {
                        ref: "psnMngVOCityNm",
                        attrs: {
                          id: "psnMngVOCityNm",
                          type: "text",
                          disabled: true
                        },
                        model: {
                          value: _vm.psnMngVO.ctAddrNm,
                          callback: function($$v) {
                            _vm.$set(_vm.psnMngVO, "ctAddrNm", $$v)
                          },
                          expression: "psnMngVO.ctAddrNm"
                        }
                      })
                    ],
                    1
                  )
                ]),
                _c("tr", [
                  _c("th", { attrs: { scope: "row" } }, [
                    _c("label", { attrs: { for: "psnMngVODistNm" } }, [
                      _vm._v(_vm._s(_vm._f("i18n")("label.dist")))
                    ])
                  ]),
                  _c(
                    "td",
                    { attrs: { colspan: "3" } },
                    [
                      _c("input-text", {
                        ref: "psnMngVODistNm",
                        attrs: {
                          id: "psnMngVODistNm",
                          type: "text",
                          disabled: true
                        },
                        model: {
                          value: _vm.psnMngVO.dsAddrNm,
                          callback: function($$v) {
                            _vm.$set(_vm.psnMngVO, "dsAddrNm", $$v)
                          },
                          expression: "psnMngVO.dsAddrNm"
                        }
                      })
                    ],
                    1
                  )
                ]),
                _c("tr", [
                  _c("th", { attrs: { scope: "row" } }, [
                    _c("label", { attrs: { for: "psnMngVOWardNm" } }, [
                      _vm._v(_vm._s(_vm._f("i18n")("label.ward")))
                    ])
                  ]),
                  _c(
                    "td",
                    { attrs: { colspan: "3" } },
                    [
                      _c("search-input-text", {
                        ref: "psnMngVOWardNm",
                        attrs: {
                          id: "psnMngVOWardNm",
                          type: "text",
                          placeholder: _vm.i18n("label.ward"),
                          disabled: true,
                          inputDisabled: true
                        },
                        on: { fnSearch: _vm.showCmnAddrPopup },
                        model: {
                          value: _vm.psnMngVO.wdAddrNm,
                          callback: function($$v) {
                            _vm.$set(_vm.psnMngVO, "wdAddrNm", $$v)
                          },
                          expression: "psnMngVO.wdAddrNm"
                        }
                      })
                    ],
                    1
                  )
                ]),
                _c("tr", [
                  _c("th", { attrs: { scope: "row" } }, [
                    _c("label", { attrs: { for: "psnMngVOBasAddr" } }, [
                      _vm._v(_vm._s(_vm._f("i18n")("label.addr")))
                    ])
                  ]),
                  _c(
                    "td",
                    { attrs: { colspan: "3" } },
                    [
                      _c("input-text", {
                        ref: "psnMngVOBasAddr",
                        staticClass: "w100p",
                        attrs: {
                          id: "psnMngVOBasAddr",
                          type: "text",
                          maxlength: 150,
                          placeholder: _vm.i18n("label.addr")
                        },
                        model: {
                          value: _vm.psnMngVO.basAddr,
                          callback: function($$v) {
                            _vm.$set(_vm.psnMngVO, "basAddr", $$v)
                          },
                          expression: "psnMngVO.basAddr"
                        }
                      })
                    ],
                    1
                  )
                ]),
                _c("tr", [
                  _c("th", { attrs: { scope: "row" } }, [
                    _c("label", { attrs: { for: "psnMngVOProfileImg" } }, [
                      _vm._v(
                        " " + _vm._s(_vm._f("i18n")("label.profileImg")) + " "
                      )
                    ])
                  ]),
                  _c(
                    "td",
                    { attrs: { id: "psnMngVOProfileImg", colspan: "3" } },
                    [
                      _c("croppa", {
                        key: _vm.refreshInx1,
                        ref: "myCroppa",
                        attrs: {
                          width: 53,
                          height: 53,
                          "remove-button-color": "#797979",
                          "remove-button-size": 20,
                          "initial-image": _vm.imgUrl,
                          placeholder: ""
                        },
                        on: {
                          "file-choose": _vm.generateImage,
                          "file-size-exceed": _vm.generateImage,
                          "new-image-drawn": _vm.generateImage,
                          move: _vm.generateImage,
                          zoom: _vm.generateImage,
                          "image-remove": _vm.removeImage
                        }
                      })
                    ],
                    1
                  )
                ]),
                _c("tr", [
                  _c("th", { attrs: { scope: "row" } }, [
                    _c("label", { attrs: { for: "psnMngVORmk" } }, [
                      _vm._v(_vm._s(_vm._f("i18n")("label.rmk")))
                    ])
                  ]),
                  _c("td", { attrs: { colspan: "3" } }, [
                    _c("textarea", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.psnMngVO.rmk,
                          expression: "psnMngVO.rmk"
                        }
                      ],
                      ref: "psnMngVORmk",
                      attrs: {
                        id: "psnMngVORmk",
                        type: "text",
                        maxlength: 100,
                        placeholder: _vm.i18n("label.rmk")
                      },
                      domProps: { value: _vm.psnMngVO.rmk },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(_vm.psnMngVO, "rmk", $event.target.value)
                        }
                      }
                    })
                  ])
                ]),
                _c("tr", [
                  _c("th", { attrs: { scope: "row" } }, [
                    _c("label", { attrs: { for: "psnMngVOAtchFileFkId" } }, [
                      _vm._v(
                        " " + _vm._s(_vm._f("i18n")("label.attchFile")) + " "
                      )
                    ])
                  ]),
                  _c("td", { attrs: { colspan: "3" } }, [
                    _c("div", { staticClass: "file-wrap" }, [
                      _c(
                        "button",
                        {
                          staticClass: "file-box",
                          attrs: { type: "button" },
                          on: {
                            click: function($event) {
                              $event.stopPropagation()
                              return _vm.fileAdd($event)
                            }
                          }
                        },
                        [
                          _c("input", {
                            ref: "file",
                            attrs: {
                              id: "file",
                              type: "file",
                              multiple: "",
                              accept: _vm.fileUpload.acceptExtention,
                              title: "i18n('label.attchFile')"
                            },
                            on: {
                              change: function($event) {
                                $event.stopPropagation()
                                return _vm.uploadFile($event)
                              }
                            }
                          }),
                          _vm._v(
                            " " +
                              _vm._s(_vm._f("i18n")("label.file")) +
                              _vm._s(_vm._f("i18n")("label.search")) +
                              " "
                          )
                        ]
                      ),
                      _c(
                        "div",
                        { staticClass: "file-list" },
                        _vm._l(_vm.fileUpload.fileList, function(file) {
                          return _c(
                            "span",
                            {
                              key: file.fileSeqNo,
                              staticClass: "file-selected",
                              on: {
                                click: function($event) {
                                  return _vm.downloadFile(file)
                                }
                              }
                            },
                            [
                              _c(
                                "a",
                                {
                                  attrs: {
                                    href: "javascript:;",
                                    title: file.orginlFileNm
                                  }
                                },
                                [_vm._v(" " + _vm._s(file.orginlFileNm) + " ")]
                              ),
                              _c(
                                "button",
                                {
                                  attrs: { type: "button" },
                                  on: {
                                    click: function($event) {
                                      $event.stopPropagation()
                                      return _vm.deleteFile(file)
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    _vm._s(_vm._f("i18n")("label.file")) +
                                      _vm._s(_vm._f("i18n")("label.del"))
                                  )
                                ]
                              )
                            ]
                          )
                        }),
                        0
                      )
                    ])
                  ])
                ]),
                _c(
                  "tr",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: false,
                        expression: "false"
                      }
                    ]
                  },
                  [
                    _c("th", { attrs: { scope: "row" } }, [
                      _vm._v(_vm._s(_vm._f("i18n")("label.addedBy")))
                    ]),
                    _c("td", [
                      _vm._v(
                        _vm._s(_vm.psnMngVO.addedBy) +
                          " (" +
                          _vm._s(_vm.psnMngVO.userNm) +
                          ")"
                      )
                    ]),
                    _c("th", { attrs: { scope: "row" } }, [
                      _vm._v(_vm._s(_vm._f("i18n")("label.modifyBy")))
                    ]),
                    _c("td", [
                      _vm._v(
                        _vm._s(_vm.psnMngVO.modifyBy) +
                          " (" +
                          _vm._s(_vm.psnMngVO.muserNm) +
                          ")"
                      )
                    ])
                  ]
                ),
                _c(
                  "tr",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: false,
                        expression: "false"
                      }
                    ]
                  },
                  [
                    _c("th", { attrs: { scope: "row" } }, [
                      _vm._v(_vm._s(_vm._f("i18n")("label.addedDate")))
                    ]),
                    _c("td", [
                      _vm._v(_vm._s(_vm.i18nDtTmFormat(_vm.psnMngVO.addedDate)))
                    ]),
                    _c("th", { attrs: { scope: "row" } }, [
                      _vm._v(_vm._s(_vm._f("i18n")("label.modifyDate")))
                    ]),
                    _c("td", [
                      _vm._v(
                        _vm._s(_vm.i18nDtTmFormat(_vm.psnMngVO.modifyDate))
                      )
                    ])
                  ]
                ),
                _vm.isShowCmnAddrPopup
                  ? _c("cmn-addr-popup", {
                      attrs: { paramData: _vm.psnMngVO },
                      on: {
                        close: _vm.closeCmnAddrPopup,
                        fnRowDoubleClick: _vm.fnCmnAddrPopupDoubleClick
                      }
                    })
                  : _vm._e()
              ],
              1
            )
          ],
          2
        )
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "btn-btm" },
      [
        _c("primary-button", { on: { click: _vm.validate } }, [
          _vm._v(_vm._s(_vm.buttonTitle))
        ]),
        _c(
          "default-button",
          {
            on: {
              click: function($event) {
                return _vm.$emit("close")
              }
            }
          },
          [_vm._v(_vm._s(_vm._f("i18n")("button.cancel")))]
        ),
        this.userInfo.grpMenuAuthorCd != "SYS"
          ? _c(
              "default-button",
              { staticClass: "del-btn", on: { click: _vm.resignedValidate } },
              [_vm._v(_vm._s(_vm._f("i18n")("label.resigned")))]
            )
          : _vm._e()
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }