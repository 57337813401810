/**
 * 1. 개요 : 주소검색
 * 2. 처리내용 : 주소검색 Mixins - 화면 모델
 * @File Name : CmnAddrPopupViewModel.js
 * @date : 2020. 04. 09. 13:00:00
 * @author : 김영노
 * @history :
 *-----------------------------------------------------------------------
 * 변경일			          		  작성자		변경내용
 *-----------------------------------------------------------------------
 * 2020. 04. 09. 13:00:00	    김영노	  최초 작성
 *-----------------------------------------------------------------------
 */

import { dateTimeFormatter } from "@/utils/formatter";

export default {
  methods: {
    cmnAddrPopupViewModel(params = {}) {
      return [
        {
          field: "rnum",
          headerName: "",
          type: Number,
          width: 50,
          isPk: false,
          editable: false,
          pinned: false,
          cellStyle: {
            textAlign: "center"
          },
          cellClassRules: {
            "selected-cell": param =>
              params && param.rowIndex === params.rowIndex
          },
          valueFormatter : (param) => {
            // 내림차순
            return this.gridProps.totalItems - (((this.currPage - 1) * this.pageUnit) + param.node.rowIndex);
          }, 
        },
        {
          field: "ctAddrNm",
          headerName: this.i18n("label.city"),
          type: String,
          width: 0,
          isPk: false,
          editable: false,
          pinned: false,
          suppressMenu: false,
          sortable: true,
          cellStyle: {},
          cellClassRules: {}
        },
        {
          field: "dsAddrNm",
          headerName: this.i18n("label.dist"),
          type: String,
          width: 0,
          isPk: false,
          editable: false,
          pinned: false,
          suppressMenu: false,
          sortable: true,
          cellStyle: {},
          cellClassRules: {}
        },

        {
          field: "wdAddrNm",
          headerName: this.i18n("label.ward"),
          type: String,
          width: 0,
          isPk: false,
          editable: false,
          pinned: false,
          suppressMenu: false,
          sortable: true,
          cellStyle: {},
          cellClassRules: {}
        },
        // {
        //   field: "addrClCd",
        //   headerName: this.i18n("label.addr") + this.i18n("label.cl") + this.i18n("label.cd"),
        //   type: String,
        //   width: 140,
        //   isPk: true,
        //   editable: false,
        //   pinned: false,
        //   suppressMenu: false,
        //   sortable: true,
        //   cellStyle: {
        //     textAlign: "center"
        //   },
        //   cellClassRules: {}
        // },
        // {
        //   field: "ctAddrCd",
        //   headerName: this.i18n("label.city") + this.i18n("label.cd"),
        //   type: String,
        //   width: 140,
        //   isPk: true,
        //   editable: false,
        //   pinned: false,
        //   suppressMenu: false,
        //   sortable: true,
        //   cellStyle: {
        //     textAlign: "center"
        //   },
        //   cellClassRules: {}
        // },        
        // {
        //   field: "dsAddrCd",
        //   headerName: this.i18n("label.dist") + this.i18n("label.cd"),
        //   type: String,
        //   width: 140,
        //   isPk: true,
        //   editable: false,
        //   pinned: false,
        //   suppressMenu: false,
        //   sortable: true,
        //   cellStyle: {
        //     textAlign: "center"
        //   },
        //   cellClassRules: {}
        // },        
        // {
        //   field: "wdAddrCd",
        //   headerName: this.i18n("label.ward") + this.i18n("label.cd"),
        //   type: String,
        //   width: 140,
        //   isPk: true,
        //   editable: false,
        //   pinned: false,
        //   suppressMenu: false,
        //   sortable: true,
        //   cellStyle: {
        //     textAlign: "center"
        //   },
        //   cellClassRules: {}
        // },        
        {
          field: "addedDate",
          headerName: this.i18n("label.addedDate"),
          type: String,
          width: 0,
          isPk: false,
          editable: false,
          pinned: false,
          suppressMenu: false,
          sortable: true,
          cellStyle: {
            textAlign: "center"
          },
          
          cellClassRules: {}
        },
        {
          field: "restCell",
          headerName: "",
          width: 0,
          type: String,
          isPk: false,
          editable: false,
          pinned: false,
          suppressMenu: false,
          sortable: false,
          cellStyle: {},
          cellClassRules: {},          
        }
        


      ];
    }
  }
};
