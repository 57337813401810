/**
 * 1. 개요 : 대리점정보검색
 * 2. 처리내용 : 대리점정보검색 - 이벤트 버스
 * @File Name : EventBus.js
 * @date : 2020. 04. 08. 13:00:00
 * @author : 이현우
 * @history :
 *-----------------------------------------------------------------------
 * 변경일			          		  작성자		변경내용
 *-----------------------------------------------------------------------
 * 2020. 04. 08. 13:00:00	    이현우	  최초 작성
 *-----------------------------------------------------------------------
 */
import Vue from "vue";

export const EventBus = new Vue();

export default {
  EventBus
};
