/**
 * 1. 개요 : 공통 컴포넌트
 * 2. 처리내용 : 공통 컴포넌트 - DataTable
 * @File Name : index.vue
 * @date : 2020. 02. 20. 09:46:00
 * @author : 이상철
 * @history :
 *-----------------------------------------------------------------------
 * 변경일			          		  작성자		변경내용
 *-----------------------------------------------------------------------
 * 2020. 2. 20. 09:46:00	    이상철	  최초 작성
 *-----------------------------------------------------------------------
 */
<template>
  <div ref="wrapper"
       class="data-table-wrap"
  >
    <div :id="tblId" class="hide">
      <slot name="tableInfo" />
    </div>
    <table class="data-table"
           :class="tableClasses"
           :aria-describedby="tblId"
    >
      <caption>
        <slot name="caption" />
      </caption>
      <colgroup v-if="$slots.colgroup">
        <slot name="colgroup" />
      </colgroup>
      <thead v-if="$slots.thead">
        <slot name="thead" />
      </thead>
      <tbody v-if="$slots.tbody">
        <slot name="tbody" />
      </tbody>
      <tfoot v-if="$slots.tfoot">
        <slot name="tfoot" />
      </tfoot>
    </table>
  </div>
</template>

<script>
export default {
  name: 'DataTable',
  props: {
    scrollable: {
      type: Boolean,
      default: true,
    },
    hoverable: {
      type: Boolean,
      default: false,
    },
    tblId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      headerHeight: 0,
    };
  },
  computed: {
    /**
     * table 스타일 속성 바인딩
     * @return {Object}
     */
    tableClasses() {  
      return {
        hoverable: this.hoverable,
      };
    },
  },
  created() {
    if (process.env.NODE_ENV === 'production') return;
    if (!this.$slots.colgroup) {
      // console.error('<template slot="colgroup">는 필수입니다.');
    }
    if (this.$slots.default) {
      // console.error('<template slot="tbody">를 사용 해주세요.');
    }
  },
};
</script>

<style lang="scss">
@import "@/scss/_color.scss";
@import "@/scss/_input.scss";

.data-table,
.data-table-header {
  color: $c6;
  width: 100%;
  table-layout: fixed;
  border-spacing: 0;
  border-collapse:separate;
  border-style: hidden;

  > * {
    > tr {
      > th,
      > td {
        height:41px;
        padding:7px 10px 7px 20px;
        font-size: 12px;
        line-height: 16px;
        letter-spacing:-0.35px;
        border-bottom: 1px solid $tbl-border;
        vertical-align: middle;
        text-align: left;
      }
      > td {
        padding-left:10px;
      }
    }
  }
  >thead{
    > tr > th {
      background: $tbl-bg;
      text-align: center;
      font-weight:normal
    }
  }
  > tbody {
    > tr > th {
      background: $tbl-bg;
      font-weight:normal
    }
    > tr {
      &:last-child{
        height: auto;
      }
    }
  }
}

.data-table {
  &.hoverable {
    > tbody {
      tr:hover {
        > td {
          background-color: #dce8ff;
        }
        > th {
          background-color: darken(#dce8ff, 5%);
        }
      }
    }
  }

  &-wrap{
    width: 100%;
    border-top: 1px solid $tbl-border;
    // border-bottom: 1px solid $tbl-border;
  }
}

.data-table-header {
  border-bottom: 1px solid rgb(187, 187, 187);
  > thead {
    > tr {
      > th {
        background-color: #f4f4f4;
        border-bottom: none;
        border-top: 1px solid #ddd;
      }
      &:first-child {
        > th{
          border-top: 0;
        }
      }
    }
  }
}
</style>