/**
 * 1. 개요 : 공통
 * 2. 처리내용 : 공통 코드 - 진입점
 * @File Name : index.js
 * @date : 2020. 02. 20. 09:46:00
 * @author : 이상철
 * @history :
 *-----------------------------------------------------------------------
 * 변경일			          		  작성자		변경내용
 *-----------------------------------------------------------------------
 * 2020. 2. 20. 09:46:00	    이상철	  최초 작성
 *-----------------------------------------------------------------------
 */
import filters from "./filters";
import computed from "./computed";
import components from "./components";
import methods from "./methods";
import beforeRouteEnter from "./beforeRouteEnter";
import data from "./data";

export default {
  components,
  filters,
  data,
  computed,
  methods,
  beforeRouteEnter
};
