var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "input-text text-box search-combo" }, [
    _c("input", {
      ref: _vm.id,
      staticStyle: { "text-align": "right" },
      attrs: {
        id: _vm.id,
        type: "text",
        disabled: _vm.disabled,
        onkeydown: _vm.onKeyDown,
        maxlength: _vm.calMaxlength,
        placeholder: _vm.placeholder
      },
      domProps: { value: _vm.formatedValue },
      on: {
        blur: _vm.onBlurHandler,
        keydown: function($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
          ) {
            return null
          }
          $event.preventDefault()
          return _vm.fnSearch($event)
        },
        input: _vm.handleInput
      }
    }),
    _vm.unit
      ? _c("span", { staticStyle: { color: "#999", "margin-top": "5px" } }, [
          _vm._v(_vm._s(_vm.unit))
        ])
      : _vm._e(),
    _vm.showBtn
      ? _c(
          "button",
          {
            attrs: {
              type: "button",
              "icon-class": "search",
              disabled: _vm.btnDisabled
            },
            on: {
              click: function($event) {
                $event.stopPropagation()
                return _vm.fnSearch($event)
              }
            }
          },
          [_vm._v("search")]
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }