var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "popup",
    {
      attrs: { title: _vm.title, popSize: "size-page", myPagePopup: true },
      on: {
        close: function($event) {
          return _vm.$emit("close")
        }
      }
    },
    [
      _c(
        "ul",
        {
          staticClass: "tags-view-container",
          attrs: { id: "tags-view-container" }
        },
        _vm._l(_vm.tagViews, function(tag) {
          return _c(
            "li",
            {
              key: tag.name,
              ref: "tag",
              refInFor: true,
              staticClass: "tags-view-item",
              class: _vm.isActive(tag) ? "active" : "",
              attrs: { title: tag.title },
              on: {
                click: function($event) {
                  $event.stopPropagation()
                  return _vm.activeTag(tag)
                }
              }
            },
            [
              _c("a", { attrs: { href: "javascript:;" } }, [
                _vm._v(_vm._s(tag.title))
              ])
            ]
          )
        }),
        0
      ),
      _c(
        "transition",
        { attrs: { name: "fade-transform", mode: "out-in" } },
        [
          _c(
            "keep-alive",
            { staticClass: "tab-content" },
            [
              _vm.activeTagName === "MyPagePopupPsn"
                ? _c("my-page-popup-psn", {
                    on: {
                      close: function($event) {
                        return _vm.$emit("close")
                      },
                      refreshProfile: function($event) {
                        return _vm.$emit("refreshProfile")
                      }
                    }
                  })
                : _vm._e(),
              _vm.activeTagName === "MyPagePopupComplaint"
                ? _c("my-page-popup-complaint")
                : _vm._e(),
              _vm.activeTagName === "MyPagePopupFaq"
                ? _c("my-page-popup-faq")
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }