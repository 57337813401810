var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("aside", { staticClass: "side-menu-wrap" }, [
    _c("div", { staticClass: "side-menu" }, [
      _c("div", { staticClass: "side-top" }, [
        _c("img", {
          staticClass: "profile",
          attrs: {
            alt: "profile photo",
            width: "45",
            height: "45",
            src: _vm.psnImage
          }
        }),
        _c("p", { staticClass: "profile-txt" }, [_vm._v(_vm._s(_vm.psnNm))])
      ]),
      _c("div", { staticClass: "side-cont" }, [
        _c(
          "ul",
          { staticClass: "el-menu" },
          _vm._l(_vm.routes, function(route) {
            return _c(
              "li",
              {
                key: route.id,
                class: { "setting sp-b": route.icon === "setting" },
                attrs: { index: route.name, route: route }
              },
              [
                route
                  ? _c(
                      "a",
                      {
                        attrs: { slot: "title", href: "javascript:;" },
                        on: {
                          click: function($event) {
                            return _vm.clickMenu(route)
                          }
                        },
                        slot: "title"
                      },
                      [_vm._v(_vm._s(route.title))]
                    )
                  : _vm._e()
              ]
            )
          }),
          0
        )
      ]),
      _c(
        "a",
        {
          staticClass: "side-close",
          attrs: { href: "javascript:;" },
          on: { click: _vm.toggleSideBar }
        },
        [_vm._v("side-menu close")]
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }