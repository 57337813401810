/**
 * 1. 개요 : Inputs 공통 컴포넌트
 * 2. 처리내용 : Inputs 공통 컴포넌트 - inputAmt2
 * @File Name : index.vue
 * @date : 2020. 02. 20. 09:46:00
 * @author : 이지혜
 * @history :
 *-----------------------------------------------------------------------
 * 변경일			          		  작성자		변경내용
 *-----------------------------------------------------------------------
 * 2020. 2. 20. 09:46:00	    이지혜	  최초 작성
 *-----------------------------------------------------------------------
 */
<template>
	<div class="input-text text-box">
		<input
			:ref="id"
			:id="id"
			type="text"
			style="text-align: right"
			:value="formatedValue"
			:disabled="disabled"
			@blur="onBlurHandler"
			@keydown.enter.prevent="onEnter"
			@input="handleInput"
			:onkeydown="onKeyDown"
			:maxlength="calMaxlength"
			:placeholder="placeholder"
		/>
	</div>
</template>

<script>
import { isEmpty } from "@/utils";

export default {
	name: "InputAmt2",
	props: {
		value: {
			default: "",
			required: true
		},
		maxlength: {
			type: Number,
			default: null
		},
		disabled: {
			type: Boolean,
			default: false
		},
		placeholder: {
			type: String,
			default: ""
		},
		id: {
			type: String,
			default: ""
		},
		preventMinus: {
			type: Boolean,
			default: true
		}
	},
	data() {
		return {
			formatedValue: this.processFormatting(this.value),
			updateCount: 0
		};
	},
	watch: {
		value() {
			this.formatedValue = this.processFormatting(this.value);
		}
	},
	computed: {
		onKeyDown(e) {
			if (!this.preventMinus) {
				return `return  event.keyCode !== 187 && // '=,+'
												event.keyCode !== 107 && // '+'
												event.keyCode !== 191 && // '/'
												event.keyCode !== 111 && // '/'
												event.keyCode !== 106 && // '*'
												event.keyCode !== 192 && // '~'
												event.keyCode !== 222 && // '
												event.keyCode !== 186 && // ';'
												event.keyCode !== 188 && // ','
												event.keyCode !== 219 && // '['
												event.keyCode !== 221 && // ']'
												event.keyCode !== 220 && // '|\'
												event.keyCode !== 229 && // '한글'
		                    event.keyCode !== 81 && // 영문
		                    event.keyCode !== 87 &&
		                    event.keyCode !== 69 &&
		                    event.keyCode !== 82 &&
		                    event.keyCode !== 84 &&
		                    event.keyCode !== 89 &&
		                    event.keyCode !== 85 &&
		                    event.keyCode !== 73 &&
		                    event.keyCode !== 79 &&
		                    event.keyCode !== 80 &&
		                    event.keyCode !== 83 &&
		                    event.keyCode !== 68 &&
		                    event.keyCode !== 70 &&
		                    event.keyCode !== 71 &&
		                    event.keyCode !== 72 &&
		                    event.keyCode !== 74 &&
		                    event.keyCode !== 75 &&
		                    event.keyCode !== 76 &&
		                    event.keyCode !== 90 &&
												event.keyCode !== 66 &&
												event.keyCode !== 78 &&
												event.keyCode !== 32 &&
												event.keyCode !== 77`;
			} else {
				return `return  event.keyCode !== 189 && // 마이너스
												event.keyCode !== 109 && // 마이너스
												event.keyCode !== 187 && // '=,+'
												event.keyCode !== 107 && // '+'
												event.keyCode !== 191 && // '/'
												event.keyCode !== 111 && // '/'
												event.keyCode !== 106 && // '*'
												event.keyCode !== 192 && // '~'
												event.keyCode !== 222 && // '
												event.keyCode !== 186 && // ';'
												event.keyCode !== 188 && // ','
												event.keyCode !== 219 && // '['
												event.keyCode !== 221 && // ']'
												event.keyCode !== 220 && // '|\'
												event.keyCode !== 229 && // '한글'
		                    event.keyCode !== 81 && // 영문
		                    event.keyCode !== 87 &&
		                    event.keyCode !== 69 &&
		                    event.keyCode !== 82 &&
		                    event.keyCode !== 84 &&
		                    event.keyCode !== 89 &&
		                    event.keyCode !== 85 &&
		                    event.keyCode !== 73 &&
		                    event.keyCode !== 79 &&
		                    event.keyCode !== 80 &&
		                    event.keyCode !== 83 &&
		                    event.keyCode !== 68 &&
		                    event.keyCode !== 70 &&
		                    event.keyCode !== 71 &&
		                    event.keyCode !== 72 &&
		                    event.keyCode !== 74 &&
		                    event.keyCode !== 75 &&
		                    event.keyCode !== 76 &&
		                    event.keyCode !== 90 &&
												event.keyCode !== 66 &&
												event.keyCode !== 78 &&
												event.keyCode !== 32 &&
												event.keyCode !== 77`;
			}
		},
		calMaxlength() {
			if (
				!isEmpty(this.formatedValue.split(".")[1]) ||
				this.formatedValue.includes("-")
			)
				return this.maxlength + 1;
			else return this.maxlength;
		}
	},
	methods: {
		onBlurHandler(e) {
			const returnValue = this.getFormatValue(this.formatedValue);

			if (
				returnValue.toString().length > 1 &&
				returnValue.toString()[0] === "0"
			) {
				e.target.value = returnValue.substring(1);
			} else {
				e.target.value = returnValue;
			}

			this.$emit("blur", e);
		},
		handleInput(e) {
			let tempValue = e.target.value;

			if (this.preventMinus) {
				tempValue = e.target.value.replace(/-/gi, "");
			}

			this.formatedValue = this.processFormatting(tempValue);

			e.target.value = tempValue;

			this.$emit("input", { value: this.formatedValue });
		},
		processFormatting(value) {
			if (typeof value !== "string") value = String(value);

			if (!value) return "0"; // 수민씨 요청에 따라 첫 로딩시 '0'이 보이게 수정

			const reg2 = /(^0+)/;

			if (this.updateCount === 0 || this.updateCount === undefined) {
				this.$nextTick(() => {
					const returnValue = this.getFormatValue(this.formatedValue);

					this.$refs[this.id].value = this.getFormatValue(this.formatedValue);

					this.updateCount = this.updateCount + 1;
				});
			} else {
				if (!isEmpty(value.split(".")[1]) && value.split(".").length === 2) {
					value = `${value.split(".")[0].replace(reg2, "")}.${
						value.split(".")[1]
					}`;
				} else if (value.toString() !== "0") {
					value = value.replace(reg2, "");
				}
			}

			return value;
		},
		focus() {
			const input = this.$el.getElementsByTagName("input")[0];
			if (input) input.focus();
		},
		onEnter() {
			this.$emit("onEnter");
		},
		getFormatValue(value) {
			const reg2 = /(^0+)/;
			const reg3 = /(\d)(?=(\d{3})+(?!\d))/g;
			let returnValue = value;
			returnValue = returnValue.replace(reg3, "$1,");

			if (this.updateCount === 0 || this.updateCount === undefined) {
				if (!isEmpty(value.split(".")[1])) {
					const front = value.split(".")[0].replace(reg3, "$1,");
					let back = value.split(".")[1];

					returnValue = `${front}.${back}`;
				}
			} else {
				if (!isEmpty(value.split(".")[1]) && value.split(".").length === 2) {
					const front = value
						.split(".")[0]
						.replace(reg2, "")
						.replace(reg3, "$1,");
					let back = value.split(".")[1];

					returnValue = `${front}.${back}`;
				} else if (value.toString() !== "0") {
					returnValue = returnValue.replace(reg2, "");
				}
			}

			return returnValue;
		}
	}
};
</script>

<style lang="scss" scoped>
@import "@/scss/_input.scss";
</style>
