/**
 * 1. 개요 : 공통 팝업
 * 2. 처리내용 : 공통 팝업 - 팝업창 컴포넌트
 * @File Name : BizMngSavePopup.vue
 * @date : 2020. 02. 20. 09:46:00
 * @author : 이상철
 * @history :
 *-----------------------------------------------------------------------
 * 변경일			          		  작성자		변경내용
 *-----------------------------------------------------------------------
 * 2020. 2. 20. 09:46:00	    이상철	  최초 작성
 *-----------------------------------------------------------------------
 */
<template>
	<popup :title="title" @close="$emit('close')" popSize="size-grid">
    <div class="search-wrap">
			<collapse-search-pane :isSearchPaneShow="isPaSearchPaneShow" @fnShowSearchPane="fnShowPaSearchPane">
				<div class="search-area">
					<div id="tblInfo" class="hide">
							{{ 'label.userId' | i18n }}, {{ 'label.Nm' | i18n }} / {{ 'label.enm' | i18n}}
							&nbsp;{{ 'label.search' | i18n }}
					</div>
					<div class="tbl-wrap">
    				<table aria-describedby="tblInfo">
							<caption>{{ 'label.searchArea' | i18n }}</caption>
							<colgroup>
								<col style="width:150px">
								<col style="width:auto">
								<col style="width:150px">
								<col style="width:auto">
							</colgroup>
							<tbody>
								<tr>
									<th scope="row">
										<label for="searchAxrBizBizNo">{{ 'label.userId' | i18n }}</label>
									</th>
									<td>
										<input-text
											id="searchAxrCmnPsnPopupUserId"
											v-model="search.searchPsnNo"
											type="text"
											name="searchCmnPsnUserid"
											@keydown.enter="searchList"
										/>
									</td>
									<th scope="row">
										<label for="searchAxrCmnPsnPopupUserNm">{{ 'label.Nm' | i18n }} / {{ 'label.enm' | i18n}}</label>
									</th>
									<td>
										<input-text
											id="searchAxrCmnPsnPopupUserNm"
											v-model="search.searchPsnNm"
											type="text"
											name="searchCmnPsnUserNm"
											@keydown.enter="searchList"
										/>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</collapse-search-pane>
			<div class="search-btn">
						<button type="button" name="searchList" @click.stop="searchList">{{'button.look' | i18n }}</button>
						<button type="button" name="clear" @click.stop="clear">{{'button.init' | i18n }}</button>
			</div>
		</div>
		<div>
			<data-grid
				ref="dataGrid1"
				:totalItems="gridProps.totalItems"
				:columnDefs="gridProps.columnDefs"
				:rowData="gridProps.rowData"
				:initPage="gridProps.initPage"
				:perPage="pageSize"
				:selectedRows="gridProps.selectedRows"
				:gridHeight="gridProps.gridHeight"
				:rowClassRules="gridProps.rowClassRules"
				:frameworkComponents="gridProps.frameworkComponents"
				:rowSelection="gridProps.rowSelection"
				:topBtnList="gridProps.topBtnList"
				:suppressRowClickSelection="gridProps.suppressRowClickSelection"
				@rowSelected="setRowSelected"
				@selectionChanged="setSelectionChangedRow"
				@callOnRowDoubleClicked="fnRowDoubleClick"
				@fnList="fnList"
			/>
		</div>
	</popup>
</template>

<script>
import Popup from "@/components/Popup";
import CollapseSearchPane from "@/components/CollapseSearchPane";
import { InputText } from "@/components/Inputs";

import { EventBus } from "./EventBus";
import { CmnPsnPopupViewModel, Helper, AnimationHelper } from "./mixins";
import { isEmpty } from "@/utils";
import DataGrid from "@/components/DataGrid";
import FileDownRenderer from "@/components/GridRenderer/FileDownRenderer";

export default {
	name: "CmnPsnPopup",
	components: {
    Popup,
    CollapseSearchPane,
    InputText,
    DataGrid
  },
	mixins: [CmnPsnPopupViewModel, Helper, AnimationHelper],
	props: {
    paramData: {
      type: Object,
      default: () => {}
    }
  },
	data() {
		return {
      resultList: [],
      currPage: 1,
			pageUnit: 100,
			pageSize: 100,
			search: {
				searchPsnNo: "",
				searchPsnNm: "",
				searchGrpTypCd: "",
				searchGrpCd: ""
      },

      gridProps: {
				totalItems: 0,
				columnDefs: [],
				rowData: [],
				// 일반적인 row 선택 or 더블클릭시
				selectedRows: [],
				initPage: true,
				editable: true,
				gridHeight: 390,
				rowClassRules: null,
				frameworkComponents: null,
				rowSelection: null,
				// 로우 선택시 선택이 되도록 하려면 false, 체크박스 or 라디오 선택시 선택이 되오도록 하려면 true
				suppressRowClickSelection: false,
				topBtnList: []
			},
			// 일반적인 row 선택 or 더블클릭시
			selectedRow: null,
			// 체크박스 또는 라디오 row 선택시
      selectedCheckOrRadioRows: [],

			isPaSearchPaneShow: true,
		};
  },
  computed: {
		title() {
			return "개인ID"
		}
  },
  watch: {
		// row 더블 클릭 했을 경우 다시 호출
		selectedRow: {
			handler(params) {
				this.setColumnDefs(params);
				this.setRowClassRules();
			}
		}
	},
	beforeMount() {

		// 그리드 초기화
		this.setColumnDefs();

		// row 클래스 동적 적용
		this.setRowClassRules();

		// 그리드의 frameworkComponents 셋(필요한 경우에만)
		this.setFrameworkComponents();

		// 그리드 상단의 버튼 리스트 정의
		this.setTopBtnList()

		// 그리드의 rowSelection 셋
		this.setRowSelection()
  },
  mounted() {
		this.search.searchGrpTypCd = this.paramData.grpTypCd;
		this.search.searchGrpCd = this.paramData.grpCd;
		if(!isEmpty(this.paramData.psnId)) {
			// this.search.merId = this.paramData.merId
			console.log('CmnPsnPopup 파라미터값' + this.paramData.psnId);
		}

    this.searchList();
	},
	beforeDestroy() {
		EventBus.$off("isSearchPaneShow"+this.$route.name);
		EventBus.$off("isInnerSearchPaneShow"+this.$route.name);
	},
	methods: {
    // 사용자 목록 조회
		searchList() {
      this.resultList = [];
      this.gridProps.rowData = [];

      this.currPage = 1;
			this.gridProps.initPage = true;

			this.$httpPost(
					"/api/bep/getCmnPsnList.do",
					{
						searchPsnNo: this.search.searchPsnNo,
						searchPsnNm: this.search.searchPsnNm,
						searchGrpTypCd: this.search.searchGrpTypCd,
						searchGrpCd: this.search.searchGrpCd,

						currPage: this.currPage,
						pageUnit: this.pageUnit,
						pageSize: this.pageSize
					},
					{
						showLoading: true
					}
				)
				.then(response => {
					// result 셋
					this.resultList = response.data.resultList
					// 데이터 총 갯수 셋
          this.gridProps.totalItems = response.data.totalCount;
				})
				.then(() => {
					// 그리드에 조회해온 데이터 셋
					if (this.resultList)
					  this.gridProps.rowData = this.resultList.map(a => ({
								...a,
								status: "R"
						}))
				})
				.catch(this.commonErrorHandler)
    },

		// 페이지 변환시 데이터 조회 함수
		fnList(currPage) {
			this.currPage = currPage;
			this.searchList();
			this.gridProps.initPage = false;
    },

    // 더블 클릭시
		fnRowDoubleClick(selectedRow) {
			this.$emit("fnRowDoubleClick", selectedRow)
			this.$emit("close")
		},

    // 그리드에 하나의 row 선택 함수
		setSelectionChangedRow(params) {
			this.gridProps.selectedRows = [];
			this.gridProps.selectedRows = [{ ...params.api.getSelectedRows()[0], status: "U" }];
		},

    // 그리드의 columnDefs 셋
		setColumnDefs(params = {}) {
			this.gridProps.columnDefs = this.CmnPsnPopupViewModel(params).map(a => ({
				headerName: a.headerName,
				field: a.field,
				width: a.width,
				editable: a.editable,
				pinned: a.pinned,
				cellStyle: a.cellStyle,
				cellClassRules: a.cellClassRules,
				valueFormatter: a.valueFormatter,
				cellRenderer: a.cellRenderer,
				checkboxSelection: a.checkboxSelection,
				headerCheckboxSelection: a.headerCheckboxSelection,
				suppressMenu: a.suppressMenu,
				sortable: a.sortable,
				hide: a.hide
			}));
    },

		// 그리드 상단의 버튼 리스트 정의
		setTopBtnList() {},
		
    // 그리드의 rowClass 셋
		setRowClassRules() {
			this.gridProps.rowClassRules = {
				// 'selected-row': param => this.selectedRow && (this.selectedRow.rowIndex === param.rowIndex)
			}
		},

		// 그리드의 frameworkComponents 셋
		setFrameworkComponents() {
			this.gridProps.frameworkComponents = {
				FileDownRenderer
			}
		},

		// 그리드의 rowSelection 셋
		setRowSelection() {
			// 단수 선택 경우 'single', 복수 선택일 경우 'multiple'
			this.gridProps.rowSelection = 'single'
		},

		// 셀렉트박스 또는 라디오 선택시
		setRowSelected(params) {
			if (params.node.isSelected()) {
				this.selectedCheckOrRadioRows.push(params.data);
			} else {
				this.selectedCheckOrRadioRows.pop(params.data);
			}
		},

		validateForDelRow() {
			// 선택한 데이터가 없는 경우 리턴
			if (isEmpty(this.gridProps.selectedRows[0])) {
				this.alert({
					type: "warning",
					title: this.i18n("label.confirm"),
					message: this.i18n("alt.noSelect")
				});
				return;
			} else {
				this.alert({
					title: this.i18n("label.del"),
					message: this.i18n("alt.questionDel"),
					buttons: [
						{
							type: "primary",
							label: this.i18n("button.confirm"),
							onPress: () => this.fnDelRow()
						},
						{
							label: this.i18n("button.cancel")
						}
					]
				});
			}
		},

    // 입력 폼 초기화
		clear() {
			this.search = {
				searchPsnNo: "",
				searchPsnNm: ""
			};
    },
  }
};
</script>

<style lang="scss" scoped>
@import "@/scss/_color.scss";
@import "@/scss/_button.scss";
</style>


