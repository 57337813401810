import Vue from "vue";
import Router from "vue-router";
import leesc from "./leesc";
import kimks from "./kimks";
import kimyn from "./kimyn";
import leehw from "./leehw";
import leejh from "./leejh";
import wangsm from "./wangsm";
import koyh from "./koyh";
import kobk from "./kobk";
import leejw from "./leejw";
import hansh from "./hansh";
import kimhy from "./kimhy";
import kimjs from "./kimjs";
import lamnt from "./lamnt";

Vue.use(Router);

export default new Router({
  mode: "history",
  routes: [
    ...leesc,
    ...kimks,
    ...kimyn,
    ...leehw,
    ...leejh,
    ...wangsm,
    ...koyh,
    ...kobk,
    ...leejw,
    ...hansh,
    ...kimhy,
    ...kimjs,
    ...lamnt,
    {
      path: "*",
      redirect: "/404"
    }
  ]
});
