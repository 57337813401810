/**
 * 1. 개요 : 로그인
 * 2. 처리내용 : 로그인 Mixins - helper
 * @File Name : helper.js
 * @date : 2020. 02. 20. 09:46:00
 * @author : 이상철
 * @history :
 *-----------------------------------------------------------------------
 * 변경일			          		  작성자		변경내용
 *-----------------------------------------------------------------------
 * 2020. 2. 20. 09:46:00	    이상철	  최초 작성
 *-----------------------------------------------------------------------
 */
import { isEmpty } from "@/utils";
import { validEmail } from "@/utils/validate";

export default {
  methods: {
    /**
     * 밸리데이터 함수
     * @params {Array} params
     * @return {Boolean}
     */
    validateHelper(params) {
      const notValidList = params.filter(a => !a.isValidate);

      if (!isEmpty(notValidList)) {
        this.alert({
          type: "warning",
          title: this.isLogin
            ? this.i18n("label.confirm")
            : this.i18nOff("label.confirm"),
          message: notValidList[0].message,
          buttons: [
            {
              label: this.isLogin
                ? this.i18n("button.confirm")
                : this.i18nOff("button.confirm"),
              onPress: () => {
                // date-picker를 쓰는 inputDate, inputTime, inputMonth일 경우
                if (
                  !isEmpty(notValidList[0].datePicker) &&
                  notValidList[0].datePicker
                )
                  notValidList[0].ref && notValidList[0].ref.focus();
              }
            }
          ]
        });

        if (isEmpty(notValidList[0].datePicker))
          // inputDate, inputTime, inputMonth을 제외한 경우
          notValidList[0].ref && notValidList[0].ref.focus();

        return true;
      } else {
        return false;
      }
    },
    /**
     * 이메일 포맷 체크 함수(필수인 경우 - validate에 isEmpy 체크 필)
     * @params {String} params
     * @return {Boolean}
     */
    emailFormatCheckHelperRequired(params) {
      if (isEmpty(params)) {
        return false;
      } else {
        return validEmail(params); // 무언가를 썼을 경우, 이메일 포맷 체크를 한다.
      }
    }
  }
};
