/**
 * 1. 개요 : admin
 * 2. 처리내용 : admin - 진입점
 * @File Name : index.vue
 * @date : 2020. 02. 20. 09:46:00
 * @author : 이상철
 * @history :
 *-----------------------------------------------------------------------
 * 변경일			          		  작성자		변경내용
 *-----------------------------------------------------------------------
 * 2020. 2. 20. 09:46:00	    이상철	  최초 작성
 *-----------------------------------------------------------------------
 */
<template>
	<div class="al-wrap">
		<!-- 접근성 tab 이동 -->
		<div class="skip">
			<a href="#elMenu">{{ 'alt.gotoMenu' | i18n }}</a>
			<a href="#appMain">{{ 'alt.gotoContents' | i18n }}</a>
		</div>
		<!-- / 접근성 tab 이동 -->
		<navbar />
		<div :class="classObj" class="app-wrapper flex">
			<sidebar class="sidebar-container" />
			<div :class="{hasTagsView:needTagsView}" class="main-container">
				<div :class="{'fixed-header':fixedHeader}">
					<tags-view v-if="needTagsView" />
				</div>
				<app-main id="appMain" />
				<copyright />
			</div>
		</div>
	</div>
</template>

<script>
import { mapState } from "vuex";
import { AppMain, Copyright, Navbar, Sidebar, TagsView } from "./components";

export default {
	name: "Layout",
	components: {
		AppMain,
		Copyright,
		Navbar,
		Sidebar,
		TagsView
	},
	computed: {
		...mapState({
			sidebar: state => state.Sidebar.sidebar,
			needTagsView: state => state.Settings.tagsView,
			fixedHeader: state => state.Settings.fixedHeader
		}),
		classObj() {
			return {
				hideSidebar: !this.sidebar.opened,
				openSidebar: this.sidebar.opened,
				withoutAnimation: this.sidebar.withoutAnimation
			};
		},
		cachedViews() {
			return this.$store.state.TagsView.cachedViews;
		},
		key() {
			return this.$route.path;
		},
		shownFooter() {
			return this.$route.meta.useFooter !== false;
		}
	},
	methods: {
		handleClickOutside() {
			this.$store.dispatch("Sidebar/closeSideBar", { withoutAnimation: false });
		}
	}
};
</script>

<style lang="scss" scoped>
@import "~@/styles/mixin.scss";
@import "~@/styles/variables.scss";

.app-wrapper {
	@include clearfix;
	position: relative;
	height: 100%;
	width: 100%;

	&.mobile.openSidebar {
		position: fixed;
		top: 0;
	}
}

.drawer-bg {
	background: #000;
	opacity: 0.3;
	width: 100%;
	top: 0;
	height: 100%;
	position: absolute;
	z-index: 999;
}

.fixed-header {
	position: fixed;
	top: 0;
	right: 0;
	z-index: 9;
	width: calc(100% - #{$sideBarWidth});
	transition: width 0.28s;
}

.hideSidebar .fixed-header {
	width: calc(100% - 54px);
}

.mobile .fixed-header {
	width: 100%;
}

// alliex
#app {
	.al-wrap {
		height: 100%;
		.sidebar-container {
			position: relative;
		}
		.app-wrapper {
			height: calc(100% - 40px);
		}
		.main-container {
			// overflow-y:auto; content영역 스크롤
			width: calc(100% - 10px);
			height: calc(100% - 50px);
			margin-left: 0;
			padding: 36px 0 30px;
			transition: width 0.6s;
			.app-main {
				min-height: calc(100% - 32px); //57
			}
		}
		.openSidebar .main-container {
			// 사파리 대응
			width: calc(100% - 210px);
			transition: width 0.4s;
		}
		.fixed-header {
			position: absolute;
			width: 100%;
		}
		.copyright {
			position: absolute;
			bottom: 0;
		}
	} //.al-wrap
}
</style>
