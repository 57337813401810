 /**
 * 1. 개요 : 공통 비밀번호 확인 팝업
 * 2. 처리내용 : 공통 비밀번호 확인 팝업 Mixins - helper
 * @File Name : helper.js
 * @date : 2020. 08. 12. 14:00:00
 * @author : 이지혜
 * @history :
 *-----------------------------------------------------------------------
 * 변경일			          		  작성자		변경내용
 *-----------------------------------------------------------------------
 * 2020. 08. 12. 14:00:00	    이지혜	  최초 작성
 *-----------------------------------------------------------------------
 */
import { isEmpty } from "@/utils";

export default {
  methods: {
    /**
     * 밸리데이터 함수
     * @params {Array} params
     * @return {Boolean}
     */
    validateHelper(params) {
      const notValidList = params.filter(a => !a.isValidate);

      if (!isEmpty(notValidList)) {
        this.alert({
          type: "warning",
          title: this.i18n("label.confirm"),
          message: notValidList[0].message,
          buttons: [{ 
            label: this.i18n("button.confirm"),
            onPress: () => { // date-picker를 쓰는 inputDate, inputTime, inputMonth일 경우
              if(!isEmpty(notValidList[0].datePicker) && notValidList[0].datePicker) 
              notValidList[0].ref && notValidList[0].ref.focus()
            }
          }]
        });
        
        if(isEmpty(notValidList[0].datePicker)) // inputDate, inputTime, inputMonth을 제외한 경우
        notValidList[0].ref && notValidList[0].ref.focus()

        return true;
      } else {
        return false;
      }
    },
  }
};
