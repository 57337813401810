/**
 * 1. 개요 : 유심정보 검색
 * 2. 처리내용 : 유심정보 검색 - 팝업창 컴포넌트 
 * @File Name : CmnMvnoPopup.vue 
 * @date : 2020. 04. 20. 13:00:00
 * @author : 이현우
 * @history :
 *-----------------------------------------------------------------------
 * 변경일			          		  작성자		변경내용
 *-----------------------------------------------------------------------
 * 2020. 04. 20. 13:00:00	    이현우	  최초 작성
 *-----------------------------------------------------------------------
 */
<template>
	<popup :title="title" @close="$emit('close')" popSize="size-grid">
		<div class="search-wrap">
			<collapse-search-pane
				:isSearchPaneShow="isPaSearchPaneShow"
				@fnShowSearchPane="fnShowPaSearchPane"
			>
				<div class="search-area">
					<div id="tblInfo" class="hide">
						{{ "label.mvnoSn" | i18n }}, {{ "label.merNm" | i18n }}, {{ "label.stckTgtCd" | i18n }}, {{ "label.mappingYn" | i18n }},{{ "label.stckYn" | i18n }}
							&nbsp;{{ 'label.search' | i18n }}
					</div>
					<div class="tbl-wrap">
    				<table aria-describedby="tblInfo">
							<caption>
								{{"label.searchArea" | i18n}}
							</caption>
							<colgroup>
								<col style="width:150px">
								<col style="width:auto">
								<col style="width:150px">
								<col style="width:auto">
							</colgroup>
							<tbody>
								<tr>
									<th scope="row">
										<label for="searchMvnoMdlCd">{{ "label.mvnoMdl" | i18n }}</label>
									</th>
									<td colspan="3">
										<biz-selector
											id="searchMvnoMdlCd"
											v-model="search.mvnoMdlCd"
											:list="mvnoMdlCdList"
											:isAll="true"
											@keydown.enter="validate"
											disabled
										/>
									</td>
									<!--th scope="row">
										<label for="searchMerNm">{{ "label.merNm" | i18n }}</label>
									</th>
									<td>
										<search-input-text
											id="searchMerNm"
											ref="searchMerNm"
											v-model="search.merNm"
											type="text"
											:placeholder="i18n('label.merNm')"
											@fnSearch="showCmnMerInfPopup"
											@change="fnChangeMerNm($event.target.value)"
										/-->
										<!-- <input-text
											id="searchMerNm"
											v-model="search.merNm"
											type="text"
											name="searchMerNm"
											@keydown.enter="searchList"
										/> -->
									<!--/td-->
								</tr>
								<tr>
									<th scope="row">
										<label for="searchStckTgtLvlCd">{{ "label.stckTgtLvl" | i18n }}</label>
									</th>
									<td>
										<code-selector
											id="searchStckTgtLvlCd"
											v-model="search.stckTgtLvlCd"
											:cdList="cdList.stckTgtLvlCdList"
											:isAll="stckTgtLvlAll"
											@keydown.enter="validate"
											:disabled="stckTgtDisabled || this.paramData.lvlDisabledYn === 'Y'"
											@change="fnChgLvlCd($event.target.value)"
										/>
									</td>
									<th scope="row">
										<label for="searchStckTgtCd">{{ "label.stckTgt" | i18n }}</label>
									</th>
									<td>
										<search-input-text
											id="searchStckTgtNm"
											ref="searchStckTgtNm"
											v-model="search.stckTgtNm"
											type="text"
											:placeholder="i18n('label.stckTgt')"
											:btnDisabled="stckTgtBtnDisabled"
											:inputDisabled="stckTgtInputDisabled"
											@fnSearch="validateShowCmnInfPopup"
											@change="fnChangeStckTgtNm($event.target.value)"
										/>
									</td>
								</tr>
								<tr>
									<th scope="row">
										<label for="searchMappingYn">{{ "label.mappingYn" | i18n }}</label>
									</th>
									<td>
										<check-box
											id="searchMappingYn"
											ref="searchMappingYn"
											:checked="search.mappingYn === true"
											:disabled="search.disabledYn === 'Y'"
											@change="search.mappingYn = !search.mappingYn"
										/>
									</td>
									<th scope="row">
										<label for="searchStckYn">{{ "label.stckYn" | i18n }}</label>
									</th>
									<td>
										<check-box
											id="searchStckYn"
											ref="searchStckYn"
											:checked="search.stckYn === true"
											:disabled="search.disabledYn === 'Y'"
											@change="search.stckYn = !search.stckYn"
										/>
									</td>
								</tr>
								<tr>
									<th scope="row">
										<label for="pageUnit">{{ "label.pageUnit" | i18n }}</label>
									</th>
									<td>
										<input-text
											id="pageUnit"
											ref="searchPageUnit"
											name="pageUnit"
											v-model="pageUnit"
											:isNumberType="true"
											@keydown.enter.prevent="validate"
										/>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</collapse-search-pane>
			<div class="search-btn">
				<button type="button" name="searchList" @click.stop="validate">{{ "button.look" | i18n }}</button>
				<button type="button" name="clear" @click.stop="clear">{{ "button.init" | i18n }}</button>
			</div>
		</div>
		<!-- 이현우 차장님 요청으로 추가된 코드입니다. 그리드 상단에 위치하며 선택된 Row의 개수를 보여줍니다. -->
		<div v-if="showSelectedRowNm" style="position:absolute;transform:translateY(15px)">Selected Row: {{ getMvnoListLength }}</div>
		<div>
			<data-grid
				ref="dataGrid1"
				:key="key"
				:totalItems="gridProps.totalItems"
				:columnDefs="gridProps.columnDefs"
				:rowData="gridProps.rowData"
				:initPage="gridProps.initPage"
				:perPage="pageSize"
				:selectedRows="gridProps.selectedRows"
				:gridHeight="gridProps.gridHeight"
				:rowClassRules="gridProps.rowClassRules"
				:frameworkComponents="gridProps.frameworkComponents"
				:rowSelection="gridProps.rowSelection"
				:topBtnList="gridProps.topBtnList"
				:suppressRowClickSelection="gridProps.suppressRowClickSelection"
				:icons="gridProps.icons"
				@rowSelected="setRowSelected"
				@selectionChanged="setSelectionChangedRow"
				@callOnRowDoubleClicked="fnRowDoubleClick"
				@fnList="fnList"
			/>

			<cmn-agncy-inf-popup
				v-if="isShowCmnAgncyInfPopup"
				@close="closeCmnAgncyInfPopup"
				@fnRowDoubleClick="fnCmnAgncyInfPopupDoubleClick"
				:paramData="search"
			/>
			<cmn-distr-inf-popup
				v-if="isShowCmnDistrInfPopup"
				@close="closeCmnDistrInfPopup"
				@fnRowDoubleClick="fnCmnDistrInfPopupDoubleClick"
				:paramData="search"
			/>
			<cmn-mer-inf-popup
				v-if="isShowCmnMerInfPopup"
				@close="closeCmnMerInfPopup"
				@fnRowDoubleClick="fnCmnMerInfPopupDoubleClick"
				:paramData="search"
			/>
			
		</div>
	</popup>
</template>

<script>
import Popup from "@/components/Popup";
import CollapseSearchPane from "@/components/CollapseSearchPane";

import { EventBus } from "./EventBus";
import { CmnMvnoPopupViewModel, Helper, AnimationHelper } from "./mixins";
import { isEmpty } from "@/utils";
import { validNumberInputData } from "@/utils/validate";
import DataGrid from "@/components/DataGrid";
import CmnAgncyInfPopup from "../../common/CmnAgncyInfPopup";
import CmnDistrInfPopup from "../../common/CmnDistrInfPopup";
import CmnMerInfPopup from "../../common/CmnMerInfPopup";

export default {
	name: "CmnMvnoPopup",
	components: {
		Popup,
		CollapseSearchPane,
		DataGrid,
		CmnAgncyInfPopup,
		CmnDistrInfPopup,
		CmnMerInfPopup
	},
	mixins: [CmnMvnoPopupViewModel, Helper, AnimationHelper],
	props: {
		paramData: {
			type: Object,
			default: () => {}
		},
		showSelectedRowNm: {
			type: Boolean,
			dafault: false
		},
		limitCheckYn: { // 9월 23일 이지혜 추가
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			key: 0,
			resultList: [],
			currPage: 1,
			pageUnit: 100,
			pageSize: 100,
			search: {
				trmId: "",
				stckTgtLvlCd: "",
				stckTgtCd: "",
				disabledYn: "",
				lvlDisabledYn: "",
				mappingYn: "",
				stckYn: ""
			},

			gridProps: {
				icons: null,
				totalItems: 0,
				columnDefs: [],
				rowData: [],
				// 일반적인 row 선택 or 더블클릭시
				selectedRows: [],
				initPage: true,
				editable: true,
				gridHeight: 260,
				rowClassRules: null,
				frameworkComponents: null,
				rowSelection: null,
				// 로우 선택시 선택이 되도록 하려면 false, 체크박스 or 라디오 선택시 선택이 되오도록 하려면 true
				suppressRowClickSelection: false,
				topBtnList: []
			},
			// 일반적인 row 선택 or 더블클릭시
			selectedRow: null,
			// 체크박스 또는 라디오 row 선택시
			// selectedCheckOrRadioRows: [],

			isPaSearchPaneShow: true,
			cdList: {
				stckTgtLvlCdList: [],
				stckStatCdList: []
			},
			mvnoMdlCdList: [],
			stckTgtDisabled: false,
			stckTgtInputDisabled: false,
			stckTgtBtnDisabled: false,
			stckTgtLvlAll: true,
			isShowCmnAgncyInfPopup: false,
			isShowCmnDistrInfPopup: false,
			isShowCmnMerInfPopup: false,
			limitCheckCnt: 0
		};
	},
	
	created() {
		this.fnGetAxcCodeGrpList(["STCK_TGT_LVL_CD", "STCK_STAT_CD"]);
		this.searchCdMvnoMdl();
	},
	computed: {
		/**
     * 화면 title 설정
     * @return {String}
     */
		title() {
			return this.i18n("label.mvno") + this.i18n("label.search");
		},
		/**
     * vuex를 이용해서 저장했던 state를 불러옴.
     * @return {Array}
     */
		getMvnoList() {
			return this.$store.getters["CmnMvnoPopupChecked/getMvnoList"];
		},
		/**
     * 저장했던 유심시리얼번호 목록 length.
     * @return {Array}
     */
		getMvnoListLength(){
			return this.getMvnoList.length
		},
		/**
     * 저장했던 유심시리얼번호 목록 check.
     * @return {Array}
     */
		getLimitCheckCnt() {
			return this.$store.getters["CmnMvnoPopupChecked/getlimitCheckCnt"];
		}
	},
	watch: {
		// row 더블 클릭 했을 경우 다시 호출
		selectedRow: {
			handler(params) {
				this.setColumnDefs(params);
				this.setRowClassRules();
			}
		}
	},
	beforeMount() {
		this.search.mvnoMdlCd = this.paramData.mdlCd;
		this.search.stckTgtCd = this.paramData.stckTgtCd;
		this.search.disabledYn = this.paramData.disabledYn;
		this.search.lvlDisabledYn = this.paramData.lvlDisabledYn;
		this.search.mappingYn = this.paramData.disabledYn == "Y" ? false : true;
		this.search.stckYn = this.paramData.disabledYn == "Y" ? true : false;
		this.search.reqQty = this.paramData.reqQty;
		this.search.chkQty = parseInt(this.paramData.chkQty, 0);
		this.search.rowSelection = this.paramData.rowSelection == "multiple" ? "multiple" : "single";
		this.gridProps.suppressRowClickSelection = this.paramData.rowSelection == "multiple" ? true : false;

		// 그리드 초기화
		this.setColumnDefs();

		// row 클래스 동적 적용
		this.setRowClassRules();

		// 그리드의 frameworkComponents 셋(필요한 경우에만)
		this.setFrameworkComponents();

		// 그리드 상단의 버튼 리스트 정의
		this.setTopBtnList();

		// 그리드의 rowSelection 셋
		this.setRowSelection();
		// 그리드의 icon 셋
    this.gridProps.icons = {
      checkboxUnchecked: `<label class="ag-check-box" tabindex="0">
                          	<input type="checkbox">
                          	<span class="ag-check-box-icon"></span>
                          </label>`,
      checkboxChecked: `<label class="ag-check-box checked" tabindex="0">
                        	<span class="ag-check-box-icon"></span>
												</label>`,
			checkboxIndeterminate: `<label class="ag-check-box" tabindex="0">
                        				<span class="ag-check-box-icon"></span>
															</label>`
    }
	},
	// @이지혜 추가
	beforeDestroy() {
		this.$store.dispatch("CmnMvnoPopupChecked/initialMvnoList", []);
		this.$store.dispatch("CmnMvnoPopupChecked/initialLimitCheckCnt", 0); // 9월 25일
	},
	mounted() {
		// 9월 25일. 스토어에 선택해야하는 숫자를 올림.
		this.$store.dispatch("CmnMvnoPopupChecked/setLimitCheckCnt", this.limitCheckCnt);

		let grpTypCd = this.$store.getters["Options/userInfo"].grpTypCd;

		// 재고대상레벨이 본사가 아닐 경우
		if(grpTypCd != "01") {
			this.stckTgtLvlAll = false;
			// 로그인 유저가 대리점일 경우
			if (grpTypCd == "04") {
				this.stckTgtDisabled = true;
			}
		}

		this.$nextTick(() => {
			// 로그인레벨정보 조회
			this.searchSysOrgView();
		});
	},
	methods: {
		/**
     * 업무 코드 리스트 페치
     * @params {Array} searchCodeGrpIdList
     */
		async fnGetAxcCodeGrpList(searchCodeGrpIdList = []) {
			if (isEmpty(searchCodeGrpIdList)) return;

			await this.$httpPost(
					"/api/bep/getAxcCodeGroupListByCodeGrpIdList.do",
					{ searchCodeGrpIdList },
					{ showLoading: true }
				)
				.then(response => {
					this.cdList.stckTgtLvlCdList = response.data.resultList.find(a => a[0].codeGrpId === "STCK_TGT_LVL_CD");
					this.cdList.stckStatCdList = response.data.resultList.find(a => a[0].codeGrpId === "STCK_STAT_CD");
				})
				.catch(this.commonErrorHandler);

			this.key = this.key + 1;
		},

		/**
     * 유심모델코드 조회(콤보박스)
     */
		async searchCdMvnoMdl() {
			await this.$httpPost(
					"/api/bep/getMvnoMdlMngListForCombo.do",
					{},
					{
						showLoading: false
					}
				)
				.then(response => {
					this.mvnoMdlCdList = response.data.resultList;
				})
				.catch(this.commonErrorHandler);
		},

		/**
     * 유심목록 조회
     * @params {Object} search
     */
		async searchList(search) {
			if (search) {
				this.currPage = 1;
				this.gridProps.initPage = true;
				// 선택대상 초기화
				this.$store.dispatch("CmnMvnoPopupChecked/initialMvnoList", []);
			}
			this.resultList = [];
			this.gridProps.rowData = [];

			await this.$httpPost(
					"/api/bep/getCmnMvnoList.do",
					{
						searchMvnoMdlCd: this.search.mvnoMdlCd,
						searchStckTgtLvlCd: this.search.stckTgtLvlCd,
						searchStckTgtCd: this.search.stckTgtCd,
						searchMappingYn: this.search.mappingYn == true ? "Y" : "",
						searchStckYn: this.search.stckYn == true ? "Y" : "",

						currPage: this.currPage,
						pageUnit: this.pageUnit,
						pageSize: this.pageUnit
					},
					{
						showLoading: true
					}
				)
				.then(response => {
					// 페이지 수 변경
					this.pageSize = this.pageUnit;
					// result 셋
					this.resultList = response.data.resultList;
					// 데이터 총 갯수 셋
					this.gridProps.totalItems = response.data.totalCount;
				})
				.then(() => {
					// 그리드에 조회해온 데이터 셋
					if (this.resultList) {
						this.gridProps.rowData = this.resultList.map(a => ({
							...a,
							status: "R"
						}));

						// state가 있으면 fnDefaultRowSelectedList를 실행한다.
						if (!isEmpty(this.getMvnoList)) {
							this.$nextTick(() => this.fnDefaultRowSelectedList());
						}
					}
				})
				.catch(this.commonErrorHandler);
		},
		validate() {
			if (
				this.validateHelper([
					{
						ref: this.$refs.searchPageUnit,
						isValidate: validNumberInputData(this.pageUnit),
						message: this.i18n("alt.validateInputNum")
					},
				])
			) {
				return;
			} else {
				this.searchList();
			}
		},

		/**
     * 사용자 정보 조회
     */
		async searchSysOrgView() {
			this.sysOrgViewList = [];
			await this.$httpPost(
					"/api/bep/getSysOrgView.do",
					{},
					{
						showLoading: true
					}
				)
				.then(response => {
					this.sysOrgViewList = response.data.resultList[0];

					this.search.stckTgtLvlCd = this.sysOrgViewList.grpTypCode;
					this.fnChgLvlCd(this.search.stckTgtLvlCd);

					// 주문 목록 조회
					this.searchList();

				})
				.catch(this.commonErrorHandler);
		},

		/**
     * 페이지 변환시 데이터 조회 함수
     * @params {int} currPage
     */
		fnList(currPage) {
			this.currPage = currPage;
			this.searchList();
			this.gridProps.initPage = false;
		},

		/**
     * 선택 목록 리턴
     */
		fnRtnAplyClick() {
			if (this.getMvnoList.length < 1) {
				this.alert({
					type: "warning",
					title: this.i18n("label.confirm"),
					message: this.i18n("alt.noSelect")
				});
				return;
			}

			this.$emit(
				"fnRtnAplyClick",
				this.getMvnoList.map(a => ({
					sn: a.mvnoSn
				}))
			);
			this.$emit("close");
		},

		/**
     * 더블 클릭시
     * @params {Object} selectedRow
     */
		fnRowDoubleClick(selectedRow) {
			if(this.search.rowSelection == "single") {
				this.$emit("fnRowDoubleClick", selectedRow);
				this.$emit("close");
			}
		},

		/**
     * 선택한 목록 체크
     */
		fnDefaultRowSelectedList() {
			this.$nextTick(() => {
				this.$refs.dataGrid1.gridApi.forEachNode(node => {
					const selectedMvnoList = this.getMvnoList.map(a => a.mvnoSn);
					node.setSelected(selectedMvnoList.includes(node.data.mvnoSn));
				});
			});
		},

		/**
     * 그리드에 하나의 row 선택 함수
     * @params {Object} params
     */
		setSelectionChangedRow(params) {
			this.gridProps.selectedRows = [];
			this.gridProps.selectedRows = [
				{ ...params.api.getSelectedRows()[0], status: "U" }
			];
		},

		/**
     * 그리드의 columnDefs 셋
     * @params {Object} params
     */
		setColumnDefs(params = {}) {
			this.$nextTick(() => {
				let gridWidth = this.$refs.dataGrid1.$el.clientWidth - 20;
				let totalWidth = 0;

				this.gridProps.columnDefs = this.cmnMvnoPopupViewModel(params).map(a => {
					totalWidth += a.width === 0 ? 200 : a.width;

					return {
						headerName: a.headerName,
						field: a.field,
						width:
							a.field === "restCell" && totalWidth > 0
								? gridWidth - totalWidth + 200
								: a.width,
						editable: a.editable,
						pinned: a.pinned,
						cellStyle: a.cellStyle,
						cellClassRules: a.cellClassRules,
						valueFormatter: a.valueFormatter,
						cellRenderer: a.cellRenderer,
						headerCheckboxSelection: a.headerCheckboxSelection,
						headerCheckboxSelectionFilteredOnly:a.headerCheckboxSelectionFilteredOnly,
						checkboxSelection: a.checkboxSelection,
						headerCheckboxSelection: a.headerCheckboxSelection,
          	headerCheckboxSelectionFilteredOnly: a.headerCheckboxSelectionFilteredOnly,
						suppressMenu: a.suppressMenu,
						sortable: a.sortable
					};
				});
			});
		},

		/**
     * 그리드 상단의 버튼 리스트 정의
     */
		setTopBtnList() {
			let aplyBtn = {
				type: "primary",
				disabled: false,
				title: this.i18n("button.aply"),
				event: this.fnRtnAplyClick
			};

			this.gridProps.topBtnList = [];

			if (this.search.rowSelection == "multiple") {
				this.gridProps.topBtnList.push(aplyBtn);
			}
		},

		/**
     * 그리드의 rowClass 셋
     */
		setRowClassRules() {
			this.gridProps.rowClassRules = {
				// 'selected-row': param => this.selectedRow && (this.selectedRow.rowIndex === param.rowIndex)
			};
		},

		/**
     * 그리드의 frameworkComponents 셋
     */
		setFrameworkComponents() {
			this.gridProps.frameworkComponents = {};
		},

		/**
     * 그리드의 rowSelection 셋
     */
		setRowSelection() {
			// 단수 선택 경우 'single', 복수 선택일 경우 'multiple'
			this.gridProps.rowSelection = this.search.rowSelection;
		},

		/**
     * 셀렉트박스 또는 라디오 선택시
     * @params {Object} params
     */
		setRowSelected(params) {
			if (params.node.isSelected()) {
				if (isEmpty(this.getMvnoList.find(a => a.mvnoSn === params.data.mvnoSn))) {
					// this.$store.dispatch("CmnMvnoPopupChecked/setMvnoList", params.data);
					this.$store.dispatch("CmnMvnoPopupChecked/setMvnosnList", {...params.data, rowIndex: params.rowIndex });

					if(this.limitCheckCnt > 0) {
						if(this.getTrmSnListLength > this.limitCheckCnt) {
							this.alert({
								type: "warning",
								title: this.i18n("label.confirm"),
								message: this.i18n("alt.hdnlQtyChk")
							});
							params.node.selectThisNode();
							return;
						}
					}
				}
			} else {
				this.$store.dispatch("CmnMvnoPopupChecked/removeMvnoList", {...params.data, rowIndex: params.rowIndex });
			}
		},

		/**
     * 입력 폼 초기화
     */
		clear() {
			this.searchList();
		},
		/**
     * 레벨코드에 따른 검색버튼 활성화 처리
     * @params {String} value
     */
		fnChgLvlCd(value) {
			this.search.stckTgtCd = "";
			this.search.stckTgtNm = "";
			let grpTypCd = this.$store.getters["Options/userInfo"].grpTypCd;

			if (grpTypCd == value) {
				this.stckTgtDisabled = false;
				this.stckTgtInputDisabled = true;
				this.stckTgtBtnDisabled = true;
				this.search.stckTgtCd = this.sysOrgViewList.grpCode;
				this.search.stckTgtNm = this.sysOrgViewList.grpName;
			} else if(isEmpty(value)) {
				this.stckTgtDisabled = false;
				this.stckTgtInputDisabled = true;
				this.stckTgtBtnDisabled = true;
			}else {
				this.stckTgtDisabled = false;
				this.stckTgtInputDisabled = false;
				this.stckTgtBtnDisabled = false;
			}
		},
		/**
     * 재고대상 변경시
     * @params {String} value
     */
		fnChangeStckTgtNm(value) {
			// 재고대상 삭제시 코드 초기화
			if(isEmpty(value)) {
				this.search.stckTgtCd = "";
			}
		},
		/**
     * 가맹점명 변경시
     * @params {String} value
     */
		fnChangeMerNm(value) {
			// 가맹점명 삭제시 코드 초기화
			if(isEmpty(value)) {
				this.search.merId = "";
			}
		},
		/**
     * 대리점정보 조회 팝업에서 넘어온 데이터 처리
     * @params {Object} params
     */
		fnCmnAgncyInfPopupDoubleClick(params) {
			this.search.stckTgtCd = params.data.agncyCd;
			 this.search.stckTgtNm = params.data.agncyNm;
		},
		/**
     * 총판정보 조회 팝업에서 넘어온 데이터 처리
     * @params {Object} params
     */
		fnCmnDistrInfPopupDoubleClick(params) {
			this.search.stckTgtCd = params.data.distrCd;
			this.search.stckTgtNm = params.data.distrNm;
		},
		/**
     * 가맹점정보 조회 팝업에서 넘어온 데이터 처리
     * @params {Object} params
     */
		fnCmnMerInfPopupDoubleClick(params) {
			this.search.merId = params.data.merId;
		},
		/**
     * 대리점, 총판 팝업 분기
     * @params {Object} params
     */
		validateShowCmnInfPopup() {
			if (isEmpty(this.search.stckTgtLvlCd)) {
				this.alert({
					type: "warning",
					title: this.i18n("label.confirm"),
					message: this.i18n("label.stckTgtLvlCd") + this.i18n("alt.required")
				});
			} else {
				if (this.search.stckTgtLvlCd == "04") {
					// 대리점 검색 팝업 호출
					this.showCmnAgncyInfPopup();
					// } else if (this.trmSnMngVO.stckTgtLvlCd == "03") {
						} else {
							// 총판 검색 팝업 호출
					this.showCmnDistrInfPopup();
				}
			}
		},
		/**
		 * 대리점정보 조회 팝업 호출
		 */
		showCmnAgncyInfPopup() {
			this.isShowCmnAgncyInfPopup = !this.isShowCmnAgncyInfPopup;
    },
		/**
		 * 대리점정보 조회 팝업 닫기
		 */
    closeCmnAgncyInfPopup() {
			this.isShowCmnAgncyInfPopup = false;
    },
		/**
		 * 총판정보 조회 팝업 호출
		 */
		showCmnDistrInfPopup() {
			this.isShowCmnDistrInfPopup = !this.isShowCmnDistrInfPopup;
    },
		/**
		 * 총판정보 조회 팝업 닫기
		 */
    closeCmnDistrInfPopup() {
			this.isShowCmnDistrInfPopup = false;
    },
		/**
     * 가맹점정보 조회 팝업 호출
     */
		showCmnMerInfPopup() {
			this.isShowCmnMerInfPopup = !this.isShowCmnMerInfPopup;
    },
		/**
     * 가맹점정보 조회 팝업 닫기
     */
    closeCmnMerInfPopup() {
      this.isShowCmnMerInfPopup = false;
		},
		/**
     * 원하는 갯수만큼 선택
     */
		// 9월 23일 이지혜 추가.
		fnLimitCheck(){
			this.$nextTick(() => {
				const currPageCheckRows = this.$refs.dataGrid1.gridOptions.api.getSelectedRows();				
				const diffCnt = this.getLimitCheckCnt - this.getTrmSnList.length;
				
				if(diffCnt === 0 && currPageCheckRows.length === 0) {
					this.alert({
						type: "warning",
						title: this.i18n("label.confirm"),
						message: this.i18n("alt.hdnlQtyChk")
					});
					return;
				}

				// 선택
				if(diffCnt >= this.pageUnit && currPageCheckRows.length != this.pageUnit) {						
						for(let i=0; i<this.pageUnit; i++){ 
								this.$refs.dataGrid1.fnSetSelectedRow(i);
						}
				// 해제
				} else if(currPageCheckRows.length === this.pageUnit) {
					this.$refs.dataGrid1.gridOptions.api.deselectAll();
				// 해제					
				} else if(diffCnt === 0 && this.gridProps.rowData.length > currPageCheckRows.length) {
					this.$refs.dataGrid1.gridOptions.api.deselectAll();
				} else if(diffCnt === 0) {
					this.$refs.dataGrid1.gridOptions.api.deselectAll();
				}	else {
					this.$refs.dataGrid1.gridOptions.api.deselectAll();
					const diffCnt2 = diffCnt + currPageCheckRows.length;

					for(let i=0; i<diffCnt2; i++){ 
							this.$refs.dataGrid1.fnSetSelectedRow(i)								
					}
				} 
			});
		}
	}
};
</script>

<style lang="scss" scoped>
@import "@/scss/_color.scss";
@import "@/scss/_button.scss";
@import "@/scss/_input.scss";
</style>
