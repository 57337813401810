/**
 * 1. 개요 : Dialog 공통 컴포넌트
 * 2. 처리내용 : Dialog 공통 컴포넌트 - 진입점
 * @File Name : index.vue
 * @date : 2020. 02. 20. 09:46:00
 * @author : 이상철
 * @history :
 *-----------------------------------------------------------------------
 * 변경일			          		  작성자		변경내용
 *-----------------------------------------------------------------------
 * 2020. 2. 20. 09:46:00	    이상철	  최초 작성
 *-----------------------------------------------------------------------
 */
<template>
  <div>
    <alert v-for="item in alertList"
           :id="item.id"
           :key="item.id"
           :message="item.options.message"
           :title="item.options.title"
           :buttons="item.options.buttons"
           :on-press="item.options.onPress"
           :type="item.options.type"
    />
  </div>
</template>

<script>
import Alert from './Alert';

export default {
  name: 'Dialog',
  components: {
    Alert,
  },
  computed: {
    /**
     * store에 선언된 alertList 겟
     * @return {Array}
     */
    alertList() {
      return this.$store.state.Alert.alertList;
    },
  },
};
</script>