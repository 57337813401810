/**
 * 1. 개요 : 공통
 * 2. 처리내용 : 공통 - data 영역
 * @File Name : data.js
 * @date : 2020. 02. 20. 09:46:00
 * @author : 이상철
 * @history :
 *-----------------------------------------------------------------------
 * 변경일			          		  작성자		변경내용
 *-----------------------------------------------------------------------
 * 2020. 2. 20. 09:46:00	    이상철	  최초 작성
 *-----------------------------------------------------------------------
 */
// import { mapState, mapGetters } from 'vuex';

export default function() {
  return {
    "alt.notSupportedBrowser": {
      en_US: "Tried to connect to a browser that dose not support it.",
      vi_VN: "Thử kết nối với một trình duyệt không hỗ trợ",
      ko_KR: "지원하지 않는 브라우저로 접속을 시도하였습니다."
    },

    //input
    "label.directInput": {
      en_US: "Direct Input",
      vi_VN: "Nhập trực tiếp",
      ko_KR: "직접 입력"
    },

    // Common
    "label.change": {
      en_US: "change",
      vi_VN: "thay doi",
      ko_KR: "변경"
    },
    "button.init": {
      en_US: "Init",
      vi_VN: "trong do",
      ko_KR: "초기화"
    },
    "button.confirm": {
      en_US: "Confirm",
      vi_VN: "xac nhan",
      ko_KR: "확인"
    },
    "label.confirm": {
      en_US: "Confirm",
      vi_VN: "xac nhan",
      ko_KR: "확인"
    },
    "button.cancel": {
      en_US: "Cancel",
      vi_VN: "huy bo",
      ko_KR: "취소"
    },
    "label.networkError": {
      en_US: "Network error.",
      vi_VN: "lỗi mạng",
      ko_KR: "네트워크 오류"
    },
    "alt.networkError": {
      en_US: "Network failure has occurred.",
      vi_VN: "Lỗi mạng đã xảy ra.",
      ko_KR: "네트워크 장애가 발생하였습니다."
    },
    "label.userAuthError": {
      en_US: "User authentication error",
      vi_VN: "loi xac thuc nguoi dung",
      ko_KR: "사용자 인증 오류"
    },
    "label.serverError": {
      en_US: "Server error",
      vi_VN: "Loi may chu",
      ko_KR: "서버 오류"
    },
    "alt.serverError": {
      en_US: "Server error has occurred.",
      vi_VN: "loi may chu da xay ra",
      ko_KR: "서버 오류가 발생하였습니다."
    },
    "label.sysError": {
      en_US: "System error",
      vi_VN: "loi he thong",
      ko_KR: "시스템 오류"
    },
    "alt.sysError": {
      en_US: "System error has occurred.",
      vi_VN: "loi he thong da xay ra",
      ko_KR: "시스템 오류가 발생하였습니다."
    },

    // Login
    "label.forgotPw": {
      en_US: "Forgot password",
      vi_VN: "Quen mat khau",
      ko_KR: "비밀번호 찾기"
    },
    "label.rememberMe": {
      en_US: "Remember ID",
      vi_VN: "Ghi nho ID",
      ko_KR: "ID 저장"
    },
    "label.viewPw": {
      en_US: "View password",
      vi_VN: "xem mat khau",
      ko_KR: "비밀번호 보임"
    },
    "label.hidePw": {
      en_US: "Hide password",
      vi_VN: "an mat khau",
      ko_KR: "비밀번호 안보임"
    },

    // ForgotPasswordPopup
    "label.userId": {
      en_US: "User ID",
      vi_VN: "ID người dùng",
      ko_KR: "사용자 ID"
    },
    "label.userNm": {
      en_US: "User Name",
      vi_VN: "Tên người dùng",
      ko_KR: "사용자 이름"
    },
    "label.orgPassword": {
      en_US: "Original password",
      vi_VN: "Mật khẩu ban đầu",
      ko_KR: "기존 비밀번호"
    },
    "label.newPassword": {
      en_US: "New password",
      vi_VN: "Mật khẩu mới",
      ko_KR: "새로운 비밀번호"
    },
    "label.confirmPassword": {
      en_US: "New password(Confirm)",
      vi_VN: "Mật khẩu mới (xác nhận)",
      ko_KR: "새로운 비밀번호(재입력)"
    },
    "label.pwdChg": {
      en_US: "Change password",
      vi_VN: "Đổi mật khẩu",
      ko_KR: "비밀번호 변경"
    },
    "label.bizNo": {
      en_US: "Bisiness No",
      vi_VN: "Số ĐKKD",
      ko_KR: "사업자 번호"
    },
    "label.subscribedEmail": {
      en_US: "Subscribed Email",
      vi_VN: "Email đã đăng ký",
      ko_KR: "가입한 이메일"
    },
    "label.mobileNo": {
      en_US: "Mobile No",
      vi_VN: "Số điện thoại di động",
      ko_KR: "휴대폰 번호"
    },
    "label.find": {
      en_US: "Find",
      vi_VN: "Tìm kiếm",
      ko_KR: "찾기"
    },
    "label.issuedNewPw": {
      en_US: "Issuing a new password",
      vi_VN: "Cấp mật khẩu mới",
      ko_KR: "새로운 비밀번호 발급"
    },
    "alt.required": {
      en_US: "is required.",
      vi_VN: "Được yêu cầu",
      ko_KR: "는 필수 입니다."
    },
    "alt.validUserNm": {
      en_US: "Validation error by user name.",
      vi_VN: "Lỗi xác thực do tên người dùng",
      ko_KR: "사용자 이름은 영문, 숫자 조합한 7자 이상 입력해주세요."
    },
    "alt.emptyUserNm": {
      en_US: "User name is required.",
      vi_VN: "Yêu cầu có tên người dùng",
      ko_KR: "사용자 이름은 필수 입력 값 입니다."
    },
    "alt.validUserId": {
      en_US: "Validation error by user ID.",
      vi_VN: "Lỗi xác thực do ID người dùng",
      ko_KR: "사용자 ID는 영문, 숫자를 조합한 7자 이상 입력해주세요."
    },
    "alt.emptyUserId": {
      en_US: "User ID is required.",
      vi_VN: "Yêu cầu có ID người dùng",
      ko_KR: "사용자 ID는 필수 입력 값 입니다."
    },
    "alt.validUserPwd": {
      en_US: "Validation error by user password.",
      vi_VN: "Lỗi xác thực do mật khẩu người dùng",
      ko_KR:
        "사용자 비밀번호는 영문, 숫자, 특수문자를 조합한 7자 이상 입력해주세요."
    },
    "alt.validBizNo": {
      en_US: "Validation error by bisiness No.",
      vi_VN: "Lỗi xác thực do số DKKD",
      ko_KR: "사업자 번호는 10자 입력해주세요."
    },
    "alt.validEmail": {
      en_US: "Validation error by email.",
      vi_VN: "Lỗi xác thực do email",
      ko_KR: "이메일 형식에 맞게 입력해주세요."
    },
    "alt.emptyEmail": {
      en_US: "Email is required.",
      vi_VN: "Yêu cầu có email",
      ko_KR: "이메일은 필수 입력 값 입니다."
    },
    "alt.validPhone": {
      en_US: "Validation error by phone No.",
      vi_VN: "Lỗi xác thực do số điện thoại",
      ko_KR: "전화번호 입력을 부탁드립니다."
    },
    "alt.notExistUser": {
      en_US: "Not a registered user",
      vi_VN: "Không phải người dùng đã đăng ký",
      ko_KR: "가입된 사용자가 아닙니다."
    },
    "alt.alreadyLogin": {
      en_US:
        "You are already logged in.\nWould you like to disconnect and log in?",
      vi_VN: "Bạn đã đăng nhập. Bạn có muốn ngắt kết nối và đăng nhập?",
      ko_KR:
        "이미 로그인 되어 있습니다.\n기존 연결을 해제하고 로그인 하시겠습니까?"
    },

    "alt.tempInitialPw": {
      en_US: "The password has been reset temporarily.",
      vi_VN: "Mật khẩu được đặt tạm thời ",
      ko_KR: "통신설정이 되면, 실제 메일이나 문자가 발송될 예정입니다."
    },
    "alt.questionSendEmailForSetPw": {
      en_US: "Would you like to set a new password via email?",
      vi_VN: "Bạn có muốn đặt mật khẩu mới bằng email không",
      ko_KR: "이메일을 통해 새 비밀번호를 설정 하시겠습니까?"
    },
    "alt.complateSendEmailForSetPw": {
      en_US: "Your mail has been sent.\nPlease check your email.",
      vi_VN: "Đã gửi tới email của bạn. Vui lòng kiểm tra email",
      ko_KR: "메일이 발송되었습니다.\n메일을 확인해 주시기 바랍니다."
    },
    "alt.pleaseChangePassword": {
      en_US: "Please login after modifying your password.",
      vi_VN: "Vui lòng đăng nhập sau khi đổi mật khẩu",
      ko_KR: "비밀번호 수정후 로그인 부탁드립니다."
    },
    "alt.emailFormatCheck": {
      en_US: "Check email format.",
      vi_VN: "Kiểm tra định dạng email",
      ko_KR: "이메일 형식을 확인하세요."
    },

    // ChangePasswordPopup
    "label.viewPassword": {
      en_US: "View password",
      vi_VN: "Xem mật khẩu",
      ko_KR: "비밀번호 보임"
    },
    "label.notViewPassword": {
      en_US: "Not view password",
      vi_VN: "Không xem mật khẩu",
      ko_KR: "비밀번호 보이지 않음"
    },
    "alt.notExistingPassword": {
      en_US: "Existing password do not match.",
      vi_VN: "Mật khẩu hiện tại không khớp",
      ko_KR: "기존 비밀번호가 일치하지 않습니다."
    },
    "alt.validCoincidePassword": {
      en_US: "Password and password confirmation are not the same.",
      vi_VN: "Mật khẩu và mật khẩu xác nhận không giống",
      ko_KR: "비밀번호와 비밀번호 확인이 동일하지 않습니다."
    },
    "alt.questionChangedPw": {
      en_US: "Would you like to change your password?",
      vi_VN: "Bạn có muốn thay đổi mật khẩu không?",
      ko_KR: "비밀번호 변경을 하시겠습니까?"
    },
    "alt.complateChangedPw": {
      en_US: "Password change is complete.",
      vi_VN: "Hoàn thành thay đổi mật khẩu",
      ko_KR: "비밀번호 변경을 완료 하였습니다."
    },
    "alt.passChgDaysLater": {
      en_US: "90 days have passed since the password was changed.",
      vi_VN: "Đã thay đổi mật khẩu từ 90 ngày trước",
      ko_KR: "비밀번호 변경 이후 {0}일이 경과 하였습니다."
    },

    // OtpCertificationPopup
    "label.inputOptNum": {
      en_US: "OTP Input Number",
      vi_VN: "Nhập số OTP",
      ko_KR: "OTP 번호입력"
    },
    "label.optNum": {
      en_US: "OTP Number",
      vi_VN: "Số OTP",
      ko_KR: "OTP 번호"
    },
    "label.viewOptNum": {
      en_US: "View OTP Number",
      vi_VN: "Xem số OTP",
      ko_KR: "OTP 번호 보임"
    },
    "label.notViewOptNum": {
      en_US: "Not view OTP Number",
      vi_VN: "Không xem số OTP",
      ko_KR: "OTP 번호 보이지 않음"
    },
    "label.input": {
      en_US: "Input",
      vi_VN: "Nhập",
      ko_KR: "입력"
    },
    "label.otpCertification": {
      en_US: "OTP Certification",
      vi_VN: "Chứng nhận OTP",
      ko_KR: "OTP 인증"
    },
    "label.approveOpt": {
      en_US: "Approve OTP",
      vi_VN: "Phê duyệt OTP",
      ko_KR: "OTP 승인"
    },
    "alt.validOptNum": {
      en_US: "OTP input is 6 digits.",
      vi_VN: "Nhập OTP 6 ký tự",
      ko_KR: "OTP 입력은 6자리입니다."
    },
    "alt.complateOtpCertification": {
      en_US: "OTP approval is complete.",
      vi_VN: "Hoàn thành phê duyệt OTP",
      ko_KR: "OTP 승인이 완료 되었습니다."
    },
    "alt.failOtpCertification": {
      en_US: "OTP approval is failed.",
      en_US: "OTP approval is failed.",
      ko_KR: "OTP 승인 요청에 실패하였습니다."
    },
    "alt.questionApproveOpt": {
      en_US: "Would you like to request OTP approval?",
      vi_VN: "Bạn có muốn yêu cầu phê duyệt OTP không",
      ko_KR: "OTP 승인을 요청하시겠습니까?"
    },

    // customer support
    "label.support": {
      en_US: "Customer Support",
      vi_VN: "Hỗ trợ khách hàng",
      ko_KR: "고객지원"
    },

    // Privacy Policy
    "label.privPolicy": {
      en_US: "Privacy Policy",
      vi_VN: "Chính sách bảo mật",
      ko_KR: "개인정보정책"
    },
    "label.announcementDate": {
      en_US: "Announcement Date",
      vi_VN: "Ngay thong bao",
      ko_KR: "공고일자"
    },
    "label.effectiveDate": {
      en_US: "Effective Date",
      vi_VN: "Ngay co hieu luc",
      ko_KR: "시행일자"
    },
    "label.all": {
      en_US: "All",
      vi_VN: "tat ca",
      ko_KR: "전체"
    },
    "label.select": {
      en_US: "Selection",
      vi_VN: "Lựa chọn",
      ko_KR: "선택"
    },

    // CmnCheckPasswordPopup
    "label.pwd": {
      en_US: "Password",
      vi_VN: "Mật khẩu",
      ko_KR: "비밀번호"
    },
    "label.pwdConfirm": {
      en_US: "Confirm password",
      vi_VN: "Xác nhận mật khẩu",
      ko_KR: "비밀번호 확인"
    },
    "alt.inputPwd": {
      en_US: "Please enter a password",
      vi_VN: "Vui lòng nhập mật khẩu",
      ko_KR: "비밀번호를 입력해주세요."
    },
    "alt.wrongPwd": {
      en_US: "Wrong password",
      vi_VN: "Sai mật khẩu",
      ko_KR: "비밀번호가 틀립니다."
    },
    "label.error": {
      en_US: "Error",
      vi_VN: "Lỗi",
      ko_KR: "오류"
    },
    "label.require": {
      en_US: "Requirement",
      vi_VN: "Cần thiết",
      ko_KR: "필수"
    },

    // framework error message
    "err.sys.cmn.001": {
      en_US: "Bad Request.",
      vi_VN: "Yêu cầu không hợp lệ",
      ko_KR: "잘못된 요청 입니다."
    },
    "err.sys.cmn.002": {
      en_US: "Forbidden.",
      vi_VN: "Cấmerr.",
      ko_KR: "허용된 요청이 아닙니다."
    },
    "err.sys.cmn.003": {
      en_US: "Not found.",
      vi_VN: "Không tìm thấy",
      ko_KR: "찾을 수 없습니다."
    },
    "err.sys.cmn.004": {
      en_US: "Not Acceptable.",
      vi_VN: "Không chấp nhận.",
      ko_KR: "허용된 형식이 아닙니다."
    },
    "err.sys.cmn.005": {
      en_US: "Server Conflict.",
      vi_VN: "Xung đột máy chủerr.",
      ko_KR: "요청 처리중 서버 상태와 충돌이 발생하였습니다."
    },
    "err.sys.cmn.006": {
      en_US: "Database Error.",
      vi_VN: "Lỗi cơ sở dữ liệu.",
      ko_KR: "데이터베이스 오류 입니다."
    },
    "err.sys.cmn.007": {
      en_US: "Internal Server Error.",
      vi_VN: "Lỗi máy chủ nội bộ",
      ko_KR: "내부 서버 오류가 발생하였습니다."
    },
    "err.sys.auth.001": {
      en_US:
        "There was no request for a certain period of time so automatically logged out.",
      vi_VN:
        "Không có yêu cầu trong một khoảng thời gian nhất định nên tự động đăng xuất.",
      ko_KR: "일정 시간 요청이 없어서 \n자동 로그아웃 되었습니다."
    },
    "err.sys.auth.002": {
      en_US: "Token is not valid.",
      vi_VN: "Token không hợp lệ.",
      ko_KR: "인증토큰이 유효하지 않습니다."
    },
    "err.sys.auth.003": {
      en_US: "Access not allowed.",
      vi_VN: "Không được phép truy cập.",
      ko_KR: "접근 권한이 없습니다."
    },
    "err.sys.auth.004": {
      en_US: "System error. Please contact administrator",
      vi_VN: "Lỗi hệ thống. Vui lòng liên hệ với quản trị viên",
      ko_KR: "시스템 오류, 관리자에게 문의해주세요."
    },
    "err.sys.auth.005": {
      en_US: "Expired tokenerr.",
      vi_VN: "Token quá hạn",
      ko_KR: "인증토큰의 유효기간이 만료 되었습니다."
    },
    "err.sys.auth.006": {
      en_US: "Authentication method not supported",
      vi_VN: "Phương thức xác thực không được hỗ trợ",
      ko_KR: "허용된 HTTP 요청이 아닙니다."
    },
    "err.sys.auth.007": {
      en_US: "Dont use content type",
      vi_VN: "Không sử dụng loại nội dung cho",
      ko_KR: "허용된 Content Type이 아닙니다."
    },
    "err.sys.user.017": {
      en_US:
        "User - {0} failed attempts to connect  please try to connect after {1} minutes",
      vi_VN:
        "Người sử dụng- {0} kết nối thử không thành công xin vui lòng thử kết nối lại sau  {1} phút",
      ko_KR:
        "사용자 - 접속시도 {0} 회 실패  {1} 분 이후에 접속 시도 해주시기 바랍니다."
    },
    "err.sys.user.019": {
      en_US: "User - not authorized user",
      vi_VN: "Người sử dụng - Không phải là người được phép truy cập",
      ko_KR: "사용자 - 접속이 허용된 사용자가 아닙니다."
    },
    "err.sys.user.021": {
      en_US: "User - login attempt failed\nplease check your id and password",
      vi_VN:
        "Người sử dụng- Đăng nhập thất bạiVui lòng kiểm tra mật khẩu và ID",
      ko_KR:
        "사용자 - 로그인 시도를 실패 하였습니다.\n아이디  패스워드를 확인 부탁드립니다."
    },
    "err.sys.user.023": {
      en_US: "User - not an IP that is allowed access",
      vi_VN:
        "Người sử dụng- không phải là IP được phép truy cập",
      ko_KR:
        "사용자 - 접근이 허용된 IP가 아닙니다."
    },

    "label.optGuide": {
      en_US: "OTP Guide",
      vi_VN: "Quy trình xác thực OTP khi đăng nhập hệ thống thông tin",
      ko_KR: "OTP Guide"
    },
    //SMS 인증 관련 메시지 
    "label.certNo": {
      en_US: "Certification Number",
      vi_VN: "Số chứng nhận",
      ko_KR: "인증번호"
    },
    "label.smsCertification": {
      en_US: "SMS authentication",
      vi_VN: "Xác thực SMS",
      ko_KR: "SMS 인증"
    },
    "label.request": {
      en_US: "Request",
      vi_VN: "yêu cầu",
      ko_KR: "요청하기"
    },
    "label.timeExtension": {
      en_US: "Time extension",
      vi_VN: "gia hạn thời gian",
      ko_KR: "시간연장"
    },
    "alt.smsGuideMessage": {
      en_US: "A verification text is sent to the registered mobile phone number.",
      vi_VN: "Một văn bản xác minh được gửi đến số điện thoại di động đã đăng ký.",
      ko_KR: "등록된 휴대폰 번호로 인증문자가 발송됩니다."
    },
    "alt.smsGuideMessage2": {
      en_US: "If you do not receive a confirmation text within 1 minute",
      vi_VN: "Nếu không nhận được văn bản xác minh trong vòng 1 phút",
      ko_KR: "1분 이내에 인증 문자가 수신되지 않는 경우 "
    },
    "alt.smsGuideMessage3": {
      en_US: "click here",
      vi_VN: "Bấm vào đây",
      ko_KR: "'이곳을 클릭하여'"
    },
    "alt.smsGuideMessage4": {
      en_US: "Please try again.",
      vi_VN: "Vui lòng thử lại.",
      ko_KR: "재시도 해주시기 바랍니다."
    },
    "alt.sendCertSms": {
      en_US: "The verification code has been sent.",
      vi_VN: "Mã xác minh đã được gửi.",
      ko_KR: "인증번호가 전송되었습니다."
    },
    "alt.retryRequest": {
      en_US: "Please re-authenticate.",
      vi_VN: "Vui lòng xác thực lại.",
      ko_KR: "다시 인증 요청을 해주세요."
    },
    "alt.complateSmsCertification": {
      en_US: "Your SMS approval request has been completed.",
      vi_VN: "Yêu cầu phê duyệt SMS của bạn đã được hoàn thành.",
      ko_KR: "SMS 승인 요청이 완료되었습니다. "
    },
    "alt.failSmsCertification": {
      en_US: "SMS authorization request failed.",
      vi_VN: "Yêu cầu ủy quyền qua SMS không thành công.",
      ko_KR: "SMS 승인 요청이 실패하였습니다.  "
    },
    "alt.timeOutSms": {
      en_US: "Timed out. Please request again.",
      vi_VN: "Hết giờ. Vui lòng yêu cầu lại.",
      ko_KR: "시간이 초과되었습니다. 다시 요청해주세요. "
    },
    "alt.smsFirstMessage1": {
      en_US: "for additional authentication",
      vi_VN: "để xác thực bổ sung",
      ko_KR: "추가 인증을 위해 본인 "
    },
    "alt.smsFirstMessage2": {
      en_US: "We are verifying your identity",
      vi_VN: "Tôi đang xác nhân.",
      ko_KR: "확인을 하고있습니다.  "
    },
    "label.addAuthTyp": {
      en_US: "Additional authentication types",
      vi_VN: "Các loại xác thực bổ sung",
      ko_KR: "추가인증유형"
    },
    "alt.selectAddAuthTyp": {
      en_US: "Please select an additional authentication type.",
      vi_VN: "Vui lòng chọn một loại xác thực bổ sung.",
      ko_KR: "추가인증유형을 선택해주세요."
    },
    "label.addAuth": {
      en_US: "Additional authentication",
      vi_VN: "xác thực bổ sung",
      ko_KR: "추가인증"
    },
    "alt.smsNoticeMessage1":{
      en_US: "Mobile phone number is the number registered when creating an account.",
      vi_VN: "Số điện thoại di động là số được đăng ký khi tạo tài khoản.",
      ko_KR: "휴대폰번호는 계정 생성시 등록된 번호입니다. "
    },
    "alt.smsNoticeMessage2":{
      en_US: "If you wish to make changes, please contact the administrator.",
      vi_VN: "Nếu bạn muốn thay đổi, vui lòng liên hệ với quản trị viên.",
      ko_KR: "변경을 원하시면 관리자에게 문의해주시기 바랍니다. "
    },
    "alt.smsNoticeMessage3":{
      en_US: "* Please enter the verification code (6 digits) within the input time.",
      vi_VN: "* Vui lòng nhập mã xác minh (6 chữ số) trong thời gian nhập.",
      ko_KR: "* 입력시간 내 인증번호(6자리)를 입력해 주세요."
    },
    "alt.smsNoticeMessage4":{
      en_US: "* If the verification code does not arrive, click Request again.",
      vi_VN: "* Nếu mã xác minh không đến, hãy nhấp lại vào Yêu cầu.",
      ko_KR: "* 인증번호가 도착하지 않을 경우 요청하기를 다시 눌러주세요."
    },
    "alt.requiredCertNum":{
      en_US: "Verification number is required.",
      vi_VN: "Số xác minh là bắt buộc.",
      ko_KR: "인증번호는 필수입니다."
    },
    "alt.maxCertNum":{
      en_US: "Please enter a 6-digit verification code.",
      vi_VN: "Vui lòng nhập mã xác minh gồm 6 chữ số.",
      ko_KR: "인증번호는 6자리를 입력해주세요."
    },
  };
}
