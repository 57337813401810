var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "date-range" }, [
    _c(
      "ul",
      { staticClass: "date-range-list" },
      _vm._l(_vm.list, function(item, index) {
        return _c(
          "li",
          {
            key: _vm.list[index],
            staticClass: "date-range-list-item",
            class: { selected: index === _vm.selectedIndex },
            on: {
              click: function($event) {
                return _vm.select(index)
              }
            }
          },
          [_c("button", [_vm._v(_vm._s(item))])]
        )
      }),
      0
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }