/**
 * 1. 개요 : 공통 컴포넌트
 * 2. 처리내용 : 공통 컴포넌트 - CollapseSearchPane
 * @File Name : index.vue
 * @date : 2020. 02. 20. 09:46:00
 * @author : 이상철
 * @history :
 *-----------------------------------------------------------------------
 * 변경일			          		  작성자		변경내용
 *-----------------------------------------------------------------------
 * 2020. 2. 20. 09:46:00	    이상철	  최초 작성
 *-----------------------------------------------------------------------
 */
<template>
	<div class="open-drawer-wrap">
		<v-collapse-transition>
			<div v-show="isSearchPaneShow" class :class="{ 'open-drawer': isSearchPaneShow }">
				<slot />
			</div>
		</v-collapse-transition>
		<button @click="$emit('fnShowSearchPane')" class="icon-search sp-b">{{ 'label.hiddenSearch' | i18n }}</button>
	</div>
</template>

<script>
import { VCollapseTransition } from "v-collapse-transition";

export default {
	name: "CollapseSearchPane",
	components: {
		VCollapseTransition
	},
	props: {
		isSearchPaneShow: {
			type: Boolean,
			default: false
		},
		fnShowSearchPane: {
			type: Function,
			default: () => null
		}
	}
};
</script>
<style lang="scss" scoped>
@import "@/scss/_color.scss";
@import "@/scss/_icon.scss";

.open-drawer-wrap{
	position:relative;
	margin-bottom:25px;
	border:solid 0 $pale;
	border-width:2px 0 1px;
	.icon-search{
		position:absolute;
		bottom:-15px;
		left:calc(50% - 28px);
		width:58px;
		height:15px;
		font-size:0;
		background-color:transparent;
		&::before {
			width:100%;
			height:100%;
			background-position:-250px 0;
		}
		&::after {
			content: "";
			display: block;
			position: absolute;
    	top: calc(50% - 3px);
    	left: calc(50% - 5px);
			box-sizing: border-box;
			border-style: solid;
			border-color: $cf transparent transparent transparent;
			border-width: 5px;
		}
	}
	
	.box{
		display:block;
		overflow:hidden;
		height:0;
	}
	.open-drawer {
		height:100%;
		transition:height 0.4s;
		&+ .icon-search{
			&::after {
				top:calc(50% - 8px);
				transform:rotate(180deg);
			}
		}
	}
	//detail
	.open-drawer-wrap{
		margin:0;
		border:none;
		border-bottom:solid 1px $ccb;
		.icon-search{
			right:0;
			bottom:0;
			left:auto;
			&::before {
				content:"Detail";
				padding:2px 0 0 9px;
				font-size:12px;
				letter-spacing:-0.5px;
				color:$c6;
				text-align:left;
				background-position:-350px 0;
			}
			&::after {
				left:auto;
				right:9px;
				border-color: $c6 transparent transparent transparent;
			}
		}
		.open-drawer{
			&+ .icon-search{
				&::after {
					top:calc(50% - 7px);
				}
			}
		}
	}
}
</style>