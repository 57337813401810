export default [
  // PC - DCC내역조회
  {
    name: "DccTrLstMng",
    path: "/biz/bep/trLst/DccTrLstMng",
    component: () => import("@/views/biz/bep/trLst/DccTrLstMng"),
    meta: { layout: "admin" }

  },
  // 모바일 - 사업자조회
  {
    name: "MBizMng",
    path: "/m/biz/MBizMng",
    component: () => import("@/views/mobile/biz/bep/MBizMng"),
    meta: { layout: "mobile-admin" }
  },
  // 모바일 - 가맹점단말기사용정보조회
  {
    name: "MMerTrmSnMng",
    path: "/m/biz/MMerTrmSnMng",
    component: () => import("@/views/mobile/biz/bep/MMerTrmSnMng"),
    meta: { layout: "mobile-admin" }
  },
  // 모바일 - 단말기관가맹점정보조회
  {
    name: "MTrmInstMng",
    path: "/m/biz/MTrmInstMng",
    component: () => import("@/views/mobile/biz/bep/MTrmInstMng"),
    meta: { layout: "mobile-admin" }
  },
  // 모바일 - 주/부처리사예외조회
  {
    name: "MTrmIssTrInstMng",
    path: "/m/biz/MTrmIssTrInstMng",
    component: () => import("@/views/mobile/biz/bep/MTrmIssTrInstMng"),
    meta: { layout: "mobile-admin" }
  },
  // 서비스관리 - 가맹그룹관리
  {
    name: "MerGrpCdMng",
    path: "/biz/bep/svc/MerGrpCdMng",
    component: () => import("@/views/biz/bep/svc/MerGrpCdMng"),
    meta: { layout: "admin" }
  },
  // 영업관리 - 영업일지관리
  {
    name: "SalMng",
    path: "/biz/bep/sal/SalMng",
    component: () => import("@/views/biz/bep/sal/SalMng"),
    meta: { layout: "admin" }
  },
  // 모바일 - 영업일지관리
  {
    name: "MSalMng",
    path: "/m/biz/MSalMng",
    component: () => import("@/views/mobile/biz/bep/MSalMng"),
    meta: { layout: "mobile-admin" }
  },
];
