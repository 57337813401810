<template>
  <h1 class="logo">
    <router-link key="expand" class="logo-link" @click="clickLogo" to="/m/dashboard">
        {{ title }}
        <img v-if="logo" :src="logo" alt="logo image" width="84.5" height="21">
    </router-link>
  </h1>
</template>

<script>
export default {
  name: 'MLogo',
  data() {
    return {
      title: '',
      logo: require('@/assets/img/m-alliex-header-logo.png')
    }
  },
  methods: {
    clickLogo() {
      this.$emit('showTitlebar', false);
    }
  }
}
</script>

<style lang="scss" scoped>
.logo{
  display: inline-block;
  margin:0 0 0 7px;
  font-size:0;
  vertical-align: middle;
}
</style>
