/**
 * Created by 이지혜 on 2020/03/18
 */
import Vue from "vue";
import { omit, isEmpty, getByte } from "@/utils";

export default Vue.extend({
  data(){
    return {
      fileName: "",
      fileList: []
    }
  },
  mounted() {
    this.fileList = this.params.value;
  },
  methods: {
    getExtensionName(file) {
      if( !isEmpty(file.orginl_file_nm) ) {
        return require('@/assets/img/admin/extension/'+ file.orginl_file_nm.slice((file.orginl_file_nm.lastIndexOf('.') - 1 >>> 0) + 2) +'.png');
      }else{
        return require('@/assets/img/admin/extension/non.png');
      }
    },
    downloadFile(file) {
      this.$httpPost('/api/com/downloadFile.do', {
        searchAtchFileFkId: file.atch_file_fk_id,
        searchFileSeqNo: file.file_seq_no,
      }, {
        responseType: 'blob'
      }).then((res) => {
        if (navigator.msSaveBlob) {
          const blob = new Blob([res.data], { type: 'application/octet-stream;charset=utf-8;' });
          navigator.msSaveBlob(blob, `${file.orginl_file_nm}`);
        } else if (navigator.appName === 'Microsoft Internet Explorer') {
          const frame = document.createElement('iframe');
          document.body.appendChild(frame);

          frame.contentWindow.document.open('application/octet-stream;charset=utf-8;', 'replace');
          frame.contentWindow.document.write(`sep=,\r\n${res.data}`);
          frame.contentWindow.document.close();
          frame.contentWindow.focus();
          frame.contentWindow.document.execCommand('SaveAs', false, `${file.orginl_file_nm}`);

          document.body.removeChild(frame);
        } else {
          const blob = new Blob([res.data], { type: 'application/octet-stream;charset=utf-8;' });
          const link = document.createElement('a');
          // feature detection
          if (link.download !== undefined) {
            // Browsers that support HTML5 download attribute
            const url = URL.createObjectURL(blob);
            link.setAttribute('href', url);
            link.setAttribute('download', `${file.orginl_file_nm}`);
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          }
        }
      }).catch(this.commonErrorHandler)
    },
  },
  template: `
  <div class="grid-file-list">
    <span v-if="fileList.length > 0" v-for="file in fileList" :key="file.file_seq_no" @click="downloadFile(file)">
      <a class="grid-more" href="javascript:;" :title="file.orginl_file_nm">
        <img :src="getExtensionName(file)" alt="file.orginl_file_nm"/>
      </a>
    </span>
  </div>
  `
})