/** * 1. 개요 : 개인관리 * 2. 처리내용 : 개인관리 * @File Name :
MyPagePopupPsn.vue * @date : 2020. 07. 30. 09:46:00 * @author : 김영노 *
@history :
*----------------------------------------------------------------------- *
변경일 작성자 변경내용
*----------------------------------------------------------------------- * 2020.
7. 30. 09:46:00 김영노 최초 작성
*----------------------------------------------------------------------- */
<template>
	<div class="tab-content">
		<div class="cont">
			<!-- <div class="grid-top-box">
				<div class="CSubTit">
					<h3 class="title">{{ "label.PsnSubTbl1" | i18n }}</h3>
				</div>
			</div> -->
			<!-- SYS_UER_INFO 리스트 조회 -->
			<!-- <div class="mt5">
				<data-grid
					ref="dataGrid1"
					:totalYn="false"
					:pagiYn="false"
					:subTitle="subTitle"
					:totalItems="gridProps.totalItems"
					:columnDefs="gridProps.columnDefs"
					:rowData="gridProps.rowData"
					:initPage="gridProps.initPage"
					:perPage="pageSize"
					:gridHeight="gridProps.gridHeight"
					:rowClassRules="gridProps.rowClassRules"
					:frameworkComponents="gridProps.frameworkComponents"
					:topBtnList="gridProps.topBtnList"
					:suppressRowClickSelection="gridProps.suppressRowClickSelection"
				/>
			</div> -->

			<div class="grid-top-box">
				<div class="CSubTit">
					<h3 class="title">{{ "label.PsnSubTbl2" | i18n }}</h3>
				</div>
			</div>

			<data-table tblId="tblInfo01">
				<template slot="colgroup">
					<col style="width:150px;" />
					<col style="width:auto;" />
					<col style="width:150px;" />
					<col style="width:auto;" />
				</template>
				<template slot="tbody">
					<tr>
						<th scope="row">
							<label for="psnMngVOPsnId" tabindex="1">
								{{
								"label.psnId" | i18n
								}}
							</label>
						</th>
						<td colspan="3">
							<input-text
								id="psnMngVOPsnId"
								ref="psnMngVOPsnId"
								v-model="psnMngVO.regPsnId"
								:isNumberType="true"
								:placeholder="i18n('label.psnId')"
								:disabled="true"
							/>
						</td>
					</tr>
					<tr>
						<th scope="row">
							<label class="required" for="psnMngVOPsnNm">
								<span class="star">{{ "label.require" | i18n }}{{ "label.input" | i18n }}</span>
								{{ "label.Nm" | i18n }}
							</label>
						</th>
						<td>
							<input-text
								id="psnMngVOPsnNm"
								ref="psnMngVOPsnNm"
								v-model="psnMngVO.psnNm"
								type="text"
								:maxlength="40"
								:placeholder="i18n('label.Nm')"
							/>
						</td>
						<th scope="row">
							<label class="required" for="psnMngVOPsnEnm">
								<span class="star">{{ "label.require" | i18n }}{{ "label.input" | i18n }}</span>
								{{ "label.enm" | i18n }}
							</label>
						</th>
						<td>
							<input-text
								id="psnMngVOPsnEnm"
								v-model="psnMngVO.psnEnm"
								type="text"
								:maxlength="80"
								:placeholder="i18n('label.enm')"
							/>
						</td>
					</tr>
					<tr>
						<!-- 직책 -->
						<th scope="row">
							<label for="psnMngVOJobPosCd">
								{{ "label.jobPosition" | i18n }}
							</label>
						</th>
						<td>
							<code-selector
								id="psnMngVOJobPosCd"
								v-model="psnMngVO.jobPosCd"
								:cdList="cdList.jobPosCdList"
								:isAll="false"
								:isSel="true"
							/>
						</td>

						<!-- 개인 상태 -->
						 <th scope="row">
              <label class="required" for="psnMngVOPsnStatCd">
                <span class="star"
                  >{{ "label.require" | i18n }}{{ "label.input" | i18n }}</span
                >
                {{ "label.stat" | i18n }}
              </label>
            </th>
            <td>
              <code-selector
                id="psnMngVOPsnStatCd"
                v-model="psnMngVO.psnStatCd"
                key="refreshInx1"
                :cdList="cdList.psnStatCdList"
                :isAll="false"
                :isSel="true"
                :disabled="true"
              />
						</td>
					</tr>
					<tr>
						<th scope="row">
							<label for="psnMngVOPidKndCd">
							{{ "label.pidKnd" | i18n }}
							</label>
							</th>
						<td>
							<code-radio-box
								id="psnMngVOPidKndCd"
								ref="psnMngVOPidKndCd"
								v-for="item in cdList.pidKndCdList"
								v-model="psnMngVO.pidKndCd"
								:key="item.codeId"
								:value="item.codeId"
								:item="item.codeName"
							/>
						</td>
						<th scope="row">{{ "label.pidNo" | i18n }}</th>
						<td>
							<input-text
								id="psnMngVOPidNo"
								ref="psnMngVOPidNo"
								v-model="psnMngVO.pidNo"
								type="text"
								:maxlength="300"
								:placeholder="i18n('label.pidNo')"
							/>
						</td>
					</tr>
					<tr>
						<th scope="row">
							<!--label class="required" for="psnMngVOTelNo">
							<span class="star">{{ 'label.require'|i18n }}{{ 'label.input'|i18n }}</span-->
							{{ "label.telNo" | i18n }}
							<!--/label-->
						</th>
						<td>
							<input-phone
								id="psnMngVOTelNo"
								ref="psnMngVOTelNo"
								type="text"
								:value="psnMngVO.telNo"
								@fnChange="value => (psnMngVO.telNo = value)"
							/>
						</td>

						<!-- 휴대폰번호 -->
						<th scope="row">
							<label class="required" for="psnMngVOTelNo">
							<span class="star">{{ 'label.require'|i18n }}{{ 'label.input'|i18n }}</span>
							{{ "label.mobileNo" | i18n }}
							</label>
						</th>
						<td>
							<input-phone
								id="psnMngVOMobileNo"
								ref="psnMngVOMobileNo"
								:value="psnMngVO.mobileNo"
								type="text"
								@fnChange="value => (psnMngVO.mobileNo = value)"
							/>
						</td>
					</tr>

					<tr>
						<!-- 팩스번호 -->
						<th scope="row">
							<label for="psnMngVOFaxNo">{{ "label.faxNo" | i18n }}</label>
						</th>
						<td colspan="3">
							<input-phone
								id="psnMngVOFaxNo"
								ref="psnMngVOFaxNo"
								:value="psnMngVO.faxNo"
								type="text"
								@fnChange="value => (psnMngVO.faxNo = value)"
							/>
						</td>
					</tr>
					<tr>
						<!-- 개인식별번호발급일자 -->
						<th scope="row">
							<label for="psnMngVOPidNoIssDt">
								{{
								"label.pidNoIssDt" | i18n
								}}
							</label>
						</th>
						<td>
							<input-date
								id="psnMngVOPidNoIssDt"
								ref="psnMngVOPidNoIssDt"
								v-model="psnMngVO.pidNoIssDt"
								:placeholder="i18n('label.pidNoIssDt')"
							/>
						</td>
						<th scope="row">
							<label for="psnMngVOPidNoIssPlc">
								{{
								"label.pidNoIssPlc" | i18n
								}}
							</label>
						</th>
						<td>
							<input-text
								id="psnMngVOPidNoIssPlc"
								ref="psnMngVOPidNoIssPlc"
								v-model="psnMngVO.pidNoIssPlc"
								type="text"
								:maxlength="25"
								:placeholder="i18n('label.pidNoIssPlc')"
							/>
						</td>
					</tr>
					<tr>
						<th scope="row">
							<label class="required" for="psnMngVOGnd">
								<span class="star">{{ 'label.require'|i18n }}{{ 'label.input'|i18n }}</span>
								{{ "label.gnd" | i18n }}</label>
						</th>
						<td>
							<code-radio-box
								name="psnMngVOGnd"
								ref="psnMngVOGnd"
								v-for="item in cdList.gndList"
								v-model="psnMngVO.gnd"
								:key="item.codeId"
								:value="item.codeId"
								:item="item.codeName"
							/>
						</td>
						<!-- 생년월일 -->
						<th scope="row">
							<label for="psnMngVODob">{{ "label.dob" | i18n }}</label>
						</th>
						<td>
							<input-date
								id="psnMngVODob"
								ref="psnMngVODob"
								v-model="psnMngVO.dob"
								:placeholder="i18n('label.dob')"
							/>
						</td>
					</tr>
					<tr>
						<th scope="row">
							<label for="psnMngVOEnthCd">{{ "label.enth" | i18n }}</label>
						</th>
						<td>
							<code-selector
								id="psnMngVOEnthCd"
								v-model="psnMngVO.enthCd"
								:cdList="cdList.enthCdList"
								:isAll="false"
								:isSel="true"
							/>
						</td>
						<th scope="row">
							<label for="psnMngVONatCd">{{ "label.nat" | i18n }}</label>
						</th>
						<td>
							<code-selector
								id="psnMngVONatCd"
								v-model="psnMngVO.natCd"
								:cdList="cdList.natCdList"
								:isAll="false"
								:isSel="true"
							/>
						</td>
					</tr>

					<tr>
						<th scope="row">
							<label class="required" for="psnMngVOEmailAddr">
								<span class="star">{{ "label.require" | i18n }}{{ "label.input" | i18n }}</span>
								{{"label.emailAddr" | i18n}}
							</label>
						</th>
						<td colspan="3">
							<input-email
								:key="refreshInx1"
								id="psnMngVOEmailAddr"
								ref="psnMngVOEmailAddr"
								type="text"
								:prefixMaxlength="30"
								:suffixMaxlength="30"
								:value="psnMngVO.emailAddr"
								@fnChange="value => (psnMngVO.emailAddr = value)"
							/>
						</td>
					</tr>
					<tr>
						<th scope="row">
							<label for="psnMngVOCityNm">{{ "label.city" | i18n }}</label>
						</th>
						<td colspan="3">
							<input-text
								id="psnMngVOCityNm"
								ref="psnMngVOCityNm"
								v-model="psnMngVO.ctAddrNm"
								type="text"
								:disabled="true"
							/>
						</td>
					</tr>
					<tr>
						<th scope="row">
							<label for="psnMngVODistNm">{{ "label.dist" | i18n }}</label>
						</th>
						<td colspan="3">
							<input-text
								id="psnMngVODistNm"
								ref="psnMngVODistNm"
								v-model="psnMngVO.dsAddrNm"
								type="text"
								:disabled="true"
							/>
						</td>
					</tr>
					<tr>
						<th scope="row">
							<label for="psnMngVOWardNm">{{ "label.ward" | i18n }}</label>
						</th>
						<td colspan="3">
							<!-- 주소검색 팝업 -->
							<search-input-text
								id="psnMngVOWardNm"
								ref="psnMngVOWardNm"
								v-model="psnMngVO.wdAddrNm"
								type="text"
								:placeholder="i18n('label.ward')"
								:disabled="true"
								:inputDisabled="true"
								@fnSearch="showCmnAddrPopup"
							/>
						</td>
					</tr>
					<tr>
						<th scope="row">
							<label for="psnMngVOBasAddr">{{ "label.addr" | i18n }}</label>
						</th>
						<td colspan="3">
							<input-text
								id="psnMngVOBasAddr"
								ref="psnMngVOBasAddr"
								class="w100p"
								v-model="psnMngVO.basAddr"
								type="text"
								:maxlength="150"
								:placeholder="i18n('label.addr')"
							/>
						</td>
					</tr>
					<!-- <tr>
						<th scope="row">
							<label for="psnMngVOPno">{{ "label.pno" | i18n }}</label>
						</th>
						<td colspan="3">
							<input-text
								id="psnMngVOPno"
								ref="psnMngVOPno"
								v-model="psnMngVO.pno"
								type="text"
								:maxlength="6"
								:placeholder="i18n('label.pno')"
							/>
						</td>
					</tr> -->
					<tr>
						<th scope="row">
							<label for="psnMngVOProfileImg">
								{{
								"label.profileImg" | i18n
								}}
							</label>
						</th>
						<td id="psnMngVOProfileImg" colspan="3">
							<croppa
								ref="myCroppa"
								:key="refreshInx1"
								:width="53"
								:height="53"
								:remove-button-color="'#797979'"
								:remove-button-size="20"
								:initial-image="imgUrl"
								:placeholder="''"
								@file-choose="generateImage"
								@file-size-exceed="generateImage"
								@new-image-drawn="generateImage"
								@move="generateImage"
								@zoom="generateImage"
								@image-remove="removeImage"
							/>
							<!--id="profileImg" -->
							<!--button type="button" @click="generateImage" class="btn-generate-img">{{ 'label.reg' | i18n }}</button-->
							<!--img :src="imgUrl"-->
						</td>
					</tr>
					<tr>
						<th scope="row">
							<label for="psnMngVORmk">{{ "label.rmk" | i18n }}</label>
						</th>
						<td colspan="3">
							<textarea
								id="psnMngVORmk"
								ref="psnMngVORmk"
								v-model="psnMngVO.rmk"
								type="text"
								:maxlength="100"
								:placeholder="i18n('label.rmk')"
							/>
						</td>
					</tr>

					<!-- 첨부파일 -->
					<tr>
						<th scope="row">
							<label for="psnMngVOAtchFileFkId">
								{{
								"label.attchFile" | i18n
								}}
							</label>
						</th>
						<td colspan="3">
							<div class="file-wrap">
								<button type="button" @click.stop="fileAdd" class="file-box">
									<input
										id="file"
										ref="file"
										type="file"
										multiple
										:accept="fileUpload.acceptExtention"
										@change.stop="uploadFile"
										title="i18n('label.attchFile')"
									/>
									{{ "label.file" | i18n }}{{ "label.search" | i18n }}
								</button>
								<div class="file-list">
									<span
										class="file-selected"
										v-for="file in fileUpload.fileList"
										:key="file.fileSeqNo"
										@click="downloadFile(file)"
									>
										<a href="javascript:;" :title="file.orginlFileNm">
											{{
											file.orginlFileNm
											}}
										</a>
										<button
											type="button"
											@click.stop="deleteFile(file)"
										>{{ "label.file" | i18n }}{{ "label.del" | i18n }}</button>
									</span>
								</div>
							</div>
						</td>
					</tr>

					<!-- 등록정보/수정정보 출력 -->
					<tr v-show="false">
						<!-- 최초등록자 -->
						<th scope="row">{{ "label.addedBy" | i18n }}</th>
						<td>{{ psnMngVO.addedBy }} ({{ psnMngVO.userNm }})</td>
						<!-- 최종수정자 -->
						<th scope="row">{{ "label.modifyBy" | i18n }}</th>
						<td>{{ psnMngVO.modifyBy }} ({{ psnMngVO.muserNm }})</td>
					</tr>
					<tr v-show="false">
						<!-- 최초등록일시 -->
						<th scope="row">{{ "label.addedDate" | i18n }}</th>
						<td>{{ i18nDtTmFormat(psnMngVO.addedDate) }}</td>
						<!-- 최종수정일시 -->
						<th scope="row">{{ "label.modifyDate" | i18n }}</th>
						<td>{{ i18nDtTmFormat(psnMngVO.modifyDate) }}</td>
					</tr>
					<cmn-addr-popup
						v-if="isShowCmnAddrPopup"
						@close="closeCmnAddrPopup"
						@fnRowDoubleClick="fnCmnAddrPopupDoubleClick"
						:paramData="psnMngVO"
					/>
				</template>
			</data-table>
		</div>
		<div class="btn-btm">
			<primary-button @click="validate">{{ buttonTitle }}</primary-button>
			<default-button @click="$emit('close')">{{"button.cancel" | i18n}}</default-button>
			<default-button
				v-if="this.userInfo.grpMenuAuthorCd != 'SYS'"
				class="del-btn"
				@click="resignedValidate"
			>{{ 'label.resigned' | i18n }}</default-button>
		</div>
	</div>
</template>

<script>
import ScrollPane from "@/layout/admin/components/TagsView/ScrollPane";
import DataGrid from "@/components/DataGrid";
import DataTable from "@/components/DataTable";
import Popup from "@/components/Popup";
import CommonPopupHelper from "@/views/biz/bep/common/CommonPopupHelper";
import { EventBus } from "./EventBus";
import { lookupValue } from "@/utils/dataGrid";
import { inputPhoneValidate } from "@/utils/validate";
import { GlobalValidationSet } from "./mixins/Helper";
import {
	UserMngViewModel,
	PsnMngSaveModel,
	CodeFetchHelper,
	Helper,
	AnimationHelper,
	FileUploadPsn
} from "./mixins";
import { isEmpty, getByteLengthOfUtf8String } from "@/utils";
import "vue-croppa/dist/vue-croppa.css";

export default {
	name: "MyPagePopupPsn",
	components: {
		ScrollPane,
		PsnMngSaveModel,
		UserMngViewModel,
		FileUploadPsn,
		DataGrid,
		DataTable
	},
	mixins: [
		UserMngViewModel,
		PsnMngSaveModel,
		CodeFetchHelper,
		CommonPopupHelper,
		Helper,
		AnimationHelper,
		FileUploadPsn
	],
	created() {},
	computed: {
		// getAuthor() {
		// 	return this.$store.getters["Options/userInfo"].menuAuthDetailList.find(
		// 		a => a.menuNm === this.$route.name
		// 	);
		// },
		userInfo() {
			return this.$store.getters["Options/userInfo"];
		},
		title() {
			return this.i18n("label.psn") + this.i18n("label.info");
		},
		subTitle() {
			this.i18n("label.merMng");
		},
		buttonTitle() {
			return this.i18n("label.update");
		}
	},
	data() {
		return {
			refreshInx1: 0, // 화면 refresh을 위한 인덱스값
			currPage: 1,
			pageUnit: 100,
			pageSize: 100,
			resultList: [],
			gridProps: {
				totalItems: 0,
				columnDefs: [],
				rowData: [],
				// 일반적인 row 선택 or 더블클릭시
				selectedRows: [],
				initPage: true,
				gridHeight: 150,
				rowClassRules: null,
				frameworkComponents: null,
				rowSelection: null,
				// 로우 선택시 선택이 되도록 하려면 false, 체크박스 or 라디오 선택시 선택이 되오도록 하려면 true
				suppressRowClickSelection: false,
				topBtnList: []
			},
			// 일반적인 row 선택 or 더블클릭시
			selectedRow: null,
			// 체크박스 또는 라디오 row 선택시
			selectedCheckOrRadioRows: [],

			psnMngVO: {
				status: "I",
				regPsnId: "",
				psnNm: "",
				psnEnm: "",
				psnStatCd: "01",
				regGrpTypCd: "",
				regGrpCd: "",
				grpCdNm: "",
				telNo: "",
				mobileNo: "",
				faxNo: "",
				emailAddr: "",
				// pno: "",
				ctAddrNm: "",
				dsAddrNm: "",
				wdAddrNm: "",
				addrCd:"",
				basAddr: "",
				pidKndCd: "",
				pidNo: "",
				pidNoIssDt: "",
				pidNoIssPlc: "",
				gnd: "",
				jobPosCd: "",
				dob: "",
				enthCd: "",
				natCd: "",
				atchFileFkId: "",
				rmk: "",
				addedBy: "",
				addedDate: "",
				modifyBy: "",
				modifyDate: "",
				regUserId: "",
				// searchTypCd: "",	#2454 Remove undeclared fields to address API Mass Assignment Vulnerability - SeungHyeon Han 2023.02.28
				deptCd: ""
			},
			orgPsnMngVO: {
				status: "",
				regPsnId: "",
				psnNm: "",
				psnEnm: "",
				psnStatCd: "",
				regGrpTypCd: "",
				regGrpCd: "",
				grpCdNm: "",
				telNo: "",
				mobileNo: "",
				faxNo: "",
				emailAddr: "",
				// pno: "",
				ctAddrNm: "",
				dsAddrNm: "",
				wdAddrNm: "",
				addrCd :"",
				basAddr: "",
				pidKndCd: "",
				pidNo: "",
				pidNoIssDt: "",
				pidNoIssPlc: "",
				gnd: "",
				jobPosCd: "",
				dob: "",
				enthCd: "",
				natCd: "",
				atchFileFkId: "",
				rmk: "",
				addedBy: "",
				addedDate: "",
				modifyBy: "",
				modifyDate: "",
				regUserId: "",
				searchTypCd: "",
				deptCd: ""
			},
			// user ID 생성시 사용될 VO
			userMngVO: {
				status: "U",
				userId: "",
				userNm: "",
				psnId: "",			
				grpTypCd: "",
				grpCd: "",
				regUserId: "",
			},
			// Croppa
			myCroppa: {},
			imgUrl: ""
		};
	},
	beforeMount() {
		// 그리드 초기화
		// this.setColumnDefs();

		// 그리드의 rowSelection 셋
		// this.setRowSelection();
	},
	mounted() {
		this.clear();
		this.searchOne();
	},
	methods: {
		// 단건 조회
		searchOne() {
			this.$httpPost(
				"/api/bep/getPsnMngDetail.do",
				{
					searchPsnId: this.userInfo.psnId
				},
				{ showLoading: true }
			)
				.then(response => {
					this.orgPsnMngVO = { ...response.data.resultList[0], status: "U" };
					this.psnMngVO = { ...response.data.resultList[0], status: "U" };
					this.imgUrl = this.psnMngVO.photoDat; // 이미지 가져오기
					this.getFileList(this.psnMngVO.atchFileFkId);

					// sys_user_info 조회
					this.searchList();
					this.refreshInx1 = this.refreshInx1 + 1;
				})
				.catch(this.commonErrorHandler);
		},

		// sys_user_info
		searchList() {
			this.gridInit();
			// sys_user_info 조회
			this.$httpPost(
				"/api/bep/selectUserList.do",
				{ searchPsnId: this.userInfo.psnId },
				{ showLoading: true }
			)
				.then(response => {
					// result 셋
					this.resultList = response.data.resultList;
					// 데이터 총 갯수 셋
					this.gridProps.totalItems = response.data.totalCount;

					// 그리드에 조회해온 데이터 셋
					if (this.resultList) {
						this.gridProps.rowData = this.resultList.map(a => ({
							...a,
							status: "R"
						}));
					}
				})
				.catch(this.commonErrorHandler);
		},

		// 단건 입력(생성/변경)
		save() {
			// axr_psn 변경정보
			// const saveData = this.filteredSaveDataHelper(
			// 	this.orgPsnMngVO,
			// 	this.psnMngVO,
			// 	this.psnMngSaveModel()
			// );

			// 전화번호 인풋을 넣었으면 필수로 넣어주세요!
			this.psnMngVO.telNo && this.psnMngVO.telNo.includes(" ") ? this.psnMngVO.telNo = this.psnMngVO.telNo.replaceAll(" ", "") : null;
			this.psnMngVO.mobileNo && this.psnMngVO.mobileNo.includes(" ") ? this.psnMngVO.mobileNo = this.psnMngVO.mobileNo.replaceAll(" ", "") : null;
			this.psnMngVO.faxNo && this.psnMngVO.faxNo.includes(" ") ? this.psnMngVO.faxNo = this.psnMngVO.faxNo.replaceAll(" ", "") : null;

			const saveData = this.psnMngVO;
			// axr_psn 수정한 데이터가 없는 경우 리턴
			if (isEmpty(saveData)) {
				this.alert({
					type: "warning",
					title: this.i18n("label.confirm"),
					message: this.i18n("alt.noRevise")
				});
				return;
			}
			// axr_psn 등록/수정
			const psnMngVOList = [saveData];
			// console.log("=== axr_psn::" + JSON.stringify(saveData));

			this.$httpPost(
				"/api/bep/savePsnMngList.do",
				{ psnMngVOList },
				{ showLoading: true }
			)
				.then(res => {
					this.saveSysUserInfo();
					this.alert({
						type: "warning",
						title: this.i18n("label.confirm"),
						message:
							this.psnMngVO.psnStatCd === "02"
								? this.i18n("alt.withdrawalSuccess")
								: this.i18n("alt.success")
					});
				})
				.then(() => {
					if (this.psnMngVO.psnStatCd === "02") {
						this.logoutWithServer();
					}
				})
				.then(() => this.$emit("refreshProfile"))
				.then(() => this.$emit("close"))
				.catch(this.commonErrorHandler);
		},
		// sys_user_info 등록/수정
		saveSysUserInfo() {
			// axr_psn 최초 등록 하면서 사용자ID 등록시
			if (this.psnMngVO.status === "U" ) {
				this.userMngVO.regUserId = this.psnMngVO.regUserId;
				this.userMngVO.userNm = this.psnMngVO.psnNm;
				this.userMngVO.psnId = this.psnMngVO.regPsnId;
				// grpTypCd를 식별해야 할 수 있으므로 추가
				this.userMngVO.grpTypCd = this.psnMngVO.regGrpTypCd;
				this.userMngVO.grpCd = this.psnMngVO.regGrpCd;
				this.userMngVO.emailAddr = this.psnMngVO.emailAddr;
			} 
			const usrSaveData = this.userMngVO;

			if (isEmpty(usrSaveData)) {
				this.alert({
					type: "warning",
					title: this.i18n("label.confirm"),
					message: this.i18n("alt.noRevise")
				});
				return;
			}
			// axr_psn 등록/수정
			const userMngVOList = [usrSaveData];

			this.$httpPost(
				"/api/bep/saveUserInfo.do",
				{ userMngVOList },
				{ showLoading: true }
			)
			.catch(this.commonErrorHandler);
		},

		// 주소조회 팝업에서 넘어온 데이터 처리
		fnCmnAddrPopupDoubleClick(params) {

			this.psnMngVO.wdAddrNm = params.data.wdAddrNm;
			this.psnMngVO.dsAddrNm = params.data.dsAddrNm;
			this.psnMngVO.ctAddrNm = params.data.ctAddrNm;
			this.psnMngVO.addrCd = params.data.addrCd;
		},

		// croppa 이미지 생성
		generateImage() {
			this.psnMngVO.photoDat = this.$refs.myCroppa.generateDataUrl();
			// console.log(this.imgUrl); // base64
			// console.log(typeof(this.imgUrl)); // String
			// 서버로 보내실 때 this.imgUrl을 그대로 보냈다가 가져오시면 됩니다.
			// 가져오셔서 붙일 때는 <img :src="imgUrl"> 이렇게 쓰시면 됩니다.
		},

		// croppa 이미지 삭제
		removeImage() {
			// console.log("removeImage");
			this.psnMngVO.photoDat = "";
			this.imgUrl = "";
		},

		// 그리드의 columnDefs 셋
		setColumnDefs(params = {}) {
			this.$nextTick(() => {
				let gridWidth = this.$refs.dataGrid1.$el.clientWidth - 20;
				let totalWidth = 0;

				this.gridProps.columnDefs = this.userMngViewModel(params).map(a => {
					totalWidth += a.width === 0 ? 200 : a.width;

					return {
						headerName: a.headerName,
						field: a.field,
						width:
							a.field === "restCell" && totalWidth > 0
								? gridWidth - totalWidth + 200
								: a.width,
						editable: a.editable,
						pinned: a.pinned,
						cellStyle: a.cellStyle,
						cellClassRules: a.cellClassRules,
						valueFormatter: a.valueFormatter,
						cellRenderer: a.cellRenderer,
						checkboxSelection: a.checkboxSelection,
						suppressMenu: a.suppressMenu,
						sortable: a.sortable,
						hide: a.hide
					};
				});
			});
		},

		// 그리드 초기화
		gridInit() {
			this.resultList = [];
			this.gridProps.rowData = [];
			this.gridProps.selectedRows = [];
		},

		// 그리드의 rowSelection 셋
		setRowSelection() {
			// 단수 선택 경우 'single', 복수 선택일 경우 'multiple'
			this.gridProps.rowSelection = "single";
		},

		validate() {
			let validObj = [];
			validObj.push({
				ref: this.$refs.psnMngVOPsnNm,
				isValidate: !isEmpty(this.psnMngVO.psnNm),
				message: this.i18n("label.Nm") + this.i18n("alt.required")
			});
			validObj.push({
					ref: this.$refs.psnMngVOPsnNm,
					isValidate: getByteLengthOfUtf8String(this.psnMngVO.psnNm) <= 80,
					message: this.i18n("label.psnNm") + this.i18n("alt.max")+"\\n"+80+this.i18n("alt.upToBytes")
				});
			validObj.push({
				ref: this.$refs.psnMngVOPsnEnm,
				isValidate: !isEmpty(this.psnMngVO.psnEnm),
				message: this.i18n("label.enm") + this.i18n("alt.required")
			});
			validObj.push({
					ref: this.$refs.psnMngVOPsnEnm,
					isValidate: getByteLengthOfUtf8String(this.psnMngVO.psnEnm) <= 80,
					message: this.i18n("label.psnEnm") + this.i18n("alt.max")+"\\n"+80+this.i18n("alt.upToBytes")
				});
			// validObj.push({
			// 	ref: this.$refs.psnMngVOJobPosCd,
			// 	isValidate: !isEmpty(this.psnMngVO.jobPosCd),
			// 	message: this.i18n("label.jobPosCd") + this.i18n("alt.required")
			// });
			validObj.push({
				ref: this.$refs.psnMngVOPsnStatCd,
				isValidate: !isEmpty(this.psnMngVO.psnStatCd),
				message: this.i18n("label.stat") + this.i18n("alt.required")
			});
			validObj.push({
				ref: this.$refs.psnMngVOMobileNo,
				isValidate: !isEmpty(this.psnMngVO.mobileNo),
				message: this.i18n("label.mobileNo") + this.i18n("alt.required")
			});
			// validObj.push({
			// 	ref: this.$refs.psnMngVOPidKndCd,
			// 	isValidate: !isEmpty(this.psnMngVO.pidKndCd),
			// 	message: this.i18n("label.pidKndCd") + this.i18n("alt.required")
			// });
			validObj.push({
				ref: this.$refs.psnMngVOGnd[0],
				isValidate: !isEmpty(this.psnMngVO.gnd),
				message: this.i18n("label.gnd") + this.i18n("alt.required")
			});

			this.psnMngVO.telNo && validObj.push({
				// 전화번호 포맷 체크(input-phone을 썼다면 필수로 넣어주세요.)
				ref: this.$refs.psnMngVOTelNo,
				isValidate: inputPhoneValidate(this.psnMngVO.telNo),
				message: this.i18n("alt.phoneFormatCheck")
			});
			this.psnMngVO.mobileNo && validObj.push({
				// 전화번호 포맷 체크(input-phone을 썼다면 필수로 넣어주세요.)
				ref: this.$refs.psnMngVOMobileNo,
				isValidate: inputPhoneValidate(this.psnMngVO.mobileNo),
				message: this.i18n("alt.phoneFormatCheck")
			});
			this.psnMngVO.faxNo && validObj.push({
				// 전화번호 포맷 체크(input-phone을 썼다면 필수로 넣어주세요.)
				ref: this.$refs.psnMngVOFaxNo,
				isValidate: inputPhoneValidate(this.psnMngVO.faxNo),
				message: this.i18n("alt.phoneFormatCheck")
			});

			validObj.push({
				// 이메일 포맷 체크(input-email을 썼다면 필수로 넣어주세요.)
				ref: this.$refs.psnMngVOEmailAddr,
				isValidate: this.emailFormatCheckHelperRequired(
					this.psnMngVO.emailAddr
				),
				message: this.i18n("alt.emailFormatCheck")
			});
			validObj.push({
					ref: this.$refs.psnMngVOPidNo,
					isValidate: getByteLengthOfUtf8String(this.psnMngVO.pidNo) <= 300,
					message: this.i18n("label.pidNo") + this.i18n("alt.max")+"\\n"+300+this.i18n("alt.upToBytes")
				});
			// validObj.push({
			// 	ref: this.$refs.psnMngVOGnd,
			// 	isValidate: !isEmpty(this.psnMngVO.gnd),
			// 	message: this.i18n("label.gnd") + this.i18n("alt.required")
			// });
			validObj.push({
					ref: this.$refs.psnMngVOPidNoIssPlc,
					isValidate: getByteLengthOfUtf8String(this.psnMngVO.pidNoIssPlc) <= 50,
					message: this.i18n("label.pidNoIssPlc") + this.i18n("alt.max")+"\\n"+50+this.i18n("alt.upToBytes")
				});
				validObj.push({
					ref: this.$refs.psnMngVOBasAddr,
					isValidate: getByteLengthOfUtf8String(this.psnMngVO.basAddr) <= 300,
					message: this.i18n("label.basAddr") + this.i18n("alt.max")+"\\n"+300+this.i18n("alt.upToBytes")
				});
				validObj.push({
					ref: this.$refs.psnMngVORmk,
					isValidate: getByteLengthOfUtf8String(this.psnMngVO.rmk) <= 200,
					message: this.i18n("label.rmk") + this.i18n("alt.max")+"\\n"+200+this.i18n("alt.upToBytes")
				});
			validObj.push({
				isValidate: this.fileUpload.fileList.length <= 5,
				message: this.i18n("alt.attatchGuide5")
			});

			if (this.legacyValidateHelper(validObj)) {
				return;
			} else {
				this.alert({
					title: this.i18n("label.save"),
					message: this.i18n("label.save") + this.i18n("alt.question"),
					buttons: [
						{
							type: "primary",
							label: this.i18n("button.confirm"),
							onPress: () => this.save()
						},
						{
							label: this.i18n("button.cancel")
						}
					]
				});
				return;
			}
		},

		// 탈퇴확인
		resignedValidate() {
			this.alert({
				title: this.i18n("label.resigned"),
				message:
					this.i18n("alt.questionResigned") +
					"\\n" +
					this.i18n("alt.resignedMsg"),
				buttons: [
					{
						type: "primary",
						label: this.i18n("button.confirm"),
						onPress: () => this.resignedStatChange()
					},
					{
						label: this.i18n("button.cancel")
					}
				]
			});
			return;
		},

		// 탈퇴상태변경
		resignedStatChange() {
			this.psnMngVO.psnStatCd = "02";
			this.save();
		},

		// 입력화면 초기화
		clear() {
			this.psnMngVO.status = "I";
			this.psnMngVO.psnNm = "";
			this.psnMngVO.psnEnm = "";
			this.psnMngVO.psnStatCd = "01";
			this.psnMngVO.regGrpTypCd = "";
			this.psnMngVO.regGrpCd = "";
			this.psnMngVO.grpCdNm = "";
			this.psnMngVO.telNo = "";
			this.psnMngVO.mobileNo = "";
			this.psnMngVO.faxNo = "";
			this.psnMngVO.emailAddr = "";
			// this.psnMngVO.pno = "";
			this.psnMngVO.ctAddrNm = "";
			this.psnMngVO.dsAddrNm = "";
			this.psnMngVO.wdAddrNm = "";
			this.psnMngVO.addrCd = "";
			this.psnMngVO.basAddr = "";
			this.psnMngVO.pidKndCd = "";
			this.psnMngVO.pidNo = "";
			this.psnMngVO.pidNoIssDt = "";
			this.psnMngVO.pidNoIssPlc = "";
			this.psnMngVO.gnd = "";
			this.psnMngVO.jobPosCd = "";
			this.psnMngVO.dob = "";
			this.psnMngVO.enthCd = "";
			this.psnMngVO.natCd = "";
			this.psnMngVO.atchFileFkId = "";
			this.psnMngVO.rmk = "";
			this.psnMngVO.photoDat = "";

			this.orgPsnMngVO.status = "I";
			this.orgPsnMngVO.psnNm = "";
			this.orgPsnMngVO.psnEnm = "";
			this.orgPsnMngVO.psnStatCd = "";
			this.orgPsnMngVO.regGrpTypCd = "";
			this.orgPsnMngVO.regGrpCd = "";
			this.orgPsnMngVO.grpCdNm = "";
			this.orgPsnMngVO.telNo = "";
			this.orgPsnMngVO.mobileNo = "";
			this.orgPsnMngVO.faxNo = "";
			this.orgPsnMngVO.emailAddr = "";
			// this.orgPsnMngVO.pno = "";
			this.orgPsnMngVO.ctAddrNm = "";
			this.orgPsnMngVO.dsAddrNm = "";
			this.orgPsnMngVO.wdAddrNm = "";
			this.orgPsnMngVO.addrCd ="";
			this.orgPsnMngVO.basAddr = "";
			this.orgPsnMngVO.pidKndCd = "";
			this.orgPsnMngVO.pidNo = "";
			this.orgPsnMngVO.pidNoIssDt = "";
			this.orgPsnMngVO.pidNoIssPlc = "";
			this.orgPsnMngVO.gnd = "";
			this.orgPsnMngVO.jobPosCd = "";
			this.orgPsnMngVO.dob = "";
			this.orgPsnMngVO.enthCd = "";
			this.orgPsnMngVO.natCd = "";
			this.orgPsnMngVO.atchFileFkId = "";
			this.orgPsnMngVO.rmk = "";
			this.orgPsnMngVO.photoDat = "";

			if (!isEmpty(this.$refs.psnMngVOTelNo)) {
				this.$refs.psnMngVOTelNo.clear();
			}
			if (!isEmpty(this.$refs.psnMngVOMobileNo)) {
				this.$refs.psnMngVOMobileNo.clear();
			}
			if (!isEmpty(this.$refs.psnMngVOEmailAddr)) {
				this.$refs.psnMngVOEmailAddr.clear();
			}
			if (!isEmpty(this.$refs.psnMngVOFaxNo)) {
				this.$refs.psnMngVOFaxNo.clear();
			}
		}
	}
};
</script>

<style lang="scss" scoped>
@import "@/scss/_button.scss";
$scroll-color: #bcd5ed;
.pc {
	.tab-content {
		margin-top: 25px;
		.cont {
			overflow: hidden;
			overflow-y: auto;
			min-height: 100px;
			max-height: 600px;
			&::-webkit-scrollbar {
				// 스크롤바
				width: 14px;
				height: 14px;
				background-color: #fff !important;
				box-sizing: border-box;
				&:horizontal {
					border-top: solid 1px $scroll-color;
				}
				&:vertical {
					border-left: solid 1px $scroll-color;
				}
				&-thumb {
					background-color: $scroll-color;
					background-clip: padding-box;
					border-radius: 7px;
					border: solid transparent;
					&:horizontal {
						border-width: 3px 2px 2px;
					}
					&:vertical {
						border-width: 2px 2px 2px 3px;
					}
				}
			}
		}
		.btn-btm {
			margin-top: 20px;
			text-align: center;
		}
	}
}
.del-btn {
	background-color: $delBtn !important;
}
.croppa-container {
	height: 55px;
	vertical-align: middle;
	background: #e2f2ff url("~@/assets/img/profile.gif") no-repeat center;
	border: solid 1px #cbcbcb;
	svg.icon-remove {
		width: 10px !important;
		height: 10px !important;
	}
}
.btn-generate-img {
	margin: 0 20px 0 5px;
	vertical-align: bottom !important;
}
</style>
