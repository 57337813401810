/**
 * 1. 개요 : Minputs 모바일 공통 컴포넌트 - MDateRange
 * 2. 처리내용 : MDateRange - 진입점
 * File Name : index.vue
 * date : 2020. 05. 12. 10:40:00
 * author : 이지혜
 * history :
 *-----------------------------------------------------------------------
 * 변경일			          		  작성자		변경내용
 *-----------------------------------------------------------------------
 * 2020. 05. 12. 10:40:00	    이지혜	  최초 작성
 *-----------------------------------------------------------------------
 */
<template>
  <div class="date-range">
    <ul class="date-range-list">
      <li
        v-for="(item, index) in list"
        :key="list[index]"
        class="date-range-list-item"
        :class="{ selected: index === selectedIndex}"
        @click="select(index)"
      >
        <button>{{ item }}</button>
      </li>
    </ul>
  </div>
</template>

<script>
import moment from "moment/moment";

const localeSetList = {
  KOR: {
    D: "일",
    W: "주",
    M: "월",
    Y: "년"
  },
  CHI: {
    D: "日",
    W: "周",
    M: "月",
    Y: "年"
  }
};

export default {
  name: "MDateRange",

  filters: {
    test: (value, msgLangCd) => {
      const itemTrans = (value || "").split(/(y|m|w|d)/i);
      if (msgLangCd === "en_US") return value;
      return itemTrans[0] + localeSetList[msgLangCd][itemTrans[1]];
    }
  },

  model: {
    prop: "value",
    event: "change"
  },

  props: {
    value: {
      type: String,
      default: ""
    },
    format: {
      type: String,
      default: "YYYY-MM-DD"
    },
    list: {
      type: Array,
      default: () => ["7D", "1M", "3M", "6M", "1Y"]
    },
    autoSetting: {
      type: Boolean,
      default: true
    }
  },

  data() {
    return {
      localValue: null
    };
  },

  computed: {
    selectedIndex() {
      return this.list.indexOf(this.localValue);
    }
  },

  watch: {
    value(value) {
      this.localValue = value;
    }
  },

  created() {
    this.localValue = this.value;
  },

  mounted() {
    if (this.autoSetting && this.selectedIndex >= 0) {
      this.select(this.selectedIndex);
    }
  },

  methods: {
    select(index) {
      this.localValue = this.list[index];
      const matches = /(\d{1,})(y|m|w|d)/i.exec(this.localValue);
      this.$emit(
        "change",
        this.list[index],
        moment()
          .subtract(
            matches[1],
            /y|d/i.test(matches[2])
              ? matches[2].toLowerCase()
              : matches[2].toUpperCase()
          )
          .add(1, "d")
          .format(this.format),
        moment().format(this.format)
      );
    }
  }
};
</script>

<style lang="scss" scoped>
$height: 32px;
$width: 30px;
$button-height: 24px;
$border-color: #e0e0e0;
$font-size: 11px;
$color: #666;
$space: 6px;
$cms-color: #4492d2;

.date-range {
  display: inline-block;
  vertical-align: middle;
  height: $height;
  padding: (($height - $button-height) / 2) 0;

  margin-left: 12px;

  &-list {
    overflow: hidden;
    &-item {
      float: left;

      height: $button-height;
      text-align: center;
      color: $color;
      background-color: #fff;
      border: solid 1px #e0e0e0;
      button {
        line-height: $button-height - 2px;
        font-size: $font-size;

        min-width: $width;
        padding: 0 5px;

        background: none;
        border: 0;
        box-shadow: none;
        appearance: none;
        box-sizing: border-box;
        color: inherit;
        line-height: 1;

        width: 100%;
        height: 100%;
        display: block;

        cursor: pointer;
      }
      &:hover {
        background: #eee;
      }
      &.selected {
        color: #fff;
        background: $cms-color;
        border-color: darken($cms-color, 10%);
      }
      &:nth-child(n + 2) {
        margin-left: $space;
      }
    }
  }
}
</style>

