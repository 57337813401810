/**
 * 1. 개요 : Minputs 모바일 공통 컴포넌트 - MCodeRadioBox
 * 2. 처리내용 : MCodeRadioBox - 진입점
 * File Name : index.vue
 * date : 2020. 05. 12. 10:40:00
 * author : 이지혜
 * history :
 *-----------------------------------------------------------------------
 * 변경일			          		  작성자		변경내용
 *-----------------------------------------------------------------------
 * 2020. 05. 12. 10:40:00	    이지혜	  최초 작성
 *-----------------------------------------------------------------------
 */
<template>
<div>
  <label 
    class="radio-box flex-btw-center"
    role="radio"
    :class="{
      disabled,
      checked: $attrs.value === model,
    }"
    id=""
  >
    <input 
      ref="radio"
      type="radio"
      v-bind="$attrs"
      :checked="$attrs.value === model"
      :disabled="disabled"
      @change="$event => $emit('apply', $attrs.value)"
      v-on="$listeners"
      :id="id + $attrs.value"
      :name="id+'Name'"
    >
    <span class="label" @click="$emit('close', item)">
      {{ item.codeName }}
    </span>
    <span class="radio-box-icon" />
  </label>
</div>
</template>

<script>
export default {
  name: 'MCodeRadioBox',
  model: {
    prop: 'model',
    event: 'apply',
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    model: {
      type: [String, Number],
      default: '',
    },
    id: {
      type: String,
      default: ""
    },
    item: {
      type: Object,
      default: () => {}
    },
  }
}
</script>

<style lang="scss" scoped>
@import "@/scss/_color.scss";
@import "@/scss/mobile/_m-input.scss";
</style>