/**
 * 1. 개요 : 카드BIN관리
 * 2. 처리내용 : 카드BIN관리 Mixins - 화면 모델
 * CmnCardBinPopupViewModel.js
 * @date : 2020. 06. 17.  15:55:53
 * @author : 이현우
 * @history :
 *-----------------------------------------------------------------------
 * 변경일			          		    작성자		변경내용
 *-----------------------------------------------------------------------
 * 2020. 06. 17.  15:55:53	    이현우	  최초 작성
 *-----------------------------------------------------------------------
 */
import { lookupValue } from "@/utils/dataGrid";
import moment from "moment";

export default {
  methods: {
    cmnCardBinPopupViewModel(params = {}) {
      return [
        {
          field: "rnum",
          headerName: "",
          type: Number,
          width: 50,
          isPk: false,
          editable: false,
          pinned: false,
          sortable: true,
          cellStyle: {
            textAlign: "center"
          },
          cellClassRules: {
            "selected-cell": param => params && param.rowIndex === params.rowIndex
          },
          valueFormatter: param => {
            // 오름차순
            // return (
            //   (this.currPage - 1) * this.pageUnit + param.node.rowIndex + 1
            // );

            // 내림차순
            return this.gridProps.totalItems - ((this.currPage - 1) * this.pageUnit + param.node.rowIndex);
          }
        },
        {
          field: "cardBin",
          headerName: this.i18n("label.cardBin"),
          type: String,
          width: 140,
          isPk: true,
          editable: false,
          pinned: false,
          suppressMenu: false,
          sortable: true,
          cellStyle: {},
          cellClassRules: {}
        },
        {
          field: "cardTypCd",
          headerName: this.i18n("label.cardTyp"),
          type: String,
          width: 110,
          isPk: true,
          editable: false,
          pinned: false,
          suppressMenu: false,
          sortable: true,
          cellStyle: {
             textAlign: "center" 
          },
          cellClassRules: {},
          valueFormatter: param => param.value && lookupValue(this.cdList.cardTypCdList, param.value)
        },
        { 
          field: "runData",
          headerName: this.i18n("label.runData"),
          type: String,
          width: 100,
          isPk: true,
          editable: false,
          pinned: false,
          suppressMenu: false,
          sortable: true,
          cellStyle: {
            textAlign: "center"
          },
          cellClassRules: {},
          valueFormatter: param => param.value && lookupValue(this.cdList.runDataCdList, param.value)     
        },
        {
          field: "aplyStaDtm",
          headerName: this.i18n("label.staDtm"),
          type: String,
          width: 150,
          isPk: true,
          editable: false,
          pinned: false,
          suppressMenu: false,
          sortable: true,
          cellStyle: { textAlign: "center" },
          cellClassRules: {},
          valueFormatter: param => param.value && moment(param.value, "YYYYMMDDHHmmss").format("YYYY/MM/DD HH:mm:ss")
        },
        {
          field: "aplyEndDtm",
          headerName: this.i18n("label.endDtm"),
          type: String,
          width: 150,
          isPk: false,
          editable: false,
          pinned: false,
          suppressMenu: false,
          sortable: true,
          cellStyle: { textAlign: "center" },
          cellClassRules: {},
          valueFormatter: param => param.value && moment(param.value, "YYYYMMDDHHmmss").format("YYYY/MM/DD HH:mm:ss")
        },
        {
          field: "cardNoLen",
          headerName: this.i18n("label.cardNoLen"),
          type: Number,
          width: 140,
          isPk: false,
          editable: false,
          pinned: false,
          suppressMenu: false,
          sortable: true,
          cellStyle: { textAlign: "center" },
          cellClassRules: {}
        },
        {
          field: "cardNm",
          headerName: this.i18n("label.cardNm"),
          type: String,
          width: 0,
          isPk: false,
          editable: false,
          pinned: false,
          suppressMenu: false,
          sortable: true,
          cellStyle: {},
          cellClassRules: {}
        },
        {
          field: "cardEnm",
          headerName: this.i18n("label.cardEnm"),
          type: String,
          width: 0,
          isPk: false,
          editable: false,
          pinned: false,
          suppressMenu: false,
          sortable: true,
          cellStyle: {},
          cellClassRules: {}
        },
        {
          field: "cardPsnCorpClCd",
          headerName: this.i18n("label.psnCorpCl"),
          type: String,
          width: 130,
          isPk: false,
          editable: false,
          pinned: false,
          suppressMenu: false,
          sortable: true,
          cellStyle: { textAlign: "center" },
          cellClassRules: {},
          valueFormatter: param => param.value && lookupValue(this.cdList.cardPsnCorpClCdList, param.value)
        },
        {
          field: "acqInstCd",
          headerName: this.i18n("label.acqInst"),
          type: String,
          width: 0,
          isPk: false,
          editable: false,
          pinned: false,
          suppressMenu: false,
          sortable: true,
          cellStyle: {},
          cellClassRules: {},
          valueFormatter: param => param.value && lookupValue(this.cdList.acqInstAllCdList, param.value)
        },
        {
          field: "issInstCd",
          headerName: this.i18n("label.issInst"),
          type: String,
          width: 0,
          isPk: false,
          editable: false,
          pinned: false,
          suppressMenu: false,
          sortable: true,
          cellStyle: {},
          cellClassRules: {},
          valueFormatter: param => param.value && lookupValue(this.cdList.issInstCdList, param.value)
        },        
        {
          field: "cardBrandClCd",
          headerName: this.i18n("label.cardBrand"),
          type: String,
          width: 0,
          isPk: false,
          editable: false,
          pinned: false,
          suppressMenu: false,
          sortable: true,
          cellStyle: {
            textAlign: "center" 
          },
          cellClassRules: {},
          valueFormatter: param => param.value && lookupValue(this.cdList.cardBrandClCdList, param.value)
        },
        {
          field: "stipLmtLvlCd",
          headerName: this.i18n("label.stipLmtLvl"),
          type: String,
          width: 150,
          isPk: false,
          editable: false,
          pinned: false,
          suppressMenu: false,
          sortable: true,
          cellStyle: { textAlign: "center" },
          cellClassRules: {},
          valueFormatter: param => param.value && lookupValue(this.cdList.stipLmtLvlCdList, param.value)
        },
        {
          field: "subBinRefYn",
          headerName: this.i18n("label.subBinRefYn"),
          type: String,
          width: 150,
          isPk: false,
          editable: false,
          pinned: false,
          suppressMenu: false,
          sortable: true,
          cellStyle: { textAlign: "center" },
          cellClassRules: {},
          valueFormatter: param => param.value && lookupValue(this.cdList.subBinRefYnList, param.value)
        },
        {
          field: "istPsblYn",
          headerName: this.i18n("label.istPsblYn"),
          type: String,
          width: 150,
          isPk: false,
          editable: false,
          pinned: false,
          suppressMenu: false,
          sortable: true,
          cellStyle: { textAlign: "center" },
          cellClassRules: {},
          valueFormatter: param => param.value && lookupValue(this.cdList.istPsblYnList, param.value)
        },
        {
          field: "stipPsblYn",
          headerName: this.i18n("label.stipPsblYn"),
          type: String,
          width: 150,
          isPk: false,
          editable: false,
          pinned: false,
          suppressMenu: false,
          sortable: true,
          cellStyle: { textAlign: "center" },
          cellClassRules: {},
          valueFormatter: param => param.value && lookupValue(this.cdList.stipPsblYnList, param.value)
        },

        {
          field: "binStatCd",
          headerName: this.i18n("label.stat"),
          type: String,
          width: 120,
          isPk: false,
          editable: false,
          pinned: false,
          suppressMenu: false,
          sortable: true,
          cellStyle: { textAlign: "center" },
          cellClassRules: {},
          valueFormatter: param => param.value && lookupValue(this.cdList.binStatCdList, param.value)
        },
        {
          field: "addedDate",
          headerName: this.i18n("label.addedDate"),
          type: String,
          width: 0,
          isPk: false,
          editable: false,
          pinned: false,
          suppressMenu: false,
          sortable: true,
          cellStyle: { textAlign: "center" },
          cellClassRules: {},
          valueFormatter: param => this.i18nDtTmFormat(param.value)
        }
      ];
    }
  }
};
