 /**
 * 1. 개요 : 카드BIN SUB 검색
 * 2. 처리내용 : 단말기ID검색 - 팝업창 컴포넌트
 * @File Name : CmnCardBinPopup.js
 * @date : 2020. 06. 17. 15:00:00
 * @author : 이현우
 * @history :
 *-----------------------------------------------------------------------
 * 변경일			          		  작성자		변경내용
 *-----------------------------------------------------------------------
 * 2020. 06. 17. 15:00:00	    이현우	  최초 작성
 *-----------------------------------------------------------------------
 */
<template>
	<popup :title="title" @close="$emit('close')" popSize="size-grid">
		<div class="search-wrap">
			<collapse-search-pane
				:isSearchPaneShow="isPaSearchPaneShow"
				@fnShowSearchPane="fnShowPaSearchPane"
			>
				<div class="search-area">
					<div id="tblInfo" class="hide">
						{{ 'label.CardBin' | i18n }}, {{ 'label.merNm' | i18n }}, {{ 'label.agncyCd' | i18n }}, {{ 'label.mappingYn' | i18n }}
						&nbsp;{{ 'label.search' | i18n }}
					</div>
					<div class="tbl-wrap">
						<table aria-describedby="tblInfo">
							<caption>{{ 'label.searchArea' | i18n }}</caption>
							<colgroup>
								<col style="width:150px" />
								<col style="width:262px" />
								<col style="width:150px" />
								<col style="width:262px" />
								<col style="width:150px" />
								<col style="width:262px" />
								<col style="width:150px" />
								<col style="width:auto" />
							</colgroup>
							<tbody>
								<tr>
									<th scope="row">
										<label for="searchCardBin">{{ 'label.cardBin' | i18n }}</label>
									</th>
									<td>
										<input-text
											id="searchCardBin"
											ref="searchCardBin"
											v-model="search.cardBin"
											type="text"
											:maxlength="8"
											name="searchCardBin"
											@keydown.enter="searchList"
										/>
									</td>
									<th scope="row">
										<label for="searchCardTypCd">{{ 'label.cardTypCd' | i18n }}</label>
									</th>
									<td>
										<code-selector
											id="searchCardTypCd"
											ref="searchCardTypCd"
											v-model="search.cardTypCd"
											:cdList="cdList.cardTypCdList"
											:isAll="true"
											@keydown.enter="searchList"
										/>
									</td>
									<th scope="row">
										<label for="searchCardBrandClCd">{{ 'label.cardBrandClCd' | i18n }}</label>
									</th>
									<td>
										<code-selector
											id="searchCardBrandClCd"
											ref="searchCardBrandClCd"
											v-model="search.cardBrandClCd"
											:cdList="cdList.cardBrandClCdList"
											:isAll="true"
											@keydown.enter="searchList"
										/>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</collapse-search-pane>
			<div class="search-btn">
				<button type="button" name="searchList" @click.stop="searchList">{{'button.look' | i18n }}</button>
				<button type="button" name="clear" @click.stop="clear">{{'button.init' | i18n }}</button>
			</div>
		</div>
		<div>
			<data-grid
				ref="dataGrid1"
				:totalItems="gridProps.totalItems"
				:columnDefs="gridProps.columnDefs"
				:rowData="gridProps.rowData"
				:initPage="gridProps.initPage"
				:perPage="pageSize"
				:selectedRows="gridProps.selectedRows"
				:gridHeight="gridProps.gridHeight"
				:rowClassRules="gridProps.rowClassRules"
				:frameworkComponents="gridProps.frameworkComponents"
				:rowSelection="gridProps.rowSelection"
				:topBtnList="gridProps.topBtnList"
				:suppressRowClickSelection="gridProps.suppressRowClickSelection"
				@rowSelected="setRowSelected"
				@selectionChanged="setSelectionChangedRow"
				@callOnRowDoubleClicked="fnRowDoubleClick"
				@fnList="fnList"
			/>
		</div>
	</popup>
</template>

<script>
import Popup from "@/components/Popup";
import CollapseSearchPane from "@/components/CollapseSearchPane";

import { EventBus } from "./EventBus";
import { CmnCardBinPopupViewModel, Helper, AnimationHelper } from "./mixins";
import { isEmpty } from "@/utils";
import DataGrid from "@/components/DataGrid";

export default {
	name: "CmnCardBinPopup",
	components: {
		Popup,
		CollapseSearchPane,
		DataGrid
	},
	mixins: [CmnCardBinPopupViewModel, Helper, AnimationHelper],
	props: {
		paramData: {
			type: Object,
			default: () => {}
		}
	},
	data() {
		return {
			resultList: [],
			currPage: 1,
			pageUnit: 100,
			pageSize: 100,
			search: {
				cardBin: "",
				cardTypCd: "",
				aplyStaDtm: "",
				issInstCd: "",
				cardBrandClCd: ""
			},

			gridProps: {
				totalItems: 0,
				columnDefs: [],
				rowData: [],
				// 일반적인 row 선택 or 더블클릭시
				selectedRows: [],
				initPage: true,
				editable: true,
				gridHeight: 360,
				rowClassRules: null,
				frameworkComponents: null,
				rowSelection: null,
				// 로우 선택시 선택이 되도록 하려면 false, 체크박스 or 라디오 선택시 선택이 되오도록 하려면 true
				suppressRowClickSelection: false,
				topBtnList: []
			},
			// 일반적인 row 선택 or 더블클릭시
			selectedRow: null,
			// 체크박스 또는 라디오 row 선택시
			selectedCheckOrRadioRows: [],

			isPaSearchPaneShow: true,
			cdList: {
				cardTypCdList: [],
				cardPsnCorpClCdList: [],
				cardBrandClCdList: [],
				issInstCdList: [],
				acqInstAllCdList:[],
				// trInstCdList: [],
				stipLmtLvlCdList: [],
				subBinRefYnList: [],
				istPsblYnList: [],
				stipPsblYnList: [],
				runDataCdList: []
			}
		};
	},
	computed: {
		/**
     * 화면 title 설정
     * @return {String}
     */
		title() {
			return (
				this.i18n("label.cardBin") +
				this.i18n("label.search")
			);
		}
	},
	watch: {
		// row 더블 클릭 했을 경우 다시 호출
		selectedRow: {
			handler(params) {
				this.setColumnDefs(params);
				this.setRowClassRules();
			}
		}
	},
	beforeMount() {

		this.onSearchCardBinInstComboList();
		this.onSearchCardBinAcqInstComboList();
		this.fnGetAxcCodeGrpList([
			"CARD_TYP_CD",
			"CARD_PSN_CORP_CL_CD",
			"CARD_BRAND_CL_CD",
			"STIP_LMT_LVL_CD",
			"SUB_BIN_REF_YN",
			"IST_PSBL_YN",
			"STIP_PSBL_YN",
			"BIN_STAT_CD",
			"RUN_DATA_CD"
		]);

		// 그리드 초기화
		this.setColumnDefs();

		// row 클래스 동적 적용
		this.setRowClassRules();

		// 그리드의 frameworkComponents 셋(필요한 경우에만)
		this.setFrameworkComponents();

		// 그리드의 rowSelection 셋
		this.setRowSelection();
	},
	mounted() {
		this.searchList();
	},
	methods: {
		/**
     * 업무 코드 리스트 페치
     * @params {Array} searchCodeGrpIdList
     */
		fnGetAxcCodeGrpList(searchCodeGrpIdList = []) {
			if (isEmpty(searchCodeGrpIdList)) return;

			this.$httpPost(
					"/api/bep/getAxcCodeGroupListByCodeGrpIdList.do",
					{ searchCodeGrpIdList },
					{ showLoading: false }
				)
				.then(response => {
					this.cdList.cardTypCdList = response.data.resultList.find(a => a[0].codeGrpId === "CARD_TYP_CD");
					this.cdList.cardPsnCorpClCdList = response.data.resultList.find(a => a[0].codeGrpId === "CARD_PSN_CORP_CL_CD");
					this.cdList.cardBrandClCdList = response.data.resultList.find(a => a[0].codeGrpId === "CARD_BRAND_CL_CD");
					this.cdList.stipLmtLvlCdList = response.data.resultList.find(a => a[0].codeGrpId === "STIP_LMT_LVL_CD");
					this.cdList.subBinRefYnList = response.data.resultList.find(a => a[0].codeGrpId === "SUB_BIN_REF_YN");
					this.cdList.istPsblYnList = response.data.resultList.find(a => a[0].codeGrpId === "IST_PSBL_YN");
					this.cdList.stipPsblYnList = response.data.resultList.find(a => a[0].codeGrpId === "STIP_PSBL_YN");
					this.cdList.binStatCdList = response.data.resultList.find(a => a[0].codeGrpId === "BIN_STAT_CD");
					this.cdList.runDataCdList = response.data.resultList.find(a => a[0].codeGrpId === "RUN_DATA_CD");
				})
				.catch(this.commonErrorHandler);
		},

		/**
     * 발급사 셀렉트박스 조회
     */
		onSearchCardBinInstComboList() {
			this.resultList = [];
			this.$httpPost("/api/bep/getInstMngMstList.do", {
					searchArrInstTypeCd : ["10", "20", "40"]
				},
				{
					showLoading: true
				})
				.then(response => {
					// result 셋
					this.resultList = response.data.resultList;
				})
				.then(() => {
					// 그리드에 조회해온 데이터 셋
					if (this.resultList) {
						this.cdList.issInstCdList = this.resultList.map(a => ({
							codeId: a.instCd,
							codeName: a.instNm
						}));
						// this.cdList.trInstCdList = this.resultList.map(a => ({
						// 	codeId: a.comboId,
						// 	codeName: a.comboName
						// }));
					}
				});
		},
		/**
     * 매입사 셀렉트박스 조회
     */
		onSearchCardBinAcqInstComboList() {
			this.resultList = [];
			this.$httpPost("/api/bep/getInstMngMstList.do", {
					 searchInstTypCd: "10",
          searchTrYn : 'Y'
				},
				{
					showLoading: true
				})
				.then(response => {
					// result 셋
					this.resultList = response.data.resultList;
				})
				.then(() => {
					// 그리드에 조회해온 데이터 셋
					if (this.resultList) {
						this.cdList.acqInstAllCdList = this.resultList.map(a => ({
							codeId: a.instCd,
							codeName: a.instNm
						}));
						// this.cdList.trInstCdList = this.resultList.map(a => ({
						// 	codeId: a.comboId,
						// 	codeName: a.comboName
						// }));
					}
				});
		},
		/**
     * 카드빈 목록 조회
     */
		searchList() {

			this.resultList = [];
			this.gridProps.rowData = [];

			this.$httpPost(
					"/api/bep/getCardBinMngList.do",
					{
						searchCardBin: this.search.cardBin,
						searchCardTypCd: this.search.cardTypCd,
						searchCardBrandClCd: this.search.cardBrandClCd,
						searchSubBinRefYn: 'Y',
						searchOnlyLatest: '1',
						searchRunData: 'YR',
						// searchAplyStaDtm: this.search.aplyStaDtm,
						// searchIssInstCd: this.search.issInstCd,
						currPage: this.currPage,
						pageUnit: this.pageUnit,
						pageSize: this.pageSize
					},
					{
						showLoading: true
					}
				)
				.then(response => {
					// result 셋
					this.resultList = response.data.resultList;
					// 데이터 총 갯수 셋
					this.gridProps.totalItems = response.data.totalCount;
				})
				.then(() => {
					// 그리드에 조회해온 데이터 셋
					if (this.resultList)
						this.gridProps.rowData = this.resultList.map(a => ({
							...a,
							status: "R"
						}));

						//console.log(this.resultList);
				})
				.catch(this.commonErrorHandler);
		},

		/**
     * 페이지 변환시 데이터 조회 함수
     * @params {int} currPage
     */
		fnList(currPage) {
			this.currPage = currPage;
			this.searchList();
			this.gridProps.initPage = false;
		},

		/**
     * 더블 클릭시
     * @params {Object} selectedRow
     */
		fnRowDoubleClick(selectedRow) {
			this.$emit("fnRowDoubleClick", selectedRow);
			this.$emit("close");
		},

		/**
     * 그리드에 하나의 row 선택 함수
     * @params {Object} params
     */
		setSelectionChangedRow(params) {
			this.gridProps.selectedRows = [];
			this.gridProps.selectedRows = [
				{ ...params.api.getSelectedRows()[0], status: "U" }
			];
		},

		/**
     * 그리드의 columnDefs 셋
     * @params {Object} params
     */
		setColumnDefs(params = {}) {
			this.$nextTick(() => {
				let gridWidth = this.$refs.dataGrid1.$el.clientWidth - 20;
				let totalWidth = 0;

				this.gridProps.columnDefs = this.cmnCardBinPopupViewModel(params).map(
					a => {
						totalWidth += a.width === 0 ? 200 : a.width;

						return {
							headerName: a.headerName,
							field: a.field,
							width:
								a.field === "restCell" && totalWidth > 0
									? gridWidth - totalWidth + 200
									: a.width,
							editable: a.editable,
							pinned: a.pinned,
							cellStyle: a.cellStyle,
							cellClassRules: a.cellClassRules,
							valueFormatter: a.valueFormatter,
							cellRenderer: a.cellRenderer,
							checkboxSelection: a.checkboxSelection,
							suppressMenu: a.suppressMenu,
							sortable: a.sortable
						};
					}
				);
			});
		},

		/**
     * 그리드의 rowClass 셋
     */
		setRowClassRules() {
			this.gridProps.rowClassRules = {
				// 'selected-row': param => this.selectedRow && (this.selectedRow.rowIndex === param.rowIndex)
			};
		},

		/**
     * 그리드의 frameworkComponents 셋
     */
		setFrameworkComponents() {
			this.gridProps.frameworkComponents = {};
		},

		/**
     * 그리드의 rowSelection 셋
     */
		setRowSelection() {
			// 단수 선택 경우 'single', 복수 선택일 경우 'multiple'
			this.gridProps.rowSelection = "single";
		},

		/**
     * 셀렉트박스 또는 라디오 선택시
     * @params {Object} params
     */
		setRowSelected(params) {
			if (params.node.isSelected()) {
				this.selectedCheckOrRadioRows.push(params.data);
			} else {
				this.selectedCheckOrRadioRows.pop(params.data);
			}
		},

		/**
     * 입력 폼 초기화
     */
		clear() {
			this.search.cardBin = "";
			this.search.cardTypCd = "";
			this.search.cardBrandClCd = "";
			this.search.merNm = "";
			this.search.issInstCd = "";
			
			this.searchList();
		}
	}
};
</script>

<style lang="scss" scoped>
@import "@/scss/_color.scss";
@import "@/scss/_button.scss";
</style>


