/**
 * 1. 개요 : QuickMenu
 * 2. 처리내용 : QuickMenu - 스토어
 * @File Name : QuickMenu.js
 * @date : 2020. 02. 20. 09:46:00
 * @author : 이상철
 * @history :
 *-----------------------------------------------------------------------
 * 변경일			          		  작성자		변경내용
 *-----------------------------------------------------------------------
 * 2020. 2. 20. 09:46:00	    이상철	  최초 작성
 *-----------------------------------------------------------------------
 */
export default {
  state: {
    quickMenuList: []
  },
  namespaced: true,
  mutations: {
    SET_QUICK_MENU_LIST(state, quickMenuList) {
      state.quickMenuList = quickMenuList;
    }
  },
  actions: {
    setQuickMenuList({ commit }, quickMenuList) {
      commit("SET_QUICK_MENU_LIST", quickMenuList);
    },
    setInitialQuickMenuList({ commit }) {
      commit("SET_QUICK_MENU_LIST", []);
    }
  },
  getters: {
    getQuickMenuList: state => state.quickMenuList
  }
};
