/**
 * 1. 개요 : Minputs 모바일 공통 컴포넌트 - MInputEmail
 * 2. 처리내용 : MInputEmail - 진입점
 * File Name : index.vue
 * date : 2020. 05. 12. 10:40:00
 * author : 이지혜
 * history :
 *-----------------------------------------------------------------------
 * 변경일			          		  작성자		변경내용
 *-----------------------------------------------------------------------
 * 2020. 05. 12. 10:40:00	    이지혜	  최초 작성
 *-----------------------------------------------------------------------
 */
<template>
  <div class="input-text mail-box">
    <label>
      <input v-model="prefix"
            :type="type"
            v-bind="$attrs"
            v-on="$listeners"
            :id="id + 'Prefix'"
            ref="prefix"
      >
      <span v-if="showClear"
          class="input-clear"
          title="clear"
          @click="$emit('clearPrefix')"
      />
    </label>
    @
    <label>
      <input v-model="suffix"
            :type="type"
            v-bind="$attrs"
            v-on="$listeners"
            :id="id + 'Subffix'"
            ref="subffix"
      >
      <span v-if="showClear"
          class="input-clear"
          title="clear"
          @click="$emit('clearSuffix')"
      />
    </label>
    <label class="selector select-box">
      <select v-model="suffix" v-bind="$attrs" v-on="$listeners">
        <option :value="suffix" :label="isDirectInput ? `::: ${directInput} :::` : suffix" >::: {{ directInput }} :::</option>
        <option
          v-for="item in pickSubffixList"
          :key="item.id"
          :value="item.id"
          :label="item.name"
        >{{ item.name }}</option>
      </select>
      <span class="arrow-box" />
    </label>
  </div>
</template>
<script>
import { isEmpty } from "@/utils";

export default {
  name: 'MInputEmail',

  props: {
    type: {
      type: String,
      default: 'text',
    },
    value: {
      type: String,
      default: ''
    },
    showClear: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
      default: ''
    }
  },

  mounted() {
    this.prefix = this.value.split('@')[0]
    this.suffix = this.value.split('@')[1]
  },

  data() {
    return {
      prefix: "",
      suffix: "",
      pickSubffixList: [
        {
          id: "gmail.com",
          name: "gmail.com"
        },
        {
          id: "naver.com",
          name: "naver.com"
        },
        {
          id: "daum.net",
          name: "daum.net"
        }
      ],
      i18nDirectInput: {
        en_US: "Direct Input",
        vi_VN: "",
        ko_KR: "직접 입력"
      }
    };
  },

  computed: {
    directInput() {
      return this.i18n("label.directInput") ? this.i18nDirectInputForLabel() : this.i18n("label.directInput")
    },
    isDirectInput() {
      return !this.pickSubffixList.map(a => a.id).includes(this.suffix)
    }
  },

  watch: {
    prefix(value) {
      this.prefix = value;
      this.$emit('fnChange', value + '@' + this.suffix);
    },

    suffix(value) {
      this.suffix = value;
      this.$emit('fnChange', this.prefix + '@' + value);
    },
  },

  methods: {
    i18nDirectInputForLabel() {
      let result;
      // 시작시 브라우저 로케일 가져와서 this.lang 에 셋 하기
      switch(navigator.language.slice(0,2)) {
        case 'en':
          result = this.i18nDirectInput.en_US
          break;
        case 'vi':
          result = this.i18nDirectInput.vi_VN
          break;
        case 'ko':
          result = this.i18nDirectInput.ko_KR
          break;
      }

      return result;
    },
    clear() {
      this.prefix = ""
      this.suffix = ""
    }
  },
};
</script>

<style lang="scss">
@import "@/scss/_color.scss";
@import "@/scss/mobile/_m-input.scss";
@import "@/scss/mobile/_m-select.scss";
.mobile{
  .mail-box {
    > label{
      display:inline-block;
      width:100%;
      vertical-align: middle;
      &:nth-of-type(1){
        width:calc(100% - 16px);
      }
      &.select-box,
      &:nth-of-type(2){
        margin:5px 5px 0 0;
      }
    }
  }
}
</style>

