/**
 * 1. 개요 : Inputs 공통 컴포넌트
 * 2. 처리내용 : Inputs 공통 컴포넌트 - CodeSelector
 * @File Name : index.vue
 * @date : 2020. 02. 20. 09:46:00
 * @author : 이상철
 * @history :
 *-----------------------------------------------------------------------
 * 변경일			          		  작성자		변경내용
 *-----------------------------------------------------------------------
 * 2020. 2. 20. 09:46:00	    이상철	  최초 작성
 *-----------------------------------------------------------------------
 */
<template>
	<div class="selector select-box">
		<select v-model="model" v-bind="$attrs" v-on="$listeners" :id="id">
			<option v-if="isAll" value>::: {{ isLogin ? i18n("label.all") : i18nOff("label.all") }} :::</option>
			<option v-if="isSel" value>::: {{ isLogin ? i18n("label.select") : i18nOff("label.select") }} :::</option>
			<option
				v-for="item in cdList"
				:key="item.codeId"
				:value="item.codeId"
				:label="item.codeName"
			>{{ item.codeName }}</option>
		</select>
		<div class="arrow-box" />
	</div>
</template>
<script>
export default {
	name: "CodeSelector",
	model: {
		prop: "value",
		event: "apply"
	},

	props: {
		value: {
			type: [String, Number],
			default: ""
		},
		cdList: {
			type: Array,
			default: () => []
		},
		isAll: {
			type: Boolean,
			default: true
		},
		isSel: {
			type: Boolean,
			default: false
		},
		id: {
			type: String,
			default: ""
		}
	},

	data() {
		return {
			model: this.value
		};
	},

	watch: {
		value(value) {
			if (this.model !== value) this.model = value;
		},

		model(value) {
			this.$emit("apply", typeof value === "number" ? Number(value) : value);
		}
	},

	methods: {
		focus() {
			const input = this.$el.getElementsByTagName("select")[0];
			if (input) input.focus();
		}
	}
};
</script>

<style lang="scss" scoped>
@import "@/scss/_select.scss";
</style>

