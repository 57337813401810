/**
 * 1. 개요 : Empty
 * 2. 처리내용 : Empty - 진입점
 * @File Name : index.vue
 * @date : 2020. 02. 20. 09:46:00
 * @author : 이상철
 * @history :
 *-----------------------------------------------------------------------
 * 변경일			          		  작성자		변경내용
 *-----------------------------------------------------------------------
 * 2020. 2. 20. 09:46:00	    이상철	  최초 작성
 *-----------------------------------------------------------------------
 */
<template>
  <router-view />
</template>

<script>
export default {
  name: 'Empty',
};
</script>
