/**
 * 1. 개요 : 공통
 * 2. 처리내용 : 공통 - computed 영역
 * @File Name : computed.js
 * @date : 2020. 02. 20. 09:46:00
 * @author : 이상철
 * @history :
 *-----------------------------------------------------------------------
 * 변경일			          		  작성자		변경내용
 *-----------------------------------------------------------------------
 * 2020. 2. 20. 09:46:00	    이상철	  최초 작성
 *-----------------------------------------------------------------------
 */
import { mapState, mapGetters } from "vuex";
import { isEmpty } from "@/utils";

export default {
  ...mapGetters("CommonCode", [
    "getCommonCodeList", 
    "getCommonCodeByCodeId",
    "getCommonCodeGroupById",
    "getMenuList",
    "getMenuTree",
    "getMobileMenuList",
    "getMobileMenuTree"
  ]),
  ...mapState("CommonCode", {
    commonCodeGroupList: state => state.codeGroupList
  }),
  ...mapGetters("Options", ["isLogin", "loginToken", "userInfo"]),
  ...mapState("Options", {
    msgLangCd: state => state.msgLangCd,
    company: "cms"
  }),
  ...mapGetters("Loading", ["isGlobalLoading"]),
  ...mapGetters("TagsView", ["visitedViews", "cachedViews"]),
  browserLang() {
    switch (navigator.language.slice(0, 2)) {
      case "en":
        return "en_US";
      case "vi":
        return "vi_VN";
      case "ko":
        return "ko_KR";
      case "jp":
        return "jp_JP";
      default:
        return "en_US";
    }
  },
  /**
   * 디바이스 체크
   * @return {String}
   */
  getConnDevice() {
    return this.$store.getters["Device/getDeviceCd"];
  },
  /**
   * 메뉴 제목
   * @return {String}
   */
  menuTitle() {
    const currentMenuName = this.$route.name;
    const menuList = this.$store.getters["CommonCode/getMenuList"]();

    if (!isEmpty(menuList)) {
      const findMenu = menuList.find(a => a.name === currentMenuName);
      return findMenu && findMenu.title;
    }

    return "";
  },
  /**
   * 권한 정보
   * @return {Object}
   */
  getAuthor() {
    return (
      !isEmpty(this.$store.getters["Options/userInfo"].menuAuthDetailList) &&
      this.$store.getters["Options/userInfo"].menuAuthDetailList.find(
        a => a.menuNm === this.$route.name
      )
    );
  }
};
