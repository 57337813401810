/**
 * 1. 개요 : 이벤트정책검색
 * 2. 처리내용 : 이벤트정책검색 - 이벤트 버스
 * @File Name : EventBus.js
 * @date : 2022. 11. 21.  14:55:00
 * @author : 김준석
 * @history :
 *-----------------------------------------------------------------------
 * 변경일			          		    작성자		변경내용
 *-----------------------------------------------------------------------
 * 2022. 11. 21.  14:55:00	    김준석	  최초 작성
 *-----------------------------------------------------------------------
 */
import Vue from "vue";

export const EventBus = new Vue();

export default {
  EventBus
};
