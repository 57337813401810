var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "fade" } }, [
    _c(
      "div",
      { staticClass: "modal" },
      [
        _c("spinner", {
          staticClass: "spinner",
          attrs: {
            "line-size": 10,
            size: 100,
            message: "Loading...",
            "text-fg-color": "#fff",
            "line-fg-color": _vm.color
          }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }