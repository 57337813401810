var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "window",
    { attrs: { role: "dialog", id: _vm.id, type: _vm.type } },
    [
      _c("template", { slot: "header" }, [_vm._v(_vm._s(_vm.title))]),
      _c("message", {
        attrs: { message: _vm.message, tag: "p", "use-i18n": false }
      }),
      _c(
        "template",
        { slot: "buttons" },
        [
          _vm._l(_vm.buttons, function(button, index) {
            return [
              button.type === "primary"
                ? _c(
                    "primary-button",
                    {
                      key: index,
                      attrs: { type: "button" },
                      on: {
                        click: function($event) {
                          return _vm.onPressListener(button.onPress)
                        }
                      }
                    },
                    [_vm._v(_vm._s(button.label))]
                  )
                : _c(
                    "default-button",
                    {
                      key: index,
                      attrs: { type: "button" },
                      on: {
                        click: function($event) {
                          return _vm.onPressListener(button.onPress)
                        }
                      }
                    },
                    [_vm._v(_vm._s(button.label))]
                  )
            ]
          })
        ],
        2
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }