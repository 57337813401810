/**
 * 1. 개요 : TodoList
 * 2. 처리내용 : TodoList 컴포넌트
 * @File Name : index.vue
 * @date : 2020. 02. 20. 09:46:00
 * @author : 이상철
 * @history :
 *-----------------------------------------------------------------------
 * 변경일			          		  작성자		변경내용
 *-----------------------------------------------------------------------
 * 2020. 2. 20. 09:46:00	    이상철	  최초 작성
 *-----------------------------------------------------------------------
 */
<template>
  <div class="tooltip">
    <strong>To-do list</strong>
    <p>{{ "alt.newWorkCheck" | i18n }}</p>
    <p>{{"label.lastConnIp"|i18n}} : {{lastConnIp}}</p>
    <ul v-if="newList && newList.length > 0" class="todo-list">
    <p class="todoTitle">{{"label.newTodo"|i18n}}</p>
      <li v-for="todo in newList" :key="todo.id" class="newList">
        <p v-if="msgLangCd === 'ko_KR'" :title="todo.todoNm" class="els">{{ todo.todoNm }}</p>
        <p v-else :title="todo.todoEnm" class="els">{{ todo.todoEnm }}</p>
        <span @click="onClickCount(todo.menuId)">{{ todo.resultCnt }}</span>
      </li>
    </ul>
    <ul v-if="resultList && resultList.length > 0" class="todo-list">
    <div class="titleDiv" @click="showList"><p class="todoTitle mustTitle">{{"label.mustTodo"|i18n}}</p><p>{{ val }}</p></div>
    <div v-if="showMustList">
      <li v-for="todo in resultList" :key="todo.id" class="mustList">
        <p v-if="msgLangCd === 'ko_KR'" :title="todo.todoNm" class="els">{{ todo.todoNm }}</p>
        <p v-else :title="todo.todoEnm" class="els">{{ todo.todoEnm }}</p>
        <span @click="onClickCount(todo.menuId)">{{ todo.resultCnt }}</span>
      </li>
    </div>
    </ul>
    <div v-if="resultList.length === 0 && newList.length === 0" class="no-work-todo">{{ 'alt.noNewWorkTodo' | i18n }}</div>
    <check-box class="three-days-wrap" :class="{'no-work-checkbox': resultList.length === 0 && newList.length === 0}" @click="closeCookie">{{ "alt.3day" | i18n }}</check-box>
    <a href="javascript:;" class="close" @click="$emit('closeBtn')">{{ "label.close" | i18n }}</a>
  </div>
</template>

<script>
export default {
  name: 'TodoList',
  data() {
    return {
      close: null,
      showMustList: true,
      val: "-"
    }
  },
  props: {
    resultList: {
      type: Array,
      default: () => []
    },
    lastConnIp:{
      type:String,
      default:()=>""
    },
    newList: {
      type:Array,
      default: () => []
    }
  },
  beforeDestroy(){
    clearTimeout(this.close);
  },
  methods: {
    /**
     * '3일 동안 보여주지 않기' 버튼 클릭 시 쿠키셋 에밋 전송
     */
    closeCookie() {
      this.$emit('getClickedMenuInfo', 'ALL');

      this.$emit('closeCookie');
      this.close = setTimeout(() => {
        this.$emit('closeBtn');
      }, 150)
    },
    /**
     * 할 일 개수를 클릭하면 해당 이슈가 있는 페이지로 route
     * @param {String} param 
     */
    onClickCount(param){
        this.$emit('getClickedMenuInfo', param);
    },
    showList(){
      this.showMustList = !this.showMustList;
      this.$nextTick(()=>{
        if(this.showMustList){
          this.val = "-";
        } else {
          this.val ="+";
        }
      });
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/scss/_color.scss";

.tooltip{
  position:absolute;
  top:0;
  right:-324px;
  width:324px;
  min-height:186px;
  padding:15px 30px 18px 15px;
  color:$c4;
  background-color:$point03;
  border:solid 1px $c5;
  z-index:1003; // 원래는 10이었는데 즐겨찾기 아이콘 위에 있어야할 것 같아서 변경. 이지혜 8월 27일.
  &::after,
  &::before{
    content:"";
    position:absolute;
    top:14px;
    left:-29px;
    border: {
      style : solid;
      color : transparent $point03 transparent transparent;
      width : 11px 15px;
    }
  }
  &::before{
    left:-31px;
    border: {
      color : transparent $c5 transparent transparent;
    }
  }
  > strong{
    margin-bottom:10px;
    font-size: 14px;
  }
  .check-box{
    margin:10px 15px 0;
    span{
      &::before{
        margin-right:5px;
      }
      &::after{
        left:5px;
      }
    }
  }
  .close{
    position:absolute;
    top:15px;
    right:15px;
    width:15px;
    height:15px;
    font-size:0;
    &::before,
    &::after{
      content:"";
      position: absolute;
      top:0;
      left:7px;
      display:block;
      width:1px;
      height:100%;
      background-color:$c5;
      transform: rotate(45deg);
    }
    &::after{
      transform: rotate(-45deg);
    }
  }
  .todo-list{
    margin-top:15px;
    padding:0 15px;
    max-height:280px; // li가 10개가 넘어가면 세로 스크롤이 생김. 이지혜 추가. 8월 28일
    overflow-y:auto; // 이지혜 추가. 8월 28일
     &::-webkit-scrollbar {
        $todo-scroll-color: rgb(255, 207, 134);
        // 스크롤바
        width: 14px;
        height: 14px;
        background-color: $point03 !important;
        box-sizing: border-box;
        &:horizontal {
          border-top: solid 1px $todo-scroll-color;
        }
        &-thumb {
          background-color: $todo-scroll-color;
          background-clip: padding-box;
          border-radius: 7px;
          border: solid transparent;
          &:horizontal {
            border-width: 3px 2px 2px;
          }
          &:vertical {
            border-width: 2px 2px 2px 3px;
          }
        }
      }
    > li{
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding:5px 0;
      border-bottom:solid 1px $c5;
      overflow-y: auto;
      > p {
        width:calc(100% - 35px)
      }
      > span{ // a태그였는데 span 태그로 변경. 8월 31일 이지혜.
        font-size:15px;
        font-weight:bold;
        color:$point;
        text-decoration: underline;
        cursor: pointer; // 그러면서 포인터 추가.
      }
    }
  }
  .three-days-wrap{ // 이지혜 추가. 8월 27일.
    transform: translateY(10px);
  }
  .no-work-todo{ // 이지혜 추가. 8월 28일.
    text-align:center;
    transform: translateY(40px);
  }
  .no-work-checkbox{ // 이지혜 추가. 8월 31일.
    transform: translateY(80px);
  }
  .mustList{
       display: flex;
      justify-content: space-between;
      align-items: center;
      padding:5px 0;
      border-bottom:solid 1px $c5;
      > p {
        width:calc(100% - 35px)
      }
      > span{ // a태그였는데 span 태그로 변경. 8월 31일 이지혜.
        font-size:14px;
        color:grey;
        text-decoration: underline;
        cursor: pointer; // 그러면서 포인터 추가.
      }
  }
  .todoTitle{
    font-weight: bold;
  }
  .titleDiv{
    white-space: nowrap;
    cursor: pointer;
    >p{
      display: inline-block;
      width: 50%;
      &:last-child{
        text-align: right;
      }
    }
  }
}//.tooltip
</style>
