var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { ref: "wrapper", staticClass: "data-table-wrap" }, [
    _c(
      "div",
      { staticClass: "hide", attrs: { id: _vm.tblId } },
      [_vm._t("tableInfo")],
      2
    ),
    _c(
      "table",
      {
        staticClass: "data-table",
        class: _vm.tableClasses,
        attrs: { "aria-describedby": _vm.tblId }
      },
      [
        _c("caption", [_vm._t("caption")], 2),
        _vm.$slots.colgroup
          ? _c("colgroup", [_vm._t("colgroup")], 2)
          : _vm._e(),
        _vm.$slots.thead ? _c("thead", [_vm._t("thead")], 2) : _vm._e(),
        _vm.$slots.tbody ? _c("tbody", [_vm._t("tbody")], 2) : _vm._e(),
        _vm.$slots.tfoot ? _c("tfoot", [_vm._t("tfoot")], 2) : _vm._e()
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }