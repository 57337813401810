var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "fade" } }, [
    _c("div", { staticClass: "dimmed" }, [
      _c(
        "div",
        { ref: "wrap", staticClass: "wrap", attrs: { role: "dialog" } },
        [
          _c(
            "h1",
            {
              staticClass: "header",
              class: { "warning-orange": _vm.type === "warning" }
            },
            [_vm.title ? [_vm._v(_vm._s(_vm.title))] : [_vm._t("header")]],
            2
          ),
          _c("div", { staticClass: "body" }, [_vm._t("default")], 2),
          _c(
            "div",
            { staticClass: "footer" },
            [_vm._t("footer"), _vm._t("buttons")],
            2
          ),
          _c(
            "button",
            {
              staticClass: "close-button",
              attrs: { type: "button" },
              on: {
                click: function($event) {
                  return _vm.close()
                }
              }
            },
            [_vm._v("Close")]
          )
        ]
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }