/**
 * 1. 개요 : Grid 공통 컴포넌트
 * 2. 처리내용 : Grid 공통 컴포넌트 - Row
 * @File Name : Row.vue
 * @date : 2020. 02. 20. 09:46:00
 * @author : 이상철
 * @history :
 *-----------------------------------------------------------------------
 * 변경일			          		  작성자		변경내용
 *-----------------------------------------------------------------------
 * 2020. 2. 20. 09:46:00	    이상철	  최초 작성
 *-----------------------------------------------------------------------
 */
<template>
  <div :style="style">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'Row',
  props: {
    width: {
      type: [String, Number],
      default: 'auto',
    },
    height: {
      type: [String, Number],
      default: 'auto',
    },
  },
  computed: {
    /**
     * style 속성 동적 셋
     * @return {Object}
     */
    style() {
      return {
        display: 'flex', 
        maxHeight: this.toString(this.height),
      };
    },
  },
  methods: {
    /**
     * props type 동적 변환
     * @params {String, Number} params
     */
    toString(params) {
      if (params === 'auto') return '100%';
      if (typeof params === 'number') return `${params}px`;
      return params;
    },
  },
};
</script>
