var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "popup",
    {
      attrs: { title: _vm.title, popSize: "size-850" },
      on: {
        close: function($event) {
          return _vm.$emit("close")
        }
      }
    },
    [
      _c(
        "data-table",
        { attrs: { tblId: "tblInfo01" } },
        [
          _c("template", { slot: "tableInfo" }, [
            _vm._v(
              " " +
                _vm._s(_vm._f("i18n")("label.mdlTypCd")) +
                ", " +
                _vm._s(_vm._f("i18n")("label.stckTgtLvlCd")) +
                ", " +
                _vm._s(_vm._f("i18n")("label.upTypCd")) +
                ", " +
                _vm._s(_vm._f("i18n")("label.stckTgtCd")) +
                ", " +
                _vm._s(_vm._f("i18n")("label.trmMdlCd")) +
                ", " +
                _vm._s(_vm._f("i18n")("label.mvnoMdlCd")) +
                ", " +
                _vm._s(_vm._f("i18n")("label.attchFile")) +
                " " +
                _vm._s(_vm._f("i18n")("label.rgt")) +
                " or " +
                _vm._s(_vm._f("i18n")("label.change")) +
                " "
            )
          ]),
          _c("template", { slot: "caption" }, [_vm._v(_vm._s(_vm.title))]),
          _c("template", { slot: "colgroup" }, [
            _c("col", { staticStyle: { width: "150px" } }),
            _c("col", { staticStyle: { width: "auto" } }),
            _c("col", { staticStyle: { width: "150px" } }),
            _c("col", { staticStyle: { width: "auto" } })
          ]),
          _c("template", { slot: "tbody" }, [
            _c("tr", [
              _c("th", { attrs: { scope: "row" } }, [
                _c(
                  "label",
                  {
                    staticClass: "required",
                    attrs: { for: "stckBatUpVOMdlTypCd" }
                  },
                  [
                    _c("span", { staticClass: "star" }, [
                      _vm._v(
                        _vm._s(_vm._f("i18n")("label.require")) +
                          _vm._s(_vm._f("i18n")("label.input"))
                      )
                    ]),
                    _vm._v(" " + _vm._s(_vm._f("i18n")("label.mdlTypCd")) + " ")
                  ]
                )
              ]),
              _c(
                "td",
                [
                  _c("code-selector", {
                    key: _vm.key,
                    ref: "stckBatUpVOMdlTypCd",
                    attrs: {
                      id: "stckBatUpVOMdlTypCd",
                      cdList: _vm.cdList.mdlTypCdList,
                      isAll: false,
                      isSel: true,
                      disabled: _vm.stckBatUpVO.status === "U"
                    },
                    on: {
                      change: function($event) {
                        return _vm.fnMdlTypCdChange($event.target.value)
                      }
                    },
                    model: {
                      value: _vm.stckBatUpVO.mdlTypCd,
                      callback: function($$v) {
                        _vm.$set(_vm.stckBatUpVO, "mdlTypCd", $$v)
                      },
                      expression: "stckBatUpVO.mdlTypCd"
                    }
                  })
                ],
                1
              ),
              _c("th", { attrs: { scope: "row" } }, [
                _c(
                  "label",
                  {
                    staticClass: "required",
                    attrs: { for: "stckBatUpVOStckTgtLvlCd" }
                  },
                  [
                    _c("span", { staticClass: "star" }, [
                      _vm._v(
                        _vm._s(_vm._f("i18n")("label.require")) +
                          _vm._s(_vm._f("i18n")("label.input"))
                      )
                    ]),
                    _vm._v(
                      " " + _vm._s(_vm._f("i18n")("label.stckTgtLvlCd")) + " "
                    )
                  ]
                )
              ]),
              _c(
                "td",
                [
                  _c("code-selector", {
                    ref: "stckBatUpVOStckTgtLvlCd",
                    attrs: {
                      id: "stckBatUpVOStckTgtLvlCd",
                      cdList: _vm.cdList.stckTgtLvlCdList,
                      isAll: false,
                      isSel: true
                    },
                    model: {
                      value: _vm.stckBatUpVO.stckTgtLvlCd,
                      callback: function($$v) {
                        _vm.$set(_vm.stckBatUpVO, "stckTgtLvlCd", $$v)
                      },
                      expression: "stckBatUpVO.stckTgtLvlCd"
                    }
                  })
                ],
                1
              )
            ]),
            _c("tr", [
              _c("th", { attrs: { scope: "row" } }, [
                _c(
                  "label",
                  {
                    staticClass: "required",
                    attrs: { for: "stckBatUpVOUpTypCd" }
                  },
                  [
                    _c("span", { staticClass: "star" }, [
                      _vm._v(
                        _vm._s(_vm._f("i18n")("label.require")) +
                          _vm._s(_vm._f("i18n")("label.input"))
                      )
                    ]),
                    _vm._v(" " + _vm._s(_vm._f("i18n")("label.upTypCd")) + " ")
                  ]
                )
              ]),
              _c(
                "td",
                [
                  _c("code-selector", {
                    key: _vm.key,
                    ref: "stckBatUpVOUpTypCd",
                    attrs: {
                      id: "stckBatUpVOUpTypCd",
                      cdList: _vm.cdList.upTypCdList,
                      isAll: false,
                      isSel: true
                    },
                    on: {
                      change: function($event) {
                        return _vm.fnUpTypCdChange($event.target.value)
                      }
                    },
                    model: {
                      value: _vm.stckBatUpVO.upTypCd,
                      callback: function($$v) {
                        _vm.$set(_vm.stckBatUpVO, "upTypCd", $$v)
                      },
                      expression: "stckBatUpVO.upTypCd"
                    }
                  })
                ],
                1
              ),
              _c("th", { attrs: { scope: "row" } }, [
                _c(
                  "label",
                  {
                    staticClass: "required",
                    attrs: { for: "stckBatUpVOStckTgtCd" }
                  },
                  [
                    _c("span", { staticClass: "star" }, [
                      _vm._v(
                        _vm._s(_vm._f("i18n")("label.require")) +
                          _vm._s(_vm._f("i18n")("label.input"))
                      )
                    ]),
                    _vm._v(
                      " " + _vm._s(_vm._f("i18n")("label.stckTgtCd")) + " "
                    )
                  ]
                )
              ]),
              _c(
                "td",
                [
                  _c("search-input-text", {
                    ref: "stckBatUpVOStckTgtCd",
                    attrs: {
                      id: "stckBatUpVOStckTgtCd",
                      type: "text",
                      disabled: false,
                      placeholder: _vm.i18n("label.stckTgtCd")
                    },
                    on: { fnSearch: _vm.validateShowCmnInfPopup },
                    model: {
                      value: _vm.stckBatUpVO.stckTgtCd,
                      callback: function($$v) {
                        _vm.$set(_vm.stckBatUpVO, "stckTgtCd", $$v)
                      },
                      expression: "stckBatUpVO.stckTgtCd"
                    }
                  })
                ],
                1
              )
            ]),
            _c(
              "tr",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.stckBatUpVO.upTypCd == "11",
                    expression: "stckBatUpVO.upTypCd=='11'"
                  }
                ]
              },
              [
                _c("th", { attrs: { scope: "row" } }, [
                  _c(
                    "label",
                    {
                      staticClass: "required",
                      attrs: { for: "searchTrmMdlCd" }
                    },
                    [
                      _c("span", { staticClass: "star" }, [
                        _vm._v(
                          _vm._s(_vm._f("i18n")("label.require")) +
                            _vm._s(_vm._f("i18n")("label.input"))
                        )
                      ]),
                      _vm._v(
                        " " + _vm._s(_vm._f("i18n")("label.trmMdlCd")) + " "
                      )
                    ]
                  )
                ]),
                _c(
                  "td",
                  { attrs: { colspan: "3" } },
                  [
                    _c("biz-selector", {
                      key: _vm.key,
                      attrs: {
                        id: "searchTrmMdlCd",
                        list: _vm.trmMdlCdList,
                        isAll: false,
                        isSel: true
                      },
                      on: {
                        change: function($event) {
                          return _vm.searchTrmMdlOne($event.target.value)
                        }
                      },
                      model: {
                        value: _vm.stckBatUpVO.trmMdlCd,
                        callback: function($$v) {
                          _vm.$set(_vm.stckBatUpVO, "trmMdlCd", $$v)
                        },
                        expression: "stckBatUpVO.trmMdlCd"
                      }
                    })
                  ],
                  1
                )
              ]
            ),
            _c(
              "tr",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.stckBatUpVO.upTypCd == "21",
                    expression: "stckBatUpVO.upTypCd=='21'"
                  }
                ]
              },
              [
                _c("th", { attrs: { scope: "row" } }, [
                  _c(
                    "label",
                    {
                      staticClass: "required",
                      attrs: { for: "searchMvnoMdlCd" }
                    },
                    [
                      _c("span", { staticClass: "star" }, [
                        _vm._v(
                          _vm._s(_vm._f("i18n")("label.require")) +
                            _vm._s(_vm._f("i18n")("label.input"))
                        )
                      ]),
                      _vm._v(
                        " " + _vm._s(_vm._f("i18n")("label.mvnoMdlCd")) + " "
                      )
                    ]
                  )
                ]),
                _c(
                  "td",
                  { attrs: { colspan: "3" } },
                  [
                    _c("biz-selector", {
                      key: _vm.key,
                      attrs: {
                        id: "searchMvnoMdlCd",
                        list: _vm.mvnoMdlCdList,
                        isAll: false,
                        isSel: true
                      },
                      model: {
                        value: _vm.stckBatUpVO.mvnoMdlCd,
                        callback: function($$v) {
                          _vm.$set(_vm.stckBatUpVO, "mvnoMdlCd", $$v)
                        },
                        expression: "stckBatUpVO.mvnoMdlCd"
                      }
                    })
                  ],
                  1
                )
              ]
            ),
            _c("tr", [
              _c("th", { attrs: { scope: "row" } }, [
                _c("label", { attrs: { for: "stckBatUpVOAtchFileFkId" } }, [
                  _vm._v(_vm._s(_vm._f("i18n")("label.attchFile")))
                ])
              ]),
              _c("td", { attrs: { colspan: "3" } }, [
                _c("div", { staticClass: "file-wrap" }, [
                  _c(
                    "a",
                    {
                      staticClass: "excel-down",
                      attrs: { href: this.excelTempUrl },
                      on: {
                        click: function($event) {
                          return _vm.fnDoNotLogout($event)
                        }
                      }
                    },
                    [
                      _c("img", {
                        attrs: {
                          src: require("@/assets/img/btn-icon-download02.png"),
                          alt: ""
                        }
                      }),
                      _vm._v(
                        " " +
                          _vm._s(_vm._f("i18n")("label.excelTempDown")) +
                          " "
                      )
                    ]
                  ),
                  _c(
                    "button",
                    {
                      staticClass: "file-box",
                      attrs: { type: "button" },
                      on: {
                        click: function($event) {
                          $event.stopPropagation()
                          return _vm.excelFileAdd($event)
                        }
                      }
                    },
                    [
                      _c("input", {
                        ref: "excelFile",
                        attrs: {
                          id: "excelFile",
                          type: "file",
                          accept: _vm.excelUpload.acceptExtention,
                          title: "i18n('label.attchFile')"
                        },
                        on: {
                          change: function($event) {
                            $event.stopPropagation()
                            return _vm.uploadExcelFile($event)
                          }
                        }
                      }),
                      _vm._v(
                        " " +
                          _vm._s(_vm._f("i18n")("label.file")) +
                          _vm._s(_vm._f("i18n")("label.search")) +
                          " "
                      )
                    ]
                  )
                ])
              ])
            ])
          ])
        ],
        2
      ),
      _vm.isShowCmnAgncyInfPopup
        ? _c("cmn-agncy-inf-popup", {
            attrs: { paramData: _vm.agncyDistrSetParam() },
            on: {
              close: _vm.closeCmnAgncyInfPopup,
              fnRowDoubleClick: _vm.fnCmnAgncyInfPopupDoubleClick
            }
          })
        : _vm._e(),
      _vm.isShowCmnDistrInfPopup
        ? _c("cmn-distr-inf-popup", {
            attrs: { paramData: _vm.agncyDistrSetParam() },
            on: {
              close: _vm.closeCmnDistrInfPopup,
              fnRowDoubleClick: _vm.fnCmnDistrInfPopupDoubleClick
            }
          })
        : _vm._e(),
      _c(
        "template",
        { slot: "buttons" },
        [
          _c("primary-button", { on: { click: _vm.validate } }, [
            _vm._v(_vm._s(_vm._f("i18n")("button.reg")))
          ]),
          _c(
            "default-button",
            {
              on: {
                click: function($event) {
                  return _vm.$emit("close")
                }
              }
            },
            [_vm._v(_vm._s(_vm._f("i18n")("button.cancel")))]
          )
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }