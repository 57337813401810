/**
 * 1. 개요 : 공통 컴포넌트
 * 2. 처리내용 : 공통 컴포넌트 - PrimaryButton
 * @File Name : PrimaryButton.vue
 * @date : 2020. 02. 20. 09:46:00
 * @author : 이상철
 * @history :
 *-----------------------------------------------------------------------
 * 변경일			          		  작성자		변경내용
 *-----------------------------------------------------------------------
 * 2020. 2. 20. 09:46:00	    이상철	  최초 작성
 *-----------------------------------------------------------------------
 */
<script>
import DefaultButton from './DefaultButton';

export default {
  name: 'PrimaryButton',
  extends: DefaultButton,
  data() {
    return {
      classes: 'primary',
    };
  },
};
</script>
