/**
 * 1. 개요 : 그룹ID검색
 * 2. 처리내용 : 그룹ID검색 Mixins - 화면 모델
 * @File Name : CmnMerGrpIdPopupViewModel.js
 * @date : 2022. 12. 02.  10:08:00
 * @author : 이진우
 * @history :
 *-----------------------------------------------------------------------
 * 변경일			          		    작성자		변경내용
 *-----------------------------------------------------------------------
 * 2022. 12. 02.  10:08:00	    이진우	  최초 작성
 *-----------------------------------------------------------------------
 */

export default {
  methods: {
    CmnMerGrpIdPopupViewModel(params = {}) {
      return [
        {
          field: "rnum",
          headerName: "",
          type: Number,
          width: 50,
          isPk: false,
          editable: false,
          pinned: false,
          cellStyle: {
            textAlign: "center"
          },
          cellClassRules: {
            "selected-cell": param =>
              params && param.rowIndex === params.rowIndex
          },
          valueFormatter : (param) => {
            // 내림차순
            return this.gridProps.totalItems - (((this.currPage - 1) * this.pageUnit) + param.node.rowIndex);
          }, 
        },
        {
          field: "merGrpId",
          headerName: this.i18n('label.grp')+this.i18n('label.id'),
          type: String,
          width: 120,
          isPk: true,
          editable: false,
          pinned: false,
          suppressMenu: false,
          sortable: true,
          cellStyle: {
            textAlign: "center"
          },
          cellClassRules: {},
        },
        {
          field: "merGrpNm",
          headerName: this.i18n('label.grpNm'),
          type: String,
          width: 250,
          isPk: false,
          editable: false,
          pinned: false,
          suppressMenu: false,
          sortable: true,
          cellStyle: {
            textAlign: "left"
          },
          cellClassRules: {}
        },
        {
          field: "dscr",
          headerName: this.i18n('label.desc'),
          type: String,
          width: 352,
          isPk: false,
          editable: false,
          pinned: false,
          suppressMenu: false,
          sortable: true,
          cellStyle: {
            textAlign: "left"
          },
          cellClassRules: {}
        },
      ];
    }
  }
};
