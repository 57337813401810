export default [
  {
    name: "TrmMdlMng",
    path: "/biz/bep/trm/TrmMdlMng",
    component: () => import("@/views/biz/bep/trm/TrmMdlMng"),
    meta: { layout: "admin" }
  },
  {
    name: "MvnoMdlMng",
    path: "/biz/bep/trm/MvnoMdlMng",
    component: () => import("@/views/biz/bep/trm/MvnoMdlMng"),
    meta: { layout: "admin" }
  },
  {
    name: "SuplMdlMng",
    path: "/biz/bep/trm/SuplMdlMng",
    component: () => import("@/views/biz/bep/trm/SuplMdlMng"),
    meta: { layout: "admin" }
  },
  {
    name: "TrmSnMng",
    path: "/biz/bep/trm/TrmSnMng",
    component: () => import("@/views/biz/bep/trm/TrmSnMng"),
    meta: { layout: "admin" }
  },
  {
    name: "OrdPrcMng",
    path: "/biz/bep/trm/OrdPrcMng",
    component: () => import("@/views/biz/bep/trm/OrdPrcMng"),
    meta: { layout: "admin" }
  },
  {
    name: "MvnoMng",
    path: "/biz/bep/trm/MvnoMng",
    component: () => import("@/views/biz/bep/trm/MvnoMng"),
    meta: { layout: "admin" }
  },
  {
    name: "StckMng",
    path: "/biz/bep/trm/StckMng",
    component: () => import("@/views/biz/bep/trm/StckMng"),
    meta: { layout: "admin" }
  },
  {
    name: "TrmInfMng",
    path: "/biz/bep/trm/TrmInfMng",
    component: () => import("@/views/biz/bep/trm/TrmInfMng"),
    meta: { layout: "admin" }
  },
  {
    name: "MerPtMng",
    path: "/biz/bep/merinst/MerPtMng",
    component: () => import("@/views/biz/bep/merinst/MerPtMng"),
    meta: { layout: "admin" }
  },
  {
    name: "OrdMng",
    path: "/biz/bep/trm/OrdMng",
    component: () => import("@/views/biz/bep/trm/OrdMng"),
    meta: { layout: "admin" }
  },
  {
    name: "TrmIfcMng",
    path: "/biz/bep/trm/TrmIfcMng",
    component: () => import("@/views/biz/bep/trm/TrmIfcMng"),
    meta: { layout: "admin" }
  },
  {
    name: "CorpMng",
    path: "/biz/bep/trm/CorpMng",
    component: () => import("@/views/biz/bep/trm/CorpMng"),
    meta: { layout: "admin" }
  },
  {
    name: "ApkInfMng",
    path: "/biz/bep/trm/ApkInfMng",
    component: () => import("@/views/biz/bep/trm/ApkInfMng"),
    meta: { layout: "admin" }
  },
  {
    name: "PromoInfMng",
    path: "/biz/bep/trm/PromoInfMng",
    component: () => import("@/views/biz/bep/trm/PromoInfMng"),
    meta: { layout: "admin" }
  },
  {
    name: "TmsDwMng",
    path: "/biz/bep/trm/TmsDwMng",
    component: () => import("@/views/biz/bep/trm/TmsDwMng"),
    meta: { layout: "admin" }
  },
  {
    name: "CardBinSubMng",
    path: "/biz/bep/merinst/CardBinSubMng",
    component: () => import("@/views/biz/bep/merinst/CardBinSubMng"),
    meta: { layout: "admin" }
  },
  {
    name: "PrftLssCdMng",
    path: "/biz/bep/sttl/PrftLssCdMng",
    component: () => import("@/views/biz/bep/sttl/PrftLssCdMng"),
    meta: { layout: "admin" }
  },
  {
    name: "PrftLssBasDatMng",
    path: "/biz/bep/sttl/PrftLssBasDatMng",
    component: () => import("@/views/biz/bep/sttl/PrftLssBasDatMng"),
    meta: { layout: "admin" }
  },
  {
    name: "PrftLssMng",
    path: "/biz/bep/sttl/PrftLssMng",
    component: () => import("@/views/biz/bep/sttl/PrftLssMng"),
    meta: { layout: "admin" }
  },
  {
    name: "ApprMng",
    path: "/biz/bep/sttl/ApprMng",
    component: () => import("@/views/biz/bep/sttl/ApprMng"),
    meta: { layout: "admin" }
  },
  // 모바일 - 소모품출고요청
  {
    name: "MSupRelReqMng",
    path: "/m/biz/MSupRelReqMng",
    component: () => import("@/views/mobile/biz/bep/MSupRelReqMng"),
    meta: { layout: "mobile-admin" }
  },
  // 모바일 - 단말기조회
  {
    name: "MTrmInfSearch",
    path: "/m/biz/MTrmInfSearch",
    component: () => import("@/views/mobile/biz/bep/MTrmInfSearch"),
    meta: { layout: "mobile-admin" }
  },
  // 모바일 - 가맹점조회
  {
    name: "MMerInfSearch",
    path: "/m/biz/MMerInfSearch",
    component: () => import("@/views/mobile/biz/bep/MMerInfSearch"),
    meta: { layout: "mobile-admin" }
  }
];
