/**
 * 1. 개요 : 사업자관리
 * 2. 처리내용 : 사업자관리 - 팝업창 태그 컴포넌트(PHB)
 * @File Name : Phb.vue
 * @date : 2020. 02. 20. 09:46:00
 * @author : 이상철
 * @history :
 *-----------------------------------------------------------------------
 * 변경일			          		  작성자		변경내용
 *-----------------------------------------------------------------------
 * 2020. 2. 20. 09:46:00	    이상철	  최초 작성
 *-----------------------------------------------------------------------
 */

<template>
  <div class="tab-content">
		<div class="search-wrap">
			<!-- 기간 조회 추가 -->
			<collapse-search-pane :isSearchPaneShow="isSearchPaneShow" @fnShowSearchPane="fnShowSearchPane">
				<div class="search-area">
					<div id="tblInfo01" class="hide">
						{{ 'label.trDt' | i18n }}
					&nbsp;{{ 'label.search' | i18n }}
					</div>
			<div class="tbl-wrap">
    		<table aria-describedby="tblInfo01">
					<caption>{{ 'label.searchArea' | i18n }}</caption>
					<colgroup>
						<col style="width:150px">
						<col style="width:262px">
						<col style="width:150px">
						<col style="width:262px">
						<col style="width:150px">
						<col style="width:262px">
						<col style="width:150px">
						<col style="width:auto">
					</colgroup>
					<tbody>
						<tr>
							<th scope="row">
								<label>{{ 'label.qnaDt' | i18n }}</label>
							</th>
							<td colspan="3">
								<date-range-and-input-date
									:key="updateKey"
									@mergeDateRangeIntoSearchObject="mergeDateRangeIntoSearchObject"
									ref="dateRange"
									:propsServerDate="serverDate"
									:propsServerTime="serverTime"
									:propsSearch="search"
									:hideInputTime="true"
								/>
							</td>
						</tr>
					</tbody>
    		</table>
			</div>
		</div>
	</collapse-search-pane>
			<!-- 기간 조회 끝 -->
			<div class="search-btn">
				<button type="button" name="searchList" @click.stop="searchValidate">{{'button.look' | i18n }}</button>
				<default-button @click="clearSearch">{{'button.init'|i18n}}</default-button>
				<button type="button" name="showMyPagePopupComplaintSavePopup" @click="showSavePopup()">{{ 'button.reg' | i18n }}</button>
			</div>
		</div>
		<div>
			<!-- 확장 보기 table -->
			<data-table class="tbl003" tblId="tbl003">
				<template slot="tbody" style="text-align:center;">
						<tr class="MyPgComplaintTblHeader">
							<th scope="row"></th>
							<th scope="row" colspan="3">{{'label.myComplaints'|i18n}}</th>
							<th scope="row">{{'label.qnaDt'|i18n}}</th>
							<th scope="row">{{'label.bbsSt'|i18n}}</th>
						</tr>
							<tr v-for="(item,index) in this.resultList" :key="index">
										<td class="MyPgComplaintTd" v-show="item.contents == null" ><input type="image" ref="inputIcon" name="toggle" value="open" @click="clickTitleShowContents(index, item)" :src='addBtn' alt=""/></td>
										<td class="MyPgComplaintTd" v-if="item.contents == null" colspan="3" @click="clickTitleShowContents(index, item, $event)" style="cursor:pointer;">{{item.title}}</td>
										<td class="MyPgComplaintTd MyPgComplaintTdCenter" v-if="item.contents == null">{{i18nDtFormat(item.acptDtm)}}</td>
										<td class="MyPgComplaintTd MyPgComplaintTdCenter" v-if="item.contents == null">{{bbsStCd(item.bbsStCd)}}</td>
										<td v-if="item.contents != null" colspan="6">
											<data-table class="MyPageComplaintTbl004" tblId="tbl004" v-if="show">
												<template slot="tableInfo">
													{{ 'label.title' | i18n }}, {{ 'label.contents' | i18n }}, {{ 'label.readCnt' | i18n }}, {{ 'label.attchFile' | i18n }}, {{ 'label.reply' | i18n }}
													&nbsp;
													{{ 'label.rgt' | i18n }} or
													{{ 'label.change' | i18n }}
												</template>
												<template slot="colgroup">
													<col style="width:150px" />
													<col style="width:auto" />
													<col style="width:150px" />
													<col style="width:auto" />
												</template>
												<template class="MyPageComplaintTbl004Body" slot="tbody">
													<!-- 답변 없을 경우(수정) -->
													<tr v-if="item.bbsStCd ==='00'&&item.status==='U'">
														<td colspan="5">
															<input-text v-model="complaintMngVO.title" style="width:100%;"/>
														</td>
													</tr>
													<tr  v-if="item.bbsStCd ==='00'&&item.status==='U'">
														<td colspan="5">
																<div :style="{height:height}">
																<input-text-editor v-show="item.bbsStCd==='00'" ref="complaintMngVOContents1" id="complaintMngVOContents"
																style="width:100%;" :height="height" @getHtmlFromEditor="getHtmlFromEditor"/>
																</div>
														</td>
													</tr>
													<!-- 답변 있을 경우(조회) -->
												<tr v-if="complaintMngVO.bbsStCd ==='10'">
													<th rowspan="3" class="MyPgComplaintPaddingTh">
														<img src="~@/assets/img/button/icon_q.gif"/>
													</th>
													<td colspan="1" style="overflow:auto;" class="MyPgComplaintPaddingTdTitle">
															<div>{{complaintMngVO.title}}</div>
													</td>
													<td colspan="3" class="MyPgComplaintDate">
														{{i18nDtFormat(complaintMngVO.acptDtm)}}
													</td>
												</tr>
												<tr v-if="complaintMngVO.bbsStCd ==='10'">
													<td colspan="4" class="MyPgComplaintPaddingTdContents">
														<div>
															<div style="min-height:100px; max-height:150px; overflow-y:auto;" v-html="decodeContents"/>
														</div>
													</td>
												</tr>
												<tr v-if="complaintMngVO.bbsStCd ==='10'">
													<td colspan="4">
														<div class="file-list">
															<span class="file-selected" v-for="file in fileUpload.fileList" :key="file.fileSeqNo" @click="downloadFile(file)">
																<a href="javascript:;" :title="file.orginlFileNm">
																{{ file.orginlFileNm }}
																</a>
															</span>
														</div>
													</td>
												</tr>
												<!-- 답변 -->
										<template class="replyDiv" v-for="(reply,index) in replyDataList" >
												<tr  :key="replyDataList[index].bbsId">
													<th rowspan="3" class="MyPgComplaintPaddingTh">
														<img src="~@/assets/img/button/icon_a.gif"/>
													</th><!-- v-if="reply.bbsStCd==='10' && reply.contents!=undefined" -->
												<td colspan="1"  style="overflow:auto;" class="MyPgComplaintPaddingTdTitle">
													{{reply.title}}
												</td>
												<td colspan="3" class="MyPgComplaintDate">
													{{i18nDtFormat(reply.hndlDtm)}}
												</td>
											</tr>
											<tr :key="replyDataList[index].bbsTypId"> <!-- v-if="reply.bbsStCd==='10' && reply.contents!=undefined" -->
												<td colspan="4"  class="MyPgComplaintPaddingTdContents">
													<div>
														<div style="min-height:100px; max-height:150px; overflow-y:auto;" v-html="getReplyContents(reply.contents)"/>
													</div>
												</td>
											</tr>
											<tr :key="replyDataList[index].atchFileFkId"><!-- v-if="reply.bbsStCd==='10'" -->
												<td colspan="4">
													<div class="file-list">
															<span class="file-selected" v-for="file in reply.atchFileList" :key="file.fileSeqNo" @click="downloadFile(file)">
																<a href="javascript:;" :title="file.orginlFileNm">
																{{ file.orginl_file_nm }}
																</a>
															</span>
														</div>
												</td>
											</tr>
											</template>
											<!--확장 보기 파일첨부(질문) -->
											<tr>
											<td colspan="4">
													<div class="file-wrap">
													<button type="button" @click.stop="fileAdd1"  class="file-box" v-show="complaintMngVO.status==='U' && complaintMngVO.bbsStCd=='00' ">
														<input id="file1" ref="file1" type="file" multiple :accept="fileUpload.acceptExtention" @change.stop="uploadFile1" title="i18n('label.attchfile')">
														{{ 'label.file' | i18n }}{{ 'label.search' | i18n }}
													</button>
														<div class="file-list">
															<span class="file-selected" v-for="file in fileUpload.fileList" :key="file.fileSeqNo" @click="downloadFile(file)" v-show="complaintMngVO.status==='U' && complaintMngVO.bbsStCd=='00' ">
																<a href="javascript:;" :title="file.orginlFileNm">
																	{{ file.orginlFileNm }}
																</a>
															</span>
														</div>
													</div>
												</td>
												<!-- 확장 보기 파일첨부 끝 -->
												<td style="text-align:right;">
														<primary-button v-if="item.bbsStCd ==='00'" @click="validate">{{'button.update'|i18n}}</primary-button>
														<primary-button v-if="item.bbsStCd ==='10'" @click="addAsk($event, index)">{{'label.addQuestion'|i18n}}</primary-button>
														<default-button v-if="item.bbsStCd ==='00'" @click="validateForDel(item.bbsId)">{{'button.delete'|i18n}}</default-button>
												</td>
										</tr>
											<tr v-show="add1">
												<td colspan="5">
													<div :style="{height:height}">
													<input-text-editor ref="complaintMngVOContents2" id="complaintMngVOContents"
														style="width:100%;" :height="height" @getHtmlFromEditor="getHtmlFromEditor1"/>
													</div>
												</td>
											</tr>
											<tr v-show="add1">
												<!-- 추가 문의 파일 첨부 -->
												<td colspan="4">
													<div class="file-wrap">
													<button type="button" @click.stop="orgFileAdd"  class="file-box">
														<input id="orgFile" ref="orgFile" type="file" multiple :accept="fileUpload.acceptExtention" @change.stop="uploadOrgFile" title="i18n('label.attchfile')">
														{{ 'label.file' | i18n }}{{ 'label.search' | i18n }}
													</button>
														<div class="file-list">
															<span class="file-selected" v-for="file in fileUpload.orgFileList" :key="file.fileSeqNo" @click="downloadFile(file)">
																<a href="javascript:;" :title="file.orginlFileNm">
																	{{ file.orginlFileNm }}
																</a>
															</span>
														</div>
													</div>
												</td>
												<!-- 추가 문의 파일 첨부 끝 -->
												<td style="text-align:right;">
													<primary-button @click="validate">{{'button.save'|i18n}}</primary-button>
												</td>
											</tr>
										</template>
									</data-table>
								</td>
							</tr>
							<tr v-if="this.resultList.length==0" class="noArticleTr">
								<td colspan="6" class="MyPgComplaintNoArticleTd">
								 {{'label.noComplaint' | i18n}}
								</td>
							</tr>

				</template>
			</data-table>
						<pagination-for-table
						v-if="this.totalCount>this.pageUnit"
						:totalItems="gridProps.totalItems"
						:initPage="gridProps.initPage"
						:perPage="pageSize"
						@fnListFromPaginationForTable="fnListFromPaginationForTable"
						/>
			</div>

<div v-if="complaintMngVO.status === 'I'">
	<data-table tblId="tblInfo001">
		<template slot="tableInfo">
				{{ 'label.title' | i18n }}, {{ 'label.contents' | i18n }}, {{ 'label.readCnt' | i18n }}, {{ 'label.attchFile' | i18n }}, {{ 'label.reply' | i18n }}
				&nbsp;
				{{ 'label.rgt' | i18n }} or
				{{ 'label.change' | i18n }}
			</template>
			<template slot="colgroup">
				<col style="width:150px" />
				<col style="width:auto" />
				<col style="width:150px" />
				<col style="width:auto" />
			</template>
			<template slot="tbody">
				<tr>
					<th scope="row">
						<label class="required" for="complaintMngVOTitle">
							<span class="star">{{ 'label.require'| i18n }}{{ 'label.input'| i18n }}</span>
							{{ 'label.title' | i18n }}
						</label>
					</th>
					<td colspan="3">
						<label for ="complaintMngVOTitle" v-if="complaintMngVO.status === 'U' && complaintMngVO.bbsStCd =='10'">{{complaintMngVO.title}}</label>
						<input-text
							v-else
							id="complaintMngVOTitle"
							ref="complaintMngVOTitle"
							v-model="complaintMngVO.title"
							type="text"
							:placeholder="i18n('label.title')"
							:maxlength="500"
							 style="width:100%;"
						/>
					</td>
				</tr>
				<!-- 원래 코드 -->
				<tr>
					<th scope="row">
						<label class="required" for="complaintMngVOContents">
							<span class="star">{{ 'label.require'| i18n }}{{ 'label.input'| i18n }}</span>
							{{ 'label.contents' | i18n }}
						</label>
					</th>
					 <td colspan="3">
						<div style="min-height:100px; max-height:150px; overflow-y:auto;"  v-if="complaintMngVO.status === 'U' && complaintMngVO.bbsStCd =='10'" v-html="decodeContents"/>
						<div :style="{height:height}" v-else>
							<input-text-editor ref="complaintMngVOContents" id="complaintMngVOContents"
							style="width:100%;" :height="height" @getHtmlFromEditor="getHtmlFromEditor"/>
						</div>
					</td>
				</tr>
				<tr>
					<th scope="row">
						<label for="complaintMngVOAtchFileFkId">{{ 'label.attchFile' | i18n }}</label>
					</th>
					<!-- 등록 파일 첨부 -->
          <td colspan="2">
							<div class="file-wrap">
								<button type="button" @click.stop="fileAdd" class="file-box" v-if="complaintMngVO.status==='I' || complaintMngVO.status==='U' && complaintMngVO.bbsStCd=='00' ">
									<input id="file" ref="file" type="file" multiple :accept="fileUpload.acceptExtention" @change.stop="uploadFile" title="i18n('label.attchfile')">
									{{ 'label.file' | i18n }}{{ 'label.search' | i18n }}
								</button>
								<div class="file-list">
									<span class="file-selected" v-for="file in fileUpload.fileList" :key="file.fileSeqNo" @click="downloadFile(file)">
										<a href="javascript:;" :title="file.orginlFileNm">
											{{ file.orginlFileNm }}
										</a>
										<button type="button" v-if="userInfo.userId === complaintMngVO.acptUserId" @click.stop="deleteFile(file)">{{ 'label.file' | i18n }}{{ 'label.del' | i18n }}</button>
									</span>
								</div>
							</div>
          </td>
					<!-- 등록 파일 첨부 끝 -->
						<td style="text-align:right;">
								<primary-button @click="validate">{{button|i18n}}</primary-button>
								<default-button @click="clearSearch">{{ 'button.cancel' | i18n }}</default-button>
						</td>
				</tr>
				<!-- 답변 작성 추가 -->
				<tr v-if="complaintMngVO.status === 'U'">
					<th scope="row">
						<label for="complaintMngVORplyContents">{{ 'label.reply' | i18n }}</label>
					</th>
					<td colspan="3" style="overflow:auto;">
						<div style="min-height:100px; max-height:150px; overflow-y:auto;" v-html="decodeReplyContents"/>
					</td>
				</tr>
				<!-- 첨부파일 -->
				 <tr  v-if="complaintMngVO.status === 'U'">
					<th scope="row">
						<label for="complaintMngVORplyAtchFileFkId">{{ 'label.attchFile' | i18n }}</label>
					</th>
          <td>
							<div class="file-wrap">
								<!-- <button type="button" @click.stop="fileAdd" class="file-box">
									<input id="file" ref="file" type="file" multiple :accept="fileUpload.acceptExtention" @change.stop="uploadFile" title="i18n('label.attchfile')">
									{{ 'label.file' | i18n }}{{ 'label.search' | i18n }}
								</button> -->
								<div class="file-list">
									<span class="file-selected" v-for="file in fileUpload.rplyFileList" :key="file.fileSeqNo" @click="downloadFile(file)">
										<a href="javascript:;" :title="file.orginlFileNm">
											{{ file.orginlFileNm }}
										</a>
										<button type="button" @click.stop="deleteFile(file)">{{ 'label.file' | i18n }}{{ 'label.del' | i18n }}</button>
									</span>
								</div>
							</div>
						</td>
				</tr>
			</template>
	</data-table>
</div>
  </div>
</template>
<script>
import { isEmpty, decodeToHtml } from "@/utils";
import ScrollPane from '@/layout/admin/components/TagsView/ScrollPane';
import Popup from "@/components/Popup";
import { EventBus } from "./EventBus";
import { CmnMyPageComplaintViewModel,CodeFetchHelper,Helper, AnimationHelper,ComplaintMngSaveModel,ComplaintFileUpload } from "./mixins";
import { GlobalValidationSet } from "./mixins/Helper"
import DataGrid from "@/components/DataGrid";
import FileDownRenderer from "./mixins/FileDownRenderer";
import CollapseSearchPane from "@/components/CollapseSearchPane";
import DataTable from "@/components/DataTable";
import PaginationForTable from "@/components/PaginationForTable";
import moment from 'moment';
export default {
	name: "MyPagePopupComplaint",
	components: {
		Popup,
		ScrollPane,
		DataGrid,
		CollapseSearchPane,
		DataTable,
		PaginationForTable
	},
	mixins: [CmnMyPageComplaintViewModel,CodeFetchHelper,Helper, AnimationHelper, ComplaintMngSaveModel, ComplaintFileUpload],
	props: {
    paramData: {
      type: Object,
      default: () => {}
		}
	},

	data() {
		return {
			listYn: false,
			listIndex: "",
			resultList: [],
			selectedData:[],
			currPage: 1,
      pageUnit: 10,
			pageSize: 10,
			totalCount: 0,
			show:true,
			height:"150PX",
			serverDate: '',
			serverTime: '',
			updateKey: 0,
			search: {
				title: "",
				bbsStCd: "",
				orderBy: "",
				bbsTypCd: "",
				tranStaDt: "",
				tranEndDt: "",
			},
			add1: false,
			removeBtn: require('@/assets/img/button/ic_remove_black_18dp.png'),
			addBtn: require('@/assets/img/button/ic_add_black_18dp.png'),
			//VO 추가
			replyList: [],
			btnArr: [],
			replyDataList: [],
			indexNo:"",
			complaintMngVO: {
				status: "U",
				bbsTypCd: "ASK",
				bbsTypId: "",
				parBbsId: "",
				title: "",
				contents: "",
				bbsStCd: "",
				dispStaDt: "",
				dispEndDt: "",
				readCnt: "",
				acptUserId: "",
				acptDtm: "",
				hndlUserId: "",
				hndlDtm: "",
				atchFileFkId: "",
				exposeTgt: "YYYYYY",
				addedBy: "",
				addedDate: "",
				addedNm: "",
				modifyBy: "",
				modifyDate: "",
				modifyNm: "",
				blockYn: "",
			},
			orgComplaintMngVO: {
				status: "",
				bbsTypCd: "ASK",
				bbsTypId: "",
				parBbsId: "",
				title: "",
				contents: "",
				bbsStCd: "",
				dispStaDt: "",
				dispEndDt: "",
				readCnt: "",
				acptUserId: "",
				acptDtm: "",
				hndlUserId: "",
				hndlDtm: "",
				atchFileFkId: "",
				exposeTgt: "YYYYYY",
				addedBy: "",
				addedDate: "",
				addedNm: "",
				modifyBy: "",
				modifyDate: "",
				modifyNm: "",
				blockYn: ""
			},
			complaintReplyMngVO: {
				status: "",
				bbsTypCd: "ASK",
				bbsTypId: "",
				parBbsId: "",
				title: "",
				contents: "",
				bbsStCd: "",
				dispStaDt: "",
				dispEndDt: "",
				readCnt: "",
				acptUserId: "",
				acptDtm: "",
				hndlUserId: "",
				hndlDtm: "",
				atchFileFkId: "",
				exposeTgt: "YYYYYY",
				addedBy: "",
				addedDate: "",
				addedNm: "",
				modifyBy: "",
				modifyDate: "",
				modifyNm: "",
				blockYn: "",
			},
			//VO추가 끝
			isSearchPaneShow: true,
			 gridProps: {
        totalItems: 0,
        columnDefs: [],
        rowData: [],
        // 일반적인 row 선택 or 더블클릭시
        selectedRows: [],
        initPage: true,
        editable: true,
        gridHeight: 360,
        rowClassRules: null,
        frameworkComponents: null,
        rowSelection: null,
        // 로우 선택시 선택이 되도록 하려면 false, 체크박스 or 라디오 선택시 선택이 되오도록 하려면 true
        suppressRowClickSelection: false,
        topBtnList: []
      },
      // 일반적인 row 선택 or 더블클릭시
      selectedRow: null,
      // 체크박스 또는 라디오 row 선택시
      selectedCheckOrRadioRows: [],
			isShowMyPagePopupComplaintSavePopup: false,
			isPaSearchPaneShow: true,
    };
	},
	created() {
		this.fnGetAxcCodeGrpList(["BBS_ST_CD"])
	},
	 watch: {
    // row 더블 클릭 했을 경우 다시 호출
    selectedRow: {
      handler(params) {
        this.setColumnDefs(params);
        this.setRowClassRules();
      }
    }
  },
  beforeMount() {
    // 그리드 초기화
    this.setColumnDefs();

    // row 클래스 동적 적용
    this.setRowClassRules();

    // 그리드의 frameworkComponents 셋(필요한 경우에만)
    this.setFrameworkComponents();

    // 그리드 상단의 버튼 리스트 정의
    this.setTopBtnList();

    // 그리드의 rowSelection 셋
		this.setRowSelection();


	},
	//추가
	computed: {
		/**
		* 로그인 한 유저 정보 겟
		* @return {Object}
		*/
		userInfo() {
      return this.$store.getters['Options/userInfo'];
		},
		/**
		* 특수문자가 포함된 문자열을 html 코드로 변환. (공통 함수)
		* @return {String}
		*/
		decodeContents() {
			if(this.complaintMngVO.contents!= undefined){
				return decodeToHtml(this.complaintMngVO.contents)
			} else {
				return '';
			}
		},
		/**
		* 특수문자가 포함된 문자열을 html 코드로 변환. (공통 함수)
		* @return {String}
		*/
		decodeReplyContents(){
			if(this.complaintReplyMngVO.contents== undefined){
				return decodeToHtml('');
			} else {
				return decodeToHtml(this.complaintReplyMngVO.contents)
			}
		},
		/**
		 * 버튼
     */
		button() {
			return isEmpty(this.selectedData) ?
				this.i18n("label.reg")
			: this.i18n("label.update");
		},
	},
	created(){
		this.getServerTime();
	},
	//추가 끝
	mounted() {

		this.searchList();
		// 등록팝업 호출시 팝업 열기
		EventBus.$on(
			"showMyPagePopupComplaintSavePopup",
			payload => {
				this.selectedRow = null
				this.gridProps.selectedRows = []
				this.isShowMyPagePopupComplaintSavePopup = payload
			}
		);
		this.fnGetAxcCodeGrpList();

		// this._animationWithSearchPane(EventBus, this)
		// 수정일 경우 단건 조회
			// this.searchOne();

			// 수정일 경우 답변이 있는지 확인한다.
			// this.searchReplyList();
	},


  // Pop-up 소명 시에 처리
  beforeDestroy() {
		// 이벤트 버스 리스너 해제
		EventBus.$off("showMyPagePopupComplaintSavePopup");

	},
	methods: {
				getReplyContents(contents){
			return decodeToHtml(contents);
		},
		/**
		 * 서버타임 겟
		 */
		getServerTime() {
			this.$httpPost(
				"/api/com/getCurrentServerTime.do",
				{},
				{ showLoading: false }
			)
				.then(response => {
					this.serverDate = moment(response.data.result)
														.format("YYYYMMDD");
					this.serverTime = moment(response.data.result)
              							.subtract("hours")
														.format("HH:mm:ss") // 세 나라 동일 포멧
														.slice(0, 2)

					// search 초기화 (최초 조회: 서버시간 기준으로 한달 데이터)
					this.search.tranStaTm="00";
          this.search.tranEndTm= this.serverTime;
          this.search.tranStaDt= moment(this.serverDate).subtract(1, "months").format("YYYYMMDD");
					this.search.tranEndDt= moment(this.serverDate).format("YYYYMMDD");
					this.updateKey = this.updateKey + 1;
				})
				.then(() => {
					this.searchList();
				})
				.catch(this.commonErrorHandler);
    },
		/**
		 * date-Range-And-Input-Date 컴포넌트에서 onChange 감지 => this.search와 병합
		 * params {Object}
		 */
		mergeDateRangeIntoSearchObject(params){
			this.search = params;
			//this.search = {...this.search, ...params}
		},
		/**
		 * 게시판 상태 구분
		 * @params {Number} key
     */
		bbsStCd(key){
			for (let i = 0; i < this.bbsStCdList.length; i++) {
				if (this.bbsStCdList[i].codeId === key) {
					return this.bbsStCdList[i].codeName;
				}
			}
		},
		/**
     * 현재 메뉴에 대한 권한 정보 겟
     * @return {Object}
     */
		// getAuthor() {
    //   return this.$store.getters['Options/userInfo'].menuAuthDetailList.find(a => a.menuNm === this.$route.name)
		// },
		/**
     * 선택한 게시물의 답글 리스트 조회
     * @params {Object} item
     */
		async searchReplyList(item) {
			if(item.bbsStCd == '10'){
			this.$httpPost(
					"/api/bep/getComplaintMngList.do",
					{
						searchBbsTypCd: "ASK",
						searchParBbsId: item.bbsId,
					},
					{
						showLoading: true
					}
				)
				.then(response => {
					if(isEmpty(response.data.resultList[0])){
						this.complaintReplyMngVO = { ...response.data.resultList[0], status: "I" };
					} else {
						this.complaintReplyMngVO = { ...response.data.resultList[0], status: "U" };
					}
					!isEmpty(this.complaintReplyMngVO.atchFileFkId != undefined) && this.getRplyFileList(this.complaintReplyMngVO.atchFileFkId);

				})
				.then(()=>{
					// 텍스트 에디터 셋
					if(!isEmpty(this.complaintReplyMngVO.contents)){
						this.$nextTick(() => {
							this.$refs.complaintMngVORplyContents &&
							this.$refs.complaintMngVORplyContents.setText(decodeToHtml(this.complaintReplyMngVO.contents));
						})
					}
				})
				.catch(this.commonErrorHandler);
			}
		},
		/**
		 * pagination-for-table에서 페이지 변환시 데이터 조회 함수
		 * @params {Number} currPage
     */
		fnListFromPaginationForTable(currPage){
			this.currPage = currPage;
			this.searchList();
			this.gridProps.initPage = false;
		},
		/**
		 * 단건 조회
		 * @params {Number} bbsId
     */
		async searchOne(bbsId) {
			this.$httpPost(
					"/api/bep/getComplaintMng.do",
					{
						searchBbsid: bbsId,
					},
					{
						showLoading: true
					}
				)
				.then(response => {
					// this.orgComplaintMngVO = { ...response.data.resultList[0], status: "U" };
					this.complaintMngVO = { ...response.data.resultList[0], status: "U" };
					!isEmpty(this.complaintMngVO.atchFileFkId != undefined) && this.getFileList(this.complaintMngVO.atchFileFkId);

				})
				.then(() => {
					// 텍스트 에디터 셋
					// if(!isEmpty(this.complaintMngVO.contents)){
					// 	this.$nextTick(() => {
					// 		this.$refs.complaintMngVOContents &&
					// 		this.$refs.complaintMngVOContents.setText(decodeToHtml(this.complaintMngVO.contents));
					// 	})
					// }
				})
				.catch(this.commonErrorHandler);
		},
		/**
     * 단건 입력(생성/변경)
     */
		save() {
			if(this.orgComplaintMngVO.title.length>0){
				this.complaintMngVO = this.orgComplaintMngVO;
			}
			const saveData = this.complaintMngVO;
			//  수정한 데이터가 없는 경우 리턴
			if (isEmpty(saveData)) {
				this.alert({
					type: "warning",
					title: this.i18n("label.confirm"),
					message: this.i18n("alt.noRevise")
				});
				return;
			}

			const complaintMngVOList = [saveData];

			this.$httpPost(
					"/api/bep/saveComplaintMngList.do",
					{ complaintMngVOList },
					{ showLoading: true }
				)
				.then(res =>
					this.alert({
						type: 'warning',
						title: this.i18n("label.confirm"),
						message: this.i18n("label.save")+this.i18n("alt.complete"),
						buttons: [{label:this.i18n("button.confirm")}]
					})
				)
				.then(() => this.clearSearch())
				.catch(this.commonErrorHandler);
		},
		/**
		 * 추가문의하기 제목과 내용 세팅
		 * @params {Event} e
		 * @params {Number} index
     */
		addAsk(e, index){
			this.add1 = true;
			//complaintMngVOContents2
			var str = "<br>---------------------------------------<br>"+this.complaintMngVO.contents+"<br>";
			var title2 = "[RERE]"+this.complaintMngVO.title;
			this.orgComplaintMngVO.title = title2;
			this.orgComplaintMngVO.status = 'I';
			this.orgComplaintMngVO.bbsStCd = '00';
			if(this.orgComplaintMngVO.bbsStCd==='00'){
				this.$nextTick(() => {
					this.$refs.complaintMngVOContents2[0] &&
					this.$refs.complaintMngVOContents2[0].setText(decodeToHtml(str));
				})
			}
		},
		/**
     * 입력폼 초기화
     */
		clear() {
			this.currPage = 1;
			this.listIndex = "";
			this.listYn = false;
			this.complaintMngVO.status = "";
			this.complaintMngVO.bbsTypCd = "ASK";
			this.complaintMngVO.bbsTypId = "";
			this.complaintMngVO.parBbsId = "";
			this.complaintMngVO.title = "";
			this.complaintMngVO.contents = "";
			this.complaintMngVO.bbsStCd = "00";					// 00: 접수완료, 10: 답변완료
			this.complaintMngVO.dispStaDt = "";
			this.complaintMngVO.dispEndDt = "";
			this.complaintMngVO.readCnt = "";
			this.complaintMngVO.acptUserId = "";
			this.complaintMngVO.acptDtm = "";
			this.complaintMngVO.hndlUserId = "";
			this.complaintMngVO.hndlDtm = "";
			this.complaintMngVO.atchFileFkId = "";
			this.complaintMngVO.exposeTgt = "YYYYYY";

			this.orgComplaintMngVO.status = "";
			this.orgComplaintMngVO.bbsTypCd = "ASK";
			this.orgComplaintMngVO.bbsTypId = "";
			this.orgComplaintMngVO.parBbsId = "";
			this.orgComplaintMngVO.title = "";
			this.orgComplaintMngVO.contents = "";
			this.orgComplaintMngVO.bbsStCd = "00";			// 00: 접수완료, 10: 답변완료
			this.orgComplaintMngVO.dispStaDt = "";
			this.orgComplaintMngVO.dispEndDt = "";
			this.orgComplaintMngVO.readCnt = "";
			this.orgComplaintMngVO.acptUserId = "";
			this.orgComplaintMngVO.acptDtm = "";
			this.orgComplaintMngVO.hndlUserId = "";
			this.orgComplaintMngVO.hndlDtm = "";
			this.orgComplaintMngVO.atchFileFkId = "";
			this.orgComplaintMngVO.exposeTgt = "YYYYYY";

			this.complaintReplyMngVO.status = "";
			this.complaintReplyMngVO.bbsTypCd = "ASK";
			this.complaintReplyMngVO.bbsTypId = "";
			this.complaintReplyMngVO.parBbsId = "";
			this.complaintReplyMngVO.title = "";
			this.complaintReplyMngVO.contents = "";
			this.complaintReplyMngVO.bbsStCd = "";			// 00: 접수완료, 10: 답변완료
			this.complaintReplyMngVO.dispStaDt = "";
			this.complaintReplyMngVO.dispEndDt = "";
			this.complaintReplyMngVO.readCnt = "";
			this.complaintReplyMngVO.acptUserId = "";
			this.complaintReplyMngVO.acptDtm = "";
			this.complaintReplyMngVO.hndlUserId = "";
			this.complaintReplyMngVO.hndlDtm = "";
			this.complaintReplyMngVO.atchFileFkId = "";
			this.complaintReplyMngVO.exposeTgt = "YYYYYY";
			this.search.title= "";
			this.search.bbsStCd= "";
			this.search.orderBy= "";
			this.search.bbsTypCd= "";
			this.search.tranStaDt= "";
			this.search.tranEndDt= "";
			// 텍스트 에디터 클리어 - 이지혜 추가
			if(this.$refs.complaintMngVORplyContents!=undefined){
				this.$nextTick(() => {
					this.$refs.complaintMngVORplyContents.delText();
				})
			}
			this.add1 = false;
		},
		/**
     * 입력 폼 초기화 및 재 검색
     */
		clearSearch() {
			this.clear();
			this.getServerTime();
			// this.searchList();
		},
		/**
     * 밸리데이터
     */
		async validate() {

			const validObj = [];

			// 제목 Not Null
			validObj.push({
				rule: GlobalValidationSet.NOT_NULL,
				value: this.complaintMngVO.title,
				ref: this.$refs.complaintMngVOTitle,
				message: this.i18n("label.title")+this.i18n("alt.required")
			});
			// 제목 MAX BYTE
			validObj.push({
				rule: GlobalValidationSet.MAX_BYTE,
				value: this.complaintMngVO.title,
				ref: this.$refs.complaintMngVOTitle,
				max: 500,
				message: this.i18n("label.title")+this.i18n("alt.max")
			});
			// 본문 Not Null
			validObj.push({
				rule: GlobalValidationSet.NOT_NULL,
				value: this.complaintMngVO.contents,
				ref: this.$refs.complaintMngVOContents,
				message: this.i18n("label.contents")+this.i18n("alt.required")
			});
			// 본문 MAX BYTE
			validObj.push({
				rule: GlobalValidationSet.MAX_BYTE,
				value: this.complaintMngVO.contents,
				// ref: this.$refs.complaintMngVOContents,
				max: 4000,
				message: this.i18n("label.contents")+this.i18n("alt.max")
			});

			if (
				await this.validateHelper(validObj)
			) {
				return;
			} else {
				this.alert({
					title: this.i18n("label.save"),
					message: this.i18n("alt.questionSave"),
					buttons: [
						{
							type: "primary",
							label: this.i18n("button.confirm"),
							onPress: () => this.save()
						},
						{ label: this.i18n("button.cancel") }
					]
				});
		  }
		},
		searchValidate(){
			if(isEmpty(this.search.tranStaDt)){
				 this.alert({
          type: "warning",
          title: this.i18n("label.error"),
          message: this.i18n("label.tranStaDt")+this.i18n("alt.required"),
          buttons: [{
            label: this.i18n("label.confirm"),

          }]
				});
				return;
			}
			if(isEmpty(this.search.tranEndDt)){
				this.alert({
          type: "warning",
          title: this.i18n("label.error"),
          message: this.i18n("label.tranEndDt")+this.i18n("alt.required"),
          buttons: [{
            label: this.i18n("label.confirm"),

          }]
				});
				return;
			}
			const isStaBiggerThanToday =
        moment(this.search.tranStaDt).format("YYYYMMDD") > moment().format("YYYYMMDD");
      const isEndBiggerThanToday =
        moment(this.search.tranEndDt).format("YYYYMMDD") > moment().format("YYYYMMDD");

      if(isStaBiggerThanToday || isEndBiggerThanToday) {
         this.alert({
          type: "warning",
          title: this.i18n("label.error"),
          message: this.i18n("alt.cannotBiggerThanToday"),
          buttons: [{
            label: this.i18n("label.confirm"),
            onPress: () => {
              this.search.tranEndDt = moment().format("YYYYMMDD")
            }
          }]
        });
      } else this.searchList();
    },

		/**
		 * 에디터의 text 겟
		 * @params {Object} params
     */
		getHtmlFromEditor(params){
			this.complaintMngVO.contents = params;
		},
		/**
		 * 에디터의 text 겟
		 * @params {Object} params
     */
		getHtmlFromEditor1(params){
			this.orgComplaintMngVO.contents = params;
		},
		/**
		 * 문의글 등록하기
     */
		showSavePopup(){
			this.fileUpload.fileList = [];
			this.clearSearch();
			this.complaintMngVO.status = 'I';
		},
		/**
		 * 문의글 상세 내용 보기
		 * @params {Number} index
		 * @params {Object} item
     */
		async clickTitleShowContents(index, item){
		this.add1 = false;
		this.replyDataList =[];
		this.btnArr = [];
		this.btnArr = this.$refs.inputIcon;
		//전부 +로 만들고 index에 해당하는 img만 -로 바꿈
		for(var i=0;i<this.btnArr.length; i++){
			this.btnArr[i].src=this.addBtn;
		}

		if(this.listIndex==index){
			//같은 글을 눌렀을 때
			if(this.listYn){
			//컨텐츠 닫아야 할 때
			console.log("컨텐츠 닫아야 할 때")
			this.listYn = false;
			this.listIndex=index;
			if(this.resultList[index+1].contents !=null){
					this.resultList.splice(index+1,1);
			}
		} else {
			// 컨텐츠 열어야 할 때
			 console.log("컨텐츠 열어야 할 때")
			this.listYn = true;
			this.btnArr[index].src = this.removeBtn;
			this.listIndex=index;
			for(var i=0;i<this.resultList.length;i++){
					if(this.resultList[i].contents !=null){
						this.resultList.splice(i,1);
						if(i<index){
						index=index-1;
					}
				}
			}
			await this.getContents(index, item);
			}
		} else {
				//다른 글을 눌렀을 때
				console.log("다른 글을 눌렀을 때");
				for(var i=0;i<this.resultList.length;i++){
						if(this.resultList[i].contents !=null){
							this.resultList.splice(i,1);
							if(i<index){
							index=index-1;
						}
					}
				}
				this.listYn = true;
				this.btnArr[index].src = this.removeBtn;
				this.listIndex=index;
				await this.getContents(index, item);
			}
		},
		/**
		 * 문의글 contents와 댓글 contents 가져오기
		 * @params {Number} index
		 * @params {Object} item
     */
		async getContents(index, item){
			await this.$httpPost(
						"/api/bep/getComplaintMng.do",
						{
							searchBbsid: item.bbsId,
						},
						{
							showLoading: true
						}
					)
					.then(response => {
						this.complaintMngVO = { ...response.data.resultList[0], status: "U" };
						!isEmpty(this.complaintMngVO.atchFileFkId != undefined) && this.getFileList(this.complaintMngVO.atchFileFkId);
					})
					.then(()=>{
						this.getReplyDataList(index, item);
					})

				.catch(this.commonErrorHandler);
		},
		async getReplyDataList(index, item){
			await	this.$httpPost(
					"/api/bep/getComplaintMngList.do",
						{
							searchBbsTypCd: "ASK",
							searchParBbsId: item.bbsId,
						},
						{
							showLoading: true
						}
					)
					.then(response => {
						if(isEmpty(response.data.resultList[0])){
							this.complaintReplyMngVO = { ...response.data.resultList[0], status: "I" };
						} else {
							this.complaintReplyMngVO = { ...response.data.resultList[0], status: "U" };
							this.replyDataList = response.data.resultList.map(a => ({
								...a,
								atchFileList : a.file_info == null ? [] : a.file_info,
								status: "R"
							}));
							console.log(this.replyDataList);
						}
						!isEmpty(this.complaintReplyMngVO.atchFileFkId != undefined) && this.getRplyFileList(this.complaintReplyMngVO.atchFileFkId);
					})
					.then(() => {
						this.resultList.splice(index+1,0,this.complaintMngVO)
					})
					.then(()=>{
						// 텍스트 에디터 셋
						if(this.complaintReplyMngVO.contents==undefined){
							this.$nextTick(() => {
								this.$refs.complaintMngVOContents1[0] &&
								this.$refs.complaintMngVOContents1[0].setText(decodeToHtml(this.complaintMngVO.contents));
							})
						}
					})
					.catch(this.commonErrorHandler);
		},
		/**
		 * 삭제 밸리데이터
		 * @params {Number} bbsId
     */
		validateForDel(bbsId){
			this.alert({
					title: this.i18n("label.del"),
					message: this.i18n("label.del")+this.i18n("alt.question"),
					buttons: [
						{
							type: "primary",
							label: this.i18n("button.confirm"),
							onPress: () => this.delOne(bbsId)
						},
						{
							label: this.i18n("button.cancel")
						}
					]
			});
		},
		/**
		 * 삭제
		 * @params {Number} bbsId
     */
		delOne(bbsId){
				const complaintMngVOList = [{
					bbsId: bbsId,
					status: 'D'
				}]

				this.$httpPost(
					"/api/bep/saveComplaintMngList.do",
					{ complaintMngVOList },
					{ showLoading: true }
				)
				.then(res =>
					this.alert({
						type: 'warning',
						title: this.i18n("label.confirm"),
						message: this.i18n("label.del")+this.i18n("alt.complete"),
						buttons: [{label:this.i18n("button.confirm")}]
					})
			)
			.then(() => this.clearSearch())
			.catch(this.commonErrorHandler);
		},
		/**
		 * 민원 목록 조회
		 * @params {Object} search
     */
		searchList(search) {
			this.selectedRow = null;
			this.gridProps.selectedRows = [];
			this.gridProps.initPage = true;

			if( !isEmpty(this.search.acptDtm)) {
				this.search.acptDtm = this.search.acptDtm.replace(/[-.]/g, '');
			}
			this.resultList = [];
			this.gridProps.rowData = [];

			this.$httpPost(
					"/api/bep/getComplaintMngList.do",
					{
						searchTitle: this.search.title,
						searchBbsStCd: this.search.bbsStCd,
						searchBbsTypCd: "ASK",
						searchAcptDtm: this.search.acptDtm,
						searchParBbsId: 0,		// 부모 ID 값이 0 인게 최상위 이기 때문..
						searchAcptUserId: this.$store.getters['Options/userInfo'].userId,
						currPage: this.currPage,
						pageUnit: this.pageUnit,
						pageSize: this.pageSize,
						searchToAcptDtm: !isEmpty(this.search.tranEndDt)?this.search.tranEndDt:"",
						searchFromAcptDtm: !isEmpty(this.search.tranStaDt)?this.search.tranStaDt:""
					},
					{
						showLoading: true
					}
				)
				.then(response => {
					// result 셋
					this.resultList = response.data.resultList;
					// 데이터 총 갯수 셋
					this.gridProps.totalItems = response.data.totalCount;
					this.totalCount = response.data.totalCount;
				})
				.then(() => {
					// 그리드에 조회해온 데이터 셋
					if (this.resultList)
					  this.gridProps.rowData = this.resultList.map(a => ({
								...a,
								status: "R"
						}))
				})
				.catch(this.commonErrorHandler);
		},
		/**
     * 업무 코드 리스트 페치
		 * @params {Array} searchCodeGrpIdList
     */
		async fnGetAxcCodeGrpList(searchCodeGrpIdList = []) {
			if (isEmpty(searchCodeGrpIdList)) return;

			await this.$httpPost(
					"/api/bep/getAxcCodeGroupListByCodeGrpIdList.do",
					{ searchCodeGrpIdList },
					{ showLoading: true }
				)
				.then(response => {
					this.bbsStCdList = response.data.resultList.find(a => a[0].codeGrpId === "BBS_ST_CD");
				})
				.catch(this.commonErrorHandler);

			this.key = this.key + 1;
		},
		/**
		* 페이지 변환시 데이터 조회 함수
		* @params {Number}currPage
		*/
    fnList(currPage) {
      this.currPage = currPage;
      this.searchList();
      this.gridProps.initPage = false;
    },
    /**
		 * 더블 클릭시 팝업 열기 함수
     * @params {Object} selectedRow
		 */
		fnShowPupop(selectedRow) {

			this.selectedRow = null
			this.gridProps.selectedRows = []
			this.selectedRow = selectedRow;
			this.gridProps.selectedRows = [{ ...selectedRow.data, status: "U" }];
			this.selectedData =[{ ...selectedRow.data, status: "U" }];

			this.clear();
			this.searchOne();
			this.searchReplyList();
		},
    /**
		 * 그리드에 하나의 row 선택 함수
		 * @params {Object} params
		 */
    setSelectionChangedRow(params) {
      this.gridProps.selectedRows = [];
      this.gridProps.selectedRows = [
        { ...params.api.getSelectedRows()[0], status: "U" }
      ];
    },
    /**
		 * 그리드의 columnDefs 셋
		 * @params {Object} params
		 */
    setColumnDefs(params = {}) {
      this.gridProps.columnDefs = this.cmnMyPageComplaintViewModel(params).map(
        a => ({
          headerName: a.headerName,
          field: a.field,
          width: a.width,
          editable: a.editable,
          pinned: a.pinned,
          cellStyle: a.cellStyle,
          cellClassRules: a.cellClassRules,
          valueFormatter: a.valueFormatter,
          cellRenderer: a.cellRenderer,
          checkboxSelection: a.checkboxSelection,
          headerCheckboxSelection: a.headerCheckboxSelection,
          suppressMenu: a.suppressMenu,
          sortable: a.sortable,
          hide: a.hide
        })
      );
    },
		/**
		 * 그리드 상단의 버튼 리스트 정의
		*/
    setTopBtnList() {},
    /**
		 * 그리드의 rowClass 셋
		*/
    setRowClassRules() {
      this.gridProps.rowClassRules = {
        // 'selected-row': param => this.selectedRow && (this.selectedRow.rowIndex === param.rowIndex)
      };
    },
   	/**
		 * 그리드의 frameworkComponents 셋 (필요한 경우에만)
		*/
		setFrameworkComponents() {
			this.gridProps.frameworkComponents = {
				FileDownRenderer
			}
		},
    /**
		 * 그리드의 하나의 rowSelection 셋
		*/
    setRowSelection() {
      // 단수 선택 경우 'single', 복수 선택일 경우 'multiple'
      this.gridProps.rowSelection = "single";
    },
    /**
		 * 셀렉트박스 또는 라디오 선택시
		 * @params {Object} params
		*/
    setRowSelected(params) {
      if (params.node.isSelected()) {
        this.selectedCheckOrRadioRows.push(params.data);
      } else {
        this.selectedCheckOrRadioRows.pop(params.data);
      }
		},
		/**
		 * 등록팝업 열기 함수
		*/
		showMyPagePopupComplaintSavePopup() {
			EventBus.$emit("showMyPagePopupComplaintSavePopup", true);
		},
		/**
		 * 팝업 닫기 함수
		 * @params {Object} params
		*/
		close(params) {
			this.isShowMyPagePopupComplaintSavePopup = false;
			this.gridProps.selectedRows = [];
			this.selectedRow = null;

			if( !isEmpty(params) && !isEmpty(params.refresh) && params.refresh ) {
			if( !isEmpty(params.currPage) ) {
				this.currPage = params.currPage;
				this.gridProps.initPage = true;
			}
				this.searchList();
			}
		},
	}
};
</script>
<style lang="scss">
</style>
<style lang="scss" scoped>

@import "@/scss/_button.scss";
$scroll-color:#bcd5ed;
.pc{
  .tab-content{
		margin-top:25px;
		.cont{
			overflow: hidden;
			overflow-y: auto;
			min-height:100px;
			// max-height:450px;
			&::-webkit-scrollbar{
				&:horizontal{
					border-top-color:transparent;
				}
				&:vertical{
					border-left-color:transparent;
				}
			}
		}
	}
		.search-wrap{
		margin-top:50px;
	}
}
.MyPgComplaintTblHeader th{
	border: 1px solid #bcd6ed;
	border-top: 0;
	border-bottom: 0;
	border-right: 0;
	height: 30px !important;
	font-size: 12px !important;
	text-align: center;
}
.MyPgComplaintTblHeader th:last-of-type{
	border: 1px solid #bcd6ed;
	border-top: 0;
	border-bottom: 0;
	height: 30px !important;
	font-size: 12px !important;
}
.MyPgComplaintTblHeader th:first-of-type{
	width: 41px !important;
}
.MyPgComplaintTd{
	border: 1px solid #bcd6ed;
	border-top: 0;
	border-right: 0;
	height: 30px !important;
	font-size: 12px !important;
}
.MyPgComplaintTd:last-of-type{
	border: 1px solid #bcd6ed;
	border-top: 0;
	height: 30px !important;
	font-size: 12px !important;
}
.MyPageComplaintTbl004{
	border-top: none;
	background-color: #F6F6F6;
}
.MyPageComplaintTbl004 tr{
	border: none;
}
.MyPageComplaintTbl004 tr td{
	border: none;
}
.MyPageComplaintTbl004 table tbody tr{
	padding-left: 30px;
}
.quill-editor{
	background-color: white;
}
.MyPgComplaintToggleBtn{
	background-color: white !important;
}
.MyPgComplaintPaddingTdTitle{
	padding:20px 10px;
	border: none;
	font-weight: bold;
}
.MyPgComplaintPaddingTh{
	border: none;
	background-color: #F6F6F6;
	vertical-align: top;
	padding: 20px 10px;
}
.MyPgComplaintPaddingTh img{
	background-color: #F6F6F6;
	float: right;
}
.MyPgComplaintDate{
	font-size: 11px;
}
.MyPgComplaintPaddingTdContents{
	padding-right: 50px;
}
.MyPgComplaintNoArticleTd{
	border-top: 1px solid #bcd6ed;
	border-left: 1px solid #bcd6ed;
	border-right: 1px solid #bcd6ed;
	text-align: center;
	height: 150px;
}
.MyPgComplaintTdCenter{
	text-align: center;
}
</style>