/**
 * 1. 개요 : Copyright
 * 2. 처리내용 : Copyright - 진입점
 * @File Name : index.vue
 * @date : 2020. 02. 20. 09:46:00
 * @author : 이상철
 * @history :
 *-----------------------------------------------------------------------
 * 변경일			          		  작성자		변경내용
 *-----------------------------------------------------------------------
 * 2020. 2. 20. 09:46:00	    이상철	  최초 작성
 *-----------------------------------------------------------------------
 */
<template>
  <div class="copyright">
		<p>COPYRIGHT {{ toYear }} Alliex, ALL RIGHTS RESERVED</p>
		<!-- case : 모바일로 PC 화면 본 경우에만 show / PC에서 볼 경우 button은 hide -->
		<!-- CONFIG FOR PCIDSS -->
		<router-link v-if="this.userAuth.userId != 'pcidss_office' 
						&& this.userAuth.userId != 'pcidss_merchant' 
						&& this.userAuth.userId != 'pcidss_agency' 
						&& this.userAuth.userId != 'pcidss_bank'
						&& this.userAuth.userId != 'pcidss_office1' 
						&& this.userAuth.userId != 'pcidss_merchant1' 
						&& this.userAuth.userId != 'pcidss_agency1' 
						&& this.userAuth.userId != 'pcidss_bank1'" to="/m/dashboard"><button type="button" @click="chgDevice">Mobile version</button></router-link>
	</div>
</template>
<script>
import moment from 'moment'

export default {
	name: 'Copyright',
	data() {
		return {
			toYear: ""
		}
	},
	created() {
		this.userAuth = this.$store.getters["Options/userInfo"];
	},
	mounted() {
		this.toYear = moment().format("YYYY")
	},
	methods: {
		chgDevice() {
			this.$store.dispatch("Sidebar/closeSideBar", { withoutAnimation: false });
			this.setDeviceCd('M')
		}
	}
};
</script>

<style lang="scss" scoped>
@import "@/scss/_color.scss";
@import "@/scss/_button.scss";

.copyright {
	width: 100%;
	height: 30px;
	padding:5px 27px;
	color:$c9;
	letter-spacing:-0.3px;
	> p{
		display: inline-block;
		margin-top:1px;
		vertical-align: middle;
	}
	button{
		height:20px !important;
		min-height:20px !important;
		margin-left:7px;
		padding:4px 10px 2px !important;
		font-size:11px;
		letter-spacing:0;
	}
}
</style>
