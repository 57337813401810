/**
 * 1. 개요 : 대리점정보검색
 * 2. 처리내용 : 대리점정보검색 - 팝업창 컴포넌트
 * @File Name : index.vue
 * @date : 2020. 04. 08. 15:00:00
 * @author : 이현우
 * @history :
 *-----------------------------------------------------------------------
 * 변경일			          		  작성자		변경내용
 *-----------------------------------------------------------------------
 * 2020. 04. 08. 15:00:00	    이현우	  최초 작성
 *-----------------------------------------------------------------------
 */
<template>
  <popup :title="title" @close="$emit('close')" popSize="size-grid">
    <div class="search-wrap">
      <collapse-search-pane
        :isSearchPaneShow="isPaSearchPaneShow"
        @fnShowSearchPane="fnShowPaSearchPane"
      >
        <div class="search-area">
          <div id="tblInfo" class="hide">
              {{ 'label.agncy' | i18n }}{{ 'label.cd' | i18n }}, 
              {{ 'label.agncy' | i18n }}{{ 'label.nm' | i18n }}, 
              {{ 'label.par' | i18n }}{{ 'label.distr' | i18n }}{{ 'label.cd' | i18n }}
              &nbsp;{{ 'label.search' | i18n }}
          </div>
          <div class="tbl-wrap">
            <table aria-describedby="tblInfo">
              <caption>{{ 'label.searchArea' | i18n }}</caption>
              <colgroup>
                <col style="width:150px" />
                <col style="width:auto" />
                <col style="width:150px" />
                <col style="width:auto" />
              </colgroup>
              <tbody>
                <tr>
                  <th scope="row">
                    <label for="searchAgncyCd">{{ 'label.agncy' | i18n }}{{ 'label.cd' | i18n }}</label>
                  </th>
                  <td>
                    <input-text
                      id="searchAgncyCd"
                      v-model="search.agncyCd"
                      type="text"
                      name="searchAgncyCd"
                      @keydown.enter="searchList"
                     
                    />
                  </td>
                  <th scope="row">
                    <label for="searchAgncyNm">{{ 'label.agncy' | i18n }}{{ 'label.nm' | i18n }}</label>
                  </th>
                  <td>
                    <input-text
                      id="searchAgncyNm"
                      v-model="search.agncyNm"
                      type="text"
                      name="searchAgncyNm"
                      @keydown.enter="searchList"
                      
                    />
                  </td>
                </tr>
                <tr>
                  <th scope="row">
                    <label
                      for="searchParDistrCd"
                    >{{ 'label.par' | i18n }}{{ 'label.distr' | i18n }}{{ 'label.cd' | i18n }}</label>
                  </th>
                  <td colspan="3">
                    <input-text
                      id="searchParDistrCd"
                      v-model="search.parDistrCd"
                      type="text"
                      name="searchParDistrCd"
                      @keydown.enter="searchList"
                      
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </collapse-search-pane>
      <div class="search-btn">
        <button type="button" name="searchList" @click.stop="searchList">{{'button.look' | i18n }}</button>
        <button type="button" name="clear" @click.stop="clear">{{'button.init' | i18n }}</button>
      </div>
    </div>
    <div>
      <data-grid
        ref="dataGrid1"
        :totalItems="gridProps.totalItems"
        :columnDefs="gridProps.columnDefs"
        :rowData="gridProps.rowData"
        :initPage="gridProps.initPage"
        :perPage="pageSize"
        :selectedRows="gridProps.selectedRows"
        :gridHeight="gridProps.gridHeight"
        :rowClassRules="gridProps.rowClassRules"
        :frameworkComponents="gridProps.frameworkComponents"
        :rowSelection="gridProps.rowSelection"
        :topBtnList="gridProps.topBtnList"
        :suppressRowClickSelection="gridProps.suppressRowClickSelection"
        @rowSelected="setRowSelected"
        @selectionChanged="setSelectionChangedRow"
        @callOnRowDoubleClicked="fnRowDoubleClick"
        @fnList="fnList"
      />
    </div>
  </popup>
</template>

<script>
import Popup from "@/components/Popup";
import CollapseSearchPane from "@/components/CollapseSearchPane";
import { InputText } from "@/components/Inputs";

import { EventBus } from "./EventBus";
import { CmnAgncyInfPopupViewModel, Helper, AnimationHelper } from "./mixins";
import { isEmpty } from "@/utils";
import DataGrid from "@/components/DataGrid";

export default {
  name: "CmnAgncyInfPopup",
  components: {
    Popup,
    CollapseSearchPane,
    InputText,
    DataGrid
  },
  mixins: [CmnAgncyInfPopupViewModel, Helper, AnimationHelper],
  props: {
    paramData: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      resultList: [],
      currPage: 1,
      pageUnit: 100,
      pageSize: 100,
      search: {
        agncyCd: "",
        agncyNm: "",
        parDistrCd: ""
      },

      gridProps: {
        totalItems: 0,
        columnDefs: [],
        rowData: [],
        // 일반적인 row 선택 or 더블클릭시
        selectedRows: [],
        initPage: true,
        editable: true,
        gridHeight: 350,
        rowClassRules: null,
        frameworkComponents: null,
        rowSelection: null,
        // 로우 선택시 선택이 되도록 하려면 false, 체크박스 or 라디오 선택시 선택이 되오도록 하려면 true
        suppressRowClickSelection: false,
        topBtnList: []
      },
      // 일반적인 row 선택 or 더블클릭시
      selectedRow: null,
      // 체크박스 또는 라디오 row 선택시
      selectedCheckOrRadioRows: [],
      isPaSearchPaneShow: true,
      paramDisabled: false
    };
  },
  computed: {
    /**
     * 화면 title 설정
     * @return {String}
     */
    title() {
      return (
        this.i18n("label.agncy") +
        this.i18n("label.inf") +
        this.i18n("label.search")
      );
    }
  },
  watch: {
    // row 더블 클릭 했을 경우 다시 호출
    selectedRow: {
      handler(params) {
        this.setColumnDefs(params);
        this.setRowClassRules();
      }
    }
  },
  beforeMount() {
    // 그리드 초기화
    this.setColumnDefs();

    // row 클래스 동적 적용
    this.setRowClassRules();

    // 그리드의 frameworkComponents 셋(필요한 경우에만)
    this.setFrameworkComponents();

    // 그리드의 rowSelection 셋
    this.setRowSelection();
  },
  mounted() {
    this.paramDisabled = this.paramData.disabledYn == "Y" ? true : false;
    this.search.agncyCd = this.paramData.cd;
    this.search.agncyNm = this.paramData.nm;
    this.search.parDistrCd = this.paramData.parCd;

    this.searchList();
  },
  methods: {
    /**
     * 대리점정보 목록 조회
     */
    searchList() {
      this.resultList = [];
      this.gridProps.rowData = [];

      this.currPage = 1;
      this.gridProps.initPage = true;

      this.$httpPost(
          "/api/bep/getCmnAgncyInfList.do",
          {
            searchAgncyCd: this.search.agncyCd,
            searchAgncyNm: this.search.agncyNm,
            searchParDistrCd: this.search.parDistrCd,

            currPage: this.currPage,
            pageUnit: this.pageUnit,
            pageSize: this.pageSize
          },
          {
            showLoading: true
          }
        )
        .then(response => {
          // result 셋
          this.resultList = response.data.resultList;
          // 데이터 총 갯수 셋
          this.gridProps.totalItems = response.data.totalCount;
        })
        .then(() => {
          // 그리드에 조회해온 데이터 셋
          if (this.resultList)
            this.gridProps.rowData = this.resultList.map(a => ({
              ...a,
              status: "R"
            }));
        })
        .catch(this.commonErrorHandler);
    },

    /**
     * 페이지 변환시 데이터 조회 함수
     * @params {int} currPage
     */
    fnList(currPage) {
      this.currPage = currPage;
      this.searchList();
      this.gridProps.initPage = false;
    },

    /**
     * 더블 클릭시
     * @params {Object} selectedRow
     */
    fnRowDoubleClick(selectedRow) {
      this.$emit("fnRowDoubleClick", selectedRow);
      this.$emit("close");
    },

    /**
     * 그리드에 하나의 row 선택 함수
     * @params {Object} params
     */
    setSelectionChangedRow(params) {
      this.gridProps.selectedRows = [];
      this.gridProps.selectedRows = [
        { ...params.api.getSelectedRows()[0], status: "U" }
      ];
    },

    /**
     * 그리드의 columnDefs 셋
     * @params {Object} params
     */
    setColumnDefs(params = {}) {
      this.$nextTick(() => {
        let gridWidth = this.$refs.dataGrid1.$el.clientWidth - 20;
        let totalWidth = 0;

        this.gridProps.columnDefs = this.cmnAgncyInfPopupViewModel(params).map(
          a => {
            totalWidth += a.width === 0 ? 200 : a.width;

            return {
              headerName: a.headerName,
              field: a.field,
              width:
                a.field === "restCell" && totalWidth > 0
                  ? gridWidth - totalWidth + 200
                  : a.width,
              editable: a.editable,
              pinned: a.pinned,
              cellStyle: a.cellStyle,
              cellClassRules: a.cellClassRules,
              valueFormatter: a.valueFormatter,
              cellRenderer: a.cellRenderer,
              checkboxSelection: a.checkboxSelection,
              suppressMenu: a.suppressMenu,
              sortable: a.sortable
            };
          }
        );
      });
    },

    /**
     * 그리드의 rowClass 셋
     */
    setRowClassRules() {
      this.gridProps.rowClassRules = {
        // 'selected-row': param => this.selectedRow && (this.selectedRow.rowIndex === param.rowIndex)
      };
    },

    /**
     * 그리드의 frameworkComponents 셋
     */
    setFrameworkComponents() {
      this.gridProps.frameworkComponents = {};
    },

    /**
     * 그리드의 rowSelection 셋
     */
    setRowSelection() {
      // 단수 선택 경우 'single', 복수 선택일 경우 'multiple'
      this.gridProps.rowSelection = "single";
    },

    /**
     * 셀렉트박스 또는 라디오 선택시
     * @params {Object} params
     */
    setRowSelected(params) {
      if (params.node.isSelected()) {
        this.selectedCheckOrRadioRows.push(params.data);
      } else {
        this.selectedCheckOrRadioRows.pop(params.data);
      }
    },

    /**
     * 입력 폼 초기화
     */
    clear() {
      this.search = {
        agncyCd: "",
        agncyNm: "",
        parDistrCd: ""
      };
      this.searchList();
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/scss/_color.scss";
@import "@/scss/_button.scss";
</style>


