/**
 * 1. 개요 : 개인관리
 * 2. 처리내용 : 개인관리 Mixins - 생성/수정 모델
 * PsnMngSaveModel.js
 * @date : 2020. 04. 06.  02:46:42
 * @author : 고영호
 * @history :
 *-----------------------------------------------------------------------
 * 변경일			          		    작성자		변경내용
 *-----------------------------------------------------------------------
 * 2020. 04. 06.  02:46:42	    고영호	  최초 작성
 *-----------------------------------------------------------------------
 */
export default {
  methods: {
    psnMngSaveModel(param = {}) {
      return [
        {
          field: "regPsnId",
          isPk: true,
          isNotNull: false
        },
        {
          field: "psnNm",
          isPk: false,
          isNotNull: false
        },
        {
          field: "psnEnm",
          isPk: false,
          isNotNull: false
        },
        {
          field: "psnCd",
          isPk: false,
          isNotNull: false
        },
        {
          field: "psnStatCd",
          isPk: false,
          isNotNull: false
        },
        {
          field: "regGrpTypCd",
          isPk: false,
          isNotNull: false
        },
        {
          field: "regGrpCd",
          isPk: false,
          isNotNull: false
        },
        {
          field: "repYn",
          isPk: false,
          isNotNull: false
        },
        {
          field: "dealrYn",
          isPk: false,
          isNotNull: false
        },
        {
          field: "telNo",
          isPk: false,
          isNotNull: false
        },
        {
          field: "mobileNo",
          isPk: false,
          isNotNull: false
        },
        {
          field: "faxNo",
          isPk: false,
          isNotNull: false
        },
        {
          field: "emailAddr",
          isPk: false,
          isNotNull: false
        },
        {
          field: "pno",
          isPk: false,
          isNotNull: false
        },
        {
          field: "city",
          isPk: false,
          isNotNull: false
        },
        {
          field: "dist",
          isPk: false,
          isNotNull: false
        },
        {
          field: "ward",
          isPk: false,
          isNotNull: false
        },
        {
          field: "basAddr",
          isPk: false,
          isNotNull: false
        },
        {
          field: "pidKndCd",
          isPk: false,
          isNotNull: false
        },
        {
          field: "pidNo",
          isPk: false,
          isNotNull: false
        },
        {
          field: "pidNoIssDt",
          isPk: false,
          isNotNull: false
        },
        {
          field: "pidNoIssPlc",
          isPk: false,
          isNotNull: false
        },
        {
          field: "gnd",
          isPk: false,
          isNotNull: false
        },
        {
          field: "jobPosCd",
          isPk: false,
          isNotNull: false
        },
        {
          field: "dob",
          isPk: false,
          isNotNull: false
        },
        {
          field: "enthCd",
          isPk: false,
          isNotNull: false
        },
        {
          field: "natCd",
          isPk: false,
          isNotNull: false
        },
        {
          field: "atchFileFkId",
          isPk: false,
          isNotNull: false
        },
        {
          field: "photoDat",
          isPk: true,
          isNotNull: false
        },
        {
          field: "rmk",
          isPk: false,
          isNotNull: false
        },
        {
          field: "addedBy",
          isPk: false,
          isNotNull: false
        },
        {
          field: "addedDate",
          isPk: false,
          isNotNull: false
        },
        {
          field: "modifyBy",
          isPk: false,
          isNotNull: false
        },
        {
          field: "modifyDate",
          isPk: false,
          isNotNull: false
        },
      ];
    }
  }
};
