/**
 * 1. 개요 : bep 스토어
 * 2. 처리내용 : 단말 수행 관리 상세 팝업 - 스토어
 * @File Name : TrmJobMngDetailPopupChecked.js
 * @date : 2020. 02. 20. 09:46:00
 * @author : 이상철
 * @history :
 *-----------------------------------------------------------------------
 * 변경일			          		  작성자		변경내용
 *-----------------------------------------------------------------------
 * 2020. 2. 20. 09:46:00	    이상철	  최초 작성
 *-----------------------------------------------------------------------
 */
export default {
  state: {
    trmList: []
  },
  namespaced: true,
  mutations: {
    SET_TRM_LIST(state, trm) {
      state.trmList.push(trm);
    },
    REMOVE_TRM_LIST(state, trm) {
      state.trmList = state.trmList.filter(a => a.trmId !== trm.trmId);
    },
    INITIAL_TRM_LIST(state, trm) {
      state.trmList = [];
    }
  },
  actions: {
    setTrmList({ commit }, trm) {
      commit("SET_TRM_LIST", trm);
    },
    removeTrmList({ commit }, trm) {
      commit("REMOVE_TRM_LIST", trm);
    },
    initialTrmList({ commit }, trm) {
      commit("INITIAL_TRM_LIST", trm);
    }
  },
  getters: {
    getTrmList: state => state.trmList
  }
};
