export default [
  // pc
  // 정산관리 > 정산달력
  {
    name: "SttlCaldMng",
    path: "/biz/bep/sttl/SttlCaldMng",
    component: () => import("@/views/biz/bep/sttl/SttlCaldMng"),
    meta: { layout: "admin" }
  },
  // 집계관리 > 매출달력
  {
    name: "AggCaldMng",
    path: "/biz/bep/agg/AggCaldMng",
    component: () => import("@/views/biz/bep/agg/AggCaldMng"),
    meta: { layout: "admin" }
  },


  // mobile
  // 대시보드
  {
    name: "Mdashboard",
    path: "/m/dashboard",
    component: () => import("@/views/mobile/dashboard"),
    meta: { layout: "mobile-admin" },
    hidden: true
  },
  // 정산달력
  {
    name: "MSttlCaldMng",
    path: "/m/biz/SttlCaldMng",
    component: () => import("@/views/mobile/biz/bep/MSttlCaldMng"),
    meta: { layout: "mobile-admin" }
  },
  // 매출달력
  {
    name: "MBilngCaldMng",
    path: "/m/biz/BilngCaldMng",
    component: () => import("@/views/mobile/biz/bep/MBilngCaldMng"),
    meta: { layout: "mobile-admin" }
  },
  // 공지사항
  {
    name: "MNoticeMng",
    path: "/m/biz/NoticeMng",
    component: () => import("@/views/mobile/biz/bep/MNoticeMng"),
    meta: { layout: "mobile-admin" },
    props: false
  },
  // 민원조회/등록/변경
  {
    name: "MCompltMng",
    path: "/m/biz/CompltMng",
    component: () => import("@/views/mobile/biz/bep/MCompltMng/MCompltMng"),
    meta: { layout: "mobile-admin" },
  },
  // 환율조회
  {
    name: "MExchangeMng",
    path: "/m/biz/ExchangeMng",
    component: () => import("@/views/mobile/biz/bep/MExchangeMng"),
    meta: { layout: "mobile-admin" },
  },
  // 가맹점 관리
  {
    name: "MMerMng",
    path: "/m/biz/MerMng",
    component: () => import("@/views/mobile/biz/bep/MMerMng"),
    meta: { layout: "mobile-admin" },
  },
  // 단말기출고요청
  {
    name: "MTrmRelReqMng",
    path: "/m/biz/TrmRelReqMng",
    component: () => import("@/views/mobile/biz/bep/MTrmRelReqMng"),
    meta: { layout: "mobile-admin" },
  },
   // 카드거래내역조회
   {
    name: "MCardTrStatHistMng",
    path: "/m/biz/CardTrStatHistMng",
    component: () => import("@/views/mobile/biz/bep/MCardTrStatHistMng"),
    meta: { layout: "mobile-admin" },
  },
  // 정산내역조회
   {
    name: "MSttlQryMng",
    path: "/m/biz/SttlQryMng",
    component: () => import("@/views/mobile/biz/bep/MSttlQryMng"),
    meta: { layout: "mobile-admin" },
  },

  // mobile-example
  // 조회/수정페이지(예시)
  {
    name: "MLook2",
    path: "/m/biz/Look2",
    component: () => import("@/views/mobile/biz/bep/MLook2"),
    meta: { layout: "mobile-admin" },
  },
  // Inputs(예시)
  {
    name: "MInputs",
    path: "/m/biz/Inputs",
    component: () => import("@/views/mobile/biz/bep/MInputs"),
    meta: { layout: "mobile-admin" },
  },
]