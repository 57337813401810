export default [
    {
      name: "CaldMng",
      path: "/biz/bep/svc/CaldMng",
      component: () => import("@/views/biz/bep/svc/CaldMng"),
      meta: { layout: "admin" }
    },
    {
      name: "SttlTypMng",
      path: "/biz/bep/sttl/SttlTypMng",
      component: () => import("@/views/biz/bep/sttl/SttlTypMng"),
      meta: { layout: "admin" }
    },
    {
      name: "SttlTypPermMng",
      path: "/biz/bep/sttl/SttlTypPermMng",
      component: () => import("@/views/biz/bep/sttl/SttlTypPermMng"),
      meta: { layout: "admin" }
    },
    {
      name: "SttlQryMng",
      path: "/biz/bep/sttl/SttlQryMng",
      component: () => import("@/views/biz/bep/sttl/SttlQryMng"),
      meta: { layout: "admin" }
    },
    {
      name: "BilngTypMng",
      path: "/biz/bep/bilng/BilngTypMng",
      component: () => import("@/views/biz/bep/bilng/BilngTypMng"),
      meta: { layout: "admin" }
    },
    {
      name: "BilngTypPermMng",
      path: "/biz/bep/bilng/BilngTypPermMng",
      component: () => import("@/views/biz/bep/bilng/BilngTypPermMng"),
      meta: { layout: "admin" }
    },
    {
      name: "BilngQryMng",
      path: "/biz/bep/bilng/BilngQryMng",
      component: () => import("@/views/biz/bep/bilng/BilngQryMng"),
      meta: { layout: "admin" }
    },
    {
      name: "NoticeMng",
      path: "/biz/bep/mng/NoticeMng",
      component: () => import("@/views/biz/bep/mng/NoticeMng"),
      meta: { layout: "admin" }
    },
    {
      name: "QnaMng",
      path: "/biz/bep/mng/QnaMng",
      component: () => import("@/views/biz/bep/mng/QnaMng"),
      meta: { layout: "admin" }
    },
    {
      name: "ComplaintMng",
      path: "/biz/bep/mng/ComplaintMng",
      component: () => import("@/views/biz/bep/mng/ComplaintMng"),
      meta: { layout: "admin" }
    },
    {
      name: "AdsMng",
      path: "/biz/bep/mng/AdsMng",
      component: () => import("@/views/biz/bep/mng/AdsMng"),
      meta: { layout: "admin" }
    },
    {
      name: "FileMng",
      path: "/biz/bep/svc/FileMng",
      component: () => import("@/views/biz/bep/svc/FileMng"),
      meta: { layout: "admin" }
    },
    {
      name: "TrmRespCdMng",
      path: "/biz/bep/svc/TrmRespCdMng",
      component: () => import("@/views/biz/bep/svc/TrmRespCdMng"),
      meta: { layout: "admin" }
    },
    {
      name: "TrmRespGrpMng",
      path: "/biz/bep/svc/TrmRespGrpMng",
      component: () => import("@/views/biz/bep/svc/TrmRespGrpMng"),
      meta: { layout: "admin" }
    },
    {
      name: "KeyMng",
      path: "/biz/bep/svc/KeyMng",
      component: () => import("@/views/biz/bep/svc/KeyMng"),
      meta: { layout: "admin" }
    },
    {
      name: "TrmAggMng",
      path: "/biz/bep/agg/TrmAggMng",
      component: () => import("@/views/biz/bep/agg/TrmAggMng"),
      meta: { layout: "admin" }
    },
    {
      name: "TrmBizAggMng",
      path: "/biz/bep/agg/TrmBizAggMng",
      component: () => import("@/views/biz/bep/agg/TrmBizAggMng"),
      meta: { layout: "admin" }
    },
    {
      name: "TrmMerAggMng",
      path: "/biz/bep/agg/TrmMerAggMng",
      component: () => import("@/views/biz/bep/agg/TrmMerAggMng"),
      meta: { layout: "admin" }
    },
    {
      name: "MerMng",
      path: "/biz/bep/user/MerMng",
      component: () => import("@/views/biz/bep/merinst/MerMng"),
      meta: { layout: "admin" }
    },
    { 
      name: "CovenantHistLstMng",
      path: "/biz/bep/trLst/CovenantHistLstMng",
      component: () => import("@/views/biz/bep/trLst/CovenantHistLstMng"),
      meta: { layout: "admin" }
    },
    { 
      name: "ExcelMng",
      path: "/biz/bep/mng/ExcelMng",
      component: () => import("@/views/biz/bep/mng/ExcelMng"),
      meta: { layout: "admin" }
    },
]
