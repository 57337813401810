export default  [
  {
    name: "CalTypMng",
    path: "/biz/bep/sttl/CalTypMng",
    component: () => import("@/views/biz/bep/sttl/CalTypMng"),
    meta: { layout: "admin" }
  },
  {
    name: "InstMng",
    path: "/biz/bep/merinst/InstMng",
    component: () => import("@/views/biz/bep/merinst/InstMng"),
    meta: { layout: "admin" }
  },
  {
    name: "DfnDicMng",
    path: "/biz/bep/sttl/DfnDicMng",
    component: () => import("@/views/biz/bep/sttl/DfnDicMng"),
    meta: { layout: "admin" }
  },
  {
    name: "TodoMng",
    path: "/biz/bep/mng/TodoMng",
    component: () => import("@/views/biz/bep/mng/TodoMng"),
    meta: { layout: "admin" }
  },  
  {
    name: "QuickMenuMng",
    path: "/admin/QuickMenuMng",
    component: () => import("@/views/admin/QuickMenuMng"),
    meta: { layout: "admin" }
  },  
  {
    name: "ApInfMng",
    path: "/biz/bep/trm/ApInfMng",
    component: () => import("@/views/biz/bep/trm/ApInfMng"),
    meta: { layout: "admin" }
  },  
  {
    name: "TmsInfMng",
    path: "/biz/bep/trm/TmsInfMng",
    component: () => import("@/views/biz/bep/trm/TmsInfMng"),
    meta: { layout: "admin" }
  },  
]