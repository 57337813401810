export default {
  findKeyCode(e) {
    // print-screen 이 눌렸을 경우 this.printImage 실행
    if (e.keyCode !== 44) return;
    else this.printImage();
  },
  printImage() {
    this.$nextTick(async () => {
      // 워터마크 show
      this.printKey = true;
      // html2canvas로 이미지를 추출해서 output에 담음
      const el = this.$refs.app;
      const options = { type: "dataURL" };
      let output = await this.$html2canvas(el, options);

      // 워터마크 close
      this.printKey = false;
      // base64를 blob으로 변환
      let blob = await this.b64toBlob(output);

      // blob을 클립보드에 복사
      if (navigator.clipboard != undefined) {
        // 크롬일 경우
        navigator.clipboard.write([new ClipboardItem({ [blob.type]: blob })]);
      } else if (window.clipboardData) {
        // IE일 경우
        // img 태그 안에 복사된 이미지 소스를 넣고 복사 시킴
        const urlCreator = window.URL || window.webkitURL;
        const imageUrl = urlCreator.createObjectURL(blob);
        document.querySelector("#watermark").src = imageUrl;

        const obj = document.getElementById("watermark");
        const ctr = document.body.createControlRange();

        ctr.addElement(obj);
        ctr.execCommand("copy");
      }
    });
  },
  // base64를 blob으로 전환하는 함수
  b64toBlob(dataURI) {
    let byteString = atob(dataURI.split(",")[1]);
    let ab = new ArrayBuffer(byteString.length);
    let ia = new Uint8Array(ab);

    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: "image/png" });
  }
};
