/**
 * 1. 개요 : Grid 공통 컴포넌트
 * 2. 처리내용 : Grid 공통 컴포넌트 - Col
 * @File Name : Col.vue
 * @date : 2020. 02. 20. 09:46:00
 * @author : 이상철
 * @history :
 *-----------------------------------------------------------------------
 * 변경일			          		  작성자		변경내용
 *-----------------------------------------------------------------------
 * 2020. 2. 20. 09:46:00	    이상철	  최초 작성
 *-----------------------------------------------------------------------
 */
<template>
  <div class="grid-col"
    :style="style"
  >
    <slot />
  </div>
</template>

<script>
export default {
  name: 'Col',
  props: {
    width: {
      type: [String, Number],
      default: 'auto',
    },
    height: {
      type: [String, Number],
      default: 'auto',
    },
  },
  computed: {
    /**
     * style 속성 동적 셋
     * @return {Object}
     */
    style() {
      return { 
        flexWrap: 'wrap',
        flex: this.toString(this.width) === '100%' ? '1 0 0' : 'none',
        width: this.toString(this.width),
        maxWidth: this.toString(this.width),
        maxHeight: this.toString(this.height),
      };
    }
  },
  methods: {
    /**
     * props type 동적 변환
     * @params {String, Number} params
     */
    toString(params) {
      if (params === 'auto') return '100%';
      if (typeof params === 'number') return `${params}px`;
      return params;
    },
  },
};
</script>

<style lang="scss" scoped>
.grid-col {
  flex: 1 0 0;
}
</style>

