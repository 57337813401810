/**
 * 1. 개요 : Minputs 모바일 공통 컴포넌트 - MCodeSelector
 * 2. 처리내용 : MCodeSelector - 진입점
 * File Name : index.vue
 * date : 2020. 05. 12. 10:40:00
 * author : 이지혜
 * history :
 *-----------------------------------------------------------------------
 * 변경일			          		  작성자		변경내용
 *-----------------------------------------------------------------------
 * 2020. 05. 12. 10:40:00	    이지혜	  최초 작성
 *-----------------------------------------------------------------------
 */
<template>
	<div class="selector select-box">
		<select v-model="model" v-bind="$attrs" v-on="$listeners" :id="id">
			<option v-if="isAll" value>::: {{ "label.all" | i18n }} :::</option>
			<option v-if="isSel" value>::: {{ "label.select" | i18n }} :::</option>
			<option
				v-for="item in cdList"
				:key="item.codeId"
				:value="item.codeId"
				:label="item.codeName"
			>{{ item.codeName }}</option>
		</select>
		<div class="arrow-box" />
	</div>
</template>
<script>
export default {
	name: "MCodeSelector",
	model: {
		prop: "value",
		event: "apply"
	},

	props: {
		value: {
			type: [String, Number],
			default: ""
		},
		cdList: {
			type: Array,
			default: () => []
		},
		isAll: {
			type: Boolean,
			default: true
		},
		isSel: {
			type: Boolean,
			default: false
		},
		id: {
			type: String,
			default: ""
		}
	},

	data() {
		return {
			model: this.value
		};
	},

	watch: {
		value(value) {
			if (this.model !== value) this.model = value;
		},

		model(value) {
			this.$emit("apply", typeof value === "number" ? Number(value) : value);
		}
    },

    // computed: {
    //     i18nCodeList() {
    //         const langName = (() => {
    //             switch (this.msgLangCd) {
    //                 case "en_US":
    //                     return 'codeEnName';
    //                 case "vi_VN":
	// 											return 'codeViName';
	// 									case "ko_KR":
    //                     return 'codeKoName';
    //                 default:
    //                     return 'codeEnName';
    //             }
    //             })()

    //         return this.cdList.map(a => ({
    //             ...a, codeName: a[langName]
    //         }))
    //     }
    // },

	methods: {
		focus() {
			const input = this.$el.getElementsByTagName("select")[0];
			if (input) input.focus();
        },
	}
};
</script>

<style lang="scss" scoped>
@import "@/scss/mobile/_m-select.scss";
</style>

